import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProcess } from '../../models/process';
import { IProcessState } from '../../state/app.state';
import { ProcessFacade } from '../../state/process/process.facade';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-processdialogadd',
	templateUrl: './entitydialogadd-process.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ProcessDialogAddComponent extends EntityDialogAddComponent<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	constructor(entityFacade: ProcessFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
