import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleStick, IArticleStickEditForm, IArticleStickFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleStickApiService } from '../../services/api/article.stick.service';
import { ArticleStickDialogService } from '../../services/dialog/article.stick.service';
import { ArticleStickNotificationService } from '../../services/notification/article.stick.service';
import { AppState, IArticleStickState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleStickActions } from './article.stick.actions';
import { ArticleStickSelector } from './article.stick.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickEffects extends EntityEffects<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleStickApiService,
		notificationService: ArticleStickNotificationService,
		dialogService: ArticleStickDialogService,
		private selector: ArticleStickSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleStickActions, 'STICK');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleStickActions.search),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			switchMap(([, paging]) =>
				this.apiService.search({ articleKind: ArticleKind.Stick }, paging).pipe(
					map(data => fromArticleStickActions.searched({ entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleStickActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleStickActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			switchMap(([[[, list], isSearching], paging]) => {
				if (isSearching || list.length >= paging.top) {
					return of(fromArticleStickActions.extended());
				}

				return this.apiService.search({ articleKind: ArticleKind.Stick }, paging).pipe(
					map(data => fromArticleStickActions.searched({ entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleStickActions.failed({ error: response.error })))
				);
			})
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleStickActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
