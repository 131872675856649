import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { AppState, IPaymentPaypalState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPaypalActions } from './payment.paypal.actions';
import { initialEditFormValue } from './payment.paypal.reducer';
import { PaypalSelector } from './payment.paypal.selectors';

@Injectable({
	providedIn: 'root',
})
export class PaypalFacade extends EntityFacade<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPaypalActions;

	constructor(store: Store<AppState>, entitySelector: PaypalSelector) {
		super(store, entitySelector);
	}

	public sendPaymentLink(payment: IPaymentPaypal): void {
		this.store.dispatch(this.actions.sendPaymentLink({ payment }));
	}
}
