import { box, validate } from 'ngrx-forms';
import { IArticleMagazineEditForm, IArticleMagazineFilterForm, IExpandedArticleMagazine } from '../../models/article.model';
import { EntityState, IArticleMagazineState } from '../app.state';

import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';
import { IArticleMagazine } from '../../models/article.model';
import { EntityReducer } from '../entity.reducer';
import { fromArticleMagazineActions } from './article.magazine.actions';

export const initialValue: IArticleMagazine = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	order: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialEditFormValue: IArticleMagazineEditForm = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	order: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialFilterFormValue: IArticleMagazineFilterForm = {
	_id: null,
	createdAt: null,
	publicationDate: null,
	quality: box([]),
	storagePlace: null,
	product: null,
	order: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialState = {
	...EntityState.create<IArticleMagazine, IArticleMagazineEditForm, IArticleMagazineFilterForm>(
		'MAGAZINE',
		initialValue,
		initialEditFormValue,
		initialFilterFormValue,
		[
			{
				processInstance: {
					state: 'IN',
				},
				publicationDate: 'GTE',
			},
			{
				processInstance: {
					state: 'IN',
				},
				publicationDate: 'LTE',
			},
		],
		{
			product: 1,
			publicationDate: 1,
		}
	),
	isSearching: false,
	isExtending: false,
	itemsSearched: [] as IExpandedArticleMagazine[],
	paging: {
		skip: 0,
		top: 20,
	},
	dayRange: {
		current: 4,
		min: 4,
		max: 1024,
	},
};

export const articleMagazineReducer = EntityReducer.create<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
		publicationDate: validate(required),
	},
	fromArticleMagazineActions,
	on(fromArticleMagazineActions.search, state => ({
		...state,
		isSearching: true,
		paging: { ...state.paging, top: initialState.paging.top },
		dayRange: { ...state.dayRange, current: state.dayRange.min },
	})),
	on(fromArticleMagazineActions.searched, (state, { entities }) => ({
		...state,
		itemsSearched: entities,
		isExtending: false,
		isSearching: false,
	})),
	on(fromArticleMagazineActions.failed, state => ({
		...state,
		isSearching: false,
	})),
	on(fromArticleMagazineActions.extended, state => ({ ...state, isExtending: false, isSearching: false })),
	on(fromArticleMagazineActions.pagingChanged, (state, { paging }) => ({ ...state, paging, isExtending: true })),
	on(fromArticleMagazineActions.dayRangeChanged, (state, { dayRange }) => ({ ...state, dayRange, isExtending: true }))
);
