import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { AppState, IShippingDhlExpressState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDhlExpressActions } from './shipping.dhl-express.actions';
import { initialEditFormValue } from './shipping.dhl-express.reducer';
import { DhlExpressSelector } from './shipping.dhl-express.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlExpressFacade extends EntityFacade<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDhlExpressActions;

	constructor(store: Store<AppState>, dhlExpressSelector: DhlExpressSelector) {
		super(store, dhlExpressSelector);
	}
}
