import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUser, IUserFilterForm } from 'src/models/user.model';
import { AppState, IUserState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IUserEditForm } from './../../models/user.model';
import { fromUserActions } from './user.actions';
import { initialEditFormValue } from './user.reducer';
import { UserSelector } from './user.selectors';

@Injectable({
	providedIn: 'root',
})
export class UserFacade extends EntityFacade<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromUserActions;

	constructor(store: Store<AppState>, userSelector: UserSelector) {
		super(store, userSelector);
	}
}
