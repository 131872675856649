import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProcess } from '../../models/process';
import { IProcessState } from '../../state/app.state';
import { ProcessFacade } from '../../state/process/process.facade';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-processdialogchange',
	templateUrl: './entitydialogchange-process.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ProcessDialogChangeComponent extends EntityDialogChangeComponent<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	constructor(entityFacade: ProcessFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
