import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, ISessionState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class SessionSelector extends BaseSelector<ISessionState> {
	protected stateSelector = (state: AppState): ISessionState => state.session;

	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public userForm = createSelector(this.selectState, state => state.userForm);
	public authToken = createSelector(this.selectState, state => state.authToken);
	public isLoggedIn = createSelector(this.authToken, authToken => authToken != null);
	public user = createSelector(this.selectState, state => state.user);
	public administrateProcessRoutes = createSelector(this.selectState, state => state.administrateProcessRoutes);
}
