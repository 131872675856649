import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IProcessRoute } from '../../models/process-route.model';
import { AppState, IProcessRouteState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { fromProcessRouteActions } from './process-route.actions';
import { initialEditFormValue } from './process-route.reducer';
import { ProcessRouteSelector } from './process-route.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessRouteFacade extends EntityFacade<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromProcessRouteActions;

	constructor(store: Store<AppState>, private processRouteSelector: ProcessRouteSelector) {
		super(store, processRouteSelector);
	}

	public findBySourceNode(sourceNodeId: string): Observable<IProcessRoute[]> {
		return this.store.select(this.processRouteSelector.findBySourceNode(sourceNodeId));
	}
}
