import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DirectDebitDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-direct-debit.component';
import { DirectDebitDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-direct-debit.component';
import { DirectDebitDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-direct-debit.component';
import { IPaymentDirectDebit, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm } from '../../models/payment.model';
import { IPaymentDirectDebitState } from '../../state/app.state';
import { DirectDebitFacade } from '../../state/payment.direct-debit/payment.direct-debit.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentDirectDebitDialogService extends EntityDialogService<IPaymentDirectDebit, IPaymentDirectDebitState, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {
	protected createComponent: ComponentType<any> = DirectDebitDialogAddComponent;
	protected updateComponent: ComponentType<any> = DirectDebitDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DirectDebitDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DirectDebitFacade) {
		super(matDialog, entityFacade);
	}
}
