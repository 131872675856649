<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<mat-dialog-content class="picking-dialog">
		<form>
			<div class="form-grid">
				<div class="form-grid--left">
					<h3 class="mat-h3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">
						<ng-container *ngIf="productFacade.fetchOne(entity.product) | async as product">
							{{ product.name }}<ng-container *ngIf="entity.publicationDate"> vom {{ entity.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
						</ng-container>
					</h3>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div class="form-grid">
				<div class="form-grid--left">
					<mat-form-field appearance="outline">
						<mat-label>Kommentar</mat-label>
						<textarea matInput rows="18" [(ngModel)]="comment" [ngModelOptions]="{ standalone: true }"></textarea>
					</mat-form-field>
				</div>
				<div class="form-grid--right">
					<babylon-articlehistorytable></babylon-articlehistorytable>
				</div>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<button mat-flat-button color="default" (click)="certificateFacade.download(entity)" *ngIf="entity.articleKind == ArticleKinds.Certificate">Herunterladen</button>
		<babylon-process-buttons [facade]="articleFacade" [comment]="comment"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
