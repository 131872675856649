import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPayment } from '../../models/payment.model';
import { IPaymentState } from '../../state/app.state';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-paymentdialogchange',
	templateUrl: './entitydialogchange-payment.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PaymentDialogChangeComponent extends EntityDialogChangeComponent<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	constructor(entityFacade: PaymentFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
