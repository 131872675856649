import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap } from 'rxjs';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { BundleApiService } from '../../services/api/bundle.service';
import { BundleDialogService } from '../../services/dialog/bundle.service';
import { BundleNotificationService } from '../../services/notification/bundle.service';
import { AppState, IBundleState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromBundleActions } from './bundle.actions';
import { BundleSelector } from './bundle.selectors';

@Injectable({
	providedIn: 'root',
})
export class BundleEffects extends EntityEffects<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: BundleApiService, notificationService: BundleNotificationService, dialogService: BundleDialogService, selector: BundleSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromBundleActions, 'BUNDLE');
	}

	public onFilteredForCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filtered),
			switchMap(() => [])
		)
	);
}
