<section [class.sidenav]="isExpanded">
	<div class="toggle">
		<mat-icon (click)="toggleMenu.emit(null)" svgIcon="menu"></mat-icon>
		<span *ngIf="isExpanded">BABYLON <ng-container *ngIf="!isProduction">TESTING</ng-container></span>
	</div>
	<span class="menu">
		<ng-container *ngIf="isExpanded">Menü</ng-container>
	</span>
	<mat-list class="nav">
		<ng-container *ngIf="isExpanded">
			<a mat-list-item *ngIf="permissionFacade.isFiltering$ | async"><mat-progress-spinner mode="indeterminate" [diameter]="15"></mat-progress-spinner> Berechtigungen werden geprüft</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: true }">
				<mat-icon mat-list-icon matTooltip="Schnellstart" svgIcon="view-dashboard-outline"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true" [expanded]="false">
					<mat-expansion-panel-header>Schnellstart</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/start">
							Heutige Aufträge
							<span class="count" *ngIf="aggregationFacade.orderCountOfDate(0) | async as orderCountOfDate">{{ orderCountOfDate }}</span>
						</a>
						<a mat-list-item (click)="orderDialogService.openSearchDialog()">
							<mat-icon mat-list-icon matTooltip="Auftragssuche starten" svgIcon="open-in-new" class="open-in-new"></mat-icon>
							Auftragssuche starten
						</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('vertrieb') | async">
				<mat-icon mat-list-icon matTooltip="Vertrieb" svgIcon="cart-variant"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true" [expanded]="false">
					<mat-expansion-panel-header>Vertrieb</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order/OrderNew" [queryParams]="{ title: 'Aufträge Neu' }">
							Aufträge Neu
							<span class="count count--red" *ngIf="orderFacade.countByProcessNodes('OrderNew') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/order/OrderAssigned,OrderVerified,OrderApproved"
							[queryParams]="{ title: 'Aufträge Bearbeitung' }">
							Aufträge Bearbeitung
							<span class="count" *ngIf="orderFacade.countByProcessNodes('OrderAssigned', 'OrderVerified', 'OrderApproved') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order/OrderPreview" [queryParams]="{ title: 'Aufträge manuell prüfen' }">
							Aufträge manuell prüfen
							<span class="count count--yellow" *ngIf="orderFacade.countByProcessNodes('OrderPreview') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order">Alle Aufträge</a>
						<mat-divider></mat-divider>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/article/ArticlePostcheck,ArticleCommissioned"
							[queryParams]="{ title: 'Artikel Nachkontrolle' }">
							Artikel Nachkontrolle
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticlePostcheck', 'ArticleCommissioned') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleReproduce" [queryParams]="{ title: 'Artikel Anfertigung' }">
							Artikel Anfertigung
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleReproduce') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/article/ArticleOrder,ArticleOrdered"
							[queryParams]="{ title: 'Artikel Bestellung' }">
							Artikel Bestellung
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleOrder', 'ArticleOrdered') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleSold" [queryParams]="{ title: 'Artikel Verkauft' }">Artikel Verkauft</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article">Alle Artikel</a>
						<mat-divider></mat-divider>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/media-stock/StockInitialized,StockPrepared"
							[queryParams]="{ title: 'Medien Bestellungen' }">
							Medien Bestellungen
							<span class="count count--red" *ngIf="mediaStockFacade.countByProcessNodes('StockInitialized', 'StockPrepared') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/accessory-stock/StockInitialized,StockPrepared"
							[queryParams]="{ title: 'Accessoire Bestellungen' }">
							Accessoire Bestellungen
							<span class="count count--red" *ngIf="accessoryStockFacade.countByProcessNodes('StockInitialized', 'StockPrepared') | async as count">{{ count }}</span>
						</a>
						<mat-divider></mat-divider>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/customer">Alle Kunden</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('logistik') | async">
				<mat-icon mat-list-icon matTooltip="Logistik" svgIcon="package-variant"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true" [expanded]="false">
					<mat-expansion-panel-header>Logistik</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/shipping/ShippingPrepared"
							[queryParams]="{ title: 'Lieferungen Vorbereitung' }">
							Lieferungen Vorbereitung
							<span class="count" *ngIf="shippingFacade.countByProcessNodes('ShippingPrepared') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/shipping/ShippingCommissioned"
							[queryParams]="{ title: 'Lieferungen Kommissionierung' }">
							Lieferungen Kommissionierung
							<span class="count" *ngIf="shippingFacade.countByProcessNodes('ShippingCommissioned') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/shipping/ShippingAborted,ShippingShipped" [queryParams]="{ title: 'Lieferungen Abgeschlossen' }"
							>Lieferungen Abgeschlossen</a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/shipping">Alle Lieferungen</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('archiv') | async">
				<mat-icon mat-list-icon matTooltip="Archiv" svgIcon="newspaper"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true" [expanded]="false">
					<mat-expansion-panel-header>Archiv</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticlePick" [queryParams]="{ title: 'Artikel Entnahme' }">
							Artikel Entnahme
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticlePick') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleHandOver" [queryParams]="{ title: 'Artikel Übergabe' }">
							Artikel Übergabe
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleHandOver') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleReturn" [queryParams]="{ title: 'Artikel Retoure' }">
							Artikel Retoure
							<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleReturn') | async as count">{{ count }}</span>
						</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('buchhaltung') | async">
				<mat-icon mat-list-icon matTooltip="Buchhaltung" svgIcon="receipt-text-outline"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true" [expanded]="false">
					<mat-expansion-panel-header>Buchhaltung</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptInitialized" [queryParams]="{ title: 'Belege Neu' }">
							Belege Neu
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptInitialized') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/receipt/ReceiptCommitted,ReceiptReminderCommitted"
							[queryParams]="{ title: 'Belege Offen' }">
							Belege Offen
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptCommitted', 'ReceiptReminderCommitted') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/receipt/ReceiptFollowUp,ReceiptFollowUpFirstReminder,ReceiptFollowUpSecondReminder"
							[queryParams]="{ title: 'Belege Wiedervorlage' }">
							Belege Wiedervorlage
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptFollowUp', 'ReceiptFollowUpFirstReminder', 'ReceiptFollowUpSecondReminder') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/receipt/ReceiptFirstReminder,ReceiptSecondReminder"
							[queryParams]="{ title: 'Belege Zahlungserinnerung' }">
							Belege Zahlungserinnerung
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptFirstReminder', 'ReceiptSecondReminder') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptDebtCollection" [queryParams]="{ title: 'Belege Inkasso' }">
							Belege Inkasso
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptDebtCollection') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/receipt/ReceiptDebtCollectionUncompleted"
							[queryParams]="{ title: 'Inkasso erfolglos' }">
							Inkasso erfolglos
							<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptDebtCollectionUncompleted') | async as count">{{ count }}</span>
						</a>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/receipt/ReceiptCommitted,ReceiptCompleted,ReceiptUncompleted"
							[queryParams]="{ title: 'Inkasso erfolgreich', previousRoute: 'SetDebtCollectionReceipt' }">
							Inkasso erfolgreich
						</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptUncompleted,ReceiptCompleted" [queryParams]="{ title: 'Belege Abgeschlossen' }"
							>Belege Abgeschlossen</a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt">Alle Belege</a>
						<mat-divider></mat-divider>
						<a mat-list-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment/PaymentAssigned" [queryParams]="{ title: 'Zahlungen Ausstehend' }">
							Zahlungen Ausstehend
							<span class="count" *ngIf="paymentFacade.countByProcessNodes('PaymentAssigned') | async as count">{{ count }}</span>
						</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment/PaymentCancelled,PaymentCompleted" [queryParams]="{ title: 'Zahlungen Abgeschlossen' }"
							>Zahlungen Abgeschlossen</a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment">Alle Zahlungen</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/paypal" [queryParams]="{ title: 'PayPal-Zahlungen' }">Alle PayPal-Zahlungen</a>
						<mat-divider></mat-divider>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/accounting-summary/AccountingSummaryInitialized,AccountingSummaryPrepared"
							[queryParams]="{ title: 'Buchungssätze Bearbeitung' }"
							>Buchungssätze Bearbeitung <span class="count" *ngIf="accountingSummaryFacade.countByProcessNodes('AccountingSummaryInitialized', 'AccountingSummaryPrepared') | async as count">{{ count }}</span></a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accounting-summary/AccountingSummarySent" [queryParams]="{ title: 'Buchungssätze Übermittelt' }"
							>Buchungssätze Übermittelt</a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accounting-summary">Alle Buchungssätze</a>
						<mat-divider></mat-divider>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/direct-debit-transfer/DirectDebitTransferInitialized"
							[queryParams]="{ title: 'Lastschrifteinzüge Bearbeitung' }"
							>Lastschrifteinzüge Bearbeitung <span class="count" *ngIf="directDebitTransferFacade.countByProcessNodes('DirectDebitTransferInitialized') | async as count">{{ count }}</span></a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/direct-debit-transfer">Alle Lastschrifteinzüge</a>
						<mat-divider></mat-divider>
						<a
							mat-list-item
							class="count-wrapper"
							routerLinkActive="active-sublink"
							[routerLinkActiveOptions]="{ exact: true }"
							routerLink="/entity/collection-purchase-transfer/CollectionPurchaseTransferInitialized"
							[queryParams]="{ title: 'Sammelrechnungen Bearbeitung' }"
							>Sammelrechnungen Bearbeitung <span class="count" *ngIf="collectionPurchaseTransferFacade.countByProcessNodes('CollectionPurchaseTransferInitialized') | async as count">{{ count }}</span></a
						>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/collection-purchase-transfer">Alle Sammelrechnungen</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('verwaltung') | async">
				<mat-icon mat-list-icon matTooltip="Verwaltung" svgIcon="file-tree"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true">
					<mat-expansion-panel-header>Verwaltung</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-stock">Medien-Bestände</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-product">Medien-Produkte</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/newspaper">Zeitungen</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/magazine">Zeitschriften</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/book">Antiquarische Bücher</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/yearbook">Geschenkbücher</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/cd">CDs</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/dvd">DVDs</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/coin">Hist. Zahlungsmittel</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/chronicle">Jahrgangs-Chroniken</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/birthday-book">Pers. Geburtstagsbuch</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/birthday-chronicle">Pers. Geburtstags-Chronik</a>
						<mat-divider></mat-divider>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accessory-product">Accessoire-Produkte</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accessory-stock">Accessoire-Bestände</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/folder">Mappen</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/stick">Zeitungshalter</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/certificate">Urkunden</a>
						<mat-divider></mat-divider>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-brand">Marken</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/region">Regionen</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/text-template">Textvorlagen</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/image">Bilder</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/document">Dokumente</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/uploaded-file">Dateien</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/storage-place">Lagerplätze</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/publication-cycle">Zeiträume</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('shop') | async">
				<mat-icon mat-list-icon matTooltip="Shop" svgIcon="store-outline"></mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true">
					<mat-expansion-panel-header>Shop</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/page">Landing-Pages</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/bundle">Produktpakete</a>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
			<a mat-list-item routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('admin') | async">
				<mat-icon mat-list-icon matTooltip="Administration" svgIcon="cog-outline"> </mat-icon>
				<mat-expansion-panel dense [class.mat-elevation-z0]="true">
					<mat-expansion-panel-header>Administration</mat-expansion-panel-header>
					<mat-nav-list dense>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/user">Benutzer</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/partner">Partner</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/configuration">Konfiguration</a>
						<a mat-list-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/counter">Statistiken</a>
						<a mat-list-item *ngIf="!isProduction" (click)="databaseDialogService.openResetDialog()">
							<mat-icon mat-list-icon matTooltip="Datenrücksetzung" svgIcon="open-in-new" class="open-in-new"></mat-icon>
							Datenrücksetzung
						</a>
						<mat-slide-toggle mat-list-item [checked]="sessionFacade.administrateProcessRoutes$ | async" (change)="sessionFacade.changeAdministrateProcessRoutes($event.checked)"> Prozessroutenverwaltung</mat-slide-toggle>
					</mat-nav-list>
				</mat-expansion-panel>
			</a>
		</ng-container>
		<ng-container *ngIf="!isExpanded">
			<a mat-list-item *ngIf="permissionFacade.isFiltering$ | async"><mat-progress-spinner mode="indeterminate" [diameter]="25"></mat-progress-spinner></a>
			<a mat-list-item [matMenuTriggerFor]="schnellstartMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('admin') | async">
				<ng-container *ngIf="aggregationFacade.orderCountOfDate(0) | async as orderCountOfDate">
					<span class="badge badge--gray" [matBadge]="orderCountOfDate" [matBadgeHidden]="orderCountOfDate == 0" matBadgePosition="above after">
						<mat-icon mat-list-icon matTooltip="Schnellstart" svgIcon="view-dashboard-outline"></mat-icon>
					</span>
				</ng-container>
			</a>
			<mat-menu #schnellstartMenu="matMenu" class="sidenav">
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/start">
					Heutige Aufträge
					<ng-container *ngIf="aggregationFacade.orderCountOfDate(0) | async as orderCountOfDate">
						<span class="count">{{ orderCountOfDate }}</span>
					</ng-container>
				</button>
				<button mat-menu-item (click)="orderDialogService.openSearchDialog()">
					<mat-icon mat-list-icon matTooltip="Auftragssuche starten" svgIcon="open-in-new" class="open-in-new"></mat-icon>
					Auftragssuche starten
				</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="vertriebMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('vertrieb') | async">
				<ng-container *ngLet="orderFacade.countByProcessNodes('OrderNew') | async as OrderNew">
					<ng-container *ngLet="orderFacade.countByProcessNodes('OrderPreview') | async as orderPreview">
						<span class="badge badge--red" [matBadge]="OrderNew" [matBadgeHidden]="OrderNew == 0" matBadgePosition="above after">
							<span class="badge badge--yellow" [matBadge]="orderPreview" [matBadgeHidden]="orderPreview == 0" matBadgePosition="below after">
								<mat-icon mat-list-icon matTooltip="Vertrieb" svgIcon="cart-variant"></mat-icon>
							</span>
						</span>
					</ng-container>
				</ng-container>
			</a>
			<mat-menu #vertriebMenu="matMenu" class="sidenav">
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order/OrderNew" [queryParams]="{ title: 'Aufträge Neu' }">
					Aufträge Neu
					<span class="count count--red" *ngIf="orderFacade.countByProcessNodes('OrderNew') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/order/OrderAssigned,OrderVerified,OrderApproved"
					[queryParams]="{ title: 'Aufträge Bearbeitung' }">
					Aufträge Bearbeitung
					<span class="count" *ngIf="orderFacade.countByProcessNodes('OrderAssigned', 'OrderVerified', 'OrderApproved') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order/OrderPreview" [queryParams]="{ title: 'Aufträge manuell prüfen' }">
					Aufträge manuell prüfen
					<span class="count count--yellow" *ngIf="orderFacade.countByProcessNodes('OrderPreview') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/order">Alle Aufträge</button>
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/article/ArticlePostcheck,ArticleCommissioned"
					[queryParams]="{ title: 'Artikel Nachkontrolle' }">
					Artikel Nachkontrolle
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticlePostcheck', 'ArticleCommissioned') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleReproduce" [queryParams]="{ title: 'Artikel Anfertigung' }">
					Artikel Anfertigung
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleReproduce') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/article/ArticleOrder,ArticleOrdered"
					[queryParams]="{ title: 'Artikel Bestellung' }">
					Artikel Bestellung
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleOrder', 'ArticleOrdered') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleSold" [queryParams]="{ title: 'Artikel Verkauft' }">Artikel Verkauft</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article">Alle Artikel</button>
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/media-stock/StockInitialized,StockPrepared"
					[queryParams]="{ title: 'Medien Bestellungen' }"
					*ngIf="mediaStockFacade.countByProcessNodes('StockInitialized', 'StockPrepared') | async as count">
					Medien Bestellungen
					<span class="count count--red">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/accessory-stock/StockInitialized,StockPrepared"
					[queryParams]="{ title: 'Accessoire Bestellungen' }"
					*ngIf="accessoryStockFacade.countByProcessNodes('StockInitialized', 'StockPrepared') | async as count">
					Accessoire Bestellungen
					<span class="count count--red">{{ count }}</span>
				</button>
				<mat-divider></mat-divider>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/customer">Alle Kunden</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="logistikMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('logistik') | async">
				<mat-icon mat-list-icon matTooltip="Logistik" svgIcon="package-variant"></mat-icon>
			</a>
			<mat-menu #logistikMenu="matMenu" class="sidenav">
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/shipping/ShippingPrepared" [queryParams]="{ title: 'Lieferungen Vorbereitung' }">
					Lieferungen Vorbereitung
					<span class="count" *ngIf="shippingFacade.countByProcessNodes('ShippingPrepared') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/shipping/ShippingCommissioned"
					[queryParams]="{ title: 'Lieferungen Kommissionierung' }">
					Lieferungen Kommissionierung
					<span class="count" *ngIf="shippingFacade.countByProcessNodes('ShippingCommissioned') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/shipping/ShippingAborted,ShippingShipped" [queryParams]="{ title: 'Lieferungen Abgeschlossen' }">
					Lieferungen Abgeschlossen
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/shipping">Alle Lieferungen</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="archivMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('archiv') | async">
				<mat-icon mat-list-icon matTooltip="Archiv" svgIcon="newspaper"></mat-icon>
			</a>
			<mat-menu #archivMenu="matMenu" class="sidenav">
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticlePick" [queryParams]="{ title: 'Artikel Entnahme' }">
					Artikel Entnahme
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticlePick') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleHandOver" [queryParams]="{ title: 'Artikel Übergabe' }">
					Artikel Übergabe
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleHandOver') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/article/ArticleReturn" [queryParams]="{ title: 'Artikel Retoure' }">
					Artikel Retoure
					<span class="count" *ngIf="articleFacade.countByProcessNodes('ArticleReturn') | async as count">{{ count }}</span>
				</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="buchhaltungMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('buchhaltung') | async">
				<mat-icon mat-list-icon matTooltip="Buchhaltung" svgIcon="receipt-text-outline"></mat-icon>
			</a>
			<mat-menu #buchhaltungMenu="matMenu" class="sidenav">
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptInitialized" [queryParams]="{ title: 'Belege Neu' }">
					Belege Neu
					<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptInitialized') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/receipt/ReceiptCommitted,ReceiptReminderCommitted"
					[queryParams]="{ title: 'Belege Offen' }">
					Belege Offen
					<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptCommitted', 'ReceiptReminderCommitted') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/receipt/ReceiptFollowUp,ReceiptFollowUpFirstReminder,ReceiptFollowUpSecondReminder"
					[queryParams]="{ title: 'Belege Wiedervorlage' }">
					Belege Wiedervorlage
					<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptFollowUp', 'ReceiptFollowUpFirstReminder', 'ReceiptFollowUpSecondReminder') | async as count">{{ count }}</span>
				</button>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/receipt/ReceiptFirstReminder,ReceiptSecondReminder"
					[queryParams]="{ title: 'Belege Zahlungserinnerung' }">
					Belege Zahlungserinnerung
					<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptFirstReminder', 'ReceiptSecondReminder') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptDebtCollection" [queryParams]="{ title: 'Belege Inkasso' }">
					Belege Inkasso
					<span class="count" *ngIf="receiptFacade.countByProcessNodes('ReceiptDebtCollection') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt/ReceiptUncompleted,ReceiptCompleted" [queryParams]="{ title: 'Belege Abgeschlossen' }">
					Belege Abgeschlossen
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/receipt">Alle Belege</button>
				<mat-divider></mat-divider>
				<button mat-menu-item class="count-wrapper" routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment/PaymentAssigned" [queryParams]="{ title: 'Zahlungen Ausstehend' }">
					Zahlungen Ausstehend
					<span class="count" *ngIf="paymentFacade.countByProcessNodes('PaymentAssigned') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment/PaymentCancelled,PaymentCompleted" [queryParams]="{ title: 'Zahlungen Abgeschlossen' }">
					Zahlungen Abgeschlossen
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/payment">Alle Zahlungen</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/paypal" [queryParams]="{ title: 'PayPal-Zahlungen' }">Alle PayPal-Zahlungen</button>
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/accounting-summary/AccountingSummaryInitialized,AccountingSummaryPrepared"
					[queryParams]="{ title: 'Buchungssätze Bearbeitung' }">
					Buchungssätze Bearbeitung <span class="count" *ngIf="accountingSummaryFacade.countByProcessNodes('AccountingSummaryInitialized', 'AccountingSummaryPrepared') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accounting-summary/AccountingSummarySent" [queryParams]="{ title: 'Buchungssätze Übermittelt' }">
					Buchungssätze Übermittelt
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accounting-summary">Alle Buchungssätze</button>
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/direct-debit-transfer/DirectDebitTransferInitialized"
					[queryParams]="{ title: 'Lastschrifteinzüge Bearbeitung' }">
					Lastschrifteinzüge Bearbeitung <span class="count" *ngIf="directDebitTransferFacade.countByProcessNodes('DirectDebitTransferInitialized') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/direct-debit-transfer">Alle Lastschrifteinzüge</button>
				<mat-divider></mat-divider>
				<button
					mat-menu-item
					class="count-wrapper"
					routerLinkActive="active-sublink"
					[routerLinkActiveOptions]="{ exact: true }"
					routerLink="/entity/collection-purchase-transfer/CollectionPurchaseTransferInitialized"
					[queryParams]="{ title: 'Lastschrifteinzüge Bearbeitung' }">
					Sammelrechnungen Bearbeitung <span class="count" *ngIf="directDebitTransferFacade.countByProcessNodes('CollectionPurchaseTransferInitialized') | async as count">{{ count }}</span>
				</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/collection-purchase-transfer">Alle Sammelrechnungen</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="verwaltungMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('verwaltung') | async">
				<mat-icon mat-list-icon matTooltip="Verwaltung" svgIcon="file-tree"></mat-icon>
			</a>
			<mat-menu #verwaltungMenu="matMenu" class="sidenav">
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-stock">Medien-Bestände</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-product">Medien-Produkte</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/newspaper">Zeitungen</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/magazine">Zeitschriften</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/book">Antiquarische Bücher</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/yearbook">Geschenkbücher</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/cd">CDs</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/dvd">DVDs</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/coin">Hist. Zahlungsmittel</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/chronicle">Jahrgangs-Chroniken</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/birthday-book">Pers. Geburtstagsbuch</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/birthday-chronicle">Pers. Geburtstags-Chronik</button>
				<mat-divider></mat-divider>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accessory-product">Accessoire-Produkte</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/accessory-stock">Accessoire-Bestände</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/folder">Mappen</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/stick">Zeitungshalter</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/certificate">Urkunden</button>
				<mat-divider></mat-divider>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/media-brand">Marken</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/region">Regionen</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/text-template">Textvorlagen</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/image">Bilder</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/document">Dokumente</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/uploaded-file">Dateien</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/storage-place">Lagerplätze</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/publication-cycle">Zeiträume</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="shopMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('shop') | async">
				<mat-icon mat-list-icon matTooltip="Shop" svgIcon="store-outline"></mat-icon>
			</a>
			<mat-menu #shopMenu="matMenu" class="sidenav">
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/page">Landing-Pages</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/bundle">Produktpakete</button>
			</mat-menu>
			<a mat-list-item [matMenuTriggerFor]="administrationMenu" routerLinkActive="active-link" [routerLinkActiveOptions]="{ exact: false }" *ngIf="permissionFacade.hasPermission('admin') | async">
				<mat-icon mat-list-icon matTooltip="Administration" svgIcon="cog-outline"> </mat-icon>
			</a>
			<mat-menu #administrationMenu="matMenu" class="sidenav">
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/user">Benutzer</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/partner">Partner</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/configuration">Konfiguration</button>
				<button mat-menu-item routerLinkActive="active-sublink" [routerLinkActiveOptions]="{ exact: true }" routerLink="/entity/counter">Statistiken</button>
				<button mat-menu-item routerLinkActive="active-sublink" *ngIf="!isProduction" (click)="databaseDialogService.openResetDialog()">
					<mat-icon mat-list-icon matTooltip="Datenrücksetzung" svgIcon="open-in-new" class="open-in-new"></mat-icon>
					Datenrücksetzung
				</button>
			</mat-menu>
		</ng-container>
	</mat-list>
	<br />
	<div class="nav-second">
		<div class="nav-second__user" *ngIf="sessionFacade.user$ | async as user">
			<babylon-profile-picture [userId]="user._id" [showName]="false"></babylon-profile-picture>
			<p *ngIf="isExpanded">{{ user.name }}</p>
		</div>
		<div class="nav-second__buttons" *ngIf="isExpanded">
			<div class="nav-second__buttons--logout">
				<button mat-icon-button (click)="sessionFacade.logout()">
					<mat-icon svgIcon="logout"></mat-icon>
				</button>
			</div>
		</div>
	</div>
</section>
