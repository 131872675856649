import { box } from 'ngrx-forms';
import { IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentPrepaymentState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromPrepaymentActions } from './payment.prepayment.actions';

export const initialValue: IPaymentPrepayment = {
	_id: null,
	order: null,
	paidDate: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentPrepaymentEditForm = {
	_id: null,
	order: null,
	paidDate: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentPrepaymentFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	paidDate: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm>('PREPAYMENT', initialValue, initialEditFormValue, initialFilterFormValue);
export const prepaymentReducer = EntityReducer.create<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm>(initialState, {}, fromPrepaymentActions);
