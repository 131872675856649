<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<ng-container *ngIf="entityFacade.selected$ | async as entity">
		<table mat-table [dataSource]="dataSource$ | async">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>
					<div>Datum</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">
					{{ action.date | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm (dd)' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="userName">
				<th mat-header-cell *matHeaderCellDef>
					<div>Name</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">{{ action.userName }}</td>
			</ng-container>

			<ng-container matColumnDef="route">
				<th mat-header-cell *matHeaderCellDef>
					<div>Route</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">
					{{ (processRouteFacade.fetchOne(action.route) | async)?.displayName }}
				</td>
			</ng-container>

			<ng-container matColumnDef="state">
				<th mat-header-cell *matHeaderCellDef>
					<div>Status</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">
					<span *ngIf="processRouteFacade.fetchOne(action.route) | async as route">
						<span *ngIf="processNodeFacade.fetchOne(route.destinationNode) | async as processNode" class="status status--{{ processNode.color }}">
							{{ processNode.displayName }}
						</span>
					</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="comment">
				<th mat-header-cell *matHeaderCellDef></th>
				<td width="250px" mat-cell *matCellDef="let action; let i = index">
					<button mat-icon-button (click)="openCommentDialog(action.comment, i)" matTooltip="Kommentar">
						<mat-icon *ngIf="action.comment && action.comment != ''" svgIcon="message-text"></mat-icon>
						<mat-icon *ngIf="!action.comment || action.comment == ''" svgIcon="message"></mat-icon>
					</button>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns"></tr>
		</table>
	</ng-container>
</ng-container>
