import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentDirectDebit, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm } from '../../models/payment.model';
import { AppState, IPaymentDirectDebitState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDirectDebitActions } from './payment.direct-debit.actions';
import { initialEditFormValue } from './payment.direct-debit.reducer';
import { DirectDebitSelector } from './payment.direct-debit.selectors';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitFacade extends EntityFacade<IPaymentDirectDebit, IPaymentDirectDebitState, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDirectDebitActions;

	constructor(store: Store<AppState>, entitySelector: DirectDebitSelector) {
		super(store, entitySelector);
	}
}
