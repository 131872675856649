import { box, validate } from 'ngrx-forms';
import { IMediaProduct, IMediaProductEditForm } from '../../models/media-product.model';
import { EntityState, IMediaProductState } from '../app.state';

import { required } from 'ngrx-forms/validation';
import { EntityReducer } from '../entity.reducer';
import { IMediaProductFilterForm } from './../../models/media-product.model';
import { fromMediaProductActions } from './media-product.actions';

export const initialValue: IMediaProduct = {
	_id: null,
	processInstance: null,
	name: null,
	validFrom: null,
	isWeekendDoubleEdition: null,
	mediaBrand: null,
	publicationCycle: null,
	regions: [],
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	link: null,
	productionRegion: null,
	graphicalRegion: null,
	articleKind: null,
	topic: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	additionalImages: [],
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialEditFormValue: IMediaProductEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	validFrom: null,
	isWeekendDoubleEdition: null,
	mediaBrand: null,
	publicationCycle: null,
	regions: box([]),
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	link: null,
	productionRegion: null,
	graphicalRegion: null,
	articleKind: null,
	topic: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	additionalImages: box([]),
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialFilterFormValue: IMediaProductFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	validFrom: null,
	isWeekendDoubleEdition: null,
	mediaBrand: box([]),
	publicationCycle: null,
	regions: box([]),
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	link: null,
	productionRegion: null,
	graphicalRegion: null,
	articleKind: box([]),
	topic: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	additionalImages: box([]),
	partnerPrices: [],
};

export const initialState = EntityState.create<IMediaProduct, IMediaProductEditForm, IMediaProductFilterForm>(
	'MEDIAPRODUCT',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			name: 'CONTAINS',
			mediaBrand: 'IN',
			productionRegion: 'IN',
			price: 'EQ',
		},
	],
	{
		name: 1,
	}
);

export const mediaProductReducer = EntityReducer.create<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm>(
	initialState,
	{
		name: validate(required),
		price: validate(required),
		taxRate: validate(required),
		productionRegion: validate(required),
		articleKind: validate(required),
		publicationCycle: validate(required),
	},
	fromMediaProductActions
);
