import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDirectDebitTransfer } from '../../models/direct-debit-transfer.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferApiService extends EntityApiService<IDirectDebitTransfer> {
	protected entityName = 'direct-debit-transfers';

	constructor(http: HttpClient) {
		super(http);
	}

	public downloadCsv(authToken: string, entity: IDirectDebitTransfer): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'text/csv');

		return this.post<any, any>([this.entityName, entity._id, 'generate-csv'], null, headers, 'blob');
	}

	public downloadXml(authToken: string, entity: IDirectDebitTransfer): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'text/xml');

		return this.post<any, any>([this.entityName, entity._id, 'generate-xml'], null, headers, 'blob');
	}

	public filterByPaymentIds(paymentIds: string[], authToken: string): Observable<IEntityListResponse<IDirectDebitTransfer>> {
		return this.post<IEntityListResponse<IDirectDebitTransfer>, { paymentIds: string[] }>([this.entityName, 'payment'], { paymentIds }, this.getHeaders(authToken));
	}
}
