import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleBook, IArticleBookEditForm, IBookFilterForm } from '../../models/article.model';
import { AppState, IArticleBookState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleBookSelector extends EntitySelector<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	protected stateSelector = (state: AppState): IArticleBookState => state.articleBook;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
	public dayRange = createSelector(this.selectState, state => state.dayRange);
}
