import { box } from 'ngrx-forms';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { EntityState, IMessageState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromMessageActions } from './message.actions';

export const initialValue: IMessage = {
	_id: null,
	title: null,
	text: null,
	readonly: false,
	hidden: false,
	order: null,
	textTemplate: null,
	attachments: [],
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IMessageEditForm = {
	_id: null,
	title: null,
	text: null,
	readonly: false,
	hidden: false,
	order: null,
	textTemplate: null,
	attachments: box([]),
	processInstance: {
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		priority: null,
	},
};

export const initialFilterFormValue: IMessageFilterForm = {
	_id: null,
	createdAt: null,
	title: null,
	text: null,
	readonly: false,
	hidden: false,
	order: null,
	textTemplate: null,
	attachments: box([]),
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IMessage, IMessageEditForm, IMessageFilterForm>(
	'MESSAGE',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			title: 'IN',
			text: 'IN',
			processInstance: {
				state: 'IN',
				currentUser: 'IN',
			},
			createdAt: 'DATEEQ',
		},
	],
	{
		createdAt: -1,
	}
);

export const messageReducer = EntityReducer.create<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm>(initialState, {}, fromMessageActions);
