import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Aggregation, Prediction } from '../../state/app.state';
import { ApiService, IDataResponse } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class PredictionService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	public predictReceiptTotalPrice(authToken: string, aggregation: Aggregation, aggregationType: string): Observable<IDataResponse<Prediction>> {
		return this.post<IDataResponse<Prediction>, any>(['prediction', 'receipt-totalprice'], { aggregation, aggregationType }, this.getHeaders(authToken), 'json');
	}
}
