import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleCd, IArticleCdEditForm, IExpandedArticleCd } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleCdActions = {
	...EntityActions.create<IArticleCd, IArticleCdEditForm>('[CD]'),
	search: createAction(`[CD] Search`, props<{ date: Date }>()),
	searched: createAction(`[CD] Searched`, props<{ date: Date; entities: IExpandedArticleCd[] }>()),
	extended: createAction(`[CD] Extended`),
	pagingChanged: createAction(`[CD] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[CD] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
