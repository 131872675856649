import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPublicationCycle } from '../../models/publication-cycle.model';
import { PublicationCycleApiService } from '../../services/api/publication-cycle.service';
import { PublicationCycleDialogService } from '../../services/dialog/publication-cycle.service';
import { PublicationCycleNotificationService } from '../../services/notification/publication-cycle.service';
import { AppState, IPublicationCycleState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { fromPublicationCycleActions } from './publication-cycle.actions';
import { PublicationCycleSelector } from './publication-cycle.selectors';

@Injectable({
	providedIn: 'root',
})
export class PublicationCycleEffects extends EntityEffects<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PublicationCycleApiService,
		notificationService: PublicationCycleNotificationService,
		dialogService: PublicationCycleDialogService,
		selector: PublicationCycleSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPublicationCycleActions, 'PUBLICATIONCYCLE');
	}
}
