import { Injectable } from '@angular/core';
import { IStockMedia, IStockMediaEditForm, IStockMediaFilterForm } from '../../models/stock.model';
import { AppState, IStockMediaState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class MediaStockSelector extends EntitySelector<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	protected stateSelector = (state: AppState): IStockMediaState => state.mediaStock;
}
