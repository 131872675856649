import { Injectable } from '@angular/core';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { AppState, IMailTemplateState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateSelector extends EntitySelector<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm> {
	protected stateSelector = (state: AppState): IMailTemplateState => state.mailTemplate;
}
