import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleYearBook } from '../../models/article.model';
import { IArticleYearBookState } from '../../state/app.state';
import { ArticleYearBookFacade } from '../../state/article.yearbook/article.yearbook.facade';
import { IArticleYearBookEditForm, IArticleYearBookFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-yearbookdialogdelete',
	templateUrl: './entitydialogdelete-yearbook.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class YearBookDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	constructor(entityFacade: ArticleYearBookFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
