import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { AppState, IPaymentCollectionPurchaseState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCollectionPurchaseActions } from './payment.collection-purchase.actions';
import { initialEditFormValue } from './payment.collection-purchase.reducer';
import { CollectionPurchaseSelector } from './payment.collection-purchase.selectors';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseFacade extends EntityFacade<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCollectionPurchaseActions;

	constructor(store: Store<AppState>, collectionPurchaseSelector: CollectionPurchaseSelector) {
		super(store, collectionPurchaseSelector);
	}
}
