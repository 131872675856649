import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { IShippingReturnState } from '../../state/app.state';
import { ReturnFacade } from '../../state/shipping.return/shipping.return.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-returndialogchange',
	templateUrl: './entitydialogchange-return.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ReturnDialogChangeComponent extends EntityDialogChangeComponent<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	constructor(entityFacade: ReturnFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
