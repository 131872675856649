<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Partner ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>E-Mail</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.email" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Telefonnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.telephone" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Fax</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.fax" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Zahlungsmethode</mat-label>
							<mat-select placeholder="Zahlungsmethode" [ngrxFormControlState]="editForm.controls.paymentKind">
								<mat-option *ngFor="let paymentKind of PaymentKinds | keyvalue" [value]="paymentKind.value">{{ paymentKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<babylon-user-autocompletesingle [value]="editForm.controls.user.value" [controlId]="editForm.controls.user.id"></babylon-user-autocompletesingle>
						<mat-form-field appearance="outline">
							<mat-label>S-Konto</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.sAccount" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>H-Konto</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.hAccount" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Rechnungsempfänger">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Anrede</mat-label>
							<mat-select placeholder="Zahlungsmethode" [ngrxFormControlState]="editForm.controls.receiver.controls.salutationKind">
								<mat-option *ngFor="let salutationKind of SalutationKinds | keyvalue" [value]="salutationKind.value">{{ salutationKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Adresstyp</mat-label>
							<mat-select placeholder="Adresstyp" [ngrxFormControlState]="editForm.controls.receiver.controls.addressKind">
								<mat-option *ngFor="let addressKind of AddressKinds | keyvalue" [value]="addressKind.value">{{ addressKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Vorname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.firstName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.lastName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Firma</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.company" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Standardpreise">
					<mat-card class="box">
						<mat-card-content>
							<babylon-article-pricestable controlId="articlePrices"></babylon-article-pricestable>
						</mat-card-content>
					</mat-card>
					<mat-card class="box">
						<mat-card-content>
							<babylon-bundle-pricestable controlId="bundlePrices"></babylon-bundle-pricestable>
						</mat-card-content>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
