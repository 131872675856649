import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import moment from 'moment';
import { interval, map } from 'rxjs';
import { UpdateService } from 'src/services/utility/update.service';
import { MenuFacade } from 'src/state/menu/menu.facade';
import { SessionFacade } from 'src/state/session/session.facade';
import { progressFade } from '../../animations/progressfade.animation';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'babylon-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [progressFade],
})
export class MainComponent extends BaseComponent implements OnInit {
	public currentDate$ = interval(1000).pipe(map(_ => moment()));

	constructor(private updateService: UpdateService, public sessionFacade: SessionFacade, public menuFacade: MenuFacade) {
		super();
	}

	public ngOnInit(): void {
		this.updateService.checkForUpdates();
	}
}
