import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FolderAddDialogComponent } from '../../components/entitydialog/entitydialog-folder-add.component';
import { FolderReplaceDialogComponent } from '../../components/entitydialog/entitydialog-folder-replace.component';
import { FolderDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-folder.component';
import { FolderDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-folder.component';
import { FolderDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-folder.component';
import { IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm } from '../../models/article.model';
import { IArticleFolderState } from '../../state/app.state';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleFolderDialogService extends EntityDialogService<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	protected createComponent: ComponentType<any> = FolderDialogAddComponent;
	protected updateComponent: ComponentType<any> = FolderDialogChangeComponent;
	protected removeComponent: ComponentType<any> = FolderDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = FolderReplaceDialogComponent;
	protected addComponent: ComponentType<any> = FolderAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleFolderFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleFolder>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleFolder>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
