import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleDvd, IArticleDvdEditForm, IExpandedArticleDvd } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleDvdActions = {
	...EntityActions.create<IArticleDvd, IArticleDvdEditForm>('[DVD]'),
	search: createAction(`[DVD] Search`, props<{ date: Date }>()),
	searched: createAction(`[DVD] Searched`, props<{ date: Date; entities: IExpandedArticleDvd[] }>()),
	extended: createAction(`[DVD] Extended`),
	pagingChanged: createAction(`[DVD] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[DVD] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
