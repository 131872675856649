<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<table mat-table [dataSource]="dataSource$ | async">
		<ng-container matColumnDef="blockKind">
			<th mat-header-cell *matHeaderCellDef>
				<div>Blocktyp</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let item">
				{{ item.blockKind }}
			</td>
		</ng-container>

		<ng-container matColumnDef="preview">
			<th mat-header-cell *matHeaderCellDef>
				<div>Vorschau</div>
			</th>
			<td mat-cell *matCellDef="let item">
				<pre *ngIf="item.parameters != null && item.parameters.length > 0">{{ item.parameters[0].value | string | strip | truncate: [30] }}</pre>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td width="100px" mat-cell *matCellDef="let item; let i = index">
				<button mat-icon-button (click)="remove(i)" matTooltip="Entfernen">
					<mat-icon svgIcon="trash-can-outline"></mat-icon>
				</button>
				<button mat-icon-button (click)="openEditDialog(item, i)" matTooltip="Bearbeiten">
					<mat-icon svgIcon="pencil"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let row; columns: columns"></tr>
	</table>
</ng-container>
<br /><button mat-button color="primary" (click)="add({ blockKind: null, parameters: [], queryParameters: [] })">Block hinzufügen</button>
