import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromAggregationActions } from './aggregation.actions';
import { AggregationSelector } from './aggregation.selectors';

export interface ITimeSpan {
	from: string;
	until: string;
}

export const thisWeek: ITimeSpan = { from: moment.utc().startOf('week').toISOString(), until: moment.utc().endOf('week').toISOString() };
export const lastWeek: ITimeSpan = { from: moment.utc().subtract(1, 'week').startOf('week').toISOString(), until: moment.utc().subtract(1, 'week').endOf('week').toISOString() };
export const thisMonth: ITimeSpan = { from: moment.utc().startOf('month').toISOString(), until: moment.utc().endOf('month').toISOString() };
export const lastMonth: ITimeSpan = { from: moment.utc().subtract(1, 'month').startOf('month').toISOString(), until: moment.utc().subtract(1, 'month').endOf('month').toISOString() };
export const thisYear: ITimeSpan = { from: moment.utc().startOf('year').toISOString(), until: moment.utc().endOf('year').toISOString() };
export const lastYear: ITimeSpan = { from: moment.utc().subtract(1, 'year').startOf('year').toISOString(), until: moment.utc().subtract(1, 'year').endOf('year').toISOString() };

@Injectable({
	providedIn: 'root',
})
export class AggregationFacade extends BaseFacade {
	constructor(store: Store<AppState>, private aggregationSelector: AggregationSelector) {
		super(store);
	}

	public thisWeek = thisWeek;
	public lastWeek = lastWeek;
	public thisMonth = thisMonth;
	public lastMonth = lastMonth;
	public thisYear = thisYear;
	public lastYear = lastYear;

	public orderCount$ = this.store.select(this.aggregationSelector.orderCount);
	public receiptTotalPrice$ = this.store.select(this.aggregationSelector.receiptTotalPrice);
	public showOrderState$ = this.store.select(this.aggregationSelector.showOrderState);
	public orderStateData$ = this.store.select(this.aggregationSelector.orderStateData);
	public orderStateOptions$ = this.store.select(this.aggregationSelector.orderStateOptions);
	public showReceiptState$ = this.store.select(this.aggregationSelector.showReceiptState);
	public receiptStateData$ = this.store.select(this.aggregationSelector.receiptStateData);
	public receiptStateOptions$ = this.store.select(this.aggregationSelector.receiptStateOptions);
	public timespan$ = this.store.select(this.aggregationSelector.timespan);
	public from$ = this.store.select(this.aggregationSelector.from);
	public until$ = this.store.select(this.aggregationSelector.until);
	public granularity$ = this.store.select(this.aggregationSelector.granularity);
	public isLoading$ = this.store.select(this.aggregationSelector.isLoading);

	public orderCountOfDate(days: number): Observable<number> {
		return this.store.select(this.aggregationSelector.orderCountOfDate(days));
	}

	public receiptTotalPriceOfDate(days: number): Observable<number> {
		return this.store.select(this.aggregationSelector.receiptTotalPriceOfDate(days));
	}

	public loadOrderCount(): void {
		this.store.dispatch(fromAggregationActions.loadOrderCount());
	}

	public loadOrderState(displayName: string, routeName: string): void {
		this.store.dispatch(fromAggregationActions.loadOrderState({ displayName, routeName }));
	}

	public loadReceiptTotalPrice(): void {
		this.store.dispatch(fromAggregationActions.loadReceiptTotalPrice());
	}

	public loadReceiptState(displayName: string, routeName: string): void {
		this.store.dispatch(fromAggregationActions.loadReceiptState({ displayName, routeName }));
	}

	public changeTimespan(timespan: ITimeSpan): void {
		this.store.dispatch(fromAggregationActions.changeTimespan(timespan));
	}

	public changeFrom(from: string): void {
		this.store.dispatch(fromAggregationActions.changeFrom({ from }));
	}

	public changeUntil(until: string): void {
		this.store.dispatch(fromAggregationActions.changeUntil({ until }));
	}

	public changeGranularity(granularity: string): void {
		this.store.dispatch(fromAggregationActions.changeGranularity({ granularity }));
	}
}
