import { EntityState, IShippingState } from '../app.state';
import { IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';

import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { IShipping } from '../../models/shipping.model';
import { EntityReducer } from '../entity.reducer';
import { fromShippingActions } from './shipping.actions';

export const initialValue: IShipping = {
	_id: null,
	shippingKind: null,
	order: null,
	deliveryNote: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IShippingEditForm = {
	_id: null,
	shippingKind: null,
	order: null,
	deliveryNote: null,
	shippingByPartner: null,
	consentEmailTransfer: null,
	shippingWithoutInvoice: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IShippingFilterForm = {
	_id: null,
	createdAt: null,
	shippingKind: box([]),
	order: null,
	deliveryNote: box([]),
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IShipping, IShippingEditForm, IShippingFilterForm>(
	'SHIPPING',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			createdAt: 'GTE',
			shippingKind: 'IN',
			processInstance: {
				state: 'IN',
			},
		},
	],
	{ createdAt: -1 }
);
export const shippingReducer = EntityReducer.create<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm>(
	initialState,
	{},
	fromShippingActions,
	on(fromShippingActions.download, state => ({ ...state, isFetching: true })),
	on(fromShippingActions.downloaded, state => ({ ...state, isFetching: false })),
	on(fromShippingActions.print, state => ({ ...state, isFiltering: true })),
	on(fromShippingActions.printed, state => ({ ...state, isFiltering: false })),
	on(fromShippingActions.changeShippingKind, state => ({ ...state, isFiltering: true })),
	on(fromShippingActions.changedShippingKind, state => ({ ...state, isFiltering: false }))
);
