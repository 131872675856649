import { createAction, props } from '@ngrx/store';
import { IArticleNewspaper, IExpandedArticleNewspaper } from '../../models/article.model';

import { IPaging } from '../../models/article-request';
import { IArticleNewspaperEditForm } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleNewspaperActions = {
	...EntityActions.create<IArticleNewspaper, IArticleNewspaperEditForm>('[Newspaper]'),
	search: createAction(`[Newspaper] Search`, props<{ date: Date }>()),
	searched: createAction(`[Newspaper] Searched`, props<{ date: Date; entities: IExpandedArticleNewspaper[] }>()),
	extended: createAction(`[Newspaper] Extended`),
	pagingChanged: createAction(`[Newspaper] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[Newspaper] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
	updateStoragePlace: createAction(`[Newspaper] Update Storageplace`, props<{ entity: IArticleNewspaper; storagePlaceId: string }>()),
};
