import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { StoragePlaceNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-storage-place.component';
import { IStoragePlace } from '../../models/storage-place.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceNotificationService extends EntityNotificationService<IStoragePlace> {
	protected component: ComponentType<any> = StoragePlaceNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
