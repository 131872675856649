import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageFacade } from 'src/state/message/message.facade';
import { MessageDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-message.component';
import { MessageDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-message.component';
import { MessageDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-message.component';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { IMessageState } from '../../state/app.state';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MessageDialogService extends EntityDialogService<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	protected createComponent: ComponentType<any> = MessageDialogAddComponent;
	protected updateComponent: ComponentType<any> = MessageDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MessageDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: MessageFacade) {
		super(matDialog, entityFacade);
	}
}
