import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChangePaymentKindDialogComponent } from '../../components/dialog/dialog-change-payment-kind.component';
import { PaymentPayDialogComponent } from '../../components/entitydialog/entitydialog-payment-pay.component';
import { PaymentDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-payment.component';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { IPayment, IPaymentCash, IPaymentCashOnDelivery, IPaymentCollectionPurchase, IPaymentDirectDebit, IPaymentPaypal, IPaymentPrepayment, IPaymentPurchaseOnAccount } from '../../models/payment.model';
import { IPaymentState } from '../../state/app.state';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';
import { PaymentBarzahlenDialogService } from './payment.barzahlen.service';
import { PaymentCashOnDeliveryDialogService } from './payment.cash-on-delivery.service';
import { PaymentCashDialogService } from './payment.cash.service';
import { PaymentCollectionPurchaseDialogService } from './payment.collection-purchase.service';
import { PaymentDirectDebitDialogService } from './payment.direct-debit.service';
import { PaymentPaypalDialogService } from './payment.paypal.service';
import { PaymentPrepaymentDialogService } from './payment.prepayment.service';
import { PaymentPurchaseOnAccountDialogService } from './payment.purchase-on-account.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentDialogService extends EntityDialogService<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	protected updateComponent: ComponentType<any> = PaymentDialogChangeComponent;
	protected payComponent: ComponentType<any> = PaymentPayDialogComponent;
	protected changePaymentKindComponent: ComponentType<any> = ChangePaymentKindDialogComponent;

	constructor(
		matDialog: MatDialog,
		private paymentFacade: PaymentFacade,
		private barzahlenDialogService: PaymentBarzahlenDialogService,
		private cashDialogService: PaymentCashDialogService,
		private cashOnDeliveryDialogService: PaymentCashOnDeliveryDialogService,
		private directDebitDialogService: PaymentDirectDebitDialogService,
		private paypalDialogService: PaymentPaypalDialogService,
		private prepaymentDialogService: PaymentPrepaymentDialogService,
		private purchaseOnAccountDialogService: PaymentPurchaseOnAccountDialogService,
		private collectionPurchaseDialogService: PaymentCollectionPurchaseDialogService,
		private orderFacade: OrderFacade
	) {
		super(matDialog, paymentFacade);
	}

	public openChangePaymentKindDialog(data: IEntityDialogData<IPayment>): void {
		const dialogRef = this.openDialog(this.changePaymentKindComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});

		dialogRef.afterClosed().subscribe((result: { entity: IPayment; paymentKind: PaymentKind }) => {
			if (result) {
				this.paymentFacade.changePaymentKind(result.entity, result.paymentKind);
			}
		});
	}

	public openPayDialog(data: IEntityDialogData<IPayment>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.payComponent, {
			width: '600px',
			disableClose: false,
		});
	}

	public openUpdateDialog(data: IEntityDialogData<IPayment>): MatDialogRef<any> {
		switch (data.entity.paymentKind) {
			case PaymentKind.Cash:
				return this.cashDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentCash>);
			case PaymentKind.CashOnDelivery:
				return this.cashOnDeliveryDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentCashOnDelivery>);
			case PaymentKind.DirectDebit:
				return this.directDebitDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentDirectDebit>);
			case PaymentKind.Paypal:
				return this.paypalDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentPaypal>);
			case PaymentKind.Prepayment:
				return this.prepaymentDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentPrepayment>);
			case PaymentKind.PurchaseOnAccount:
				return this.purchaseOnAccountDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentPurchaseOnAccount>);
			case PaymentKind.CollectionPurchase:
				return this.collectionPurchaseDialogService.openUpdateDialog(data as IEntityDialogData<IPaymentCollectionPurchase>);
		}
	}
}
