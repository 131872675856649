import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm } from '../../models/article.model';
import { AppState, IArticleChronicleState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleChronicleActions } from './article.chronicle.actions';
import { initialEditFormValue } from './article.chronicle.reducer';
import { ArticleChronicleSelector } from './article.chronicle.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleChronicleFacade extends EntityFacade<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleChronicleActions;

	constructor(store: Store<AppState>, private chronicleSelector: ArticleChronicleSelector) {
		super(store, chronicleSelector);
	}

	public isSearching$ = this.store.select(this.chronicleSelector.isSearching);
	public isExtending$ = this.store.select(this.chronicleSelector.isExtending);
	public itemsSearched$ = this.store.select(this.chronicleSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.chronicleSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleChronicleActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
