import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { PartnerApiService } from '../../services/api/partner.service';
import { PartnerDialogService } from '../../services/dialog/partner.service';
import { PartnerNotificationService } from '../../services/notification/partner.service';
import { AppState, IPartnerState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPartnerActions } from './partner.actions';
import { PartnerSelector } from './partner.selectors';

@Injectable({
	providedIn: 'root',
})
export class PartnerEffects extends EntityEffects<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: PartnerApiService, notificationService: PartnerNotificationService, dialogService: PartnerDialogService, selector: PartnerSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPartnerActions, 'PARTNER');
	}
}
