import { Directive } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';
import { IEntity, IEntityEditForm, IEntityFilterForm } from '../../models/entity.model';
import { CommentDialogComponent } from '../dialog/dialog-comment.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

export interface IValueChange {
	index: number;
	attributeName?: string;
	value: any;
}

@Directive()
export abstract class HistoryDialogTableComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TTableElement,
	TOriginalElement,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends EntityDialogTableComponent<TEntity, TEntityState, TTableElement, TOriginalElement, TEntityEditForm, TEntityFilterForm> {
	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>, public matDialog: MatDialog) {
		super(entityFacade);
	}

	public openCommentDialog(comment: string, index: number): void {
		const dialog = this.matDialog.open<CommentDialogComponent, string, { comment: string }>(CommentDialogComponent, {
			width: '500px',
			data: comment,
		});

		dialog
			.afterClosed()
			.pipe(filter(result => !!result))
			.subscribe(result => this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, attributeName: 'comment', value: result.comment })]));
	}
}
