import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { FormGroupState } from 'ngrx-forms';
import { BehaviorSubject, combineLatest, debounceTime, filter, map } from 'rxjs';
import { SessionFacade } from 'src/state/session/session.facade';
import { DeviceFacade } from '../../state/device/device.facade';
import { PageComponent } from '../page.component';

export enum LoginStateEnum {
	AUTHENTICATE_DEVICE = 'AUTHENTICATE_DEVICE',
	USER_INPUT = 'USER_INPUT',
	REGISTER_DEVICE = 'REGISTER_DEVICE',
	AUTHENTICATE_SIMPLE = 'AUTHENTICATE_SIMPLE',
}

@Component({
	selector: 'babylon-loginpage',
	templateUrl: './loginpage.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./loginpage.component.scss'],
})
export class LoginpageComponent extends PageComponent implements OnInit {
	public pageTitle$ = new BehaviorSubject('Login');
	public loginStates = LoginStateEnum;
	public loginState$ = new BehaviorSubject<LoginStateEnum>(LoginStateEnum.AUTHENTICATE_DEVICE);

	constructor(titleService: Title, public sessionFacade: SessionFacade, public deviceFacade: DeviceFacade) {
		super(titleService);
		this.sessionFacade.reset();
		this.deviceFacade.reset();
	}

	public ngOnInit(): void {
		combineLatest([this.loginState$, this.sessionFacade.userForm$.pipe(debounceTime(200))])
			.pipe(
				map(([loginState, userForm]) => {
					switch (loginState) {
						case LoginStateEnum.AUTHENTICATE_DEVICE:
							this.deviceFacade.loadDevicesForMachine();
							break;
						case LoginStateEnum.REGISTER_DEVICE:
							if (userForm.isValid) {
								this.deviceFacade.loadDeviceForUser(userForm.value.email);
							}

							break;
					}
				})
			)
			.subscribe();

		combineLatest([this.deviceFacade.userRegistered$])
			.pipe(
				filter(([userRegistered]) => userRegistered),
				map(() => this.loginState$.next(LoginStateEnum.AUTHENTICATE_DEVICE))
			)
			.subscribe();
	}

	public getFormValidationErrors(form: FormGroupState<any>): string[] {
		const errors: string[] = [];

		Object.keys(form.controls).forEach(key => {
			const controlErrors: ValidationErrors = form.controls[key].errors;

			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					errors.push(controlErrors[keyError]);
				});
			}
		});

		return errors;
	}
}
