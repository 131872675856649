import { createAction, props } from '@ngrx/store';
import { IUser, IUserDevice } from 'src/models/user.model';
import { IServiceError } from '../../services/api/api.service';

const stateName = '[Device]';

export const fromDeviceActions = {
	machineIdCalculated: createAction(`${stateName} Machine Id Calculated`, props<{ machineId: string }>()),
	loadDevicesForMachine: createAction(`${stateName} Load Devices For Machine`),
	loadedDevicesForMachine: createAction(`${stateName} Loaded Devices For Machine`, props<{ userDevices: IUserDevice[] }>()),
	loadDeviceForUser: createAction(`${stateName} Load Devices For User`, props<{ email: string }>()),
	loadedDeviceForUser: createAction(`${stateName} Loaded Devices For User`, props<{ userDevice: IUserDevice }>()),
	register: createAction(`${stateName} Register`, props<{ userDevice: IUserDevice }>()),
	registered: createAction(`${stateName} Registered`),
	authenticate: createAction(`${stateName} Authenticate`, props<{ userDevice: IUserDevice }>()),
	authenticated: createAction(`${stateName} Authenticated`, props<{ authToken: string; user: IUser }>()),
	reset: createAction(`${stateName} Reset`),
	failed: createAction(`${stateName} Failed`, props<{ error: IServiceError }>()),
};
