import { Injectable } from '@angular/core';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { AppState, IPageState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class PageSelector extends EntitySelector<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	protected stateSelector = (state: AppState): IPageState => state.page;
}
