import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ShippingApiService } from 'src/services/api/shipping.service';
import { PaymentFacade } from 'src/state/payment/payment.facade';
import { ShippingFacade } from 'src/state/shipping/shipping.facade';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { IShipping } from '../../models/shipping.model';
import { ShippingDialogService } from '../../services/dialog/shipping.service';
import { IShippingState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-shippingpage',
	templateUrl: './entitypage-shipping.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class ShippingPageComponent extends EntityPageComponent<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	public pageTitle$ = new BehaviorSubject('Lieferungen');
	public columns = ['receiver', 'shippingKind', 'order', 'createdAt', 'assignee', 'state', 'filter'];
	public shippingKinds = ShippingKind;

	protected shouldDoInitialFilter = false;
	protected shouldDoRegularFilter = 60;

	constructor(
		titleService: Title,
		route: ActivatedRoute,
		private shippingDialogService: ShippingDialogService,
		public apiService: ShippingApiService,
		public entityFacade: ShippingFacade,
		public orderFacade: OrderFacade,
		public customerFacade: CustomerFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public articleFacade: ArticleFacade,
		public receiptFacade: ReceiptFacade,
		public productFacade: ProductFacade,
		public shippingFacade: ShippingFacade,
		public paymentFacade: PaymentFacade,
		public processFacade: ProcessFacade,
		public routerFacade: RouterFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public router: Router
	) {
		super(titleService, shippingDialogService, route, entityFacade);

		combineLatest([this.routerFacade.shippingStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([states, processNodes]) => processNodes.filter(processNode => states.some(state => state.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					const processNodeNames = processNodes.map(processNode => processNode.name);

					if (processNodes.length == 0 || processNodeNames.indexOf('ShippingAborted') > -1 || processNodeNames.indexOf('ShippingShipped') > -1) {
						this.entityFacade.changeSorting({ createdAt: -1 });
					} else {
						this.entityFacade.changeSorting({ createdAt: 1 });
					}

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}

	public showPrepareDialog(entity: IShipping, width: string = null): void {
		this.shippingDialogService.openPrepareDialog({ entity, width });
	}

	public showCommissionDialog(entity: IShipping, width: string = null): void {
		this.shippingDialogService.openCommissionDialog({ entity, width });
	}
}
