import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPartnerPrice } from '../../models/product.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-partner-pricedialog',
	templateUrl: './dialog-partner-price.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PartnerPriceDialogComponent extends DialogComponent<PartnerPriceDialogComponent, IPartnerPrice> {
	constructor(matDialogRef: MatDialogRef<PartnerPriceDialogComponent, IPartnerPrice>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPartnerPrice) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				partner: [data.partner, [Validators.required]],
				price: [data.price, []],
			})
		);
	}
}
