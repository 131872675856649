import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IPublisher } from 'src/models/publisher.model';
import { IServiceError } from '../../services/api/api.service';
import { EntityNotificationErrorComponent } from './entitynotificationerror.component';

@Component({
	selector: 'babylon-publishernotificationerror',
	templateUrl: './entitynotificationerror-publisher.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitynotificationerror.component.scss'],
})
export class PublisherNotificationErrorComponent extends EntityNotificationErrorComponent<IPublisher> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
