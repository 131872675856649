import { Injectable } from '@angular/core';
import { IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm } from '../../models/shipping.model';
import { AppState, IShippingPickupState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class PickupSelector extends EntitySelector<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	protected stateSelector = (state: AppState): IShippingPickupState => state.shippingPickup;
}
