import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAttachment } from 'src/models/attachment.model';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AttachmentApiService extends EntityApiService<IAttachment> {
	protected entityName = 'attachments';

	constructor(http: HttpClient) {
		super(http);
	}

	public createAttachment(entity: IAttachment, file: File, authToken: string): Observable<IEntitySingleResponse<IAttachment>> {
		let formData = new FormData();
		formData.set('title', entity.title);
		formData.append('file', file);

		return this.put<IEntitySingleResponse<IAttachment>, FormData>([this.entityName, 'attachment'], formData, this.getHeaders(authToken));
	}

	public updateAttachment(entity: IAttachment, file: File, authToken: string): Observable<IEntitySingleResponse<IAttachment>> {
		let formData = new FormData();
		formData.set('title', entity.title);
		formData.append('file', file);

		return this.patch<IEntitySingleResponse<IAttachment>, FormData>([this.entityName, 'attachment', entity._id], formData, this.getHeaders(authToken));
	}
}
