<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="updatedAt-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="updatedAt">Änderungsdatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="updatedAt-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="updatedAt-content">
							<td mat-cell *matCellDef="let entity">{{ entity.updatedAt | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm (dd)' }}</td>
						</ng-container>

						<ng-container matColumnDef="order-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="order">Bestellung</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<babylon-order-autocompletesingle [value]="filterForm.controls[0].controls.order.value" [controlId]="filterForm.controls[0].controls.order.id"></babylon-order-autocompletesingle>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
									<a [routerLink]="['/entity', 'order', 'change', order._id]">{{ order.number }}</a>
								</ng-container>
							</td>
						</ng-container>

						<ng-container matColumnDef="dueAmount-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="dueAmount">Betrag</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="dueAmount-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="dueAmount-content">
							<td mat-cell *matCellDef="let entity">{{ entity.dueAmount | currency }}</td>
						</ng-container>

						<ng-container matColumnDef="paypalorder-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="paypalOrder.id">Bestellnummer</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="paypalorder-filter">
							<th mat-header-cell *matHeaderCellDef>
								<mat-form-field appearance="outline" class="filter">
									<mat-label>Bestellnummer</mat-label>
									<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.paypalOrder.controls.id" />
								</mat-form-field>
								<babylon-filteroperator
									[entityFacade]="entityFacade"
									[availableOptions]="['EQ', 'NE', 'CONTAINS', 'STARTSWITH']"
									[value]="filterOperatorForm.controls[0].controls.paypalOrder.controls.id.value"
									[controlId]="filterOperatorForm.controls[0].controls.paypalOrder.controls.id.id"></babylon-filteroperator>
							</th>
						</ng-container>
						<ng-container matColumnDef="paypalorder-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.paypalOrder?.id }}
							</td>
						</ng-container>

						<ng-container matColumnDef="paypaltransaction-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="paypalTransaction.id">Transaktionsnummer</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="paypaltransaction-filter">
							<th mat-header-cell *matHeaderCellDef>
								<mat-form-field appearance="outline" class="filter">
									<mat-label>Transaktionsnummer</mat-label>
									<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.paypalTransaction.controls.id" />
								</mat-form-field>
								<babylon-filteroperator
									[entityFacade]="entityFacade"
									[availableOptions]="['EQ', 'NE', 'CONTAINS', 'STARTSWITH']"
									[value]="filterOperatorForm.controls[0].controls.paypalTransaction.controls.id.value"
									[controlId]="filterOperatorForm.controls[0].controls.paypalTransaction.controls.id.id"></babylon-filteroperator>
							</th>
						</ng-container>
						<ng-container matColumnDef="paypaltransaction-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.paypalTransaction?.id }}
							</td>
						</ng-container>

						<ng-container matColumnDef="assignee-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Bearbeiter</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="assignee-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="assignee-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-profile-picture [userId]="entity.processInstance.currentUser"></babylon-profile-picture>
							</td>
						</ng-container>

						<ng-container matColumnDef="state-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="state">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-state [entity]="entity" [facade]="entityFacade"></babylon-process-state>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
									<button mat-icon-button (click)="showPayDialog(entity)" matTooltip="Zahlungseingang" *ngIf="processNode.name == 'PaymentAssigned'">
										<mat-icon svgIcon="cash"></mat-icon>
									</button>
								</ng-container>
								<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
									<mat-icon svgIcon="pencil"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
