<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lastschrifteinzug hinzufügen</h1>
	<p>Der Lastschrifteinzug kann nur erzeugt werden, falls zum Zeitpunkt der Erzeugung ausstehende Lastschrifteinzüge gefunden werden können. Diese werden dem neuen Lastschrifteinzug automatisch hinzugefügt.</p>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
