<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">CD hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-label>Publizierungsdatum</mat-label>
					<input matInput [matDatepicker]="publicationDate" [ngrxFormControlState]="editForm.controls.publicationDate" [ngrxValueConverter]="dateToISOStringConverter" />
					<mat-datepicker-toggle matSuffix [for]="publicationDate"></mat-datepicker-toggle>
					<mat-datepicker #publicationDate></mat-datepicker>
				</mat-form-field>
				<babylon-media-product-autocompletesingle [value]="editForm.controls.product.value" [controlId]="editForm.controls.product.id"></babylon-media-product-autocompletesingle>
				<babylon-storage-place-autocompletesingle [value]="editForm.controls.storagePlace.value" [controlId]="editForm.controls.storagePlace.id"></babylon-storage-place-autocompletesingle>
				<babylon-media-stock-autocompletesingle [value]="editForm.controls.stock.value" [controlId]="editForm.controls.stock.id"></babylon-media-stock-autocompletesingle>
				<mat-form-field appearance="outline">
					<mat-label>Qualität</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.quality">
						<mat-option [value]="5">Sehr gut</mat-option>
						<mat-option [value]="4">Gut</mat-option>
						<mat-option [value]="3">Befriedigend</mat-option>
						<mat-option [value]="2">Ausreichend</mat-option>
						<mat-option [value]="1">Mangelhaft</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
