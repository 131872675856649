import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IDocument } from 'src/models/document.model';
import { DocumentDialogService } from 'src/services/dialog/document.service';
import { IDocumentState } from 'src/state/app.state';
import { DocumentFacade } from 'src/state/document/document.facade';
import { IDocumentEditForm, IDocumentFilterForm } from '../../models/document.model';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-documentpage',
	templateUrl: './entitypage-document.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class DocumentPageComponent extends EntityPageComponent<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	public pageTitle$ = new BehaviorSubject('Dokumente');
	public columns = ['title', 'file', 'filter'];

	constructor(titleService: Title, documentDialogService: DocumentDialogService, route: ActivatedRoute, entityFacade: DocumentFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(titleService, documentDialogService, route, entityFacade);
	}
}
