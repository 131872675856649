import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleBirthdayBook, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm } from '../../models/article.model';
import { AppState, IArticleBirthdayBookState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayBookSelector extends EntitySelector<IArticleBirthdayBook, IArticleBirthdayBookState, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm> {
	protected stateSelector = (state: AppState): IArticleBirthdayBookState => state.articleBirthdayBook;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
	public dayRange = createSelector(this.selectState, state => state.dayRange);
}
