import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StockTypeEnum } from '../../models/enums/stock-type.enum';
import { IStockMedia } from '../../models/stock.model';
import { IStockMediaState } from '../../state/app.state';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-mediastockdialogadd',
	templateUrl: './entitydialogadd-media-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class MediaStockDialogAddComponent extends EntityDialogAddComponent<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	public StockTypes = StockTypeEnum;

	constructor(entityFacade: MediaStockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
