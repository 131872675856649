import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { IProcessRoute } from '../../models/process-route.model';
import { AppState, IProcessRouteState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';

@Injectable({
	providedIn: 'root',
})
export class ProcessRouteSelector extends EntitySelector<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	protected stateSelector = (state: AppState): IProcessRouteState => state.processRoute;

	public orderedByHappyPath = createSelector(this.list, list => _.orderBy(list, ['isHappyPath'], ['asc']));
	public findBySourceNode = (sourceNodeId: string) => createSelector(this.orderedByHappyPath, list => list.filter(route => route?.sourceNode === sourceNodeId));
}
