import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IReceiptTypeInformation } from '../../models/configuration.model';
import { ReceiptKind } from '../../models/enums/receipt-kind.enum';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-receipt-typedialog',
	templateUrl: './dialog-receipt-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ReceiptTypeDialogComponent extends DialogComponent<ReceiptTypeDialogComponent, IReceiptTypeInformation> {
	public ReceiptKinds = ReceiptKind;

	constructor(matDialogRef: MatDialogRef<ReceiptTypeDialogComponent, IReceiptTypeInformation>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IReceiptTypeInformation) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				receiptKind: [data.receiptKind, [Validators.required]],
				dueDays: [data.dueDays, [Validators.required]],
				reminderDays: [data.reminderDays, [Validators.required]],
				pdfText: [data.pdfText, [Validators.required]],
			})
		);
	}
}
