import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStoragePlace } from '../../models/storage-place.model';
import { AppState, IStoragePlaceState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { fromStoragePlaceActions } from './storage-place.actions';
import { initialEditFormValue } from './storage-place.reducer';
import { StoragePlaceSelector } from './storage-place.selectors';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceFacade extends EntityFacade<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromStoragePlaceActions;

	constructor(store: Store<AppState>, storagePlaceSelector: StoragePlaceSelector) {
		super(store, storagePlaceSelector);
	}
}
