import { EntityState, IDeliveryNoteState } from '../app.state';
import { IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from './../../models/delivery-note';

import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { IDeliveryNote } from '../../models/delivery-note';
import { EntityReducer } from '../entity.reducer';
import { fromDeliveryNoteActions } from './delivery-note.actions';

export const initialValue: IDeliveryNote = {
	_id: null,
	biller: {
		companyName: null,
		accountOwner: null,
		name: null,
		nameInAddress: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		bankAccountNumber: null,
		bankCode: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
	},
	receiver: {
		salutationKind: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		addressKind: null,
		email: null,
		telephone: null,
	},
	positions: [],
	deliveryNoteKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IDeliveryNoteEditForm = {
	_id: null,
	biller: {
		companyName: null,
		accountOwner: null,
		name: null,
		nameInAddress: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		bankAccountNumber: null,
		bankCode: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
	},
	receiver: {
		salutationKind: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		addressKind: null,
		email: null,
		telephone: null,
	},
	positions: [
		{
			_id: null,
			positionKind: null,
			title: null,
			price: null,
			taxRate: null,
			units: null,
			paymentKind: null,
			shippingKind: null,
			article: {
				_id: null,
				publicationDate: null,
				articleKind: null,
				processInstance: {
					actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
					state: {
						name: null,
						displayName: null,
						version: null,
					},
					priority: null,
				},
				product: {
					name: null,
					productKind: null,
					articleKind: null,
					price: null,
					taxRate: null,
				},
			},
			bundle: {
				_id: null,
				name: null,
				price: null,
				components: [
					{
						articleKind: null,
						discount: null,
						isPrincipal: null,
					},
				],
			},
			articles: [
				{
					_id: null,
					publicationDate: null,
					articleKind: null,
					processInstance: {
						actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
						state: {
							name: null,
							displayName: null,
							version: null,
						},
						priority: null,
					},
					product: {
						name: null,
						productKind: null,
						articleKind: null,
						price: null,
						taxRate: null,
					},
				},
			],
		},
	],
	deliveryNoteKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IDeliveryNoteFilterForm = {
	_id: null,
	createdAt: null,
	biller: {
		companyName: null,
		accountOwner: null,
		name: null,
		nameInAddress: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		bankAccountNumber: null,
		bankCode: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
	},
	receiver: {
		salutationKind: null,
		address: {
			city: null,
			country: null,
			postCode: null,
			street: null,
			streetNumber: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		addressKind: null,
		email: null,
		telephone: null,
	},
	positions: [],
	deliveryNoteKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm>(
	'DELIVERYNOTE',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			deliveryNoteKind: 'IN',
		},
	],
	{ number: -1 }
);

export const deliveryNoteReducer = EntityReducer.create<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm>(
	initialState,
	{},
	fromDeliveryNoteActions,
	on(fromDeliveryNoteActions.download, state => ({ ...state, isFetching: true })),
	on(fromDeliveryNoteActions.downloaded, state => ({ ...state, isFetching: false }))
);
