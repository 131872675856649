import { EntityActions } from '../entity.actions';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { IMailTemplateEditForm } from './../../models/mailtemplate.model';
import { createAction } from '@ngrx/store';

export const fromMailTemplateActions = {
	...EntityActions.create<IMailTemplate, IMailTemplateEditForm>('[MailTemplate]'),
	test: createAction(`[MailTemplate] Test`),
	tested: createAction(`[MailTemplate] Tested`),
	testSent: createAction(`[MailTemplate] Sent`),
};
