import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-user.component';
import { UserDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-user.component';
import { UserDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-user.component';
import { IUser, IUserFilterForm } from 'src/models/user.model';
import { IUserState } from 'src/state/app.state';
import { UserFacade } from 'src/state/user/user.facade';
import { IUserEditForm } from './../../models/user.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class UserDialogService extends EntityDialogService<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	protected createComponent: ComponentType<any> = UserDialogAddComponent;
	protected updateComponent: ComponentType<any> = UserDialogChangeComponent;
	protected removeComponent: ComponentType<any> = UserDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: UserFacade) {
		super(matDialog, entityFacade);
	}
}
