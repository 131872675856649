import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { IAuthorizationRequest } from 'src/models/authorization.model';
import { SessionFacade } from 'src/state/session/session.facade';
import { ApiService, IResponse } from '../api/api.service';
import { IAuthenticationResponse } from './user.service';

@Injectable({
	providedIn: 'root',
})
export class AuthorizationService extends ApiService implements CanActivate {
	constructor(http: HttpClient, private sessionFacade: SessionFacade) {
		super(http);
	}

	public login(email: string, password: string): Observable<IAuthenticationResponse> {
		return this.post<IAuthenticationResponse, IAuthorizationRequest>(['users', 'login'], {
			email,
			password,
		});
	}

	public logout(): Observable<IResponse> {
		return this.post<IResponse, any>(['users', 'logout'], null);
	}

	public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const isValid = this.isTokenValid(this.sessionFacade.authToken$.value);

		if (!isValid) {
			this.sessionFacade.logout();
		}

		return isValid;
	}

	private isTokenValid(authToken: string): boolean {
		if (authToken == null) {
			return false;
		}

		var jwt = jwt_decode(authToken) as any;
		const now = moment.utc().add(1, 'minute');
		const issuedAt = moment.utc(jwt.iat * 1000);
		const expiry = moment.utc(jwt.exp * 1000);
		const isValid = now.isBetween(issuedAt, expiry, null, '[]');

		return isValid;
	}
}
