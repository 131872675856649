import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPublisher } from 'src/models/publisher.model';
import { IPublisherState } from 'src/state/app.state';
import { PublisherFacade } from 'src/state/publisher/publisher.facade';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-publisherdialogchange',
	templateUrl: './entitydialogchange-publisher.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PublisherDialogChangeComponent extends EntityDialogChangeComponent<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	constructor(entityFacade: PublisherFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
