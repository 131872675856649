import { box } from 'ngrx-forms';
import { IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentCashState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCashActions } from './payment.cash.actions';

export const initialValue: IPaymentCash = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentCashEditForm = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentCashFilterForm = {
	_id: null,
	createdAt: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm>('CASH', initialValue, initialEditFormValue, initialFilterFormValue);
export const cashReducer = EntityReducer.create<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm>(initialState, {}, fromCashActions);
