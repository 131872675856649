import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigurationDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-configuration.component';
import { IConfiguration } from 'src/models/configuration.model';
import { IConfigurationState } from 'src/state/app.state';
import { ConfigurationFacade } from 'src/state/configuration/configuration.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationDialogService extends EntityDialogService<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	protected updateComponent: ComponentType<any> = ConfigurationDialogChangeComponent;

	constructor(matDialog: MatDialog, entityFacade: ConfigurationFacade) {
		super(matDialog, entityFacade);
	}
}
