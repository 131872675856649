import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteReturnState } from '../../state/app.state';
import { DeliveryNoteReturnFacade } from '../../state/delivery-note.return/delivery-note.return.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-delivery-note-returndialogadd',
	templateUrl: './entitydialogadd-delivery-note-return.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class DeliveryNoteReturnDialogAddComponent extends EntityDialogAddComponent<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	constructor(entityFacade: DeliveryNoteReturnFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
