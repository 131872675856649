import { EntityState, IArticleState } from '../app.state';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';

import { box } from 'ngrx-forms';
import { IArticle } from '../../models/article.model';
import { EntityReducer } from '../entity.reducer';
import { fromArticleActions } from './article.actions';

export const initialValue: IArticle = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	order: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialEditFormValue: IArticleEditForm = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	order: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialFilterFormValue: IArticleFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	publicationDate: null,
	quality: box([]),
	storagePlace: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	articleKind: box([]),
	stock: null,
};

export const initialState = EntityState.create<IArticle, IArticleEditForm, IArticleFilterForm>(
	'ARTICLE',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			processInstance: {
				state: 'IN',
				currentUser: 'IN',
			},
			quality: 'IN',
			articleKind: 'IN',
		},
	],
	{
		'processInstance.priority': -1,
	}
);

export const articleReducer = EntityReducer.create<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm>(initialState, {}, fromArticleActions);
