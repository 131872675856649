import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDeliveryNote } from '../../models/delivery-note';
import { DeliveryNoteKind } from '../../models/enums/delivery-note-kind.enum';
import { DeliveryNoteOrderApiService } from './delivery-note.order.service';
import { DeliveryNoteReturnApiService } from './delivery-note.return.service';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteApiService extends EntityApiService<IDeliveryNote> {
	protected entityName = 'delivery-notes';

	constructor(http: HttpClient, private deliveryNoteOrderService: DeliveryNoteOrderApiService, private deliveryNoteReturnService: DeliveryNoteReturnApiService) {
		super(http);
	}

	public print(authToken: string, entity: IDeliveryNote): Observable<string> {
		switch (entity.deliveryNoteKind) {
			case DeliveryNoteKind.DeliveryNoteOrder:
				return this.deliveryNoteOrderService.print(authToken, entity);
			case DeliveryNoteKind.DeliveryNoteReturn:
				return this.deliveryNoteReturnService.print(authToken, entity);
		}
	}

	public download(authToken: string, entity: IDeliveryNote): Observable<string> {
		switch (entity.deliveryNoteKind) {
			case DeliveryNoteKind.DeliveryNoteOrder:
				return this.deliveryNoteOrderService.download(authToken, entity);
			case DeliveryNoteKind.DeliveryNoteReturn:
				return this.deliveryNoteReturnService.download(authToken, entity);
		}
	}
}
