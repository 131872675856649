import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { IMailTemplateState } from 'src/state/app.state';
import { MailTemplateFacade } from 'src/state/mailtemplate/mailtemplate.facade';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-mailtemplatedialogchange',
	templateUrl: './entitydialogchange-mailtemplate.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class MailTemplateDialogChangeComponent extends EntityDialogChangeComponent<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm> {
	public showHtmlTemplate: boolean = false;
	public showTestData: boolean = false;

	constructor(entityFacade: MailTemplateFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
