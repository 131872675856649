import { IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPurchaseOnAccount } from '../../models/payment.model';
import { IPaymentPurchaseOnAccountState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PurchaseOnAccountFacade } from '../../state/payment.purchase-on-account/payment.purchase-on-account.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-purchase-on-accountdialogchange',
	templateUrl: './entitydialogchange-purchase-on-account.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PurchaseOnAccountDialogChangeComponent extends EntityDialogChangeComponent<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountState, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {
	constructor(entityFacade: PurchaseOnAccountFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
