import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { AppState, ITextTemplateState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromTextTemplateActions } from './text-template.actions';
import { initialEditFormValue } from './text-template.reducer';
import { TextTemplateSelector } from './text-template.selectors';

@Injectable({
	providedIn: 'root',
})
export class TextTemplateFacade extends EntityFacade<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromTextTemplateActions;

	constructor(store: Store<AppState>, publicationCycleSelector: TextTemplateSelector) {
		super(store, publicationCycleSelector);
	}
}
