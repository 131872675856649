import { Injectable } from '@angular/core';
import { IMediaProduct } from '../../models/media-product.model';
import { AppState, IMediaProductState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';

@Injectable({
	providedIn: 'root',
})
export class MediaProductSelector extends EntitySelector<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	protected stateSelector = (state: AppState): IMediaProductState => state.mediaProduct;
}
