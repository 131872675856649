import { createAction, props } from '@ngrx/store';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { EntityActions } from '../entity.actions';

export const fromAccountingSummaryActions = {
	...EntityActions.create<IAccountingSummary<IAccountingSummaryPosition>, IAccountingSummaryEditForm<IAccountingSummaryPosition>>('[AccountingSummary]'),
	downloadCsv: createAction(`[AccountingSummary] Download CSV`, props<{ entity: IAccountingSummary<IAccountingSummaryPosition> }>()),
	downloadedCsv: createAction(`[AccountingSummary] Downloaded CSV`, props<{ entity: IAccountingSummary<IAccountingSummaryPosition>; data: any }>()),
	downloadXml: createAction(`[AccountingSummary] Download XML`, props<{ entity: IAccountingSummary<IAccountingSummaryPosition> }>()),
	downloadedXml: createAction(`[AccountingSummary] Downloaded XML`, props<{ entity: IAccountingSummary<IAccountingSummaryPosition>; data: any }>()),
};
