import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class DatabaseApiService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	public reset(authToken: string, commit: string): Observable<any> {
		return this.post<any, { commit: string }>(['patch', 'database', '?ticketNumber=0'], { commit }, this.getHeaders(authToken)).pipe(map(response => response.data));
	}
}
