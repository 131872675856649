import { AppState, IReceiptState } from '../app.state';
import { IReceipt, IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ReceiptSelector extends EntitySelector<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	protected stateSelector = (state: AppState): IReceiptState => state.receipt;

	private invoiceState = createSelector(
		(state: AppState) => state.receiptInvoice,
		state => state
	);
	private cancellationState = createSelector(
		(state: AppState) => state.receiptCancellation,
		state => state
	);
	private correctionState = createSelector(
		(state: AppState) => state.receiptCorrection,
		state => state
	);

	private allStates = createSelector(this.selectState, this.invoiceState, this.cancellationState, this.correctionState, (...states) => states);

	public isFiltering = createSelector(this.allStates, states => states.some(state => state.isFiltering));
	public isSuggesting = createSelector(this.allStates, states => states.some(state => state.isSuggesting));
	public isFetching = createSelector(this.allStates, states => states.some(state => state.isFetching));
}
