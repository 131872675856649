import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReceiptDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-receipt.component';
import { ReceiptDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-receipt.component';
import { ReceiptDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-receipt.component';
import { ReceiptReceiverDialogComponent } from '../../components/dialog/dialog-receipt-receiver.component';
import { ReceiptKind } from '../../models/enums/receipt-kind.enum';
import { IReceipt, IReceiptCancellation, IReceiptCorrection, IReceiptInvoice } from '../../models/receipt';
import { IReceiptState } from '../../state/app.state';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';
import { ReceiptCancellationDialogService } from './receipt.cancellation.service';
import { ReceiptCorrectionDialogService } from './receipt.correction.service';
import { ReceiptInvoiceDialogService } from './receipt.invoice.service';

@Injectable({
	providedIn: 'root',
})
export class ReceiptDialogService extends EntityDialogService<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	protected createComponent: ComponentType<any> = ReceiptDialogAddComponent;
	protected updateComponent: ComponentType<any> = ReceiptDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ReceiptDialogDeleteComponent;
	protected editReceiverComponent: ComponentType<any> = ReceiptReceiverDialogComponent;

	constructor(
		matDialog: MatDialog,
		entityFacade: ReceiptFacade,
		private cancellationDialogService: ReceiptCancellationDialogService,
		private correctionDialogService: ReceiptCorrectionDialogService,
		private invoiceDialogService: ReceiptInvoiceDialogService
	) {
		super(matDialog, entityFacade);
	}

	public openUpdateDialog(data: IEntityDialogData<IReceipt>): MatDialogRef<any> {
		switch (data.entity.receiptKind) {
			case ReceiptKind.Cancellation:
				return this.cancellationDialogService.openUpdateDialog(data as IEntityDialogData<IReceiptCancellation>);
			case ReceiptKind.Correction:
				return this.correctionDialogService.openUpdateDialog(data as IEntityDialogData<IReceiptCorrection>);
			case ReceiptKind.Invoice:
				return this.invoiceDialogService.openUpdateDialog(data as IEntityDialogData<IReceiptInvoice>);
		}
	}

	public openEditReceiverDialog(): MatDialogRef<any> {
		return this.openDialog(this.editReceiverComponent, {
			disableClose: false,
			minWidth: '600px',
		});
	}
}
