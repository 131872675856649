import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { AppState, IDeliveryNoteOrderState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDeliveryNoteOrderActions } from './delivery-note.order.actions';
import { initialEditFormValue } from './delivery-note.order.reducer';
import { DeliveryNoteOrderSelector } from './delivery-note.order.selectors';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteOrderFacade extends EntityFacade<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDeliveryNoteOrderActions;

	constructor(store: Store<AppState>, deliveryNoteSelector: DeliveryNoteOrderSelector) {
		super(store, deliveryNoteSelector);
	}

	public download(deliveryNote: IDeliveryNoteOrder): void {
		this.store.dispatch(this.actions.download({ deliveryNote }));
	}
}
