import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm } from '../../models/payment.model';
import { AppState, IPaymentCashState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCashActions } from './payment.cash.actions';
import { initialEditFormValue } from './payment.cash.reducer';
import { CashSelector } from './payment.cash.selectors';

@Injectable({
	providedIn: 'root',
})
export class CashFacade extends EntityFacade<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCashActions;

	constructor(store: Store<AppState>, entitySelector: CashSelector) {
		super(store, entitySelector);
	}
}
