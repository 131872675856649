import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IBundleState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-bundledialogadd',
	templateUrl: './entitydialogadd-bundle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class BundleDialogAddComponent extends EntityDialogAddComponent<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	public SalutationKinds = SalutationKind;
	public PaymentKinds = PaymentKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: BundleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
