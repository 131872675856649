import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-permission.component';
import { PermissionDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-permission.component';
import { PermissionDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-permission.component';
import { IPermissionState } from '../../state/app.state';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { IPermission, IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionDialogService extends EntityDialogService<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	protected createComponent: ComponentType<any> = PermissionDialogAddComponent;
	protected updateComponent: ComponentType<any> = PermissionDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PermissionDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PermissionFacade) {
		super(matDialog, entityFacade);
	}
}
