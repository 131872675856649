import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEntity } from 'src/models/entity.model';
import { IProcessRoute } from '../../models/process-route.model';
import { IUser } from '../../models/user.model';
import { IProcessNodeCount } from '../../state/app.state';
import { ApiService, IDataResponse, IResponse } from './api.service';

export interface IQueryOptions<TEntity> {
	count?: boolean;
	limit?: number;
	sort?: any;
	skip?: number;
}

export interface IEntityRequest<TEntity> {
	query: any;
	queryOptions?: IQueryOptions<TEntity>;
}

export interface IEntitySingleResponse<TEntity extends IEntity> extends IDataResponse<TEntity> {}

export interface IEntityListResponse<TEntity extends IEntity> extends IDataResponse<TEntity[]> {}

@Injectable({
	providedIn: 'root',
})
export abstract class EntityApiService<TEntity extends IEntity> extends ApiService {
	protected abstract entityName: string;

	constructor(http: HttpClient) {
		super(http);
	}

	public find(id: string, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		return this.get<IEntitySingleResponse<TEntity>>([this.entityName, id], this.getHeaders(authToken));
	}

	public filter(request: IEntityRequest<TEntity>, authToken: string): Observable<IEntityListResponse<TEntity>> {
		return this.post<IEntityListResponse<TEntity>, IEntityRequest<TEntity>>([this.entityName], request, this.getHeaders(authToken));
	}

	public count(authToken: string): Observable<IDataResponse<IProcessNodeCount[]>> {
		return this.get<IDataResponse<IProcessNodeCount[]>>([this.entityName, 'count'], this.getHeaders(authToken));
	}

	public create(entity: TEntity, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		return this.put<IEntitySingleResponse<TEntity>, TEntity>([this.entityName], entity, this.getHeaders(authToken));
	}

	public update(entity: TEntity, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		return this.patch<IEntitySingleResponse<TEntity>, TEntity>([this.entityName, entity._id], entity, this.getHeaders(authToken));
	}

	public remove(entity: TEntity, authToken: string): Observable<IResponse> {
		return this.delete<IEntitySingleResponse<TEntity>>([this.entityName, entity._id], this.getHeaders(authToken));
	}

	public changeProcess(entity: IEntity, processRoute: IProcessRoute, comment: string, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		return this.post<IEntitySingleResponse<TEntity>, { entity: IEntity; comment: string }>([this.entityName, entity._id, 'process', processRoute.name], { entity, comment }, this.getHeaders(authToken));
	}

	public revertProcess(entity: IEntity, comment: string, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		return this.post<IEntitySingleResponse<TEntity>, { comment: string }>([this.entityName, entity._id, 'process', 'revert'], { comment }, this.getHeaders(authToken));
	}

	public updateAssignee(entity: IEntity, user: IUser, authToken: string): Observable<IEntitySingleResponse<TEntity>> {
		if (user == null) {
			return this.get<IEntitySingleResponse<TEntity>>([this.entityName, entity._id, 'process', 'unassign'], this.getHeaders(authToken));
		} else {
			return this.get<IEntitySingleResponse<TEntity>>([this.entityName, entity._id, 'process', 'assign', user._id], this.getHeaders(authToken));
		}
	}
}
