import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from '../../models/payment.model';
import { PaymentCashOnDeliveryApiService } from '../../services/api/payment.cash-on-delivery.service';
import { PaymentCashOnDeliveryDialogService } from '../../services/dialog/payment.cash-on-delivery.service';
import { CashOnDeliveryNotificationService } from '../../services/notification/payment.cash-on-delivery.service';
import { AppState, IPaymentCashOnDeliveryState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCashOnDeliveryActions } from './payment.cash-on-delivery.actions';
import { CashOnDeliverySelector } from './payment.cash-on-delivery.selectors';

@Injectable({
	providedIn: 'root',
})
export class CashOnDeliveryEffects extends EntityEffects<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PaymentCashOnDeliveryApiService,
		notificationService: CashOnDeliveryNotificationService,
		dialogService: PaymentCashOnDeliveryDialogService,
		selector: CashOnDeliverySelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCashOnDeliveryActions, 'CASHONDELIVERY');
	}
}
