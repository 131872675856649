import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleBook, IArticleBookEditForm, IBookFilterForm } from '../../models/article.model';
import { AppState, IArticleBookState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleBookActions } from './article.book.actions';
import { initialEditFormValue } from './article.book.reducer';
import { ArticleBookSelector } from './article.book.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleBookFacade extends EntityFacade<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleBookActions;

	constructor(store: Store<AppState>, private bookSelector: ArticleBookSelector) {
		super(store, bookSelector);
	}

	public isSearching$ = this.store.select(this.bookSelector.isSearching);
	public isExtending$ = this.store.select(this.bookSelector.isExtending);
	public itemsSearched$ = this.store.select(this.bookSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.bookSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleBookActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
