import { Injectable } from '@angular/core';
import { ICustomer } from '../../models/customer.model';
import { AppState, ICustomerState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';

@Injectable({
	providedIn: 'root',
})
export class CustomerSelector extends EntitySelector<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	protected stateSelector = (state: AppState): ICustomerState => state.customer;
}
