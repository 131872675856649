import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm } from '../../models/shipping.model';
import { AppState, IShippingDhlState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDhlActions } from './shipping.dhl.actions';
import { initialEditFormValue } from './shipping.dhl.reducer';
import { DhlSelector } from './shipping.dhl.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlFacade extends EntityFacade<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDhlActions;

	constructor(store: Store<AppState>, DhlSelector: DhlSelector) {
		super(store, DhlSelector);
	}
}
