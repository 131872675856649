import { map, Observable } from 'rxjs';
import { IMediaArticleRequest, IPaging } from '../../models/article-request';
import { IArticleNewspaper, IExpandedArticleNewspaper } from '../../models/article.model';
import { EntityApiService, IEntityListResponse, IEntitySingleResponse } from './entity.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INewspaperRating } from '../../models/article-rating';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperApiService extends EntityApiService<IArticleNewspaper> {
	protected entityName = 'newspapers';

	constructor(http: HttpClient) {
		super(http);
	}

	public search(request: IMediaArticleRequest, paging?: IPaging): Observable<IExpandedArticleNewspaper[]> {
		const route = [this.entityName, 'search'];

		if (paging != null) {
			route.push(`?skip=${paging.skip}&top=${paging.top}`);
		}

		return this.post<IEntityListResponse<INewspaperRating>, IMediaArticleRequest>(route, request).pipe(map(ratings => ratings.data.map(rating => rating.article)));
	}

	public updateStoragePlace(entity: IArticleNewspaper, storagePlaceId: string, authToken: string): Observable<IEntitySingleResponse<IArticleNewspaper>> {
		return this.get<IEntitySingleResponse<IArticleNewspaper>>([this.entityName, entity._id, 'storageplace', storagePlaceId], this.getHeaders(authToken));
	}
}
