import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IProcess } from '../../models/process';
import { IProcessNode } from '../../models/process-node.model';
import { FilterConnection, IFilterDescriptor, IProcessNodeState, SortDescriptors } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-process-node-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-process-node.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class ProcessNodeAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	public placeholder = 'Status';
	public columns: string[] = ['name', 'delete'];
	@Input() public process: IProcess;

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [{ attributeName: 'process', operator: 'EQ', value: this.process._id }];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'displayName', operator: 'STARTSWITH', value }];
	protected override sortDescriptors: () => SortDescriptors<IProcessNode> = () => ({ displayName: 1 });

	constructor(entityFacade: ProcessNodeFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IProcessNode): string {
		return entity != null ? entity.displayName : null;
	}
}
