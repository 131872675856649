import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { MarkAsTouchedAction, ResetAction, SetValueAction } from 'ngrx-forms';
import { catchError, debounceTime, map, of, switchMap, tap } from 'rxjs';
import { AddressFormType, ArticleKind, IArticleCertificate, IArticleCertificateEditForm, IArticleCertificateFilterForm, IExpandedArticleCertificate } from '../../models/article.model';
import { AppState, IArticleCertificateState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleCertificateApiService } from '../../services/api/article.certificate.service';
import { ArticleCertificateDialogService } from '../../services/dialog/article.certificate.service';
import { ArticleCertificateNotificationService } from '../../services/notification/article.certificate.service';
import { constructFormGroupValue } from '../../types/form';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleCertificateActions } from './article.certificate.actions';
import { initialExpandedEditFormValue } from './article.certificate.reducer';
import { ArticleCertificateSelector } from './article.certificate.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleCertificateEffects extends EntityEffects<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private certificateService: ArticleCertificateApiService,
		notificationService: ArticleCertificateNotificationService,
		dialogService: ArticleCertificateDialogService,
		private selector: ArticleCertificateSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, certificateService, notificationService, dialogService, selector, sessionSelector, fromArticleCertificateActions, 'CERTIFICATE');
	}

	public onPrint$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.print),
			debounceTime(500),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ certificate }, authToken]) =>
				this.certificateService.print(authToken, certificate).pipe(
					map(() => fromArticleCertificateActions.printed()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ certificate }, authToken]) =>
				this.certificateService.download(authToken, certificate).pipe(
					map(data => fromArticleCertificateActions.downloaded({ certificate, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromArticleCertificateActions.downloaded),
				tap(({ certificate, data }) => saveAs(data, `Geschenkurkunde-${certificate._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.search),
			debounceTime(10),
			switchMap(() =>
				this.certificateService.search({ articleKind: ArticleKind.Certificate }).pipe(
					map(data => fromArticleCertificateActions.searched({ entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleCertificateActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onSearched$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.searched),
			map(({ entities }) => entities[0]),
			map(certificate => fromArticleCertificateActions.updateExpandedForm({ entity: certificate }))
		)
	);

	public onUpdateExpandedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.updateExpandedForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => constructFormGroupValue<IExpandedArticleCertificate>(entity, initialExpandedEditFormValue)),
			switchMap(entity => [new ResetAction('CERTIFICATE_EXPANDED_EDIT'), new MarkAsTouchedAction('CERTIFICATE_EXPANDED_EDIT'), new SetValueAction('CERTIFICATE_EXPANDED_EDIT', entity)])
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);

	public onExpandedFormValueChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.expandedFormValueChanged),
			switchMap(({ changes }) => changes.map(change => new SetValueAction(`CERTIFICATE_EXPANDED_EDIT.${change.attributeName}`, change.value)))
		)
	);

	public onChangeTextForAddressForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.addressFormChanged),
			map(({ addressForm }) => {
				if (addressForm == AddressFormType.Personal) {
					return fromArticleCertificateActions.formValueChanged({
						changes: [{ attributeName: 'text', value: 'Dein Geburtstag war<br />ein besonderer Tag.<br /><br />Was sonst noch<br />in der Welt geschah,<br />liest Du in der Zeitung<br />vom Tag Deiner Geburt.<br /><br />' }],
					});
				} else {
					return fromArticleCertificateActions.formValueChanged({
						changes: [{ attributeName: 'text', value: 'Ihr Geburtstag war<br />ein besonderer Tag.<br /><br />Was sonst noch<br />in der Welt geschah,<br />lesen Sie in der Zeitung<br />vom Tag Ihrer Geburt.<br /><br />' }],
					});
				}
			})
		)
	);

	public onChangeExpandedTextForAddressForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCertificateActions.addressFormChanged),
			map(({ addressForm }) => {
				if (addressForm == AddressFormType.Personal) {
					return fromArticleCertificateActions.expandedFormValueChanged({
						changes: [{ attributeName: 'text', value: 'Dein Geburtstag war<br />ein besonderer Tag.<br /><br />Was sonst noch<br />in der Welt geschah,<br />liest Du in der Zeitung<br />vom Tag Deiner Geburt.<br /><br />' }],
					});
				} else {
					return fromArticleCertificateActions.expandedFormValueChanged({
						changes: [{ attributeName: 'text', value: 'Ihr Geburtstag war<br />ein besonderer Tag.<br /><br />Was sonst noch<br />in der Welt geschah,<br />lesen Sie in der Zeitung<br />vom Tag Ihrer Geburt.<br /><br />' }],
					});
				}
			})
		)
	);
}
