import { ICounter } from 'src/models/counter.model';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { EntityState, ICounterState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCounterActions } from './counter.actions';

export const initialValue: ICounter = {
	_id: null,
	type: null,
	value: null,
};

export const initialEditFormValue: ICounterEditForm = {
	_id: null,
	type: null,
	value: null,
};

export const initialFilterFormValue: ICounterFilterForm = {
	_id: null,
	type: null,
	value: null,
};

export const initialState = EntityState.create<ICounter, ICounterEditForm, ICounterFilterForm>('COUNTER', initialValue, initialEditFormValue, initialFilterFormValue);
export const counterReducer = EntityReducer.create<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm>(initialState, {}, fromCounterActions);
