import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { DeliveryNoteReturnApiService } from '../../services/api/delivery-note.return.service';
import { DeliveryNoteReturnDialogService } from '../../services/dialog/delivery-note.return.service';
import { DeliveryNoteReturnNotificationService } from '../../services/notification/delivery-note.return.service';
import { AppState, IDeliveryNoteReturnState } from '../app.state';
import { fromDeliveryNoteActions } from '../delivery-note/delivery-note.actions';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDeliveryNoteReturnActions } from './delivery-note.return.actions';
import { DeliveryNoteReturnSelector } from './delivery-note.return.selectors';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnEffects extends EntityEffects<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private deliveryNoteService: DeliveryNoteReturnApiService,
		notificationService: DeliveryNoteReturnNotificationService,
		dialogService: DeliveryNoteReturnDialogService,
		selector: DeliveryNoteReturnSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, deliveryNoteService, notificationService, dialogService, selector, sessionSelector, fromDeliveryNoteReturnActions, 'DELIVERYNOTERETURN');
	}

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteReturnActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ deliveryNote }, authToken]) =>
				this.deliveryNoteService.download(authToken, deliveryNote).pipe(
					map(data => fromDeliveryNoteReturnActions.downloaded({ deliveryNote, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromDeliveryNoteReturnActions.downloaded),
				tap(({ deliveryNote, data }) => saveAs(data, `Retourenschein-${deliveryNote._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onFetchedDiscriminator$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteReturnActions.fetched),
			map(({ entities }) => fromDeliveryNoteActions.fetched({ entities }))
		)
	);
}
