<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="image-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="image">Bild</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="image-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="image-content">
							<td mat-cell *matCellDef="let entity" width="80">
								<babylon-picture [image]="entity" [file]="uploadedFileFacade.fetchOne(entity.file) | async" [width]="40"></babylon-picture>
							</td>
						</ng-container>

						<ng-container matColumnDef="title-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="title">Titel</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="title-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Titel</mat-label>
										<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.title" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['EQ', 'NE', 'STARTSWITH', 'CONTAINS', 'NOTCONTAINS']"
										[value]="filterOperatorForm.controls[0].controls.title.value"
										[controlId]="filterOperatorForm.controls[0].controls.title.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="title-content">
							<td mat-cell *matCellDef="let entity">{{ entity.title }}</td>
						</ng-container>

						<ng-container matColumnDef="altText-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="altText">Alternativtext</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="altText-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Alternativtext</mat-label>
										<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.altText" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['EQ', 'NE', 'STARTSWITH', 'CONTAINS', 'NOTCONTAINS']"
										[value]="filterOperatorForm.controls[0].controls.altText.value"
										[controlId]="filterOperatorForm.controls[0].controls.altText.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="altText-content">
							<td mat-cell *matCellDef="let entity">{{ entity.altText }}</td>
						</ng-container>

						<ng-container matColumnDef="file-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="file">Datei</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="file-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="file-content">
							<td mat-cell *matCellDef="let entity" width="80">
								<a [href]="file.url" target="_blank" *ngIf="uploadedFileFacade.fetchOne(entity.file) | async as file">Original anzeigen</a>
								<babylon-fetch [facade]="uploadedFileFacade" [value]="entity.file"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
									<mat-icon svgIcon="pencil"></mat-icon>
								</button>

								<button mat-icon-button (click)="remove(entity)" matTooltip="Löschen">
									<mat-icon svgIcon="trash-can-outline"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<button mat-flat-button color="accent" (click)="create()">
								<mat-icon svgIcon="plus"></mat-icon>
								Hinzufügen
							</button>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
