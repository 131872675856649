import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DhlPremiumNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-dhl-premium.component';
import { IShippingDhlPremium } from '../../models/shipping.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DhlPremiumNotificationService extends EntityNotificationService<IShippingDhlPremium> {
	protected component: ComponentType<any> = DhlPremiumNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
