import { createAction, props } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { EntityActions } from '../entity.actions';
import { IRegionEditForm } from './../../models/region.model';

export const fromRegionActions = {
	...EntityActions.create<IRegion, IRegionEditForm>('[Region]'),
	nameChanged: createAction(`[Region] Name Changed`, props<{ name: string }>()),
	search: createAction(`[Region] Search`),
	searched: createAction(`[Region] Searched`, props<{ entities: IRegion[] }>()),
};
