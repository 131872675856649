import { createAction, props } from '@ngrx/store';
import { IDocument } from 'src/models/document.model';
import { IDocumentEditForm } from '../../models/document.model';
import { EntityActions } from '../entity.actions';

export const fromDocumentActions = {
	...EntityActions.create<IDocument, IDocumentEditForm>('[Document]'),
	createdDocumentForm: createAction(`[Document] Created Document Form`, props<{ entity: IDocument; file: File }>()),
	updatedDocumentForm: createAction(`[Document] Updated Document Form`, props<{ entity: IDocument; file: File }>()),
};
