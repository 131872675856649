import { IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPaypal } from '../../models/payment.model';
import { IPaymentPaypalState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaypalFacade } from '../../state/payment.paypal/payment.paypal.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-paypaldialogchange',
	templateUrl: './entitydialogchange-paypal.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PaypalDialogChangeComponent extends EntityDialogChangeComponent<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	constructor(entityFacade: PaypalFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
