import { Injectable } from '@angular/core';
import { IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm } from '../../models/payment.model';
import { AppState, IPaymentCashState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CashSelector extends EntitySelector<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	protected stateSelector = (state: AppState): IPaymentCashState => state.paymentCash;
}
