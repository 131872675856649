import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IMediaProductState } from 'src/state/app.state';
import { IMediaProduct } from '../../models/media-product.model';
import { MediaProductDialogService } from '../../services/dialog/media-product.service';
import { ImageFacade } from '../../state/image/image.facade';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-media-productpage',
	templateUrl: './entitypage-media-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class MediaProductPageComponent extends EntityPageComponent<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	public pageTitle$ = new BehaviorSubject('Medien-Produkte');
	public columns = ['name', 'mediaBrand', 'region', 'price', 'image', 'logo', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: MediaProductDialogService,
		route: ActivatedRoute,
		entityFacade: MediaProductFacade,
		public mediaBrandFacade: MediaBrandFacade,
		public regionFacade: RegionFacade,
		public publicationCycleFacade: PublicationCycleFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
