import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IConfiguration } from 'src/models/configuration.model';
import { ConfigurationDialogService } from 'src/services/dialog/configuration.service';
import { IConfigurationState } from 'src/state/app.state';
import { ConfigurationFacade } from 'src/state/configuration/configuration.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-configurationpage',
	templateUrl: './entitypage-configuration.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class ConfigurationPageComponent extends EntityPageComponent<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	public pageTitle$ = new BehaviorSubject('Konfiguration');
	public columns = ['brandName', 'filter'];

	constructor(titleService: Title, dialogService: ConfigurationDialogService, route: ActivatedRoute, entityFacade: ConfigurationFacade) {
		super(titleService, dialogService, route, entityFacade);
	}
}
