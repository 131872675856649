import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleBirthdayChronicle } from '../../models/article.model';
import { IArticleBirthdayChronicleState } from '../../state/app.state';
import { ArticleBirthdayChronicleFacade } from '../../state/article.birthday-chronicle/article.birthday-chronicle.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-birthday-chronicledialogchange',
	templateUrl: './entitydialogchange-birthday-chronicle.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class BirthdayChronicleDialogChangeComponent extends EntityDialogChangeComponent<IArticleBirthdayChronicle, IArticleBirthdayChronicleState, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm> {
	constructor(entityFacade: ArticleBirthdayChronicleFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
