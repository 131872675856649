import { createAction, props } from '@ngrx/store';
import { IArticleMagazine, IExpandedArticleMagazine } from '../../models/article.model';

import { IPaging } from '../../models/article-request';
import { IArticleMagazineEditForm } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleMagazineActions = {
	...EntityActions.create<IArticleMagazine, IArticleMagazineEditForm>('[Magazine]'),
	search: createAction(`[Magazine] Search`, props<{ date: Date }>()),
	searched: createAction(`[Magazine] Searched`, props<{ date: Date; entities: IExpandedArticleMagazine[] }>()),
	extended: createAction(`[Magazine] Extended`),
	pagingChanged: createAction(`[Magazine] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[Magazine] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
	updateStoragePlace: createAction(`[Magazine] Update Storageplace`, props<{ entity: IArticleMagazine; storagePlaceId: string }>()),
};
