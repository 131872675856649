import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MagazineAddDialogComponent } from '../../components/entitydialog/entitydialog-magazine-add.component';
import { MagazineReplaceDialogComponent } from '../../components/entitydialog/entitydialog-magazine-replace.component';
import { MagazineDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-magazine.component';
import { MagazineDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-magazine.component';
import { MagazineDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-magazine.component';
import { IArticleMagazine, IArticleMagazineEditForm, IArticleMagazineFilterForm } from '../../models/article.model';
import { IArticleMagazineState } from '../../state/app.state';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleMagazineDialogService extends EntityDialogService<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	protected createComponent: ComponentType<any> = MagazineDialogAddComponent;
	protected updateComponent: ComponentType<any> = MagazineDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MagazineDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = MagazineReplaceDialogComponent;
	protected addComponent: ComponentType<any> = MagazineAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleMagazineFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleMagazine>): MatDialogRef<any> {
		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleMagazine>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '800px',
			data,
		});
	}
}
