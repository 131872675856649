import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, filter, first, Subject, tap } from 'rxjs';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { FilterConnection, IEntityState, IFilterDescriptor, SortDescriptors } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';

@Directive()
@UntilDestroy()
export abstract class EntityDropdownSingleComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> implements OnInit, OnChanges, ControlValueAccessor
{
	@Input() public abstract placeholder: string;
	@Input() public optional: boolean = false;
	@Input() public readonly: boolean = false;
	@Input() public value: string;
	@Input() public controlId: string;
	@Output() public selected = new EventEmitter<string>();

	protected abstract filterConnection: () => FilterConnection;
	protected abstract filterDescriptorsStatic: () => IFilterDescriptor[];
	protected abstract filterDescriptors: (value: string) => IFilterDescriptor[];
	protected abstract sortDescriptors: () => SortDescriptors<TEntity>;

	public onChange = (_: string) => {};
	public onTouched = () => {};

	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>) {}

	public ngOnInit(): void {
		this.entityFacade.suggest(this.filterConnection(), [...this.filterDescriptorsStatic(), ...this.filterDescriptors('')], this.sortDescriptors());
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.value != null && changes.value.currentValue !== changes.value.previousValue) {
			this.handleValue(changes.value.currentValue);
		}
	}

	public select(entity: TEntity): void {
		const selectedEntityId = entity ? entity._id : null;

		this.entityFacade.suggest('AND', [{ attributeName: '_id', operator: 'EQ', value: selectedEntityId }], this.sortDescriptors());
		this.selected.emit(selectedEntityId);

		if (this.controlId != null) {
			this.entityFacade.changeGlobalForm({ controlId: this.controlId, value: selectedEntityId });
		}

		this.onTouched();
		this.onChange(selectedEntityId);
	}

	public writeValue(value: string) {
		this.handleValue(value);
	}

	public registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	public registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	public markAsTouched() {}

	public setDisabledState(readonly: boolean) {
		this.readonly = readonly;
	}

	private handleValue(value: string): void {
		if (value != null) {
			this.entityFacade.suggest('AND', [...this.filterDescriptorsStatic(), { attributeName: '_id', operator: 'EQ', value: value }], {});
		} else {
			this.entityFacade.suggest('AND', this.filterDescriptorsStatic(), this.sortDescriptors());
		}
	}
}
