import { createAction, props } from '@ngrx/store';

import { IPayment } from '../../models/payment.model';
import { EntityActions } from '../entity.actions';
import { IPaymentEditForm } from './../../models/payment.model';

export const fromPaymentActions = {
	...EntityActions.create<IPayment, IPaymentEditForm>('[Payment]'),
	changePaymentKind: createAction(`[Payment] Change Payment Kind`, props<{ payment: IPayment; paymentKind: string }>()),
	changedPaymentKind: createAction(`[Payment] Changed Payment Kind`, props<{ payment: IPayment }>()),
};
