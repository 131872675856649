import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PickupStationType } from '../../models/configuration.model';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IShippingPickup } from '../../models/shipping.model';
import { IShippingPickupState } from '../../state/app.state';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';
import { PickupFacade } from '../../state/shipping.pickup/shipping.pickup.facade';
import { IShippingPickupEditForm, IShippingPickupFilterForm } from './../../models/shipping.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-pickupdialogchange',
	templateUrl: './entitydialogchange-pickup.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PickupDialogChangeComponent extends EntityDialogChangeComponent<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	public SalutationKinds = SalutationKind;
	public AddressKinds = AddressKind;
	public PickupStationTypes = PickupStationType;

	constructor(entityFacade: PickupFacade, matDialogRef: MatDialogRef<any>, private configurationFacade: ConfigurationFacade) {
		super(entityFacade, matDialogRef);
	}

	public changePickupStationType(pickupStationType: PickupStationType): void {
		const pickupStation = this.configurationFacade.single$.value.shippingInformation.pickupStations.find(pickupStation => pickupStation.type == pickupStationType);
		this.entityFacade.changeFormValue({ attributeName: 'pickupStation.address', value: pickupStation.address });
	}
}
