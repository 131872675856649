import { box } from 'ngrx-forms';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { EntityState, IShippingDhlExpressState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromDhlExpressActions } from './shipping.dhl-express.actions';

export const initialValue: IShippingDhlExpress = {
	_id: null,
	order: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	labelBase64: null,
	deliveryNote: null,
	dhlExpressOrder: {
		shipmentTrackingNumber: null,
		labelBase64: null,
	},
};

export const initialEditFormValue: IShippingDhlExpressEditForm = {
	_id: null,
	order: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	shipmentTrackingNumber: null,
	labelBase64: null,
	deliveryNote: null,
	dhlExpressOrder: {
		shipmentTrackingNumber: null,
		labelBase64: null,
	},
};

export const initialFilterFormValue: IShippingDhlExpressFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	shippingKind: null,
	processInstance: null,
	shipmentTrackingNumber: null,
	labelBase64: null,
	deliveryNote: box([]),
};

export const initialState = EntityState.create<IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm>('DHLEXPRESS', initialValue, initialEditFormValue, initialFilterFormValue);
export const dhlExpressReducer = EntityReducer.create<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm>(initialState, {}, fromDhlExpressActions);
