import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IMediaBrand } from '../../models/media-brand.model';
import { MediaBrandApiService } from '../../services/api/media-brand.service';
import { MediaBrandDialogService } from '../../services/dialog/media-brand.service';
import { MediaBrandNotificationService } from '../../services/notification/media-brand.service';
import { AppState, IMediaBrandState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { fromMediaBrandActions } from './media-brand.actions';
import { MediaBrandSelector } from './media-brand.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandEffects extends EntityEffects<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: MediaBrandApiService,
		notificationService: MediaBrandNotificationService,
		dialogService: MediaBrandDialogService,
		selector: MediaBrandSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromMediaBrandActions, 'MEDIABRAND');
	}
}
