import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IReceiptInvoice } from '../../models/receipt';
import { IReceiptInvoiceState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { InvoiceFacade } from '../../state/receipt.invoice/receipt.invoice.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm } from './../../models/receipt';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-invoicedialogchange',
	templateUrl: './entitydialogchange-invoice.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class InvoiceDialogChangeComponent extends EntityDialogChangeComponent<IReceiptInvoice, IReceiptInvoiceState, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {
	public addressKinds = Object.values(AddressKind);
	public salutationKinds = Object.values(SalutationKind);

	public paymentColumns = ['paidDate', 'dueAmount', 'paymentKind', 'state', 'actions'];
	public positionColumns = ['image', 'title', 'price', 'positionKind'];

	constructor(
		entityFacade: InvoiceFacade,
		matDialogRef: MatDialogRef<any>,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public paymentFacade: PaymentFacade,
		public customerFacade: CustomerFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(entityFacade, matDialogRef);
	}
}
