import { map, Observable } from 'rxjs';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IContactInfo } from '../../models/contact-details.model';
import { ReceiptKind } from '../../models/enums/receipt-kind.enum';
import { IProcessRoute } from '../../models/process-route.model';
import { IReceipt } from './../../models/receipt';
import { IResponse } from './api.service';
import { ReceiptCancellationApiService } from './receipt.cancellation.service';
import { ReceiptCorrectionApiService } from './receipt.correction.service';
import { ReceiptInvoiceApiService } from './receipt.invoice.service';

@Injectable({
	providedIn: 'root',
})
export class ReceiptApiService extends EntityApiService<IReceipt> {
	protected entityName = 'receipts';

	constructor(http: HttpClient, private cancellationService: ReceiptCancellationApiService, private correctionService: ReceiptCorrectionApiService, private invoiceService: ReceiptInvoiceApiService) {
		super(http);
	}

	public changeProcess(entity: IReceipt, processRoute: IProcessRoute, comment: string, authToken: string): Observable<IEntitySingleResponse<IReceipt>> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				return this.cancellationService.changeProcess(entity, processRoute, comment, authToken);
			case ReceiptKind.Correction:
				return this.correctionService.changeProcess(entity, processRoute, comment, authToken);
			case ReceiptKind.Invoice:
				return this.invoiceService.changeProcess(entity, processRoute, comment, authToken);
		}
	}

	public revertProcess(entity: IReceipt, comment: string, authToken: string): Observable<IEntitySingleResponse<IReceipt>> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				return this.cancellationService.revertProcess(entity, comment, authToken);
			case ReceiptKind.Correction:
				return this.correctionService.revertProcess(entity, comment, authToken);
			case ReceiptKind.Invoice:
				return this.invoiceService.revertProcess(entity, comment, authToken);
		}
	}

	public download(authToken: string, entity: IReceipt): Observable<string> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				return this.cancellationService.download(authToken, entity);
			case ReceiptKind.Correction:
				return this.correctionService.download(authToken, entity);
			case ReceiptKind.Invoice:
				return this.invoiceService.download(authToken, entity);
		}
	}

	public downloadFirstReminder(authToken: string, entity: IReceipt): Observable<string> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				throw new Error('Method not implemented.');
			case ReceiptKind.Correction:
				return this.correctionService.downloadFirstReminder(authToken, entity);
			case ReceiptKind.Invoice:
				return this.invoiceService.downloadFirstReminder(authToken, entity);
		}
	}

	public downloadSecondReminder(authToken: string, entity: IReceipt): Observable<string> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				throw new Error('Method not implemented.');
			case ReceiptKind.Correction:
				return this.correctionService.downloadSecondReminder(authToken, entity);
			case ReceiptKind.Invoice:
				return this.invoiceService.downloadSecondReminder(authToken, entity);
		}
	}

	public print(authToken: string, entity: IReceipt): Observable<string> {
		switch (entity.receiptKind) {
			case ReceiptKind.Cancellation:
				return this.cancellationService.print(authToken, entity);
			case ReceiptKind.Correction:
				return this.correctionService.print(authToken, entity);
			case ReceiptKind.Invoice:
				return this.invoiceService.print(authToken, entity);
		}
	}

	public updateReceiver(receiptId: string, receiver: IContactInfo, authToken: string): Observable<IReceipt> {
		const route = [this.entityName, receiptId, 'update-receiver'];

		return this.post<IEntitySingleResponse<IReceipt>, IContactInfo>(route, receiver, this.getHeaders(authToken)).pipe(map(response => response.data));
	}

	public followUp(authToken: string): Observable<IResponse> {
		const route = [this.entityName, 'follow-up'];

		return this.post<IResponse, {}>(route, {}, this.getHeaders(authToken)).pipe(map(response => response));
	}
}
