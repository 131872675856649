<div class="progress-wrapper">
	<mat-form-field appearance="outline" class="filter">
		<mat-label>{{ placeholder }}</mat-label>
		<mat-select [disabled]="readonly" (selectionChange)="select($event.value)">
			<mat-option *ngFor="let entity of entityFacade.list$ | async" [value]="entity">
				{{ entity.title }}
			</mat-option>
		</mat-select>
		<mat-icon matSuffix (click)="select(null)" *ngIf="optional && (value != null || value === undefined)" svgIcon="close" matTooltip="Auswahl entfernen"></mat-icon>
	</mat-form-field>
	<mat-progress-bar mode="indeterminate" *ngIf="entityFacade.isSuggesting$ | async" [@progressFade]></mat-progress-bar>
</div>
