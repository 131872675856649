import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IBundlePrice, IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { BundlePriceDialogComponent } from '../dialog/dialog-bundle-price.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-bundle-pricestable',
	templateUrl: './entitydialogtable-bundle-prices.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class BundlePricesTableComponent extends EntityDialogTableComponent<IPartner, IPartnerState, IBundlePrice, IBundlePrice, IPartnerEditForm, IPartnerFilterForm> {
	public columns = ['bundle', 'price', 'edit'];

	constructor(entityFacade: PartnerFacade, public bundleFacade: BundleFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IPartner): IBundlePrice[] {
		return entity.bundlePrices;
	}

	protected createElement(): IBundlePrice {
		return {
			bundle: null,
			price: null,
		};
	}

	public openEditDialog(data: IBundlePrice, index: number): void {
		const dialogRef = this.matDialog.open(BundlePriceDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IBundlePrice) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
