import { getSelectors, RouterReducerState } from '@ngrx/router-store';

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class RouterSelector extends BaseSelector<RouterReducerState> {
	protected stateSelector = (state: AppState): RouterReducerState => state.router;

	public url = getSelectors(this.selectState).selectUrl;
	public routeData = getSelectors(this.selectState).selectRouteData;
	public routeParams = getSelectors(this.selectState).selectRouteParams;
	public queryParams = getSelectors(this.selectState).selectQueryParams;

	public orderId = createSelector(this.routeParams, routeParams => routeParams.orderId as string);
	public orderStates = createSelector(this.routeParams, routeParams => (routeParams.orderStates == null ? [] : (routeParams.orderStates.split(',') as string[])));
	public articleStates = createSelector(this.routeParams, routeParams => (routeParams.articleStates == null ? [] : (routeParams.articleStates.split(',') as string[])));
	public shippingStates = createSelector(this.routeParams, routeParams => (routeParams.shippingStates == null ? [] : (routeParams.shippingStates.split(',') as string[])));
	public paymentStates = createSelector(this.routeParams, routeParams => (routeParams.paymentStates == null ? [] : (routeParams.paymentStates.split(',') as string[])));
	public previousRoute = createSelector(this.queryParams, queryParams => queryParams.previousRoute);
	public receiptStates = createSelector(this.routeParams, routeParams => (routeParams.receiptStates == null ? [] : (routeParams.receiptStates.split(',') as string[])));
	public accountingSummaryStates = createSelector(this.routeParams, routeParams => (routeParams.accountingSummaryStates == null ? [] : (routeParams.accountingSummaryStates.split(',') as string[])));
	public directDebitTransferStates = createSelector(this.routeParams, routeParams => (routeParams.directDebitTransferStates == null ? [] : (routeParams.directDebitTransferStates.split(',') as string[])));
	public collectionPurchaseTransferStates = createSelector(this.routeParams, routeParams => (routeParams.collectionPurchaseTransferStates == null ? [] : (routeParams.collectionPurchaseTransferStates.split(',') as string[])));
	public stockStates = createSelector(this.routeParams, routeParams => (routeParams.stockStates == null ? [] : (routeParams.stockStates.split(',') as string[])));
}
