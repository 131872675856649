import { Injectable } from '@angular/core';
import { IStoragePlace } from '../../models/storage-place.model';
import { AppState, IStoragePlaceState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceSelector extends EntitySelector<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	protected stateSelector = (state: AppState): IStoragePlaceState => state.storagePlace;
}
