import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentDirectDebit, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm } from '../../models/payment.model';
import { PaymentDirectDebitApiService } from '../../services/api/payment.direct-debit.service';
import { PaymentDirectDebitDialogService } from '../../services/dialog/payment.direct-debit.service';
import { DirectDebitNotificationService } from '../../services/notification/payment.direct-debit.service';
import { AppState, IPaymentDirectDebitState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDirectDebitActions } from './payment.direct-debit.actions';
import { DirectDebitSelector } from './payment.direct-debit.selectors';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitEffects extends EntityEffects<IPaymentDirectDebit, IPaymentDirectDebitState, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PaymentDirectDebitApiService,
		notificationService: DirectDebitNotificationService,
		dialogService: PaymentDirectDebitDialogService,
		selector: DirectDebitSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDirectDebitActions, 'DIRECTDEBIT');
	}
}
