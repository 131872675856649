import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { timer } from 'rxjs';

import { ArticleFacade } from 'src/state/article/article.facade';
import { environment } from '../../environments/environment';
import { DatabaseDialogService } from '../../services/dialog/database.service';
import { OrderDialogService } from '../../services/dialog/order.service';
import { AccountingSummaryFacade } from '../../state/accounting-summary/accounting-summary.facade';
import { AggregationFacade } from '../../state/aggregation/aggregation.facade';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { DirectDebitTransferFacade } from '../../state/direct-debit-transfer/direct-debit-transfer.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { PredictionFacade } from '../../state/prediction/prediction.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { SessionFacade } from '../../state/session/session.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { StatisticsFacade } from '../../state/statistics/statistics.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { UserFacade } from '../../state/user/user.facade';
import { UploadedFileFacade } from './../../state/uploadedfile/uploadedfile.facade';

@Component({
	selector: 'side-nav',
	styleUrls: ['./sidenav.component.scss'],
	templateUrl: './sidenav.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavComponent {
	public isProduction = environment.production;
	@Input() public isExpanded: boolean;
	@Output() public toggleMenu = new EventEmitter();

	constructor(
		public sessionFacade: SessionFacade,
		public statisticsFacade: StatisticsFacade,
		public userFacade: UserFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade,
		public accountingSummaryFacade: AccountingSummaryFacade,
		public directDebitTransferFacade: DirectDebitTransferFacade,
		public collectionPurchaseTransferFacade: CollectionPurchaseTransferFacade,
		public processNodeFacade: ProcessNodeFacade,
		public articleFacade: ArticleFacade,
		public orderFacade: OrderFacade,
		public paymentFacade: PaymentFacade,
		public shippingFacade: ShippingFacade,
		public receiptFacade: ReceiptFacade,
		public permissionFacade: PermissionFacade,
		public mediaStockFacade: MediaStockFacade,
		public accessoryStockFacade: AccessoryStockFacade,
		public aggregationFacade: AggregationFacade,
		public predictionFacade: PredictionFacade,
		public orderDialogService: OrderDialogService,
		public databaseDialogService: DatabaseDialogService
	) {
		timer(0, 60000).subscribe(() => statisticsFacade.count());
	}
}
