import { createReducer, on } from '@ngrx/store';
import { IPredictionState } from '../app.state';
import { fromPredictionActions } from './prediction.actions';

const initialState: IPredictionState = {
	receiptTotalPrice: null,
	error: null,
	isLoading: false,
};

export const predictionReducer = createReducer(
	initialState,
	on(fromPredictionActions.loadReceiptTotalPrice, state => ({
		...state,
		isLoading: true,
		error: null,
	})),
	on(fromPredictionActions.loadedReceiptTotalPrice, (state, { data }) => ({
		...state,
		isLoading: false,
		receiptTotalPrice: data,
		error: null,
	})),
	on(fromPredictionActions.reset, state => ({
		...state,
		receiptTotalPrice: null,
		isLoading: false,
		error: null,
	})),
	on(fromPredictionActions.failed, (state, { error }) => ({
		...state,
		isLoading: false,
		error,
	}))
);
