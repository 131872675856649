import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IUser, IUserFilterForm } from 'src/models/user.model';
import { UserDialogService } from 'src/services/dialog/user.service';
import { IUserState } from 'src/state/app.state';
import { UserFacade } from 'src/state/user/user.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IUserEditForm } from './../../models/user.model';
import { PermissionFacade } from './../../state/permission/permission.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-userpage',
	templateUrl: './entitypage-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class UserPageComponent extends EntityPageComponent<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	public pageTitle$ = new BehaviorSubject('Benutzer');
	public columns = ['name', 'email', 'permissions', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: UserDialogService,
		route: ActivatedRoute,
		entityFacade: UserFacade,
		public permissionFacade: PermissionFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
