import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleStick, IArticleStickEditForm, IArticleStickFilterForm } from '../../models/article.model';
import { AppState, IArticleStickState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickSelector extends EntitySelector<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	protected stateSelector = (state: AppState): IArticleStickState => state.articleStick;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
}
