import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticle } from '../../models/article.model';
import { PriorityType } from '../../models/process-instance-model';
import { IArticleState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-articledialogadd',
	templateUrl: './entitydialogadd-article.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ArticleDialogAddComponent extends EntityDialogAddComponent<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	constructor(entityFacade: ArticleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public PriorityTypes = PriorityType;
}
