import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IPayment, IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';
import { IPaymentState } from './../app.state';

@Injectable({
	providedIn: 'root',
})
export class PaymentSelector extends EntitySelector<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	protected stateSelector = (state: AppState): IPaymentState => state.payment;

	private cashOnDeliveryState = createSelector(
		(state: AppState) => state.paymentCashOnDelivery,
		state => state
	);
	private cashState = createSelector(
		(state: AppState) => state.paymentCash,
		state => state
	);
	private directDebitState = createSelector(
		(state: AppState) => state.paymentDirectDebit,
		state => state
	);
	private paypalState = createSelector(
		(state: AppState) => state.paymentPaypal,
		state => state
	);
	private prepaymentState = createSelector(
		(state: AppState) => state.paymentPrepayment,
		state => state
	);
	private purchaseOnAccountState = createSelector(
		(state: AppState) => state.paymentPurchaseOnAccount,
		state => state
	);
	private barzahlenState = createSelector(
		(state: AppState) => state.paymentBarzahlen,
		state => state
	);

	private allStates = createSelector(this.selectState, this.cashOnDeliveryState, this.cashState, this.directDebitState, this.paypalState, this.prepaymentState, this.purchaseOnAccountState, this.barzahlenState, (...states) => states);

	public isFiltering = createSelector(this.allStates, states => states.some(state => state.isFiltering));
	public isSuggesting = createSelector(this.allStates, states => states.some(state => state.isSuggesting));
	public isFetching = createSelector(this.allStates, states => states.some(state => state.isFetching));
}
