import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StockDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-stock.component';
import { StockDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-stock.component';
import { StockDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-stock.component';
import { IStock } from '../../models/stock.model';
import { IStockState } from '../../state/app.state';
import { StockFacade } from '../../state/stock/stock.facade';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class StockDialogService extends EntityDialogService<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	protected createComponent: ComponentType<any> = StockDialogAddComponent;
	protected updateComponent: ComponentType<any> = StockDialogChangeComponent;
	protected removeComponent: ComponentType<any> = StockDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: StockFacade) {
		super(matDialog, entityFacade);
	}
}
