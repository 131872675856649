import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-cash.component';
import { CashDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-cash.component';
import { CashDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-cash.component';
import { IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm } from '../../models/payment.model';
import { IPaymentCashState } from '../../state/app.state';
import { CashFacade } from '../../state/payment.cash/payment.cash.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentCashDialogService extends EntityDialogService<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	protected createComponent: ComponentType<any> = CashDialogAddComponent;
	protected updateComponent: ComponentType<any> = CashDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CashDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CashFacade) {
		super(matDialog, entityFacade);
	}
}
