import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PartnerDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-partner.component';
import { PartnerDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-partner.component';
import { PartnerDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-partner.component';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PartnerDialogService extends EntityDialogService<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	protected createComponent: ComponentType<any> = PartnerDialogAddComponent;
	protected updateComponent: ComponentType<any> = PartnerDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PartnerDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PartnerFacade) {
		super(matDialog, entityFacade);
	}
}
