import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaymentBarzahlen } from '../../models/payment.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBarzahlenApiService extends EntityApiService<IPaymentBarzahlen> {
	protected entityName = 'barzahlens';

	constructor(http: HttpClient) {
		super(http);
	}
}
