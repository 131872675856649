<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lieferart</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-select formControlName="shippingKind" placeholder="Lieferart">
						<mat-option *ngFor="let shippingKind of ShippingKinds | keyvalue" [value]="shippingKind.value">{{ shippingKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Preis</mat-label>
					<input type="number" matInput formControlName="price" />
					<span matSuffix>€</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Preiszuschlag Samstagszustellung</mat-label>
					<input type="number" matInput formControlName="priceSaturdayDelivery" />
					<span matSuffix>€</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Lieferzeit Min.</mat-label>
					<input type="text" matInput formControlName="shippingDaysMin" />
					<span matSuffix>Tage</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Lieferzeit Max.</mat-label>
					<input type="text" matInput formControlName="shippingDaysMax" />
					<span matSuffix>Tage</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label> Bei Bestellung bis</mat-label>
					<input type="text" matInput formControlName="shippedBeforeHour" />
					<span matSuffix>Uhr</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label> Passwort</mat-label>
					<input type="text" matInput formControlName="password" />
				</mat-form-field>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
