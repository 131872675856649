import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPurchaseDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-collection-purchase.component';
import { CollectionPurchaseDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-collection-purchase.component';
import { CollectionPurchaseDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-collection-purchase.component';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { IPaymentCollectionPurchaseState } from '../../state/app.state';
import { CollectionPurchaseFacade } from '../../state/payment.collection-purchase/payment.collection-purchase.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentCollectionPurchaseDialogService extends EntityDialogService<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	protected createComponent: ComponentType<any> = CollectionPurchaseDialogAddComponent;
	protected updateComponent: ComponentType<any> = CollectionPurchaseDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CollectionPurchaseDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CollectionPurchaseFacade) {
		super(matDialog, entityFacade);
	}
}
