import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from '../../models/receipt';
import { ReceiptCancellationApiService } from '../../services/api/receipt.cancellation.service';
import { ReceiptCancellationDialogService } from '../../services/dialog/receipt.cancellation.service';
import { CancellationNotificationService } from '../../services/notification/receipt.cancellation.service';
import { AppState, IReceiptCancellationState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCancellationActions } from './receipt.cancellation.actions';
import { CancellationSelector } from './receipt.cancellation.selectors';

@Injectable({
	providedIn: 'root',
})
export class CancellationEffects extends EntityEffects<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ReceiptCancellationApiService,
		notificationService: CancellationNotificationService,
		dialogService: ReceiptCancellationDialogService,
		selector: CancellationSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCancellationActions, 'CANCELLATION');
	}
}
