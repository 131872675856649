import { IConfiguration } from 'src/models/configuration.model';
import { EntityState, IConfigurationState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { fromConfigurationActions } from './configuration.actions';

export const initialValue: IConfiguration = {
	_id: null,
	processInstance: null,
	orderInformation: {
		number: null,
		brandName: null,
	},
	receiptInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		number: null,
		numberRange: null,
		dunningFee: null,
		defaultTaxRate: null,
		receiptTypes: [],
	},
	shippingInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		shippingTypes: [],
		pickupStations: [],
		length: null,
		width: null,
		height: null,
		weight: null,
	},
	articleInformation: {
		identifier: null,
	},
	customerInformation: {
		number: null,
	},
	paymentInformation: {
		paymentTypes: [],
	},
};

export const initialEditFormValue: IConfigurationEditForm = {
	_id: null,
	processInstance: null,
	orderInformation: {
		number: null,
		brandName: null,
	},
	receiptInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		number: null,
		numberRange: null,
		dunningFee: null,
		defaultTaxRate: null,
		receiptTypes: [{ receiptKind: null, dueDays: null, reminderDays: null, pdfText: null }],
	},
	shippingInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		shippingTypes: [
			{
				price: null,
				priceSaturdayDelivery: null,
				shippingKind: null,
				shippingDaysMin: null,
				shippingDaysMax: null,
				shippedBeforeHour: null,
				password: null,
			},
		],
		pickupStations: [
			{
				type: null,
				address: {
					street: null,
					streetNumber: null,
					postCode: null,
					city: null,
					country: null,
				},
				default: null,
			},
		],
		length: null,
		width: null,
		height: null,
		weight: null,
	},
	articleInformation: {
		identifier: null,
	},
	customerInformation: {
		number: null,
	},
	paymentInformation: {
		paymentTypes: [
			{
				price: null,
				paymentKind: null,
				emailText: null,
				pdfText: null,
			},
		],
	},
};

export const initialFilterFormValue: IConfigurationFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	orderInformation: {
		number: null,
		brandName: null,
	},
	receiptInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		number: null,
		numberRange: null,
		dunningFee: null,
		defaultTaxRate: null,
		receiptTypes: [],
	},
	shippingInformation: {
		details: {
			companyName: null,
			accountOwner: null,
			address: {
				street: null,
				streetNumber: null,
				postCode: null,
				city: null,
				country: null,
			},
			hrb: null,
			bank: null,
			iban: null,
			bic: null,
			email: null,
			bankEmail: null,
			taxNumber: null,
			taxOffice: null,
			telephoneNumber: null,
			creditorIdentificationNumber: null,
			salesTaxIdentificationNumber: null,
			name: null,
			nameInAddress: null,
			bankAccountNumber: null,
			bankCode: null,
		},
		shippingTypes: [],
		pickupStations: [],
		length: null,
		width: null,
		height: null,
		weight: null,
	},
	articleInformation: {
		identifier: null,
	},
	customerInformation: {
		number: null,
	},
	paymentInformation: {
		paymentTypes: [],
	},
};

export const initialState = EntityState.create<IConfiguration, IConfigurationEditForm, IConfigurationFilterForm>('CONFIGURATION', initialValue, initialEditFormValue, initialFilterFormValue);
export const configurationReducer = EntityReducer.create<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm>(initialState, {}, fromConfigurationActions);
