import { Directive, Input } from '@angular/core';
import { BehaviorSubject, debounceTime, filter, first, map, Observable, tap, withLatestFrom } from 'rxjs';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';

export interface IValueChange {
	index: number;
	attributeName?: string;
	value: any;
}

@Directive()
export abstract class EntityDialogTableComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TTableElement,
	TOriginalElement,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> {
	public abstract columns: string[];
	public dataSource$: Observable<TTableElement[]>;
	public valueChanges$: BehaviorSubject<IValueChange[]>;
	public isDirty$: Observable<boolean>;
	@Input() public controlId: string;

	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>) {
		this.dataSource$ = this.entityFacade.buildDataSource(this.dataSourceSelector);
		this.valueChanges$ = new BehaviorSubject<IValueChange[]>([]);
		this.isDirty$ = this.valueChanges$.pipe(map(valueChanges => valueChanges.length > 0));

		this.valueChanges$
			.pipe(
				debounceTime(300),
				filter(valueChanges => valueChanges.length > 0),
				withLatestFrom(this.dataSource$),
				tap(([valueChanges, dataSource]) => {
					for (let valueChange of valueChanges) {
						const element = dataSource[valueChange.index] as any;

						if (valueChange.attributeName != null) {
							element[valueChange.attributeName] = valueChange.value;
							dataSource[valueChange.index] = element;
						} else {
							dataSource[valueChange.index] = valueChange.value;
						}
					}

					this.entityFacade.changeFormValue({ attributeName: this.controlId, value: this.updateDataSource(dataSource) });
					this.valueChanges$.next([]);
					this.afterValueChange();
				})
			)
			.subscribe();
	}

	protected updateDataSource(dataSource: TTableElement[]): TOriginalElement[] {
		return dataSource as any as TOriginalElement[];
	}

	protected abstract dataSourceSelector(entity: TEntity): TTableElement[];

	protected onUpdate(valueChange: IValueChange): IValueChange[] {
		return [valueChange];
	}

	protected afterValueChange(): void {}

	protected abstract createElement(): TTableElement;

	public onInput(index: number, attributeName: string, event: any, isNumber: boolean = false): void {
		let value = event.target.value;

		if (isNumber) {
			value = +value.replace('.', '').replace(',', '.');
		}

		this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, attributeName, value })]);
	}

	public onSelectionChange(index: number, attributeName: string, event: any): void {
		this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, attributeName, value: event.value })]);
	}

	public remove(index: number): void {
		this.dataSource$
			.pipe(
				first(),
				tap(dataSource => {
					const newDataSource = [...dataSource];
					newDataSource.splice(index, 1);

					this.entityFacade.changeFormValue({ attributeName: this.controlId, value: this.updateDataSource(newDataSource) });
				})
			)
			.subscribe();
	}

	public clone(index: number): void {
		this.dataSource$
			.pipe(
				first(),
				tap(dataSource => this.entityFacade.changeFormValue({ attributeName: this.controlId, value: this.updateDataSource([...dataSource, dataSource[index]]) }))
			)
			.subscribe();
	}

	public add(data?: TTableElement): void {
		this.dataSource$
			.pipe(
				first(),
				tap(dataSource => this.entityFacade.changeFormValue({ attributeName: this.controlId, value: this.updateDataSource([...dataSource, data ? data : this.createElement()]) }))
			)
			.subscribe();
	}
}
