import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SetValueAction, box } from 'ngrx-forms';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { MessageApiService } from '../../services/api/message.service';
import { MessageDialogService } from '../../services/dialog/message.service';
import { MessageNotificationService } from '../../services/notification/message.service';
import { AppState, IMessageState } from '../app.state';
import { fromAttachmentActions } from '../attachment/attachment.actions';
import { EntityEffects } from '../entity.effects';
import { fromOrderActions } from '../order/order.actions';
import { OrderSelector } from '../order/order.selectors';
import { SessionSelector } from '../session/session.selectors';
import { TextTemplateSelector } from '../text-template/text-template.selectors';
import { fromMessageActions } from './message.actions';
import { MessageSelector } from './message.selectors';

@Injectable({
	providedIn: 'root',
})
export class MessageEffects extends EntityEffects<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: MessageApiService,
		notificationService: MessageNotificationService,
		dialogService: MessageDialogService,
		selector: MessageSelector,
		sessionSelector: SessionSelector,
		private orderSelector: OrderSelector,
		private textTemplateSelector: TextTemplateSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromMessageActions, 'MESSAGE');
	}

	public onCreatedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.createdForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[[, entity], order], authToken]) =>
				this.entityService.create({ ...entity, order: order._id }, authToken).pipe(
					map(result => this.entityActions.created({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onCreatedUpdatedOrRemoved$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.created, this.entityActions.updated, this.entityActions.removed),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			map(([, order]) => fromOrderActions.invalidate({ ids: [order._id] }))
		)
	);

	public onPriorityUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMessageActions.updatePriority),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, priority }, authToken]) =>
				this.apiService.updatePriority(entity, priority, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onSelectedTextTemplate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetValueAction.TYPE),
			filter((formControlUpdate: SetValueAction<string>) => formControlUpdate.controlId?.indexOf(this.EDIT_FORM) > -1 && formControlUpdate.controlId?.indexOf('textTemplate') > -1),
			concatLatestFrom(({ value }) => this.store.select(this.textTemplateSelector.getOne(value))),
			filter(([, textTemplate]) => !!textTemplate),
			switchMap(([, textTemplate]) => [
				this.entityActions.globalFormValueChanged({
					changes: [
						{ controlId: `${this.EDIT_FORM}.title`, value: textTemplate?.title },
						{ controlId: `${this.EDIT_FORM}.text`, value: textTemplate?.text },
					],
				}),
			])
		)
	);

	public onAttachmentCreated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAttachmentActions.created),
			filter(entity => !!entity),
			concatLatestFrom(() => this.store.select(this.entitySelector.selected)),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			switchMap(([[{ entity }, message], editForm]) => [
				this.entityActions.globalFormValueChanged({
					changes: [{ controlId: `${this.EDIT_FORM}.attachments`, value: box([...(message.attachments ?? []), ...editForm.attachments, entity._id].filter((value, index, result) => result.indexOf(value) === index)) }],
				}),
			])
		)
	);
}
