import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleState } from 'src/state/app.state';
import { IArticle, IArticleEditForm, IArticleFilterForm } from '../../models/article.model';
import { ArticleProcessComment } from '../../models/enums/articleprocess-comment.enum';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleFacade } from '../../state/article/article.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-articlehandoverdialog',
	templateUrl: './entitydialog-article-handover.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class ArticleHandoverDialogComponent extends EntityDialogComponent<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	public comment: string = '';
	public ArticleProcessComments = ArticleProcessComment;

	constructor(public articleFacade: ArticleFacade, public productFacade: ProductFacade, public storagePlaceFacade: StoragePlaceFacade, matDialogRef: MatDialogRef<ArticleHandoverDialogComponent, IEntityDialogData<IArticle>>) {
		super(articleFacade, matDialogRef);
	}

	public changeQuality(quality: number, entity: IArticle): void {
		this.articleFacade.changeFormValue({ attributeName: 'quality', value: quality });
		this.articleFacade.updated(false);
	}
}
