import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleBirthdayChronicle } from '../../models/article.model';
import { IArticleBirthdayChronicleState } from '../../state/app.state';
import { ArticleBirthdayChronicleFacade } from '../../state/article.birthday-chronicle/article.birthday-chronicle.facade';
import { IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-birthday-chronicledialogadd',
	templateUrl: './entitydialogadd-birthday-chronicle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class BirthdayChronicleDialogAddComponent extends EntityDialogAddComponent<IArticleBirthdayChronicle, IArticleBirthdayChronicleState, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm> {
	constructor(entityFacade: ArticleBirthdayChronicleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
