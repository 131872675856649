import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleStick, IArticleStickEditForm, IExpandedArticleStick } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleStickActions = {
	...EntityActions.create<IArticleStick, IArticleStickEditForm>('[Stick]'),
	search: createAction(`[Stick] Search`),
	searched: createAction(`[Stick] Searched`, props<{ entities: IExpandedArticleStick[] }>()),
	extended: createAction(`[Stick] Extended`),
	pagingChanged: createAction(`[Stick] Paging Changed`, props<{ paging: IPaging }>()),
	dayRangeChanged: createAction(`[Stick] Day Range Changed`, props<{ dayRange: IDayRange }>()),
};
