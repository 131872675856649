import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { UserNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-user.component';
import { IUser } from 'src/models/user.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class UserNotificationService extends EntityNotificationService<IUser> {
	protected component: ComponentType<any> = UserNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
