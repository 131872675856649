import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrepaymentDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-prepayment.component';
import { PrepaymentDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-prepayment.component';
import { PrepaymentDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-prepayment.component';
import { IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from '../../models/payment.model';
import { IPaymentPrepaymentState } from '../../state/app.state';
import { PrepaymentFacade } from '../../state/payment.prepayment/payment.prepayment.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentPrepaymentDialogService extends EntityDialogService<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	protected createComponent: ComponentType<any> = PrepaymentDialogAddComponent;
	protected updateComponent: ComponentType<any> = PrepaymentDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PrepaymentDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PrepaymentFacade) {
		super(matDialog, entityFacade);
	}
}
