import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleBookState } from 'src/state/app.state';
import { IArticleBook } from '../../models/article.model';
import { ArticleBookDialogService } from '../../services/dialog/article.book.service';
import { ArticleBookFacade } from '../../state/article.book/article.book.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IArticleBookEditForm, IBookFilterForm } from './../../models/article.model';
import { ProcessNodeFacade } from './../../state/process-node/process-node.facade';
import { StoragePlaceFacade } from './../../state/storage-place/storage-place.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-bookpage',
	templateUrl: './entitypage-book.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class BookPageComponent extends EntityPageComponent<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	public pageTitle$ = new BehaviorSubject('Antiquarische Bücher');
	public columns = ['updatedAt', 'order', 'product', 'storagePlace', 'quality', 'publicationDate', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: ArticleBookDialogService,
		route: ActivatedRoute,
		public entityFacade: ArticleBookFacade,
		public orderFacade: OrderFacade,
		public mediaProductFacade: MediaProductFacade,
		public mediaStockFacade: MediaStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });
	}
}
