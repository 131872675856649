import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Aggregation } from '../../state/app.state';
import { ApiService, IDataResponse } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class AggregationService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	public aggregateOrderCount(authToken: string, from: string, until: string, aggregationType: string): Observable<IDataResponse<Aggregation>> {
		return this.post<IDataResponse<Aggregation>, any>(['aggregation', 'order-count'], {}, this.getHeaders(authToken), 'json', { from, until, aggregationType });
	}

	public aggregateOrderState(authToken: string, from: string, until: string, aggregationType: string, routeName: string): Observable<IDataResponse<Aggregation>> {
		return this.post<IDataResponse<Aggregation>, any>(['aggregation', 'order-state'], { routeName }, this.getHeaders(authToken), 'json', { from, until, aggregationType });
	}

	public aggregateReceiptTotalPrice(authToken: string, from: string, until: string, aggregationType: string): Observable<IDataResponse<Aggregation>> {
		return this.post<IDataResponse<Aggregation>, any>(['aggregation', 'receipt-totalprice'], {}, this.getHeaders(authToken), 'json', { from, until, aggregationType });
	}

	public aggregateReceiptState(authToken: string, from: string, until: string, aggregationType: string, routeName: string): Observable<IDataResponse<Aggregation>> {
		return this.post<IDataResponse<Aggregation>, any>(['aggregation', 'receipt-state'], { routeName }, this.getHeaders(authToken), 'json', { from, until, aggregationType });
	}
}
