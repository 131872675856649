import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from '../../models/payment.model';
import { AppState, IPaymentBarzahlenState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromBarzahlenActions } from './payment.barzahlen.actions';
import { initialEditFormValue } from './payment.barzahlen.reducer';
import { BarzahlenSelector } from './payment.barzahlen.selectors';

@Injectable({
	providedIn: 'root',
})
export class BarzahlenFacade extends EntityFacade<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromBarzahlenActions;

	constructor(store: Store<AppState>, entitySelector: BarzahlenSelector) {
		super(store, entitySelector);
	}
}
