import { on } from '@ngrx/store';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { EntityState, IMailTemplateState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';
import { fromMailTemplateActions } from './mailtemplate.actions';

export const initialValue: IMailTemplate = {
	_id: null,
	processInstance: null,
	name: null,
	subject: null,
	template: null,
	receivers: null,
	testData: null,
};

export const initialEditFormValue: IMailTemplateEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	subject: null,
	template: null,
	receivers: null,
	testData: null,
};

export const initialFilterFormValue: IMailTemplateFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	subject: null,
	template: null,
	receivers: null,
	testData: null,
};

export const initialState = EntityState.create<IMailTemplate, IMailTemplateEditForm, IMailTemplateFilterForm>('MAILTEMPLATE', initialValue, initialEditFormValue, initialFilterFormValue);
export const mailtemplateReducer = EntityReducer.create<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm>(
	initialState,
	{
		name: validate(required),
		subject: validate(required),
		template: validate(required),
	},
	fromMailTemplateActions,
	on(fromMailTemplateActions.test, state => ({ ...state })),
	on(fromMailTemplateActions.tested, state => ({ ...state, isFiltering: true })),
	on(fromMailTemplateActions.testSent, state => ({ ...state, isFiltering: false }))
);
