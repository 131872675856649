import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaymentPaypal } from '../../models/payment.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentPaypalApiService extends EntityApiService<IPaymentPaypal> {
	protected entityName = 'paypals';

	constructor(http: HttpClient) {
		super(http);
	}

	public sendPaymentLink(payment: IPaymentPaypal, authToken: string): Observable<void> {
		return this.post<void, { payment: IPaymentPaypal }>([this.entityName, 'send-payment-link'], { payment }, this.getHeaders(authToken));
	}
}
