import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleFolderApiService } from '../../services/api/article.folder.service';
import { ArticleFolderDialogService } from '../../services/dialog/article.folder.service';
import { ArticleFolderNotificationService } from '../../services/notification/article.folder.service';
import { AppState, IArticleFolderState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleFolderActions } from './article.folder.actions';
import { ArticleFolderSelector } from './article.folder.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleFolderEffects extends EntityEffects<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		protected apiService: ArticleFolderApiService,
		notificationService: ArticleFolderNotificationService,
		dialogService: ArticleFolderDialogService,
		selector: ArticleFolderSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleFolderActions, 'FOLDER');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleFolderActions.search),
			debounceTime(10),
			switchMap(() =>
				this.apiService.search({ articleKind: ArticleKind.Folder }).pipe(
					map(data => fromArticleFolderActions.searched({ entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleFolderActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleFolderActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
