import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { AppState, IShippingReturnState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromReturnActions } from './shipping.return.actions';
import { initialEditFormValue } from './shipping.return.reducer';
import { ReturnSelector } from './shipping.return.selectors';

@Injectable({
	providedIn: 'root',
})
export class ReturnFacade extends EntityFacade<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromReturnActions;

	constructor(store: Store<AppState>, returnSelector: ReturnSelector) {
		super(store, returnSelector);
	}
}
