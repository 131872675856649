<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Benutzer hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-label>E-Mail</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.email" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Name</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
				</mat-form-field>
				<babylon-image-autocompletesingle [value]="editForm.controls.image.value" [controlId]="editForm.controls.image.id" [optional]="true" placeholder="Profilbild"></babylon-image-autocompletesingle>
				<mat-form-field appearance="outline">
					<mat-label>Passwort</mat-label>
					<input type="password" matInput [ngrxFormControlState]="editForm.controls.password" />
				</mat-form-field>
				<babylon-permission-autocompletemultiple [values]="editForm.controls.permissions.value" [controlId]="editForm.controls.permissions.id" class="mat-form-field--span"></babylon-permission-autocompletemultiple>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
