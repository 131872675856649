import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleCoin, IArticleCoinEditForm, IArticleCoinFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleCoinApiService } from '../../services/api/article.coin.service';
import { ArticleCoinDialogService } from '../../services/dialog/article.coin.service';
import { ArticleCoinNotificationService } from '../../services/notification/article.coin.service';
import { AppState, IArticleCoinState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleCoinActions } from './article.coin.actions';
import { ArticleCoinSelector } from './article.coin.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleCoinEffects extends EntityEffects<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleCoinApiService,
		notificationService: ArticleCoinNotificationService,
		dialogService: ArticleCoinDialogService,
		private selector: ArticleCoinSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleCoinActions, 'COIN');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCoinActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Coin,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleCoinActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleCoinActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCoinActions.dayRangeChanged, fromArticleCoinActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleCoinActions.extended());
				}

				return this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Coin,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleCoinActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleCoinActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCoinActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleCoinActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleCoinActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
