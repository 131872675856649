import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProductNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-product.component';
import { IProduct } from '../../models/product.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ProductNotificationService extends EntityNotificationService<IProduct> {
	protected component: ComponentType<any> = ProductNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
