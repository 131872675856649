import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { IPaymentCollectionPurchaseState } from '../../state/app.state';
import { CollectionPurchaseFacade } from '../../state/payment.collection-purchase/payment.collection-purchase.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-collection-purchasedialogadd',
	templateUrl: './entitydialogadd-collection-purchase.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class CollectionPurchaseDialogAddComponent extends EntityDialogAddComponent<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	constructor(entityFacade: CollectionPurchaseFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
