<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Zahlungsweise ändern</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-select placeholder="Zahlungsweise" formControlName="paymentKind">
						<mat-option *ngFor="let paymentKind of PaymentKinds | keyvalue" [value]="paymentKind.value">{{ paymentKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</form>
		<i *ngIf="data.entity.paymentKind != PaymentKinds.DirectDebit && formGroup.value.paymentKind == PaymentKinds.DirectDebit">
			Bitte stelle sicher, dass in der Zahlung die korrekte IBAN hinterlegt ist, nachdem du die Zahlungsweise geändert hast.
		</i>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
