<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Konfiguration ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Bestellungen">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Markenname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.orderInformation.controls.brandName" />
						</mat-form-field>
						<div></div>
						<mat-form-field appearance="outline">
							<mat-label>Aktuelle Auftragsnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.orderInformation.controls.number" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Aktuelle Kundennummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.customerInformation.controls.number" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Belege">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Aktuelle Rechnungsnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.number" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Mahngebühr</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.dunningFee" />
							<span matSuffix>€</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Standard Steuersatz</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.defaultTaxRate" [ngrxValueConverter]="percentageConverter" />
							<span matSuffix>%</span>
						</mat-form-field>
					</div>
					<mat-card class="box">
						<mat-card-content>
							<span class="mat-caption">Belegtypen</span>
							<babylon-receipt-typestable controlId="receiptInformation.receiptTypes"></babylon-receipt-typestable>
						</mat-card-content>
					</mat-card>
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Firmenname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.companyName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Inhaber</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.accountOwner" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Straße</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.address.controls.street" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Hausnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.address.controls.streetNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>PLZ</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.address.controls.postCode" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Stadt</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.address.controls.city" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Land</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.address.controls.country" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>HRB</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.hrb" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Bank</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.bank" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>IBAN</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.iban" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>BIC</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.bic" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Gläubiger Identifikationsnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.creditorIdentificationNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>USt-ID</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.salesTaxIdentificationNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>E-Mail</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.email" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Bank E-Mail</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.bankEmail" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Steuernummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.taxNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Stauerverwaltung</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.taxOffice" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Telefon</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiptInformation.controls.details.controls.telephoneNumber" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Lieferungen">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Länge</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.length" />
							<span matSuffix>cm</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Höhe</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.height" />
							<span matSuffix>cm</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Breite</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.width" />
							<span matSuffix>cm</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Gewicht</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.weight" />
							<span matSuffix>kg</span>
						</mat-form-field>
					</div>
					<mat-card class="box">
						<mat-card-content>
							<span class="mat-caption">Lieferarten</span>
							<babylon-shipping-typestable controlId="shippingInformation.shippingTypes"></babylon-shipping-typestable>
						</mat-card-content>
					</mat-card>
					<mat-card class="box">
						<mat-card-content>
							<span class="mat-caption">Abholorte</span>
							<babylon-pickup-stationstable controlId="shippingInformation.pickupStations"></babylon-pickup-stationstable>
						</mat-card-content>
					</mat-card>
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Firmenname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.companyName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Ansprechpartner</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.nameInAddress" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Straße</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.address.controls.street" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Hausnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.address.controls.streetNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>PLZ</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.address.controls.postCode" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Stadt</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.address.controls.city" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Land</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.address.controls.country" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Kontoinhaber</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.accountOwner" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Bank</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.bank" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>IBAN</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.iban" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>BIC</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.bic" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Steuernummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.taxNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>E-Mail</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.email" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Telefon</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.shippingInformation.controls.details.controls.telephoneNumber" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Zahlungen">
					<mat-card class="box">
						<mat-card-content>
							<span class="mat-caption">Zahlungsarten</span>
							<babylon-payment-typestable controlId="paymentInformation.paymentTypes"></babylon-payment-typestable>
						</mat-card-content>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
