import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { MenuFacade } from 'src/state/menu/menu.facade';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { EntityPageAddComponent } from './entitypageadd.component';

@Component({
	selector: 'babylon-orderpageadd',
	templateUrl: './entitypageadd-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypageadd.component.scss'],
})
export class OrderPageAddComponent extends EntityPageAddComponent {
	public pageTitle$ = new BehaviorSubject('Auftrag hinzufügen');

	constructor(titleService: Title, menuFacade: MenuFacade, public newspaperFacade: ArticleNewspaperFacade) {
		super(titleService, menuFacade);
	}
}
