import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartConfiguration } from 'chart.js';
import _ from 'lodash';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, interval, map } from 'rxjs';
import { progressFade } from '../../animations/progressfade.animation';
import { AggregationFacade } from '../../state/aggregation/aggregation.facade';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { PredictionFacade } from '../../state/prediction/prediction.facade';
import { SessionFacade } from '../../state/session/session.facade';
import { PageComponent } from '../page.component';

const jsonEqual = (a: any, b: any) => JSON.stringify(a) === JSON.stringify(b);

@UntilDestroy()
@Component({
	selector: 'babylon-startpage',
	templateUrl: './startpage.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./startpage.component.scss'],
	animations: [progressFade],
})
export class StartpageComponent extends PageComponent {
	public pageTitle$ = new BehaviorSubject('Start');

	private shouldDoInitialFilter: boolean = true;
	private shouldDoRegularFilter: number = 60;

	constructor(titleService: Title, public sessionFacade: SessionFacade, public permissionFacade: PermissionFacade, public aggregationFacade: AggregationFacade, public predictionFacade: PredictionFacade) {
		super(titleService);

		if (this.shouldDoInitialFilter) {
			aggregationFacade.loadOrderCount();
			aggregationFacade.loadReceiptTotalPrice();
		}

		if (this.shouldDoRegularFilter) {
			interval(this.shouldDoRegularFilter * 1000)
				.pipe(untilDestroyed(this))
				.subscribe(() => {
					aggregationFacade.loadOrderCount();
					aggregationFacade.loadReceiptTotalPrice();
				});
		}
	}

	public compareTimespan = (option: MatOption, value: MatOption): boolean => {
		return JSON.stringify(option) === JSON.stringify(value);
	};

	public conversionData$ = combineLatest([this.aggregationFacade.orderCount$, this.aggregationFacade.receiptTotalPrice$, this.predictionFacade.receiptTotalPrice$]).pipe(
		debounceTime(1000),
		map(([orderCount, aggregationReceiptTotalPrice, predictionReceiptTotalPrice]) => ({
			datasets: [
				{
					data: _.values(orderCount),
					label: 'Aufträge',
					yAxisID: 'Aufträge',
					backgroundColor: 'rgba(136, 0, 0, 0.2)',
					borderColor: 'rgba(136, 0, 0, 1)',
					pointBackgroundColor: 'rgba(136, 0, 0, 1)',
					fill: 'origin',
				},
				{
					data: _.values(aggregationReceiptTotalPrice),
					label: 'Umsatz',
					yAxisID: 'Umsatz',
					backgroundColor: 'rgba(20, 143, 204, 0.2)',
					borderColor: 'rgba(20, 143, 204, 1)',
					pointBackgroundColor: 'rgba(20, 143, 204, 1)',
					fill: 'origin',
				},
				{
					data: _.values(predictionReceiptTotalPrice),
					label: 'Prognose',
					yAxisID: 'Umsatz',
					pointRadius: 10,
					pointHoverRadius: 15,
					backgroundColor: 'rgba(20, 143, 95, 0.2)',
					borderColor: 'rgba(20, 143, 95, 1)',
					pointBackgroundColor: 'rgba(20, 143, 95, 1)',
					fill: 'origin',
				},
			],
			labels: _.keys(orderCount),
		})),
		distinctUntilChanged(jsonEqual)
	);

	public conversionOptions = {
		responsive: true,
		maintainAspectRatio: false,
		interaction: {
			intersect: false,
			mode: 'index',
		},
		plugins: {
			legend: {
				labels: {
					usePointStyle: true,
				},
			},
		},
		elements: {
			line: {
				tension: 0.5,
			},
		},
		scales: {
			Umsatz: {
				position: 'right',
				beginAtZero: true,
				ticks: {
					color: 'rgba(20, 143, 204, 1)',
					callback: value => `${value} €`,
				},
			},
			Aufträge: {
				position: 'left',
				beginAtZero: true,
				ticks: {
					color: 'rgba(136, 0, 0, 1)',
				},
			},
		},
	} as ChartConfiguration['options'];
}
