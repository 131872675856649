import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { AppState, IDeliveryNoteReturnState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDeliveryNoteReturnActions } from './delivery-note.return.actions';
import { initialEditFormValue } from './delivery-note.return.reducer';
import { DeliveryNoteReturnSelector } from './delivery-note.return.selectors';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnFacade extends EntityFacade<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDeliveryNoteReturnActions;

	constructor(store: Store<AppState>, deliveryNoteSelector: DeliveryNoteReturnSelector) {
		super(store, deliveryNoteSelector);
	}

	public download(deliveryNote: IDeliveryNoteReturn): void {
		this.store.dispatch(this.actions.download({ deliveryNote }));
	}
}
