import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from '../../models/shipping.model';
import { ShippingDhlPremiumApiService } from '../../services/api/shipping.dhl-premium.service';
import { ShippingDhlPremiumDialogService } from '../../services/dialog/shipping.dhl-premium.service';
import { DhlPremiumNotificationService } from '../../services/notification/shipping.dhl-premium.service';
import { AppState, IShippingDhlPremiumState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDhlPremiumActions } from './shipping.dhl-premium.actions';
import { DhlPremiumSelector } from './shipping.dhl-premium.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlPremiumEffects extends EntityEffects<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ShippingDhlPremiumApiService,
		notificationService: DhlPremiumNotificationService,
		dialogService: ShippingDhlPremiumDialogService,
		selector: DhlPremiumSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDhlPremiumActions, 'DHLPREMIUM');
	}
}
