import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPublisher } from 'src/models/publisher.model';
import { PublisherApiService } from 'src/services/api/publisher.service';
import { PublisherDialogService } from 'src/services/dialog/publisher.service';
import { PublisherNotificationService } from 'src/services/notification/publisher.service';
import { AppState, IPublisherState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { fromPublisherActions } from './publisher.actions';
import { PublisherSelector } from './publisher.selectors';

@Injectable({
	providedIn: 'root',
})
export class PublisherEffects extends EntityEffects<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PublisherApiService,
		notificationService: PublisherNotificationService,
		dialogService: PublisherDialogService,
		selector: PublisherSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPublisherActions, 'PUBLISHER');
	}
}
