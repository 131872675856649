import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryNoteOrderDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-delivery-note-order.component';
import { DeliveryNoteOrderDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-delivery-note-order.component';
import { DeliveryNoteOrderDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-delivery-note-order.component';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteOrderState } from '../../state/app.state';
import { DeliveryNoteOrderFacade } from '../../state/delivery-note.order/delivery-note.order.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteOrderDialogService extends EntityDialogService<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	protected createComponent: ComponentType<any> = DeliveryNoteOrderDialogAddComponent;
	protected updateComponent: ComponentType<any> = DeliveryNoteOrderDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DeliveryNoteOrderDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DeliveryNoteOrderFacade) {
		super(matDialog, entityFacade);
	}
}
