import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { CONSTANTS, processReactNode } from 'cloudimage-responsive-utils';
import { environment } from '../../environments/environment';
import { IImage } from '../../models/image.model';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'babylon-picture',
	templateUrl: './picture.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PictureComponent extends BaseComponent implements AfterViewInit, OnChanges {
	public elementRef: ElementRef;
	@Input() public image: IImage;
	@Input() public file: IUploadedFile;
	@Input() public width: number;
	@Input() public srcSet = '';

	constructor(private changeDetector: ChangeDetectorRef) {
		super();
	}

	@ViewChild('element', { static: false }) set element(element: ElementRef) {
		if (element) {
			this.elementRef = element;
			this.updateSrcSet();
		}
	}

	public ngAfterViewInit(): void {
		this.updateSrcSet();
	}

	public ngOnChanges(): void {
		this.updateSrcSet();
	}

	private updateSrcSet(): void {
		if (this.file != null && this.elementRef != null) {
			try {
				const { cloudimgSRCSET } = processReactNode(
					{
						src: this.file.url,
						config: {
							token: environment.cloudImageToken,
							domain: 'cloudimg.io',
							customDomain: false,
							lazyLoading: false,
							lazyLoadOffset: null,
							placeholderBackground: '#f4f4f4',
							baseUrl: null,
							baseURL: null,
							apiVersion: null,
							presets: {
								xs: '(max-width: 575px)',
								sm: '(min-width: 576px)',
								md: '(min-width: 768px)',
								lg: '(min-width: 992px)',
								xl: '(min-width: 1200px)',
							},
							ratio: 1.5,
							params: '',
							imageSizeAttributes: 'use',
							exactSize: true,
							doNotReplaceURL: false,
							limitFactor: 100,
							devicePixelRatioList: CONSTANTS.DEVICE_PIXEL_RATIO_LIST,
							lowQualityPreview: null,
							autoAlt: false,
							delay: null,
						},
					},
					this.elementRef.nativeElement,
					false,
					false
				);

				this.srcSet = cloudimgSRCSET.map((srcSet: any) => `${srcSet.url}&v${this.image.version} ${srcSet.dpr}x`).join(', ');
			} catch (e) {
				// no problem, recalculation will be triggered again
			}

			this.changeDetector.detectChanges();
		}
	}
}
