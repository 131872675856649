import { EntityApiService, IEntitySingleResponse } from './entity.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { IProcessRoute } from '../../models/process-route.model';
import { IShipping } from './../../models/shipping.model';
import { ShippingDhlExpressApiService } from './shipping.dhl-express.service';
import { ShippingDhlPremiumApiService } from './shipping.dhl-premium.service';
import { ShippingDhlApiService } from './shipping.dhl.service';
import { ShippingPickupApiService } from './shipping.pickup.service';
import { ShippingReturnApiService } from './shipping.return.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingApiService extends EntityApiService<IShipping> {
	protected entityName = 'shippings';

	constructor(
		http: HttpClient,
		private dhlService: ShippingDhlApiService,
		private dhlPremiumService: ShippingDhlPremiumApiService,
		private dhlExpressService: ShippingDhlExpressApiService,
		private pickupService: ShippingPickupApiService,
		private returnService: ShippingReturnApiService
	) {
		super(http);
	}

	public changeProcess(entity: IShipping, processRoute: IProcessRoute, comment: string, authToken: string): Observable<IEntitySingleResponse<IShipping>> {
		switch (entity.shippingKind) {
			case ShippingKind.Dhl:
				return this.dhlService.changeProcess(entity, processRoute, comment, authToken);
			case ShippingKind.DhlPremium:
				return this.dhlPremiumService.changeProcess(entity, processRoute, comment, authToken);
			case ShippingKind.DhlExpress:
				return this.dhlExpressService.changeProcess(entity, processRoute, comment, authToken);
			case ShippingKind.Pickup:
				return this.pickupService.changeProcess(entity, processRoute, comment, authToken);
			case ShippingKind.Return:
				return this.returnService.changeProcess(entity, processRoute, comment, authToken);
		}
	}

	public revertProcess(entity: IShipping, comment: string, authToken: string): Observable<IEntitySingleResponse<IShipping>> {
		switch (entity.shippingKind) {
			case ShippingKind.Dhl:
				return this.dhlService.revertProcess(entity, comment, authToken);
			case ShippingKind.DhlPremium:
				return this.dhlPremiumService.revertProcess(entity, comment, authToken);
			case ShippingKind.DhlExpress:
				return this.dhlExpressService.revertProcess(entity, comment, authToken);
			case ShippingKind.Pickup:
				return this.pickupService.revertProcess(entity, comment, authToken);
			case ShippingKind.Return:
				return this.returnService.revertProcess(entity, comment, authToken);
		}
	}

	public download(authToken: string, entity: IShipping): Observable<string> {
		switch (entity.shippingKind) {
			case ShippingKind.Dhl:
				return this.dhlService.download(authToken, entity);
			case ShippingKind.DhlExpress:
				return this.dhlExpressService.download(authToken, entity);
			case ShippingKind.DhlPremium:
				return this.dhlPremiumService.download(authToken, entity);
			case ShippingKind.Pickup:
				return this.pickupService.download(authToken, entity);
			case ShippingKind.Return:
				return this.returnService.download(authToken, entity);
		}
	}

	public print(authToken: string, entity: IShipping): Observable<string> {
		switch (entity.shippingKind) {
			case ShippingKind.Dhl:
				return this.dhlService.print(authToken, entity);
			case ShippingKind.DhlExpress:
				return this.dhlExpressService.print(authToken, entity);
			case ShippingKind.DhlPremium:
				return this.dhlPremiumService.print(authToken, entity);
			case ShippingKind.Pickup:
				return this.pickupService.print(authToken, entity);
			case ShippingKind.Return:
				return this.returnService.print(authToken, entity);
		}
	}

	public changeShippingKind(shipping: IShipping, shippingKind: string, enableSaturdayDelivery: boolean, authToken: string): Observable<IEntitySingleResponse<IShipping>> {
		return this.post<IEntitySingleResponse<IShipping>, { shippingKind: string; enableSaturdayDelivery: boolean }>(
			[this.entityName, shipping._id, 'change-shipping-kind'],
			{ shippingKind, enableSaturdayDelivery },
			this.getHeaders(authToken)
		);
	}
}
