import { createAction, props } from '@ngrx/store';
import { IImage } from 'src/models/image.model';
import { EntityActions } from '../entity.actions';
import { IImageEditForm } from './../../models/image.model';

export const fromImageActions = {
	...EntityActions.create<IImage, IImageEditForm>('[Image]'),
	createdImageForm: createAction(`[Image] Created Image Form`, props<{ entity: IImage; file: File }>()),
	updatedImageForm: createAction(`[Image] Updated Image Form`, props<{ entity: IImage; file: File }>()),
};
