import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { decode, encode } from 'base64-arraybuffer';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { UserApiService } from '../../services/api/user.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromDeviceActions } from './device.actions';
import { DeviceSelector } from './device.selectors';

@Injectable({
	providedIn: 'root',
})
export class DeviceEffects extends BaseEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private deviceSelector: DeviceSelector, private userService: UserApiService) {
		super();
	}

	public onLoadUserDevicesForMachine$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.loadDevicesForMachine),
			concatLatestFrom(() => this.store.select(this.deviceSelector.machineId)),
			switchMap(([, machineId]) =>
				this.userService.getUserDevicesForMachine(machineId).pipe(
					map(response => fromDeviceActions.loadedDevicesForMachine({ userDevices: response.data })),
					catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLoadUserDeviceForUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.loadDeviceForUser),
			switchMap(({ email }) =>
				this.userService.getUserDeviceForUser(email).pipe(
					map(response => fromDeviceActions.loadedDeviceForUser({ userDevice: response.data })),
					catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onRegister$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.register),
			concatLatestFrom(() => this.store.select(this.deviceSelector.machineId)),
			switchMap(([{ userDevice }, machineId]) =>
				this.userService.registerRequestDevice(userDevice).pipe(
					switchMap(response =>
						from(navigator.credentials.create({ publicKey: this.getPublicKeyForRegistration(response.data) })).pipe(
							switchMap(credential =>
								this.userService.registerVerifyDevice(userDevice, machineId, response.data, this.getCredentialForAuthentication(credential)).pipe(
									map(() => fromDeviceActions.registered()),
									catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
								)
							)
						)
					),
					catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onRegistered$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.registered),
			map(() => fromDeviceActions.loadDevicesForMachine())
		)
	);

	public onAuthenticateRequest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.authenticate),
			switchMap(({ userDevice }) =>
				this.userService.authenticateRequestDevice(userDevice).pipe(
					switchMap(response =>
						from(navigator.credentials.get({ publicKey: this.getPublicKeyForAuthentication(response.data) })).pipe(
							switchMap(credential =>
								this.userService.authenticateVerifyDevice(userDevice, response.data, this.getCredentialForAuthentication(credential)).pipe(
									map(response => fromDeviceActions.authenticated({ authToken: response.token, user: response.user })),
									catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
								)
							)
						)
					),
					catchError((response: IServiceErrorResponse) => of(fromDeviceActions.failed({ error: response.error })))
				)
			)
		)
	);

	private getPublicKeyForRegistration(publicKey: any): any {
		return {
			...publicKey,
			challenge: decode(publicKey.challenge),
			user: {
				...publicKey.user,
				id: decode(publicKey.user.id),
			},
		};
	}

	private getPublicKeyForAuthentication(publicKey: any): any {
		return {
			...publicKey,
			challenge: decode(publicKey.challenge),
			allowCredentials: publicKey.allowCredentials.map((allowCredentials: any) => ({
				...allowCredentials,
				id: decode(allowCredentials.id),
			})),
		};
	}

	private getCredentialForAuthentication(pubKeyCred: any): any {
		return {
			id: pubKeyCred.id,
			rawId: encode(pubKeyCred.rawId),
			response: {
				attestationObject: encode(pubKeyCred.response.attestationObject),
				clientDataJSON: encode(pubKeyCred.response.clientDataJSON),
				authenticatorData: encode(pubKeyCred.response.authenticatorData),
				signature: encode(pubKeyCred.response.signature),
				userHandle: encode(pubKeyCred.rawId),
			},
			type: pubKeyCred.type,
		};
	}
}
