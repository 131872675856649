import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IProcess } from '../../models/process';
import { AppState, IProcessState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { fromProcessActions } from './process.actions';
import { initialEditFormValue } from './process.reducer';
import { ProcessSelector } from './process.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessFacade extends EntityFacade<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromProcessActions;

	constructor(store: Store<AppState>, private processSelector: ProcessSelector) {
		super(store, processSelector);
	}

	public findByName(name: string): Observable<IProcess> {
		return this.store.select(this.processSelector.find(x => x.name == name));
	}
}
