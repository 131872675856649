import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IConfiguration } from 'src/models/configuration.model';
import { AppState, IConfigurationState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { fromConfigurationActions } from './configuration.actions';
import { initialEditFormValue } from './configuration.reducer';
import { ConfigurationSelector } from './configuration.selectors';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationFacade extends EntityFacade<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromConfigurationActions;

	constructor(store: Store<AppState>, private configurationSelector: ConfigurationSelector) {
		super(store, configurationSelector);
	}

	public single$ = this.createBehaviorSubject(this.store.select(this.configurationSelector.single), null);
}
