<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Abholort</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-select placeholder="Typ" formControlName="type">
						<mat-option *ngFor="let pickupStationType of PickupStationTypes | keyvalue" [value]="pickupStationType.value">{{ pickupStationType.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-checkbox formControlName="default">Standard</mat-checkbox>
				<div formGroupName="address">
					<mat-form-field appearance="outline">
						<mat-label>Straße</mat-label>
						<input type="text" matInput formControlName="street" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Hausnummer</mat-label>
						<input type="text" matInput formControlName="streetNumber" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>PLZ</mat-label>
						<input type="text" matInput formControlName="postCode" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Ort</mat-label>
						<input type="text" matInput formControlName="city" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Land</mat-label>
						<input type="text" matInput formControlName="country" />
					</mat-form-field>
				</div>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
