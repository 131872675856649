import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BirthdayBookAddDialogComponent } from '../../components/entitydialog/entitydialog-birthday-book-add.component';
import { BirthdayBookReplaceDialogComponent } from '../../components/entitydialog/entitydialog-birthday-book-replace.component';
import { BirthdayBookDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-birthday-book.component';
import { BirthdayBookDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-birthday-book.component';
import { BirthdayBookDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-birthday-book.component';
import { IArticleBirthdayBook, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm } from '../../models/article.model';
import { IArticleBirthdayBookState } from '../../state/app.state';
import { ArticleBirthdayBookFacade } from '../../state/article.birthday-book/article.birthday-book.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayBookDialogService extends EntityDialogService<IArticleBirthdayBook, IArticleBirthdayBookState, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm> {
	protected createComponent: ComponentType<any> = BirthdayBookDialogAddComponent;
	protected updateComponent: ComponentType<any> = BirthdayBookDialogChangeComponent;
	protected removeComponent: ComponentType<any> = BirthdayBookDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = BirthdayBookReplaceDialogComponent;
	protected addComponent: ComponentType<any> = BirthdayBookAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleBirthdayBookFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleBirthdayBook>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleBirthdayBook>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
