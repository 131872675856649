import { IAttachment, IAttachmentEditForm } from 'src/models/attachment.model';
import { EntityState, IAttachmentState } from '../app.state';

import { on } from '@ngrx/store';
import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IAttachmentFilterForm } from '../../models/attachment.model';
import { EntityReducer } from '../entity.reducer';
import { fromAttachmentActions } from './attachment.actions';

export const initialValue: IAttachment = {
	_id: null,
	title: null,
	file: null,
	version: null,
};

export const initialEditFormValue: IAttachmentEditForm = {
	_id: null,
	title: null,
	file: null,
	version: null,
};

export const initialFilterFormValue: IAttachmentFilterForm = {
	_id: null,
	createdAt: null,
	title: null,
	file: null,
	version: null,
};

export const initialState = EntityState.create<IAttachment, IAttachmentEditForm, IAttachmentFilterForm>(
	'ATTACHMENT',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			title: 'CONTAINS',
		},
	],
	{
		title: 1,
	}
);

export const attachmentReducer = EntityReducer.create<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm>(
	initialState,
	{
		title: validate(required),
	},
	fromAttachmentActions,
	on(fromAttachmentActions.createdAttachmentForm, state => ({ ...state, isFiltering: true })),
	on(fromAttachmentActions.updatedAttachmentForm, state => ({ ...state, isFiltering: true }))
);
