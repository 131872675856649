import { Boxed } from 'ngrx-forms';
import { IEntity, IEntityEditForm, IEntityFilterForm } from './entity.model';

export interface IStock extends IEntity {
	comment: string;
	product: string;
	storagePlace: string;
	quality: number;
	quantity: number;
	stockKind: StockKind;
}
export interface IStockEditForm extends IEntityEditForm {
	comment: string;
	product: string;
	storagePlace: string;
	quality: number;
	quantity: number;
	stockKind: StockKind;
}
export interface IStockFilterForm extends IEntityFilterForm {
	comment: string;
	product: string;
	storagePlace: string;
	quality: Boxed<number[]>;
	quantity: number;
	stockKind: StockKind;
}

export interface IStockAccessory extends IStock {}
export interface IStockAccessoryEditForm extends IStockEditForm {}
export interface IStockAccessoryFilterForm extends IStockFilterForm {}
export interface IStockMedia extends IStock {
	from: string;
	until: string;
	stockType: MediaStockTypeEnum;
}
export interface IStockMediaEditForm extends IStockEditForm {
	from: string;
	until: string;
	stockType: MediaStockTypeEnum;
}
export interface IStockMediaFilterForm extends IStockFilterForm {
	from: string;
	until: string;
	stockType: MediaStockTypeEnum;
}

export enum MediaStockTypeEnum {
	Loose = 'Lose',
	Bound = 'Gebunden',
}

export enum StockKind {
	StockMedia = 'MediaStock',
	StockAccessory = 'AccessoryStock',
}
