<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="(cartFacade.isLoading$ | async) || (orderFacade.isUpdating$ | async)"></mat-progress-bar>
<ng-container *ngIf="orderFacade.selected$ | async as entity">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Positionen bearbeiten</h1>
	<div mat-dialog-content>
		<div class="content-wrapper">
			<table mat-table [dataSource]="orderFacade.editFormPositions$ | async" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>Position/Artikel</th>
					<td mat-cell *matCellDef="let position" class="detail-header">
						{{ position.title }}
					</td>
				</ng-container>

				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef>Preis</th>
					<td mat-cell *matCellDef="let position" class="detail-header" width="100">
						{{ position.price | currency }}
					</td>
				</ng-container>

				<ng-container matColumnDef="articles">
					<td [attr.colspan]="positionColumns.length" mat-cell *matCellDef="let position" class="detail-cell">
						<table mat-table [dataSource]="orderFacade.getArticlesFromEditPosition(position) | async">
							<ng-container matColumnDef="image">
								<td mat-cell *matCellDef="let position" width="80"></td>
							</ng-container>

							<ng-container matColumnDef="title">
								<td mat-cell *matCellDef="let article">
									{{ article.product.name }} <ng-container *ngIf="article.publicationDate">vom {{ article.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="price">
								<td mat-cell *matCellDef="let article" width="100">
									{{ article?.product?.price | currency }}
								</td>
							</ng-container>

							<ng-container matColumnDef="priority">
								<td mat-cell *matCellDef="let article" width="150">
									<ng-container *ngIf="articleFacade.fetchOne(article?._id) | async as article">
										<babylon-process-priority-article [priority]="article.processInstance.priority"></babylon-process-priority-article>
									</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let article" class="align-right" width="250">
									<ng-container *ngIf="articleFacade.fetchOne(article?._id) | async as article">
										<button mat-icon-button matTooltip="Artikel bearbeiten" *ngIf="article?.articleKind == ArticleKinds.Certificate" (click)="articleDialogService.openUpdateDialog({ entity: article })">
											<mat-icon svgIcon="pencil"></mat-icon>
										</button>
										<ng-container *ngIf="processNodeFacade.fetchOne(article?.processInstance?.state) | async as processNode">
											<button
												mat-icon-button
												matTooltip="Artikel austauschen"
												(click)="articleDialogService.openReplaceDialog({ entity: article, position })"
												[disabled]="
													processNode.name != 'ArticleAvailable' &&
													processNode.name != 'ArticleReserved' &&
													processNode.name != 'ArticleReturn' &&
													processNode.name != 'ArticleDisabled' &&
													processNode.name != 'ArticleNotAvailable' &&
													processNode.name != 'ArticleOrder' &&
													processNode.name != 'ArticleOrdered' &&
													processNode.name != 'ArticleCommissioned' &&
													processNode.name != 'ArticleHandOver'
												">
												<mat-icon svgIcon="sync"></mat-icon>
											</button>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!article._id">
										<button mat-icon-button matTooltip="Artikel austauschen" (click)="articleDialogService.openReplaceDialog({ entity: article, position })">
											<mat-icon svgIcon="sync"></mat-icon>
										</button>
									</ng-container>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: positionColumns"></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="priority">
					<th mat-header-cell *matHeaderCellDef>Priorität</th>
					<td mat-cell *matCellDef="let position" class="detail-header" width="150"></td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef class="align-right">
						<button mat-icon-button matTooltip="Position hinzufügen" (click)="addPosition()" color="accent">
							<mat-icon svgIcon="plus"></mat-icon>
						</button>
					</th>
					<td mat-cell *matCellDef="let position" class="detail-header align-right" width="250">
						<button mat-icon-button matTooltip="Position bearbeiten" (click)="updatePosition(position)">
							<mat-icon svgIcon="pencil"></mat-icon>
						</button>
						<button mat-icon-button matTooltip="Position entfernen" (click)="removePosition(position)" *ngIf="position.positionKind == PositionKinds.Article || position.positionKind == PositionKinds.Bundle">
							<mat-icon svgIcon="trash-can-outline"></mat-icon>
						</button>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="positionColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: positionColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: ['articles']" class="detail-row" [hidden]="row.positionKind != PositionKinds.Article && row.positionKind != PositionKinds.Bundle"></tr>
			</table>
		</div>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
			<ng-container *ngIf="processNodeFacade.fetchOne(receipt.processInstance.state) | async as processNode">
				<button mat-flat-button color="default" (click)="orderFacade.updatePositions(false)" [disabled]="(cartFacade.isLoading$ | async) || (orderFacade.isUpdating$ | async)" *ngIf="processNode.name != 'ReceiptInitialized'">
					Speichern ohne Rechnungskorrektur
				</button>
				<button mat-flat-button color="accent" (click)="orderFacade.updatePositions(true)" [disabled]="(cartFacade.isLoading$ | async) || (orderFacade.isUpdating$ | async)" *ngIf="processNode.name != 'ReceiptInitialized'">
					Rechnungskorrektur
				</button>
				<button mat-flat-button color="accent" (click)="orderFacade.updatePositions(true)" [disabled]="(cartFacade.isLoading$ | async) || (orderFacade.isUpdating$ | async)" *ngIf="processNode.name == 'ReceiptInitialized'">
					Speichern
				</button>
			</ng-container>
		</ng-container>
	</mat-dialog-actions>
</ng-container>
