import { IArticleMagazineEditForm, IArticleMagazineFilterForm } from '../../models/article.model';
import { AppState, IArticleMagazineState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleMagazine } from '../../models/article.model';
import { EntityFacade } from '../entity.facade';
import { fromArticleMagazineActions } from './article.magazine.actions';
import { initialEditFormValue } from './article.magazine.reducer';
import { ArticleMagazineSelector } from './article.magazine.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleMagazineFacade extends EntityFacade<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleMagazineActions;

	constructor(store: Store<AppState>, private magazineSelector: ArticleMagazineSelector) {
		super(store, magazineSelector);
	}
	public isSearching$ = this.store.select(this.magazineSelector.isSearching);
	public isExtending$ = this.store.select(this.magazineSelector.isExtending);
	public itemsSearched$ = this.store.select(this.magazineSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.magazineSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleMagazineActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}

	public updateStoragePlace(entity: IArticleMagazine, storagePlaceId: string): void {
		this.store.dispatch(this.actions.updateStoragePlace({ entity, storagePlaceId: storagePlaceId }));
	}
}
