import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { FilterConnection, IAccessoryProductState, IFilterDescriptor, SortDescriptors } from '../../state/app.state';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-accessory-product-autocompletesingle',
	templateUrl: './entityautocompletesingle-accessory-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class AccessoryProductAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	public placeholder = 'Produkt';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IAccessoryProduct> = () => ({ name: 1 });

	constructor(entityFacade: AccessoryProductFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IAccessoryProduct): string {
		return entity?.name;
	}
}
