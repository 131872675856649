import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AggregationService } from '../../services/api/aggregation.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAggregationActions } from './aggregation.actions';
import { AggregationSelector } from './aggregation.selectors';

@Injectable({
	providedIn: 'root',
})
export class AggregationEffects extends BaseEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private sessionSelector: SessionSelector, private aggregationSelector: AggregationSelector, private aggregationService: AggregationService) {
		super();
	}

	public onChangeTimespan$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.changeTimespan, fromAggregationActions.changeFrom, fromAggregationActions.changeUntil),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.from)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.until)),
			map(([[, from], until]) => fromAggregationActions.changeGranularity({ granularity: moment(until).diff(moment(from), 'days') > 31 ? 'Monatlich' : 'Täglich' }))
		)
	);

	public onChangeGranularity$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.changeGranularity),
			switchMap(() => [fromAggregationActions.loadOrderCount(), fromAggregationActions.loadReceiptTotalPrice()])
		)
	);

	public onLoadOrderCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadOrderCount),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.from)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.until)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.granularity)),
			switchMap(([[[[, authToken], from], until], granularity]) =>
				this.aggregationService.aggregateOrderCount(authToken, from, until, granularity).pipe(
					map(result => fromAggregationActions.loadedOrderCount({ data: result.data })),
					catchError((response: IServiceErrorResponse) => of(fromAggregationActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLoadOrderState$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadOrderState),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.from)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.until)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.granularity)),
			mergeMap(([[[[{ displayName, routeName }, authToken], from], until], granularity]) =>
				this.aggregationService.aggregateOrderState(authToken, from, until, granularity, routeName).pipe(
					map(result => fromAggregationActions.loadedOrderState({ data: result.data, displayName, routeName })),
					catchError((response: IServiceErrorResponse) => of(fromAggregationActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLoadReceiptTotalPrice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadReceiptTotalPrice),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.from)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.until)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.granularity)),
			switchMap(([[[[, authToken], from], until], granularity]) =>
				this.aggregationService.aggregateReceiptTotalPrice(authToken, from, until, granularity).pipe(
					map(result => fromAggregationActions.loadedReceiptTotalPrice({ data: result.data })),
					catchError((response: IServiceErrorResponse) => of(fromAggregationActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLoadReceiptState$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadReceiptState),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.from)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.until)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.granularity)),
			mergeMap(([[[[{ displayName, routeName }, authToken], from], until], granularity]) =>
				this.aggregationService.aggregateReceiptState(authToken, from, until, granularity, routeName).pipe(
					map(result => fromAggregationActions.loadedReceiptState({ data: result.data, displayName, routeName })),
					catchError((response: IServiceErrorResponse) => of(fromAggregationActions.failed({ error: response.error })))
				)
			)
		)
	);
}
