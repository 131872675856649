import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PaypalNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-paypal.component';
import { IPaymentPaypal } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PaypalNotificationService extends EntityNotificationService<IPaymentPaypal> {
	protected component: ComponentType<any> = PaypalNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
