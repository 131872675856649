import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { YearBookNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-yearbook.component';
import { IArticleYearBook } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleYearArticleBookNotificationService extends EntityNotificationService<IArticleYearBook> {
	protected component: ComponentType<any> = YearBookNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
