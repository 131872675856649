<ng-container *ngIf="entity; else useEditForm">
	<ng-container *ngIf="entity.processInstance != null">
		<ng-container *ngFor="let route of processRouteFacade.findBySourceNode(entity.processInstance.state) | async">
			<ng-container *ngIf="route.isVisible || (sessionFacade.administrateProcessRoutes$ | async)">
				<button
					mat-flat-button
					*ngIf="mode == 'text'"
					(click)="execute(entity, route)"
					[class.mat-button-disabled]="disabled || (facade.isFiltering$ | async)"
					[disabled]="disabled || (facade.isFiltering$ | async)"
					[color]="route.color">
					{{ route.displayName }}
				</button>
				<button
					mat-icon-button
					*ngIf="mode == 'icon'"
					(click)="execute(entity, route)"
					[class.mat-button-disabled]="disabled || (facade.isFiltering$ | async)"
					[disabled]="disabled || (facade.isFiltering$ | async)"
					[matTooltip]="route.displayName">
					<mat-icon [svgIcon]="route.matIconName || 'alert-circle-outline'"></mat-icon>
				</button>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-container>
<ng-template #useEditForm>
	<ng-container *ngIf="facade.editFormValue$ | async as editFormValue">
		<ng-container *ngIf="editFormValue.processInstance != null">
			<ng-container *ngFor="let route of processRouteFacade.findBySourceNode(editFormValue.processInstance.state) | async">
				<ng-container *ngIf="route.isVisible || (sessionFacade.administrateProcessRoutes$ | async)">
					<button
						mat-flat-button
						*ngIf="mode == 'text'"
						(click)="execute(editFormValue, route)"
						[class.mat-button-disabled]="disabled || (facade.isFiltering$ | async)"
						[disabled]="disabled || (facade.isFiltering$ | async)"
						[color]="route.color">
						{{ route.displayName }}
					</button>
					<button
						mat-icon-button
						*ngIf="mode == 'icon'"
						(click)="execute(editFormValue, route)"
						[class.mat-button-disabled]="disabled || (facade.isFiltering$ | async)"
						[disabled]="disabled || (facade.isFiltering$ | async)"
						[matTooltip]="route.displayName">
						<mat-icon [svgIcon]="route.matIconName || 'alert-circle-outline'"></mat-icon>
					</button>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>
