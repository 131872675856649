import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAttachment, IAttachmentEditForm } from 'src/models/attachment.model';
import { IAttachmentState } from 'src/state/app.state';
import { AttachmentFacade } from 'src/state/attachment/attachment.facade';
import { IAttachmentFilterForm } from '../../models/attachment.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-picturedialogchange',
	templateUrl: './entitydialogchange-attachment.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class AttachmentDialogChangeComponent extends EntityDialogChangeComponent<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	private attachmentFile: File = null;

	constructor(private attachmentFacade: AttachmentFacade, matDialogRef: MatDialogRef<any>) {
		super(attachmentFacade, matDialogRef);
	}

	public onAttachmentChanged($event: any): void {
		this.attachmentFile = $event.target.files[0];
	}

	public updateAttachment(attachment: IAttachment) {
		if (this.attachmentFile != null) {
			this.attachmentFacade.updatedAttachment(attachment, this.attachmentFile);
		} else {
			this.attachmentFacade.updated();
		}
	}
}
