import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDocument, IDocumentEditForm } from 'src/models/document.model';
import { IDocumentState } from 'src/state/app.state';
import { DocumentFacade } from 'src/state/document/document.facade';
import { IDocumentFilterForm } from '../../models/document.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-documentdialogdelete',
	templateUrl: './entitydialogdelete-document.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class DocumentDialogDeleteComponent extends EntityDialogDeleteComponent<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	constructor(entityFacade: DocumentFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
