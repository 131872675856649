import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IReceiptInvoice, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm } from '../../models/receipt';
import { AppState, IReceiptInvoiceState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromInvoiceActions } from './receipt.invoice.actions';
import { initialEditFormValue } from './receipt.invoice.reducer';
import { InvoiceSelector } from './receipt.invoice.selectors';

@Injectable({
	providedIn: 'root',
})
export class InvoiceFacade extends EntityFacade<IReceiptInvoice, IReceiptInvoiceState, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromInvoiceActions;

	constructor(store: Store<AppState>, invoiceSelector: InvoiceSelector) {
		super(store, invoiceSelector);
	}
}
