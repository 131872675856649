import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPaymentTypeInformation } from '../../models/configuration.model';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-payment-typedialog',
	templateUrl: './dialog-payment-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PaymentTypeDialogComponent extends DialogComponent<PaymentTypeDialogComponent, IPaymentTypeInformation> {
	public PaymentKinds = PaymentKind;

	constructor(matDialogRef: MatDialogRef<PaymentTypeDialogComponent, IPaymentTypeInformation>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPaymentTypeInformation) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				price: [data.price, [Validators.required]],
				paymentKind: [data.paymentKind, []],
				emailText: [data.emailText, []],
				pdfText: [data.pdfText, []],
			})
		);
	}
}
