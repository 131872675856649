import { box } from 'ngrx-forms';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { EntityState, IShippingReturnState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromReturnActions } from './shipping.return.actions';

export const initialValue: IShippingReturn = {
	_id: null,
	order: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IShippingReturnEditForm = {
	_id: null,
	order: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IShippingReturnFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	deliveryNote: box([]),
	shippingKind: null,
	processInstance: null,
};

export const initialState = EntityState.create<IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm>('RETURN', initialValue, initialEditFormValue, initialFilterFormValue);
export const returnReducer = EntityReducer.create<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm>(initialState, {}, fromReturnActions);
