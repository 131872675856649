import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ShippingNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-shipping.component';
import { IShipping } from '../../models/shipping.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingNotificationService extends EntityNotificationService<IShipping> {
	protected component: ComponentType<any> = ShippingNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
