import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AccountingSummaryKind, IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AccountingSummaryFacade } from '../../state/accounting-summary/accounting-summary.facade';
import { IAccountingSummaryState } from '../../state/app.state';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-accounting-summarydialogadd',
	templateUrl: './entitydialogadd-accounting-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class AccountingSummaryDialogAddComponent extends EntityDialogAddComponent<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	public AccountingSummaryKinds = AccountingSummaryKind;

	constructor(entityFacade: AccountingSummaryFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
