import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState } from './app.state';

@Injectable({
	providedIn: 'root',
})
export abstract class BaseSelector<T> {
	protected selectState = createSelector(
		(state: AppState) => this.stateSelector(state),
		state => state
	);

	protected abstract stateSelector(state: AppState): T;
}
