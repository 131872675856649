import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CounterDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-counter.component';
import { ICounter } from 'src/models/counter.model';
import { ICounterState } from 'src/state/app.state';
import { CounterFacade } from 'src/state/counter/counter.facade';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class CounterDialogService extends EntityDialogService<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	protected updateComponent: ComponentType<any> = CounterDialogChangeComponent;

	constructor(matDialog: MatDialog, entityFacade: CounterFacade) {
		super(matDialog, entityFacade);
	}
}
