import { EntityState, IPaymentState } from '../app.state';
import { IPayment, IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';

import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { EntityReducer } from '../entity.reducer';
import { fromPaymentActions } from './payment.actions';

export const initialValue: IPayment = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentEditForm = {
	_id: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentFilterForm = {
	_id: null,
	createdAt: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: box([]),
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPayment, IPaymentEditForm, IPaymentFilterForm>('PAYMENT', initialValue, initialEditFormValue, initialFilterFormValue, [
	{
		paymentKind: 'IN',
		createdAt: 'GTE',
		dueAmount: 'EQ',
		processInstance: {
			state: 'IN',
		},
	},
]);
export const paymentReducer = EntityReducer.create<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm>(
	initialState,
	{},
	fromPaymentActions,
	on(fromPaymentActions.changePaymentKind, state => ({ ...state, isFiltering: true })),
	on(fromPaymentActions.changedPaymentKind, state => ({ ...state, isFiltering: false }))
);
