import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IShippingTypeInformation } from '../../models/configuration.model';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-shipping-typedialog',
	templateUrl: './dialog-shipping-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ShippingTypeDialogComponent extends DialogComponent<ShippingTypeDialogComponent, IShippingTypeInformation> {
	public ShippingKinds = ShippingKind;

	constructor(matDialogRef: MatDialogRef<ShippingTypeDialogComponent, IShippingTypeInformation>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IShippingTypeInformation) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				price: [data.price, [Validators.required]],
				priceSaturdayDelivery: [data.priceSaturdayDelivery, []],
				shippingKind: [data.shippingKind, [Validators.required]],
				shippingDaysMin: [data.shippingDaysMin, [Validators.required]],
				shippingDaysMax: [data.shippingDaysMax, [Validators.required]],
				shippedBeforeHour: [data.shippedBeforeHour, [Validators.required]],
				password: [data.password, [Validators.required]],
			})
		);
	}
}
