import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { FilterConnection, IFilterDescriptor, ITextTemplateState, SortDescriptors } from '../../state/app.state';
import { TextTemplateFacade } from '../../state/text-template/text-template.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-text-template-autocompletesingle',
	templateUrl: './entityautocompletesingle-text-template.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class TextTemplateAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	public placeholder = 'Textvorlage';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'title', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<ITextTemplate> = () => ({ title: 1 });

	constructor(entityFacade: TextTemplateFacade) {
		super(entityFacade);
	}

	public displayWith(entity: ITextTemplate): string {
		return entity?.title;
	}
}
