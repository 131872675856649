import { Injectable } from '@angular/core';
import { IProduct } from '../../models/product.model';
import { AppState, IProductState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';

@Injectable({
	providedIn: 'root',
})
export class ProductSelector extends EntitySelector<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	protected stateSelector = (state: AppState): IProductState => state.product;
}
