import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DhlExpressNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-dhl-express.component';
import { IShippingDhlExpress } from '../../models/shipping.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DhlExpressNotificationService extends EntityNotificationService<IShippingDhlExpress> {
	protected component: ComponentType<any> = DhlExpressNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
