import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StatisticsApiService } from '../../services/api/statistics.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromStatisticsActions } from './statistics.actions';

@Injectable({
	providedIn: 'root',
})
export class StatisticsEffects extends BaseEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private sessionSelector: SessionSelector, private statisticsService: StatisticsApiService) {
		super();
	}

	public onCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromStatisticsActions.count),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([, authToken]) =>
				this.statisticsService.count(authToken).pipe(
					map(result => fromStatisticsActions.counted({ processNodeCounts: result.data })),
					catchError(() => of(fromStatisticsActions.counted({ processNodeCounts: {} })))
				)
			)
		)
	);
}
