import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStoragePlace } from '../../models/storage-place.model';
import { IStoragePlaceState } from '../../state/app.state';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-storage-placedialogdelete',
	templateUrl: './entitydialogdelete-storage-place.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class StoragePlaceDialogDeleteComponent extends EntityDialogDeleteComponent<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	constructor(entityFacade: StoragePlaceFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
