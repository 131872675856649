import { IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCollectionPurchase } from '../../models/payment.model';
import { IPaymentCollectionPurchaseState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { CollectionPurchaseFacade } from '../../state/payment.collection-purchase/payment.collection-purchase.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-collection-purchasedialogchange',
	templateUrl: './entitydialogchange-collection-purchase.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CollectionPurchaseDialogChangeComponent extends EntityDialogChangeComponent<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	constructor(entityFacade: CollectionPurchaseFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
