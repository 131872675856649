import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MailTemplateDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-mailtemplate.component';
import { MailTemplateDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-mailtemplate.component';
import { MailTemplateDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-mailtemplate.component';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { IMailTemplateState } from 'src/state/app.state';
import { MailTemplateFacade } from 'src/state/mailtemplate/mailtemplate.facade';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateDialogService extends EntityDialogService<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm> {
	protected createComponent: ComponentType<any> = MailTemplateDialogAddComponent;
	protected updateComponent: ComponentType<any> = MailTemplateDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MailTemplateDialogDeleteComponent;

	constructor(matDialog: MatDialog, mailTemplateFacade: MailTemplateFacade) {
		super(matDialog, mailTemplateFacade);
	}
}
