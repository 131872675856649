import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticlePrice, IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { ArticlePriceDialogComponent } from '../dialog/dialog-article-price.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-article-pricestable',
	templateUrl: './entitydialogtable-article-prices.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class ArticlePricesTableComponent extends EntityDialogTableComponent<IPartner, IPartnerState, IArticlePrice, IArticlePrice, IPartnerEditForm, IPartnerFilterForm> {
	public columns = ['articleKind', 'price', 'edit'];

	constructor(entityFacade: PartnerFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IPartner): IArticlePrice[] {
		return entity.articlePrices;
	}

	protected createElement(): IArticlePrice {
		return {
			articleKind: null,
			price: null,
		};
	}

	public openEditDialog(data: IArticlePrice, index: number): void {
		const dialogRef = this.matDialog.open(ArticlePriceDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IArticlePrice) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
