import { Injectable } from '@angular/core';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { AppState, ITextTemplateState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class TextTemplateSelector extends EntitySelector<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	protected stateSelector = (state: AppState): ITextTemplateState => state.textTemplate;
}
