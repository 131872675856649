<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lagerplätze geändert</h1>

	<p>Lagerplätze erfolgreich geändert.</p>
	<p>Filterung auf neue Lagerplätze anwenden?</p>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<button mat-flat-button color="accent" (click)="setStoragePlaceFilter()">Ja</button>
	</mat-dialog-actions>
</ng-container>
