import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { IDataResponse } from './api.service';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateApiService extends EntityApiService<IMailTemplate> {
	protected entityName = 'mail-templates';
	protected compareValue = {
		receivers: (value: string) => value || '',
	};

	constructor(http: HttpClient) {
		super(http);
	}

	public test(entity: IMailTemplate, receivers: string, authToken: string): Observable<IDataResponse<string>> {
		const to = receivers.split(';').map(x => x.trim());
		return this.post<IDataResponse<string>, { to: string[] }>([this.entityName, entity._id, 'test'], { to }, this.getHeaders(authToken));
	}
}
