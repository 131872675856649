import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BirthdayBookNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-birthday-book.component';
import { IArticleBirthdayBook } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayArticleBookNotificationService extends EntityNotificationService<IArticleBirthdayBook> {
	protected component: ComponentType<any> = BirthdayBookNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
