import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleYearBook } from '../../models/article.model';
import { IArticleYearBookState } from '../../state/app.state';
import { ArticleYearBookFacade } from '../../state/article.yearbook/article.yearbook.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleYearBookEditForm, IArticleYearBookFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-yearbookdialogchange',
	templateUrl: './entitydialogchange-yearbook.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class YearBookDialogChangeComponent extends EntityDialogChangeComponent<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	constructor(entityFacade: ArticleYearBookFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
