import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IOrderState } from 'src/state/app.state';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { PriorityType } from '../../models/process-instance-model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { OrderDialogService } from '../../services/dialog/order.service';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-ordersearchdialog',
	templateUrl: './entitydialog-order-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class OrderSearchDialogComponent extends EntityDialogComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	public PriorityTypes = Object.fromEntries(Object.entries(PriorityType).filter(([key]) => isNaN(parseInt(key))));
	public columns = ['customer', 'number', 'createdAt', 'totalPrice', 'orderState', 'prio', 'edit'];

	constructor(
		public orderDialogService: OrderDialogService,
		public orderFacade: OrderFacade,
		public customerFacade: CustomerFacade,
		public partnerFacade: PartnerFacade,
		public receiptFacade: ReceiptFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public shippingFacade: ShippingFacade,
		public processNodeFacade: ProcessNodeFacade,
		matDialogRef: MatDialogRef<OrderSearchDialogComponent, IEntityDialogData<IOrder>>
	) {
		super(orderFacade, matDialogRef);
	}

	public get columnsHeader(): string[] {
		return this.columns.map(x => `${x}-header`);
	}

	public get columnsContent(): string[] {
		return this.columns.map(x => `${x}-content`);
	}
}
