import { EntityState, IStoragePlaceState } from '../app.state';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';

import { EntityReducer } from '../entity.reducer';
import { IStoragePlace } from '../../models/storage-place.model';
import { fromStoragePlaceActions } from './storage-place.actions';
import { required } from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';

export const initialValue: IStoragePlace = {
	_id: null,
	processInstance: null,
	identifier: null,
	room: null,
	comment: null,
};

export const initialEditFormValue: IStoragePlaceEditForm = {
	_id: null,
	processInstance: null,
	identifier: null,
	room: null,
	comment: null,
};

export const initialFilterFormValue: IStoragePlaceFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	identifier: null,
	room: null,
	comment: null,
};

export const initialState = EntityState.create<IStoragePlace, IStoragePlaceEditForm, IStoragePlaceFilterForm>(
	'STORAGEPLACE',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			identifier: 'CONTAINS',
			room: 'CONTAINS',
			comment: 'CONTAINS',
		},
	],
	{
		identifier: 1,
	}
);

export const storagePlaceReducer = EntityReducer.create<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm>(
	initialState,
	{
		identifier: validate(required),
		room: validate(required),
	},
	fromStoragePlaceActions
);
