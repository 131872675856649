import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleCertificate, IArticleCertificateEditForm, IArticleCertificateFilterForm, IExpandedArticleCertificate } from '../../models/article.model';
import { destructFormGroupValue } from '../../types/form';
import { AppState, IArticleCertificateState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleCertificateSelector extends EntitySelector<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	protected stateSelector = (state: AppState): IArticleCertificateState => state.articleCertificate;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);

	public expandedEditForm = createSelector(this.selectState, state => state.expandedEditForm);
	public expandedEditFormValue = createSelector(this.expandedEditForm, form => destructFormGroupValue<IExpandedArticleCertificate>(form.value));
}
