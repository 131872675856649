import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IFolderRating } from '../../models/article-rating';
import { IAccessoryArticleRequest, IPaging } from '../../models/article-request';
import { IArticleFolder, IExpandedArticleFolder } from '../../models/article.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleFolderApiService extends EntityApiService<IArticleFolder> {
	protected entityName = 'folders';

	constructor(http: HttpClient) {
		super(http);
	}

	public search(request: IAccessoryArticleRequest, paging?: IPaging): Observable<IExpandedArticleFolder[]> {
		const route = [this.entityName, 'search'];

		if (paging != null) {
			route.push(`?skip=${paging.skip}&top=${paging.top}`);
		}

		return this.post<IEntityListResponse<IFolderRating>, IAccessoryArticleRequest>(route, request).pipe(map(ratings => ratings.data.map(rating => rating.article)));
	}
}
