import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMediaBrandState } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-media-branddialogchange',
	templateUrl: './entitydialogchange-media-brand.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class MediaBrandDialogChangeComponent extends EntityDialogChangeComponent<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	constructor(entityFacade: MediaBrandFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
