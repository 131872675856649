import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAction } from '../../models/process-instance-model';
import { IStockAccessory } from '../../models/stock.model';
import { IStockAccessoryState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-accessory-stockhistorytable',
	templateUrl: './entitydialogtable-accessory-stockhistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class AccessoryStockHistoryTableComponent extends HistoryDialogTableComponent<IStockAccessory, IStockAccessoryState, IAction, IAction, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	public columns = ['date', 'userName', 'route', 'state', 'comment'];

	constructor(entityFacade: AccessoryStockFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IStockAccessory): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
