import { Injectable } from '@angular/core';
import { IReceiptInvoice, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm } from '../../models/receipt';
import { AppState, IReceiptInvoiceState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class InvoiceSelector extends EntitySelector<IReceiptInvoice, IReceiptInvoiceState, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {
	protected stateSelector = (state: AppState): IReceiptInvoiceState => state.receiptInvoice;
}
