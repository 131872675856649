import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentDirectDebit } from '../../models/payment.model';
import { IPaymentDirectDebitState } from '../../state/app.state';
import { DirectDebitFacade } from '../../state/payment.direct-debit/payment.direct-debit.facade';
import { IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm } from './../../models/payment.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-direct-debitdialogadd',
	templateUrl: './entitydialogadd-direct-debit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class DirectDebitDialogAddComponent extends EntityDialogAddComponent<IPaymentDirectDebit, IPaymentDirectDebitState, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {
	constructor(entityFacade: DirectDebitFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
