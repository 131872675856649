import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCash } from '../../models/payment.model';
import { IPaymentCashState } from '../../state/app.state';
import { CashFacade } from '../../state/payment.cash/payment.cash.facade';
import { IPaymentCashEditForm, IPaymentCashFilterForm } from './../../models/payment.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-cashdialogdelete',
	templateUrl: './entitydialogdelete-cash.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CashDialogDeleteComponent extends EntityDialogDeleteComponent<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	constructor(entityFacade: CashFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
