<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<mat-dialog-content class="picking-dialog">
		<form>
			<div class="form-grid">
				<div class="form-grid--left">
					<h3 class="mat-h3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">
						<ng-container *ngIf="productFacade.fetchOne(entity.product) | async as product">
							{{ product.name }}<br /><ng-container *ngIf="entity.publicationDate">vom {{ entity.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
						</ng-container>
					</h3>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div class="form-grid">
				<div class="form-grid--left">
					<mat-form-field appearance="outline">
						<mat-label>Kommentar</mat-label>
						<textarea matInput rows="18" [(ngModel)]="comment" [ngModelOptions]="{ standalone: true }"></textarea>
					</mat-form-field>
				</div>
				<div class="form-grid--right" *ngIf="entity | asCertificate as certificate">
					<table class="mat-table">
						<tr class="mat-row" *ngIf="certificate.anniversary">
							<td class="mat-cell">Jubiläum</td>
							<td class="mat-cell">{{ certificate.anniversary }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">Geschenkdatum</td>
							<td class="mat-cell">{{ certificate.date | amDateFormat: 'DD.MM.YYYY (dd)' }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">Geschenkanlass</td>
							<td class="mat-cell">{{ certificate.customOccasion || certificate.occasion }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">Anredeform</td>
							<td class="mat-cell">{{ certificate.addressForm }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">Beschenkte*r*</td>
							<td class="mat-cell">
								{{ certificate.giftee }}<ng-container *ngIf="certificate.partner"> / {{ certificate.partner }}</ng-container>
							</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">Glückwunschtext</td>
							<td class="mat-cell">{{ certificate.message }}</td>
						</tr>
					</table>
					<br />
					<button mat-button (click)="articleDialogService.openUpdateDialog({ entity })" color="accent"><mat-icon svgIcon="pencil"></mat-icon> Daten Bearbeiten</button>
				</div>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<button mat-flat-button color="default" (click)="certificateFacade.download(entity)" *ngIf="entity.articleKind == ArticleKinds.Certificate">Herunterladen</button>
		<babylon-process-buttons [facade]="articleFacade" [comment]="comment"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
