import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { PublicationCycleDialogService } from '../../services/dialog/publication-cycle.service';
import { IPublicationCycleState } from '../../state/app.state';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-publication-cyclepage',
	templateUrl: './entitypage-publication-cycle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class PublicationCyclePageComponent extends EntityPageComponent<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	public pageTitle$ = new BehaviorSubject('Erscheinungszeiträume');
	public columns = ['name', 'cronexpression', 'filter'];

	constructor(titleService: Title, entityDialogService: PublicationCycleDialogService, route: ActivatedRoute, entityFacade: PublicationCycleFacade) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
