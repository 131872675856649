import { createAction, props } from '@ngrx/store';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm } from '../../models/collection-purchase-transfer.model';
import { EntityActions } from '../entity.actions';

export const fromCollectionPurchaseTransferActions = {
	...EntityActions.create<ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm>('[CollectionPurchaseTransfer]'),
	downloadCsv: createAction(`[CollectionPurchaseTransfer] Download CSV`, props<{ entity: ICollectionPurchaseTransfer }>()),
	downloadedCsv: createAction(`[CollectionPurchaseTransfer] Downloaded CSV`, props<{ entity: ICollectionPurchaseTransfer; data: any }>()),
	filterByReceiptNumbers: createAction(`[CollectionPurchaseTransfer] Filter By Receipt Number`, props<{ receiptNumbers: number[] }>()),
	filteredByReceiptNumber: createAction(`[CollectionPurchaseTransfer] Filtered By Receipt Number`, props<{ entities: ICollectionPurchaseTransfer[] }>()),
};
