import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DirectDebitTransferDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-direct-debit-transfer.component';
import { DirectDebitTransferDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-direct-debit-transfer.component';
import { DirectDebitTransferDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-direct-debit-transfer.component';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../../models/direct-debit-transfer.model';
import { IDirectDebitTransferState } from '../../state/app.state';
import { DirectDebitTransferFacade } from '../../state/direct-debit-transfer/direct-debit-transfer.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferDialogService extends EntityDialogService<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {
	protected createComponent: ComponentType<any> = DirectDebitTransferDialogAddComponent;
	protected updateComponent: ComponentType<any> = DirectDebitTransferDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DirectDebitTransferDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DirectDebitTransferFacade) {
		super(matDialog, entityFacade);
	}
}
