import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleChronicle } from '../../models/article.model';
import { IArticleChronicleState } from '../../state/app.state';
import { ArticleChronicleFacade } from '../../state/article.chronicle/article.chronicle.facade';
import { IArticleChronicleEditForm, IArticleChronicleFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-chronicledialogadd',
	templateUrl: './entitydialogadd-chronicle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ChronicleDialogAddComponent extends EntityDialogAddComponent<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	constructor(entityFacade: ArticleChronicleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
