import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface IResponse {
	count: number;
	totalCount: number;
	success: boolean;
}

export interface IDataResponse<TResponse> extends IResponse {
	data: TResponse;
}

export interface IServiceErrorResponse {
	error: IServiceError;
}

export interface IServiceError {
	message: string;
	errorCode: number;
	statusCode: number;
}

@Injectable({
	providedIn: 'root',
})
export abstract class ApiService {
	constructor(private http: HttpClient) {}

	public get<TResponse>(path: string[], headers?: HttpHeaders, responseType: any = 'json', params: any = {}): Observable<TResponse> {
		return this.http.get<TResponse>(this.combineRootWithPath(path), { headers, responseType, params });
	}

	public post<TResponse, TBody>(path: string[], body: TBody, headers?: HttpHeaders, responseType: any = 'json', params: any = {}): Observable<TResponse> {
		return this.http.post<TResponse>(this.combineRootWithPath(path), body, { headers, responseType, params });
	}

	public put<TResponse, TBody>(path: string[], body: TBody, headers?: HttpHeaders, responseType: any = 'json'): Observable<TResponse> {
		return this.http.put<TResponse>(this.combineRootWithPath(path), body, { headers, responseType });
	}

	public patch<TResponse, TBody>(path: string[], body: TBody, headers?: HttpHeaders, responseType: any = 'json'): Observable<TResponse> {
		return this.http.patch<TResponse>(this.combineRootWithPath(path), body, { headers, responseType });
	}

	public delete<TResponse>(path: string[], headers?: HttpHeaders, responseType: any = 'json'): Observable<TResponse> {
		return this.http.delete<TResponse>(this.combineRootWithPath(path), { headers, responseType });
	}

	protected combineRootWithPath(path: string[]): string {
		return this.pathJoin([`${environment.serverUrl}:${environment.serverPort}`, environment.apiUrl, ...path]);
	}

	protected pathJoin(parts: string[]): string {
		return parts.filter(x => x != null && x.trim() !== '').join('/');
	}

	protected getHeaders(authToken: string): HttpHeaders {
		let headers = new HttpHeaders();

		if (authToken != null) {
			headers = headers.set('Authorization', `Bearer ${authToken}`);
		}

		return headers;
	}
}
