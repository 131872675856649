import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PickupDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-pickup.component';
import { PickupDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-pickup.component';
import { PickupDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-pickup.component';
import { IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm } from '../../models/shipping.model';
import { IShippingPickupState } from '../../state/app.state';
import { PickupFacade } from '../../state/shipping.pickup/shipping.pickup.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingPickupDialogService extends EntityDialogService<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	protected createComponent: ComponentType<any> = PickupDialogAddComponent;
	protected updateComponent: ComponentType<any> = PickupDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PickupDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PickupFacade) {
		super(matDialog, entityFacade);
	}
}
