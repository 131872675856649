import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChronicleAddDialogComponent } from '../../components/entitydialog/entitydialog-chronicle-add.component';
import { ChronicleReplaceDialogComponent } from '../../components/entitydialog/entitydialog-chronicle-replace.component';
import { ChronicleDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-chronicle.component';
import { ChronicleDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-chronicle.component';
import { ChronicleDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-chronicle.component';
import { IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm } from '../../models/article.model';
import { IArticleChronicleState } from '../../state/app.state';
import { ArticleChronicleFacade } from '../../state/article.chronicle/article.chronicle.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleChronicleDialogService extends EntityDialogService<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	protected createComponent: ComponentType<any> = ChronicleDialogAddComponent;
	protected updateComponent: ComponentType<any> = ChronicleDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ChronicleDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = ChronicleReplaceDialogComponent;
	protected addComponent: ComponentType<any> = ChronicleAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleChronicleFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleChronicle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleChronicle>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
