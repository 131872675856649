<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lastschrifteinzug ändern</h1>
	<mat-dialog-content>
		<mat-tab-group>
			<mat-tab label="Positionen">
				<table mat-table [dataSource]="editForm.controls.positions.value">
					<ng-container matColumnDef="orderNumber">
						<th mat-header-cell *matHeaderCellDef>
							<div>Bestellung</div>
						</th>
						<td mat-cell *matCellDef="let position">{{ position.orderNumber }}</td>
					</ng-container>

					<ng-container matColumnDef="customerName">
						<th mat-header-cell *matHeaderCellDef>Kunde</th>
						<td mat-cell *matCellDef="let position">
							{{ position.customerName }}
						</td>
					</ng-container>

					<ng-container matColumnDef="date">
						<th mat-header-cell *matHeaderCellDef>Datum</th>
						<td mat-cell *matCellDef="let position">
							{{ position.date | amDateFormat: 'DD.MM.YYYY' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="amount">
						<th mat-header-cell *matHeaderCellDef>Betrag</th>
						<td mat-cell *matCellDef="let position">
							{{ position.amount | currency }}
						</td>
					</ng-container>

					<ng-container matColumnDef="actions">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let position">
							<button mat-icon-button matTooltip="Löschen" (click)="removePosition(position)">
								<mat-icon svgIcon="trash-can-outline"></mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="positionColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: positionColumns"></tr>
				</table>
			</mat-tab>
			<mat-tab label="Historie">
				<babylon-direct-debit-transferhistorytable controlId="processInstance.actions"> </babylon-direct-debit-transferhistorytable>
			</mat-tab>
		</mat-tab-group>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
