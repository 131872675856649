import { Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgrxValueConverters } from 'ngrx-forms';
import { CustomNgrxValueConverters } from '../../converter/ngrx-custom-converter';

@Directive()
export abstract class DialogComponent<TDialogComponent, TDialogData = unknown> {
	public objectToJSONConverter = NgrxValueConverters.objectToJSON;
	public dateToISOStringConverter = NgrxValueConverters.dateToISOString;
	public percentageConverter = CustomNgrxValueConverters.percentage;
	public priceConverter = CustomNgrxValueConverters.price;
	public CKEditor = ClassicEditor;

	constructor(public matDialogRef: MatDialogRef<TDialogComponent, TDialogData>, public data: TDialogData = null, public formGroup: FormGroup = null) {}

	public get value(): TDialogData {
		return { ...this.data, ...this.formGroup.value };
	}
}
