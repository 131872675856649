import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'string',
})
export class StringPipe implements PipeTransform {
	public transform(value: string): string {
		return value != null && typeof value === 'string' ? value : '';
	}
}
