import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntity } from '../../models/entity.model';
import { IProcessRoute } from '../../models/process-route.model';
import { DialogService } from '../../services/dialog/dialog.service';
import { IEntityState } from '../../state/app.state';
import { EntityFacade } from '../../state/entity.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { SessionFacade } from '../../state/session/session.facade';
import { BaseComponent } from '../base.component';

export type ProcessButtonsMode = 'text' | 'icon';

export interface IProcessButtonClick<TEntity extends IEntity> {
	entity: TEntity;
	route: IProcessRoute;
}

@Component({
	selector: 'babylon-process-buttons',
	styleUrls: ['./process-buttons.component.scss'],
	templateUrl: './process-buttons.component.html',
})
export class ProcessButtonsComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseComponent {
	@Input() public mode: ProcessButtonsMode = 'text';
	@Input() public facade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>;
	@Input() public entity: TEntity;
	@Input() public disabled: boolean = false;
	@Input() public comment: string = null;
	@Output() public executed = new EventEmitter<IProcessButtonClick<TEntity>>();

	constructor(public processRouteFacade: ProcessRouteFacade, public sessionFacade: SessionFacade, public dialogService: DialogService) {
		super();
	}

	public execute(entity: TEntity, route: IProcessRoute): void {
		const updateProcess = (entity: TEntity, route: IProcessRoute) => this.facade.updateProcess(entity, route, this.comment, () => this.executed.emit({ entity, route }));

		if (route.isHappyPath) {
			updateProcess(entity, route);
		} else {
			this.dialogService.openConfirmationDialog({
				title: 'Bestätigung',
				text: `Bist du dir sicher, dass du die Aktion "${route.displayName}" ausführen möchtest?`,
				onConfirm: () => updateProcess(entity, route),
				onReject: () => {},
			});
		}
	}
}
