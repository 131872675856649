import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from '../../models/receipt';
import { AppState, IReceiptCancellationState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCancellationActions } from './receipt.cancellation.actions';
import { initialEditFormValue } from './receipt.cancellation.reducer';
import { CancellationSelector } from './receipt.cancellation.selectors';

@Injectable({
	providedIn: 'root',
})
export class CancellationFacade extends EntityFacade<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCancellationActions;

	constructor(store: Store<AppState>, cancellationSelector: CancellationSelector) {
		super(store, cancellationSelector);
	}
}
