import { IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCashOnDelivery } from '../../models/payment.model';
import { IPaymentCashOnDeliveryState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { CashOnDeliveryFacade } from '../../state/payment.cash-on-delivery/payment.cash-on-delivery.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-cash-on-deliverydialogchange',
	templateUrl: './entitydialogchange-cash-on-delivery.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CashOnDeliveryDialogChangeComponent extends EntityDialogChangeComponent<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {
	constructor(entityFacade: CashOnDeliveryFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
