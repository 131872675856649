import { createAction, props } from '@ngrx/store';
import { IOrder } from '../../models/order.model';
import { IPosition } from '../../models/position.model';
import { IOrderSearchWarning } from '../app.state';
import { EntityActions } from '../entity.actions';
import { IOrderEditForm } from './../../models/order.model';

export const fromOrderActions = {
	...EntityActions.create<IOrder, IOrderEditForm>('[Order]'),
	updatePositions: createAction(`[Order] Update Positions`, props<{ shouldUpdateReceipt: boolean }>()),
	updatedPositions: createAction(`[Order] Updated Positions`, props<{ positions: IPosition[] }>()),
	updatePriority: createAction(`[Order] Update Priority`, props<{ entity: IOrder; priority: number }>()),
	createReturn: createAction(`[Order] Create Return`, props<{ positions: IPosition[] }>()),
	searchValueChanged: createAction(`[Order] Search Value Changed`, props<{ searchValue: string }>()),
	searchLimitChanged: createAction(`[Order] Search Limit Changed`, props<{ searchLimit: number }>()),
	sendConfirmationEmail: createAction(`[Order] Send Confirmation Email`),
	sentConfirmationEmail: createAction(`[Order] Sent Confirmation Email`),
	duplicate: createAction(`[Order] Duplicate`),
	duplicated: createAction(`[Order] Duplicated`, props<{ order: IOrder }>()),
	search: createAction(`[Order] Search`),
	searched: createAction(`[Order] Searched`, props<{ entities: IOrder[]; totalCount: number; warnings: IOrderSearchWarning[] }>()),
};
