import { Directive, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { BaseComponent } from './base.component';

@Directive()
export abstract class PageComponent extends BaseComponent implements OnInit {
	private pageTitleBase: string = 'Babylon';
	protected abstract pageTitle$: BehaviorSubject<string>;

	constructor(private titleService: Title) {
		super();
	}

	public ngOnInit(): void {
		this.pageTitle$.subscribe(pageTitle => this.titleService.setTitle(`${this.pageTitleBase} | ${pageTitle}`));
	}
}
