import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ICollectionPurchaseTransferState } from 'src/state/app.state';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { CollectionPurchaseTransferDialogService } from '../../services/dialog/collection-purchase-transfer.service';
import { ReceiptCancellationDialogService } from '../../services/dialog/receipt.cancellation.service';
import { ReceiptCorrectionDialogService } from '../../services/dialog/receipt.correction.service';
import { ReceiptInvoiceDialogService } from '../../services/dialog/receipt.invoice.service';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-collection-purchase-transferpage',
	templateUrl: './entitypage-collection-purchase-transfer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class CollectionPurchaseTransferPageComponent extends EntityPageComponent<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	public pageTitle$ = new BehaviorSubject('Sammelrechnungen');
	public columns = ['partner', 'from', 'until', 'totalAmount', 'positionCount', 'state', 'filter'];

	protected shouldDoInitialFilter = true;
	protected shouldDoRegularFilter = 60;

	constructor(
		titleService: Title,
		entityDialogService: CollectionPurchaseTransferDialogService,
		route: ActivatedRoute,
		public entityFacade: CollectionPurchaseTransferFacade,
		public processFacade: ProcessFacade,
		public processNodeFacade: ProcessNodeFacade,
		public orderFacade: OrderFacade,
		public partnerFacade: PartnerFacade,
		public invoiceDialogService: ReceiptInvoiceDialogService,
		public correctionDialogService: ReceiptCorrectionDialogService,
		public cancellationDialogService: ReceiptCancellationDialogService,
		public routerFacade: RouterFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);

		combineLatest([this.routerFacade.collectionPurchaseTransferStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([states, processNodes]) => processNodes.filter(processNode => states.some(state => state.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					const processNodeNames = processNodes.map(processNode => processNode.name);

					if (processNodes.length == 0 || processNodeNames.indexOf('CollectionPurchaseTransferFinished') > -1) {
						this.entityFacade.changeSorting({ createdAt: -1 });
					} else {
						this.entityFacade.changeSorting({ createdAt: 1 });
					}

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}
}
