import { Directive, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgrxValueConverters } from 'ngrx-forms';
import { filter, interval, map, of, Subject, withLatestFrom } from 'rxjs';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { EntityDialogService } from 'src/services/dialog/entity.service';
import { IEntityState, SortDescriptors } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';
import { PageComponent } from '../page.component';

@UntilDestroy()
@Directive()
export abstract class EntityPageComponent<TEntity extends IEntity, TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm>
	extends PageComponent
	implements OnInit
{
	public abstract columns: string[];
	public filterExpanded: boolean = false;
	public objectToJSONConverter = NgrxValueConverters.objectToJSON;
	public dateToISOStringConverter = NgrxValueConverters.dateToISOString;
	public highlightedId$ = this.route.queryParams.pipe(map(params => params.id)) ?? of(null);

	protected shouldDoInitialFilter: boolean = true;
	protected shouldDoRegularFilter: number = null;
	private scrollTop$ = new Subject<number>();

	constructor(
		titleService: Title,
		private entityDialogService: EntityDialogService<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>,
		private route: ActivatedRoute,
		public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>
	) {
		super(titleService);
	}

	public ngOnInit(): void {
		super.ngOnInit();

		if (this.shouldDoInitialFilter) {
			this.entityFacade.filter();
		}

		if (this.shouldDoRegularFilter) {
			interval(this.shouldDoRegularFilter * 1000)
				.pipe(
					untilDestroyed(this),
					withLatestFrom(this.scrollTop$),
					filter(([, scrollTop]) => scrollTop === 0)
				)
				.subscribe(() => this.entityFacade.filter());
		}

		this.route.queryParams
			.pipe(
				map(params => params.title),
				filter(title => !!title)
			)
			.subscribe(title => this.pageTitle$.next(title));
	}

	public confirm(title: string, text: string, onConfirm: () => void, onReject: () => void = () => {}, width: string = null): void {
		this.entityDialogService.openConfirmationDialog({ width, title, text, onConfirm, onReject });
	}

	public create(width: string = null): void {
		this.entityDialogService.openCreateDialog({ entity: null, width });
	}

	public remove(entity: TEntity, width: string = null): void {
		this.entityDialogService.openRemoveDialog({ entity, width });
	}

	public update(entity: TEntity, width: string = null): void {
		this.entityDialogService.openUpdateDialog({ entity, width });
	}

	public onPageChange(event: PageEvent): void {
		this.entityFacade.changePage(event.pageSize, event.pageIndex);
	}

	public onScroll(event: any): void {
		const scrollBottom = event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop;
		this.scrollTop$.next(event.target.scrollTop);

		if (scrollBottom <= 0) {
			this.entityFacade.increase();
		}
	}

	public onSortChange(sort: Sort): void {
		const sortDescriptors: SortDescriptors<any> = {};

		if (sort.active && sort.direction) {
			sortDescriptors[sort.active] = sort.direction;
		}

		this.entityFacade.changeSorting(sortDescriptors);
	}

	public get columnsHeader(): string[] {
		return this.columns.map(x => `${x}-header`);
	}

	public get columnsFilter(): string[] {
		return this.columns.map(x => `${x}-filter`);
	}

	public get columnsContent(): string[] {
		return this.columns.map(x => `${x}-content`);
	}
}
