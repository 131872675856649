import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryCustomerState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromAccountingSummaryCustomerActions } from './accounting-summary.customer.actions';
import { initialEditFormValue } from './accounting-summary.customer.reducer';
import { AccountingSummaryCustomerSelector } from './accounting-summary.customer.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerFacade extends EntityFacade<IAccountingSummaryCustomer, IAccountingSummaryCustomerState, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAccountingSummaryCustomerActions;

	constructor(store: Store<AppState>, entitySelector: AccountingSummaryCustomerSelector) {
		super(store, entitySelector);
	}
}
