import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { InvoiceNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-invoice.component';
import { IReceiptInvoice } from '../../models/receipt';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class InvoiceNotificationService extends EntityNotificationService<IReceiptInvoice> {
	protected component: ComponentType<any> = InvoiceNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
