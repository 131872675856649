import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-attachment.component';
import { AttachmentDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-attachment.component';
import { AttachmentDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-attachment.component';
import { IAttachment } from 'src/models/attachment.model';
import { IAttachmentState } from 'src/state/app.state';
import { AttachmentFacade } from 'src/state/attachment/attachment.facade';
import { IAttachmentEditForm, IAttachmentFilterForm } from '../../models/attachment.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AttachmentDialogService extends EntityDialogService<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	protected createComponent: ComponentType<any> = AttachmentDialogAddComponent;
	protected updateComponent: ComponentType<any> = AttachmentDialogChangeComponent;
	protected removeComponent: ComponentType<any> = AttachmentDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: AttachmentFacade) {
		super(matDialog, entityFacade);
	}
}
