import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { IPaymentCollectionPurchaseState } from '../../state/app.state';
import { CollectionPurchaseFacade } from '../../state/payment.collection-purchase/payment.collection-purchase.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-collection-purchasedialogdelete',
	templateUrl: './entitydialogdelete-collection-purchase.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CollectionPurchaseDialogDeleteComponent extends EntityDialogDeleteComponent<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	constructor(entityFacade: CollectionPurchaseFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
