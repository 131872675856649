import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm } from '../../models/article.model';
import { AppState, IArticleChronicleState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleChronicleSelector extends EntitySelector<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	protected stateSelector = (state: AppState): IArticleChronicleState => state.articleChronicle;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
	public dayRange = createSelector(this.selectState, state => state.dayRange);
}
