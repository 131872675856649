import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingPickup } from '../../models/shipping.model';
import { IShippingPickupState } from '../../state/app.state';
import { PickupFacade } from '../../state/shipping.pickup/shipping.pickup.facade';
import { IShippingPickupEditForm, IShippingPickupFilterForm } from './../../models/shipping.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-pickupdialogadd',
	templateUrl: './entitydialogadd-pickup.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PickupDialogAddComponent extends EntityDialogAddComponent<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	constructor(entityFacade: PickupFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
