import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMediaProduct } from '../../models/media-product.model';
import { IMediaProductState } from '../../state/app.state';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-media-productdialogdelete',
	templateUrl: './entitydialogdelete-media-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class MediaProductDialogDeleteComponent extends EntityDialogDeleteComponent<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	constructor(entityFacade: MediaProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
