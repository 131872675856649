import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-customer.component';
import { CustomerDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-customer.component';
import { CustomerDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-customer.component';
import { CustomerFacade } from 'src/state/customer/customer.facade';
import { ICustomerState } from '../../state/app.state';
import { ICustomer, ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerDialogService extends EntityDialogService<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	protected createComponent: ComponentType<any> = CustomerDialogAddComponent;
	protected updateComponent: ComponentType<any> = CustomerDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CustomerDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CustomerFacade) {
		super(matDialog, entityFacade);
	}
}
