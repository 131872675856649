import { ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { EntityState, ITextTemplateState } from '../app.state';

import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { ITextTemplate } from '../../models/text-template.model';
import { EntityReducer } from '../entity.reducer';
import { fromTextTemplateActions } from './text-template.actions';

export const initialValue: ITextTemplate = {
	_id: null,
	title: null,
	text: null,
};

export const initialEditFormValue: ITextTemplateEditForm = {
	_id: null,
	title: null,
	text: null,
};

export const initialFilterFormValue: ITextTemplateFilterForm = {
	_id: null,
	createdAt: null,
	title: null,
	text: null,
};

export const initialState = EntityState.create<ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm>('TEXTTEMPLATE', initialValue, initialEditFormValue, initialFilterFormValue, [{}], {
	title: 1,
});

export const textTemplateReducer = EntityReducer.create<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm>(
	initialState,
	{
		title: validate(required),
		text: validate(required),
	},
	fromTextTemplateActions
);
