import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryNoteReturnDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-delivery-note-return.component';
import { DeliveryNoteReturnDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-delivery-note-return.component';
import { DeliveryNoteReturnDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-delivery-note-return.component';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteReturnState } from '../../state/app.state';
import { DeliveryNoteReturnFacade } from '../../state/delivery-note.return/delivery-note.return.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnDialogService extends EntityDialogService<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	protected createComponent: ComponentType<any> = DeliveryNoteReturnDialogAddComponent;
	protected updateComponent: ComponentType<any> = DeliveryNoteReturnDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DeliveryNoteReturnDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DeliveryNoteReturnFacade) {
		super(matDialog, entityFacade);
	}
}
