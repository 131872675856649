import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { IProcessNode } from '../../models/process-node.model';
import { ProcessNodeApiService } from '../../services/api/process-node.service';
import { ProcessNodeNotificationService } from '../../services/notification/process-node.service';
import { AppState, IProcessNodeState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromSessionActions } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { fromProcessNodeActions } from './process-node.actions';
import { ProcessNodeSelector } from './process-node.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessNodeEffects extends EntityEffects<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: ProcessNodeApiService, notificationService: ProcessNodeNotificationService, selector: ProcessNodeSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, null, selector, sessionSelector, fromProcessNodeActions, 'PROCESSNODE');
	}

	public onLoggedIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.loggedIn),
			map(() => fromProcessNodeActions.filter())
		)
	);
}
