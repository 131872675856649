import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { CartApiService } from '../../services/api/cart.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromOrderActions } from '../order/order.actions';
import { SessionSelector } from '../session/session.selectors';
import { fromCartActions } from './cart.actions';

@Injectable({
	providedIn: 'root',
})
export class CartEffects extends BaseEffects {
	constructor(private store: Store<AppState>, private actions$: Actions, private cartService: CartApiService, private sessionSelector: SessionSelector) {
		super();
	}

	public onValidatePositions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.validatePositions),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ positions }, authToken]) => {
				return this.cartService.validate(positions, authToken).pipe(
					map(response => fromCartActions.validatedPositions({ positions: response.data })),
					catchError((response: IServiceErrorResponse) => of(fromCartActions.failed({ error: response.error })))
				);
			})
		)
	);

	public onValidatedPositions$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCartActions.validatedPositions),
			map(({ positions }) => fromOrderActions.updatedPositions({ positions }))
		)
	);
}
