import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-partnerdialogadd',
	templateUrl: './entitydialogadd-partner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PartnerDialogAddComponent extends EntityDialogAddComponent<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	public SalutationKinds = SalutationKind;
	public PaymentKinds = PaymentKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: PartnerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
