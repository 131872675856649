import { Injectable } from '@angular/core';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { AppState, IShippingDhlExpressState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class DhlExpressSelector extends EntitySelector<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	protected stateSelector = (state: AppState): IShippingDhlExpressState => state.shippingDhlExpress;
}
