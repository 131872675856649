import { AppState, IDeliveryNoteState } from '../app.state';
import { IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from './../../models/delivery-note';

import { DeliveryNoteSelector } from './delivery-note.selectors';
import { EntityFacade } from '../entity.facade';
import { IDeliveryNote } from '../../models/delivery-note';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromDeliveryNoteActions } from './delivery-note.actions';
import { initialEditFormValue } from './delivery-note.reducer';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteFacade extends EntityFacade<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDeliveryNoteActions;

	constructor(store: Store<AppState>, deliveryNoteSelector: DeliveryNoteSelector) {
		super(store, deliveryNoteSelector);
	}

	public download(deliveryNote: IDeliveryNote): void {
		this.store.dispatch(this.actions.download({ deliveryNote }));
	}

	public print(deliveryNote: IDeliveryNote): void {
		this.store.dispatch(this.actions.print({ deliveryNote }));
	}
}
