import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { FilterOperator, FilterOperators, IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';

export interface IFilterOperatorOption {
	char: string;
	value: FilterOperator;
	title: string;
}

@Component({
	selector: 'babylon-filteroperator',
	templateUrl: './filteroperator.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./filteroperator.component.scss'],
})
export class FilterOperatorComponent<TEntity extends IEntity, TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm>
	implements AfterViewInit
{
	@Input() public controlId: string;
	@Input() public value: FilterOperator | FilterOperators<unknown>;
	@Input() public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>;
	@Input() public availableOptions: FilterOperator[];

	public options: IFilterOperatorOption[] = [
		{ value: 'EQ', title: 'Gleich', char: '=' },
		{ value: 'DATEEQ', title: 'Gleich', char: '=' },
		{ value: 'NE', title: 'Ungleich', char: '≠' },
		{ value: 'STARTSWITH', title: 'Beginnt mit', char: '~' },
		{ value: 'CONTAINS', title: 'Enthält', char: '∈' },
		{ value: 'NOTCONTAINS', title: 'Enthält nicht', char: '∉' },
		{ value: 'IN', title: 'Beinhaltet', char: '⊂' },
		{ value: 'GTE', title: 'Größer gleich', char: '≥' },
		{ value: 'LTE', title: 'Kleiner gleich', char: '≤' },
		{ value: 'GT', title: 'Größer', char: '>' },
		{ value: 'LT', title: 'Kleiner', char: '<' },
	];

	public get selected(): IFilterOperatorOption {
		return this.options.find(x => x.value == this.value);
	}

	public ngAfterViewInit(): void {
		if (this.availableOptions) {
			this.options = this.options.filter(x => this.availableOptions.includes(x.value));
		}
	}

	public select(operator: FilterOperator): void {
		this.entityFacade.changeGlobalForm({ controlId: this.controlId, value: operator });
	}
}
