<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Abholung ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm" *ngIf="entityFacade.selected$ | async as receipt">
			<mat-tab-group>
				<mat-tab label="Abholadresse">
					<div class="form-grid">
						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Abholort</mat-label>
							<mat-select matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.type" (selectionChange)="changePickupStationType($event.value)">
								<mat-option *ngFor="let pickupStation of PickupStationTypes | keyvalue" [value]="pickupStation.value">{{ pickupStation.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Straße</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.address.controls.street" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Hausnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.address.controls.streetNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>PLZ</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.address.controls.postCode" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Stadt</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.address.controls.city" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Land</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.pickupStation.controls.address.controls.country" />
						</mat-form-field>
					</div>
				</mat-tab>

				<mat-tab label="Historie">
					<babylon-pickuphistorytable controlId="processInstance.actions"></babylon-pickuphistorytable>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
