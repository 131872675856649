import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IReceiptCancellation } from '../../models/receipt';
import { IReceiptCancellationState } from '../../state/app.state';
import { CancellationFacade } from '../../state/receipt.cancellation/receipt.cancellation.facade';
import { IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from './../../models/receipt';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-cancellationdialogadd',
	templateUrl: './entitydialogadd-cancellation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class CancellationDialogAddComponent extends EntityDialogAddComponent<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	constructor(entityFacade: CancellationFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
