import { Action, ActionCreator, ActionReducer, ActionType, createReducer, ReducerTypes } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

export function createRehydrateReducer<State, A extends Action = Action>(key: string, initialState: State, ...ons: ReducerTypes<State, ActionCreator[]>[]): ActionReducer<State, A> {
	const item = localStorage.getItem(key);
	const newInitialState = (item && JSON.parse(item)) ?? initialState;
	const newOns: ReducerTypes<State, ActionCreator[]>[] = [];

	ons.forEach((oldOn: ReducerTypes<State, ActionCreator[]>) => {
		const newReducer: OnReducer<State, ActionCreator[]> = (state: State | undefined, action: ActionType<ActionCreator[][number]>) => {
			const newState = oldOn.reducer(state, action);
			localStorage.setItem(key, JSON.stringify(newState));
			return newState;
		};

		newOns.push({ ...oldOn, reducer: newReducer });
	});

	return createReducer(newInitialState, ...newOns);
}
