import { box } from 'ngrx-forms';
import { IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm } from '../../models/accounting-summary.model';
import { EntityState, IAccountingSummaryCustomerState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromAccountingSummaryCustomerActions } from './accounting-summary.customer.actions';

export const initialValue: IAccountingSummaryCustomer = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialEditFormValue: IAccountingSummaryCustomerEditForm = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [
		{
			orderNumber: null,
			firstName: null,
			lastName: null,
			company: null,
			street: null,
			postCode: null,
			city: null,
			country: null,
			paypalTransactionId: null,
		},
	],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialFilterFormValue: IAccountingSummaryCustomerFilterForm = {
	_id: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialState = EntityState.create<IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm>('ACCOUNTINGSUMMARYCUSTOMER', initialValue, initialEditFormValue, initialFilterFormValue);
export const accountingSummaryCustomerReducer = EntityReducer.create<IAccountingSummaryCustomer, IAccountingSummaryCustomerState, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm>(
	initialState,
	{},
	fromAccountingSummaryCustomerActions
);
