import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PickupNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-pickup.component';
import { IShippingPickup } from '../../models/shipping.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PickupNotificationService extends EntityNotificationService<IShippingPickup> {
	protected component: ComponentType<any> = PickupNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
