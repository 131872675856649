import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TextTemplateDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-text-template.component';
import { TextTemplateDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-text-template.component';
import { TextTemplateDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-text-template.component';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { ITextTemplateState } from '../../state/app.state';
import { TextTemplateFacade } from '../../state/text-template/text-template.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class TextTemplateDialogService extends EntityDialogService<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	protected createComponent: ComponentType<any> = TextTemplateDialogAddComponent;
	protected updateComponent: ComponentType<any> = TextTemplateDialogChangeComponent;
	protected removeComponent: ComponentType<any> = TextTemplateDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: TextTemplateFacade) {
		super(matDialog, entityFacade);
	}
}
