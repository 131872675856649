import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, IMenuState, ISessionState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class MenuSelector extends BaseSelector<IMenuState> {
	protected stateSelector = (state: AppState): IMenuState => state.menu;
	protected sessionState = (state: AppState): ISessionState => state.session;

	public isMenuExpanded = createSelector(this.selectState, state => state.isMenuExpanded);
	public testingAcknowledgedAt = createSelector(this.selectState, state => state.testingAcknowledgedAt);
	public menuMargin = createSelector(this.isMenuExpanded, this.sessionState, (isMenuExpanded, sessionState) => (sessionState.authToken != null ? (isMenuExpanded ? 320 : 64) : 0));
}
