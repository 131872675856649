<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">{{ data.title }}</h1>
	<p>{{ data.text }}</p>
	<h2 class="mat-h4">Meldung</h2>
	<pre class="error">{{ data.error.message }}</pre>
	<ng-container *ngIf="data.error.stack">
		<h2 class="mat-h4">Stack-Trace</h2>
		<pre class="error">{{ data.error.stack }}</pre>
	</ng-container>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="data.onReject()">Schließen</button>
	</mat-dialog-actions>
</ng-container>
