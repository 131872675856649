import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm } from '../../models/media-brand.model';
import { FilterConnection, IFilterDescriptor, IMediaBrandState, SortDescriptors } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-media-brand-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-media-brand.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class MediaBrandAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	public placeholder = 'Marken';
	public columns: string[] = ['caption', 'delete'];

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'caption', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IMediaBrand> = () => ({ caption: 1 });

	constructor(entityFacade: MediaBrandFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IMediaBrand): string {
		return entity != null ? entity.caption : null;
	}
}
