import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgrxValueConverters } from 'ngrx-forms';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { ICustomerState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { CustomerTypeEnum, ICustomer, ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-customerdialogchange',
	templateUrl: './entitydialogchange-customer.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CustomerDialogChangeComponent extends EntityDialogChangeComponent<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	constructor(entityFacade: CustomerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public salutationKinds = Object.values(SalutationKind);
	public customerTypes = Object.values(CustomerTypeEnum);
	public dateToISOString = NgrxValueConverters.dateToISOString;
}
