import { Injectable } from '@angular/core';
import { IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { AppState, IPaymentPaypalState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class PaypalSelector extends EntitySelector<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	protected stateSelector = (state: AppState): IPaymentPaypalState => state.paymentPaypal;
}
