import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../../models/direct-debit-transfer.model';
import { AppState, IDirectDebitTransferState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferSelector extends EntitySelector<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {
	protected stateSelector = (state: AppState): IDirectDebitTransferState => state.directDebitTransfer;

	public filterByPaymentIds = (paymentIds: string[]) => createSelector(this.list, list => list.filter(entity => entity.positions.some(position => paymentIds.includes(position.payment))));
}
