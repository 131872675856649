import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { IPageState } from '../../state/app.state';
import { PageFacade } from '../../state/page/page.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-pagedialogdelete',
	templateUrl: './entitydialogdelete-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class PageDialogDeleteComponent extends EntityDialogDeleteComponent<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	constructor(entityFacade: PageFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
