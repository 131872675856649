import { createAction, props } from '@ngrx/store';
import { IAttachment } from 'src/models/attachment.model';
import { IAttachmentEditForm } from '../../models/attachment.model';
import { EntityActions } from '../entity.actions';

export const fromAttachmentActions = {
	...EntityActions.create<IAttachment, IAttachmentEditForm>('[Attachment]'),
	createdAttachmentForm: createAction(`[Attachment] Created Attachment Form`, props<{ entity: IAttachment; file: File }>()),
	updatedAttachmentForm: createAction(`[Attachment] Updated Attachment Form`, props<{ entity: IAttachment; file: File }>()),
};
