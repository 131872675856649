import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStockAccessory } from '../../models/stock.model';
import { IStockAccessoryState } from '../../state/app.state';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-accessorystockdialogdelete',
	templateUrl: './entitydialogdelete-accessory-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class AccessoryStockDialogDeleteComponent extends EntityDialogDeleteComponent<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	constructor(entityFacade: AccessoryStockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
