import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAccessoryProductState } from 'src/state/app.state';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AccessoryProductDialogService } from '../../services/dialog/accessory-product.service';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-accessory-productpage',
	templateUrl: './entitypage-accessory-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class AccessoryProductPageComponent extends EntityPageComponent<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	public pageTitle$ = new BehaviorSubject('Accessoire-Produkte');
	public columns = ['name', 'price', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: AccessoryProductDialogService,
		entityFacade: AccessoryProductFacade,
		route: ActivatedRoute,
		public mediaBrandFacade: MediaBrandFacade,
		public regionFacade: RegionFacade,
		public publicationCycleFacade: PublicationCycleFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
