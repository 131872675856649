import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleFolder } from '../../models/article.model';
import { IArticleFolderState } from '../../state/app.state';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { IArticleFolderEditForm, IArticleFolderFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-folderdialogadd',
	templateUrl: './entitydialogadd-folder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class FolderDialogAddComponent extends EntityDialogAddComponent<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	constructor(entityFacade: ArticleFolderFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
