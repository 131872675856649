import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { CoinNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-coin.component';
import { IArticleCoin } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleCoinNotificationService extends EntityNotificationService<IArticleCoin> {
	protected component: ComponentType<any> = CoinNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
