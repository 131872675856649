import { Injectable } from '@angular/core';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AppState, IAccessoryProductState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';

@Injectable({
	providedIn: 'root',
})
export class AccessoryProductSelector extends EntitySelector<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	protected stateSelector = (state: AppState): IAccessoryProductState => state.accessoryProduct;
}
