import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm } from '../../models/payment.model';
import { PaymentCashApiService } from '../../services/api/payment.cash.service';
import { PaymentCashDialogService } from '../../services/dialog/payment.cash.service';
import { CashNotificationService } from '../../services/notification/payment.cash.service';
import { AppState, IPaymentCashState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCashActions } from './payment.cash.actions';
import { CashSelector } from './payment.cash.selectors';

@Injectable({
	providedIn: 'root',
})
export class CashEffects extends EntityEffects<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: PaymentCashApiService, notificationService: CashNotificationService, dialogService: PaymentCashDialogService, selector: CashSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCashActions, 'CASH');
	}
}
