import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-page.component';
import { PageDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-page.component';
import { PageDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-page.component';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { IPageState } from '../../state/app.state';
import { PageFacade } from '../../state/page/page.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PageDialogService extends EntityDialogService<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	protected createComponent: ComponentType<any> = PageDialogAddComponent;
	protected updateComponent: ComponentType<any> = PageDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PageDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PageFacade) {
		super(matDialog, entityFacade);
	}
}
