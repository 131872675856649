import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ICounter } from 'src/models/counter.model';
import { CounterDialogService } from 'src/services/dialog/counter.service';
import { ICounterState } from 'src/state/app.state';
import { CounterFacade } from 'src/state/counter/counter.facade';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-counterpage',
	templateUrl: './entitypage-counter.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class CounterPageComponent extends EntityPageComponent<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	public pageTitle$ = new BehaviorSubject('Statistik');
	public columns = ['type', 'value'];

	constructor(titleService: Title, dialogService: CounterDialogService, route: ActivatedRoute, entityFacade: CounterFacade) {
		super(titleService, dialogService, route, entityFacade);
	}
}
