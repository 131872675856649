import { createAction, props } from '@ngrx/store';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm } from '../../models/direct-debit-transfer.model';
import { EntityActions } from '../entity.actions';

export const fromDirectDebitTransferActions = {
	...EntityActions.create<IDirectDebitTransfer, IDirectDebitTransferEditForm>('[DirectDebitTransfer]'),
	downloadCsv: createAction(`[DirectDebitTransfer] Download CSV`, props<{ entity: IDirectDebitTransfer }>()),
	downloadedCsv: createAction(`[DirectDebitTransfer] Downloaded CSV`, props<{ entity: IDirectDebitTransfer; data: any }>()),
	downloadXml: createAction(`[DirectDebitTransfer] Download XML`, props<{ entity: IDirectDebitTransfer }>()),
	downloadedXml: createAction(`[DirectDebitTransfer] Downloaded XML`, props<{ entity: IDirectDebitTransfer; data: any }>()),
	filterByPaymentIds: createAction(`[DirectDebitTransfer] Filter By Payment Ids`, props<{ paymentIds: string[] }>()),
	filteredByPaymentId: createAction(`[DirectDebitTransfer] Filtered By Payment Ids`, props<{ entities: IDirectDebitTransfer[] }>()),
};
