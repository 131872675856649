import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, map, Observable, withLatestFrom } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IPosition } from '../../models/position.model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-order-return-positionsdialog',
	templateUrl: './dialog-order-return-positions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss', './dialog-order-return-positions.component.scss'],
	animations: [progressFade],
})
export class OrdersReturnPositionDialogComponent extends DialogComponent<OrdersReturnPositionDialogComponent, IPosition[]> {
	public positionColumns = ['select', 'title', 'price'];
	public PositionKinds = PositionKind;

	public selection = new SelectionModel<IPosition>(true, []);
	public masterSelection$ = new BehaviorSubject<boolean>(false);

	constructor(
		matDialogRef: MatDialogRef<OrdersReturnPositionDialogComponent, IPosition[]>,
		@Inject(MAT_DIALOG_DATA) data: IPosition[],
		public orderFacade: OrderFacade,
		public processNodeFacade: ProcessNodeFacade,
		public articleDialogService: ArticleDialogService
	) {
		super(matDialogRef, data);

		this.masterSelection$
			.pipe(
				withLatestFrom(this.orderFacade.selected$),
				map(([selectAll, order]) => {
					if (selectAll) {
						this.selection.select(...order.positions);
					} else {
						this.selection.clear();
					}
				})
			)
			.subscribe();
	}

	public get isAllSelected$(): Observable<boolean> {
		return this.orderFacade.selected$.pipe(map(order => order.positions.length == this.selection.selected.length));
	}

	public showCreateRetoureDialog(): void {
		this.articleDialogService.openConfirmationDialog({
			title: 'Retoure erstellen',
			text: `Bist du dir sicher, dass du eine Retoure erstellen möchtest?`,
			onConfirm: () => {
				this.orderFacade.createReturn(this.selection.selected);
			},
			onReject: () => {},
		});
	}
}
