import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, ICartState } from '../app.state';
import { BaseSelector } from '../base.selector';
import { RouterSelector } from '../router/router.selectors';

@Injectable({
	providedIn: 'root',
})
export class CartSelector extends BaseSelector<ICartState> {
	constructor(private routerSelector: RouterSelector) {
		super();
	}

	protected stateSelector = (state: AppState): ICartState => state.cart;

	public cart = createSelector(this.selectState, state => state);
	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public error = createSelector(this.selectState, state => state.error);
	public hasError = createSelector(this.error, error => error != null);
	public isDisabled = createSelector(this.selectState, state => state.isDisabled);
}
