import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleCoin, IArticleCoinEditForm, IArticleCoinFilterForm } from '../../models/article.model';
import { AppState, IArticleCoinState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleCoinActions } from './article.coin.actions';
import { initialEditFormValue } from './article.coin.reducer';
import { ArticleCoinSelector } from './article.coin.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleCoinFacade extends EntityFacade<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleCoinActions;

	constructor(store: Store<AppState>, private coinSelector: ArticleCoinSelector) {
		super(store, coinSelector);
	}

	public isSearching$ = this.store.select(this.coinSelector.isSearching);
	public isExtending$ = this.store.select(this.coinSelector.isExtending);
	public itemsSearched$ = this.store.select(this.coinSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.coinSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleCoinActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
