import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IStickRating } from '../../models/article-rating';
import { IAccessoryArticleRequest, IPaging } from '../../models/article-request';
import { IArticleStick, IExpandedArticleStick } from '../../models/article.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickApiService extends EntityApiService<IArticleStick> {
	protected entityName = 'sticks';

	constructor(http: HttpClient) {
		super(http);
	}

	public search(request: IAccessoryArticleRequest, paging?: IPaging): Observable<IExpandedArticleStick[]> {
		const route = [this.entityName, 'search'];

		if (paging != null) {
			route.push(`?skip=${paging.skip}&top=${paging.top}`);
		}

		return this.post<IEntityListResponse<IStickRating>, IAccessoryArticleRequest>(route, request).pipe(map(ratings => ratings.data.map(rating => rating.article)));
	}
}
