import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { PickupStationType } from '../../models/configuration.model';
import { IPickupStation } from './../../models/configuration.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-pickup-stationdialog',
	templateUrl: './dialog-pickup-station.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PickupStationDialogComponent extends DialogComponent<PickupStationDialogComponent, IPickupStation> {
	public PickupStationTypes = PickupStationType;

	constructor(matDialogRef: MatDialogRef<PickupStationDialogComponent, IPickupStation>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPickupStation) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				type: [data.type, [Validators.required]],
				default: [data.default, []],
				address: formBuilder.group({
					street: [data.address.street, [Validators.required]],
					streetNumber: [data.address.streetNumber, [Validators.required]],
					postCode: [data.address.postCode, [Validators.required]],
					city: [data.address.city, [Validators.required]],
					country: [data.address.country, [Validators.required]],
				}),
			})
		);
	}
}
