import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { AppState, IMailTemplateState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';
import { fromMailTemplateActions } from './mailtemplate.actions';
import { initialEditFormValue } from './mailtemplate.reducer';
import { MailTemplateSelector } from './mailtemplate.selectors';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateFacade extends EntityFacade<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromMailTemplateActions;

	constructor(store: Store<AppState>, mailtemplateSelector: MailTemplateSelector) {
		super(store, mailtemplateSelector);
	}

	public test(): void {
		this.store.dispatch(fromMailTemplateActions.test());
	}

	public tested(): void {
		this.store.dispatch(fromMailTemplateActions.tested());
	}
}
