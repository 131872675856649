import { first, Observable } from 'rxjs';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { AppState, IOrderSearchWarning, IOrderState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { IPositionAddDialogData } from '../../components/dialog/dialog-position-add.component';
import { IExpandedArticle } from '../../models/article.model';
import { ICustomer } from '../../models/customer.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IPosition } from '../../models/position.model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { BundleDialogService } from '../../services/dialog/bundle.service';
import { EntityFacade } from '../entity.facade';
import { ProcessNodeFacade } from '../process-node/process-node.facade';
import { fromOrderActions } from './order.actions';
import { initialEditFormValue } from './order.reducer';
import { OrderSelector } from './order.selectors';

@Injectable({
	providedIn: 'root',
})
export class OrderFacade extends EntityFacade<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromOrderActions;

	constructor(store: Store<AppState>, private orderSelector: OrderSelector, private processNodeFacade: ProcessNodeFacade, private articleDialogService: ArticleDialogService, public bundleDialogService: BundleDialogService) {
		super(store, orderSelector);
	}

	public articles$ = this.store.select(this.orderSelector.articles);
	public products$ = this.store.select(this.orderSelector.products);
	public positions$ = this.store.select(this.orderSelector.positions);
	public editFormPositions$ = this.store.select(this.orderSelector.editFormPositions);
	public returnPositions$ = this.store.select(this.orderSelector.returnPositions);

	public searchValue$ = this.store.select(this.orderSelector.searchValue);
	public searchLimit$ = this.store.select(this.orderSelector.searchLimit);
	public isSearching$ = this.store.select(this.orderSelector.isSearching);
	public itemsSearched$ = this.store.select(this.orderSelector.itemsSearched);
	public totalCountSearched$ = this.store.select(this.orderSelector.totalCountSearched);
	public listSearched$ = this.store.select(this.orderSelector.listSearched);

	public getCustomerWarning(customer: ICustomer): Observable<IOrderSearchWarning> {
		return this.store.select(this.orderSelector.customerWarning(customer));
	}

	public changeSearchValue(searchValue: string) {
		this.store.dispatch(fromOrderActions.searchValueChanged({ searchValue }));
	}

	public changeSearchLimit(searchLimit: number) {
		this.store.dispatch(fromOrderActions.searchLimitChanged({ searchLimit }));
	}

	public getArticlesFromPosition(position: IPosition): Observable<IExpandedArticle[]> {
		return this.store.select(this.orderSelector.getArticlesFromPosition(position));
	}

	public getArticlesFromEditPosition(position: IPosition): Observable<IExpandedArticle[]> {
		return this.store.select(this.orderSelector.getArticlesFromEditPosition(position));
	}

	public createReturn(positions: IPosition[]): void {
		this.store.dispatch(this.actions.createReturn({ positions }));
	}

	public sendConfirmationEmail(): void {
		this.store.dispatch(this.actions.sendConfirmationEmail());
	}

	public duplicate(): void {
		this.store.dispatch(this.actions.duplicate());
	}

	public removePosition(position: IPosition): void {
		this.editFormPositions$.pipe(first()).subscribe(positions => this.changeFormValue({ attributeName: 'positions', value: positions.filter(x => x._id != position._id) }));
	}

	public updatedPositions(positions: IPosition[]): void {
		this.store.dispatch(this.actions.updatedPositions({ positions }));
	}

	public addPosition(data: IPositionAddDialogData): void {
		this.editFormPositions$
			.pipe(
				first(),
				tap(positions => {
					this.changeFormValue({
						attributeName: 'positions',
						value: [...positions, data.position],
					});

					if (data.position.positionKind == PositionKind.Article) {
						this.articleDialogService.openAddDialog({
							articleKind: data.articleKind,
							position: data.position,
						});
					} else if (data.position.positionKind == PositionKind.Bundle) {
						this.bundleDialogService.openAddDialog({
							...data.position,
							title: data.bundle.name,
							bundle: data.bundle,
							articles: [],
						});
					}
				})
			)
			.subscribe();
	}

	public updatePositions(shouldUpdateReceipt: boolean): void {
		this.store.dispatch(this.actions.updatePositions({ shouldUpdateReceipt }));
	}

	public updatePriority(entity: IOrder, priority: number): void {
		this.store.dispatch(this.actions.updatePriority({ entity, priority }));
	}
}
