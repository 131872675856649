import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IPayment, IPaymentEditForm, IPaymentFilterForm } from '../../models/payment.model';

import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPaymentState } from 'src/state/app.state';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { PaymentDialogService } from '../../services/dialog/payment.service';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-paymentpaydialog',
	templateUrl: './entitydialog-payment-pay.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class PaymentPayDialogComponent extends EntityDialogComponent<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	public comment: string = '';
	public PaymentKinds = PaymentKind;

	constructor(
		public paymentFacade: PaymentFacade,
		public productFacade: ProductFacade,
		public certificateFacade: ArticleCertificateFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public paymentDialogService: PaymentDialogService,
		public orderFacade: OrderFacade,
		public receiptFacade: ReceiptFacade,
		public customerFacade: CustomerFacade,
		matDialogRef: MatDialogRef<PaymentPayDialogComponent, IEntityDialogData<IPayment>>
	) {
		super(paymentFacade, matDialogRef);
	}
}
