import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProcessNode } from 'src/models/process-node.model';
import { IProcessNodeState } from 'src/state/app.state';
import { ProcessNodeFacade } from 'src/state/process-node/process-node.facade';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-processnodedialogadd',
	templateUrl: './entitydialogadd-process-node.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ProcessNodeDialogAddComponent extends EntityDialogAddComponent<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	constructor(entityFacade: ProcessNodeFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
