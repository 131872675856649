import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from '../../models/payment.model';
import { ArticleBarzahlenApiService } from '../../services/api/payment.barzahlen.service';
import { PaymentBarzahlenDialogService } from '../../services/dialog/payment.barzahlen.service';
import { BarzahlenNotificationService } from '../../services/notification/payment.barzahlen.service';
import { AppState, IPaymentBarzahlenState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromBarzahlenActions } from './payment.barzahlen.actions';
import { BarzahlenSelector } from './payment.barzahlen.selectors';

@Injectable({
	providedIn: 'root',
})
export class BarzahlenEffects extends EntityEffects<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ArticleBarzahlenApiService,
		notificationService: BarzahlenNotificationService,
		dialogService: PaymentBarzahlenDialogService,
		selector: BarzahlenSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromBarzahlenActions, 'BARZAHLEN');
	}
}
