import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPaypal } from '../../models/payment.model';
import { IPaymentPaypalState } from '../../state/app.state';
import { PaypalFacade } from '../../state/payment.paypal/payment.paypal.facade';
import { IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from './../../models/payment.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-paypaldialogadd',
	templateUrl: './entitydialogadd-paypal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PaypalDialogAddComponent extends EntityDialogAddComponent<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	constructor(entityFacade: PaypalFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
