import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { catchError, filter, map, of, switchMap, tap } from 'rxjs';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { CollectionPurchaseTransferApiService } from '../../services/api/collection-purchase-transfer.service';
import { CollectionPurchaseTransferDialogService } from '../../services/dialog/collection-purchase-transfer.service';
import { CollectionPurchaseTransferNotificationService } from '../../services/notification/collection-purchase-transfer.service';
import { AppState, ICollectionPurchaseTransferState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCollectionPurchaseTransferActions } from './collection-purchase-transfer.actions';
import { CollectionPurchaseTransferSelector } from './collection-purchase-transfer.selectors';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseTransferEffects extends EntityEffects<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: CollectionPurchaseTransferApiService,
		notificationService: CollectionPurchaseTransferNotificationService,
		dialogService: CollectionPurchaseTransferDialogService,
		selector: CollectionPurchaseTransferSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCollectionPurchaseTransferActions, 'COLLECTIONPURCHASETRANSFER');
	}

	public onDownloadCsv$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCollectionPurchaseTransferActions.downloadCsv),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity }, authToken]) =>
				this.apiService.downloadCsv(authToken, entity).pipe(
					map(data => fromCollectionPurchaseTransferActions.downloadedCsv({ entity, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedCsv$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromCollectionPurchaseTransferActions.downloadedCsv),
				filter(({ entity }) => entity.positions.length > 0),
				tap(({ entity, data }) => {
					const dateFrom = _.orderBy(entity.positions, ['receiptDate'], ['desc'])[0].receiptDate;
					const dateTo = _.orderBy(entity.positions, ['receiptDate'], ['asc'])[0].receiptDate;

					saveAs(data, `CSV_${moment(dateFrom).format('YYYY-MM-DD') + '' + (dateFrom != dateTo ? 'bis' + moment(dateTo).format('YYYY-MM-DD') : '')}_${entity.positions.reduce((acc, o) => acc + o.amount, 0).toLocaleString()}.csv`);
				})
			),
		{ dispatch: false }
	);

	public onFindByReceipt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCollectionPurchaseTransferActions.filterByReceiptNumbers),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receiptNumbers }, authToken]) =>
				this.apiService.filterByReceiptNumbers(receiptNumbers, authToken).pipe(
					map(result => fromCollectionPurchaseTransferActions.filteredByReceiptNumber({ entities: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onFoundByReceipt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromCollectionPurchaseTransferActions.filteredByReceiptNumber),
			map(({ entities }) => this.entityActions.fetched({ entities }))
		)
	);
}
