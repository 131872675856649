import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IShippingState } from 'src/state/app.state';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { IShipping, IShippingEditForm, IShippingFilterForm } from '../../models/shipping.model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ShippingDialogService } from '../../services/dialog/shipping.service';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-shippingpreparedialog',
	templateUrl: './entitydialog-shipping-prepare.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class ShippingPrepareDialogComponent extends EntityDialogComponent<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	public comment: string = '';
	public ShippingKinds = ShippingKind;

	constructor(
		public shippingFacade: ShippingFacade,
		public productFacade: ProductFacade,
		public certificateFacade: ArticleCertificateFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public shippingDialogService: ShippingDialogService,
		matDialogRef: MatDialogRef<ShippingPrepareDialogComponent, IEntityDialogData<IShipping>>
	) {
		super(shippingFacade, matDialogRef);
	}
}
