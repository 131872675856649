import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteState } from '../../state/app.state';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-delivery-notedialogdelete',
	templateUrl: './entitydialogdelete-delivery-note.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class DeliveryNoteDialogDeleteComponent extends EntityDialogDeleteComponent<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	constructor(entityFacade: DeliveryNoteFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
