import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BundleDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-bundle.component';
import { BundleDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-bundle.component';
import { BundleDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-bundle.component';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { IBundlePosition } from '../../models/position.model';
import { IBundleState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { BundlePositionAddDialogComponent } from './../../components/dialog/dialog-bundle-position-add.component';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class BundleDialogService extends EntityDialogService<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	protected createComponent: ComponentType<any> = BundleDialogAddComponent;
	protected updateComponent: ComponentType<any> = BundleDialogChangeComponent;
	protected removeComponent: ComponentType<any> = BundleDialogDeleteComponent;
	protected addBundlePositionComponent: ComponentType<any> = BundlePositionAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: BundleFacade) {
		super(matDialog, entityFacade);
	}

	public openAddDialog(data: IBundlePosition): MatDialogRef<any> {
		return this.openDialog(this.addBundlePositionComponent, {
			width: null,
			height: null,
			disableClose: false,
			minWidth: '800px',
			data,
		});
	}
}
