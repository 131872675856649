import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleBirthdayChronicleApiService } from '../../services/api/article.birthday-chronicle.service';
import { ArticleBirthdayChronicleDialogService } from '../../services/dialog/article.birthday-chronicle.service';
import { ArticleBirthdayArticleChronicleNotificationService } from '../../services/notification/article.birthday-chronicle.service';
import { AppState, IArticleBirthdayChronicleState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleBirthdayChronicleActions } from './article.birthday-chronicle.actions';
import { ArticleBirthdayChronicleSelector } from './article.birthday-chronicle.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayChronicleEffects extends EntityEffects<IArticleBirthdayChronicle, IArticleBirthdayChronicleState, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleBirthdayChronicleApiService,
		notificationService: ArticleBirthdayArticleChronicleNotificationService,
		dialogService: ArticleBirthdayChronicleDialogService,
		private selector: ArticleBirthdayChronicleSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleBirthdayChronicleActions, 'BIRTHDAYCHRONICLE');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayChronicleActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search(
						{ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.BirthdayChronicle, region },
						paging
					)
					.pipe(
						map(data => fromArticleBirthdayChronicleActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBirthdayChronicleActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayChronicleActions.dayRangeChanged, fromArticleBirthdayChronicleActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleBirthdayChronicleActions.extended());
				}

				return this.apiService
					.search(
						{ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.BirthdayChronicle, region },
						paging
					)
					.pipe(
						map(data => fromArticleBirthdayChronicleActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBirthdayChronicleActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayChronicleActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleBirthdayChronicleActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayChronicleActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
