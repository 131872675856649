import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, Observable, withLatestFrom } from 'rxjs';
import { IPermission } from '../../models/permission.model';
import { AppState, IPermissionState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { fromPermissionActions } from './permission.actions';
import { initialEditFormValue } from './permission.reducer';
import { PermissionSelector } from './permission.selectors';

@Injectable({
	providedIn: 'root',
})
export class PermissionFacade extends EntityFacade<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPermissionActions;

	constructor(store: Store<AppState>, private permissionSelector: PermissionSelector) {
		super(store, permissionSelector);
	}

	public hasPermission(...permissionNames: string[]): Observable<boolean> {
		return this.list$.pipe(
			filter(permissions => permissions.length > 0),
			withLatestFrom(this.store.select(this.permissionSelector.hasPermission(...permissionNames))),
			map(([, hasPermission]) => hasPermission)
		);
	}
}
