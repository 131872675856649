import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProcessRouteNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-process-route.component';
import { IProcessRoute } from '../../models/process-route.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ProcessRouteNotificationService extends EntityNotificationService<IProcessRoute> {
	protected component: ComponentType<any> = ProcessRouteNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
