import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDocument } from 'src/models/document.model';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DocumentApiService extends EntityApiService<IDocument> {
	protected entityName = 'documents';

	constructor(http: HttpClient) {
		super(http);
	}

	public createDocument(entity: IDocument, file: File, authToken: string): Observable<IEntitySingleResponse<IDocument>> {
		let formData = new FormData();
		formData.set('title', entity.title);
		formData.append('file', file);

		return this.put<IEntitySingleResponse<IDocument>, FormData>([this.entityName, 'document'], formData, this.getHeaders(authToken));
	}

	public updateDocument(entity: IDocument, file: File, authToken: string): Observable<IEntitySingleResponse<IDocument>> {
		let formData = new FormData();
		formData.set('title', entity.title);
		formData.append('file', file);

		return this.patch<IEntitySingleResponse<IDocument>, FormData>([this.entityName, 'document', entity._id], formData, this.getHeaders(authToken));
	}
}
