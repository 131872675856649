import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleCertificate } from '../../models/article.model';
import { IArticleCertificateState } from '../../state/app.state';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { IArticleCertificateEditForm, IArticleCertificateFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-certificatedialogadd',
	templateUrl: './entitydialogadd-certificate.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class CertificateDialogAddComponent extends EntityDialogAddComponent<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	constructor(entityFacade: ArticleCertificateFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
