import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { IAccessoryProductState } from '../../state/app.state';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-accessory-productdialogdelete',
	templateUrl: './entitydialogdelete-accessory-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class AccessoryProductDialogDeleteComponent extends EntityDialogDeleteComponent<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	constructor(entityFacade: AccessoryProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
