import { box, validate } from 'ngrx-forms';
import { IArticleDvdEditForm, IArticleDvdFilterForm, IExpandedArticleDvd } from '../../models/article.model';
import { EntityState, IArticleDvdState } from '../app.state';

import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';
import { IArticleDvd } from '../../models/article.model';
import { EntityReducer } from '../entity.reducer';
import { fromArticleDvdActions } from './article.dvd.actions';

export const initialValue: IArticleDvd = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	order: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialEditFormValue: IArticleDvdEditForm = {
	_id: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	order: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialFilterFormValue: IArticleDvdFilterForm = {
	_id: null,
	createdAt: null,
	publicationDate: null,
	quality: box([]),
	storagePlace: null,
	order: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialState = {
	...EntityState.create<IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm>(
		'DVD',
		initialValue,
		initialEditFormValue,
		initialFilterFormValue,
		[
			{
				processInstance: {
					state: 'IN',
				},
				publicationDate: 'GTE',
			},
		],
		{
			product: 1,
			publicationDate: 1,
		}
	),
	isSearching: false,
	isExtending: false,
	itemsSearched: [] as IExpandedArticleDvd[],
	paging: {
		skip: 0,
		top: 10,
	},
	dayRange: {
		current: 3650,
		min: 3650,
		max: 3650,
	},
};

export const articleDvdReducer = EntityReducer.create<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
		publicationDate: validate(required),
	},
	fromArticleDvdActions,
	on(fromArticleDvdActions.search, state => ({
		...state,
		isSearching: true,
		paging: { ...state.paging, top: initialState.paging.top },
		dayRange: { ...state.dayRange, current: state.dayRange.min },
	})),
	on(fromArticleDvdActions.searched, (state, { entities }) => ({
		...state,
		itemsSearched: entities,
		isExtending: false,
		isSearching: false,
	})),
	on(fromArticleDvdActions.failed, state => ({
		...state,
		isSearching: false,
	})),
	on(fromArticleDvdActions.extended, state => ({ ...state, isExtending: false, isSearching: false })),
	on(fromArticleDvdActions.pagingChanged, (state, { paging }) => ({ ...state, paging, isExtending: true })),
	on(fromArticleDvdActions.dayRangeChanged, (state, { dayRange }) => ({ ...state, dayRange, isExtending: true }))
);
