import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { TextTemplateApiService } from '../../services/api/text-template.service';
import { TextTemplateDialogService } from '../../services/dialog/text-template.service';
import { TextTemplateNotificationService } from '../../services/notification/text-template.service';
import { AppState, ITextTemplateState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromTextTemplateActions } from './text-template.actions';
import { TextTemplateSelector } from './text-template.selectors';

@Injectable({
	providedIn: 'root',
})
export class TextTemplateEffects extends EntityEffects<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: TextTemplateApiService,
		notificationService: TextTemplateNotificationService,
		dialogService: TextTemplateDialogService,
		selector: TextTemplateSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromTextTemplateActions, 'TEXTTEMPLATE');
	}
}
