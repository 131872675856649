import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleExpandedYearBook, IArticleYearBook, IArticleYearBookEditForm } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleYearBookActions = {
	...EntityActions.create<IArticleYearBook, IArticleYearBookEditForm>('[YearBook]'),
	search: createAction(`[YearBook] Search`, props<{ date: Date }>()),
	searched: createAction(`[YearBook] Searched`, props<{ date: Date; entities: IArticleExpandedYearBook[] }>()),
	extended: createAction(`[YearBook] Extended`),
	pagingChanged: createAction(`[YearBook] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[YearBook] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
