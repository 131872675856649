import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentBarzahlen } from '../../models/payment.model';
import { IPaymentBarzahlenState } from '../../state/app.state';
import { BarzahlenFacade } from '../../state/payment.barzahlen/payment.barzahlen.facade';
import { IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from './../../models/payment.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-barzahlendialogdelete',
	templateUrl: './entitydialogdelete-barzahlen.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class BarzahlenDialogDeleteComponent extends EntityDialogDeleteComponent<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {
	constructor(entityFacade: BarzahlenFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
