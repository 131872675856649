import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { ITextTemplateState } from '../../state/app.state';
import { TextTemplateFacade } from '../../state/text-template/text-template.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-text-templatedialogadd',
	templateUrl: './entitydialogadd-text-template.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class TextTemplateDialogAddComponent extends EntityDialogAddComponent<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	constructor(entityFacade: TextTemplateFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
