import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IDeliveryNoteReturnState } from '../../state/app.state';
import { DeliveryNoteReturnFacade } from '../../state/delivery-note.return/delivery-note.return.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-delivery-note-returndialogchange',
	templateUrl: './entitydialogchange-delivery-note-return.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DeliveryNoteReturnDialogChangeComponent extends EntityDialogChangeComponent<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	public SalutationKinds = SalutationKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: DeliveryNoteReturnFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
