import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';
import { DialogService, IEntityDialogData } from './dialog.service';

@Injectable({
	providedIn: 'root',
})
export abstract class EntityDialogService<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends DialogService {
	protected createComponent: ComponentType<TEntity> = null;
	protected updateComponent: ComponentType<TEntity> = null;
	protected removeComponent: ComponentType<TEntity> = null;
	protected previewComponent: ComponentType<TEntity> = null;

	constructor(matDialog: MatDialog, protected entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>) {
		super(matDialog);
	}

	public openCreateDialog(data?: IEntityDialogData<TEntity>): MatDialogRef<any> {
		this.entityFacade.create();

		return this.openDialog(this.createComponent, {
			width: data?.width ?? '600px',
			height: data?.height ?? null,
			disableClose: false,
			minWidth: '600px',
		});
	}

	public openUpdateDialog(data: IEntityDialogData<TEntity>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.updateComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '600px',
		});
	}

	public openRemoveDialog(data: IEntityDialogData<TEntity>): MatDialogRef<any> {
		this.entityFacade.remove(data.entity);

		return this.openDialog(this.removeComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openPreviewDialog(data: IEntityDialogData<TEntity>): MatDialogRef<any> {
		this.entityFacade.preview(data.entity);

		return this.openDialog(this.previewComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}
}
