import { Injectable } from '@angular/core';
import { IArticle } from '../../models/article.model';
import { AppState, IArticleState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';

@Injectable({
	providedIn: 'root',
})
export class ArticleSelector extends EntitySelector<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	protected stateSelector = (state: AppState): IArticleState => state.article;
}
