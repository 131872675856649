<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<table mat-table [dataSource]="dataSource$ | async">
		<ng-container matColumnDef="receiptKind">
			<th mat-header-cell *matHeaderCellDef>
				<div>Belegtyp</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let receiptType">
				{{ receiptType.receiptKind }}
			</td>
		</ng-container>
		<ng-container matColumnDef="dueDays">
			<th mat-header-cell *matHeaderCellDef>
				<div>Fällig in</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let receiptType">{{ receiptType.dueDays }} Tag(e)</td>
		</ng-container>
		<ng-container matColumnDef="reminderDays">
			<th mat-header-cell *matHeaderCellDef>
				<div>Wiedervorlage nach Fälligkeit in</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let receiptType">{{ receiptType.reminderDays }} Tag(e)</td>
		</ng-container>
		<ng-container matColumnDef="pdfText">
			<th mat-header-cell *matHeaderCellDef>
				<div>Pdf Text</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let receiptType">
				{{ receiptType.pdfText | slice: 0:60 }}
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td width="50px" mat-cell *matCellDef="let receiptType; let i = index">
				<button mat-icon-button (click)="openEditDialog(receiptType, i)" matTooltip="Bearbeiten">
					<mat-icon svgIcon="pencil"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let row; columns: columns"></tr>
	</table>
</ng-container>
