import { box } from 'ngrx-forms';
import { IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentBarzahlenState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromBarzahlenActions } from './payment.barzahlen.actions';

export const initialValue: IPaymentBarzahlen = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	barzahlenSlip: null,
};

export const initialEditFormValue: IPaymentBarzahlenEditForm = {
	_id: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	barzahlenSlip: null,
};

export const initialFilterFormValue: IPaymentBarzahlenFilterForm = {
	_id: null,
	createdAt: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	barzahlenSlip: null,
};

export const initialState = EntityState.create<IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm>('BARZAHLEN', initialValue, initialEditFormValue, initialFilterFormValue);

export const barzahlenReducer = EntityReducer.create<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm>(initialState, {}, fromBarzahlenActions);
