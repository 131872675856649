import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICounter } from 'src/models/counter.model';
import { ICounterState } from 'src/state/app.state';
import { CounterFacade } from 'src/state/counter/counter.facade';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-counterdialogchange',
	templateUrl: './entitydialogchange-counter.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CounterDialogChangeComponent extends EntityDialogChangeComponent<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	constructor(entityFacade: CounterFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
