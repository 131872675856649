import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleCoin } from '../../models/article.model';
import { ArticleCoinDialogService } from '../../services/dialog/article.coin.service';
import { IArticleCoinState } from '../../state/app.state';
import { ArticleCoinFacade } from '../../state/article.coin/article.coin.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IArticleCoinEditForm, IArticleCoinFilterForm } from './../../models/article.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-coinpage',
	templateUrl: './entitypage-coin.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class CoinPageComponent extends EntityPageComponent<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	public pageTitle$ = new BehaviorSubject('Historische Zahlungsmittel');
	public columns = ['updatedAt', 'order', 'product', 'storagePlace', 'quality', 'publicationDate', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: ArticleCoinDialogService,
		route: ActivatedRoute,
		public entityFacade: ArticleCoinFacade,
		public orderFacade: OrderFacade,
		public mediaProductFacade: MediaProductFacade,
		public mediaStockFacade: MediaStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });
	}
}
