import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { StickNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-stick.component';
import { IArticleStick } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickNotificationService extends EntityNotificationService<IArticleStick> {
	protected component: ComponentType<any> = StickNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
