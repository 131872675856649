<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Nachricht ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<babylon-text-template-dropdownsingle
					*ngIf="!editForm.value.readonly"
					[value]="editForm.controls.textTemplate.value"
					[controlId]="editForm.controls.textTemplate.id"
					placeholder="Optionale Textvorlage"
					class="mat-form-field--span"></babylon-text-template-dropdownsingle>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Titel</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.title" [readonly]="editForm.value.readonly" />
				</mat-form-field>
				<div class="editor" *ngIf="!editForm.value.readonly">
					<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.text"></ckeditor>
				</div>
				<babylon-attachment-autocompletemultiple
					[values]="editForm.controls.attachments.value"
					[controlId]="editForm.controls.attachments.id"
					[optional]="true"
					[allowSelect]="false"
					class="mat-form-field--span"></babylon-attachment-autocompletemultiple>
			</div>
			<div class="iframe" *ngIf="editForm.value.readonly">
				<iframe [srcdoc]="editForm.value.text | safe"></iframe>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button (click)="createAttachment()">Anhang hinzufügen</button>
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
		<babylon-process-buttons [facade]="entityFacade"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
