import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProcessNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-process.component';
import { IProcess } from '../../models/process';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ProcessNotificationService extends EntityNotificationService<IProcess> {
	protected component: ComponentType<any> = ProcessNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
