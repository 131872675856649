import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAttachment } from 'src/models/attachment.model';
import { IAttachmentEditForm, IAttachmentFilterForm } from '../../models/attachment.model';
import { AppState, IAttachmentState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromAttachmentActions } from './attachment.actions';
import { initialEditFormValue } from './attachment.reducer';
import { AttachmentSelector } from './attachment.selectors';

@Injectable({
	providedIn: 'root',
})
export class AttachmentFacade extends EntityFacade<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAttachmentActions;

	constructor(store: Store<AppState>, attachmentSelector: AttachmentSelector) {
		super(store, attachmentSelector);
	}

	public createdAttachment(entity: IAttachment, file: File): void {
		this.store.dispatch(this.actions.createdAttachmentForm({ entity, file }));
	}

	public updatedAttachment(entity: IAttachment, file: File): void {
		this.store.dispatch(this.actions.updatedAttachmentForm({ entity, file }));
	}
}
