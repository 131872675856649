import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IMediaProduct } from '../../models/media-product.model';
import { FilterConnection, IFilterDescriptor, IMediaProductState, SortDescriptors } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-media-product-autocompletesingle',
	templateUrl: './entityautocompletesingle-media-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class MediaProductAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	public placeholder = 'Produkt';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IMediaProduct> = () => ({ name: 1 });

	constructor(entityFacade: MediaProductFacade, public mediaBrandFacade: MediaBrandFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IMediaProduct): string {
		return entity?.name;
	}
}
