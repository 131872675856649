import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IConfiguration, IPickupStation } from '../../models/configuration.model';
import { IConfigurationState } from '../../state/app.state';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { PickupStationDialogComponent } from './../dialog/dialog-pickup-station.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-pickup-stationstable',
	templateUrl: './entitydialogtable-pickup-stations.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class PickupStationsTableComponent extends EntityDialogTableComponent<IConfiguration, IConfigurationState, IPickupStation, IPickupStation, IConfigurationEditForm, IConfigurationFilterForm> {
	public columns = ['type', 'default', 'address', 'edit'];

	constructor(entityFacade: ConfigurationFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IConfiguration): IPickupStation[] {
		return entity.shippingInformation.pickupStations;
	}

	protected createElement(): IPickupStation {
		return {
			type: null,
			default: false,
			address: {
				street: '',
				streetNumber: '',
				city: '',
				postCode: '',
				country: '',
			},
		};
	}

	public openEditDialog(data: IPickupStation, index: number): void {
		const dialogRef = this.matDialog.open(PickupStationDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IPickupStation) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
