import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleFolderState } from 'src/state/app.state';
import { IArticleFolder } from '../../models/article.model';
import { ArticleFolderDialogService } from '../../services/dialog/article.folder.service';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IArticleFolderEditForm, IArticleFolderFilterForm } from './../../models/article.model';
import { ProcessNodeFacade } from './../../state/process-node/process-node.facade';
import { StoragePlaceFacade } from './../../state/storage-place/storage-place.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-folderpage',
	templateUrl: './entitypage-folder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class FolderPageComponent extends EntityPageComponent<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	public pageTitle$ = new BehaviorSubject('Mappen');
	public columns = ['updatedAt', 'order', 'product', 'storagePlace', 'quality', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: ArticleFolderDialogService,
		route: ActivatedRoute,
		public entityFacade: ArticleFolderFacade,
		public orderFacade: OrderFacade,
		public accessoryProductFacade: AccessoryProductFacade,
		public accessoryStockFacade: AccessoryStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });
	}
}
