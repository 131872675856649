import { Injectable } from '@angular/core';
import { IStock } from '../../models/stock.model';
import { AppState, IStockState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';

@Injectable({
	providedIn: 'root',
})
export class StockSelector extends EntitySelector<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	protected stateSelector = (state: AppState): IStockState => state.stock;
}
