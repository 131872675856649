<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Buchungssatz Historie</h1>
	<mat-dialog-content>
		<mat-tab-group>
			<mat-tab label="Historie">
				<babylon-accounting-summaryhistorytable controlId="processInstance.actions"> </babylon-accounting-summaryhistorytable>
			</mat-tab>
		</mat-tab-group>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
