import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { EntityState, IOrderState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromOrderActions } from './order.actions';

export const initialValue: IOrder = {
	_id: null,
	number: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	previewArticles: [],
	shippings: [],
	messages: [],
	customer: null,
	partner: null,
	searchContexts: [],
	positions: [],
	primaryReceipt: null,
	secondaryReceipts: [],
	ipInfo: {
		ip: null,
		hostname: null,
		city: null,
		region: null,
		country: null,
		loc: null,
		postal: null,
		org: null,
		timezone: null,
	},
};

export const initialEditFormValue: IOrderEditForm = {
	_id: null,
	number: null,
	processInstance: {
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		priority: null,
	},
	previewArticles: box([]),
	shippings: box([]),
	messages: box([]),
	customer: null,
	partner: null,
	searchContexts: box([]),
	positions: [
		{
			_id: null,
			positionKind: null,
			title: null,
			price: null,
			taxRate: null,
			units: null,
			paymentKind: null,
			shippingKind: null,
			article: {
				_id: null,
				publicationDate: null,
				articleKind: null,
				processInstance: {
					actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
					state: {
						name: null,
						displayName: null,
						version: null,
					},
					priority: null,
				},
				product: {
					name: null,
					productKind: null,
					articleKind: null,
					price: null,
					taxRate: null,
				},
			},
			bundle: {
				_id: null,
				name: null,
				price: null,
				components: [
					{
						articleKind: null,
						discount: null,
						isPrincipal: null,
					},
				],
			},
			articles: [
				{
					_id: null,
					publicationDate: null,
					articleKind: null,
					processInstance: {
						actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
						state: {
							name: null,
							displayName: null,
							version: null,
						},
						priority: null,
					},
					product: {
						name: null,
						productKind: null,
						articleKind: null,
						price: null,
						taxRate: null,
					},
				},
			],
		},
	],
	primaryReceipt: null,
	secondaryReceipts: box([]),
	ipInfo: {
		ip: null,
		hostname: null,
		city: null,
		region: null,
		country: null,
		loc: null,
		postal: null,
		org: null,
		timezone: null,
	},
};

export const initialFilterFormValue: IOrderFilterForm = {
	_id: null,
	createdAt: null,
	number: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	previewArticles: box([]),
	shippings: box([]),
	messages: box([]),
	customer: box([]),
	partner: box([]),
	searchContexts: box([]),
	positions: [],
	primaryReceipt: null,
	secondaryReceipts: box([]),
};

export const initialState: IOrderState = {
	...EntityState.create<IOrder, IOrderEditForm, IOrderFilterForm>(
		'ORDER',
		initialValue,
		initialEditFormValue,
		initialFilterFormValue,
		[
			{
				customer: 'IN',
				processInstance: {
					state: 'IN',
					currentUser: 'IN',
				},
				number: 'EQ',
				createdAt: 'DATEEQ',
			},
		],
		{
			'processInstance.priority': -1,
			'number': -1,
		}
	),
	searchValue: null,
	searchLimit: 20,
	itemsSearched: [],
	totalCountSearched: 0,
	isSearching: false,
	warnings: [],
};

export const orderReducer = EntityReducer.create<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm>(
	initialState,
	{},
	fromOrderActions,
	on(fromOrderActions.updatedPositions, (state, { positions }) => {
		const updatedPositions = [...state.editForm.value.positions].filter(x => x._id != null);

		for (const position of positions) {
			const index = updatedPositions.findIndex(x => x._id == position._id);

			if (index > -1) {
				updatedPositions.splice(index, 1, position);
			} else {
				updatedPositions.push(position);
			}
		}

		return { ...state, editForm: { ...state.editForm, value: { ...state.editForm.value, positions: updatedPositions } } };
	}),
	on(fromOrderActions.updatePositions, state => ({ ...state, isUpdating: true })),
	on(fromOrderActions.createReturn, state => ({ ...state, isUpdating: true })),
	on(fromOrderActions.searchValueChanged, (state, { searchValue }) => ({ ...state, searchValue })),
	on(fromOrderActions.searchLimitChanged, (state, { searchLimit }) => ({ ...state, searchLimit })),
	on(fromOrderActions.sendConfirmationEmail, state => ({ ...state, isFetching: true })),
	on(fromOrderActions.sentConfirmationEmail, state => ({ ...state, isFetching: false })),
	on(fromOrderActions.duplicate, state => ({ ...state, isFetching: true })),
	on(fromOrderActions.duplicated, state => ({ ...state, isFetching: false })),
	on(fromOrderActions.search, state => ({
		...state,
		error: initialState.error,
		isSearching: true,
		warnings: [],
	})),
	on(fromOrderActions.searched, (state, { entities, totalCount, warnings }) => ({
		...state,
		error: initialState.error,
		isSearching: false,
		items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
		totalCountSearched: totalCount,
		itemsSearched: entities.map(entity => entity._id),
		warnings,
	})),
	on(fromOrderActions.failed, (state, { error }) => ({
		...state,
		isFiltering: false,
		isFetching: false,
		isSuggesting: false,
		isUpdating: false,
		isSearching: false,
		error,
	}))
);
