import { Injectable } from '@angular/core';
import { IReceiptCorrection, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm } from '../../models/receipt';
import { AppState, IReceiptCorrectionState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CorrectionSelector extends EntitySelector<IReceiptCorrection, IReceiptCorrectionState, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {
	protected stateSelector = (state: AppState): IReceiptCorrectionState => state.receiptCorrection;
}
