import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICounter } from 'src/models/counter.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class CounterApiService extends EntityApiService<ICounter> {
	protected entityName = 'counters';

	constructor(http: HttpClient) {
		super(http);
	}
}
