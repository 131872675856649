import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, of, switchMap } from 'rxjs';
import { IPayment } from '../../models/payment.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { PaymentDialogService } from '../../services/dialog/payment.service';
import { PaymentNotificationService } from '../../services/notification/payment.service';
import { AppState, IPaymentState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromOrderActions } from '../order/order.actions';
import { OrderSelector } from '../order/order.selectors';
import { fromBarzahlenActions } from '../payment.barzahlen/payment.barzahlen.actions';
import { fromCashOnDeliveryActions } from '../payment.cash-on-delivery/payment.cash-on-delivery.actions';
import { fromCashActions } from '../payment.cash/payment.cash.actions';
import { fromCollectionPurchaseActions } from '../payment.collection-purchase/payment.collection-purchase.actions';
import { fromDirectDebitActions } from '../payment.direct-debit/payment.direct-debit.actions';
import { fromPaypalActions } from '../payment.paypal/payment.paypal.actions';
import { fromPrepaymentActions } from '../payment.prepayment/payment.prepayment.actions';
import { fromPurchaseOnAccountActions } from '../payment.purchase-on-account/payment.purchase-on-account.actions';
import { SessionSelector } from '../session/session.selectors';
import { IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';
import { PaymentApiService } from './../../services/api/payment.service';
import { fromPaymentActions } from './payment.actions';
import { PaymentSelector } from './payment.selectors';

@Injectable({
	providedIn: 'root',
})
export class PaymentEffects extends EntityEffects<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: PaymentApiService,
		private orderSelector: OrderSelector,
		notificationService: PaymentNotificationService,
		dialogService: PaymentDialogService,
		selector: PaymentSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPaymentActions, 'PAYMENT');
	}

	public onDiscriminatorUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				fromBarzahlenActions.updated,
				fromCashActions.updated,
				fromCashOnDeliveryActions.updated,
				fromDirectDebitActions.updated,
				fromPaypalActions.updated,
				fromPrepaymentActions.updated,
				fromPurchaseOnAccountActions.updated,
				fromCollectionPurchaseActions.updated
			),
			map(({ entity }) => this.entityActions.updated({ entity }))
		)
	);

	public onChangePaymentKind$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPaymentActions.changePaymentKind),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ payment, paymentKind }, authToken]) =>
				this.apiService.changePaymentKind(payment, paymentKind, authToken).pipe(
					map(result => fromPaymentActions.changedPaymentKind({ payment: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onChangedPaymentKind$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPaymentActions.changedPaymentKind),
			map(({ payment }) => fromPaymentActions.updated({ entity: payment }))
		)
	);

	public onUpdatedForOrderInvalidation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPaymentActions.updated),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			filter(([, order]) => !!order),
			map(([, order]) => fromOrderActions.invalidate({ ids: [order._id] }))
		)
	);
}
