import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IMediaProduct, IMediaProductEditForm, IMediaProductFilterForm } from '../../models/media-product.model';
import { IPartnerPrice } from '../../models/product.model';
import { IMediaProductState } from '../../state/app.state';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { PartnerPriceDialogComponent } from '../dialog/dialog-partner-price.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-media-product-partner-pricestable',
	templateUrl: './entitydialogtable-media-product-partner-prices.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class MediaProductPartnerPricesTableComponent extends EntityDialogTableComponent<IMediaProduct, IMediaProductState, IPartnerPrice, IPartnerPrice, IMediaProductEditForm, IMediaProductFilterForm> {
	public columns = ['partner', 'price', 'edit'];

	constructor(entityFacade: MediaProductFacade, public partnerFacade: PartnerFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IMediaProduct): IPartnerPrice[] {
		return entity.partnerPrices;
	}

	protected createElement(): IPartnerPrice {
		return {
			partner: null,
			price: null,
		};
	}

	public openEditDialog(data: IPartnerPrice, index: number): void {
		const dialogRef = this.matDialog.open(PartnerPriceDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IPartnerPrice) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
