import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, IDeviceState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class DeviceSelector extends BaseSelector<IDeviceState> {
	protected stateSelector = (state: AppState): IDeviceState => state.device;

	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public forMachine = createSelector(this.selectState, state => state.forMachine);
	public forUser = createSelector(this.selectState, state => state.forUser);
	public userRegistered = createSelector(this.selectState, state => state.userRegistered);
	public machineId = createSelector(this.selectState, state => state.machineId);
}
