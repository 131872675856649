import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IConfiguration } from 'src/models/configuration.model';
import { IProcessNodeCount } from '../../state/app.state';
import { IDataResponse } from './api.service';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationApiService extends EntityApiService<IConfiguration> {
	protected entityName = 'configurations';

	constructor(http: HttpClient) {
		super(http);
	}

	public count(authToken: string): Observable<IDataResponse<IProcessNodeCount[]>> {
		return of({ data: [], count: 0, totalCount: 0, success: true });
	}
}
