import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IConfiguration, IReceiptTypeInformation } from '../../models/configuration.model';
import { IConfigurationState } from '../../state/app.state';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';
import { ReceiptTypeDialogComponent } from '../dialog/dialog-receipt-type.component';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-receipt-typestable',
	templateUrl: './entitydialogtable-receipt-types.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class ReceiptTypesTableComponent extends EntityDialogTableComponent<IConfiguration, IConfigurationState, IReceiptTypeInformation, IReceiptTypeInformation, IConfigurationEditForm, IConfigurationFilterForm> {
	public columns = ['receiptKind', 'dueDays', 'reminderDays', 'pdfText', 'edit'];

	constructor(entityFacade: ConfigurationFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IConfiguration): IReceiptTypeInformation[] {
		return entity.receiptInformation.receiptTypes;
	}

	protected createElement(): IReceiptTypeInformation {
		return {
			receiptKind: null,
			dueDays: null,
			reminderDays: null,
			pdfText: null,
		};
	}

	public openAddDialog(): void {
		const dialogRef = this.matDialog.open(ReceiptTypeDialogComponent, {
			width: '1000px',
			data: this.createElement(),
		});

		dialogRef.afterClosed().subscribe((result: any) => {
			if (result?.action == 'save') {
				this.add(result.data);
			}
		});
	}

	public openEditDialog(data: IReceiptTypeInformation, index: number): void {
		const dialogRef = this.matDialog.open(ReceiptTypeDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IReceiptTypeInformation) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
