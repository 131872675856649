import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { AppState, IRegionState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { fromRegionActions } from './region.actions';
import { initialEditFormValue } from './region.reducer';
import { RegionSelector } from './region.selectors';

@Injectable({
	providedIn: 'root',
})
export class RegionFacade extends EntityFacade<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromRegionActions;

	public name$ = this.store.select(this.regionSelector.name);
	public isSearching$ = this.store.select(this.regionSelector.isSearching);
	public itemsSearched$ = this.store.select(this.regionSelector.itemsSearched);
	public listSearched$ = this.store.select(this.regionSelector.listSearched);
	public selectedAddress$ = this.store.select(this.regionSelector.selectedAddress);

	constructor(store: Store<AppState>, private regionSelector: RegionSelector) {
		super(store, regionSelector);
	}

	public changeName(name: string) {
		this.store.dispatch(fromRegionActions.nameChanged({ name }));
	}
}
