import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCashOnDelivery } from '../../models/payment.model';
import { IPaymentCashOnDeliveryState } from '../../state/app.state';
import { CashOnDeliveryFacade } from '../../state/payment.cash-on-delivery/payment.cash-on-delivery.facade';
import { IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from './../../models/payment.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-cash-on-deliverydialogdelete',
	templateUrl: './entitydialogdelete-cash-on-delivery.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CashOnDeliveryDialogDeleteComponent extends EntityDialogDeleteComponent<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {
	constructor(entityFacade: CashOnDeliveryFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
