import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleYearBook } from '../../models/article.model';
import { IArticleYearBookState } from '../../state/app.state';
import { ArticleYearBookFacade } from '../../state/article.yearbook/article.yearbook.facade';
import { IArticleYearBookEditForm, IArticleYearBookFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-yearbookdialogadd',
	templateUrl: './entitydialogadd-yearbook.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class YearBookDialogAddComponent extends EntityDialogAddComponent<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	constructor(entityFacade: ArticleYearBookFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
