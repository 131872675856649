import { Injectable } from '@angular/core';
import { IUser, IUserFilterForm } from 'src/models/user.model';
import { AppState, IUserState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IUserEditForm } from './../../models/user.model';

@Injectable({
	providedIn: 'root',
})
export class UserSelector extends EntitySelector<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	protected stateSelector = (state: AppState): IUserState => state.user;
}
