import { box, validate } from 'ngrx-forms';
import { EntityState, IAccessoryProductState } from '../app.state';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';

import { required } from 'ngrx-forms/validation';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { EntityReducer } from '../entity.reducer';
import { fromAccessoryProductActions } from './accessory-product.actions';

export const initialValue: IAccessoryProduct = {
	_id: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	articleKind: null,
	link: null,
	additionalImages: [],
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialEditFormValue: IAccessoryProductEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	articleKind: null,
	link: null,
	additionalImages: box([]),
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialFilterFormValue: IAccessoryProductFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	articleKind: box([]),
	link: null,
	additionalImages: box([]),
	partnerPrices: [],
};

export const initialState = EntityState.create<IAccessoryProduct, IAccessoryProductEditForm, IAccessoryProductFilterForm>(
	'ACCESSORYPRODUCT',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			name: 'CONTAINS',
			price: 'EQ',
		},
	],
	{
		name: 1,
	}
);

export const accessoryProductReducer = EntityReducer.create<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm>(
	initialState,
	{
		name: validate(required),
		price: validate(required),
		taxRate: validate(required),
		articleKind: validate(required),
	},
	fromAccessoryProductActions
);
