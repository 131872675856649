import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AccountingSummaryApiService } from '../../services/api/accounting-summary.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AccountingSummaryDialogService } from '../../services/dialog/accounting-summary.service';
import { AccountingSummaryNotificationService } from '../../services/notification/accounting-summary.service';
import { AppState, IAccountingSummaryState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAccountingSummaryActions } from './accounting-summary.actions';
import { AccountingSummarySelector } from './accounting-summary.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryEffects extends EntityEffects<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: AccountingSummaryApiService,
		notificationService: AccountingSummaryNotificationService,
		dialogService: AccountingSummaryDialogService,
		selector: AccountingSummarySelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromAccountingSummaryActions, 'ACCOUNTINGSUMMARY');
	}

	public onDownloadCsv$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAccountingSummaryActions.downloadCsv),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity }, authToken]) =>
				this.apiService.downloadCsv(authToken, entity).pipe(
					map(data => fromAccountingSummaryActions.downloadedCsv({ entity, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedCsv$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromAccountingSummaryActions.downloadedCsv),
				tap(({ entity, data }) => saveAs(data, `${entity.accountingSummaryKind}_${moment(entity.from).format('DD.MM.YYYY')}-${moment(entity.until).format('DD.MM.YYYY')}.csv`))
			),
		{ dispatch: false }
	);
}
