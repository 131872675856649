import { Directive, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { box, Boxed, unbox } from 'ngrx-forms';
import { BehaviorSubject, debounceTime, filter, Subject, tap, withLatestFrom } from 'rxjs';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { FilterConnection, IEntityState, IFilterDescriptor, SortDescriptors } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';

@Directive()
@UntilDestroy()
export abstract class EntityAutocompleteMultipleComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> implements OnInit, OnChanges
{
	@Input() public abstract placeholder: string;
	@Input() public optional: boolean = false;
	@Input() public values: Boxed<string[]>;
	@Input() public controlId: string;
	@Output() public selected = new EventEmitter<string[]>();
	@ViewChild('input', { static: true }) public inputElement: ElementRef;
	public input$ = new Subject<string>();
	public dataSource$ = new BehaviorSubject<string[]>([]);
	public toBeAdded$ = new Subject<TEntity>();
	public toBeRemoved$ = new Subject<string>();

	protected abstract filterConnection: () => FilterConnection;
	protected abstract filterDescriptorsStatic: () => IFilterDescriptor[];
	protected abstract filterDescriptors: (value: string) => IFilterDescriptor[];
	protected abstract sortDescriptors: () => SortDescriptors<TEntity>;

	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>) {}

	public ngOnInit(): void {
		this.handleValues(this.values);
		this.dataSource$.pipe(untilDestroyed(this)).subscribe(dataSource => this.select(dataSource));
		this.input$
			.pipe(
				untilDestroyed(this),
				debounceTime(500),
				filter(value => !!value && typeof value === 'string' && value.length >= 3),
				tap(value => this.entityFacade.suggest(this.filterConnection(), [...this.filterDescriptorsStatic(), ...this.filterDescriptors(value)], this.sortDescriptors()))
			)
			.subscribe();

		this.toBeAdded$.pipe(untilDestroyed(this), withLatestFrom(this.dataSource$)).subscribe(([entity, dataSource]) => {
			const result = dataSource || [];
			const index = result.findIndex(id => id == entity._id);

			if (index == -1) {
				this.dataSource$.next([...result, entity._id]);
				this.inputElement.nativeElement.value = '';
			}
		});

		this.toBeRemoved$.pipe(untilDestroyed(this), withLatestFrom(this.dataSource$)).subscribe(([id, dataSource]) => {
			const result = [...dataSource];
			const index = result.findIndex(x => x == id);

			if (index > -1) {
				result.splice(index, 1);
				this.dataSource$.next(result);
				this.inputElement.nativeElement.value = '';
			}
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.values != null) {
			const currentValue = changes.values.currentValue;
			const previousValue = changes.values.previousValue;
			const hasChanges = (currentValue == null && previousValue != null) || (previousValue == null && currentValue != null) || !_.isEqual(currentValue, previousValue);

			if (hasChanges) {
				this.handleValues(changes.values.currentValue);
			}
		}
	}

	public abstract displayWith(entity: TEntity): string;

	public select(ids: string[]): void {
		this.selected.emit(ids);

		if (this.controlId != null) {
			this.entityFacade.changeGlobalForm({ controlId: this.controlId, value: box(ids) });
		}
	}

	private handleValues(boxedValues: Boxed<string[]>): void {
		const values = boxedValues != null ? unbox(boxedValues) : [];

		this.entityFacade.suggest('AND', this.filterDescriptorsStatic(), this.sortDescriptors());
		this.dataSource$.next(values);
		this.inputElement.nativeElement.value = '';
	}
}
