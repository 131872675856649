import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgrxValueConverters } from 'ngrx-forms';
import { ArticleKind } from '../../models/article.model';
import { StockTypeEnum } from '../../models/enums/stock-type.enum';
import { IStock } from '../../models/stock.model';
import { IStockState } from '../../state/app.state';
import { StockFacade } from '../../state/stock/stock.facade';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-stockdialogchange',
	templateUrl: './entitydialogchange-stock.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class StockDialogChangeComponent extends EntityDialogChangeComponent<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	constructor(entityFacade: StockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public dateToISOString = NgrxValueConverters.dateToISOString;
	public articleKinds = Object.values(ArticleKind);
	public stockTypes = Object.values(StockTypeEnum);
}
