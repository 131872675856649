import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { IShipping } from '../../models/shipping.model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-change-shipping-kinddialog',
	templateUrl: './dialog-change-shipping-kind.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ChangeShippingKindDialogComponent extends DialogComponent<ChangeShippingKindDialogComponent, IEntityDialogData<IShipping>> {
	public ShippingKinds = ShippingKind;

	constructor(matDialogRef: MatDialogRef<ChangeShippingKindDialogComponent, IEntityDialogData<IShipping>>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IEntityDialogData<IShipping>) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				shippingKind: [data.entity?.shippingKind, [Validators.required]],
				enableSaturdayDelivery: [data.entity?.enableSaturdayDelivery],
			})
		);
	}
}
