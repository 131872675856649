import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IReceiptInvoice } from '../../models/receipt';
import { IReceiptInvoiceState } from '../../state/app.state';
import { InvoiceFacade } from '../../state/receipt.invoice/receipt.invoice.facade';
import { IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm } from './../../models/receipt';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-invoicedialogdelete',
	templateUrl: './entitydialogdelete-invoice.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class InvoiceDialogDeleteComponent extends EntityDialogDeleteComponent<IReceiptInvoice, IReceiptInvoiceState, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {
	constructor(entityFacade: InvoiceFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
