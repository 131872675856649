import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IStock } from '../../models/stock.model';
import { StockApiService } from '../../services/api/stock.service';
import { StockDialogService } from '../../services/dialog/stock.service';
import { StockNotificationService } from '../../services/notification/stock.service';
import { AppState, IStockState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { fromStockActions } from './stock.actions';
import { StockSelector } from './stock.selectors';

@Injectable({
	providedIn: 'root',
})
export class StockEffects extends EntityEffects<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: StockApiService, notificationService: StockNotificationService, dialogService: StockDialogService, selector: StockSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromStockActions, 'STOCK');
	}
}
