import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IOrderState } from '../../state/app.state';
import { OrderFacade } from '../../state/order/order.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-orderdialogdelete',
	templateUrl: './entitydialogdelete-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class OrderDialogDeleteComponent extends EntityDialogDeleteComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	constructor(entityFacade: OrderFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
