import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAction } from '../../models/process-instance-model';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { IShippingDhlExpressState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { DhlExpressFacade } from '../../state/shipping.dhl-express/shipping.dhl-express.facade';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-dhl-expresshistorytable',
	templateUrl: './entitydialogtable-dhl-expresshistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class DhlExpressHistoryTableComponent extends HistoryDialogTableComponent<IShippingDhlExpress, IShippingDhlExpressState, IAction, IAction, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	public columns = ['date', 'userName', 'route', 'state', 'comment'];

	constructor(entityFacade: DhlExpressFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IShippingDhlExpress): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
