import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMediaProduct } from '../../models/media-product.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MediaProductApiService extends EntityApiService<IMediaProduct> {
	protected entityName = 'media-products';

	constructor(http: HttpClient) {
		super(http);
	}
}
