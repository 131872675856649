import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAttachment, IAttachmentEditForm } from 'src/models/attachment.model';
import { IAttachmentState } from 'src/state/app.state';
import { AttachmentFacade } from 'src/state/attachment/attachment.facade';
import { IAttachmentFilterForm } from '../../models/attachment.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-attachmentdialogadd',
	templateUrl: './entitydialogadd-attachment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class AttachmentDialogAddComponent extends EntityDialogAddComponent<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	private attachmentFile: File = null;

	constructor(public attachmentFacade: AttachmentFacade, matDialogRef: MatDialogRef<any>) {
		super(attachmentFacade, matDialogRef);
	}

	public onAttachmentChanged($event: any): void {
		this.attachmentFile = $event.target.files[0];
	}

	public createAttachment(attachment: IAttachment) {
		this.attachmentFacade.createdAttachment(attachment, this.attachmentFile);
	}
}
