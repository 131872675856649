import { IEntity, IEntityEditForm, IEntityFilterForm } from './entity.model';

export interface IAccountingSummary<TAccountingSummaryPosition extends IAccountingSummaryPosition> extends IEntity {
	positions: TAccountingSummaryPosition[];
	from: string;
	until: string;
	accountingSummaryKind: AccountingSummaryKind;
}

export interface IAccountingSummaryEditForm<TAccountingSummaryPosition extends IAccountingSummaryPosition> extends IEntityEditForm {
	positions: TAccountingSummaryPosition[];
	from: string;
	until: string;
	accountingSummaryKind: AccountingSummaryKind;
}

export interface IAccountingSummaryFilterForm<TAccountingSummaryPosition extends IAccountingSummaryPosition> extends IEntityFilterForm {
	positions: TAccountingSummaryPosition[];
	from: string;
	until: string;
	accountingSummaryKind: AccountingSummaryKind;
}

export interface IAccountingSummaryAdvancement extends IAccountingSummary<IAccountingSummaryAdvancementPosition> {}
export interface IAccountingSummaryAdvancementEditForm extends IAccountingSummaryEditForm<IAccountingSummaryAdvancementPosition> {}
export interface IAccountingSummaryAdvancementFilterForm extends IAccountingSummaryFilterForm<IAccountingSummaryAdvancementPosition> {}
export interface IAccountingSummaryCustomer extends IAccountingSummary<IAccountingSummaryCustomerPosition> {}
export interface IAccountingSummaryCustomerEditForm extends IAccountingSummaryEditForm<IAccountingSummaryCustomerPosition> {}
export interface IAccountingSummaryCustomerFilterForm extends IAccountingSummaryFilterForm<IAccountingSummaryCustomerPosition> {}

export interface IAccountingSummaryPosition {
	orderNumber: string;
}

export interface IAccountingSummaryAdvancementPosition extends IAccountingSummaryPosition {
	receiptNumber: string;
	date: string;
	amount: number;
	account: string;
	name: string;
	having: string;
	paypalTransactionId: string;
}

export interface IAccountingSummaryCustomerPosition extends IAccountingSummaryPosition {
	firstName: string;
	lastName: string;
	company: string;
	street: string;
	postCode: string;
	city: string;
	country: string;
	paypalTransactionId: string;
}

export enum AccountingSummaryKind {
	CUSTOMERS = 'Kunden',
	ADVANCEMENTS = 'Forderungen',
}
