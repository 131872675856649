import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IStoragePlace } from '../../models/storage-place.model';
import { StoragePlaceApiService } from '../../services/api/storage-palce.service';
import { StoragePlaceDialogService } from '../../services/dialog/storage-place.service';
import { StoragePlaceNotificationService } from '../../services/notification/storage-place.service';
import { AppState, IStoragePlaceState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { fromStoragePlaceActions } from './storage-place.actions';
import { StoragePlaceSelector } from './storage-place.selectors';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceEffects extends EntityEffects<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: StoragePlaceApiService,
		notificationService: StoragePlaceNotificationService,
		dialogService: StoragePlaceDialogService,
		selector: StoragePlaceSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromStoragePlaceActions, 'STORAGEPLACE');
	}
}
