import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IPermission } from '../../models/permission.model';
import { AppState, IPermissionState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { SessionSelector } from '../session/session.selectors';
import { IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';

@Injectable({
	providedIn: 'root',
})
export class PermissionSelector extends EntitySelector<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	protected stateSelector = (state: AppState): IPermissionState => state.permission;

	constructor(private sessionSelector: SessionSelector) {
		super();
	}

	public getByName = (...permissionNames: string[]) => createSelector(this.list, (permissions: IPermission[]) => permissions.filter(permission => permissionNames.includes(permission.name)));
	public hasPermission = (...permissionNames: string[]) => createSelector(this.getByName(...permissionNames), this.sessionSelector.user, (permissions, user) => user.permissions.some(x => permissions.some(y => y._id === x)));
}
