import { box, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IProduct } from '../../models/product.model';
import { EntityState, IProductState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { fromProductActions } from './product.actions';

export const initialValue: IProduct = {
	_id: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	articleKind: null,
	link: null,
	additionalImages: [],
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialEditFormValue: IProductEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: null,
	minimalQuantity: null,
	shortDescription: null,
	articleKind: null,
	link: null,
	additionalImages: box([]),
	partnerPrices: [
		{
			partner: null,
			price: null,
		},
	],
};

export const initialFilterFormValue: IProductFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	price: null,
	description: null,
	taxRate: null,
	priority: null,
	image: null,
	logo: null,
	productKind: box([]),
	minimalQuantity: null,
	shortDescription: null,
	articleKind: box([]),
	link: null,
	additionalImages: box([]),
	partnerPrices: [],
};

export const initialState = EntityState.create<IProduct, IProductEditForm, IProductFilterForm>('PRODUCT', initialValue, initialEditFormValue, initialFilterFormValue);
export const productReducer = EntityReducer.create<IProduct, IProductState, IProductEditForm, IProductFilterForm>(
	initialState,
	{
		name: validate(required),
		price: validate(required),
		articleKind: validate(required),
	},
	fromProductActions
);
