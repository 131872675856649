import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IImage } from 'src/models/image.model';
import { AppState, IImageState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IImageEditForm, IImageFilterForm } from './../../models/image.model';
import { fromImageActions } from './image.actions';
import { initialEditFormValue } from './image.reducer';
import { ImageSelector } from './image.selectors';

@Injectable({
	providedIn: 'root',
})
export class ImageFacade extends EntityFacade<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromImageActions;

	constructor(store: Store<AppState>, imageSelector: ImageSelector) {
		super(store, imageSelector);
	}

	public createdImage(entity: IImage, file: File): void {
		this.store.dispatch(this.actions.createdImageForm({ entity, file }));
	}

	public updatedImage(entity: IImage, file: File): void {
		this.store.dispatch(this.actions.updatedImageForm({ entity, file }));
	}
}
