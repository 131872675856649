import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm } from '../../models/publication-cycle.model';
import { FilterConnection, IFilterDescriptor, IPublicationCycleState, SortDescriptors } from '../../state/app.state';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-publication-cycle-autocompletesingle',
	templateUrl: './entityautocompletesingle-publication-cycle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class PublicationCycleAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	public placeholder = 'Veröffentlichungszeitraum';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IPublicationCycle> = () => ({ name: 1 });

	constructor(entityFacade: PublicationCycleFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IPublicationCycle): string {
		return entity?.name;
	}
}
