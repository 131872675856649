import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { IBundleComponent } from '../../models/bundle.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-bundlecomponentdialog',
	templateUrl: './dialog-bundlecomponent.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class BundleComponentDialogComponent extends DialogComponent<BundleComponentDialogComponent, IBundleComponent> {
	public ArticleKinds = ArticleKind;

	constructor(matDialogRef: MatDialogRef<BundleComponentDialogComponent, IBundleComponent>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: IBundleComponent) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				articleKind: [data.articleKind, [Validators.required]],
				discount: [data.discount, [Validators.required]],
				isPrincipal: [data.isPrincipal, []],
			})
		);
	}
}
