import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { CounterNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-counter.component';
import { ICounter } from 'src/models/counter.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class CounterNotificationService extends EntityNotificationService<ICounter> {
	protected component: ComponentType<any> = CounterNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
