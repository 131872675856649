import { Pipe, PipeTransform } from '@angular/core';
import { ArticleKind, IArticle, IArticleCertificate } from '../models/article.model';

@Pipe({
	name: 'asCertificate',
})
export class CastCertificatePipe implements PipeTransform {
	transform(article: IArticle | null): any {
		return article?.articleKind == ArticleKind.Certificate ? (article as IArticleCertificate) : null;
	}
}
