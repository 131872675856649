import { AppState, IReceiptState } from '../app.state';
import { IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IOrder } from '../../models/order.model';
import { IPayment } from '../../models/payment.model';
import { IReceipt } from '../../models/receipt';
import { EntityFacade } from '../entity.facade';
import { fromReceiptActions } from './receipt.actions';
import { initialEditFormValue } from './receipt.reducer';
import { ReceiptSelector } from './receipt.selectors';

@Injectable({
	providedIn: 'root',
})
export class ReceiptFacade extends EntityFacade<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromReceiptActions;

	constructor(store: Store<AppState>, private receiptSelector: ReceiptSelector) {
		super(store, receiptSelector);
	}

	public download(receipt: IReceipt): void {
		this.store.dispatch(this.actions.download({ receipt }));
	}

	public downloadFirstReminder(receipt: IReceipt): void {
		this.store.dispatch(this.actions.downloadFirstReminder({ receipt }));
	}

	public downloadSecondReminder(receipt: IReceipt): void {
		this.store.dispatch(this.actions.downloadSecondReminder({ receipt }));
	}

	public print(receipt: IReceipt): void {
		this.store.dispatch(this.actions.print({ receipt }));
	}

	public updateReceiver(): void {
		this.store.dispatch(this.actions.updateReceiver());
	}

	public followUp(): void {
		this.store.dispatch(this.actions.followUp());
	}

	public fetchManyCombined(order: IOrder): Observable<IReceipt[]> {
		const allReceipts = [order.primaryReceipt, ...order.secondaryReceipts];
		return this.fetchMany(allReceipts);
	}

	public getOrderReceiptByPayment(receipts: IReceipt[], payment: IPayment): IReceipt {
		return receipts.find(receipt => receipt?.payments?.some(x => x === payment._id));
	}
}
