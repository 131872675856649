import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IEntity } from 'src/models/entity.model';
import { AuthorizationErrorComponent } from '../../components/entitynotificationerror/authorizationerror.component';
import { IServiceError } from '../api/api.service';
import { NotificationHandler, NotificationService } from './notification.service';

@UntilDestroy()
@Injectable({
	providedIn: 'root',
})
export abstract class EntityNotificationService<TEntity extends IEntity> extends NotificationService {
	protected unauthorizedComponent = AuthorizationErrorComponent;
	protected abstract component: ComponentType<TEntity>;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}

	public showError(error: IServiceError): void {
		const component = error.statusCode == 401 ? this.unauthorizedComponent : this.component;
		const horizontalPosition = error.statusCode == 401 ? 'center' : 'right';

		this.notificationHandler.showError(error, component, { horizontalPosition });
	}
}
