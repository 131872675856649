import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm } from '../../models/shipping.model';
import { AppState, IShippingPickupState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPickupActions } from './shipping.pickup.actions';
import { initialEditFormValue } from './shipping.pickup.reducer';
import { PickupSelector } from './shipping.pickup.selectors';

@Injectable({
	providedIn: 'root',
})
export class PickupFacade extends EntityFacade<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPickupActions;

	constructor(store: Store<AppState>, PickupSelector: PickupSelector) {
		super(store, PickupSelector);
	}
}
