import { createAction, props } from '@ngrx/store';
import { IUser } from '../../models/user.model';
import { IServiceError } from '../../services/api/api.service';

export interface IInvalidationResult {
	entityIdentifier: string;
	entityId: string;
}

const stateName = '[Session]';

export const fromSessionActions = {
	login: createAction(`${stateName} Login`),
	loggedIn: createAction(`${stateName} Logged In`, props<{ authToken: string; user: IUser }>()),
	logout: createAction(`${stateName} Logout`),
	loggedOut: createAction(`${stateName} Logged Out`),
	reset: createAction(`${stateName} Reset`),
	failed: createAction(`${stateName} Failed`, props<{ error: IServiceError }>()),
	invalidated: createAction(`${stateName} Invalidated`, props<{ invalidationResults: IInvalidationResult[] }>()),
	changedAdministrateProcessRoutes: createAction(`${stateName} Changed Administrate Process Routes`, props<{ administrateProcessRoutes: boolean }>()),
	resetDatabase: createAction(`${stateName} Reset Database`, props<{ commit: string }>()),
	resettedDatabase: createAction(`${stateName} Resetted Database`),
};
