import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DhlDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-dhl.component';
import { DhlDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-dhl.component';
import { DhlDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-dhl.component';
import { IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm } from '../../models/shipping.model';
import { IShippingDhlState } from '../../state/app.state';
import { DhlFacade } from '../../state/shipping.dhl/shipping.dhl.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingDhlDialogService extends EntityDialogService<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm> {
	protected createComponent: ComponentType<any> = DhlDialogAddComponent;
	protected updateComponent: ComponentType<any> = DhlDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DhlDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DhlFacade) {
		super(matDialog, entityFacade);
	}
}
