import { Injectable } from '@angular/core';
import { IAttachment } from 'src/models/attachment.model';
import { IAttachmentEditForm, IAttachmentFilterForm } from '../../models/attachment.model';
import { AppState, IAttachmentState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class AttachmentSelector extends EntitySelector<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	protected stateSelector = (state: AppState): IAttachmentState => state.attachment;
}
