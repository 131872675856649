import { IReceipt, IReceiptCorrection } from '../../models/receipt';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ReceiptCorrectionApiService extends EntityApiService<IReceiptCorrection> {
	protected entityName = 'corrections';

	constructor(http: HttpClient) {
		super(http);
	}

	public download(authToken: string, receipt: IReceipt): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'application/pdf');

		return this.post<any, any>([this.entityName, receipt._id, 'generate-pdf'], null, headers, 'blob');
	}

	public downloadFirstReminder(authToken: string, receipt: IReceipt): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'application/pdf');

		return this.post<any, any>([this.entityName, receipt._id, 'generate-pdf', 'first-reminder'], null, headers, 'blob');
	}

	public downloadSecondReminder(authToken: string, receipt: IReceipt): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'application/pdf');

		return this.post<any, any>([this.entityName, receipt._id, 'generate-pdf', 'second-reminder'], null, headers, 'blob');
	}

	public print(authToken: string, receipt: IReceipt): Observable<string> {
		let headers = this.getHeaders(authToken);

		return this.post<any, unknown>([this.entityName, receipt._id, 'print-pdf'], null, headers);
	}
}
