import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm } from '../../models/accounting-summary.model';
import { AccountingSummaryAdvancementApiService } from '../../services/api/accounting-summary.advancement.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AccountingSummaryAdvancementDialogService } from '../../services/dialog/accounting-summary.advancement.service';
import { AccountingSummaryAdvancementNotificationService } from '../../services/notification/accounting-summary.advancement.service';
import { AppState, IAccountingSummaryAdvancementState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAccountingSummaryAdvancementActions } from './accounting-summary.advancement.actions';
import { AccountingSummaryAdvancementSelector } from './accounting-summary.advancement.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementEffects extends EntityEffects<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementState, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private accountingSummaryAdvancementApiService: AccountingSummaryAdvancementApiService,
		notificationService: AccountingSummaryAdvancementNotificationService,
		dialogService: AccountingSummaryAdvancementDialogService,
		selector: AccountingSummaryAdvancementSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, accountingSummaryAdvancementApiService, notificationService, dialogService, selector, sessionSelector, fromAccountingSummaryAdvancementActions, 'ACCOUNTINGSUMMARYADVANCEMENT');
	}

	public onFindByReceipt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAccountingSummaryAdvancementActions.filterByReceiptNumbers),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receiptNumbers }, authToken]) =>
				this.accountingSummaryAdvancementApiService.filterByReceiptNumbers(receiptNumbers, authToken).pipe(
					map(result => fromAccountingSummaryAdvancementActions.filteredByReceiptNumber({ entities: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onFoundByReceipt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAccountingSummaryAdvancementActions.filteredByReceiptNumber),
			map(({ entities }) => this.entityActions.fetched({ entities }))
		)
	);
}
