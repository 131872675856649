import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPage } from '../../models/page.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PageApiService extends EntityApiService<IPage> {
	public entityName = 'pages';

	constructor(http: HttpClient) {
		super(http);
	}
}
