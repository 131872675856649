<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="(stickFacade.isSearching$ | async) || (stickFacade.isExtending$ | async)"></mat-progress-bar>
<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Zeitungshalter auswählen</h1>
<mat-dialog-content>
	<form>
		<div class="form-grid">
			<ng-container *ngIf="stickFacade.itemsSearched$ | async as itemsSearched">
				<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
					<ng-container matColumnDef="storagePlace">
						<th mat-header-cell *matHeaderCellDef>Lagerplatz</th>
						<td mat-cell *matCellDef="let entity">
							{{ (storagePlaceFacade.fetchOne(entity.storagePlace) | async)?.identifier }}
							<babylon-fetch [facade]="storagePlaceFacade" [value]="entity.storagePlace"></babylon-fetch>
						</td>
					</ng-container>

					<ng-container matColumnDef="product">
						<th mat-header-cell *matHeaderCellDef>Produkt</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity.product?.name }}
						</td>
					</ng-container>

					<ng-container matColumnDef="add">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let entity">
							<button type="button" mat-icon-button matTooltip="Auswählen" (click)="addStick(entity)">
								<mat-icon svgIcon="check"></mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: columns"></tr>
				</table>
			</ng-container>
		</div>
	</form>
	<div *ngIf="(stickFacade.itemsSearched$ | async).length > 0 && (stickFacade.isExtending$ | async)" class="more-results more-results__spinner"><mat-spinner [diameter]="25"></mat-spinner> Weitere Zeitungshalter werden gesucht...</div>
	<div *ngIf="!(stickFacade.isExtending$ | async)" class="more-results">
		<a (click)="stickFacade.extend()">Weitere Zeitungshalter finden</a>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="cancel()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="stickFacade.search()" class="search-button">Suchen</button>
</mat-dialog-actions>
