import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CollectionPurchaseTransferDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-collection-purchase-transfer.component';
import { CollectionPurchaseTransferDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-collection-purchase-transfer.component';
import { CollectionPurchaseTransferDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-collection-purchase-transfer.component';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { ICollectionPurchaseTransferState } from '../../state/app.state';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseTransferDialogService extends EntityDialogService<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	protected createComponent: ComponentType<any> = CollectionPurchaseTransferDialogAddComponent;
	protected updateComponent: ComponentType<any> = CollectionPurchaseTransferDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CollectionPurchaseTransferDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CollectionPurchaseTransferFacade) {
		super(matDialog, entityFacade);
	}
}
