<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Position bearbeiten</h1>
<mat-dialog-content>
	<form [formGroup]="formGroup">
		<div class="form-grid">
			<mat-form-field appearance="outline" class="mat-form-field--span">
				<mat-label>Titel</mat-label>
				<input type="text" matInput formControlName="title" />
			</mat-form-field>
			<mat-form-field appearance="outline" class="readonly">
				<mat-select formControlName="positionKind" placeholder="Positionstyp">
					<mat-option *ngFor="let positionKind of PositionKinds | keyvalue" [value]="positionKind.value">{{ positionKind.value }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Preis</mat-label>
				<input type="number" matInput formControlName="price" />
				<span matSuffix>€</span>
			</mat-form-field>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
</mat-dialog-actions>
