import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IUserEditForm } from 'src/models/user.model';
import { progressFade } from '../../animations/progressfade.animation';
import { IUser } from '../../models/user.model';
import { FilterConnection, IFilterDescriptor, IUserState, SortDescriptors } from '../../state/app.state';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { UserFacade } from '../../state/user/user.facade';
import { IUserFilterForm } from './../../models/user.model';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-user-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class UserAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	public placeholder = 'Benutzer';
	public columns: string[] = ['name', 'delete'];

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'STARTSWITH', value }];
	protected override sortDescriptors: () => SortDescriptors<IUser> = () => ({ name: 1 });

	constructor(entityFacade: UserFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IUser): string {
		return entity != null ? entity.name : null;
	}
}
