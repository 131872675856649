import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromSessionActions } from './session.actions';
import { SessionSelector } from './session.selectors';

@Injectable({
	providedIn: 'root',
})
export class SessionFacade extends BaseFacade {
	constructor(store: Store<AppState>, private sessionSelector: SessionSelector) {
		super(store);
	}

	public userForm$ = this.store.select(this.sessionSelector.userForm);
	public isLoggedIn$ = this.store.select(this.sessionSelector.isLoggedIn);
	public isFiltering$ = this.store.select(this.sessionSelector.isLoading);
	public authToken$ = this.createBehaviorSubject(this.store.select(this.sessionSelector.authToken), null);
	public user$ = this.store.select(this.sessionSelector.user);
	public administrateProcessRoutes$ = this.store.select(this.sessionSelector.administrateProcessRoutes);

	public login(): void {
		this.store.dispatch(fromSessionActions.login());
	}

	public logout(): void {
		this.store.dispatch(fromSessionActions.logout());
	}

	public reset(): void {
		this.store.dispatch(fromSessionActions.reset());
	}

	public changeAdministrateProcessRoutes(administrateProcessRoutes: boolean): void {
		this.store.dispatch(fromSessionActions.changedAdministrateProcessRoutes({ administrateProcessRoutes }));
	}

	public resetDatabase(commit: string): void {
		this.store.dispatch(fromSessionActions.resetDatabase({ commit }));
	}
}
