import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoragePlaceDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-storage-place.component';
import { StoragePlaceDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-storage-place.component';
import { StoragePlaceDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-storage-place.component';
import { IStoragePlace } from '../../models/storage-place.model';
import { IStoragePlaceState } from '../../state/app.state';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceDialogService extends EntityDialogService<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	protected createComponent: ComponentType<any> = StoragePlaceDialogAddComponent;
	protected updateComponent: ComponentType<any> = StoragePlaceDialogChangeComponent;
	protected removeComponent: ComponentType<any> = StoragePlaceDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: StoragePlaceFacade) {
		super(matDialog, entityFacade);
	}
}
