import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IMediaBrand } from '../../models/media-brand.model';
import { FilterConnection, IFilterDescriptor, IMediaBrandState, SortDescriptors } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-media-brand-autocompletesingle',
	templateUrl: './entityautocompletesingle-media-brand.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class MediaBrandAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	public placeholder = 'Marke';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'caption', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IMediaBrand> = () => ({ caption: 1 });

	constructor(entityFacade: MediaBrandFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IMediaBrand): string {
		return entity?.caption;
	}
}
