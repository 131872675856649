import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomer } from './../../models/customer.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class CustomerApiService extends EntityApiService<ICustomer> {
	protected entityName = 'customers';

	constructor(http: HttpClient) {
		super(http);
	}
}
