import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { ChartConfiguration } from 'chart.js';
import _ from 'lodash';
import moment from 'moment';
import { AppState, IAggregationState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class AggregationSelector extends BaseSelector<IAggregationState> {
	protected stateSelector = (state: AppState): IAggregationState => state.aggregation;

	public orderCount = createSelector(this.selectState, state => state.orderCount);
	public orderState = createSelector(this.selectState, state => state.orderState);
	public showOrderState = createSelector(this.orderState, orderState => _.keys(orderState).length > 0);
	public receiptTotalPrice = createSelector(this.selectState, state => state.receiptTotalPrice);
	public receiptState = createSelector(this.selectState, state => state.receiptState);
	public showReceiptState = createSelector(this.receiptState, receiptState => _.keys(receiptState).length > 0);
	public timespan = createSelector(this.selectState, state => ({ from: state.from, until: state.until }));
	public from = createSelector(this.selectState, state => state.from);
	public until = createSelector(this.selectState, state => state.until);
	public granularity = createSelector(this.selectState, state => state.granularity);

	public orderCountOfDate = (days: number) => createSelector(this.orderCount, orderCount => orderCount[moment().subtract(days, 'days').format('YYYY-MM-DD')] || null);
	public receiptTotalPriceOfDate = (days: number) => createSelector(this.receiptTotalPrice, receiptTotalPrice => receiptTotalPrice[moment().subtract(days, 'days').format('YYYY-MM-DD')] || null);

	public orderStateData = createSelector(this.orderState, orderState => ({
		datasets: _.map(orderState, (aggregation, key) => ({
			data: _.values(aggregation),
			label: key,
			yAxisID: 'Übergänge',
		})),
		labels: _.keys(_.values(orderState)[0]),
	}));

	public orderStateOptions = createSelector(
		this.selectState,
		state =>
			({
				responsive: true,
				maintainAspectRatio: false,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				elements: {
					line: {
						tension: 0.5,
					},
				},
				scales: {
					Übergänge: {
						position: 'left',
					},
				},
			} as ChartConfiguration['options'])
	);

	public receiptStateData = createSelector(this.receiptState, receiptState => ({
		datasets: _.map(receiptState, (aggregation, key) => ({
			data: _.values(aggregation),
			label: key,
			yAxisID: 'Übergänge',
		})),
		labels: _.keys(_.values(receiptState)[0]),
	}));

	public receiptStateOptions = createSelector(
		this.selectState,
		state =>
			({
				responsive: true,
				maintainAspectRatio: false,
				interaction: {
					intersect: false,
					mode: 'index',
				},
				elements: {
					line: {
						tension: 0.5,
					},
				},
				scales: {
					Übergänge: {
						position: 'left',
					},
				},
			} as ChartConfiguration['options'])
	);

	public isLoading = createSelector(this.selectState, state => state.isLoading);
}
