import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { RouterSelector } from './router.selectors';

@Injectable({
	providedIn: 'root',
})
export class RouterFacade extends BaseFacade {
	constructor(store: Store<AppState>, private routerSelector: RouterSelector) {
		super(store);
	}

	public routeData$ = this.store.select(this.routerSelector.routeData);
	public routeParams$ = this.store.select(this.routerSelector.routeParams);

	public orderId$ = this.store.select(this.routerSelector.orderId);
	public orderStates$ = this.store.select(this.routerSelector.orderStates);
	public articleStates$ = this.store.select(this.routerSelector.articleStates);
	public shippingStates$ = this.store.select(this.routerSelector.shippingStates);
	public paymentStates$ = this.store.select(this.routerSelector.paymentStates);
	public receiptStates$ = this.store.select(this.routerSelector.receiptStates);
	public previousRoute$ = this.store.select(this.routerSelector.previousRoute);
	public accountingSummaryStates$ = this.store.select(this.routerSelector.accountingSummaryStates);
	public directDebitTransferStates$ = this.store.select(this.routerSelector.directDebitTransferStates);
	public collectionPurchaseTransferStates$ = this.store.select(this.routerSelector.collectionPurchaseTransferStates);
	public stockStates$ = this.store.select(this.routerSelector.stockStates);
}
