import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IUser, IUserEditForm, IUserFilterForm } from 'src/models/user.model';
import { IUserState } from 'src/state/app.state';
import { UserFacade } from 'src/state/user/user.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-userdialogdelete',
	templateUrl: './entitydialogdelete-user.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class UserDialogDeleteComponent extends EntityDialogDeleteComponent<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	constructor(entityFacade: UserFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
