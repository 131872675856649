import { box } from 'ngrx-forms';
import { IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm } from '../../models/shipping.model';
import { EntityState, IShippingDhlState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromDhlActions } from './shipping.dhl.actions';

export const initialValue: IShippingDhl = {
	_id: null,
	shipmentNumber: null,
	labelUrl: null,
	order: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	dhlOrder: {
		shipmentNumber: null,
		labelUrl: null,
	},
};

export const initialEditFormValue: IShippingDhlEditForm = {
	_id: null,
	shipmentNumber: null,
	order: null,
	labelUrl: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	consentEmailTransfer: null,
	shippingWithoutInvoice: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	dhlOrder: {
		shipmentNumber: null,
		labelUrl: null,
	},
};

export const initialFilterFormValue: IShippingDhlFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	shipmentNumber: null,
	labelUrl: null,
	deliveryNote: box([]),
	shippingKind: null,
	processInstance: null,
};

export const initialState = EntityState.create<IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm>('DHL', initialValue, initialEditFormValue, initialFilterFormValue);
export const dhlReducer = EntityReducer.create<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm>(initialState, {}, fromDhlActions);
