import { box } from 'ngrx-forms';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentCollectionPurchaseState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCollectionPurchaseActions } from './payment.collection-purchase.actions';

export const initialValue: IPaymentCollectionPurchase = {
	_id: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentCollectionPurchaseEditForm = {
	_id: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentCollectionPurchaseFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	paidDate: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm>('COLLECTIONPURCHASE', initialValue, initialEditFormValue, initialFilterFormValue);
export const collectionPurchaseReducer = EntityReducer.create<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm>(
	initialState,
	{},
	fromCollectionPurchaseActions
);
