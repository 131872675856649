import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleCoin, IArticleCoinEditForm, IExpandedArticleCoin } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleCoinActions = {
	...EntityActions.create<IArticleCoin, IArticleCoinEditForm>('[Coin]'),
	search: createAction(`[Coin] Search`, props<{ date: Date }>()),
	searched: createAction(`[Coin] Searched`, props<{ date: Date; entities: IExpandedArticleCoin[] }>()),
	extended: createAction(`[Coin] Extended`),
	pagingChanged: createAction(`[Coin] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[Coin] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
