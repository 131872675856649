import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MediaStockNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-media-stock.component';
import { IStockMedia } from '../../models/stock.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class MediaStockNotificationService extends EntityNotificationService<IStockMedia> {
	protected component: ComponentType<any> = MediaStockNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
