import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { IProcessRoute } from '../../models/process-route.model';
import { ProcessRouteApiService } from '../../services/api/process-route.service';
import { ProcessRouteNotificationService } from '../../services/notification/process-route.service';
import { AppState, IProcessRouteState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromSessionActions } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { fromProcessRouteActions } from './process-route.actions';
import { ProcessRouteSelector } from './process-route.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessRouteEffects extends EntityEffects<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: ProcessRouteApiService, notificationService: ProcessRouteNotificationService, selector: ProcessRouteSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, null, selector, sessionSelector, fromProcessRouteActions, 'PROCESSROUTE');
	}

	public onLoggedIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.loggedIn),
			map(() => fromProcessRouteActions.filter())
		)
	);
}
