import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IConfiguration } from 'src/models/configuration.model';
import { AppState, IConfigurationState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationSelector extends EntitySelector<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	protected stateSelector = (state: AppState): IConfigurationState => state.configuration;
	public single = createSelector(this.list, list => list[0]);
}
