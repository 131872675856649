<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Nachricht hinzufügen</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<babylon-text-template-dropdownsingle
					[value]="editForm.controls.textTemplate.value"
					[controlId]="editForm.controls.textTemplate.id"
					placeholder="Optionale Textvorlage"
					class="mat-form-field--span"></babylon-text-template-dropdownsingle>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Titel</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.title" />
				</mat-form-field>
				<div class="editor">
					<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.text"></ckeditor>
				</div>
				<babylon-attachment-autocompletemultiple
					[values]="editForm.controls.attachments.value"
					[controlId]="editForm.controls.attachments.id"
					[optional]="true"
					[allowSelect]="false"
					placeholder="Anhänge auswählen"
					class="mat-form-field--span"></babylon-attachment-autocompletemultiple>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button (click)="createAttachment()">Anhang hinzufügen</button>
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
