import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleFolder } from '../../models/article.model';
import { IArticleFolderState } from '../../state/app.state';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleFolderEditForm, IArticleFolderFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-folderdialogchange',
	templateUrl: './entitydialogchange-folder.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class FolderDialogChangeComponent extends EntityDialogChangeComponent<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	constructor(entityFacade: ArticleFolderFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
