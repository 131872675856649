import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { progressFade } from 'src/animations/progressfade.animation';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { IStockMedia } from '../../models/stock.model';
import { StockMediaDialogService } from '../../services/dialog/stock.media.service';
import { IStockMediaState } from '../../state/app.state';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-mediastockpage',
	templateUrl: './entitypage-media-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class MediaStockPageComponent extends EntityPageComponent<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	public pageTitle$ = new BehaviorSubject('Medien-Bestände');
	public columns = ['product', 'storagePlace', 'quality', 'from', 'until', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: StockMediaDialogService,
		route: ActivatedRoute,
		public entityFacade: MediaStockFacade,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processFacade: ProcessFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		private routerFacade: RouterFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);

		combineLatest([this.routerFacade.stockStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([stockStates, processNodes]) => processNodes.filter(processNode => stockStates.some(stockState => stockState.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					this.entityFacade.changeSorting({ createdAt: -1 });

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}
}
