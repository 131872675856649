import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../../models/direct-debit-transfer.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { DirectDebitTransferApiService } from '../../services/api/direct-debit-transfer.service';
import { DirectDebitTransferDialogService } from '../../services/dialog/direct-debit-transfer.service';
import { DirectDebitTransferNotificationService } from '../../services/notification/direct-debit-transfer.service';
import { AppState, IDirectDebitTransferState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDirectDebitTransferActions } from './direct-debit-transfer.actions';
import { DirectDebitTransferSelector } from './direct-debit-transfer.selectors';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferEffects extends EntityEffects<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: DirectDebitTransferApiService,
		notificationService: DirectDebitTransferNotificationService,
		dialogService: DirectDebitTransferDialogService,
		selector: DirectDebitTransferSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDirectDebitTransferActions, 'DIRECTDEBITTRANSFER');
	}

	public onDownloadCsv$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDirectDebitTransferActions.downloadCsv),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity }, authToken]) =>
				this.apiService.downloadCsv(authToken, entity).pipe(
					map(data => fromDirectDebitTransferActions.downloadedCsv({ entity, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedCsv$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromDirectDebitTransferActions.downloadedCsv),
				tap(({ entity, data }) => {
					const dateFrom = _.orderBy(entity.positions, ['date'], ['desc'])[0].date;
					const dateTo = _.orderBy(entity.positions, ['date'], ['asc'])[0].date;

					saveAs(data, `CSV_${moment(dateFrom).format('YYYY-MM-DD') + '' + (dateFrom != dateTo ? 'bis' + moment(dateTo).format('YYYY-MM-DD') : '')}_${entity.positions.reduce((acc, o) => acc + o.amount, 0).toLocaleString()}.csv`);
				})
			),
		{ dispatch: false }
	);

	public onDownloadXml$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDirectDebitTransferActions.downloadXml),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity }, authToken]) =>
				this.apiService.downloadXml(authToken, entity).pipe(
					map(data => fromDirectDebitTransferActions.downloadedXml({ entity, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedXml$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromDirectDebitTransferActions.downloadedXml),
				tap(({ entity, data }) => {
					const dateFrom = _.orderBy(entity.positions, ['date'], ['desc'])[0].date;
					const dateTo = _.orderBy(entity.positions, ['date'], ['asc'])[0].date;

					saveAs(data, `XML_${moment(dateFrom).format('YYYY-MM-DD') + '' + (dateFrom != dateTo ? 'bis' + moment(dateTo).format('YYYY-MM-DD') : '')}_${entity.positions.reduce((acc, o) => acc + o.amount, 0).toLocaleString()}.xml`);
				})
			),
		{ dispatch: false }
	);

	public onFindByPayment$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDirectDebitTransferActions.filterByPaymentIds),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ paymentIds }, authToken]) =>
				this.apiService.filterByPaymentIds(paymentIds, authToken).pipe(
					map(result => fromDirectDebitTransferActions.filteredByPaymentId({ entities: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onFoundByReceipt$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDirectDebitTransferActions.filteredByPaymentId),
			map(({ entities }) => this.entityActions.fetched({ entities }))
		)
	);
}
