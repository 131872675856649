import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IAccountingSummary, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { IServiceError } from '../../services/api/api.service';
import { EntityNotificationErrorComponent } from './entitynotificationerror.component';

@Component({
	selector: 'babylon-accounting-summarynotificationerror',
	templateUrl: './entitynotificationerror-accounting-summary.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitynotificationerror.component.scss'],
})
export class AccountingSummaryNotificationErrorComponent extends EntityNotificationErrorComponent<IAccountingSummary<IAccountingSummaryPosition>> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
