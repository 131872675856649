<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Position hinzufügen</h1>
<mat-dialog-content>
	<form [formGroup]="formGroup">
		<div class="form-grid">
			<ng-container formGroupName="position">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-select formControlName="positionKind" placeholder="Positionstyp">
						<mat-option [value]="PositionKinds.Article">{{ PositionKinds.Article }}</mat-option>
						<mat-option [value]="PositionKinds.Bundle">{{ PositionKinds.Bundle }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					*ngIf="formGroup.value.position.positionKind == PositionKinds.Discount || formGroup.value.position.positionKind == PositionKinds.Payment || formGroup.value.position.positionKind == PositionKinds.Shipping">
					<mat-label>Titel</mat-label>
					<input type="text" matInput formControlName="title" />
				</mat-form-field>
				<mat-form-field
					appearance="outline"
					*ngIf="formGroup.value.position.positionKind == PositionKinds.Discount || formGroup.value.position.positionKind == PositionKinds.Payment || formGroup.value.position.positionKind == PositionKinds.Shipping">
					<mat-label>Preis</mat-label>
					<input type="number" matInput formControlName="price" />
					<span matSuffix>€</span>
				</mat-form-field>
			</ng-container>
			<mat-form-field appearance="outline" *ngIf="formGroup.value.position.positionKind == PositionKinds.Article" class="mat-form-field--span">
				<mat-select formControlName="articleKind" placeholder="Artikeltyp">
					<mat-option *ngFor="let articleKind of ArticleKinds | keyvalue" [value]="articleKind.value">{{ articleKind.value }}</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field appearance="outline" *ngIf="formGroup.value.position.positionKind == PositionKinds.Bundle" class="mat-form-field--span">
				<mat-select formControlName="bundle" placeholder="Pakettyp">
					<mat-option *ngFor="let bundle of bundleFacade.list$ | async" [value]="bundle">{{ bundle.name }}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Auswählen</button>
</mat-dialog-actions>
