import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DhlPremiumDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-dhl-premium.component';
import { DhlPremiumDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-dhl-premium.component';
import { DhlPremiumDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-dhl-premium.component';
import { IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from '../../models/shipping.model';
import { IShippingDhlPremiumState } from '../../state/app.state';
import { DhlPremiumFacade } from '../../state/shipping.dhl-premium/shipping.dhl-premium.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingDhlPremiumDialogService extends EntityDialogService<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	protected createComponent: ComponentType<any> = DhlPremiumDialogAddComponent;
	protected updateComponent: ComponentType<any> = DhlPremiumDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DhlPremiumDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DhlPremiumFacade) {
		super(matDialog, entityFacade);
	}
}
