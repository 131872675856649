<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Dokument ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-label>Titel</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.title" />
				</mat-form-field>
				<mat-form-field appearance="outline" class="file">
					<mat-label>Datei</mat-label>
					<ngx-mat-file-input matInput (change)="onDocumentChanged($event)"></ngx-mat-file-input>
					<mat-icon svgIcon="file" matSuffix></mat-icon>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions>
		<babylon-process-buttons [facade]="entityFacade"></babylon-process-buttons>
		<span fxFlex></span>
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" *ngIf="entityFacade.editFormValue$ | async as editFormValue" (click)="updateDocument(editFormValue)" [disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
