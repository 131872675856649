import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingDhlPremium } from '../../models/shipping.model';
import { IShippingDhlPremiumState } from '../../state/app.state';
import { DhlPremiumFacade } from '../../state/shipping.dhl-premium/shipping.dhl-premium.facade';
import { IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from './../../models/shipping.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-dhl-premiumdialogdelete',
	templateUrl: './entitydialogdelete-dhl-premium.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class DhlPremiumDialogDeleteComponent extends EntityDialogDeleteComponent<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	constructor(entityFacade: DhlPremiumFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
