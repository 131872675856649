import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProcessRoute } from 'src/models/process-route.model';
import { IProcessRouteState } from 'src/state/app.state';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-processroutedialogchange',
	templateUrl: './entitydialogchange-process-route.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ProcessRouteDialogChangeComponent extends EntityDialogChangeComponent<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	constructor(entityFacade: ProcessRouteFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
