import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, concatLatestFrom, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { filter, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment';
import { AppState } from '../app.state';
import { fromSessionActions } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { MenuFacade } from './menu.facade';
import { MenuSelector } from './menu.selectors';

@Injectable({
	providedIn: 'root',
})
export class MenuEffects {
	constructor(protected actions$: Actions, protected store: Store<AppState>, private sessionSelector: SessionSelector, private menuSelector: MenuSelector, private menuFacade: MenuFacade, private snackBar: MatSnackBar) {}

	public onInit$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(ROOT_EFFECTS_INIT, fromSessionActions.loggedIn),
				filter(() => !environment.production),
				concatLatestFrom(() => this.store.select(this.sessionSelector.isLoggedIn)),
				filter(([, isLoggedIn]) => isLoggedIn),
				concatLatestFrom(() => this.store.select(this.menuSelector.testingAcknowledgedAt)),
				filter(([, testingAcknowledgedAt]) => testingAcknowledgedAt == null || moment(testingAcknowledgedAt).isBefore(moment().subtract(1, 'day'))),
				switchMap(() =>
					this.snackBar
						.open(`Du befindest dich auf der Testumgebung`, 'Okay', {
							horizontalPosition: 'center',
							verticalPosition: 'bottom',
						})
						.afterDismissed()
						.pipe(tap(() => this.menuFacade.acknowledgeTesting()))
				)
			),
		{ dispatch: false }
	);
}
