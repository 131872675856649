import _ from 'lodash';
import { box, isBoxed, unbox } from 'ngrx-forms';

export function constructFormGroupValue<TOutput>(item: any, initial: any): TOutput {
	if (item == null) {
		return initial;
	}

	if (typeof item !== 'object') {
		return item;
	}

	const result: any = {};

	for (const key in initial) {
		const initialValue = initial[key];
		const itemValue = item[key];

		if (initialValue === undefined) {
			result[key] = undefined;
		} else if (itemValue == null && initialValue != null) {
			result[key] = initialValue;
		} else if (isBoxed(initialValue)) {
			result[key] = isBoxed(itemValue) ? itemValue : box(itemValue);
		} else if (_.isArray(initialValue) || (_.isArray(itemValue) && initialValue != null)) {
			const itemArray = [];

			for (let i = 0; i < itemValue.length; i++) {
				itemArray.push(constructFormGroupValue(itemValue[i], initialValue[0]));
			}

			result[key] = itemArray;
		} else {
			result[key] = constructFormGroupValue(itemValue, initialValue);
		}
	}

	return result;
}

export function destructFormGroupValue<TOutput>(item: any): TOutput {
	if (item == null) {
		return null;
	}

	if (isBoxed(item)) {
		item = unbox(item);
	}

	if (typeof item !== 'object') {
		return item;
	}

	const result: any = {};

	for (const key in item) {
		let value = item[key];

		if (isBoxed(value)) {
			value = unbox(value);
		}

		if (_.isArray(value)) {
			result[key] = value;
		} else {
			result[key] = destructFormGroupValue(value);
		}
	}

	return result;
}
