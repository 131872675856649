import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPublicationCycleState } from '../../state/app.state';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-publication-cycledialogdelete',
	templateUrl: './entitydialogdelete-publication-cycle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class PublicationCycleDialogDeleteComponent extends EntityDialogDeleteComponent<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	constructor(entityFacade: PublicationCycleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
