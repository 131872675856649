import { createAction, props } from '@ngrx/store';
import { IPosition } from '../../models/position.model';
import { IServiceError } from '../../services/api/api.service';

const stateName = '[Cart]';
const failed = createAction(`${stateName} Failed`, props<{ error: IServiceError }>());
const validatePositions = createAction(`${stateName} Validate Positions`, props<{ positions: IPosition[] }>());
const validatedPositions = createAction(`${stateName} Validated Positions`, props<{ positions: IPosition[] }>());

export const fromCartActions = {
	failed,
	validatePositions,
	validatedPositions,
};
