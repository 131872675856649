import { Directive } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { IEntity } from 'src/models/entity.model';
import { IServiceError } from '../../services/api/api.service';
import { NotificationComponent } from '../notification/notification.component';

@Directive()
export abstract class EntityNotificationErrorComponent<TEntity extends IEntity> extends NotificationComponent<IServiceError> {
	constructor(matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}

	public get message(): string {
		return this.matSnackBarData.message || 'Es ist ein unbekannter Fehler aufgetreten';
	}
}
