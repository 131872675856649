import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { IShippingReturnState } from '../../state/app.state';
import { ReturnFacade } from '../../state/shipping.return/shipping.return.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-returndialogadd',
	templateUrl: './entitydialogadd-return.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ReturnDialogAddComponent extends EntityDialogAddComponent<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	constructor(entityFacade: ReturnFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
