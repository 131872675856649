import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { EntityState, IPartnerState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromPartnerActions } from './partner.actions';

export const initialValue: IPartner = {
	_id: null,
	name: null,
	email: null,
	telephone: null,
	fax: null,
	sAccount: null,
	hAccount: null,
	receiver: {
		salutationKind: null,
		addressKind: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
			addressAddition: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		iban: null,
		accountOwner: null,
	},
	articlePrices: [
		{
			articleKind: null,
			price: null,
		},
	],
	bundlePrices: [
		{
			bundle: null,
			price: null,
		},
	],
	paymentKind: null,
	deliveryNoteHtmlTemplate: null,
	user: null,
};

export const initialEditFormValue: IPartnerEditForm = {
	_id: null,
	name: null,
	email: null,
	telephone: null,
	fax: null,
	sAccount: null,
	hAccount: null,
	receiver: {
		salutationKind: null,
		addressKind: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
			addressAddition: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		iban: null,
		accountOwner: null,
	},
	articlePrices: [
		{
			articleKind: null,
			price: null,
		},
	],
	bundlePrices: [
		{
			bundle: null,
			price: null,
		},
	],
	paymentKind: null,
	deliveryNoteHtmlTemplate: null,
	user: null,
};

export const initialFilterFormValue: IPartnerFilterForm = {
	_id: null,
	name: null,
	email: null,
	telephone: null,
	fax: null,
	sAccount: null,
	hAccount: null,
	receiver: {
		salutationKind: null,
		addressKind: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
			addressAddition: null,
		},
		firstName: null,
		lastName: null,
		company: null,
		iban: null,
		accountOwner: null,
	},
	articlePrices: [
		{
			articleKind: null,
			price: null,
		},
	],
	bundlePrices: [
		{
			bundle: null,
			price: null,
		},
	],
	paymentKind: null,
	deliveryNoteHtmlTemplate: null,
	user: null,
};

export const initialState = EntityState.create<IPartner, IPartnerEditForm, IPartnerFilterForm>('PARTNER', initialValue, initialEditFormValue, initialFilterFormValue);
export const partnerReducer = EntityReducer.create<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm>(initialState, {}, fromPartnerActions);
