import { Injectable } from '@angular/core';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { AppState, IDeliveryNoteOrderState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteOrderSelector extends EntitySelector<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	protected stateSelector = (state: AppState): IDeliveryNoteOrderState => state.deliveryNoteOrder;
}
