import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { YearBookAddDialogComponent } from '../../components/entitydialog/entitydialog-yearbook-add.component';
import { YearBookReplaceDialogComponent } from '../../components/entitydialog/entitydialog-yearbook-replace.component';
import { YearBookDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-yearbook.component';
import { YearBookDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-yearbook.component';
import { IArticleYearBook, IArticleYearBookEditForm, IArticleYearBookFilterForm } from '../../models/article.model';
import { IArticleYearBookState } from '../../state/app.state';
import { ArticleYearBookFacade } from '../../state/article.yearbook/article.yearbook.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleYearArticleBookDialogService extends EntityDialogService<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	protected createComponent: ComponentType<any> = YearBookDialogAddComponent;
	protected updateComponent: ComponentType<any> = YearBookDialogChangeComponent;
	protected removeComponent: ComponentType<any> = YearBookReplaceDialogComponent;
	protected replaceComponent: ComponentType<any> = YearBookReplaceDialogComponent;
	protected addComponent: ComponentType<any> = YearBookAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleYearBookFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleYearBook>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleYearBook>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
