import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaBrandDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-media-brand.component';
import { MediaBrandDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-media-brand.component';
import { MediaBrandDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-media-brand.component';
import { IMediaBrandState } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandDialogService extends EntityDialogService<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	protected createComponent: ComponentType<any> = MediaBrandDialogAddComponent;
	protected updateComponent: ComponentType<any> = MediaBrandDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MediaBrandDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: MediaBrandFacade) {
		super(matDialog, entityFacade);
	}
}
