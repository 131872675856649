import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IReceiptState } from '../../state/app.state';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { IReceipt, IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-receiptdialogchange',
	templateUrl: './entitydialogchange-receipt.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ReceiptDialogChangeComponent extends EntityDialogChangeComponent<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	constructor(entityFacade: ReceiptFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
