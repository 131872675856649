import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IProduct } from '../../models/product.model';
import { AppState, IProductState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { fromProductActions } from './product.actions';
import { initialEditFormValue } from './product.reducer';
import { ProductSelector } from './product.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProductFacade extends EntityFacade<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromProductActions;

	constructor(store: Store<AppState>, ProductSelector: ProductSelector) {
		super(store, ProductSelector);
	}
}
