import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PartnerNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-partner.component';
import { IPartner } from '../../models/partner.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PartnerNotificationService extends EntityNotificationService<IPartner> {
	protected component: ComponentType<any> = PartnerNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
