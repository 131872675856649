import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { TextTemplateNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-text-template.component';
import { ITextTemplate } from '../../models/text-template.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class TextTemplateNotificationService extends EntityNotificationService<ITextTemplate> {
	protected component: ComponentType<any> = TextTemplateNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
