import { Injectable } from '@angular/core';
import { IProcessNode } from '../../models/process-node.model';
import { AppState, IProcessNodeState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';

@Injectable({
	providedIn: 'root',
})
export class ProcessNodeSelector extends EntitySelector<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	protected stateSelector = (state: AppState): IProcessNodeState => state.processNode;
}
