import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm, IExpandedArticleBirthdayChronicle } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleBirthdayChronicleActions = {
	...EntityActions.create<IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm>('[BirthdayChronicle]'),
	search: createAction(`[BirthdayChronicle] Search`, props<{ date: Date }>()),
	searched: createAction(`[BirthdayChronicle] Searched`, props<{ date: Date; entities: IExpandedArticleBirthdayChronicle[] }>()),
	extended: createAction(`[BirthdayChronicle] Extended`),
	pagingChanged: createAction(`[BirthdayChronicle] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[BirthdayChronicle] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
