import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPaymentCollectionPurchase } from '../../models/payment.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentCollectionPurchaseApiService extends EntityApiService<IPaymentCollectionPurchase> {
	protected entityName = 'collection-purchases';

	constructor(http: HttpClient) {
		super(http);
	}
}
