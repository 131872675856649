import { Directive, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuFacade } from 'src/state/menu/menu.facade';
import { IEntity, IEntityEditForm, IEntityFilterForm } from '../../models/entity.model';
import { IEntityState } from '../../state/app.state';
import { EntityFacade } from '../../state/entity.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { SessionFacade } from '../../state/session/session.facade';
import { UserFacade } from '../../state/user/user.facade';
import { PageComponent } from '../page.component';

@Directive()
export abstract class EntityPageChangeComponent<TEntity extends IEntity, TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm>
	extends PageComponent
	implements OnDestroy
{
	constructor(
		titleService: Title,
		public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>,
		public menuFacade: MenuFacade,
		public routerFacade: RouterFacade,
		public sessionFacade: SessionFacade,
		public userFacade: UserFacade
	) {
		super(titleService);
	}

	public ngOnDestroy(): void {
		this.entityFacade.abort();
	}
}
