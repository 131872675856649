<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<table mat-table [dataSource]="dataSource$ | async">
		<ng-container matColumnDef="partner">
			<th mat-header-cell *matHeaderCellDef>
				<div>Partner</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let item">
				<ng-container *ngIf="partnerFacade.fetchOne(item.partner) | async as partner">
					{{ partner.name }}
				</ng-container>
			</td>
		</ng-container>
		<ng-container matColumnDef="price">
			<th mat-header-cell *matHeaderCellDef>
				<div>Preis (Brutto)</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let item">
				<ng-container *ngIf="item.price != null">{{ item.price | currency }}</ng-container>
				<ng-container *ngIf="item.price == null">Standard Partnerpreis</ng-container>
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td width="100px" mat-cell *matCellDef="let item; let i = index">
				<button mat-icon-button (click)="remove(i)" matTooltip="Entfernen">
					<mat-icon svgIcon="trash-can-outline"></mat-icon>
				</button>
				<button mat-icon-button (click)="openEditDialog(item, i)" matTooltip="Bearbeiten">
					<mat-icon svgIcon="pencil"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let row; columns: columns"></tr>
	</table>
</ng-container>
<br /><button mat-button color="primary" (click)="add({ partner: null, price: null })">Partnerfreigabe hinzufügen</button>
