import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IConfiguration } from 'src/models/configuration.model';
import { IConfigurationState } from 'src/state/app.state';
import { ConfigurationFacade } from 'src/state/configuration/configuration.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-configurationdialogchange',
	templateUrl: './entitydialogchange-configuration.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ConfigurationDialogChangeComponent extends EntityDialogChangeComponent<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	constructor(entityFacade: ConfigurationFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
