import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { IArticlePrice } from '../../models/partner.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-article-pricedialog',
	templateUrl: './dialog-article-price.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ArticlePriceDialogComponent extends DialogComponent<ArticlePriceDialogComponent, IArticlePrice> {
	public ArticleKinds = ArticleKind;

	constructor(matDialogRef: MatDialogRef<ArticlePriceDialogComponent, IArticlePrice>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IArticlePrice) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				articleKind: [data.articleKind, [Validators.required]],
				price: [data.price, [Validators.required]],
			})
		);
	}
}
