import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageFacade } from '../../state/image/image.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { UserFacade } from '../../state/user/user.facade';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'babylon-profile-picture',
	templateUrl: './profile-picture.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent extends BaseComponent {
	@Input() public userId: string;
	@Input() public showName: boolean = true;

	constructor(public userFacade: UserFacade, public imageFacade: ImageFacade, public uploadedFileFacade: UploadedFileFacade) {
		super();
	}
}
