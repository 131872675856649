import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPage, IPageBlock, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { IPageState } from '../../state/app.state';
import { PageFacade } from '../../state/page/page.facade';
import { PageBlockDialogComponent } from '../dialog/dialog-pageblock.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-pageblockstable',
	templateUrl: './entitydialogtable-pageblocks.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class PageBlocksTableComponent extends EntityDialogTableComponent<IPage, IPageState, IPageBlock, IPageBlock, IPageEditForm, IPageFilterForm> {
	public columns = ['blockKind', 'preview', 'edit'];

	constructor(entityFacade: PageFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IPage): IPageBlock[] {
		return entity.blocks;
	}

	protected createElement(): IPageBlock {
		return {
			blockKind: null,
			parameters: [],
			queryParameters: [],
		};
	}

	public openEditDialog(data: IPageBlock, index: number): void {
		const dialogRef = this.matDialog.open(PageBlockDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IPageBlock) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
