import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, first } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleChronicleState } from 'src/state/app.state';
import { IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm, IExpandedArticle, IExpandedArticleChronicle } from '../../models/article.model';
import { IRegion } from '../../models/region.model';
import { ArticleChronicleDialogService } from '../../services/dialog/article.chronicle.service';
import { IReplaceArticleDialogData } from '../../services/dialog/article.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleChronicleFacade } from '../../state/article.chronicle/article.chronicle.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-chroniclereplacedialog',
	templateUrl: './entitydialog-chronicle-replace.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class ChronicleReplaceDialogComponent extends EntityDialogComponent<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	public formGroup: FormGroup;
	public columns = ['publicationDate', 'product', 'region', 'storagePlace', 'replace'];

	public displayRegionWith = (region: IRegion) => region.name;

	constructor(
		public chronicleFacade: ArticleChronicleFacade,
		formBuilder: FormBuilder,
		matDialogRef: MatDialogRef<ChronicleReplaceDialogComponent, IEntityDialogData<IArticleChronicle>>,
		@Inject(MAT_DIALOG_DATA) data: IReplaceArticleDialogData<IExpandedArticleChronicle>,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		public orderFacade: OrderFacade,
		public regionFacade: RegionFacade,
		public chronicleDialogService: ArticleChronicleDialogService,
		public cartFacade: CartFacade
	) {
		super(chronicleFacade, matDialogRef);

		this.chronicleFacade.search(new Date(data.entity.publicationDate));
		this.formGroup = formBuilder.group({
			publicationDate: [data.entity.publicationDate, [Validators.required]],
		});

		this.position = data.position;
		this.originalArticle = data.entity;
	}

	public search(): void {
		this.chronicleFacade.search(this.formGroup.value.publicationDate);
	}

	public replaceChronicle(article: IExpandedArticle): void {
		const updatedPosition = this.replaceArticlePosition(article);
		this.cartFacade.validatePositions(updatedPosition);
		this.matDialogRef.close();
	}

	public onBlurRegion(value: string): void {
		this.regionFacade.selected$
			.pipe(
				first(),
				filter(region => region == null && value != null && value.length > 0)
			)
			.subscribe(() => {
				this.regionFacade.changeName('');
			});
	}
}
