import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IServiceError } from '../../services/api/api.service';
import { NotificationComponent } from '../notification/notification.component';

@Component({
	selector: 'babylon-authorizationerror',
	templateUrl: './authorizationerror.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./authorizationerror.component.scss'],
})
export class AuthorizationErrorComponent extends NotificationComponent<IServiceError> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
