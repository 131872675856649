<mat-sidenav-container autosize>
	<mat-sidenav #sidenav fixedInViewport="true" mode="side" [opened]="menuFacade.isMenuExpanded$ | async">
		<side-nav *ngIf="sessionFacade.isLoggedIn$ | async" (toggleMenu)="menuFacade.toggleIsMenuExpanded()" [isExpanded]="menuFacade.isMenuExpanded$ | async"></side-nav>
	</mat-sidenav>
	<mat-sidenav-content [style.margin-left.px]="menuFacade.menuMargin$ | async">
		<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="sessionFacade.isFiltering$ | async"></mat-progress-bar>
		<main role="main">
			<router-outlet></router-outlet>
			<div *ngIf="currentDate$ | async as currentDate" class="datewrapper">
				<div class="date">{{ currentDate | amDateFormat: 'dd, DD.MM.YYYY' }}</div>
				<div class="time">{{ currentDate | amDateFormat: 'HH:mm' }} Uhr</div>
			</div>
		</main>
	</mat-sidenav-content>
</mat-sidenav-container>
