<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="(yearBookFacade.isSearching$ | async) || (yearBookFacade.isExtending$ | async)"></mat-progress-bar>
<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Geschenkbuch auswählen</h1>
<mat-dialog-content>
	<form [formGroup]="formGroup">
		<div class="form-grid">
			<mat-form-field appearance="outline">
				<mat-label>Publizierungsdatum</mat-label>
				<input matInput #dateInput [matDatepicker]="publicationDate" formControlName="publicationDate" (keyup.enter)="search()" (dateChange)="search()" />
				<mat-datepicker-toggle matSuffix [for]="publicationDate"></mat-datepicker-toggle>
				<mat-datepicker #publicationDate></mat-datepicker>
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-spinner [diameter]="24" matPrefix *ngIf="regionFacade.isSearching$ | async"></mat-spinner>
				<mat-icon svgIcon="map-marker" matPrefix *ngIf="!(regionFacade.isSearching$ | async)"></mat-icon>
				<mat-label>PLZ, Ort oder Region</mat-label>
				<input
					matInput
					[matAutocomplete]="regionAutocomplete"
					[value]="regionFacade.selectedAddress$ | async"
					placeholder="PLZ, Ort oder Region"
					#region
					(keyup.enter)="search()"
					(keyup)="regionFacade.changeName(region.value)"
					(blur)="onBlurRegion(region.value)"
					autocomplete="off" />
				<mat-autocomplete autoActiveFirstOption #regionAutocomplete="matAutocomplete" [displayWith]="displayRegionWith" (optionSelected)="regionFacade.select($event.option.value)">
					<ng-container *ngFor="let region of regionFacade.listSearched$ | async; let i = index">
						<mat-option [value]="region">
							{{ region.address }} <small class="mat-small">{{ region.regionKind }}</small>
						</mat-option>
					</ng-container>
				</mat-autocomplete>
				<button matSuffix mat-icon-button aria-label="Leeren" (click)="regionFacade.select(null)" *ngIf="regionFacade.selected$ | async">
					<mat-icon svgIcon="close"></mat-icon>
				</button>
			</mat-form-field>

			<ng-container *ngIf="yearBookFacade.itemsSearched$ | async as itemsSearched">
				<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
					<ng-container matColumnDef="publicationDate">
						<th mat-header-cell *matHeaderCellDef>Publizierungsdatum</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity ? (entity.publicationDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)') : '' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="quality">
						<th mat-header-cell *matHeaderCellDef>Qualität</th>
						<td mat-cell *matCellDef="let entity">{{ entity?.quality }}</td>
					</ng-container>

					<ng-container matColumnDef="storagePlace">
						<th mat-header-cell *matHeaderCellDef>Lagerplatz</th>
						<td mat-cell *matCellDef="let entity">
							{{ (storagePlaceFacade.fetchOne(entity?.storagePlace) | async)?.identifier }}
							<babylon-fetch [facade]="storagePlaceFacade" [value]="entity?.storagePlace"></babylon-fetch>
						</td>
					</ng-container>

					<ng-container matColumnDef="product">
						<th mat-header-cell *matHeaderCellDef>Produkt</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity?.product?.name }}
						</td>
					</ng-container>

					<ng-container matColumnDef="region">
						<th mat-header-cell *matHeaderCellDef>Region</th>
						<td mat-cell *matCellDef="let entity">{{ entity.product.productionRegion.name }}, {{ entity.product.graphicalRegion.shortName }}</td>
					</ng-container>

					<ng-container matColumnDef="replace">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let entity">
							<button type="button" mat-icon-button matTooltip="Austauschen" (click)="addYearBook(entity)">
								<mat-icon svgIcon="check"></mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: columns"></tr>
				</table>
			</ng-container>
		</div>
	</form>
	<div *ngIf="(yearBookFacade.itemsSearched$ | async).length > 0 && (yearBookFacade.isExtending$ | async)" class="more-results more-results__spinner"><mat-spinner [diameter]="25"></mat-spinner> Weitere Jahrbücher werden gesucht...</div>
	<div *ngIf="!(yearBookFacade.isExtending$ | async)" class="more-results">
		<a (click)="yearBookFacade.extend(formGroup.value.publicationDate)">Weitere Jahrbücher finden</a>
	</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="cancel()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="search()" class="search-button">Suchen</button>
</mat-dialog-actions>
