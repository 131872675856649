import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, first } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleDvdState } from 'src/state/app.state';
import { IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm, IExpandedArticle } from '../../models/article.model';
import { IRegion } from '../../models/region.model';
import { ArticleDvdDialogService } from '../../services/dialog/article.dvd.service';
import { IAddArticleDialogData } from '../../services/dialog/article.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleDvdFacade } from '../../state/article.dvd/article.dvd.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-dvdadddialog',
	templateUrl: './entitydialog-dvd-add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class DvdAddDialogComponent extends EntityDialogComponent<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	public formGroup: FormGroup;
	public columns = ['publicationDate', 'product', 'region', 'storagePlace', 'add'];
	public displayRegionWith = (region: IRegion) => region.name;

	constructor(
		public dvdFacade: ArticleDvdFacade,
		formBuilder: FormBuilder,
		matDialogRef: MatDialogRef<DvdAddDialogComponent, IEntityDialogData<IArticleDvd>>,
		@Inject(MAT_DIALOG_DATA) data: IAddArticleDialogData,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		public orderFacade: OrderFacade,
		public regionFacade: RegionFacade,
		public dvdDialogService: ArticleDvdDialogService,
		public cartFacade: CartFacade
	) {
		super(dvdFacade, matDialogRef);

		this.formGroup = formBuilder.group({
			publicationDate: [data.date, [Validators.required]],
		});
		this.position = data.position;
	}

	public search(): void {
		this.dvdFacade.search(this.formGroup.value.publicationDate);
	}

	public addDvd(article: IExpandedArticle): void {
		const position = this.addArticlePosition(article);
		this.cartFacade.validatePositions(position);
		this.matDialogRef.close();
	}

	public onBlurRegion(value: string): void {
		this.regionFacade.selected$
			.pipe(
				first(),
				filter(region => region == null && value != null && value.length > 0)
			)
			.subscribe(() => {
				this.regionFacade.changeName('');
			});
	}

	public cancel(): void {
		this.orderFacade.removePosition(this.position);
		this.matDialogRef.close();
	}
}
