import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
	name: 'strip',
})
export class StripPipe implements PipeTransform {
	constructor(protected sanitizer: DomSanitizer) {}

	public transform(value: string): string {
		if (value) {
			return value.replace(/<\/?[^>]+(>|$)/g, '');
		}
		return '';
	}
}
