import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MarkAsTouchedAction, ResetAction, SetValueAction } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IUser, IUserFilterForm } from 'src/models/user.model';
import { UserApiService } from 'src/services/api/user.service';
import { UserDialogService } from 'src/services/dialog/user.service';
import { UserNotificationService } from 'src/services/notification/user.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { constructFormGroupValue } from '../../types/form';
import { AppState, IUserState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromSessionActions } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { IUserEditForm } from './../../models/user.model';
import { fromUserActions } from './user.actions';
import { UserSelector } from './user.selectors';

@Injectable({
	providedIn: 'root',
})
export class UserEffects extends EntityEffects<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: UserApiService, notificationService: UserNotificationService, dialogService: UserDialogService, selector: UserSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromUserActions, 'USER');
	}

	public onLoggedIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.loggedIn),
			map(() => fromUserActions.filter())
		)
	);

	public onCreateForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.createForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.selected)),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([[, entity], initialEditFormValue]) => constructFormGroupValue({ ...entity, password: null }, initialEditFormValue)),
			switchMap(entity => [new ResetAction(this.EDIT_FORM), new MarkAsTouchedAction(this.EDIT_FORM), new SetValueAction(this.EDIT_FORM, entity)])
		)
	);

	public onCreatedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.createdForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			map(([[, entity], authToken]) => {
				const updatedEntity: IUser = { ...entity };

				if (updatedEntity.password == null) {
					delete updatedEntity.password;
				}

				return [[, updatedEntity], authToken];
			}),
			switchMap(([[, entity], authToken]) =>
				this.entityService.create(entity as IUser, authToken as string).pipe(
					map(result => this.entityActions.created({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onCreated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.created),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue({ ...entity, password: null }, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new SetValueAction(this.EDIT_FORM, formValue)])
		)
	);

	public onUpdateForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updateForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.selected)),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([[, entity], initialEditFormValue]) => constructFormGroupValue<IUser>({ ...entity, password: null }, initialEditFormValue)),
			switchMap(entity => [new ResetAction(this.EDIT_FORM), new MarkAsTouchedAction(this.EDIT_FORM), new SetValueAction(this.EDIT_FORM, entity)])
		)
	);

	public onUpdatedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updatedForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			map(([[, entity], authToken]) => {
				const updatedEntity: IUser = { ...entity };

				if (updatedEntity.password == null) {
					delete updatedEntity.password;
				}

				return [[, updatedEntity], authToken];
			}),
			switchMap(([[, entity], authToken]) =>
				this.entityService.update(entity as IUser, authToken as string).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updated),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue<IUser>({ ...entity, password: null }, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new SetValueAction(this.EDIT_FORM, formValue), this.entityActions.selected({ selected: entity })])
		)
	);
}
