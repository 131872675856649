import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IImage, IImageEditForm } from 'src/models/image.model';
import { FilterConnection, IFilterDescriptor, IImageState, SortDescriptors } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { progressFade } from '../../animations/progressfade.animation';
import { IImageFilterForm } from './../../models/image.model';
import { UploadedFileFacade } from './../../state/uploadedfile/uploadedfile.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-image-autocompletesingle',
	templateUrl: './entityautocompletesingle-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class ImageAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	@Input() placeholder: string = 'Bild';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'title', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IImage> = () => ({ title: 1 });

	constructor(entityFacade: ImageFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IImage): string {
		return entity?.title;
	}
}
