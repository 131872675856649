import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleCoin } from '../../models/article.model';
import { IArticleCoinState } from '../../state/app.state';
import { ArticleCoinFacade } from '../../state/article.coin/article.coin.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleCoinEditForm, IArticleCoinFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-coindialogchange',
	templateUrl: './entitydialogchange-coin.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CoinDialogChangeComponent extends EntityDialogChangeComponent<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	constructor(entityFacade: ArticleCoinFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
