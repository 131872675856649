import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAction } from '../../models/process-instance-model';
import { IReceiptCancellation } from '../../models/receipt';
import { IReceiptCancellationState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { CancellationFacade } from '../../state/receipt.cancellation/receipt.cancellation.facade';
import { IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from './../../models/receipt';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-cancellationhistorytable',
	templateUrl: './entitydialogtable-cancellationhistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class CancellationHistoryTableComponent extends HistoryDialogTableComponent<IReceiptCancellation, IReceiptCancellationState, IAction, IAction, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	public columns = ['date', 'userName', 'route', 'state', 'comment'];

	constructor(entityFacade: CancellationFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IReceiptCancellation): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
