import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-commentdialog',
	templateUrl: './dialog-comment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class CommentDialogComponent extends DialogComponent<CommentDialogComponent, string> {
	constructor(matDialogRef: MatDialogRef<CommentDialogComponent, string>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: string) {
		super(matDialogRef, data, formBuilder.group({ comment: [data, []] }));
	}
}
