import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IStoragePlace } from '../../models/storage-place.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class StoragePlaceApiService extends EntityApiService<IStoragePlace> {
	protected entityName = 'storage-places';

	constructor(http: HttpClient) {
		super(http);
	}
}
