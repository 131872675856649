import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DeliveryNoteReturnNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-delivery-note-return.component';
import { IDeliveryNoteReturn } from '../../models/delivery-note';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnNotificationService extends EntityNotificationService<IDeliveryNoteReturn> {
	protected component: ComponentType<any> = DeliveryNoteReturnNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
