import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IProcess } from '../../models/process';
import { EntityState, IProcessState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { fromProcessActions } from './process.actions';

export const initialValue: IProcess = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialEditFormValue: IProcessEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialFilterFormValue: IProcessFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialState = EntityState.create<IProcess, IProcessEditForm, IProcessFilterForm>('PROCESS', initialValue, initialEditFormValue, initialFilterFormValue);
export const processReducer = EntityReducer.create<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm>(
	initialState,
	{
		name: validate(required),
		displayName: validate(required),
	},
	fromProcessActions
);
