import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm } from '../../models/accounting-summary.model';
import { EntityState, IAccountingSummaryAdvancementState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromAccountingSummaryAdvancementActions } from './accounting-summary.advancement.actions';

export const initialValue: IAccountingSummaryAdvancement = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialEditFormValue: IAccountingSummaryAdvancementEditForm = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [
		{
			orderNumber: null,
			receiptNumber: null,
			amount: null,
			date: null,
			account: null,
			name: null,
			having: null,
			paypalTransactionId: null,
		},
	],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialFilterFormValue: IAccountingSummaryAdvancementFilterForm = {
	_id: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialState = EntityState.create<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm>(
	'ACCOUNTINGSUMMARYADVANCEMENT',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue
);
export const accountingSummaryAdvancementReducer = EntityReducer.create<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementState, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm>(
	initialState,
	{},
	fromAccountingSummaryAdvancementActions,
	on(fromAccountingSummaryAdvancementActions.filterByReceiptNumbers, state => ({
		...state,
		isFetching: true,
	})),
	on(fromAccountingSummaryAdvancementActions.filteredByReceiptNumber, state => ({
		...state,
		isFetching: false,
	}))
);
