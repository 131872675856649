<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Position ändern</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm"></form>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
		<button mat-button color="primary" (click)="matDialogRef.close()">Abbrechen</button>
	</div>
</ng-container>
