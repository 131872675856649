import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { accessoryProductReducer } from './accessory-product/accessory-product.reducer';
import { accountingSummaryAdvancementReducer } from './accounting-summary.advancement/accounting-summary.advancement.reducer';
import { accountingSummaryCustomerReducer } from './accounting-summary.customer/accounting-summary.customer.reducer';
import { accountingSummaryReducer } from './accounting-summary/accounting-summary.reducer';
import { aggregationReducer } from './aggregation/aggregation.reducer';
import { AppState } from './app.state';
import { articleBirthdayBookReducer } from './article.birthday-book/article.birthday-book.reducer';
import { articleBirthdayChronicleReducer } from './article.birthday-chronicle/article.birthday-chronicle.reducer';
import { articleBookReducer } from './article.book/article.book.reducer';
import { articleCdReducer } from './article.cd/article.cd.reducer';
import { articleCertificateReducer } from './article.certificate/article.certificate.reducer';
import { articleChronicleReducer } from './article.chronicle/article.chronicle.reducer';
import { articleCoinReducer } from './article.coin/article.coin.reducer';
import { articleDvdReducer } from './article.dvd/article.dvd.reducer';
import { articleFolderReducer } from './article.folder/article.folder.reducer';
import { articleMagazineReducer } from './article.magazine/article.magazine.reducer';
import { articleNewspaperReducer } from './article.newspaper/article.newspaper.reducer';
import { articleStickReducer } from './article.stick/article.stick.reducer';
import { articleYearBookReducer } from './article.yearbook/article.yearbook.reducer';
import { articleReducer } from './article/article.reducer';
import { attachmentReducer } from './attachment/attachment.reducer';
import { bundleReducer } from './bundle/bundle.reducer';
import { cartReducer } from './cart/cart.reducer';
import { collectionPurchaseTransferReducer } from './collection-purchase-transfer/collection-purchase-transfer.reducer';
import { configurationReducer } from './configuration/configuration.reducer';
import { counterReducer } from './counter/counter.reducer';
import { customerReducer } from './customer/customer.reducer';
import { deliveryNoteOrderReducer } from './delivery-note.order/delivery-note.order.reducer';
import { deliveryNoteReturnReducer } from './delivery-note.return/delivery-note.return.reducer';
import { deliveryNoteReducer } from './delivery-note/delivery-note.reducer';
import { deviceReducer } from './device/device.reducer';
import { directDebitTransferReducer } from './direct-debit-transfer/direct-debit-transfer.reducer';
import { documentReducer } from './document/document.reducer';
import { imageReducer } from './image/image.reducer';
import { mailtemplateReducer } from './mailtemplate/mailtemplate.reducer';
import { mediaBrandReducer } from './media-brand/media-brand.reducer';
import { mediaProductReducer } from './media-product/media-product.reducer';
import { menuReducer } from './menu/menu.reducer';
import { messageReducer } from './message/message.reducer';
import { orderReducer } from './order/order.reducer';
import { pageReducer } from './page/page.reducer';
import { partnerReducer } from './partner/partner.reducer';
import { barzahlenReducer } from './payment.barzahlen/payment.barzahlen.reducer';
import { cashOnDeliveryReducer } from './payment.cash-on-delivery/payment.cash-on-delivery.reducer';
import { cashReducer } from './payment.cash/payment.cash.reducer';
import { collectionPurchaseReducer } from './payment.collection-purchase/payment.collection-purchase.reducer';
import { directDebitReducer } from './payment.direct-debit/payment.direct-debit.reducer';
import { paypalReducer } from './payment.paypal/payment.paypal.reducer';
import { prepaymentReducer } from './payment.prepayment/payment.prepayment.reducer';
import { purchaseOnAccountReducer } from './payment.purchase-on-account/payment.purchase-on-account.reducer';
import { paymentReducer } from './payment/payment.reducer';
import { permissionReducer } from './permission/permission.reducer';
import { predictionReducer } from './prediction/prediction.reducer';
import { processNodeReducer } from './process-node/process-node.reducer';
import { processRouteReducer } from './process-route/process-route.reducer';
import { processReducer } from './process/process.reducer';
import { productReducer } from './product/product.reducer';
import { publicationCycleReducer } from './publication-cycle/publication-cycle.reducer';
import { publisherReducer } from './publisher/publisher.reducer';
import { cancellationReducer } from './receipt.cancellation/receipt.cancellation.reducer';
import { correctionReducer } from './receipt.correction/receipt.correction.reducer';
import { invoiceReducer } from './receipt.invoice/receipt.invoice.reducer';
import { receiptReducer } from './receipt/receipt.reducer';
import { regionReducer } from './region/region.reducer';
import { sessionReducer } from './session/session.reducer';
import { dhlExpressReducer } from './shipping.dhl-express/shipping.dhl-express.reducer';
import { dhlPremiumReducer } from './shipping.dhl-premium/shipping.dhl-premium.reducer';
import { dhlReducer } from './shipping.dhl/shipping.dhl.reducer';
import { pickupReducer } from './shipping.pickup/shipping.pickup.reducer';
import { returnReducer } from './shipping.return/shipping.return.reducer';
import { shippingReducer } from './shipping/shipping.reducer';
import { accessoryStockReducer } from './stock.accessory/stock.accessory.reducer';
import { mediaStockReducer } from './stock.media/stock.media.reducer';
import { stockReducer } from './stock/stock.reducer';
import { storagePlaceReducer } from './storage-place/storage-place.reducer';
import { textTemplateReducer } from './text-template/text-template.reducer';
import { uploadedfileReducer } from './uploadedfile/uploadedfile.reducer';
import { userReducer } from './user/user.reducer';

export const actionReducerMap: ActionReducerMap<AppState> = {
	aggregation: aggregationReducer,
	prediction: predictionReducer,
	accessoryProduct: accessoryProductReducer,
	stockAccessory: accessoryStockReducer,
	article: articleReducer,
	paymentBarzahlen: barzahlenReducer,
	articleBirthdayBook: articleBirthdayBookReducer,
	articleBirthdayChronicle: articleBirthdayChronicleReducer,
	articleBook: articleBookReducer,
	bundle: bundleReducer,
	partner: partnerReducer,
	page: pageReducer,
	receiptCancellation: cancellationReducer,
	cart: cartReducer,
	paymentCash: cashReducer,
	paymentCashOnDelivery: cashOnDeliveryReducer,
	articleCd: articleCdReducer,
	counter: counterReducer,
	articleCertificate: articleCertificateReducer,
	articleChronicle: articleChronicleReducer,
	articleCoin: articleCoinReducer,
	configuration: configurationReducer,
	receiptCorrection: correctionReducer,
	customer: customerReducer,
	deliveryNote: deliveryNoteReducer,
	deliveryNoteOrder: deliveryNoteOrderReducer,
	deliveryNoteReturn: deliveryNoteReturnReducer,
	device: deviceReducer,
	shippingDhl: dhlReducer,
	shippingReturn: returnReducer,
	shippingDhlExpress: dhlExpressReducer,
	shippingDhlPremium: dhlPremiumReducer,
	paymentDirectDebit: directDebitReducer,
	articleDvd: articleDvdReducer,
	articleFolder: articleFolderReducer,
	image: imageReducer,
	document: documentReducer,
	attachment: attachmentReducer,
	receiptInvoice: invoiceReducer,
	articleMagazine: articleMagazineReducer,
	mailTemplate: mailtemplateReducer,
	mediaBrand: mediaBrandReducer,
	mediaProduct: mediaProductReducer,
	mediaStock: mediaStockReducer,
	menu: menuReducer,
	textTemplate: textTemplateReducer,
	articleNewspaper: articleNewspaperReducer,
	directDebitTransfer: directDebitTransferReducer,
	collectionPurchaseTransfer: collectionPurchaseTransferReducer,
	order: orderReducer,
	message: messageReducer,
	payment: paymentReducer,
	paymentPaypal: paypalReducer,
	permission: permissionReducer,
	shippingPickup: pickupReducer,
	paymentPrepayment: prepaymentReducer,
	process: processReducer,
	processNode: processNodeReducer,
	processRoute: processRouteReducer,
	product: productReducer,
	publicationCycle: publicationCycleReducer,
	publisher: publisherReducer,
	paymentPurchaseOnAccount: purchaseOnAccountReducer,
	paymentCollectionPurchase: collectionPurchaseReducer,
	receipt: receiptReducer,
	region: regionReducer,
	router: routerReducer,
	session: sessionReducer,
	shipping: shippingReducer,
	articleStick: articleStickReducer,
	stock: stockReducer,
	storagePlace: storagePlaceReducer,
	uploadedFile: uploadedfileReducer,
	user: userReducer,
	articleYearBook: articleYearBookReducer,
	accountingSummary: accountingSummaryReducer,
	accountingSummaryAdvancement: accountingSummaryAdvancementReducer,
	accountingSummaryCustomer: accountingSummaryCustomerReducer,
};
