import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromStatisticsActions } from './statistics.actions';

@Injectable({
	providedIn: 'root',
})
export class StatisticsFacade extends BaseFacade {
	constructor(store: Store<AppState>) {
		super(store);
	}

	public count(): void {
		this.store.dispatch(fromStatisticsActions.count());
	}
}
