import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MediaBrandNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-media-brand.component';
import { IMediaBrand } from './../../models/media-brand.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandNotificationService extends EntityNotificationService<IMediaBrand> {
	protected component: ComponentType<any> = MediaBrandNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
