import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleCoin } from '../../models/article.model';
import { IArticleCoinState } from '../../state/app.state';
import { ArticleCoinFacade } from '../../state/article.coin/article.coin.facade';
import { IArticleCoinEditForm, IArticleCoinFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-coindialogdelete',
	templateUrl: './entitydialogdelete-coin.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CoinDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	constructor(entityFacade: ArticleCoinFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
