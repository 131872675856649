import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleChronicleApiService } from '../../services/api/article.chronicle.service';
import { ArticleChronicleDialogService } from '../../services/dialog/article.chronicle.service';
import { ArticleChronicleNotificationService } from '../../services/notification/article.chronicle.service';
import { AppState, IArticleChronicleState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleChronicleActions } from './article.chronicle.actions';
import { ArticleChronicleSelector } from './article.chronicle.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleChronicleEffects extends EntityEffects<IArticleChronicle, IArticleChronicleState, IArticleChronicleEditForm, IArticleChronicleFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleChronicleApiService,
		notificationService: ArticleChronicleNotificationService,
		dialogService: ArticleChronicleDialogService,
		private selector: ArticleChronicleSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleChronicleActions, 'CHRONICLE');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleChronicleActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.Chronicle, region }, paging)
					.pipe(
						map(data => fromArticleChronicleActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleChronicleActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleChronicleActions.dayRangeChanged, fromArticleChronicleActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleChronicleActions.extended());
				}

				return this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.Chronicle, region }, paging)
					.pipe(
						map(data => fromArticleChronicleActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleChronicleActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleChronicleActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleChronicleActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleChronicleActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
