import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../../models/direct-debit-transfer.model';

import { Component } from '@angular/core';
import { DirectDebitTransferFacade } from '../../state/direct-debit-transfer/direct-debit-transfer.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';
import { IDirectDebitTransferPosition } from './../../models/direct-debit-transfer.model';
import { IDirectDebitTransferState } from '../../state/app.state';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'babylon-direct-debit-transferdialogchange',
	templateUrl: './entitydialogchange-direct-debit-transfer.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DirectDebitTransferDialogChangeComponent extends EntityDialogChangeComponent<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {
	public positionColumns = ['orderNumber', 'customerName', 'date', 'amount', 'actions'];

	constructor(private directDebitTransfertFacade: DirectDebitTransferFacade, matDialogRef: MatDialogRef<any>) {
		super(directDebitTransfertFacade, matDialogRef);
	}

	public removePosition(position: IDirectDebitTransferPosition): void {
		this.directDebitTransfertFacade.removePosition(position);
	}
}
