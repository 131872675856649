import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, first, map, Observable, tap, withLatestFrom } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { OrderApiService } from 'src/services/api/order.service';
import { PaymentFacade } from 'src/state/payment/payment.facade';
import { ShippingFacade } from 'src/state/shipping/shipping.facade';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { PriorityType } from '../../models/process-instance-model';
import { OrderDialogService } from '../../services/dialog/order.service';
import { IOrderState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { UserFacade } from '../../state/user/user.facade';
import { OrderFacade } from './../../state/order/order.facade';
import { ProcessNodeFacade } from './../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from './../../state/process-route/process-route.facade';
import { ProductFacade } from './../../state/product/product.facade';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-orderpage',
	templateUrl: './entitypage-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class OrderPageComponent extends EntityPageComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	public pageTitle$ = new BehaviorSubject('Aufträge');
	public columns = ['select', 'customer', 'number', 'createdAt', 'totalPrice', 'paymentKind', 'orderState', 'prio', 'filter'];
	public selection = new SelectionModel<IOrder>(true, []);
	public masterSelection$ = new BehaviorSubject<boolean>(false);
	public PriorityTypes = Object.fromEntries(Object.entries(PriorityType).filter(([key]) => isNaN(parseInt(key))));

	protected shouldDoInitialFilter = false;
	protected shouldDoRegularFilter = 60;

	constructor(
		titleService: Title,
		route: ActivatedRoute,
		private orderDialogService: OrderDialogService,
		private routerFacade: RouterFacade,
		public apiService: OrderApiService,
		public entityFacade: OrderFacade,
		public customerFacade: CustomerFacade,
		public partnerFacade: PartnerFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public articleFacade: ArticleFacade,
		public receiptFacade: ReceiptFacade,
		public productFacade: ProductFacade,
		public shippingFacade: ShippingFacade,
		public paymentFacade: PaymentFacade,
		public processFacade: ProcessFacade,
		public userFacade: UserFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade,
		public router: Router
	) {
		super(titleService, orderDialogService, route, entityFacade);

		this.masterSelection$
			.pipe(
				withLatestFrom(this.entityFacade.listFiltered$),
				map(([selectAll, list]) => {
					if (selectAll) {
						this.selection.select(...list);
					} else {
						this.selection.clear();
					}
				})
			)
			.subscribe();

		combineLatest([this.routerFacade.orderStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([orderStates, processNodes]) => processNodes.filter(processNode => orderStates.some(orderState => orderState.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					const processNodeNames = processNodes.map(processNode => processNode.name);

					if (processNodes.length == 0 || processNodeNames.indexOf('OrderCancelled') > -1 || processNodeNames.indexOf('OrderCompleted') > -1) {
						this.entityFacade.changeSorting({ createdAt: -1 });
					} else {
						this.entityFacade.changeSorting({ 'processInstance.priority': -1, 'number': 1 });
					}

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}

	public get isAllSelected$(): Observable<boolean> {
		return this.entityFacade.listFiltered$.pipe(map(list => list.length == this.selection.selected.length));
	}

	public changeAssignee(userId: string): void {
		this.userFacade
			.fetchOne(userId)
			.pipe(first())
			.subscribe(user => {
				for (let article of this.selection.selected) {
					this.entityFacade.updateAssignee(article, user);
				}

				this.selection.clear();
			});
	}

	public changePriority(priority: number): void {
		for (let order of this.selection.selected) {
			this.entityFacade.updatePriority(order, priority);
		}

		this.selection.clear();
	}

	public update(entity: IOrder, width: string = null): void {
		this.router.navigate(['/entity', 'order', 'change', entity._id]);
	}

	public returnZero(): number {
		return 0;
	}
}
