import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleCd } from '../../models/article.model';
import { IArticleCdState } from '../../state/app.state';
import { ArticleCdFacade } from '../../state/article.cd/article.cd.facade';
import { IArticleCdEditForm, IArticleCdFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-cddialogdelete',
	templateUrl: './entitydialogdelete-cd.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CdDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleCd, IArticleCdState, IArticleCdEditForm, IArticleCdFilterForm> {
	constructor(entityFacade: ArticleCdFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
