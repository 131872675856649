import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IReceiptCorrection, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm } from '../../models/receipt';
import { AppState, IReceiptCorrectionState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCorrectionActions } from './receipt.correction.actions';
import { initialEditFormValue } from './receipt.correction.reducer';
import { CorrectionSelector } from './receipt.correction.selectors';

@Injectable({
	providedIn: 'root',
})
export class CorrectionFacade extends EntityFacade<IReceiptCorrection, IReceiptCorrectionState, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCorrectionActions;

	constructor(store: Store<AppState>, correctionSelector: CorrectionSelector) {
		super(store, correctionSelector);
	}
}
