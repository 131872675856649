import { on } from '@ngrx/store';
import moment from 'moment';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { IMenuState } from '../app.state';
import { fromMenuActions } from './menu.actions';

export const initialState: IMenuState = {
	isMenuExpanded: false,
	testingAcknowledgedAt: null,
};

export const menuReducer = createRehydrateReducer(
	'menu',
	initialState,
	on(fromMenuActions.changeIsMenuExpanded, (state, { isMenuExpanded }) => ({ ...state, isMenuExpanded })),
	on(fromMenuActions.toggleIsMenuExpanded, state => ({ ...state, isMenuExpanded: !state.isMenuExpanded })),
	on(fromMenuActions.acknowledgedTesting, state => ({ ...state, testingAcknowledgedAt: moment().startOf('day').toISOString() }))
);
