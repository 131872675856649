import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DeliveryNoteNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-delivery-note.component';
import { IDeliveryNote } from '../../models/delivery-note';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteNotificationService extends EntityNotificationService<IDeliveryNote> {
	protected component: ComponentType<any> = DeliveryNoteNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
