import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { UploadedFileNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-uploaded-file.component';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';
@Injectable({
	providedIn: 'root',
})
export class UploadedFileNotificationService extends EntityNotificationService<IUploadedFile> {
	protected component: ComponentType<any> = UploadedFileNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
