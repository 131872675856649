import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticle, IArticleEditForm, IArticleFilterForm } from '../../models/article.model';
import { IAction } from '../../models/process-instance-model';
import { IArticleState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from './../../state/process-route/process-route.facade';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-articlehistorytable',
	templateUrl: './entitydialogtable-articlehistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class ArticleHistoryTableComponent extends HistoryDialogTableComponent<IArticle, IArticleState, IAction, IAction, IArticleEditForm, IArticleFilterForm> {
	public columns = ['date', 'route', 'userName', 'state', 'comment'];

	constructor(entityFacade: ArticleFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IArticle): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
