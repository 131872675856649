import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ICounter } from 'src/models/counter.model';
import { CounterApiService } from 'src/services/api/counter.service';
import { CounterDialogService } from 'src/services/dialog/counter.service';
import { CounterNotificationService } from 'src/services/notification/counter.service';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { AppState, ICounterState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCounterActions } from './counter.actions';
import { CounterSelector } from './counter.selectors';

@Injectable({
	providedIn: 'root',
})
export class CounterEffects extends EntityEffects<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: CounterApiService, notificationService: CounterNotificationService, dialogService: CounterDialogService, selector: CounterSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCounterActions, 'COUNTER');
	}
}
