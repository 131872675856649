import { Boxed } from 'ngrx-forms';
import { ArticleKind } from './article.model';
import { IEntity, IEntityEditForm, IEntityFilterForm, IExpandedEntityEditForm } from './entity.model';

export interface IProduct extends IEntity {
	name: string;
	productKind: ProductKind;
	articleKind: ArticleKind;
	description: string;
	shortDescription: string;
	link: string;
	price: number;
	taxRate: number;
	priority: number | null;
	image: string;
	logo: string;
	additionalImages: string[];
	partnerPrices: IPartnerPrice[];
	minimalQuantity: number;
}

export interface IPartnerPrice {
	partner: string;
	price: number;
}

export interface IProductEditForm extends IEntityEditForm {
	name: string;
	productKind: ProductKind;
	articleKind: ArticleKind;
	description: string;
	shortDescription: string;
	link: string;
	price: number;
	taxRate: number;
	priority: number | null;
	image: string;
	logo: string;
	additionalImages: Boxed<string[]>;
	partnerPrices: IPartnerPrice[];
	minimalQuantity: number;
}

export interface IExpandedProductEditForm extends IExpandedEntityEditForm {
	name: string;
	productKind: ProductKind;
	articleKind: ArticleKind;
	price: number;
	taxRate: number;
}

export interface IProductFilterForm extends IEntityFilterForm {
	name: string;
	productKind: Boxed<ProductKind[]>;
	articleKind: Boxed<ArticleKind[]>;
	description: string;
	shortDescription: string;
	link: string;
	price: number;
	taxRate: number;
	priority: number | null;
	image: string;
	logo: string;
	additionalImages: Boxed<string[]>;
	partnerPrices: IPartnerPrice[];
	minimalQuantity: number;
}

export enum ProductKind {
	MediaProduct = 'Medium',
	AccessoryProduct = 'Accessoire',
}

export enum ArticleVariant {
	Original = 'original',
	Personal = 'personalisiert',
	Timetravel = 'zeitreise',
}

export enum FolderVariant {
	VIP = 'vip',
	Premium = 'premium',
	Menu = 'menu',
}
