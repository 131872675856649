import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStoragePlace } from '../../models/storage-place.model';
import { IStoragePlaceState } from '../../state/app.state';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-storage-placedialogchange',
	templateUrl: './entitydialogchange-storage-place.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class StoragePlaceDialogChangeComponent extends EntityDialogChangeComponent<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	constructor(entityFacade: StoragePlaceFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
