import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ArticleDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-article.component';
import { ArticleDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-article.component';
import { UpdatedStoragePlacesDialogComponent } from '../../components/dialog/dialog-updated-storage-places.component';
import { ArticleHandoverDialogComponent } from '../../components/entitydialog/entitydialog-article-handover.component';
import { ArticleOrderDialogComponent } from '../../components/entitydialog/entitydialog-article-order.component';
import { ArticlePickDialogComponent } from '../../components/entitydialog/entitydialog-article-pick.component';
import { ArticlePostcheckDialogComponent } from '../../components/entitydialog/entitydialog-article-postcheck.component';
import { ArticleReproduceDialogComponent } from '../../components/entitydialog/entitydialog-article-reproduce.component';
import { ArticleReturnDialogComponent } from '../../components/entitydialog/entitydialog-article-return.component';
import {
	ArticleKind,
	IArticle,
	IArticleBirthdayBook,
	IArticleBirthdayChronicle,
	IArticleBook,
	IArticleCd,
	IArticleCertificate,
	IArticleChronicle,
	IArticleCoin,
	IArticleDvd,
	IArticleFolder,
	IArticleMagazine,
	IArticleNewspaper,
	IArticleStick,
	IArticleYearBook,
} from '../../models/article.model';
import { IEntity } from '../../models/entity.model';
import { IPosition } from '../../models/position.model';
import { IArticleState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { ArticleBirthdayBookDialogService } from './article.birthday-book.service';
import { ArticleBirthdayChronicleDialogService } from './article.birthday-chronicle.service';
import { ArticleBookDialogService } from './article.book.service';
import { ArticleCdDialogService } from './article.cd.service';
import { ArticleCertificateDialogService } from './article.certificate.service';
import { ArticleChronicleDialogService } from './article.chronicle.service';
import { ArticleCoinDialogService } from './article.coin.service';
import { ArticleDvdDialogService } from './article.dvd.service';
import { ArticleFolderDialogService } from './article.folder.service';
import { ArticleMagazineDialogService } from './article.magazine.service';
import { ArticleNewspaperDialogService } from './article.newspaper.service';
import { ArticleStickDialogService } from './article.stick.service';
import { ArticleYearArticleBookDialogService } from './article.yearbook.service';
import { IDialogData, IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

export interface IAddArticleDialogData extends IDialogData {
	date?: string;
	articleKind: ArticleKind;
	position: IPosition;
}

export interface IReplaceArticleDialogData<TEntity extends IEntity> extends IDialogData {
	entity?: TEntity;
	position: IPosition;
}

@Injectable({
	providedIn: 'root',
})
export class ArticleDialogService extends EntityDialogService<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	protected addComponent: ComponentType<any> = ArticleDialogAddComponent;
	protected changeComponent: ComponentType<any> = ArticleDialogChangeComponent;
	protected pickComponent: ComponentType<any> = ArticlePickDialogComponent;
	protected reproduceComponent: ComponentType<any> = ArticleReproduceDialogComponent;
	protected handoverComponent: ComponentType<any> = ArticleHandoverDialogComponent;
	protected orderComponent: ComponentType<any> = ArticleOrderDialogComponent;
	protected returnComponent: ComponentType<any> = ArticleReturnDialogComponent;
	protected postcheckComponent: ComponentType<any> = ArticlePostcheckDialogComponent;
	protected storagePlaceUpdatedComponent: ComponentType<any> = UpdatedStoragePlacesDialogComponent;

	constructor(
		matDialog: MatDialog,
		entityFacade: ArticleFacade,
		public birthdayArticleBookDialogService: ArticleBirthdayBookDialogService,
		public birthdayChronicleDialogService: ArticleBirthdayChronicleDialogService,
		public articleBookDialogService: ArticleBookDialogService,
		public articleCdDialogService: ArticleCdDialogService,
		public certificateDialogService: ArticleCertificateDialogService,
		public chronicleDialogService: ArticleChronicleDialogService,
		public coinDialogService: ArticleCoinDialogService,
		public dvdDialogService: ArticleDvdDialogService,
		public folderDialogService: ArticleFolderDialogService,
		public magazineDialogService: ArticleMagazineDialogService,
		public newspaperDialogService: ArticleNewspaperDialogService,
		public stickDialogService: ArticleStickDialogService,
		public yearArticleBookDialogService: ArticleYearArticleBookDialogService
	) {
		super(matDialog, entityFacade);
	}

	public openHandoverDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.handoverComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openOrderDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.orderComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openReturnDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.returnComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openPostcheckDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.postcheckComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openPickDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.pickComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openReproduceDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.reproduceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openStoragePlaceUpdated(articleKind: ArticleKind, storagePlaceId: string): MatDialogRef<any> {
		return this.openDialog(this.storagePlaceUpdatedComponent, {
			disableClose: false,
			minWidth: '400px',
			data: {
				articleKind,
				storagePlaceId,
			},
		});
	}

	public openReplaceDialog(data: IReplaceArticleDialogData<IArticle>): MatDialogRef<any> {
		switch (data.entity.articleKind) {
			case ArticleKind.BirthdayBook:
				return this.birthdayArticleBookDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleBirthdayBook>);
			case ArticleKind.BirthdayChronicle:
				return this.birthdayChronicleDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleBirthdayChronicle>);
			case ArticleKind.Book:
				return this.articleBookDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleBook>);
			case ArticleKind.Cd:
				return this.articleCdDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleCd>);
			case ArticleKind.Certificate:
				return this.certificateDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleCertificate>);
			case ArticleKind.Chronicle:
				return this.chronicleDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleChronicle>);
			case ArticleKind.Coin:
				return this.coinDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleCoin>);
			case ArticleKind.Dvd:
				return this.dvdDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleDvd>);
			case ArticleKind.Folder:
				return this.folderDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleFolder>);
			case ArticleKind.Magazine:
				return this.magazineDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleMagazine>);
			case ArticleKind.Newspaper:
				return this.newspaperDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleNewspaper>);
			case ArticleKind.Stick:
				return this.stickDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleStick>);
			case ArticleKind.YearBook:
				return this.yearArticleBookDialogService.openReplaceDialog(data as IReplaceArticleDialogData<IArticleYearBook>);
		}
	}

	public openAddDialog(data: IAddArticleDialogData): MatDialogRef<any> {
		switch (data.articleKind) {
			case ArticleKind.BirthdayBook:
				return this.birthdayArticleBookDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.BirthdayChronicle:
				return this.birthdayChronicleDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Book:
				return this.articleBookDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Cd:
				return this.articleCdDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Certificate:
				return this.certificateDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Chronicle:
				return this.chronicleDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Coin:
				return this.coinDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Dvd:
				return this.dvdDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Folder:
				return this.folderDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Magazine:
				return this.magazineDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Newspaper:
				return this.newspaperDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.Stick:
				return this.stickDialogService.openAddDialog(data as IAddArticleDialogData);
			case ArticleKind.YearBook:
				return this.yearArticleBookDialogService.openAddDialog(data as IAddArticleDialogData);
		}
	}

	public openUpdateDialog(data: IEntityDialogData<IArticle>): MatDialogRef<any> {
		switch (data.entity.articleKind) {
			case ArticleKind.BirthdayBook:
				return this.birthdayArticleBookDialogService.openUpdateDialog(data as IEntityDialogData<IArticleBirthdayBook>);
			case ArticleKind.BirthdayChronicle:
				return this.birthdayChronicleDialogService.openUpdateDialog(data as IEntityDialogData<IArticleBirthdayChronicle>);
			case ArticleKind.Book:
				return this.articleBookDialogService.openUpdateDialog(data as IEntityDialogData<IArticleBook>);
			case ArticleKind.Cd:
				return this.articleCdDialogService.openUpdateDialog(data as IEntityDialogData<IArticleCd>);
			case ArticleKind.Certificate:
				return this.certificateDialogService.openUpdateDialog(data as IEntityDialogData<IArticleCertificate>);
			case ArticleKind.Chronicle:
				return this.chronicleDialogService.openUpdateDialog(data as IEntityDialogData<IArticleChronicle>);
			case ArticleKind.Coin:
				return this.coinDialogService.openUpdateDialog(data as IEntityDialogData<IArticleCoin>);
			case ArticleKind.Dvd:
				return this.dvdDialogService.openUpdateDialog(data as IEntityDialogData<IArticleDvd>);
			case ArticleKind.Folder:
				return this.folderDialogService.openUpdateDialog(data as IEntityDialogData<IArticleFolder>);
			case ArticleKind.Magazine:
				return this.magazineDialogService.openUpdateDialog(data as IEntityDialogData<IArticleMagazine>);
			case ArticleKind.Newspaper:
				return this.newspaperDialogService.openUpdateDialog(data as IEntityDialogData<IArticleNewspaper>);
			case ArticleKind.Stick:
				return this.stickDialogService.openUpdateDialog(data as IEntityDialogData<IArticleStick>);
			case ArticleKind.YearBook:
				return this.yearArticleBookDialogService.openUpdateDialog(data as IEntityDialogData<IArticleYearBook>);
		}
	}
}
