import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PriorityType } from 'src/models/process-instance-model';

@Component({
	selector: 'babylon-process-priority-order',
	templateUrl: './process-priority-order.component.html',
})
export class ProcessPriorityOrderComponent extends BaseComponent {
	public PriorityTypes = PriorityType;
	@Input() public priority: PriorityType | string;
}
