import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { AppState, IReceiptState } from '../app.state';
import { IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { IReceipt } from '../../models/receipt';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ReceiptApiService } from '../../services/api/receipt.service';
import { ReceiptDialogService } from '../../services/dialog/receipt.service';
import { ReceiptNotificationService } from '../../services/notification/receipt.service';
import { EntityEffects } from '../entity.effects';
import { fromOrderActions } from '../order/order.actions';
import { OrderSelector } from '../order/order.selectors';
import { fromCancellationActions } from '../receipt.cancellation/receipt.cancellation.actions';
import { fromCorrectionActions } from '../receipt.correction/receipt.correction.actions';
import { fromInvoiceActions } from '../receipt.invoice/receipt.invoice.actions';
import { IInvalidationResult } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { fromReceiptActions } from './receipt.actions';
import { ReceiptSelector } from './receipt.selectors';

@Injectable({
	providedIn: 'root',
})
export class ReceiptEffects extends EntityEffects<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private receiptService: ReceiptApiService,
		notificationService: ReceiptNotificationService,
		dialogService: ReceiptDialogService,
		private selector: ReceiptSelector,
		private orderSelector: OrderSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, receiptService, notificationService, dialogService, selector, sessionSelector, fromReceiptActions, 'RECEIPT');
	}

	protected onInvalidate(entity: IReceipt): IInvalidationResult[] {
		return entity.payments.map(payment => ({ entityIdentifier: 'PAYMENT', entityId: payment }));
	}

	public onDiscriminatorUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromInvoiceActions.updated, fromCorrectionActions.updated, fromCancellationActions.updated),
			map(({ entity }) => fromReceiptActions.updated({ entity }))
		)
	);

	public onPrint$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.print),
			debounceTime(500),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receipt }, authToken]) =>
				this.receiptService.print(authToken, receipt).pipe(
					map(() => fromReceiptActions.printed()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receipt }, authToken]) =>
				this.receiptService.download(authToken, receipt).pipe(
					map(data => fromReceiptActions.downloaded({ receipt, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromReceiptActions.downloaded),
				tap(({ receipt, data }) => saveAs(data, `Beleg-${receipt.number || receipt._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onDownloadFirstReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.downloadFirstReminder),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receipt }, authToken]) =>
				this.receiptService.downloadFirstReminder(authToken, receipt).pipe(
					map(data => fromReceiptActions.downloadedFirstReminder({ receipt, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedFirstReminder$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromReceiptActions.downloadedFirstReminder),
				tap(({ receipt, data }) => saveAs(data, `Zahlungserinnerung-${receipt.number || receipt._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onDownloadSecondReminder$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.downloadSecondReminder),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ receipt }, authToken]) =>
				this.receiptService.downloadSecondReminder(authToken, receipt).pipe(
					map(data => fromReceiptActions.downloadedSecondReminder({ receipt, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloadedSecondReminder$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromReceiptActions.downloadedSecondReminder),
				tap(({ receipt, data }) => saveAs(data, `Mahnung-${receipt.number || receipt._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onUpdateReceiver$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.updateReceiver),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			filter(([[, order]]) => order != null),
			switchMap(([[, receipt], authToken]) =>
				this.receiptService.updateReceiver(receipt._id, receipt.receiver, authToken).pipe(
					map(data => fromReceiptActions.updatedReceiver({ receipt: data })),
					catchError((response: IServiceErrorResponse) => of(fromReceiptActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdatedReceiver$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.updatedReceiver),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			switchMap(([{ receipt }, order]) => [fromReceiptActions.updated({ entity: receipt }), fromOrderActions.invalidate({ ids: [order._id] })])
		)
	);

	public onFollowUp$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.followUp),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([, authToken]) =>
				this.receiptService.followUp(authToken).pipe(
					map(() => fromReceiptActions.followedUp()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onFollowedUp$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromReceiptActions.followedUp),
			map(() => fromReceiptActions.filter())
		)
	);
}
