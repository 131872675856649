import { ApplicationRef, enableProdMode } from '@angular/core';
import { enableDebugTools } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';
import { BabylonModule } from './modules/babylon.module';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(BabylonModule)
	.then(moduleRef => {
		const applicationRef = moduleRef.injector.get(ApplicationRef);
		const componentRef = applicationRef.components[0];

		if (!environment.production) {
			enableDebugTools(componentRef);
		}
	})
	.catch(err => console.error(err));
