import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { AppState, IShippingState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IShipping, IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';

@Injectable({
	providedIn: 'root',
})
export class ShippingSelector extends EntitySelector<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	protected stateSelector = (state: AppState): IShippingState => state.shipping;

	private dhlState = createSelector(
		(state: AppState) => state.shippingDhl,
		state => state
	);
	private dhlpremiumState = createSelector(
		(state: AppState) => state.shippingDhlPremium,
		state => state
	);
	private dhlexpressState = createSelector(
		(state: AppState) => state.shippingDhlExpress,
		state => state
	);
	private pickupState = createSelector(
		(state: AppState) => state.shippingPickup,
		state => state
	);

	private allStates = createSelector(this.selectState, this.dhlState, this.dhlpremiumState, this.dhlexpressState, this.pickupState, (...states) => states);

	public isFiltering = createSelector(this.allStates, states => states.some(state => state.isFiltering));
	public isSuggesting = createSelector(this.allStates, states => states.some(state => state.isSuggesting));
	public isFetching = createSelector(this.allStates, states => states.some(state => state.isFetching));
}
