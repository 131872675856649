import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { FilterConnection, IFilterDescriptor, IPageState, SortDescriptors } from '../../state/app.state';
import { PageFacade } from '../../state/page/page.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-page-autocompletesingle',
	templateUrl: './entityautocompletesingle-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class PageAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	public placeholder = 'Landing-Page';

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IPage> = () => ({ lastName: 1 });

	constructor(entityFacade: PageFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IPage): string {
		return entity != null ? `${entity.name}` : null;
	}
}
