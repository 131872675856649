import { Injectable } from '@angular/core';
import { IPublisher } from 'src/models/publisher.model';
import { AppState, IPublisherState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';

@Injectable({
	providedIn: 'root',
})
export class PublisherSelector extends EntitySelector<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	protected stateSelector = (state: AppState): IPublisherState => state.publisher;
}
