import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IArticlePosition, IBundlePosition } from '../../models/position.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { OrderApiService } from '../../services/api/order.service';
import { OrderDialogService } from '../../services/dialog/order.service';
import { OrderNotificationService } from '../../services/notification/order.service';
import { AppState, IOrderState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { RouterSelector } from '../router/router.selectors';
import { IInvalidationResult } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { fromOrderActions } from './order.actions';
import { OrderSelector } from './order.selectors';

@Injectable({
	providedIn: 'root',
})
export class OrderEffects extends EntityEffects<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: OrderApiService,
		notificationService: OrderNotificationService,
		dialogService: OrderDialogService,
		private selector: OrderSelector,
		sessionSelector: SessionSelector,
		private routerSelector: RouterSelector,
		private router: Router
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromOrderActions, 'ORDER');
	}

	public onOrderChange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ROUTER_NAVIGATED),
			concatLatestFrom(() => this.store.select(this.routerSelector.orderId)),
			switchMap(([, orderId]) => [fromOrderActions.invalidate({ ids: [orderId] }), this.entityActions.fetch({ ids: [orderId] })])
		)
	);

	public onUpdatePositions$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.updatePositions),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			filter(([[, order]]) => order != null),
			switchMap(([[{ shouldUpdateReceipt }, order], authToken]) => {
				const positionKindSorting = [PositionKind.Bundle, PositionKind.Article, PositionKind.Payment, PositionKind.Shipping, PositionKind.Discount];
				const positions = [...order.positions].sort((a, b) => positionKindSorting.indexOf(a.positionKind) - positionKindSorting.indexOf(b.positionKind));
				return this.apiService.updatePositions(order._id, positions, shouldUpdateReceipt, authToken).pipe(
					map(data => fromOrderActions.updated({ entity: data })),
					catchError((response: IServiceErrorResponse) => of(fromOrderActions.failed({ error: response.error })))
				);
			})
		)
	);

	public onPriorityUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.updatePriority),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, priority }, authToken]) =>
				this.apiService.updatePriority(entity, priority, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onCreateReturn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.createReturn),
			concatLatestFrom(() => this.store.select(this.selector.selected)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ positions }, entity], authToken]) =>
				this.apiService.createReturn(entity, positions, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onSendConfirmationEmail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.sendConfirmationEmail),
			concatLatestFrom(() => this.store.select(this.selector.selected)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[, entity], authToken]) =>
				this.apiService.sendConfirmationEmail(entity, authToken).pipe(
					switchMap(() => [fromOrderActions.sentConfirmationEmail(), fromOrderActions.updated({ entity })]),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDuplicate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.duplicate),
			concatLatestFrom(() => this.store.select(this.selector.selected)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[, entity], authToken]) =>
				this.apiService.duplicate(entity, authToken).pipe(
					switchMap(response => [fromOrderActions.duplicated({ order: response.data })]),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDuplicated$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromOrderActions.duplicated),
				filter(({ order }) => order != null),
				map(({ order }) => this.router.navigate(['/', 'entity', 'order', 'OrderNew'], { queryParams: { id: order._id } }))
			),
		{ dispatch: false }
	);

	public onSearchValueOrLimitChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.searchValueChanged, fromOrderActions.searchLimitChanged),
			concatLatestFrom(() => this.store.select(this.selector.searchValue)),
			debounceTime(500),
			filter(([, searchValue]) => searchValue != null && searchValue.length >= 3),
			map(() => fromOrderActions.search())
		)
	);

	public onSearch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromOrderActions.search),
			concatLatestFrom(() => this.store.select(this.selector.searchValue)),
			concatLatestFrom(() => this.store.select(this.selector.searchLimit)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[[, searchValue], searchLimit], authToken]) =>
				this.apiService.search(searchValue, searchLimit, authToken).pipe(
					map(({ orders, totalCount, warnings }) => fromOrderActions.searched({ entities: orders, totalCount, warnings })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	protected onInvalidate(entity: IOrder): IInvalidationResult[] {
		const result: IInvalidationResult[] = [];

		for (const position of entity.positions) {
			if (position.positionKind == PositionKind.Article) {
				const articlePosition = position as IArticlePosition;
				result.push({ entityIdentifier: 'ARTICLE', entityId: articlePosition.article._id });
			}

			if (position.positionKind == PositionKind.Bundle) {
				const bundlePosition = position as IBundlePosition;

				for (const article of bundlePosition.articles) {
					result.push({ entityIdentifier: 'ARTICLE', entityId: article._id });
				}
			}
		}

		result.push({ entityIdentifier: 'RECEIPT', entityId: entity.primaryReceipt });

		for (const receipt of entity.secondaryReceipts) {
			result.push({ entityIdentifier: 'RECEIPT', entityId: receipt });
		}

		for (const shipping of entity.shippings) {
			result.push({ entityIdentifier: 'SHIPPING', entityId: shipping });
		}

		return result;
	}
}
