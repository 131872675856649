import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { AttachmentNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-attachment.component';
import { IAttachment } from 'src/models/attachment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class AttachmentNotificationService extends EntityNotificationService<IAttachment> {
	protected component: ComponentType<any> = AttachmentNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
