import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IBirthdayBookRating } from '../../models/article-rating';
import { IMediaArticleRequest, IPaging } from '../../models/article-request';
import { IArticleBirthdayBook, IExpandedArticleBirthdayBook } from '../../models/article.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayArticleBookApiService extends EntityApiService<IArticleBirthdayBook> {
	protected entityName = 'birthday-books';

	constructor(http: HttpClient) {
		super(http);
	}

	public search(request: IMediaArticleRequest, paging?: IPaging): Observable<IExpandedArticleBirthdayBook[]> {
		const route = [this.entityName, 'search'];

		if (paging != null) {
			route.push(`?skip=${paging.skip}&top=${paging.top}`);
		}

		return this.post<IEntityListResponse<IBirthdayBookRating>, IMediaArticleRequest>(route, request).pipe(map(ratings => ratings.data.map(rating => rating.article)));
	}
}
