import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleCd, IArticleCdEditForm, IArticleCdFilterForm } from '../../models/article.model';
import { AppState, IArticleCdState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleCdActions } from './article.cd.actions';
import { initialEditFormValue } from './article.cd.reducer';
import { ArticleCdSelector } from './article.cd.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleCdFacade extends EntityFacade<IArticleCd, IArticleCdState, IArticleCdEditForm, IArticleCdFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleCdActions;

	constructor(store: Store<AppState>, private cdSelector: ArticleCdSelector) {
		super(store, cdSelector);
	}

	public isSearching$ = this.store.select(this.cdSelector.isSearching);
	public isExtending$ = this.store.select(this.cdSelector.isExtending);
	public itemsSearched$ = this.store.select(this.cdSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.cdSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleCdActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
