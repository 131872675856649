import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap } from 'rxjs';
import { IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { PaymentPaypalApiService } from '../../services/api/payment.paypal.service';
import { PaymentPaypalDialogService } from '../../services/dialog/payment.paypal.service';
import { PaypalNotificationService } from '../../services/notification/payment.paypal.service';
import { AppState, IPaymentPaypalState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromOrderActions } from '../order/order.actions';
import { OrderSelector } from '../order/order.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromPaypalActions } from './payment.paypal.actions';
import { PaypalSelector } from './payment.paypal.selectors';

@Injectable({
	providedIn: 'root',
})
export class PaypalEffects extends EntityEffects<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private paypalService: PaymentPaypalApiService,
		private orderSelector: OrderSelector,
		notificationService: PaypalNotificationService,
		dialogService: PaymentPaypalDialogService,
		selector: PaypalSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, paypalService, notificationService, dialogService, selector, sessionSelector, fromPaypalActions, 'PAYPAL');
	}

	public onSendPaymentLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPaypalActions.sendPaymentLink),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ payment }, authToken]) =>
				this.paypalService.sendPaymentLink(payment, authToken).pipe(
					map(() => fromPaypalActions.sentPaymentLink()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onSentPaymentLink$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPaypalActions.sentPaymentLink),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			map(([, order]) => fromOrderActions.invalidate({ ids: [order._id] }))
		)
	);
}
