import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IPosition } from '../../models/position.model';
import { PriorityType } from '../../models/process-instance-model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { ArticleFacade } from '../../state/article/article.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { UserFacade } from '../../state/user/user.facade';
import { IPositionAddDialogData, PositionAddDialogComponent } from './dialog-position-add.component';
import { PositionEditDialogComponent } from './dialog-position-edit.component';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-order-positionsdialog',
	templateUrl: './dialog-order-positions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss', './dialog-order-positions.component.scss'],
	animations: [progressFade],
})
export class OrdersPositionDialogComponent extends DialogComponent<OrdersPositionDialogComponent, IPosition[]> {
	public positionColumns = ['title', 'price', 'priority', 'actions'];
	public PositionKinds = PositionKind;
	public ArticleKinds = ArticleKind;
	public PriorityTypes = PriorityType;

	constructor(
		matDialogRef: MatDialogRef<OrdersPositionDialogComponent, IPosition[]>,
		formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) data: IPosition[],
		private matDialog: MatDialog,
		public orderFacade: OrderFacade,
		public articleFacade: ArticleFacade,
		public userFacade: UserFacade,
		public processNodeFacade: ProcessNodeFacade,
		public certificateFacade: ArticleCertificateFacade,
		public articleDialogService: ArticleDialogService,
		public cartFacade: CartFacade,
		public receiptFacade: ReceiptFacade
	) {
		super(matDialogRef, data);
	}

	public removePosition(position: IPosition): void {
		this.orderFacade.removePosition(position);
	}

	public updatePosition(position: IPosition): void {
		const dialog = this.matDialog.open<PositionEditDialogComponent, IPosition, IPosition>(PositionEditDialogComponent, { data: position });
		dialog
			.afterClosed()
			.pipe(filter(position => !!position))
			.subscribe(position => this.orderFacade.updatedPositions([position]));
	}

	public addPosition(): void {
		const dialog = this.matDialog.open<PositionAddDialogComponent, IPosition, IPositionAddDialogData>(PositionAddDialogComponent, { data: null, minWidth: '600px' });
		dialog
			.afterClosed()
			.pipe(filter(data => !!data))
			.subscribe(data => {
				return this.orderFacade.addPosition(data);
			});
	}
}
