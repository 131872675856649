import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPrepayment } from '../../models/payment.model';
import { IPaymentPrepaymentState } from '../../state/app.state';
import { PrepaymentFacade } from '../../state/payment.prepayment/payment.prepayment.facade';
import { IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from './../../models/payment.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-prepaymentdialogadd',
	templateUrl: './entitydialogadd-prepayment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PrepaymentDialogAddComponent extends EntityDialogAddComponent<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	constructor(entityFacade: PrepaymentFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
