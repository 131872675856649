import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { DialogComponent } from './dialog.component';

export interface IUpdateStoragePlacesData {
	articleKind: ArticleKind;
	storagePlaceId: string;
}

@Component({
	selector: 'babylon-updated-storage-placesdialog',
	templateUrl: './dialog-updated-storage-places.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class UpdatedStoragePlacesDialogComponent extends DialogComponent<UpdatedStoragePlacesDialogComponent, IUpdateStoragePlacesData> {
	constructor(
		matDialogRef: MatDialogRef<UpdatedStoragePlacesDialogComponent, IUpdateStoragePlacesData>,
		@Inject(MAT_DIALOG_DATA) data: IUpdateStoragePlacesData,
		private newspaperFacade: ArticleNewspaperFacade,
		private magazineFacade: ArticleMagazineFacade
	) {
		super(matDialogRef, data);
	}

	public setStoragePlaceFilter() {
		switch (this.data.articleKind) {
			case ArticleKind.Newspaper:
				this.newspaperFacade.changeFilterValue({ attributeName: '0.storagePlace', value: this.data.storagePlaceId });
				this.newspaperFacade.filter();
				break;
			case ArticleKind.Magazine:
				this.magazineFacade.changeFilterValue({ attributeName: '0.storagePlace', value: this.data.storagePlaceId });
				this.magazineFacade.filter();
				break;
		}
		this.matDialogRef.close();
	}
}
