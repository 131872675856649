import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPublicationCycle } from '../../models/publication-cycle.model';
import { AppState, IPublicationCycleState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { fromPublicationCycleActions } from './publication-cycle.actions';
import { initialEditFormValue } from './publication-cycle.reducer';
import { PublicationCycleSelector } from './publication-cycle.selectors';

@Injectable({
	providedIn: 'root',
})
export class PublicationCycleFacade extends EntityFacade<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPublicationCycleActions;

	constructor(store: Store<AppState>, publicationCycleSelector: PublicationCycleSelector) {
		super(store, publicationCycleSelector);
	}
}
