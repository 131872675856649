import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { SessionFacade } from '../../state/session/session.facade';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-databaseresetdialog',
	templateUrl: './dialog-database-reset.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class DatabaseResetDialogComponent extends DialogComponent<DatabaseResetDialogComponent> {
	public commit = 'I know what I am doing. This will delete all data in the database!';

	constructor(matDialogRef: MatDialogRef<DatabaseResetDialogComponent>, public sessionFacade: SessionFacade) {
		super(matDialogRef);
	}
}
