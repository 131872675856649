import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IOrder } from '../../models/order.model';
import { IPosition } from '../../models/position.model';
import { IOrderSearchWarning } from '../../state/app.state';
import { IDataResponse } from './api.service';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class OrderApiService extends EntityApiService<IOrder> {
	protected entityName = 'orders';

	constructor(http: HttpClient) {
		super(http);
	}

	public updatePositions(orderId: string, positions: IPosition[], shouldUpdateReceipt: boolean, authToken: string): Observable<IOrder> {
		const route = [this.entityName, orderId, 'update-positions', '?shouldUpdateReceipt=' + shouldUpdateReceipt];

		return this.post<IEntitySingleResponse<IOrder>, { positions: IPosition[] }>(route, { positions }, this.getHeaders(authToken)).pipe(map(response => response.data));
	}

	public updatePriority(entity: IOrder, priority: number, authToken: string): Observable<IEntitySingleResponse<IOrder>> {
		return this.post<IEntitySingleResponse<IOrder>, { priority: number }>([this.entityName, entity._id, 'priority'], { priority }, this.getHeaders(authToken));
	}

	public createReturn(entity: IOrder, positions: IPosition[], authToken: string): Observable<IEntitySingleResponse<IOrder>> {
		return this.post<IEntitySingleResponse<IOrder>, { positions: IPosition[] }>([this.entityName, entity._id, 'return-positions'], { positions }, this.getHeaders(authToken));
	}

	public sendConfirmationEmail(entity: IOrder, authToken: string): Observable<void> {
		return this.post([this.entityName, entity._id, 'send-confirmation-email'], null, this.getHeaders(authToken));
	}

	public duplicate(entity: IOrder, authToken: string): Observable<IEntitySingleResponse<IOrder>> {
		return this.post([this.entityName, entity._id, 'duplicate'], null, this.getHeaders(authToken));
	}

	public search(searchValue: string, searchLimit: number, authToken: string): Observable<{ orders: IOrder[]; totalCount: number; warnings: IOrderSearchWarning[] }> {
		return this.post<IDataResponse<{ orders: IOrder[]; totalCount: number; warnings: IOrderSearchWarning[] }>, { searchValue: string; searchLimit: number }>(
			[this.entityName, 'search'],
			{ searchValue, searchLimit },
			this.getHeaders(authToken)
		).pipe(map(result => result.data));
	}
}
