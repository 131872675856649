import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMediaProduct } from '../../models/media-product.model';
import { AppState, IMediaProductState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { fromMediaProductActions } from './media-product.actions';
import { initialEditFormValue } from './media-product.reducer';
import { MediaProductSelector } from './media-product.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaProductFacade extends EntityFacade<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromMediaProductActions;

	constructor(store: Store<AppState>, mediaProductSelector: MediaProductSelector) {
		super(store, mediaProductSelector);
	}
}
