import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { IShipping, IShippingEditForm, IShippingFilterForm } from '../../models/shipping.model';
import { AppState, IShippingState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ShippingApiService } from '../../services/api/shipping.service';
import { ShippingDialogService } from '../../services/dialog/shipping.service';
import { ShippingNotificationService } from '../../services/notification/shipping.service';
import { EntityEffects } from '../entity.effects';
import { fromOrderActions } from '../order/order.actions';
import { OrderSelector } from '../order/order.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromDhlExpressActions } from '../shipping.dhl-express/shipping.dhl-express.actions';
import { fromDhlPremiumActions } from '../shipping.dhl-premium/shipping.dhl-premium.actions';
import { fromDhlActions } from '../shipping.dhl/shipping.dhl.actions';
import { fromPickupActions } from '../shipping.pickup/shipping.pickup.actions';
import { fromShippingActions } from './shipping.actions';
import { ShippingSelector } from './shipping.selectors';

@Injectable({
	providedIn: 'root',
})
export class ShippingEffects extends EntityEffects<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private shippingService: ShippingApiService,
		private orderSelector: OrderSelector,
		notificationService: ShippingNotificationService,
		dialogService: ShippingDialogService,
		selector: ShippingSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, shippingService, notificationService, dialogService, selector, sessionSelector, fromShippingActions, 'SHIPPING');
	}

	public onDiscriminatorUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDhlActions.updated, fromDhlExpressActions.updated, fromDhlPremiumActions.updated, fromPickupActions.updated),
			map(({ entity }) => this.entityActions.updated({ entity }))
		)
	);

	public onPrint$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromShippingActions.print),
			debounceTime(500),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ shipping }, authToken]) =>
				this.shippingService.print(authToken, shipping).pipe(
					map(() => fromShippingActions.printed()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromShippingActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ shipping }, authToken]) =>
				this.shippingService.download(authToken, shipping).pipe(
					map(data => fromShippingActions.downloaded({ shipping, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public downloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromShippingActions.downloaded),
				tap(({ shipping, data }) => saveAs(data, `Versandlabel-${shipping._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onChangeShippingKind$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromShippingActions.changeShippingKind),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ shipping, shippingKind, enableSaturdayDelivery }, authToken]) =>
				this.shippingService.changeShippingKind(shipping, shippingKind, enableSaturdayDelivery, authToken).pipe(
					map(result => fromShippingActions.changedShippingKind({ shipping: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onChangeddShippingKind$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromShippingActions.changedShippingKind),
			map(({ shipping }) => fromShippingActions.updated({ entity: shipping }))
		)
	);

	public onUpdatedForOrderInvalidation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromShippingActions.updated),
			concatLatestFrom(() => this.store.select(this.orderSelector.selected)),
			filter(([, order]) => !!order),
			map(([, order]) => fromOrderActions.invalidate({ ids: [order._id] }))
		)
	);
}
