import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from '../../models/payment.model';
import { AppState, IPaymentCashOnDeliveryState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCashOnDeliveryActions } from './payment.cash-on-delivery.actions';
import { initialEditFormValue } from './payment.cash-on-delivery.reducer';
import { CashOnDeliverySelector } from './payment.cash-on-delivery.selectors';

@Injectable({
	providedIn: 'root',
})
export class CashOnDeliveryFacade extends EntityFacade<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCashOnDeliveryActions;

	constructor(store: Store<AppState>, entitySelector: CashOnDeliverySelector) {
		super(store, entitySelector);
	}
}
