import { createAction, props } from '@ngrx/store';
import { IServiceError } from '../../services/api/api.service';
import { Aggregation } from '../app.state';

const stateName = '[Aggregation]';

export const fromAggregationActions = {
	loadOrderCount: createAction(`${stateName} Load Order Count`),
	loadedOrderCount: createAction(`${stateName} Loaded Order Counts`, props<{ data: Aggregation }>()),
	loadOrderState: createAction(`${stateName} Load Order State`, props<{ displayName: string; routeName: string }>()),
	loadedOrderState: createAction(`${stateName} Loaded Order State`, props<{ displayName: string; routeName: string; data: Aggregation }>()),
	loadReceiptTotalPrice: createAction(`${stateName} Load Receipt Total Price`),
	loadedReceiptTotalPrice: createAction(`${stateName} Loaded Receipt Total Price`, props<{ data: Aggregation }>()),
	loadReceiptState: createAction(`${stateName} Load Receipt State`, props<{ displayName: string; routeName: string }>()),
	loadedReceiptState: createAction(`${stateName} Loaded Receipt State`, props<{ displayName: string; routeName: string; data: Aggregation }>()),
	changeTimespan: createAction(`${stateName} Change Timespan`, props<{ from: string; until: string }>()),
	changeFrom: createAction(`${stateName} Change From`, props<{ from: string }>()),
	changeUntil: createAction(`${stateName} Change Until`, props<{ until: string }>()),
	changeGranularity: createAction(`${stateName} Change Granularity`, props<{ granularity: string }>()),
	reset: createAction(`${stateName} Reset`),
	failed: createAction(`${stateName} Failed`, props<{ error: IServiceError }>()),
};
