import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { TextTemplateDialogService } from '../../services/dialog/text-template.service';
import { ITextTemplateState } from '../../state/app.state';
import { TextTemplateFacade } from '../../state/text-template/text-template.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-text-templatepage',
	templateUrl: './entitypage-text-template.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class TextTemplatePageComponent extends EntityPageComponent<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	public pageTitle$ = new BehaviorSubject('Textvorlagen');
	public columns = ['title', 'filter'];

	constructor(titleService: Title, entityDialogService: TextTemplateDialogService, route: ActivatedRoute, entityFacade: TextTemplateFacade) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
