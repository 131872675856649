import { createAction, props } from '@ngrx/store';
import { IServiceError } from '../../services/api/api.service';
import { Prediction } from '../app.state';

const stateName = '[Prediction]';

export const fromPredictionActions = {
	loadReceiptTotalPrice: createAction(`${stateName} Load Receipt Total Price`),
	loadedReceiptTotalPrice: createAction(`${stateName} Loaded Receipt Total Price`, props<{ data: Prediction }>()),
	reset: createAction(`${stateName} Reset`),
	failed: createAction(`${stateName} Failed`, props<{ error: IServiceError }>()),
};
