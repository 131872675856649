import { IEntity } from './entity.model';
import { IProcess } from './process';
import { IProcessNodeEditForm } from './process-node.model';
import { IUser } from './user.model';

export interface IProcessInstance {
	state: string;
	actions?: IAction[];
	currentUser?: string;
	process?: string;
	priority?: PriorityType;
}

export interface IExpandedProcessInstance {
	state: IProcessNodeEditForm;
	actions?: IAction[];
	currentUser?: IUser;
	process?: IProcess;
	priority?: PriorityType;
}

export interface IAction {
	userName?: string;
	route?: string;
	comment?: string;
	date?: string;
	timespan?: number;
	isManual?: boolean;
	isHappyPath?: boolean;
	isVisible?: boolean;
	displayName?: string;
	entityName?: string;
	entity?: IEntity;
	index?: number;
}

export enum PriorityType {
	OFFER = 5,
	EXPRESS = 4,
	PREMIUM = 3,
	PICKUP = 2,
	HASTE = 1,
	NORMAL = 0,
}
