import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { DeliveryNoteOrderApiService } from '../../services/api/delivery-note.order.service';
import { DeliveryNoteOrderDialogService } from '../../services/dialog/delivery-note.order.service';
import { DeliveryNoteOrderNotificationService } from '../../services/notification/delivery-note.order.service';
import { AppState, IDeliveryNoteOrderState } from '../app.state';
import { fromDeliveryNoteActions } from '../delivery-note/delivery-note.actions';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDeliveryNoteOrderActions } from './delivery-note.order.actions';
import { DeliveryNoteOrderSelector } from './delivery-note.order.selectors';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteOrderEffects extends EntityEffects<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private deliveryNoteService: DeliveryNoteOrderApiService,
		notificationService: DeliveryNoteOrderNotificationService,
		dialogService: DeliveryNoteOrderDialogService,
		selector: DeliveryNoteOrderSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, deliveryNoteService, notificationService, dialogService, selector, sessionSelector, fromDeliveryNoteOrderActions, 'DELIVERYNOTEORDER');
	}

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteOrderActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ deliveryNote }, authToken]) =>
				this.deliveryNoteService.download(authToken, deliveryNote).pipe(
					map(data => fromDeliveryNoteOrderActions.downloaded({ deliveryNote, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromDeliveryNoteOrderActions.downloaded),
				tap(({ deliveryNote, data }) => saveAs(data, `Lieferschein-${deliveryNote._id}.pdf`))
			),
		{ dispatch: false }
	);

	public onFetchedDiscriminator$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteOrderActions.fetched),
			map(({ entities }) => fromDeliveryNoteActions.fetched({ entities }))
		)
	);
}
