import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DvdAddDialogComponent } from '../../components/entitydialog/entitydialog-dvd-add.component';
import { DvdReplaceDialogComponent } from '../../components/entitydialog/entitydialog-dvd-replace.component';
import { DvdDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-dvd.component';
import { DvdDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-dvd.component';
import { DvdDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-dvd.component';
import { IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm } from '../../models/article.model';
import { IArticleDvdState } from '../../state/app.state';
import { ArticleDvdFacade } from '../../state/article.dvd/article.dvd.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleDvdDialogService extends EntityDialogService<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	protected createComponent: ComponentType<any> = DvdDialogAddComponent;
	protected updateComponent: ComponentType<any> = DvdDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DvdDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = DvdReplaceDialogComponent;
	protected addComponent: ComponentType<any> = DvdAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleDvdFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleDvd>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleDvd>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
