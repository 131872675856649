import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IOrder } from '../../models/order.model';
import { IOrderState } from '../../state/app.state';
import { IOrderEditForm, IOrderFilterForm } from './../../models/order.model';
import { OrderFacade } from './../../state/order/order.facade';
import { ProcessEntityDialogPreviewComponent } from './processentitydialogpreview.component';

@Component({
	selector: 'babylon-orderdialogpreview',
	templateUrl: './entitydialogpreview-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogpreview.component.scss'],
})
export class OrderDialogPreviewComponent extends ProcessEntityDialogPreviewComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	constructor(matDialogRef: MatDialogRef<any>, matDialog: MatDialog, public orderFacade: OrderFacade) {
		super(orderFacade, matDialogRef, matDialog);
	}
}
