import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { EntityState, ICollectionPurchaseTransferState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCollectionPurchaseTransferActions } from './collection-purchase-transfer.actions';

export const initialValue: ICollectionPurchaseTransfer = {
	_id: null,
	partner: null,
	positions: [],
	from: null,
	until: null,
	totalAmount: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: ICollectionPurchaseTransferEditForm = {
	_id: null,
	partner: null,
	from: null,
	until: null,
	totalAmount: null,
	positions: [
		{
			_id: null,
			receiptNumber: null,
			orderNumber: null,
			receiptDate: null,
			partnerName: null,
			hAccount: null,
			sAccount: null,
			amount: null,
			tax7: null,
			tax19: null,
		},
	],
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: ICollectionPurchaseTransferFilterForm = {
	_id: null,
	createdAt: null,
	partner: null,
	from: null,
	until: null,
	totalAmount: null,
	positions: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm>(
	'COLLECTIONPURCHASETRANSFER',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			from: 'DATEEQ',
			until: 'DATEEQ',
			totalAmount: 'EQ',
			processInstance: {
				state: 'IN',
			},
		},
	],
	{ createdAt: -1 }
);
export const collectionPurchaseTransferReducer = EntityReducer.create<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm>(
	initialState,
	{},
	fromCollectionPurchaseTransferActions,
	on(fromCollectionPurchaseTransferActions.filterByReceiptNumbers, state => ({
		...state,
		isFetching: true,
	})),
	on(fromCollectionPurchaseTransferActions.filteredByReceiptNumber, state => ({
		...state,
		isFetching: false,
	}))
);
