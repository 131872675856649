import { Observable, of, Subject } from 'rxjs';
import { bufferTime, delay, filter, map, tap } from 'rxjs/operators';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { AppState, FilterConnection, IEntityState, IFilterDescriptor, SortDescriptors } from './app.state';
import { IEntityActions, IFilterOperatorChange, IFilterValueChange, IFormValueChange, IGlobalFormValueChange } from './entity.actions';

import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { IProcessRoute } from '../models/process-route.model';
import { IUser } from '../models/user.model';
import { FilterQuery } from '../types/filter';
import { BaseFacade } from './base.facade';
import { EntitySelector } from './entity.selector';

@Injectable({
	providedIn: 'root',
})
export abstract class EntityFacade<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseFacade {
	protected abstract initialEditFormValue: TEntityEditForm;
	protected abstract actions: IEntityActions<TEntity, TEntityEditForm>;
	protected requestedIds = new Subject<string[]>();

	constructor(protected store: Store<AppState>, protected entitySelector: EntitySelector<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>) {
		super(store);

		this.requestedIds
			.pipe(
				bufferTime(500),
				map(bufferedIds => _.uniq(_.flatten(bufferedIds))),
				filter(ids => ids.length > 0),
				concatLatestFrom(ids => this.store.select(this.entitySelector.getMany(ids))),
				map(([ids, entities]) => this.findMissingIds(ids, entities)),
				filter(ids => ids.length > 0),
				tap(ids => this.store.dispatch(this.actions.fetch({ ids })))
			)
			.subscribe();
	}

	public items$ = this.store.select(this.entitySelector.items);
	public itemsFiltered$ = this.store.select(this.entitySelector.itemsFiltered);
	public itemsSuggested$ = this.store.select(this.entitySelector.itemsSuggested);
	public isFiltering$ = this.createBehaviorSubject(this.store.select(this.entitySelector.isFiltering), false);
	public isUpdating$ = this.store.select(this.entitySelector.isUpdating);
	public isSuggesting$ = this.store.select(this.entitySelector.isSuggesting).pipe(delay(0));
	public isFetching$ = this.store.select(this.entitySelector.isFetching);
	public error$ = this.store.select(this.entitySelector.error);
	public selected$ = this.store.select(this.entitySelector.selected);
	public pageIndex$ = this.store.select(this.entitySelector.pageIndex);
	public pageSize$ = this.store.select(this.entitySelector.pageSize);
	public filterCount$ = this.store.select(this.entitySelector.filterCount);
	public totalCount$ = this.store.select(this.entitySelector.totalCount);
	public editForm$ = this.store.select(this.entitySelector.editForm);
	public editFormValue$ = this.store.select(this.entitySelector.editFormValue);
	public filterForm$ = this.store.select(this.entitySelector.filterForm);
	public filterOperatorForm$ = this.store.select(this.entitySelector.filterOperatorForm);
	public sortDescriptors$ = this.store.select(this.entitySelector.sortDescriptors);
	public filterConnection$ = this.store.select(this.entitySelector.filterConnection);
	public filterDescriptors$ = this.store.select(this.entitySelector.filterDescriptors);
	public list$ = this.store.select(this.entitySelector.list);
	public listFiltered$ = this.store.select(this.entitySelector.listFiltered);
	public listSuggested$ = this.store.select(this.entitySelector.listSuggested);

	public isInvalidated(id: string): Observable<boolean> {
		return this.store.select(this.entitySelector.isInvalidated(id));
	}

	public getOne(id: string): Observable<TEntity> {
		return this.store.select(this.entitySelector.getOne(id));
	}

	public getMany(ids: string[]): Observable<TEntity[]> {
		return this.store.select(this.entitySelector.getMany(ids));
	}

	public getOneByPredicate(...predicates: ((entity: TEntity) => boolean)[]): Observable<TEntity> {
		return this.store.select(this.entitySelector.find(...predicates));
	}

	public getManyByPredicate(...predicates: ((entity: TEntity) => boolean)[]): Observable<TEntity[]> {
		return this.store.select(this.entitySelector.filter(...predicates));
	}

	public countByProcessNodes(...processNodeNames: string[]): Observable<number> {
		return this.store.select(this.entitySelector.countByProcessNodes(...processNodeNames));
	}

	public invalidate(...entities: TEntity[]): void {
		this.store.dispatch(this.actions.invalidate({ ids: entities.map(entity => entity._id) }));
	}

	public filter(): void {
		this.store.dispatch(this.actions.filter());
	}

	public increase(): void {
		if (!this.isFiltering$.value) {
			this.store.dispatch(this.actions.increase());
		}
	}

	public suggest(filterConnection: FilterConnection, filterDescriptors: IFilterDescriptor[], sortDescriptors: SortDescriptors<TEntity>): void {
		this.store.dispatch(this.actions.suggest({ filterConnection, filterDescriptors, sortDescriptors }));
	}

	public select(selected: TEntity): void {
		this.store.dispatch(this.actions.selected({ selected }));
	}

	public changePage(pageSize: number, pageIndex: number): void {
		this.store.dispatch(this.actions.pageChanged({ pageSize, pageIndex }));
	}

	public changeFilterOperator(...changes: IFilterOperatorChange[]): void {
		this.store.dispatch(this.actions.filterOperatorChanged({ changes }));
	}

	public changeFilterConnection(filterConnection: FilterConnection): void {
		this.store.dispatch(this.actions.filterConnectionChanged({ filterConnection }));
	}

	public changeFormValue(...changes: IFormValueChange[]): void {
		this.store.dispatch(this.actions.formValueChanged({ changes }));
	}

	public changeFilterValue(...changes: IFilterValueChange[]): void {
		this.store.dispatch(this.actions.filterValueChanged({ changes }));
	}

	public changeFilterDescriptor(...filterDescriptors: IFilterDescriptor[]): void {
		this.store.dispatch(this.actions.filterDescriptorChanged({ filterDescriptors }));
	}

	public changeFilterDescriptors(filterDescriptors: IFilterDescriptor[]): void {
		this.store.dispatch(this.actions.filterDescriptorChanged({ filterDescriptors }));
	}

	public changeFilterQuery(filterQuery: FilterQuery<TEntity>): void {
		this.store.dispatch(this.actions.filterQueryChanged({ filterQuery }));
	}

	public changeSorting(sortDescriptors: SortDescriptors<TEntity>): void {
		this.store.dispatch(this.actions.sortDescriptorChanged({ sortDescriptors }));
	}

	public changeGlobalForm(...changes: IGlobalFormValueChange[]): void {
		this.store.dispatch(this.actions.globalFormValueChanged({ changes }));
	}

	public resetFilter(ignoreProgrammatic: boolean = false): void {
		this.store.dispatch(this.actions.filterDescriptorChanged({ filterDescriptors: [], ignoreProgrammatic }));
	}

	public resetSorting(): void {
		this.store.dispatch(this.actions.sortDescriptorChanged({ sortDescriptors: {} }));
	}

	public fetchMany(ids: TEntity['_id'][]): Observable<TEntity[]> {
		if (ids == null || ids.length == 0) {
			return of([]);
		}

		this.requestedIds.next(ids);
		return this.store.select(this.entitySelector.getMany(ids)).pipe(map(entities => entities.filter(entity => entity != null)));
	}

	public fetchOne(id: TEntity['_id']): Observable<TEntity> {
		if (id == null) {
			return of(null);
		}

		return this.fetchMany([id]).pipe(map(([entity]) => entity));
	}

	public fetch(ids: TEntity['_id'][]): void {
		this.store.dispatch(this.actions.fetch({ ids }));
	}

	public create(): void {
		this.store.dispatch(this.actions.createForm());
	}

	public created(entity: TEntity): void {
		this.store.dispatch(this.actions.createdForm());
	}

	public update(entity: TEntity): void {
		this.store.dispatch(this.actions.updateForm({ entity }));
	}

	public updateAssignee(entity: IEntity, user: IUser): void {
		this.store.dispatch(this.actions.updateAssignee({ entity, user }));
	}

	public updateProcess(entity: IEntity, processRoute: IProcessRoute, comment: string = null, callback: (entity: TEntity) => void = null): void {
		this.store.dispatch(this.actions.updateProcess({ entity, processRoute, comment, callback }));
	}

	public revertProcess(entity: IEntity, comment: string = null): void {
		this.store.dispatch(this.actions.revertProcess({ entity, comment }));
	}

	public updated(closeDialog: boolean = true): void {
		this.store.dispatch(this.actions.updatedForm({ closeDialog }));
	}

	public remove(entity: TEntity): void {
		this.store.dispatch(this.actions.removeForm({ entity }));
	}

	public removed(): void {
		this.store.dispatch(this.actions.removedForm());
	}

	public abort(): void {
		this.store.dispatch(this.actions.abortedForm());
	}

	public preview(entity: TEntity): void {
		this.store.dispatch(this.actions.previewForm({ entity }));
	}

	public previewed(entity: TEntity): void {
		this.store.dispatch(this.actions.previewedForm({ entity }));
	}

	public buildDataSource<T>(selector: (entity: TEntity) => T[]): Observable<T[]> {
		return this.store.select(this.entitySelector.buildDataSource<T>(selector));
	}

	private findMissingIds(ids: string[], entities: TEntity[]): string[] {
		const result: string[] = [];

		for (let i = 0; i < ids.length; i++) {
			if (entities[i] === undefined) {
				result.push(ids[i]);
			}
		}

		return result;
	}
}
