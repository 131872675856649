<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="(newspaperFacade.isSearching$ | async) || (magazineFacade.isSearching$ | async) || (folderFacade.isSearching$ | async)"></mat-progress-bar>
<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Paket hinzufügen</h1>
<mat-dialog-content>
	<mat-tab-group>
		<mat-tab *ngIf="positionShouldContain(articleKinds.Newspaper)">
			<ng-template mat-tab-label> Zeitung <mat-icon svgIcon="check-circle-outline" color="success" *ngIf="positionContains(articleKinds.Newspaper)"></mat-icon> </ng-template>
			<form [formGroup]="formGroup">
				<div class="form-grid" formGroupName="newspaper">
					<mat-form-field appearance="outline">
						<mat-label>Publizierungsdatum</mat-label>
						<input matInput #dateInput [matDatepicker]="newspaperPublicationDate" formControlName="publicationDate" (keyup.enter)="searchNewspaper()" (dateChange)="searchNewspaper()" />
						<mat-datepicker-toggle matSuffix [for]="newspaperPublicationDate"></mat-datepicker-toggle>
						<mat-datepicker #newspaperPublicationDate></mat-datepicker>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-spinner [diameter]="24" matPrefix *ngIf="regionFacade.isSearching$ | async"></mat-spinner>
						<mat-icon svgIcon="map-marker" matPrefix *ngIf="!(regionFacade.isSearching$ | async)"></mat-icon>
						<mat-label>PLZ, Ort oder Region</mat-label>
						<input
							matInput
							[matAutocomplete]="regionAutocomplete"
							[value]="regionFacade.selectedAddress$ | async"
							placeholder="PLZ, Ort oder Region"
							#region
							(keyup.enter)="searchNewspaper()"
							(keyup)="regionFacade.changeName(region.value)"
							(blur)="onBlurRegion(region.value)"
							autocomplete="off" />
						<mat-autocomplete autoActiveFirstOption #regionAutocomplete="matAutocomplete" [displayWith]="displayRegionWith" (optionSelected)="regionFacade.select($event.option.value)">
							<ng-container *ngFor="let region of regionFacade.listSearched$ | async; let i = index">
								<mat-option [value]="region">
									{{ region.address }} <small class="mat-small">{{ region.regionKind }}</small>
								</mat-option>
							</ng-container>
						</mat-autocomplete>
						<button matSuffix mat-icon-button aria-label="Leeren" (click)="regionFacade.select(null)" *ngIf="regionFacade.selected$ | async">
							<mat-icon svgIcon="close"></mat-icon>
						</button>
					</mat-form-field>
				</div>
			</form>

			<ng-container *ngIf="newspaperFacade.itemsSearched$ | async as itemsSearched">
				<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
					<ng-container matColumnDef="publicationDate">
						<th mat-header-cell *matHeaderCellDef>Publizierungsdatum</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity ? (entity.publicationDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)') : '' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="price">
						<th mat-header-cell *matHeaderCellDef>Preis</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity.product.price | currency }}
						</td>
					</ng-container>

					<ng-container matColumnDef="product">
						<th mat-header-cell *matHeaderCellDef>Produkt</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity.product?.name }}
						</td>
					</ng-container>

					<ng-container matColumnDef="region">
						<th mat-header-cell *matHeaderCellDef>Region</th>
						<td mat-cell *matCellDef="let entity">{{ entity.product.productionRegion.name }}, {{ entity.product.graphicalRegion.shortName }}</td>
					</ng-container>

					<ng-container matColumnDef="add">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let entity">
							<button mat-icon-button matTooltip="Auswählen" (click)="addArticle(entity, articleKinds.Newspaper)" *ngIf="!positionContainsId(entity._id)">
								<mat-icon svgIcon="check-circle-outline"></mat-icon>
							</button>
							<button mat-icon-button matTooltip="Ausgewählt" *ngIf="positionContainsId(entity._id)">
								<mat-icon svgIcon="check-circle" color="success"></mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="newspaperColumns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: newspaperColumns"></tr>
				</table>
			</ng-container>
		</mat-tab>
		<mat-tab *ngIf="positionShouldContain(articleKinds.Magazine)">
			<ng-template mat-tab-label> Zeitschrift <mat-icon svgIcon="check-circle-outline" color="success" *ngIf="positionContains(articleKinds.Magazine)"></mat-icon> </ng-template>
			<form [formGroup]="formGroup">
				<div class="form-grid" formGroupName="magazine">
					<mat-form-field appearance="outline">
						<mat-label>Publizierungsdatum</mat-label>
						<input matInput #dateInput [matDatepicker]="magazinePublicationDate" formControlName="publicationDate" (keyup.enter)="searchMagazine()" (dateChange)="searchMagazine()" />
						<mat-datepicker-toggle matSuffix [for]="magazinePublicationDate"></mat-datepicker-toggle>
						<mat-datepicker #magazinePublicationDate></mat-datepicker>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-spinner [diameter]="24" matPrefix *ngIf="regionFacade.isSearching$ | async"></mat-spinner>
						<mat-icon svgIcon="map-marker" matPrefix *ngIf="!(regionFacade.isSearching$ | async)"></mat-icon>
						<mat-label>PLZ, Ort oder Region</mat-label>
						<input
							matInput
							[matAutocomplete]="regionAutocomplete"
							[value]="regionFacade.selectedAddress$ | async"
							placeholder="PLZ, Ort oder Region"
							#region
							(keyup.enter)="searchMagazine()"
							(keyup)="regionFacade.changeName(region.value)"
							(blur)="onBlurRegion(region.value)"
							autocomplete="off" />
						<mat-autocomplete autoActiveFirstOption #regionAutocomplete="matAutocomplete" [displayWith]="displayRegionWith" (optionSelected)="regionFacade.select($event.option.value)">
							<ng-container *ngFor="let region of regionFacade.listSearched$ | async; let i = index">
								<mat-option [value]="region">
									{{ region.address }} <small class="mat-small">{{ region.regionKind }}</small>
								</mat-option>
							</ng-container>
						</mat-autocomplete>
						<button matSuffix mat-icon-button aria-label="Leeren" (click)="regionFacade.select(null)" *ngIf="regionFacade.selected$ | async">
							<mat-icon svgIcon="close"></mat-icon>
						</button>
					</mat-form-field>
				</div>
			</form>

			<ng-container *ngIf="magazineFacade.itemsSearched$ | async as itemsSearched">
				<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
					<ng-container matColumnDef="publicationDate">
						<th mat-header-cell *matHeaderCellDef>Publizierungsdatum</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity ? (entity.publicationDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)') : '' }}
						</td>
					</ng-container>

					<ng-container matColumnDef="price">
						<th mat-header-cell *matHeaderCellDef>Preis</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity.product.price | currency }}
						</td>
					</ng-container>

					<ng-container matColumnDef="product">
						<th mat-header-cell *matHeaderCellDef>Produkt</th>
						<td mat-cell *matCellDef="let entity">
							{{ entity.product?.name }}
						</td>
					</ng-container>

					<ng-container matColumnDef="region">
						<th mat-header-cell *matHeaderCellDef>Region</th>
						<td mat-cell *matCellDef="let entity">{{ entity.product.productionRegion.name }}, {{ entity.product.graphicalRegion.shortName }}</td>
					</ng-container>

					<ng-container matColumnDef="add">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let entity">
							<button mat-icon-button matTooltip="Auswählen" (click)="addArticle(entity, articleKinds.Magazine)" *ngIf="!positionContainsId(entity._id)">
								<mat-icon svgIcon="check-circle-outline"></mat-icon>
							</button>
							<button mat-icon-button matTooltip="Ausgewählt" *ngIf="positionContainsId(entity._id)">
								<mat-icon svgIcon="check-circle" color="success"></mat-icon>
							</button>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="magazineColumns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: magazineColumns"></tr>
				</table>
			</ng-container>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label> Mappe <mat-icon svgIcon="check-circle-outline" color="success" *ngIf="positionContains(articleKinds.Folder)"></mat-icon> </ng-template>
			<form>
				<div class="form-grid">
					<ng-container *ngIf="folderFacade.itemsSearched$ | async as itemsSearched">
						<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
							<ng-container matColumnDef="storagePlace">
								<th mat-header-cell *matHeaderCellDef>Preis</th>
								<td mat-cell *matCellDef="let entity">
									{{ entity.product?.price | currency }}
								</td>
							</ng-container>

							<ng-container matColumnDef="product">
								<th mat-header-cell *matHeaderCellDef>Produkt</th>
								<td mat-cell *matCellDef="let entity">
									{{ entity.product?.name }}
								</td>
							</ng-container>

							<ng-container matColumnDef="add">
								<th mat-header-cell *matHeaderCellDef></th>
								<td mat-cell *matCellDef="let entity">
									<button mat-icon-button matTooltip="Austauschen" (click)="addArticle(entity, articleKinds.Folder)" *ngIf="!positionContainsId(entity._id)">
										<mat-icon svgIcon="check-circle-outline"></mat-icon>
									</button>
									<button mat-icon-button matTooltip="Ausgewählt" *ngIf="positionContainsId(entity._id)">
										<mat-icon svgIcon="check-circle" color="success"></mat-icon>
									</button>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="folderColumns; sticky: true"></tr>
							<tr mat-row *matRowDef="let row; columns: folderColumns"></tr>
						</table>
					</ng-container>
				</div>
			</form>
		</mat-tab>
		<mat-tab label="Urkunde" *ngIf="certificateFacade.expandedEditForm$ | async as editForm">
			<form [ngrxFormState]="editForm">
				<div class="form-grid">
					<mat-form-field appearance="outline">
						<mat-label>Jubiläum</mat-label>
						<input type="number" matInput [ngrxFormControlState]="editForm.controls.anniversary" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Geschenkdatum</mat-label>
						<input matInput [matDatepicker]="date" [ngrxFormControlState]="editForm.controls.date" [ngrxValueConverter]="dateToISOStringConverter" />
						<mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
						<mat-datepicker #date></mat-datepicker>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Geschenkanlass</mat-label>
						<mat-select [ngrxFormControlState]="editForm.controls.occasion">
							<mat-option [value]="occasionType.value" *ngFor="let occasionType of OccasionTypes | keyvalue">{{ occasionType.value }}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Eigener Anlass</mat-label>
						<input type="text" matInput [ngrxFormControlState]="editForm.controls.customOccasion" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Anredeform</mat-label>
						<mat-select [ngrxFormControlState]="editForm.controls.addressForm" (selectionChange)="changedAddressForm($event)">
							<mat-option [value]="adressFormType.value" *ngFor="let adressFormType of AdressFormTypes | keyvalue">{{ adressFormType.value }}</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Beschenkte*r*</mat-label>
						<input type="text" matInput [ngrxFormControlState]="editForm.controls.giftee" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Geburtsname</mat-label>
						<input type="text" matInput [ngrxFormControlState]="editForm.controls.gifteeBirthName" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Partner/-in</mat-label>
						<input type="text" matInput [ngrxFormControlState]="editForm.controls.partner" />
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Geburtstname Partner/-in</mat-label>
						<input type="text" matInput [ngrxFormControlState]="editForm.controls.partnerBirthName" />
					</mat-form-field>
					<div class="editor">
						<span class="mat-caption">Text</span>
						<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.text"></ckeditor>
					</div>
					<mat-form-field appearance="outline" class="mat-form-field--span">
						<mat-label>Glückwunschtext</mat-label>
						<textarea matInput cdkTextareaAutosize rows="5" [ngrxFormControlState]="editForm.controls.message"></textarea>
					</mat-form-field>
				</div>
			</form>
		</mat-tab>
	</mat-tab-group>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="cancel()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="addPosition()" [disabled]="positionInvalid()">Hinzufügen</button>
</mat-dialog-actions>
