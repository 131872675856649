import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm } from '../../models/article.model';
import { AppState, IArticleFolderState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleFolderActions } from './article.folder.actions';
import { initialEditFormValue } from './article.folder.reducer';
import { ArticleFolderSelector } from './article.folder.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleFolderFacade extends EntityFacade<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleFolderActions;

	constructor(store: Store<AppState>, private folderSelector: ArticleFolderSelector) {
		super(store, folderSelector);
	}

	public isSearching$ = this.store.select(this.folderSelector.isSearching);
	public itemsSearched$ = this.store.select(this.folderSelector.itemsSearched);

	public search(): void {
		this.store.dispatch(this.actions.search());
	}
}
