import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleStick } from '../../models/article.model';
import { IArticleStickState } from '../../state/app.state';
import { ArticleStickFacade } from '../../state/article.stick/article.stick.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleStickEditForm, IArticleStickFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-stickdialogchange',
	templateUrl: './entitydialogchange-stick.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class StickDialogChangeComponent extends EntityDialogChangeComponent<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	constructor(entityFacade: ArticleStickFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
