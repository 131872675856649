import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'boolean' })
export class BooleanPipe implements PipeTransform {
	transform(value: boolean, type: string) {
		switch (type) {
			case 'icon':
				return value ? 'done' : 'close';
			default:
			case 'text':
				return value ? 'Ja' : 'Nein';
		}
	}
}
