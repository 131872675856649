import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICounter } from 'src/models/counter.model';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { AppState, ICounterState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCounterActions } from './counter.actions';
import { initialEditFormValue } from './counter.reducer';
import { CounterSelector } from './counter.selectors';

@Injectable({
	providedIn: 'root',
})
export class CounterFacade extends EntityFacade<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCounterActions;

	constructor(store: Store<AppState>, private counterSelector: CounterSelector) {
		super(store, counterSelector);
	}
}
