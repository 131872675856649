import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IAttachment } from 'src/models/attachment.model';
import { AttachmentApiService } from 'src/services/api/attachment.service';
import { AttachmentDialogService } from 'src/services/dialog/attachment.service';
import { AttachmentNotificationService } from 'src/services/notification/attachment.service';
import { IAttachmentEditForm, IAttachmentFilterForm } from '../../models/attachment.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AppState, IAttachmentState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAttachmentActions } from './attachment.actions';
import { AttachmentSelector } from './attachment.selectors';

@Injectable({
	providedIn: 'root',
})
export class AttachmentEffects extends EntityEffects<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: AttachmentApiService,
		notificationService: AttachmentNotificationService,
		dialogService: AttachmentDialogService,
		private selector: AttachmentSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromAttachmentActions, 'ATTACHMENT');
	}

	public onCreatedAttachmentForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAttachmentActions.createdAttachmentForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.createAttachment(entity, file, authToken).pipe(
					map(result => fromAttachmentActions.created({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdatedAttachmentForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAttachmentActions.updatedAttachmentForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.updateAttachment(entity, file, authToken).pipe(
					map(result => fromAttachmentActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);
}
