import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleStickState } from 'src/state/app.state';
import { IArticleStick, IArticleStickEditForm, IArticleStickFilterForm, IExpandedArticle, IExpandedArticleStick } from '../../models/article.model';
import { IReplaceArticleDialogData } from '../../services/dialog/article.service';
import { ArticleStickDialogService } from '../../services/dialog/article.stick.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleStickFacade } from '../../state/article.stick/article.stick.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-stickreplacedialog',
	templateUrl: './entitydialog-stick-replace.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class StickReplaceDialogComponent extends EntityDialogComponent<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	public columns = ['product', 'storagePlace', 'replace'];

	constructor(
		public stickFacade: ArticleStickFacade,
		matDialogRef: MatDialogRef<StickReplaceDialogComponent, IEntityDialogData<IArticleStick>>,
		@Inject(MAT_DIALOG_DATA) data: IReplaceArticleDialogData<IExpandedArticleStick>,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		public orderFacade: OrderFacade,
		public regionFacade: RegionFacade,
		public stickDialogService: ArticleStickDialogService,
		public cartFacade: CartFacade
	) {
		super(stickFacade, matDialogRef);

		this.stickFacade.search();

		this.position = data.position;
		this.originalArticle = data.entity;
	}

	public replaceStick(article: IExpandedArticle): void {
		const updatedPosition = this.replaceArticlePosition(article);
		this.cartFacade.validatePositions(updatedPosition);
		this.matDialogRef.close();
	}
}
