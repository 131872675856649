import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleCertificate } from '../../models/article.model';
import { ArticleCertificateDialogService } from '../../services/dialog/article.certificate.service';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { IArticleCertificateState } from '../../state/app.state';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IArticleCertificateEditForm, IArticleCertificateFilterForm } from './../../models/article.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-certificatepage',
	templateUrl: './entitypage-certificate.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class CertificatePageComponent extends EntityPageComponent<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	public pageTitle$ = new BehaviorSubject('Geschenkurkunden');
	public columns = ['updatedAt', 'order', 'date', 'giftee', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: ArticleCertificateDialogService,
		route: ActivatedRoute,
		public entityFacade: ArticleCertificateFacade,
		public orderFacade: OrderFacade,
		public accessoryProductFacade: AccessoryProductFacade,
		public accessoryStockFacade: AccessoryStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });
	}
}
