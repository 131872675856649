import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AppState, IAccessoryProductState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { fromAccessoryProductActions } from './accessory-product.actions';
import { initialEditFormValue } from './accessory-product.reducer';
import { AccessoryProductSelector } from './accessory-product.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccessoryProductFacade extends EntityFacade<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAccessoryProductActions;

	constructor(store: Store<AppState>, accessoryProductSelector: AccessoryProductSelector) {
		super(store, accessoryProductSelector);
	}
}
