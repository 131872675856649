<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Datenrücksetzung</h1>
<mat-dialog-content>
	<p>Du bist dabei, die Laufzeitdaten des Systems vollständig zurückzusetzen. Diese Funktion kann ausschließlich auf der Testumgebung ausgeführt werden.</p>
	<p>Folgendes wird passieren:</p>
	<ol>
		<li>Alle Aufträge werden gelöscht</li>
		<li>Alle Belege werden gelöscht</li>
		<li>Alle Zahlungen/Lieferungen werden gelöscht</li>
		<li>Alle Buchungssätze/Lastschrifteinzüge werden gelöscht</li>
		<li>Alle angefertigten Urkunden werden gelöscht</li>
		<li>Alle Artikel werden zurückgesetzt und sind anschließend wieder verfügbar</li>
		<li>Alle laufenden Nummern werden auf ihren initialen Wert zurückgesetzt</li>
	</ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="sessionFacade.resetDatabase(commit)">Ausführen</button>
</mat-dialog-actions>
