<div fxLayout="row">
	<div class="progress-wrapper">
		<mat-form-field appearance="outline" class="filter">
			<mat-label>{{ placeholder }}</mat-label>
			<input type="text" #input matInput [matAutocomplete]="autocomplete" (keyup)="input$.next(input.value)" />
			<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayWith" panelWidth="auto" (optionSelected)="toBeAdded$.next($event.option.value)">
				<mat-option *ngFor="let entity of entityFacade.listSuggested$ | async" [value]="entity"> {{ entity.caption }} </mat-option>
			</mat-autocomplete>
		</mat-form-field>
		<mat-progress-bar mode="indeterminate" *ngIf="entityFacade.isSuggesting$ | async" [@progressFade]></mat-progress-bar>
	</div>
</div>

<table mat-table [dataSource]="dataSource$ | async" class="filter-table">
	<ng-container matColumnDef="caption">
		<td mat-cell *matCellDef="let entityId" width="80">
			<ng-container *ngIf="entityFacade.fetchOne(entityId) | async as entity">
				{{ entity.caption }}
			</ng-container>
		</td>
	</ng-container>
	<ng-container matColumnDef="delete">
		<td mat-cell *matCellDef="let entityId" width="50">
			<button mat-icon-button matTooltip="Filter entfernen" (click)="toBeRemoved$.next(entityId)">
				<mat-icon svgIcon="close"></mat-icon>
			</button>
		</td>
	</ng-container>

	<tr mat-row *matRowDef="let row; columns: columns"></tr>
</table>
