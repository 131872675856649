import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IProcessRoute } from '../../models/process-route.model';
import { IProcessNodeCount } from '../../state/app.state';
import { IDataResponse } from './api.service';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ProcessRouteApiService extends EntityApiService<IProcessRoute> {
	protected entityName = 'process-routes';

	constructor(http: HttpClient) {
		super(http);
	}

	public count(authToken: string): Observable<IDataResponse<IProcessNodeCount[]>> {
		return of({ data: [], count: 0, totalCount: 0, success: true });
	}
}
