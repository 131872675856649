<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Produktpaket hinzufügen</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Name (Web)</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Name (Print)</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.printName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Überschrift</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.headline" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Preis (zzgl. ab)</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.price" />
							<span matSuffix>€</span>
						</mat-form-field>
						<babylon-image-autocompletesingle [value]="editForm.controls.image.value" [controlId]="editForm.controls.image.id" [optional]="true" placeholder="Bild"></babylon-image-autocompletesingle>
						<div class="editor">
							<span class="mat-caption">Beschreibung</span>
							<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.description"></ckeditor>
						</div>
						<div class="editor">
							<span class="mat-caption">Beschreibung (Modal)</span>
							<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.descriptionModal"></ckeditor>
						</div>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.isPreferred">Badge "Unser Tipp!" hinzufügen</mat-checkbox>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.isActive">Öffentlich zugänglich</mat-checkbox>
					</div>
				</mat-tab>
				<mat-tab label="Upselling">
					<div class="form-grid">
						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Überschrift</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.upsellingHeadline" />
						</mat-form-field>
						<div class="editor">
							<span class="mat-caption">Text</span>
							<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.upsellingText"></ckeditor>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Produkte">
					<mat-card class="box">
						<mat-card-content>
							<babylon-bundlecomponentstable controlId="components"></babylon-bundlecomponentstable>
						</mat-card-content>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
