import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';

import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { progressFade } from 'src/animations/progressfade.animation';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { IStockAccessory } from '../../models/stock.model';
import { StockAccessoryDialogService } from '../../services/dialog/stock.accessory.service';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { IStockAccessoryState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-accessorystockpage',
	templateUrl: './entitypage-accessory-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class AccessoryStockPageComponent extends EntityPageComponent<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	public pageTitle$ = new BehaviorSubject('Accessoire-Bestände');
	public columns = ['product', 'storagePlace', 'quality', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: StockAccessoryDialogService,
		route: ActivatedRoute,
		public entityFacade: AccessoryStockFacade,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processFacade: ProcessFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public accessoryProductFacade: AccessoryProductFacade,
		private routerFacade: RouterFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);

		combineLatest([this.routerFacade.stockStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([stockStates, processNodes]) => processNodes.filter(processNode => stockStates.some(stockState => stockState.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					this.entityFacade.changeSorting({ createdAt: -1 });

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}
}
