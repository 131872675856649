<mat-progress-bar
	class="dialog-progress"
	[@progressFade]
	mode="indeterminate"
	*ngIf="
		(articleFacade.isFetching$ | async) ||
		(paymentFacade.isFetching$ | async) ||
		(receiptFacade.isFetching$ | async) ||
		(shippingFacade.isFetching$ | async) ||
		(deliveryNoteFacade.isFetching$ | async) ||
		(accountingSummaryAdvancementFacade.isFetching$ | async) ||
		(collectionPurchaseTransferFacade.isFetching$ | async) ||
		(directDebitTransferFacade.isFetching$ | async)
	"></mat-progress-bar>
<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<ng-container *ngIf="entityFacade.selected$ | async as entity">
		<table mat-table [dataSource]="deepHistory$ | async">
			<ng-container matColumnDef="date">
				<th mat-header-cell *matHeaderCellDef>
					<div>Datum</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">
					<span class="timespan" *ngIf="action.timespan != null">{{ action.timespan | amDuration: 'seconds' }} später</span>
					<ng-container *ngIf="action.timespan == null">{{ action.date | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm:ss' }}</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="userName">
				<th mat-header-cell *matHeaderCellDef>
					<div>Bearbeiter</div>
				</th>
				<td width="270px" mat-cell *matCellDef="let action">
					<ng-container *ngIf="action.timespan == null">
						<mat-icon *ngIf="action.isManual" svgIcon="hand-back-left-outline" matTooltip="Diese Aktion wurde händisch durch den Benutzer ausgeführt"> </mat-icon>
						<mat-icon *ngIf="!action.isManual" svgIcon="arrow-right-top" matTooltip="Diese Aktion wurde automatisiert durch den Benutzer ausgeführt"> </mat-icon>
						{{ action.userName }}
					</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="entityType">
				<th mat-header-cell *matHeaderCellDef>
					<div>Typ</div>
				</th>
				<td width="200px" mat-cell *matCellDef="let action">
					<ng-container *ngIf="action.timespan == null">{{ action.entityName }}</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="entityName">
				<th mat-header-cell *matHeaderCellDef>
					<div>Aktivität</div>
				</th>
				<td width="300px" mat-cell *matCellDef="let action">
					<ng-container *ngIf="action.timespan == null">{{ action.displayName }}</ng-container>
				</td>
			</ng-container>

			<ng-container matColumnDef="route">
				<th mat-header-cell *matHeaderCellDef>
					<div>Aktion</div>
				</th>
				<td width="270px" mat-cell *matCellDef="let action">
					<ng-container *ngIf="action.timespan == null"
						><mat-icon *ngIf="action.isVisible" svgIcon="sign-direction" matTooltip="Diese Prozessroute ist immer sichtbar"> </mat-icon>
						<mat-icon *ngIf="!action.isVisible" svgIcon="directions-fork" matTooltip="Diese Prozessroute ist nur innerhalb der Prozessroutenadministration sichtbar"> </mat-icon>
						{{ (processRouteFacade.fetchOne(action.route) | async)?.displayName }}</ng-container
					>
				</td>
			</ng-container>

			<ng-container matColumnDef="state">
				<th mat-header-cell *matHeaderCellDef>
					<div>Neuer Status</div>
				</th>
				<td width="250px" mat-cell *matCellDef="let action">
					<ng-container *ngIf="action.timespan == null"
						><span *ngIf="processRouteFacade.fetchOne(action.route) | async as route">
							<span *ngIf="processNodeFacade.fetchOne(route.destinationNode) | async as processNode" class="status status--{{ processNode.color }}">
								{{ processNode.displayName }}
							</span>
						</span></ng-container
					>
				</td>
			</ng-container>

			<ng-container matColumnDef="comment">
				<th mat-header-cell *matHeaderCellDef></th>
				<td width="250px" mat-cell *matCellDef="let action; let i = index">
					<ng-container *ngIf="action.timespan == null"
						><button mat-icon-button (click)="openOrderCommentDialog(action, entity)">
							<mat-icon *ngIf="action.comment && action.comment != ''" svgIcon="message-text-outline"> </mat-icon>
							<mat-icon *ngIf="!action.comment || action.comment == ''" svgIcon="message-outline"></mat-icon></button
					></ng-container>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="columns"></tr>
			<tr mat-row *matRowDef="let row; columns: columns" [class.is-manual]="row.isManual" [class.is-visible]="row.isVisible" [class.is-happypath]="row.isHappyPath"></tr>
		</table>
	</ng-container>
</ng-container>
