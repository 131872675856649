import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IPermission } from '../../models/permission.model';
import { FilterConnection, IFilterDescriptor, IPermissionState, SortDescriptors } from '../../state/app.state';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-permission-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-permission.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class PermissionAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	public placeholder = 'Berechtigungen';
	public columns: string[] = ['name', 'delete'];

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'displayName', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IPermission> = () => ({ displayName: 1 });

	constructor(entityFacade: PermissionFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IPermission): string {
		return entity?.displayName;
	}
}
