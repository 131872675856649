import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ICustomer } from '../../models/customer.model';
import { CustomerApiService } from '../../services/api/customer.service';
import { CustomerDialogService } from '../../services/dialog/customer.service';
import { CustomerNotificationService } from '../../services/notification/customer.service';
import { AppState, ICustomerState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { fromCustomerActions } from './customer.actions';
import { CustomerSelector } from './customer.selectors';

@Injectable({
	providedIn: 'root',
})
export class CustomerEffects extends EntityEffects<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: CustomerApiService,
		notificationService: CustomerNotificationService,
		dialogService: CustomerDialogService,
		selector: CustomerSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCustomerActions, 'CUSTOMER');
	}
}
