import { Injectable } from '@angular/core';
import { IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm } from '../../models/delivery-note';
import { AppState, IDeliveryNoteReturnState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnSelector extends EntitySelector<IDeliveryNoteReturn, IDeliveryNoteReturnState, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {
	protected stateSelector = (state: AppState): IDeliveryNoteReturnState => state.deliveryNoteReturn;
}
