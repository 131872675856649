import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';
import { progressFade } from '../../animations/progressfade.animation';
import { IStock, IStockMedia } from '../../models/stock.model';
import { FilterConnection, IFilterDescriptor, IStockMediaState, SortDescriptors } from '../../state/app.state';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-media-stock-autocompletesingle',
	templateUrl: './entityautocompletesingle-media-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class MediaStockAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	public placeholder = 'Bestand';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IStock> = () => ({ createdAt: -1 });

	constructor(entityFacade: MediaStockFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IStock): string {
		return `${moment(entity?.createdAt).format('DD.MM.YYYY')}`;
	}
}
