import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ICustomer } from '../../models/customer.model';
import { AppState, ICustomerState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { fromCustomerActions } from './customer.actions';
import { initialEditFormValue } from './customer.reducer';
import { CustomerSelector } from './customer.selectors';

@Injectable({
	providedIn: 'root',
})
export class CustomerFacade extends EntityFacade<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCustomerActions;

	constructor(store: Store<AppState>, customerSelector: CustomerSelector) {
		super(store, customerSelector);
	}
}
