import { RouterReducerState } from '@ngrx/router-store';
import { createFormArrayState, createFormGroupState, FormArrayState, FormGroupState } from 'ngrx-forms';
import { IAccessoryProduct } from '../models/accessory-product.model';
import {
	IAccountingSummary,
	IAccountingSummaryAdvancement,
	IAccountingSummaryAdvancementEditForm,
	IAccountingSummaryAdvancementFilterForm,
	IAccountingSummaryCustomer,
	IAccountingSummaryCustomerEditForm,
	IAccountingSummaryCustomerFilterForm,
	IAccountingSummaryEditForm,
	IAccountingSummaryFilterForm,
	IAccountingSummaryPosition,
} from '../models/accounting-summary.model';
import { IPaging } from '../models/article-request';
import {
	IArticle,
	IArticleBirthdayBook,
	IArticleBirthdayChronicle,
	IArticleBook,
	IArticleCd,
	IArticleCertificate,
	IArticleChronicle,
	IArticleChronicleEditForm,
	IArticleChronicleFilterForm,
	IArticleCoin,
	IArticleCoinEditForm,
	IArticleCoinFilterForm,
	IArticleDvd,
	IArticleDvdEditForm,
	IArticleDvdFilterForm,
	IArticleExpandedYearBook,
	IArticleFolder,
	IArticleFolderEditForm,
	IArticleFolderFilterForm,
	IArticleMagazine,
	IArticleMagazineEditForm,
	IArticleMagazineFilterForm,
	IArticleNewspaper,
	IArticleNewspaperEditForm,
	IArticleNewspaperFilterForm,
	IArticleStickEditForm,
	IArticleStickFilterForm,
	IArticleYearBook,
	IArticleYearBookEditForm,
	IArticleYearBookFilterForm,
	IExpandedArticle,
	IExpandedArticleBirthdayBook,
	IExpandedArticleBirthdayChronicle,
	IExpandedArticleBook,
	IExpandedArticleCd,
	IExpandedArticleCertificate,
	IExpandedArticleCertificateEditForm,
	IExpandedArticleChronicle,
	IExpandedArticleCoin,
	IExpandedArticleDvd,
	IExpandedArticleFolder,
	IExpandedArticleMagazine,
	IExpandedArticleNewspaper,
	IExpandedArticleStick,
	IExpandedMediaArticle,
} from '../models/article.model';
import { IAttachment, IAttachmentEditForm, IAttachmentFilterForm } from '../models/attachment.model';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../models/bundle.model';
import { ICart } from '../models/cart.model';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../models/collection-purchase-transfer.model';
import { IConfiguration } from '../models/configuration.model';
import { ICounter, ICounterEditForm, ICounterFilterForm } from '../models/counter.model';
import { ICustomer } from '../models/customer.model';
import {
	IDeliveryNote,
	IDeliveryNoteEditForm,
	IDeliveryNoteFilterForm,
	IDeliveryNoteOrder,
	IDeliveryNoteOrderEditForm,
	IDeliveryNoteOrderFilterForm,
	IDeliveryNoteReturn,
	IDeliveryNoteReturnEditForm,
	IDeliveryNoteReturnFilterForm,
} from '../models/delivery-note';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../models/direct-debit-transfer.model';
import { IDocument, IDocumentEditForm, IDocumentFilterForm } from '../models/document.model';
import { IEntity, IEntityEditForm, IEntityFilterForm } from '../models/entity.model';
import { IImage } from '../models/image.model';
import { IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm } from '../models/media-brand.model';
import { IMediaProduct } from '../models/media-product.model';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../models/message.model';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../models/order.model';
import { IPage, IPageEditForm, IPageFilterForm } from '../models/page.model';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../models/partner.model';
import {
	IPayment,
	IPaymentBarzahlen,
	IPaymentCash,
	IPaymentCashOnDelivery,
	IPaymentCollectionPurchase,
	IPaymentCollectionPurchaseEditForm,
	IPaymentCollectionPurchaseFilterForm,
	IPaymentDirectDebit,
	IPaymentPaypal,
	IPaymentPrepayment,
	IPaymentPrepaymentEditForm,
	IPaymentPrepaymentFilterForm,
	IPaymentPurchaseOnAccount,
	IPaymentPurchaseOnAccountEditForm,
	IPaymentPurchaseOnAccountFilterForm,
} from '../models/payment.model';
import { IPermission } from '../models/permission.model';
import { IPosition } from '../models/position.model';
import { IProcess } from '../models/process';
import { IProcessNode } from '../models/process-node.model';
import { IProcessRoute } from '../models/process-route.model';
import { IProduct } from '../models/product.model';
import { IPublicationCycle } from '../models/publication-cycle.model';
import { IPublisher } from '../models/publisher.model';
import { IRegion } from '../models/region.model';
import { IStock, IStockAccessory, IStockMedia } from '../models/stock.model';
import { IStoragePlace } from '../models/storage-place.model';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../models/text-template.model';
import { IUploadedFile } from '../models/uploaded-file.model';
import { IUser, IUserDevice, IUserEditForm, IUserFilterForm } from '../models/user.model';
import { IServiceError } from '../services/api/api.service';
import { FilterQuery } from '../types/filter';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../models/accessory-product.model';
import {
	IArticleBirthdayBookEditForm,
	IArticleBirthdayBookFilterForm,
	IArticleBookEditForm,
	IArticleCdEditForm,
	IArticleCdFilterForm,
	IArticleCertificateEditForm,
	IArticleCertificateFilterForm,
	IArticleEditForm,
	IArticleFilterForm,
	IArticleStick,
	IBookFilterForm,
} from './../models/article.model';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../models/configuration.model';
import { ICustomerEditForm, ICustomerFilterForm } from './../models/customer.model';
import { IImageEditForm, IImageFilterForm } from './../models/image.model';
import { IMailTemplate, IMailTemplateEditForm, IMailTemplateFilterForm } from './../models/mailtemplate.model';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../models/media-product.model';
import {
	IPaymentBarzahlenEditForm,
	IPaymentBarzahlenFilterForm,
	IPaymentCashEditForm,
	IPaymentCashFilterForm,
	IPaymentCashOnDeliveryEditForm,
	IPaymentCashOnDeliveryFilterForm,
	IPaymentDirectDebitEditForm,
	IPaymentDirectDebitFilterForm,
	IPaymentEditForm,
	IPaymentFilterForm,
	IPaymentPaypalEditForm,
	IPaymentPaypalFilterForm,
} from './../models/payment.model';
import { IPermissionEditForm, IPermissionFilterForm } from './../models/permission.model';
import { IProcessEditForm, IProcessFilterForm } from './../models/process';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../models/process-node.model';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../models/process-route.model';
import { IProductEditForm, IProductFilterForm } from './../models/product.model';
import { IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../models/publication-cycle.model';
import { IPublisherEditForm, IPublisherFilterForm } from './../models/publisher.model';
import {
	IReceipt,
	IReceiptCancellation,
	IReceiptCancellationEditForm,
	IReceiptCancellationFilterForm,
	IReceiptCorrection,
	IReceiptCorrectionEditForm,
	IReceiptCorrectionFilterForm,
	IReceiptEditForm,
	IReceiptFilterForm,
	IReceiptInvoice,
	IReceiptInvoiceEditForm,
	IReceiptInvoiceFilterForm,
} from './../models/receipt';
import { IRegionEditForm, IRegionFilterForm } from './../models/region.model';
import {
	IShipping,
	IShippingDhl,
	IShippingDhlEditForm,
	IShippingDhlExpress,
	IShippingDhlExpressEditForm,
	IShippingDhlExpressFilterForm,
	IShippingDhlFilterForm,
	IShippingDhlPremium,
	IShippingDhlPremiumEditForm,
	IShippingDhlPremiumFilterForm,
	IShippingEditForm,
	IShippingFilterForm,
	IShippingPickup,
	IShippingPickupEditForm,
	IShippingPickupFilterForm,
	IShippingReturn,
	IShippingReturnEditForm,
	IShippingReturnFilterForm,
} from './../models/shipping.model';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm, IStockEditForm, IStockFilterForm, IStockMediaEditForm, IStockMediaFilterForm } from './../models/stock.model';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../models/storage-place.model';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../models/uploaded-file.model';

export interface AppState {
	aggregation: IAggregationState;
	accessoryProduct: IAccessoryProductState;
	attachment: IAttachmentState;

	article: IArticleState;
	articleYearBook: IArticleYearBookState;
	articleBirthdayBook: IArticleBirthdayBookState;
	articleBirthdayChronicle: IArticleBirthdayChronicleState;
	articleBook: IArticleBookState;
	articleCd: IArticleCdState;
	articleCertificate: IArticleCertificateState;
	articleChronicle: IArticleChronicleState;
	articleCoin: IArticleCoinState;
	articleDvd: IArticleDvdState;
	articleFolder: IArticleFolderState;
	articleStick: IArticleStickState;
	articleNewspaper: IArticleNewspaperState;
	articleMagazine: IArticleMagazineState;

	accountingSummary: IAccountingSummaryState;
	accountingSummaryAdvancement: IAccountingSummaryAdvancementState;
	accountingSummaryCustomer: IAccountingSummaryCustomerState;

	bundle: IBundleState;
	cart: ICartState;
	configuration: IConfigurationState;
	counter: ICounterState;
	customer: ICustomerState;
	collectionPurchaseTransfer: ICollectionPurchaseTransferState;

	deliveryNote: IDeliveryNoteState;
	deliveryNoteOrder: IDeliveryNoteOrderState;
	deliveryNoteReturn: IDeliveryNoteReturnState;
	document: IDocumentState;

	directDebitTransfer: IDirectDebitTransferState;
	device: IDeviceState;
	image: IImageState;
	mailTemplate: IMailTemplateState;
	mediaBrand: IMediaBrandState;
	mediaProduct: IMediaProductState;
	mediaStock: IStockMediaState;
	menu: IMenuState;
	message: IMessageState;
	order: IOrderState;

	payment: IPaymentState;
	paymentPaypal: IPaymentPaypalState;
	paymentBarzahlen: IPaymentBarzahlenState;
	paymentCash: IPaymentCashState;
	paymentCashOnDelivery: IPaymentCashOnDeliveryState;
	paymentPrepayment: IPaymentPrepaymentState;
	paymentPurchaseOnAccount: IPaymentPurchaseOnAccountState;
	paymentCollectionPurchase: IPaymentCollectionPurchaseState;
	paymentDirectDebit: IPaymentDirectDebitState;

	prediction: IPredictionState;
	partner: IPartnerState;
	page: IPageState;
	permission: IPermissionState;
	process: IProcessState;
	processNode: IProcessNodeState;
	processRoute: IProcessRouteState;
	product: IProductState;
	publicationCycle: IPublicationCycleState;
	publisher: IPublisherState;

	receipt: IReceiptState;
	receiptCorrection: IReceiptCorrectionState;
	receiptInvoice: IReceiptInvoiceState;
	receiptCancellation: IReceiptCancellationState;

	region: IRegionState;
	router: RouterReducerState;

	shippingDhl: IShippingDhlState;
	shippingReturn: IShippingReturnState;
	shippingDhlExpress: IShippingDhlExpressState;
	shippingDhlPremium: IShippingDhlPremiumState;
	shippingPickup: IShippingPickupState;

	stockAccessory: IStockAccessoryState;
	session: ISessionState;
	shipping: IShippingState;
	stock: IStockState;
	storagePlace: IStoragePlaceState;
	textTemplate: ITextTemplateState;
	uploadedFile: IUploadedFileState;
	user: IUserState;
}

export type Aggregation = { [key: string]: number };

export interface IAggregationState {
	orderCount: Aggregation;
	orderState: { [key: string]: Aggregation };
	receiptTotalPrice: Aggregation;
	receiptState: { [key: string]: Aggregation };
	from: string;
	until: string;
	granularity: string;
	error: IServiceError;
	isLoading: boolean;
}

export type Prediction = { [date: string]: number };

export interface IPredictionState {
	receiptTotalPrice: Prediction;
	error: IServiceError;
	isLoading: boolean;
}

export interface IDayRange {
	current: number;
	min: number;
	max: number;
}

export interface IMenuState {
	isMenuExpanded: boolean;
	testingAcknowledgedAt: string;
}

export interface IExternalLibrariesState {
	isGoogleMapsLoaded: boolean;
}

export interface ISessionState {
	isLoading: boolean;
	authToken: string;
	userForm: FormGroupState<IUser>;
	user: IUser;
	error: IServiceError;
	administrateProcessRoutes: boolean;
}

export interface IDeviceState {
	isLoading: boolean;
	loaded: boolean;
	forMachine: IUserDevice[];
	forUser: IUserDevice;
	userRegistered: boolean;
	machineId: string;
	error: IServiceError;
}

export interface IFilterDescriptor {
	isProgrammatic?: boolean;
	attributeName: string;
	operator: FilterOperator;
	value: any;
}

export type FilterConnection = 'AND' | 'OR';
export type FilterOperator = 'STARTSWITH' | 'CONTAINS' | 'GTE' | 'LTE' | 'GT' | 'LT' | 'IN' | 'EQ' | 'NOTCONTAINS' | 'NE' | 'DATEEQ' | 'CUSTOM' | 'ELEMMATCH';
export type FilterOperators<T> = { [K in keyof T]?: T[K] extends Array<infer U> ? FilterOperator : T[K] extends object ? FilterOperators<T[K]> : FilterOperator };
export type SortDescriptor = -1 | 1 | 'asc' | 'desc';
export type SortDescriptors<T> = { [index: string]: SortDescriptor };

export interface IProcessNodeCount {
	processNode: IProcessNode;
	count: number;
}

export interface IEntityState<TEntity extends IEntity, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm> {
	items: { [key: string]: TEntity };
	itemsFiltered: string[];
	itemsSuggested: string[];
	itemsInvalidated: string[];
	isFiltering: boolean;
	isUpdating: boolean;
	isSuggesting: boolean;
	isCounting: boolean;
	isFetching: boolean;
	error: IServiceError;
	selected: TEntity;
	pageIndex: number;
	pageSize: number;
	totalCount: number;
	initialValue: TEntity;
	initialEditFormValue: TEntityEditForm;
	initialFilterFormValue: TEntityFilterForm;
	editForm: FormGroupState<TEntityEditForm>;
	filterForm: FormArrayState<TEntityFilterForm>;
	filterOperatorForm: FormArrayState<FilterOperators<TEntity>>;
	sortDescriptors: SortDescriptors<TEntity>;
	filterConnection: FilterConnection;
	filterDescriptors: IFilterDescriptor[];
	filterQuery: FilterQuery<TEntity>;
	processNodeCounts: IProcessNodeCount[];
}

export interface IAccessoryProductState extends IEntityState<IAccessoryProduct, IAccessoryProductEditForm, IAccessoryProductFilterForm> {}
export interface IStockAccessoryState extends IEntityState<IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm> {}
export interface IArticleState extends IEntityState<IArticle, IArticleEditForm, IArticleFilterForm> {}
export interface IPaymentBarzahlenState extends IEntityState<IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {}
export interface IArticleBirthdayBookState extends IEntityState<IArticleBirthdayBook, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm>, ISearchableMediaArticle<IExpandedArticleBirthdayBook> {}
export interface IArticleBirthdayChronicleState extends IEntityState<IArticleBirthdayChronicle, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm>, ISearchableMediaArticle<IExpandedArticleBirthdayChronicle> {}
export interface IBundleState extends IEntityState<IBundle, IBundleEditForm, IBundleFilterForm> {}
export interface IPartnerState extends IEntityState<IPartner, IPartnerEditForm, IPartnerFilterForm> {}
export interface IPageState extends IEntityState<IPage, IPageEditForm, IPageFilterForm> {}
export interface IArticleBookState extends IEntityState<IArticleBook, IArticleBookEditForm, IBookFilterForm>, ISearchableMediaArticle<IExpandedArticleBook> {}

export interface IReceiptCancellationState extends IEntityState<IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {}
export interface IPaymentCashOnDeliveryState extends IEntityState<IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {}
export interface IPaymentCashState extends IEntityState<IPaymentCash, IPaymentCashEditForm, IPaymentCashFilterForm> {}
export interface IArticleCdState extends IEntityState<IArticleCd, IArticleCdEditForm, IArticleCdFilterForm>, ISearchableMediaArticle<IExpandedArticleCd> {}
export interface IArticleCertificateState extends IEntityState<IArticleCertificate, IArticleCertificateEditForm, IArticleCertificateFilterForm>, ISearchableArticle<IExpandedArticleCertificate> {
	expandedEditForm: FormGroupState<IExpandedArticleCertificateEditForm>;
}
export interface IArticleChronicleState extends IEntityState<IArticleChronicle, IArticleChronicleEditForm, IArticleChronicleFilterForm>, ISearchableMediaArticle<IExpandedArticleChronicle> {}
export interface IArticleCoinState extends IEntityState<IArticleCoin, IArticleCoinEditForm, IArticleCoinFilterForm>, ISearchableMediaArticle<IExpandedArticleCoin> {}
export interface IConfigurationState extends IEntityState<IConfiguration, IConfigurationEditForm, IConfigurationFilterForm> {}
export interface ICounterState extends IEntityState<ICounter, ICounterEditForm, ICounterFilterForm> {}
export interface IReceiptCorrectionState extends IEntityState<IReceiptCorrection, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {}
export interface ICustomerState extends IEntityState<ICustomer, ICustomerEditForm, ICustomerFilterForm> {}
export interface IShippingDhlExpressState extends IEntityState<IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {}
export interface IShippingDhlPremiumState extends IEntityState<IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {}
export interface IShippingDhlState extends IEntityState<IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm> {}
export interface IShippingReturnState extends IEntityState<IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm> {}
export interface IPaymentDirectDebitState extends IEntityState<IPaymentDirectDebit, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {}
export interface IArticleDvdState extends IEntityState<IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm>, ISearchableMediaArticle<IExpandedArticleDvd> {}
export interface IArticleFolderState extends IEntityState<IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm>, ISearchableArticle<IExpandedArticleFolder> {}
export interface IImageState extends IEntityState<IImage, IImageEditForm, IImageFilterForm> {}
export interface IDocumentState extends IEntityState<IDocument, IDocumentEditForm, IDocumentFilterForm> {}
export interface IAttachmentState extends IEntityState<IAttachment, IAttachmentEditForm, IAttachmentFilterForm> {}
export interface IReceiptInvoiceState extends IEntityState<IReceiptInvoice, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {}
export interface IArticleMagazineState extends IEntityState<IArticleMagazine, IArticleMagazineEditForm, IArticleMagazineFilterForm>, ISearchableMediaArticle<IExpandedArticleMagazine> {}
export interface IMailTemplateState extends IEntityState<IMailTemplate, IMailTemplateEditForm, IMailTemplateFilterForm> {}
export interface IMediaBrandState extends IEntityState<IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm> {}
export interface IMediaProductState extends IEntityState<IMediaProduct, IMediaProductEditForm, IMediaProductFilterForm> {}
export interface IStockMediaState extends IEntityState<IStockMedia, IStockMediaEditForm, IStockMediaFilterForm> {}
export interface IArticleNewspaperState extends IEntityState<IArticleNewspaper, IArticleNewspaperEditForm, IArticleNewspaperFilterForm>, ISearchableMediaArticle<IExpandedArticleNewspaper> {}
export interface IOrderState extends IEntityState<IOrder, IOrderEditForm, IOrderFilterForm> {
	searchValue: string;
	searchLimit: number;
	itemsSearched: string[];
	totalCountSearched: number;
	isSearching: boolean;
	warnings: IOrderSearchWarning[];
}
export interface IMessageState extends IEntityState<IMessage, IMessageEditForm, IMessageFilterForm> {}
export interface IPaymentState extends IEntityState<IPayment, IPaymentEditForm, IPaymentFilterForm> {}
export interface IPaymentPaypalState extends IEntityState<IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {}
export interface IPermissionState extends IEntityState<IPermission, IPermissionEditForm, IPermissionFilterForm> {}
export interface IShippingPickupState extends IEntityState<IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm> {}
export interface IPaymentPrepaymentState extends IEntityState<IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {}
export interface IProcessNodeState extends IEntityState<IProcessNode, IProcessNodeEditForm, IProcessNodeFilterForm> {}
export interface IProcessRouteState extends IEntityState<IProcessRoute, IProcessRouteEditForm, IProcessRouteFilterForm> {}
export interface IProcessState extends IEntityState<IProcess, IProcessEditForm, IProcessFilterForm> {}
export interface IProductState extends IEntityState<IProduct, IProductEditForm, IProductFilterForm> {}
export interface IPublicationCycleState extends IEntityState<IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm> {}
export interface ITextTemplateState extends IEntityState<ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm> {}
export interface IPublisherState extends IEntityState<IPublisher, IPublisherEditForm, IPublisherFilterForm> {}
export interface IPaymentPurchaseOnAccountState extends IEntityState<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {}
export interface IPaymentCollectionPurchaseState extends IEntityState<IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {}
export interface IReceiptState extends IEntityState<IReceipt, IReceiptEditForm, IReceiptFilterForm> {}
export interface IRegionState extends IEntityState<IRegion, IRegionEditForm, IRegionFilterForm> {
	name: string;
	itemsSearched: string[];
	isSearching: boolean;
}
export interface IShippingState extends IEntityState<IShipping, IShippingEditForm, IShippingFilterForm> {}
export interface IArticleStickState extends IEntityState<IArticleStick, IArticleStickEditForm, IArticleStickFilterForm>, ISearchableArticle<IExpandedArticleStick> {}
export interface IStockState extends IEntityState<IStock, IStockEditForm, IStockFilterForm> {}
export interface IDirectDebitTransferState extends IEntityState<IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {}
export interface ICollectionPurchaseTransferState extends IEntityState<ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {}
export interface IAccountingSummaryState extends IEntityState<IAccountingSummary<IAccountingSummaryPosition>, IAccountingSummaryEditForm<IAccountingSummaryPosition>, IAccountingSummaryFilterForm<IAccountingSummaryPosition>> {}
export interface IAccountingSummaryAdvancementState extends IEntityState<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm> {}
export interface IAccountingSummaryCustomerState extends IEntityState<IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm> {}
export interface IDeliveryNoteState extends IEntityState<IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {}
export interface IDeliveryNoteOrderState extends IEntityState<IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {}
export interface IDeliveryNoteReturnState extends IEntityState<IDeliveryNoteReturn, IDeliveryNoteReturnEditForm, IDeliveryNoteReturnFilterForm> {}
export interface IStoragePlaceState extends IEntityState<IStoragePlace, IStoragePlaceEditForm, IStoragePlaceFilterForm> {}
export interface IUploadedFileState extends IEntityState<IUploadedFile, IUploadedFileEditForm, IUploadedFileFilterForm> {}
export interface IUserState extends IEntityState<IUser, IUserEditForm, IUserFilterForm> {}
export interface IArticleYearBookState extends IEntityState<IArticleYearBook, IArticleYearBookEditForm, IArticleYearBookFilterForm>, ISearchableMediaArticle<IArticleExpandedYearBook> {}

export interface ISearchableArticle<TExpandedArticle extends IExpandedArticle> {
	isSearching: boolean;
	isExtending: boolean;
	itemsSearched: TExpandedArticle[];
	paging: IPaging;
}
export interface ISearchableMediaArticle<TExpandedArticle extends IExpandedMediaArticle> extends ISearchableArticle<TExpandedArticle> {
	dayRange: IDayRange;
}

export type ICartState = ICart & {
	selected: IPosition;
	isLoading: boolean;
	isDisabled: boolean;
	error: IServiceError;
	isReceiverDiffering?: boolean;
	isPackstation?: boolean;
};

export interface IOrderSearchWarning {
	customerId: string;
	type: string;
	message: string;
}

export class EntityState {
	public static create<TEntity extends IEntity, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm>(
		identifier: string,
		initialValue: TEntity,
		initialEditFormValue: TEntityEditForm,
		initialFilterFormValue: TEntityFilterForm,
		initialFilterOperators: FilterOperators<TEntity>[] = [{}, {}],
		initialSortDescriptors: SortDescriptors<TEntity> = {}
	): IEntityState<TEntity, TEntityEditForm, TEntityFilterForm> {
		const editForm = createFormGroupState<TEntityEditForm>(`${identifier}_EDIT`, initialEditFormValue);
		const filterForm = createFormArrayState<TEntityFilterForm>(`${identifier}_FILTER`, [initialFilterFormValue, initialFilterFormValue]);
		const filterOperatorForm = createFormArrayState<FilterOperators<TEntity>>(`${identifier}_FILTER_OPERATOR`, initialFilterOperators.length > 1 ? initialFilterOperators : [initialFilterOperators[0], initialFilterOperators[0]]);

		return {
			items: {},
			itemsFiltered: [],
			itemsSuggested: [],
			itemsInvalidated: [],
			initialValue,
			initialEditFormValue,
			initialFilterFormValue,
			editForm,
			filterForm,
			filterOperatorForm,
			sortDescriptors: initialSortDescriptors,
			filterDescriptors: [],
			filterQuery: {},
			filterConnection: 'AND',
			pageIndex: 0,
			totalCount: 0,
			pageSize: 25,
			selected: null,
			isFiltering: false,
			isUpdating: false,
			isSuggesting: false,
			isCounting: false,
			isFetching: false,
			error: null,
			processNodeCounts: [],
		};
	}
}
