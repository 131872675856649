import { Injectable } from '@angular/core';
import { Action, ActionCreator, ActionReducer, createReducer, on, ReducerTypes } from '@ngrx/store';
import _ from 'lodash';
import { onNgrxForms, StateUpdateFns, updateGroup, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState, IFilterDescriptor } from './app.state';
import { IEntityActions } from './entity.actions';

@Injectable({
	providedIn: 'root',
})
export class EntityReducer {
	public static create<TEntity extends IEntity, TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm>(
		initialState: TEntityState,
		validation: StateUpdateFns<TEntityEditForm>,
		actions: IEntityActions<TEntity, TEntityEditForm>,
		...ons: ReducerTypes<TEntityState, readonly ActionCreator[]>[]
	): ActionReducer<TEntityState, Action> {
		return wrapReducerWithFormStateUpdate(
			createReducer(
				initialState,
				onNgrxForms(),

				on(actions.fetch, (state, { ids }) => ({
					...state,
					error: initialState.error,
					isFetching: true,
				})),
				on(actions.fetched, (state, { entities }) => ({
					...state,
					error: initialState.error,
					isFetching: false,
					items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
					selected: state.selected != null && entities.some(x => x._id == state.selected._id) ? entities.find(x => x._id == state.selected._id) : state.selected,
					itemsInvalidated: state.itemsInvalidated.filter(x => !entities.some(y => y._id === x)),
				})),
				on(actions.missed, (state, { ids }) => ({
					...state,
					items: { ...state.items, ...ids.filter(x => x != null).reduce((x, id) => ({ ...x, [id]: null }), {}) },
					isFetching: false,
					selected: state.selected != null && ids.some(id => id == state.selected._id) ? null : state.selected,
				})),
				on(actions.invalidated, (state, { ids }) => ({
					...state,
					itemsInvalidated: ids.filter(id => state.items[id] != null),
				})),
				on(actions.filter, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.filtered, (state, { entities, totalCount }) => ({
					...state,
					error: initialState.error,
					isFiltering: false,
					totalCount,
					pageIndex: 0,
					items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
					itemsFiltered: entities.map(entity => entity._id),
				})),
				on(actions.increase, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.increased, (state, { entities, pageIndex }) => ({
					...state,
					error: initialState.error,
					isFiltering: false,
					pageIndex,
					items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
					itemsFiltered: _.uniq([...state.itemsFiltered, ...entities.map(entity => entity._id)]),
				})),
				on(actions.suggest, state => ({
					...state,
					error: initialState.error,
					isSuggesting: true,
				})),
				on(actions.suggested, (state, { entities }) => ({
					...state,
					error: initialState.error,
					isSuggesting: false,
					items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
					itemsSuggested: entities.map(entity => entity._id),
				})),
				on(actions.failed, (state, { error }) => ({
					...state,
					isFiltering: false,
					isFetching: false,
					isSuggesting: false,
					isUpdating: false,
					error,
				})),
				on(actions.selected, (state, { selected }) => ({
					...state,
					selected,
				})),

				on(actions.filterConnectionChanged, (state, { filterConnection }) => ({
					...state,
					filterConnection,
				})),
				on(actions.filterDescriptorChanged, (state, { filterDescriptors, ignoreProgrammatic }) => ({
					...state,
					error: initialState.error,
					filterDescriptors: EntityReducer.mergeFilterDescriptors(state.filterDescriptors, filterDescriptors, ignoreProgrammatic),
				})),
				on(actions.filterQueryChanged, (state, { filterQuery }) => ({
					...state,
					isFiltering: true,
					filterQuery,
				})),
				on(actions.sortDescriptorChanged, (state, { sortDescriptors }) => ({
					...state,
					isFiltering: true,
					sortDescriptors,
				})),
				on(actions.pageChanged, (state, { pageIndex, pageSize }) => ({
					...state,
					isFiltering: true,
					pageIndex,
					pageSize,
				})),

				on(actions.createForm, state => ({
					...state,
					selected: state.initialValue,
				})),
				on(actions.createdForm, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.created, (state, { entity }) => ({
					...state,
					isFiltering: false,
					selected: entity,
					items: { ...state.items, [entity._id]: entity },
				})),

				on(actions.updateForm, (state, { entity }) => ({
					...state,
					selected: entity,
				})),
				on(actions.updatedForm, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.updateAssignee, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.updateProcess, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.revertProcess, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.updated, (state, { entity }) => ({
					...state,
					isFiltering: false,
					isUpdating: false,
					selected: entity,
					items: { ...state.items, [entity._id]: entity },
				})),

				on(actions.removeForm, (state, { entity }) => ({
					...state,
					selected: entity,
				})),
				on(actions.removedForm, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),
				on(actions.removed, (state, { entity }) => {
					const items = { ...state.items };
					delete items[entity._id];

					return {
						...state,
						isFiltering: false,
						selected: initialState.selected,
						itemsFiltered: state.itemsFiltered.filter(id => id != entity._id),
						itemsSuggested: state.itemsSuggested.filter(id => id != entity._id),
						items,
					};
				}),
				on(actions.abortedForm, state => ({
					...state,
					selected: null,
				})),

				on(actions.previewForm, (state, { entity }) => ({
					...state,
					selected: entity,
				})),
				on(actions.previewedForm, state => ({
					...state,
					error: initialState.error,
					isFiltering: true,
				})),

				on(actions.count, state => ({
					...state,
					error: initialState.error,
					isCounting: true,
				})),
				on(actions.counted, (state, { processNodeCounts }) => ({
					...state,
					error: initialState.error,
					isCounting: false,
					processNodeCounts,
				})),

				...ons
			),
			state => state.editForm,
			updateGroup<TEntityEditForm>(validation)
		);
	}

	public static mergeFilterDescriptors(originalFilterDescriptors: IFilterDescriptor[], filterDescriptors: IFilterDescriptor[], ignoreProgrammatic: boolean = false): IFilterDescriptor[] {
		const result: IFilterDescriptor[] = [];

		if (ignoreProgrammatic) {
			return filterDescriptors;
		}

		for (const filterDescriptor of filterDescriptors) {
			const originalFilterDescriptor = originalFilterDescriptors.find(x => x.attributeName == filterDescriptor.attributeName);
			const isProgrammatic = originalFilterDescriptor?.isProgrammatic || filterDescriptor.isProgrammatic == null || filterDescriptor.isProgrammatic;

			result.push({ ...filterDescriptor, isProgrammatic });
		}

		const previousProgrammaticFilterDescriptors = originalFilterDescriptors.filter(x => x.isProgrammatic && filterDescriptors.find(y => x.attributeName == y.attributeName) == null);
		return [...result, ...previousProgrammaticFilterDescriptors];
	}
}
