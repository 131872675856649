import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleYearBook, IArticleYearBookEditForm, IArticleYearBookFilterForm } from '../../models/article.model';
import { AppState, IArticleYearBookState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleYearBookSelector extends EntitySelector<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	protected stateSelector = (state: AppState): IArticleYearBookState => state.articleYearBook;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public isExtending = createSelector(this.selectState, state => state.isExtending);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public paging = createSelector(this.selectState, state => state.paging);
	public dayRange = createSelector(this.selectState, state => state.dayRange);
}
