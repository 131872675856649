import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm } from '../../models/article.model';
import { AppState, IArticleFolderState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ArticleFolderSelector extends EntitySelector<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	protected stateSelector = (state: AppState): IArticleFolderState => state.articleFolder;

	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
}
