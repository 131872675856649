import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CancellationDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-cancellation.component';
import { CancellationDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-cancellation.component';
import { CancellationDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-cancellation.component';
import { IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from '../../models/receipt';
import { IReceiptCancellationState } from '../../state/app.state';
import { CancellationFacade } from '../../state/receipt.cancellation/receipt.cancellation.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ReceiptCancellationDialogService extends EntityDialogService<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	protected createComponent: ComponentType<any> = CancellationDialogAddComponent;
	protected updateComponent: ComponentType<any> = CancellationDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CancellationDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CancellationFacade) {
		super(matDialog, entityFacade);
	}
}
