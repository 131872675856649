<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Block</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-select placeholder="Typ" formControlName="blockKind">
						<mat-option *ngFor="let blockKind of BlockKinds | keyvalue" [value]="blockKind.value">{{ blockKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-card class="box mat-form-field--span">
					<span>Parameter</span>
					<mat-card-content>
						<pre>{{ data.parameters | json }}</pre>
					</mat-card-content>
				</mat-card>
				<mat-card class="box mat-form-field--span">
					<span>URL Parameter</span>
					<mat-card-content>
						<pre>{{ data.queryParameters | json }}</pre>
					</mat-card-content>
				</mat-card>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
