import { Injectable } from '@angular/core';
import { ICounter } from 'src/models/counter.model';
import { ICounterEditForm, ICounterFilterForm } from '../../models/counter.model';
import { AppState, ICounterState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CounterSelector extends EntitySelector<ICounter, ICounterState, ICounterEditForm, ICounterFilterForm> {
	protected stateSelector = (state: AppState): ICounterState => state.counter;
}
