import { Boxed } from 'ngrx-forms';
import { IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntity } from './entity.model';

export interface ICustomer extends IEntity {
	number: number;
	salutationKind: string;
	firstName: string;
	lastName: string;
	email: string;
	sessions: string[];
	telephone?: string;
	group?: string;
}

export interface ICustomerEditForm extends IEntityEditForm {
	number: number;
	salutationKind: string;
	firstName: string;
	lastName: string;
	email: string;
	sessions: Boxed<string[]>;
	telephone?: string;
	group?: string;
}

export interface ICustomerFilterForm extends IEntityFilterForm {
	number: number;
	salutationKind: string;
	firstName: string;
	lastName: string;
	email: string;
	sessions: Boxed<string[]>;
	telephone?: string;
	group?: string;
}

export enum CustomerTypeEnum {
	STANDARD = 'Standard',
	BUSINESS = 'Business',
	PARTNER = 'Partner',
}
