import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IPosition } from '../../models/position.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-positioneditdialog',
	templateUrl: './dialog-position-edit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PositionEditDialogComponent extends DialogComponent<PositionEditDialogComponent, IPosition> {
	public PositionKinds = PositionKind;

	constructor(matDialogRef: MatDialogRef<PositionEditDialogComponent, IPosition>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPosition) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				title: [data.title, [Validators.required]],
				positionKind: [{ value: data.positionKind, disabled: true }, [Validators.required]],
				price: [data.price, [Validators.required]],
			})
		);
	}
}
