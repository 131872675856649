<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Geschenkurkunde ändern</h1>
	<div mat-dialog-content>
		<mat-tab-group>
			<div mat-dialog-content>
				<mat-tab label="Artikel">
					<form [ngrxFormState]="editForm">
						<div class="form-grid">
							<babylon-accessory-product-autocompletesingle [value]="editForm.controls.product.value" [controlId]="editForm.controls.product.id"> </babylon-accessory-product-autocompletesingle>
							<babylon-storage-place-autocompletesingle [value]="editForm.controls.storagePlace.value" [controlId]="editForm.controls.storagePlace.id"> </babylon-storage-place-autocompletesingle>
							<babylon-accessory-stock-autocompletesingle [value]="editForm.controls.stock.value" [controlId]="editForm.controls.stock.id"> </babylon-accessory-stock-autocompletesingle>
							<mat-form-field appearance="outline">
								<mat-label>Qualität</mat-label>
								<mat-select matInput [ngrxFormControlState]="editForm.controls.quality">
									<mat-option [value]="5">Sehr gut</mat-option>
									<mat-option [value]="4">Gut</mat-option>
									<mat-option [value]="3">Befriedigend</mat-option>
									<mat-option [value]="2">Ausreichend</mat-option>
									<mat-option [value]="1">Mangelhaft</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</form>
				</mat-tab>
				<mat-tab label="Details">
					<form [ngrxFormState]="editForm" class="certificate-form">
						<div class="form-grid">
							<mat-form-field appearance="outline">
								<mat-label>Jubiläum</mat-label>
								<input type="number" matInput [ngrxFormControlState]="editForm.controls.anniversary" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Geschenkdatum</mat-label>
								<input matInput [matDatepicker]="date" [ngrxFormControlState]="editForm.controls.date" [ngrxValueConverter]="dateToISOStringConverter" />
								<mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
								<mat-datepicker #date></mat-datepicker>
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Geschenkanlass</mat-label>
								<mat-select [ngrxFormControlState]="editForm.controls.occasion">
									<mat-option [value]="occasionType.value" *ngFor="let occasionType of OccasionTypes | keyvalue">{{ occasionType.value }}</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Eigener Anlass</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.customOccasion" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Anredeform</mat-label>
								<mat-select [ngrxFormControlState]="editForm.controls.addressForm" (selectionChange)="changedAddressForm($event)">
									<mat-option [value]="adressFormType.value" *ngFor="let adressFormType of AdressFormTypes | keyvalue">{{ adressFormType.value }}</mat-option>
								</mat-select>
							</mat-form-field>
							<div></div>
							<mat-form-field appearance="outline">
								<mat-label>Beschenkte*r*</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.giftee" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Zus. Namensfeld Beschenkte*r*</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.gifteeAdditionalName" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Geburtsname</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.gifteeBirthName" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Partner/-in</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.partner" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Zus. Namensfeld Partner/-in</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.partnerAdditionalName" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Geburtsname Partner/-in</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.partnerBirthName" />
							</mat-form-field>
							<div class="editor">
								<span class="mat-caption">Text</span>
								<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.text"></ckeditor>
							</div>
							<mat-form-field appearance="outline" class="mat-form-field--span">
								<mat-label>Glückwunschtext</mat-label>
								<textarea matInput cdkTextareaAutosize rows="5" [ngrxFormControlState]="editForm.controls.message"></textarea>
							</mat-form-field>
						</div>
						<div>
							<div class="certificate-form--certificate-wrapper" *ngIf="entityFacade.editFormValue$ | async as certificate">
								<div class="certificate__image">
									<div
										class="certificate__image--frame"
										[class.certificate__image--frame-anniversary]="certificate.anniversary != null"
										[class.certificate__image--frame-xmas]="certificate.occasion == OccasionTypes.Christmas"></div>
									<div class="certificate__image--text">
										<div class="certificate__image--text-year">{{ certificate.occasion != OccasionTypes.Christmas ? certificate.anniversary || '&nbsp;' : '&nbsp;' }}</div>
										<div class="certificate__image--text-occasion">
											<div *ngIf="certificate.occasion == OccasionTypes.Custom; else elseBlock" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
												{{ certificate.customOccasion }}
											</div>
											<ng-template #elseBlock>
												<div *ngIf="certificate.occasion == OccasionTypes.Birthday" class="birthday" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
													Zum<br />Geburtstag
												</div>
												<div *ngIf="certificate.occasion == OccasionTypes.Silver" class="silver" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
													Zur<br />Silberhochzeit
												</div>
												<div *ngIf="certificate.occasion == OccasionTypes.Gold" class="golden" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
													Zur<br />Goldenen Hochzeit
												</div>
												<div *ngIf="certificate.occasion == OccasionTypes.Diamond" class="diamond" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
													Zur<br />Diamantenen Hochzeit
												</div>
												<div *ngIf="certificate.occasion == OccasionTypes.Retirement" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">Zum<br />Ruhestand</div>
												<div *ngIf="certificate.occasion == OccasionTypes.Christmas" class="xmas" [ngStyle]="{ 'font-size': 'calc(var(--fs-occasion) *' + editForm.controls.fontSizeOccasion.value + ')' }">
													Zu Weihnachten
												</div>
											</ng-template>
										</div>
										<div class="certificate__image--text-for">für</div>
										<div class="certificate__image--text-giftee" [ngStyle]="{ 'font-size': 'calc(var(--fs-giftee) *' + editForm.controls.fontSizeGiftee.value + ')' }">{{ certificate.giftee || 'Alex Glückskind' }}</div>
										<div class="certificate__image--text-giftee-addname" *ngIf="certificate.gifteeAdditionalName" [ngStyle]="{ 'font-size': 'calc(var(--fs-giftee) *' + editForm.controls.fontSizeGiftee.value + ')' }">
											{{ certificate.gifteeAdditionalName }}
										</div>
										<div
											class="certificate__image--text-giftee-birthname"
											*ngIf="certificate.gifteeBirthName"
											[ngStyle]="{ 'font-size': 'calc(var(--fs-giftee-birthname) *' + editForm.controls.fontSizeGifteeBirthName.value + ')' }">
											geb. {{ certificate.gifteeBirthName }}
										</div>
										<div
											class="certificate__image--text-partner"
											*ngIf="(certificate.occasion == OccasionTypes.Silver || certificate.occasion == OccasionTypes.Gold || certificate.occasion == OccasionTypes.Diamond) && certificate.partner"
											[ngStyle]="{ 'font-size': 'calc(var(--fs-partner) *' + editForm.controls.fontSizePartner.value + ')' }">
											{{ certificate.partner }}
										</div>
										<div
											class="certificate__image--text-partner-addname"
											*ngIf="(certificate.occasion == OccasionTypes.Silver || certificate.occasion == OccasionTypes.Gold || certificate.occasion == OccasionTypes.Diamond) && certificate.partner"
											[ngStyle]="{ 'font-size': 'calc(var(--fs-partner) *' + editForm.controls.fontSizePartner.value + ')' }">
											{{ certificate.partnerAdditionalName }}
										</div>
										<div
											class="certificate__image--text-partner-birthname"
											*ngIf="
												(certificate.occasion == OccasionTypes.Silver || certificate.occasion == OccasionTypes.Gold || certificate.occasion == OccasionTypes.Diamond) &&
												certificate.partner &&
												certificate.partnerBirthName
											"
											[ngStyle]="{ 'font-size': 'calc(var(--fs-partner-birthname) *' + editForm.controls.fontSizePartnerBirthName.value + ')' }">
											geb. {{ certificate.partnerBirthName }}
										</div>
										<div class="certificate__image--text-date">
											<span *ngIf="certificate.occasion == OccasionTypes.Birthday || certificate.occasion == OccasionTypes.Christmas">geboren am </span>
											<span *ngIf="certificate.occasion == OccasionTypes.Silver || certificate.occasion == OccasionTypes.Gold || certificate.occasion == OccasionTypes.Diamond"><span class="rings">OO</span> am </span>
											{{ certificate.date | amDateFormat: 'D. MMMM YYYY' }}
										</div>
										<div class="certificate__image--text-default" [innerHtml]="certificate.text | safe" [ngStyle]="{ 'font-size': 'calc(var(--fs-text) *' + editForm.controls.fontSizeText.value + ')' }"></div>
										<div class="certificate__image--text-message" [ngStyle]="{ 'font-size': 'calc(var(--fs-message) *' + editForm.controls.fontSizeMessage.value + ')' }">
											{{ certificate.message }}
										</div>
									</div>
								</div>
							</div>
							<div class="font-sizes-grid">
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Anlass</small></label>
										<label>
											<small>{{ editForm.controls.fontSizeOccasion.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizeOccasion" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Beschenkte*r</small></label>
										<label>
											<small>{{ editForm.controls.fontSizeGiftee.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizeGiftee" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Geburtsname</small></label>
										<label>
											<small>{{ editForm.controls.fontSizeGifteeBirthName.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizeGifteeBirthName" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Partner/-in</small></label>
										<label>
											<small>{{ editForm.controls.fontSizePartner.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizePartner" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Geb. Partner/-in</small></label>
										<label>
											<small>{{ editForm.controls.fontSizePartnerBirthName.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizePartnerBirthName" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Text</small></label>
										<label>
											<small>{{ editForm.controls.fontSizeText.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizeText" />
								</div>
								<div class="fs-display">
									<div class="fs-display--label">
										<label><small>Schriftgröße Glückwunschtext</small></label>
										<label>
											<small>{{ editForm.controls.fontSizeMessage.value }}</small>
										</label>
									</div>
									<input type="range" min="0.75" max="1.25" step="0.05" [ngrxFormControlState]="editForm.controls.fontSizeMessage" />
								</div>
							</div>
						</div>
					</form>
				</mat-tab>
				<mat-tab label="Historie">
					<babylon-certificatehistorytable controlId="processInstance.actions"></babylon-certificatehistorytable>
				</mat-tab>
			</div>
		</mat-tab-group>
	</div>
	<div mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</div>
</ng-container>
