import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CdAddDialogComponent } from '../../components/entitydialog/entitydialog-cd-add.component';
import { CdReplaceDialogComponent } from '../../components/entitydialog/entitydialog-cd-replace.component';
import { CdDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-cd.component';
import { CdDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-cd.component';
import { CdDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-cd.component';
import { IArticleCd } from '../../models/article.model';
import { IArticleCdState } from '../../state/app.state';
import { ArticleCdFacade } from '../../state/article.cd/article.cd.facade';
import { IArticleCdEditForm, IArticleCdFilterForm } from './../../models/article.model';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleCdDialogService extends EntityDialogService<IArticleCd, IArticleCdState, IArticleCdEditForm, IArticleCdFilterForm> {
	protected createComponent: ComponentType<any> = CdDialogAddComponent;
	protected updateComponent: ComponentType<any> = CdDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CdDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = CdReplaceDialogComponent;
	protected addComponent: ComponentType<any> = CdAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleCdFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleCd>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleCd>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
