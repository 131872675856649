<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="aggregationFacade.isLoading$ | async"></mat-progress-bar>
<div class="container" *ngIf="sessionFacade.user$ | async as user">
	<p class="title">Hallo {{ user.name }}</p>
	<ng-container *ngIf="permissionFacade.hasPermission('admin')">
		<div>
			<mat-form-field appearance="outline">
				<mat-label>Zeitraum</mat-label>
				<mat-select [value]="aggregationFacade.timespan$ | async" [compareWith]="compareTimespan" (selectionChange)="aggregationFacade.changeTimespan($event.value)">
					<mat-option [value]="aggregationFacade.thisWeek">Aktuelle Woche</mat-option>
					<mat-option [value]="aggregationFacade.lastWeek">Letzte Woche</mat-option>
					<mat-option [value]="aggregationFacade.thisMonth">Aktueller Monat</mat-option>
					<mat-option [value]="aggregationFacade.lastMonth">Letzter Monat</mat-option>
					<mat-option [value]="aggregationFacade.thisYear">Aktuelles Jahr</mat-option>
					<mat-option [value]="aggregationFacade.lastYear">Letztes Jahr</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="chart">
			<canvas baseChart [data]="conversionData$ | async" [options]="conversionOptions" type="line"></canvas>
		</div>

		<div>
			<mat-form-field appearance="outline">
				<mat-label>Auftragszustand</mat-label>
				<mat-select (selectionChange)="aggregationFacade.loadOrderState($event.value.displayName, $event.value.routeName)">
					<mat-option [value]="{ displayName: 'Abgeschlossen', routeName: 'CompleteOrder' }">Abgeschlossen</mat-option>
					<mat-option [value]="{ displayName: 'In Wiedervorlage', routeName: 'RepreviewOrder' }">In Wiedervorlage</mat-option>
				</mat-select>
			</mat-form-field>
			&nbsp;
			<mat-form-field appearance="outline">
				<mat-label>Belegzustand</mat-label>
				<mat-select (selectionChange)="aggregationFacade.loadReceiptState($event.value.displayName, $event.value.routeName)">
					<mat-option [value]="{ displayName: 'Festgeschrieben', routeName: 'CommitReceipt' }">Festgeschrieben</mat-option>
					<mat-option [value]="{ displayName: 'Fertiggestellt', routeName: 'CompleteReceipt' }">Fertiggestellt</mat-option>
					<mat-option [value]="{ displayName: 'Zahlungserinnerung', routeName: 'FollowUpReceipt' }">Zahlungserinnerung</mat-option>
					<mat-option [value]="{ displayName: 'Mahnung', routeName: 'FollowUpFirstReminderReceipt' }">Mahnung</mat-option>
					<mat-option [value]="{ displayName: 'Inkasso', routeName: 'FollowUpSecondReminderReceipt' }">Inkasso</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="chart" *ngIf="aggregationFacade.showOrderState$ | async">
			<canvas baseChart [data]="aggregationFacade.orderStateData$ | async" [options]="aggregationFacade.orderStateOptions$ | async" type="line"></canvas>
		</div>

		<div class="chart" *ngIf="aggregationFacade.showReceiptState$ | async">
			<canvas baseChart [data]="aggregationFacade.receiptStateData$ | async" [options]="aggregationFacade.receiptStateOptions$ | async" type="line"></canvas>
		</div>
	</ng-container>
</div>
