import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleDvdApiService } from '../../services/api/article.dvd.service';
import { ArticleDvdDialogService } from '../../services/dialog/article.dvd.service';
import { ArticleDvdNotificationService } from '../../services/notification/article.dvd.service';
import { AppState, IArticleDvdState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleDvdActions } from './article.dvd.actions';
import { ArticleDvdSelector } from './article.dvd.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleDvdEffects extends EntityEffects<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleDvdApiService,
		notificationService: ArticleDvdNotificationService,
		dialogService: ArticleDvdDialogService,
		private selector: ArticleDvdSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleDvdActions, 'DVD');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleDvdActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Dvd,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleDvdActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleDvdActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleDvdActions.dayRangeChanged, fromArticleDvdActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleDvdActions.extended());
				}

				return this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Dvd,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleDvdActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleDvdActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleDvdActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleDvdActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleDvdActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
