import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IImage } from 'src/models/image.model';
import { ImageApiService } from 'src/services/api/image.service';
import { ImageDialogService } from 'src/services/dialog/image.service';
import { ImageNotificationService } from 'src/services/notification/image.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AppState, IImageState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IImageEditForm, IImageFilterForm } from './../../models/image.model';
import { fromImageActions } from './image.actions';
import { ImageSelector } from './image.selectors';

@Injectable({
	providedIn: 'root',
})
export class ImageEffects extends EntityEffects<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ImageApiService,
		notificationService: ImageNotificationService,
		dialogService: ImageDialogService,
		private selector: ImageSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromImageActions, 'IMAGE');
	}

	public onCreatedImageForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromImageActions.createdImageForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.createImage(entity, file, authToken).pipe(
					map(result => fromImageActions.created({ entity })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdatedImageForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromImageActions.updatedImageForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.updateImage(entity, file, authToken).pipe(
					map(result => fromImageActions.updated({ entity })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);
}
