import { EntityState, ICustomerState } from '../app.state';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';

import { EntityReducer } from '../entity.reducer';
import { ICustomer } from '../../models/customer.model';
import { box } from 'ngrx-forms';
import { fromCustomerActions } from './customer.actions';

export const initialValue: ICustomer = {
	_id: null,
	processInstance: null,
	email: null,
	number: null,
	firstName: null,
	lastName: null,
	sessions: [],
	salutationKind: null,
	telephone: null,
	group: null,
};

export const initialEditFormValue: ICustomerEditForm = {
	_id: null,
	processInstance: null,
	email: null,
	number: null,
	firstName: null,
	lastName: null,
	sessions: box([]),
	salutationKind: null,
	telephone: null,
	group: null,
};

export const initialFilterFormValue: ICustomerFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	email: null,
	number: null,
	firstName: null,
	lastName: null,
	sessions: box([]),
	salutationKind: null,
	telephone: null,
	group: null,
};

export const initialState = EntityState.create<ICustomer, ICustomerEditForm, ICustomerFilterForm>(
	'CUSTOMER',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			number: 'EQ',
			firstName: 'CONTAINS',
			lastName: 'CONTAINS',
			email: 'CONTAINS',
			telephone: 'CONTAINS',
		},
	],
	{
		number: -1,
	}
);

export const customerReducer = EntityReducer.create<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm>(initialState, {}, fromCustomerActions);
