<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Bestand hinzufügen</h1>
	<mat-dialog-content>
		<p class="text-warning">Es können maximal 400 Artikel durch einen Bestand angelegt werden.</p>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<babylon-media-product-autocompletesingle [value]="editForm.controls.product.value" [controlId]="editForm.controls.product.id"></babylon-media-product-autocompletesingle>
				<mat-form-field appearance="outline">
					<mat-label>Anzahl</mat-label>
					<input type="number" matInput [ngrxFormControlState]="editForm.controls.quantity" />
				</mat-form-field>
				<div class="form-subgrid">
					<mat-form-field appearance="outline">
						<mat-label>Von</mat-label>
						<input matInput [matDatepicker]="from" [ngrxFormControlState]="editForm.controls.from" [ngrxValueConverter]="dateToISOStringConverter" />
						<mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
						<mat-datepicker #from></mat-datepicker>
					</mat-form-field>
					<mat-form-field appearance="outline">
						<mat-label>Bis</mat-label>
						<input matInput [matDatepicker]="until" [ngrxFormControlState]="editForm.controls.until" [ngrxValueConverter]="dateToISOStringConverter" />
						<mat-datepicker-toggle matSuffix [for]="until"></mat-datepicker-toggle>
						<mat-datepicker #until></mat-datepicker>
					</mat-form-field>
				</div>
				<mat-form-field appearance="outline">
					<mat-label>Qualität</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.quality">
						<mat-option [value]="5">Sehr gut</mat-option>
						<mat-option [value]="4">Gut</mat-option>
						<mat-option [value]="3">Befriedigend</mat-option>
						<mat-option [value]="2">Ausreichend</mat-option>
						<mat-option [value]="1">Mangelhaft</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Bestandstyp</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.stockType">
						<mat-option *ngFor="let option of StockTypes | keyvalue" [value]="option.value">{{ option.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<babylon-storage-place-autocompletesingle [value]="editForm.controls.storagePlace.value" [controlId]="editForm.controls.storagePlace.id"></babylon-storage-place-autocompletesingle>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Kommentar</mat-label>
					<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.comment"></textarea>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
