import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeliveryNoteDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-delivery-note.component';
import { DeliveryNoteDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-delivery-note.component';
import { DeliveryNoteDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-delivery-note.component';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm, IDeliveryNoteOrder, IDeliveryNoteReturn } from '../../models/delivery-note';
import { DeliveryNoteKind } from '../../models/enums/delivery-note-kind.enum';
import { IDeliveryNoteState } from '../../state/app.state';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { DeliveryNoteOrderDialogService } from './delivery-note.order.service';
import { DeliveryNoteReturnDialogService } from './delivery-note.return.service';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteDialogService extends EntityDialogService<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	protected createComponent: ComponentType<any> = DeliveryNoteDialogAddComponent;
	protected updateComponent: ComponentType<any> = DeliveryNoteDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DeliveryNoteDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DeliveryNoteFacade, private deliveryNoteOrderDialogService: DeliveryNoteOrderDialogService, private deliveryNoteReturnDialogService: DeliveryNoteReturnDialogService) {
		super(matDialog, entityFacade);
	}

	public openUpdateDialog(data: IEntityDialogData<IDeliveryNote>): MatDialogRef<any> {
		switch (data.entity.deliveryNoteKind) {
			case DeliveryNoteKind.DeliveryNoteOrder:
				return this.deliveryNoteOrderDialogService.openUpdateDialog(data as IEntityDialogData<IDeliveryNoteOrder>);
			case DeliveryNoteKind.DeliveryNoteReturn:
				return this.deliveryNoteReturnDialogService.openUpdateDialog(data as IEntityDialogData<IDeliveryNoteReturn>);
		}
	}
}
