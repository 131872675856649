import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPurchaseOnAccount } from '../../models/payment.model';
import { IPaymentPurchaseOnAccountState } from '../../state/app.state';
import { PurchaseOnAccountFacade } from '../../state/payment.purchase-on-account/payment.purchase-on-account.facade';
import { IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm } from './../../models/payment.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-purchase-on-accountdialogadd',
	templateUrl: './entitydialogadd-purchase-on-account.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PurchaseOnAccountDialogAddComponent extends EntityDialogAddComponent<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountState, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {
	constructor(entityFacade: PurchaseOnAccountFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
