import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { BlockKind, IPageBlock } from '../../models/page.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-pageblockdialog',
	templateUrl: './dialog-pageblock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PageBlockDialogComponent extends DialogComponent<PageBlockDialogComponent, IPageBlock> {
	public BlockKinds = BlockKind;

	constructor(matDialogRef: MatDialogRef<PageBlockDialogComponent, IPageBlock>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: IPageBlock) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				blockKind: [data.blockKind, [Validators.required]],
				parameters: [data.parameters, []],
				queryParameters: [data.queryParameters, []],
			})
		);
	}
}
