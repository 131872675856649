import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IConfiguration } from 'src/models/configuration.model';
import { ConfigurationApiService } from 'src/services/api/configuration.service';
import { ConfigurationDialogService } from 'src/services/dialog/configuration.service';
import { ConfigurationNotificationService } from 'src/services/notification/configuration.service';
import { AppState, IConfigurationState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { fromConfigurationActions } from './configuration.actions';
import { ConfigurationSelector } from './configuration.selectors';

@Injectable({
	providedIn: 'root',
})
export class ConfigurationEffects extends EntityEffects<IConfiguration, IConfigurationState, IConfigurationEditForm, IConfigurationFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ConfigurationApiService,
		notificationService: ConfigurationNotificationService,
		dialogService: ConfigurationDialogService,
		selector: ConfigurationSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromConfigurationActions, 'CONFIGURATION');
	}
}
