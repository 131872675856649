<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<mat-dialog-content class="picking-dialog">
		<form>
			<div class="form-grid">
				<div class="form-grid--left">
					<h3 class="mat-h3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">
						{{ entity.shippingKind }}
					</h3>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div class="form-grid">
				<div class="form-grid--left">
					<mat-form-field appearance="outline">
						<mat-label>Kommentar</mat-label>
						<textarea matInput rows="18" [(ngModel)]="comment" [ngModelOptions]="{ standalone: true }"></textarea>
					</mat-form-field>
				</div>
				<div class="form-grid--right">
					<table class="mat-table" *ngIf="deliveryNoteFacade.fetchOne(entity.deliveryNote) | async as deliveryNote">
						<tr class="mat-row">
							<td class="mat-cell">{{ deliveryNote.receiver.salutationKind }} {{ deliveryNote.receiver.firstName }} {{ deliveryNote.receiver.lastName }}</td>
						</tr>
						<tr class="mat-row" *ngIf="deliveryNote.receiver.company">
							<td class="mat-cell">{{ deliveryNote.receiver.company }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">{{ deliveryNote.receiver.address.street }} {{ deliveryNote.receiver.address.streetNumber }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">{{ deliveryNote.receiver.address.postCode }} {{ deliveryNote.receiver.address.city }}</td>
						</tr>
						<tr class="mat-row">
							<td class="mat-cell">{{ deliveryNote.receiver.address.country }}</td>
						</tr>
					</table>
					<br />
					<button mat-button (click)="shippingDialogService.openUpdateDialog({ entity })" color="accent"><mat-icon svgIcon="pencil"></mat-icon> Daten Bearbeiten</button>
				</div>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<button mat-flat-button color="default" [matMenuTriggerFor]="shippingDownloadMenu">Herunterladen</button>
		<mat-menu #shippingDownloadMenu="matMenu">
			<button mat-menu-item *ngIf="deliveryNoteFacade.fetchOne(entity.deliveryNote) | async as deliveryNote" (click)="deliveryNoteFacade.download(deliveryNote)">
				<span>Lieferschein</span>
			</button>
		</mat-menu>
		<babylon-process-buttons [facade]="shippingFacade" [comment]="comment"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
