import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromMenuActions } from './menu.actions';
import { MenuSelector } from './menu.selectors';

@Injectable({
	providedIn: 'root',
})
export class MenuFacade extends BaseFacade {
	constructor(store: Store<AppState>, private menuSelector: MenuSelector) {
		super(store);
	}

	public isMenuExpanded$ = this.store.select(this.menuSelector.isMenuExpanded);
	public testingAcknowledgedAt$ = this.store.select(this.menuSelector.testingAcknowledgedAt);
	public menuMargin$ = this.store.select(this.menuSelector.menuMargin);

	public toggleIsMenuExpanded(): void {
		this.store.dispatch(fromMenuActions.toggleIsMenuExpanded());
	}

	public acknowledgeTesting(): void {
		this.store.dispatch(fromMenuActions.acknowledgedTesting());
	}
}
