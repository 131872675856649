import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, Observable, tap } from 'rxjs';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm, ICollectionPurchaseTransferPosition } from '../../models/collection-purchase-transfer.model';
import { AppState, ICollectionPurchaseTransferState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromCollectionPurchaseTransferActions } from './collection-purchase-transfer.actions';
import { initialEditFormValue } from './collection-purchase-transfer.reducer';
import { CollectionPurchaseTransferSelector } from './collection-purchase-transfer.selectors';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseTransferFacade extends EntityFacade<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromCollectionPurchaseTransferActions;

	constructor(store: Store<AppState>, private collectionPurchaseTransferSelector: CollectionPurchaseTransferSelector) {
		super(store, collectionPurchaseTransferSelector);
	}

	public removePosition(position: ICollectionPurchaseTransferPosition) {
		this.editFormValue$
			.pipe(
				first(),
				tap(editFormValue => {
					this.changeFormValue({ attributeName: 'positions', value: editFormValue.positions.filter(p => p._id !== position._id) });
				})
			)
			.subscribe();
	}

	public downloadCsv(entity: ICollectionPurchaseTransfer) {
		this.store.dispatch(this.actions.downloadCsv({ entity }));
	}

	public filterByReceiptNumbers(receiptNumbers: number[]): Observable<ICollectionPurchaseTransfer[]> {
		this.store.dispatch(fromCollectionPurchaseTransferActions.filterByReceiptNumbers({ receiptNumbers }));
		return this.store.select(this.collectionPurchaseTransferSelector.filterByReceiptNumbers(receiptNumbers));
	}
}
