import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { IBundleState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-bundledialogdelete',
	templateUrl: './entitydialogdelete-bundle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class BundleDialogDeleteComponent extends EntityDialogDeleteComponent<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	constructor(entityFacade: BundleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
