import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PurchaseOnAccountNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-purchase-on-account.component';
import { IPaymentPurchaseOnAccount } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOnAccountNotificationService extends EntityNotificationService<IPaymentPurchaseOnAccount> {
	protected component: ComponentType<any> = PurchaseOnAccountNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
