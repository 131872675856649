import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IConfiguration, IShippingTypeInformation } from '../../models/configuration.model';
import { IConfigurationState } from '../../state/app.state';
import { ConfigurationFacade } from '../../state/configuration/configuration.facade';
import { IConfigurationEditForm, IConfigurationFilterForm } from './../../models/configuration.model';
import { ShippingTypeDialogComponent } from './../dialog/dialog-shipping-type.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-shipping-typestable',
	templateUrl: './entitydialogtable-shipping-types.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class ShippingTypesTableComponent extends EntityDialogTableComponent<IConfiguration, IConfigurationState, IShippingTypeInformation, IShippingTypeInformation, IConfigurationEditForm, IConfigurationFilterForm> {
	public columns = ['shippingKind', 'price', 'shippingDaysMin', 'shippingDaysMax', 'shippedBeforeHour', 'edit'];

	constructor(entityFacade: ConfigurationFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IConfiguration): IShippingTypeInformation[] {
		return entity.shippingInformation.shippingTypes;
	}

	protected createElement(): IShippingTypeInformation {
		return {
			shippingKind: null,
			price: null,
			priceSaturdayDelivery: null,
			shippingDaysMin: null,
			shippingDaysMax: null,
			shippedBeforeHour: null,
			password: null,
		};
	}

	public openEditDialog(data: IShippingTypeInformation, index: number): void {
		const dialogRef = this.matDialog.open(ShippingTypeDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IShippingTypeInformation) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
