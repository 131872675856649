import { first, Observable, tap } from 'rxjs';
import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm, IDirectDebitTransferPosition } from '../../models/direct-debit-transfer.model';
import { AppState, IDirectDebitTransferState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityFacade } from '../entity.facade';
import { fromDirectDebitTransferActions } from './direct-debit-transfer.actions';
import { initialEditFormValue } from './direct-debit-transfer.reducer';
import { DirectDebitTransferSelector } from './direct-debit-transfer.selectors';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferFacade extends EntityFacade<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDirectDebitTransferActions;

	constructor(store: Store<AppState>, private directDebitTransferSelector: DirectDebitTransferSelector) {
		super(store, directDebitTransferSelector);
	}

	public removePosition(position: IDirectDebitTransferPosition) {
		this.editFormValue$
			.pipe(
				first(),
				tap(editFormValue => {
					this.changeFormValue({ attributeName: 'positions', value: editFormValue.positions.filter(p => p._id !== position._id) });
				})
			)
			.subscribe();
	}

	public downloadCsv(entity: IDirectDebitTransfer) {
		this.store.dispatch(this.actions.downloadCsv({ entity }));
	}

	public downloadXml(entity: IDirectDebitTransfer) {
		this.store.dispatch(this.actions.downloadXml({ entity }));
	}

	public filterByPaymentIds(paymentIds: string[]): Observable<IDirectDebitTransfer[]> {
		this.store.dispatch(fromDirectDebitTransferActions.filterByPaymentIds({ paymentIds }));
		return this.store.select(this.directDebitTransferSelector.filterByPaymentIds(paymentIds));
	}
}
