import { Injectable } from '@angular/core';
import { IProcess } from '../../models/process';
import { AppState, IProcessState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';

@Injectable({
	providedIn: 'root',
})
export class ProcessSelector extends EntitySelector<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	protected stateSelector = (state: AppState): IProcessState => state.process;
}
