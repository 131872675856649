import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ProcessNodeNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-process-node.component';
import { IProcessNode } from '../../models/process-node.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ProcessNodeNotificationService extends EntityNotificationService<IProcessNode> {
	protected component: ComponentType<any> = ProcessNodeNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
