import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm } from '../../models/accounting-summary.model';
import { AccountingSummaryAdvancementFacade } from '../../state/accounting-summary.advancement/accounting-summary.advancement.facade';
import { IAccountingSummaryAdvancementState } from '../../state/app.state';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementDialogService extends EntityDialogService<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementState, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm> {
	protected createComponent: ComponentType<any> = null;
	protected updateComponent: ComponentType<any> = null;
	protected removeComponent: ComponentType<any> = null;

	constructor(matDialog: MatDialog, entityFacade: AccountingSummaryAdvancementFacade) {
		super(matDialog, entityFacade);
	}
}
