import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStockMedia, IStockMediaEditForm, IStockMediaFilterForm } from '../../models/stock.model';
import { AppState, IStockMediaState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromMediaStockActions } from './stock.media.actions';
import { initialEditFormValue } from './stock.media.reducer';
import { MediaStockSelector } from './stock.media.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaStockFacade extends EntityFacade<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromMediaStockActions;

	constructor(store: Store<AppState>, mediaStockSelector: MediaStockSelector) {
		super(store, mediaStockSelector);
	}
}
