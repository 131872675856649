import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IDeliveryNoteReturn } from '../../models/delivery-note';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteReturnApiService extends EntityApiService<IDeliveryNoteReturn> {
	protected entityName = 'delivery-note-returns';

	constructor(http: HttpClient) {
		super(http);
	}

	public download(authToken: string, receipt: IDeliveryNoteReturn): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'application/pdf');

		return this.post<any, any>([this.entityName, receipt._id, 'generate-pdf'], null, headers, 'blob');
	}

	public print(authToken: string, entity: IDeliveryNoteReturn): Observable<string> {
		let headers = this.getHeaders(authToken);

		return this.post<any, unknown>([this.entityName, entity._id, 'print-pdf'], null, headers);
	}
}
