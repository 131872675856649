import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IStoragePlace } from '../../models/storage-place.model';
import { StoragePlaceDialogService } from '../../services/dialog/storage-place.service';
import { IStoragePlaceState } from '../../state/app.state';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-storage-placepage',
	templateUrl: './entitypage-storage-place.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class StoragePlacePageComponent extends EntityPageComponent<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	public pageTitle$ = new BehaviorSubject('Lagerplätze');
	public columns = ['identifier', 'room', 'comment', 'filter'];

	constructor(titleService: Title, entityDialogService: StoragePlaceDialogService, route: ActivatedRoute, entityFacade: StoragePlaceFacade) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
