import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPublicationCycleState } from '../../state/app.state';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-publication-cycledialogchange',
	templateUrl: './entitydialogchange-publication-cycle.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PublicationCycleDialogChangeComponent extends EntityDialogChangeComponent<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	constructor(entityFacade: PublicationCycleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
