import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { AccessoryProductNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-accessory-product.component';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class AccessoryProductNotificationService extends EntityNotificationService<IAccessoryProduct> {
	protected component: ComponentType<any> = AccessoryProductNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
