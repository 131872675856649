import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPublisher } from 'src/models/publisher.model';
import { AppState, IPublisherState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { fromPublisherActions } from './publisher.actions';
import { initialEditFormValue } from './publisher.reducer';
import { PublisherSelector } from './publisher.selectors';

@Injectable({
	providedIn: 'root',
})
export class PublisherFacade extends EntityFacade<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPublisherActions;

	constructor(store: Store<AppState>, publisherSelector: PublisherSelector) {
		super(store, publisherSelector);
	}
}
