<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Beleg</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-select formControlName="receiptKind" placeholder="Belegtyp">
						<mat-option *ngFor="let receiptKind of ReceiptKinds | keyvalue" [value]="receiptKind.value">{{ receiptKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Fällig in</mat-label>
					<input type="number" matInput formControlName="dueDays" />
					<span matSuffix>Tag(e)</span>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Wiedervorlage nach Fälligkeit in</mat-label>
					<input type="number" matInput formControlName="reminderDays" />
					<span matSuffix>Tag(e)</span>
				</mat-form-field>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Pdf Text</mat-label>
					<textarea matInput rows="4" cdkTextareaAutosize formControlName="pdfText"></textarea>
				</mat-form-field>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
