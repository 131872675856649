<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Artikelpreis</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-select placeholder="Typ" formControlName="articleKind">
						<mat-option *ngFor="let articleKind of ArticleKinds | keyvalue" [value]="articleKind.value">{{ articleKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Preis</mat-label>
					<input type="number" matInput formControlName="price" />
					<span matSuffix>€</span>
				</mat-form-field>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
