import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgrxValueConverters } from 'ngrx-forms';
import { CustomerTypeEnum, ICustomer } from '../../models/customer.model';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { ICustomerState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-customerdialogadd',
	templateUrl: './entitydialogadd-customer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class CustomerDialogAddComponent extends EntityDialogAddComponent<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	constructor(entityFacade: CustomerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public salutationKinds = Object.values(SalutationKind);
	public customerTypes = Object.values(CustomerTypeEnum);
	public dateToISOString = NgrxValueConverters.dateToISOString;
}
