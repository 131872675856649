import { Directive, Host, Input, Optional } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { FormControlState } from 'ngrx-forms';

@Directive({
	selector: '[ngrxFormControlState]',
})
export class CustomErrorStateMatcherDirective {
	@Input() set ngrxFormControlState(state: FormControlState<any>) {
		const errorsAreShown = state && state.isInvalid && (state.isTouched || state.isSubmitted);

		if (this.input) {
			this.input.errorState = errorsAreShown;
			this.input.stateChanges.next();
		}
	}

	constructor(@Host() @Optional() private input: MatInput) {}
}
