import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IUser, IUserDevice } from 'src/models/user.model';
import { IProcessNodeCount } from '../../state/app.state';
import { IDataResponse, IResponse } from './api.service';
import { EntityApiService, IEntityListResponse, IEntitySingleResponse } from './entity.service';

export interface IUserResponse extends IResponse {
	user: IUser;
}

export interface IAuthenticationResponse extends IResponse {
	token: string;
	user: IUser;
}

@Injectable({
	providedIn: 'root',
})
export class UserApiService extends EntityApiService<IUser> {
	protected entityName = 'users';

	constructor(http: HttpClient) {
		super(http);
	}

	public count(authToken: string): Observable<IDataResponse<IProcessNodeCount[]>> {
		return of({ data: [], count: 0, totalCount: 0, success: true });
	}

	public getUserDevicesForMachine(machineId: string): Observable<IEntityListResponse<IUserDevice>> {
		return this.get<IEntityListResponse<IUserDevice>>([this.entityName, 'devices', 'machine', machineId]);
	}

	public getUserDeviceForUser(email: string): Observable<IEntitySingleResponse<IUserDevice>> {
		return this.get<IEntitySingleResponse<IUserDevice>>([this.entityName, 'devices', 'user', email]);
	}

	public registerRequestDevice(userDevice: IUserDevice): Observable<IEntitySingleResponse<any>> {
		return this.get<IEntitySingleResponse<any>>([this.entityName, 'devices', 'register', userDevice._id]);
	}

	public registerVerifyDevice(userDevice: IUserDevice, machineId: string, publicKey: any, credential: any): Observable<IResponse> {
		return this.post<IEntitySingleResponse<IUserDevice>, any>([this.entityName, 'devices', 'register', userDevice._id, machineId], {
			publicKey,
			credential,
		});
	}

	public authenticateRequestDevice(userDevice: IUserDevice): Observable<IEntitySingleResponse<any>> {
		return this.get<IEntitySingleResponse<any>>([this.entityName, 'devices', 'login', userDevice._id]);
	}

	public authenticateVerifyDevice(userDevice: IUserDevice, publicKey: any, credential: any): Observable<IAuthenticationResponse> {
		return this.post<IAuthenticationResponse, any>([this.entityName, 'devices', 'login', userDevice._id], {
			publicKey,
			credential,
		});
	}

	public getMe(authToken: string): Observable<IUserResponse> {
		return this.get<IUserResponse>([this.entityName, 'me'], this.getHeaders(authToken));
	}
}
