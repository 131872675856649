import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleFolderState } from 'src/state/app.state';
import { IArticleFolder, IArticleFolderEditForm, IArticleFolderFilterForm, IExpandedArticle } from '../../models/article.model';
import { ArticleFolderDialogService } from '../../services/dialog/article.folder.service';
import { IAddArticleDialogData } from '../../services/dialog/article.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-folderadddialog',
	templateUrl: './entitydialog-folder-add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class FolderAddDialogComponent extends EntityDialogComponent<IArticleFolder, IArticleFolderState, IArticleFolderEditForm, IArticleFolderFilterForm> {
	public columns = ['product', 'storagePlace', 'add'];

	constructor(
		public folderFacade: ArticleFolderFacade,
		matDialogRef: MatDialogRef<FolderAddDialogComponent, IEntityDialogData<IArticleFolder>>,
		@Inject(MAT_DIALOG_DATA) data: IAddArticleDialogData,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		public orderFacade: OrderFacade,
		public regionFacade: RegionFacade,
		public folderDialogService: ArticleFolderDialogService,
		public cartFacade: CartFacade
	) {
		super(folderFacade, matDialogRef);
		this.folderFacade.search();
		this.position = data.position;
	}

	public addFolder(article: IExpandedArticle): void {
		const position = this.addArticlePosition(article);
		this.cartFacade.validatePositions(position);
		this.matDialogRef.close();
	}

	public cancel(): void {
		this.orderFacade.removePosition(this.position);
		this.matDialogRef.close();
	}
}
