import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class UploadedFileApiService extends EntityApiService<IUploadedFile> {
	protected entityName = 'uploaded-files';

	constructor(http: HttpClient) {
		super(http);
	}
}
