<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Sammelrechnung hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<babylon-partner-autocompletesingle [value]="editForm.controls.partner.value" [controlId]="editForm.controls.partner.id" class="mat-form-field--span"></babylon-partner-autocompletesingle>
				<mat-form-field appearance="outline">
					<mat-label>Von</mat-label>
					<input matInput [matDatepicker]="from" [ngrxFormControlState]="editForm.controls.from" [ngrxValueConverter]="dateToISOStringConverter" />
					<mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
					<mat-datepicker #from></mat-datepicker>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Bis</mat-label>
					<input matInput [matDatepicker]="until" [ngrxFormControlState]="editForm.controls.until" [ngrxValueConverter]="dateToISOStringConverter" />
					<mat-datepicker-toggle matSuffix [for]="until"></mat-datepicker-toggle>
					<mat-datepicker #until></mat-datepicker>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
