<div class="progress-wrapper">
	<mat-form-field appearance="outline" class="filter">
		<mat-label>{{ placeholder }}</mat-label>
		<input type="text" #input matInput [matAutocomplete]="autocomplete" (keyup)="input$.next(input.value)" [readonly]="readonly" />
		<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayWith" panelWidth="auto" (optionSelected)="select($event.option.value)">
			<mat-option *ngFor="let entity of entityFacade.listSuggested$ | async" [value]="entity">
				{{ entity.number }}
			</mat-option>
		</mat-autocomplete>
		<mat-icon matSuffix (click)="select(null)" *ngIf="optional && (value != null || value === undefined)" svgIcon="close" matTooltip="Auswahl entfernen"></mat-icon>
	</mat-form-field>
	<mat-progress-bar mode="indeterminate" *ngIf="entityFacade.isSuggesting$ | async" [@progressFade]></mat-progress-bar>
</div>
