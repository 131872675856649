import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingDhl } from '../../models/shipping.model';
import { IShippingDhlState } from '../../state/app.state';
import { DhlFacade } from '../../state/shipping.dhl/shipping.dhl.facade';
import { IShippingDhlEditForm, IShippingDhlFilterForm } from './../../models/shipping.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-dhldialogchange',
	templateUrl: './entitydialogchange-dhl.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DhlDialogChangeComponent extends EntityDialogChangeComponent<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm> {
	constructor(entityFacade: DhlFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
