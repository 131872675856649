<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<ng-container *ngLet="(processNodeFacade.fetchOne(editForm.value.processInstance.state) | async)?.name != 'StockInitialized' as readonly">
		<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Bestand ändern</h1>
		<mat-dialog-content>
			<mat-tab-group animationDuration="0ms">
				<mat-tab label="Bestand">
					<form [ngrxFormState]="editForm">
						<div class="form-grid">
							<div class="form-subgrid">
								<mat-form-field appearance="outline">
									<mat-label>Von</mat-label>
									<input matInput [matDatepicker]="from" [ngrxFormControlState]="editForm.controls.from" [ngrxValueConverter]="dateToISOStringConverter" [readonly]="readonly" />
									<mat-datepicker-toggle matSuffix [for]="from" [disabled]="readonly"></mat-datepicker-toggle>
									<mat-datepicker #from></mat-datepicker>
								</mat-form-field>
								<mat-form-field appearance="outline">
									<mat-label>Bis</mat-label>
									<input matInput [matDatepicker]="until" [ngrxFormControlState]="editForm.controls.until" [ngrxValueConverter]="dateToISOStringConverter" [readonly]="readonly" />
									<mat-datepicker-toggle matSuffix [for]="until" [disabled]="readonly"></mat-datepicker-toggle>
									<mat-datepicker #until></mat-datepicker>
								</mat-form-field>
							</div>
							<mat-form-field appearance="outline">
								<mat-label>Qualität</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.quality" [readonly]="readonly" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Anzahl</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.quantity" [readonly]="readonly" />
							</mat-form-field>
							<mat-form-field appearance="outline">
								<mat-label>Bestandstyp</mat-label>
								<input type="text" matInput [ngrxFormControlState]="editForm.controls.stockType" [readonly]="readonly" />
							</mat-form-field>
							<babylon-media-product-autocompletesingle [value]="editForm.controls.product.value" [controlId]="editForm.controls.product.id" [readonly]="readonly"> </babylon-media-product-autocompletesingle>
							<babylon-storage-place-autocompletesingle [value]="editForm.controls.storagePlace.value" [controlId]="editForm.controls.storagePlace.id" [readonly]="readonly"> </babylon-storage-place-autocompletesingle>
							<mat-form-field appearance="outline" class="mat-form-field--span">
								<mat-label>Kurzbeschreibung</mat-label>
								<textarea maxlength="1000" matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.comment" [readonly]="readonly"></textarea>
								<mat-hint align="end">{{ editForm.value.comment?.length ?? 0 }} / 1000</mat-hint>
							</mat-form-field>
						</div>
					</form> </mat-tab
				>+
				<mat-tab label="Historie">
					<babylon-media-stockhistorytable controlId="processInstance.actions"></babylon-media-stockhistorytable>
				</mat-tab>
			</mat-tab-group>
		</mat-dialog-content>
		<mat-dialog-actions align="end">
			<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
			<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
		</mat-dialog-actions>
	</ng-container>
</ng-container>
