import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PublicationCycleNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-publication-cycle.component';
import { IPublicationCycle } from './../../models/publication-cycle.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PublicationCycleNotificationService extends EntityNotificationService<IPublicationCycle> {
	protected component: ComponentType<any> = PublicationCycleNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
