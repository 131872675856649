import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-partnerdialogchange',
	templateUrl: './entitydialogchange-partner.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PartnerDialogChangeComponent extends EntityDialogChangeComponent<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	public SalutationKinds = SalutationKind;
	public PaymentKinds = PaymentKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: PartnerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
