import { box, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm, StockKind } from '../../models/stock.model';
import { EntityState, IStockAccessoryState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromAccessoryStockActions } from './stock.accessory.actions';

export const initialValue: IStockAccessory = {
	_id: null,
	comment: null,
	quality: null,
	quantity: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: StockKind.StockAccessory,
};

export const initialEditFormValue: IStockAccessoryEditForm = {
	_id: null,
	comment: null,
	quality: null,
	quantity: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: StockKind.StockAccessory,
};

export const initialFilterFormValue: IStockAccessoryFilterForm = {
	_id: null,
	createdAt: null,
	comment: null,
	quality: box([]),
	quantity: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: StockKind.StockAccessory,
};

export const initialState = EntityState.create<IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm>(
	'ACCESSORYSTOCK',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			processInstance: {
				state: 'IN',
			},
		},
	],
	{
		createdAt: -1,
	}
);

export const accessoryStockReducer = EntityReducer.create<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
	},
	fromAccessoryStockActions
);
