import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BirthdayChronicleAddDialogComponent } from '../../components/entitydialog/entitydialog-birthday-chronicle-add.component';
import { BirthdayChronicleReplaceDialogComponent } from '../../components/entitydialog/entitydialog-birthday-chronicle-replace.component';
import { BirthdayChronicleDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-birthday-chronicle.component';
import { BirthdayChronicleDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-birthday-chronicle.component';
import { BirthdayChronicleDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-birthday-chronicle.component';
import { IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm } from '../../models/article.model';
import { IArticleBirthdayChronicleState } from '../../state/app.state';
import { ArticleBirthdayChronicleFacade } from '../../state/article.birthday-chronicle/article.birthday-chronicle.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayChronicleDialogService extends EntityDialogService<IArticleBirthdayChronicle, IArticleBirthdayChronicleState, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm> {
	protected createComponent: ComponentType<any> = BirthdayChronicleDialogAddComponent;
	protected updateComponent: ComponentType<any> = BirthdayChronicleDialogChangeComponent;
	protected removeComponent: ComponentType<any> = BirthdayChronicleDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = BirthdayChronicleReplaceDialogComponent;
	protected addComponent: ComponentType<any> = BirthdayChronicleAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleBirthdayChronicleFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleBirthdayChronicle>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleBirthdayChronicle>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
