import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import _ from 'lodash';
import { filter, map, Observable } from 'rxjs';
import { IEntity, IEntityEditForm, IEntityFilterForm } from '../../models/entity.model';
import { IEntityState } from '../../state/app.state';
import { EntityFacade } from '../../state/entity.facade';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'babylon-fetch',
	styleUrls: ['./fetch.component.scss'],
	templateUrl: './fetch.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FetchComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseComponent {
	@Input() public value: string | string[];
	@Input() public facade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>;

	public get valueAsOne(): string {
		return !_.isArray(this.value) ? this.value : null;
	}

	public get valueAsMany(): string[] {
		return _.isArray(this.value) ? this.value : null;
	}

	public get isFetchingMany$(): Observable<boolean> {
		return this.facade.isFetching$.pipe(
			filter(() => this.value != null && _.isArray(this.value)),
			concatLatestFrom(() => this.facade.getMany(this.value as string[])),
			map(([isFetching, entities]) => isFetching && entities.every(entity => entity === undefined))
		);
	}

	public get isFetchingOne$(): Observable<boolean> {
		return this.facade.isFetching$.pipe(
			filter(() => this.value != null && !_.isArray(this.value)),
			concatLatestFrom(() => this.facade.getOne(this.value as string)),
			map(([isFetching, entity]) => isFetching && entity === undefined)
		);
	}
}
