import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IBundlePrice } from '../../models/partner.model';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-bundle-pricedialog',
	templateUrl: './dialog-bundle-price.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class BundlePriceDialogComponent extends DialogComponent<BundlePriceDialogComponent, IBundlePrice> {
	constructor(matDialogRef: MatDialogRef<BundlePriceDialogComponent, IBundlePrice>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IBundlePrice) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				bundle: [data.bundle, [Validators.required]],
				price: [data.price, []],
			})
		);
	}
}
