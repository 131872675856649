import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IUploadedFile } from 'src/models/uploaded-file.model';
import { UploadedFileDialogService } from 'src/services/dialog/uploaded-file.service';
import { IUploadedFileState } from 'src/state/app.state';
import { UploadedFileFacade } from 'src/state/uploadedfile/uploadedfile.facade';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-uploadedfilepage',
	templateUrl: './entitypage-uploaded-file.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class UploadedFilePageComponent extends EntityPageComponent<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm> {
	public pageTitle$ = new BehaviorSubject('Dateien');
	public columns = ['mimeType', 'url', 'filter'];

	constructor(titleService: Title, uploadedfileDialogService: UploadedFileDialogService, route: ActivatedRoute, entityFacade: UploadedFileFacade) {
		super(titleService, uploadedfileDialogService, route, entityFacade);
	}
}
