import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';
import { EntityDialogComponent } from '../entitydialog/entitydialog.component';

@Directive()
export abstract class EntityDialogPreviewComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends EntityDialogComponent<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm> {
	constructor(entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
