import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, filter, first, interval, map, tap, withLatestFrom } from 'rxjs';
import { MenuFacade } from 'src/state/menu/menu.facade';
import { ProcessNodeFacade } from 'src/state/process-node/process-node.facade';
import { fadeInOut } from '../../animations/fadeinout.animation';
import { progressFade } from '../../animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { IEntity } from '../../models/entity.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IMessage } from '../../models/message.model';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { PriorityType } from '../../models/process-instance-model';
import { IReceipt } from '../../models/receipt';
import { IShipping } from '../../models/shipping.model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { CustomerDialogService } from '../../services/dialog/customer.service';
import { DeliveryNoteDialogService } from '../../services/dialog/delivery-note.service';
import { MessageDialogService } from '../../services/dialog/message.service';
import { OrderDialogService } from '../../services/dialog/order.service';
import { PaymentDialogService } from '../../services/dialog/payment.service';
import { ReceiptDialogService } from '../../services/dialog/receipt.service';
import { ShippingDialogService } from '../../services/dialog/shipping.service';
import { WindowRefService } from '../../services/utility/window.service';
import { IOrderState } from '../../state/app.state';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { ArticleFacade } from '../../state/article/article.facade';
import { AttachmentFacade } from '../../state/attachment/attachment.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { MessageFacade } from '../../state/message/message.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { SessionFacade } from '../../state/session/session.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { UserFacade } from '../../state/user/user.facade';
import { IProcessButtonClick } from '../process-buttons/process-buttons.component';
import { ProcessRouteFacade } from './../../state/process-route/process-route.facade';
import { EntityPageChangeComponent } from './entitypagechange.component';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import moment from 'moment';

@Component({
	selector: 'babylon-orderpagechange',
	templateUrl: './entitypagechange-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypagechange.component.scss'],
	animations: [progressFade, fadeInOut],
})
export class OrderPageChangeComponent extends EntityPageChangeComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	public pageTitle$ = new BehaviorSubject('Auftrag');
	public ArticleKinds = ArticleKind;
	public PositionKinds = PositionKind;
	public PriorityTypes = PriorityType;
	public ShippingKinds = ShippingKind;
	public currentDate$ = interval(1000).pipe(map(_ => moment()));

	public customerColumns = ['salutationKind', 'lastName', 'firstName', 'email', 'actions'];
	public positionColumns = ['title', 'price', 'currentUser', 'state', 'priority', 'actions'];
	public receiptColumns = ['receiptKind', 'number', 'date', 'dueDate', 'totalPrice', 'consentEmailTransfer', 'state', 'actions'];
	public paymentColumns = ['paymentKind', 'dueAmount', 'state', 'actions'];
	public shippingColumns = ['shippingKind', 'shippingByPartner', 'enableSaturdayDelivery', 'consentEmailTransfer', 'state', 'actions'];
	public messageColumns = ['title', 'state', 'prio', 'currentUser', 'actions'];
	public deliveryNoteColumns = ['deliveryNoteKind', 'receiver', 'email', 'telephone', 'actions'];

	constructor(
		titleService: Title,
		menuFacade: MenuFacade,
		sessionFacade: SessionFacade,
		userFacade: UserFacade,
		private windowService: WindowRefService,
		public router: Router,
		public orderFacade: OrderFacade,
		public orderDialogService: OrderDialogService,
		public routerFacade: RouterFacade,
		public imageFacade: ImageFacade,
		public messageFacade: MessageFacade,
		public uploadedFileFacade: UploadedFileFacade,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public paymentFacade: PaymentFacade,
		public partnerFacade: PartnerFacade,
		public paymentDialogService: PaymentDialogService,
		public shippingFacade: ShippingFacade,
		public attachmentFacade: AttachmentFacade,
		public shippingDialogService: ShippingDialogService,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public deliveryNoteDialogService: DeliveryNoteDialogService,
		public certificateFacade: ArticleCertificateFacade,
		public receiptFacade: ReceiptFacade,
		public receiptDialogService: ReceiptDialogService,
		public messageDialogService: MessageDialogService,
		public customerFacade: CustomerFacade,
		public customerDialogService: CustomerDialogService,
		public articleFacade: ArticleFacade,
		public articleDialogService: ArticleDialogService,
		public cartFacade: CartFacade
	) {
		super(titleService, orderFacade, menuFacade, routerFacade, sessionFacade, userFacade);

		this.routerFacade.orderId$
			.pipe(
				filter(orderId => !!orderId),
				first(),
				map(orderId =>
					this.entityFacade
						.fetchOne(orderId)
						.pipe(
							filter(entity => !!entity),
							first(),
							tap(entity => this.pageTitle$.next(`Auftrag ${entity.number}`)),
							withLatestFrom(this.sessionFacade.user$),
							withLatestFrom(this.userFacade.list$),
							map(([[entity, user], users]) => {
								this.entityFacade.update(entity);
								const currentUser = users.find(u => u._id == entity.processInstance.currentUser);

								if (currentUser != null && currentUser._id != user._id && currentUser.name != 'Gast') {
									this.orderDialogService.openConfirmationDialog({
										title: 'Auftragsbearbeitung',
										text: `Der Auftrag ${entity.number} wird bereits von ${currentUser.name} bearbeitet`,
										onConfirm: () => {},
										onReject: () => this.windowService.nativeWindow.history.back(),
									});
								}
							})
						)
						.subscribe()
				)
			)
			.subscribe();
	}

	public sendOrderConfirmation(): void {
		this.orderDialogService.openConfirmationDialog({
			width: '500px',
			title: `Bestellbestätigung senden`,
			text: 'Die Bestellbestätigung beinhaltet alle aktuellen Positionen und aktuelle Preise. Wenn Sie eine bereits versendete Bestätigung erneut senden wollen, verwenden Sie stattdessen bitte die Funktion "Nachricht erneut versenden". Möchten Sie jetzt eine aktuelle Bestellbestätigung an die derzeit im Kunden hinterlegte E-Mailadresse senden?',
			onConfirm: () => this.orderFacade.sendConfirmationEmail(),
			onReject: () => {},
		});
	}

	public duplicate(order: IOrder): void {
		this.orderDialogService.openConfirmationDialog({
			width: '500px',
			title: `Auftrag ${order.number} duplizieren`,
			text: 'Die Zahlart und Lieferart werden aus dem aktuellen Auftrag übernommen und eine neue Bestellung für den Kunden angelegt. Postionen müssen dem Auftrag im Nachhinein manuell zugeordnet werden. Eine Bestellbestätigung wird nicht automatisiert versendet. Möchten Sie den Auftrag jetzt duplizieren?',
			onConfirm: () => this.orderFacade.duplicate(),
			onReject: () => {},
		});
	}

	public allReceipts(primaryReceipt: string, secondaryReceipts: string[]): string[] {
		return [primaryReceipt, ...secondaryReceipts];
	}

	public editPositions(entity: IOrder): void {
		this.entityFacade.update(entity);
		this.orderDialogService.openEditPositionsDialog();
	}

	public editReceiver(entity: IReceipt, order: IOrder): void {
		this.receiptFacade.update(entity);
		this.receiptDialogService.openEditReceiverDialog();
	}

	public addMessage(order: IOrder): void {
		this.orderFacade.update(order);
		this.messageDialogService.openCreateDialog();
	}

	public updateMessage(entity: IMessage, order: IOrder): void {
		this.orderFacade.update(order);
		this.messageDialogService.openUpdateDialog({ entity, height: '700px' });
	}

	public removeMessage(entity: IMessage, order: IOrder): void {
		this.orderFacade.update(order);
		this.messageDialogService.openRemoveDialog({ entity });
	}

	public onExecuted(event: IProcessButtonClick<IOrder>) {
		if (event.route.name == 'VerifyOrderOk') {
			this.router.navigate(['/entity/order/OrderNew'], { queryParams: { title: 'Aufträge Neu' } });
		}
	}

	public changeShippingByPartner(event: MatSlideToggleChange, shipping: IShipping) {
		this.shippingFacade.update(shipping);
		this.shippingFacade.changeFormValue({ attributeName: 'shippingByPartner', value: event.checked });
		this.shippingFacade.updated();
	}

	public changeReceiptConsentEmailTransfer(event: MatSlideToggleChange, receipt: IReceipt) {
		this.receiptFacade.update(receipt);
		this.receiptFacade.changeFormValue({ attributeName: 'consentEmailTransfer', value: event.checked });
		this.receiptFacade.updated();
	}

	public changeShippingConsentEmailTransfer(event: MatSlideToggleChange, shipping: IShipping) {
		this.shippingFacade.update(shipping);
		this.shippingFacade.changeFormValue({ attributeName: 'consentEmailTransfer', value: event.checked });
		this.shippingFacade.updated();
	}

	public changeEnableSaturdayDelivery(event: MatSlideToggleChange, shipping: IShipping) {
		this.shippingFacade.update(shipping);
		this.shippingFacade.changeFormValue({ attributeName: 'enableSaturdayDelivery', value: event.checked });
		this.shippingFacade.updated();
	}

	public trackByEntity = (index: number, entity: IEntity) => entity._id;
}
