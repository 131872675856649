import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPermission, IPermissionEditForm, IPermissionFilterForm } from '../../models/permission.model';
import { PermissionApiService } from '../../services/api/permission.service';
import { PermissionDialogService } from '../../services/dialog/permission.service';
import { PermissionNotificationService } from '../../services/notification/permission.service';
import { AppState, IPermissionState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPermissionActions } from './permission.actions';
import { PermissionSelector } from './permission.selectors';

@Injectable({
	providedIn: 'root',
})
export class PermissionEffects extends EntityEffects<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PermissionApiService,
		notificationService: PermissionNotificationService,
		dialogService: PermissionDialogService,
		selector: PermissionSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPermissionActions, 'PERMISSION');
	}
}
