import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProductDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-product.component';
import { ProductDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-product.component';
import { ProductDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-product.component';
import { IProduct } from '../../models/product.model';
import { IProductState } from '../../state/app.state';
import { ProductFacade } from '../../state/product/product.facade';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ProductDialogService extends EntityDialogService<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	protected createComponent: ComponentType<any> = ProductDialogAddComponent;
	protected updateComponent: ComponentType<any> = ProductDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ProductDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: ProductFacade) {
		super(matDialog, entityFacade);
	}
}
