import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICustomerState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { ICustomer, ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-customerdialogdelete',
	templateUrl: './entitydialogdelete-customer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CustomerDialogDeleteComponent extends EntityDialogDeleteComponent<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	constructor(entityFacade: CustomerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
