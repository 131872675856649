import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm } from '../../models/shipping.model';
import { ShippingPickupApiService } from '../../services/api/shipping.pickup.service';
import { ShippingPickupDialogService } from '../../services/dialog/shipping.pickup.service';
import { PickupNotificationService } from '../../services/notification/shipping.pickup.service';
import { AppState, IShippingPickupState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPickupActions } from './shipping.pickup.actions';
import { PickupSelector } from './shipping.pickup.selectors';

@Injectable({
	providedIn: 'root',
})
export class PickupEffects extends EntityEffects<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ShippingPickupApiService,
		notificationService: PickupNotificationService,
		dialogService: ShippingPickupDialogService,
		selector: PickupSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPickupActions, 'PICKUP');
	}
}
