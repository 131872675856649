import { Injectable } from '@angular/core';
import { IImage } from 'src/models/image.model';
import { AppState, IImageState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IImageEditForm, IImageFilterForm } from './../../models/image.model';

@Injectable({
	providedIn: 'root',
})
export class ImageSelector extends EntitySelector<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	protected stateSelector = (state: AppState): IImageState => state.image;
}
