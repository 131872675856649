import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleDvd, IArticleDvdEditForm, IArticleDvdFilterForm } from '../../models/article.model';
import { AppState, IArticleDvdState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleDvdActions } from './article.dvd.actions';
import { initialEditFormValue } from './article.dvd.reducer';
import { ArticleDvdSelector } from './article.dvd.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleDvdFacade extends EntityFacade<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleDvdActions;

	constructor(store: Store<AppState>, private dvdSelector: ArticleDvdSelector) {
		super(store, dvdSelector);
	}

	public isSearching$ = this.store.select(this.dvdSelector.isSearching);
	public isExtending$ = this.store.select(this.dvdSelector.isExtending);
	public itemsSearched$ = this.store.select(this.dvdSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.dvdSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleDvdActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
