import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAccountingSummaryAdvancement } from '../../models/accounting-summary.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementApiService extends EntityApiService<IAccountingSummaryAdvancement> {
	protected entityName = 'accounting-advancements';

	constructor(http: HttpClient) {
		super(http);
	}

	public downloadCsv(authToken: string, entity: IAccountingSummaryAdvancement): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'text/csv');

		return this.post<any, any>([this.entityName, entity._id, 'generate-csv'], null, headers, 'blob');
	}

	public filterByReceiptNumbers(receiptNumbers: number[], authToken: string): Observable<IEntityListResponse<IAccountingSummaryAdvancement>> {
		return this.post<IEntityListResponse<IAccountingSummaryAdvancement>, { receiptNumbers: number[] }>([this.entityName, 'receipt'], { receiptNumbers }, this.getHeaders(authToken));
	}
}
