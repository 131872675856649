import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IProcessNode } from '../../models/process-node.model';
import { AppState, IProcessNodeState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { fromProcessNodeActions } from './process-node.actions';
import { initialEditFormValue } from './process-node.reducer';
import { ProcessNodeSelector } from './process-node.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessNodeFacade extends EntityFacade<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromProcessNodeActions;

	constructor(store: Store<AppState>, private processNodeSelector: ProcessNodeSelector) {
		super(store, processNodeSelector);
	}

	public filterByProcess(processId: string): Observable<IProcessNode[]> {
		return this.store.select(this.processNodeSelector.filter(x => x.process == processId));
	}
}
