import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { CustomerDialogService } from '../../services/dialog/customer.service';
import { ICustomerState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ICustomer, ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-customerpage',
	templateUrl: './entitypage-customer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class CustomerPageComponent extends EntityPageComponent<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	public pageTitle$ = new BehaviorSubject('Kunden');
	public columns = ['number', 'firstName', 'lastName', 'email', 'telephone', 'filter'];

	constructor(titleService: Title, entityDialogService: CustomerDialogService, route: ActivatedRoute, public cusomerFacade: CustomerFacade, public processFacade: ProcessFacade, public processNodeFacade: ProcessNodeFacade) {
		super(titleService, entityDialogService, route, cusomerFacade);
	}
}
