import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryAdvancementState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementSelector extends EntitySelector<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementState, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm> {
	protected stateSelector = (state: AppState): IAccountingSummaryAdvancementState => state.accountingSummaryAdvancement;

	public filterByReceiptNumbers = (receiptNumbers: number[]) => createSelector(this.list, list => list.filter(entity => entity.positions.some(position => receiptNumbers.includes(+position.receiptNumber))));
}
