import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAccountingSummaryAdvancement, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryAdvancementState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromAccountingSummaryAdvancementActions } from './accounting-summary.advancement.actions';
import { initialEditFormValue } from './accounting-summary.advancement.reducer';
import { AccountingSummaryAdvancementSelector } from './accounting-summary.advancement.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementFacade extends EntityFacade<IAccountingSummaryAdvancement, IAccountingSummaryAdvancementState, IAccountingSummaryAdvancementEditForm, IAccountingSummaryAdvancementFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAccountingSummaryAdvancementActions;

	constructor(store: Store<AppState>, private accountingSummaryAdvancementSelector: AccountingSummaryAdvancementSelector) {
		super(store, accountingSummaryAdvancementSelector);
	}

	public filterByReceiptNumbers(receiptNumbers: number[]): Observable<IAccountingSummaryAdvancement[]> {
		this.store.dispatch(fromAccountingSummaryAdvancementActions.filterByReceiptNumbers({ receiptNumbers }));
		return this.store.select(this.accountingSummaryAdvancementSelector.filterByReceiptNumbers(receiptNumbers));
	}
}
