import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DhlNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-dhl.component';
import { IShippingDhl } from '../../models/shipping.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DhlNotificationService extends EntityNotificationService<IShippingDhl> {
	protected component: ComponentType<any> = DhlNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
