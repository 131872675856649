import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AccessoryArticleKind } from '../../models/enums/accessoryarticle-kind.enum';
import { AccessoryProductFacade } from '../../state/accessory-product/accessory-product.facade';
import { IAccessoryProductState } from '../../state/app.state';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-accessory-productdialogchange',
	templateUrl: './entitydialogchange-accessory-product.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class AccessoryProductDialogChangeComponent extends EntityDialogChangeComponent<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	public ArticleKinds = AccessoryArticleKind;

	constructor(entityFacade: AccessoryProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
