import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { EntityState, IPageState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromPageActions } from './page.actions';

export const initialValue: IPage = {
	_id: null,
	name: null,
	url: null,
	icon: null,
	redirect: null,
	parent: null,
	isActive: null,
	allowSitemap: null,
	blocks: [
		{
			blockKind: null,
			parameters: [
				{
					name: null,
					value: null,
				},
			],
			queryParameters: [
				{
					name: null,
					parseAsYear: null,
					parseAsDate: null,
				},
			],
		},
	],
	visibility: {
		checkout: null,
		footer: null,
		header: null,
		mobile: null,
	},
	seo: {
		title: null,
		description: null,
	},
};

export const initialEditFormValue: IPageEditForm = {
	_id: null,
	name: null,
	url: null,
	icon: null,
	redirect: null,
	parent: null,
	isActive: null,
	allowSitemap: null,
	blocks: [
		{
			blockKind: null,
			parameters: [
				{
					name: null,
					value: null,
				},
			],
			queryParameters: [
				{
					name: null,
					parseAsYear: null,
					parseAsDate: null,
				},
			],
		},
	],
	visibility: {
		checkout: null,
		footer: null,
		header: null,
		mobile: null,
	},
	seo: {
		title: null,
		description: null,
	},
};

export const initialFilterFormValue: IPageFilterForm = {
	_id: null,
	name: null,
	url: null,
	icon: null,
	redirect: null,
	parent: null,
	isActive: null,
	allowSitemap: null,
	blocks: [
		{
			blockKind: null,
			parameters: [
				{
					name: null,
					value: null,
				},
			],
			queryParameters: [
				{
					name: null,
					parseAsYear: null,
					parseAsDate: null,
				},
			],
		},
	],
	visibility: {
		checkout: null,
		footer: null,
		header: null,
		mobile: null,
	},
	seo: {
		title: null,
		description: null,
	},
};

export const initialState = EntityState.create<IPage, IPageEditForm, IPageFilterForm>('PAGE', initialValue, initialEditFormValue, initialFilterFormValue);
export const pageReducer = EntityReducer.create<IPage, IPageState, IPageEditForm, IPageFilterForm>(initialState, {}, fromPageActions);
