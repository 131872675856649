import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleNewspaper } from '../../models/article.model';
import { IArticleNewspaperState } from '../../state/app.state';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-newspaperdialogdelete',
	templateUrl: './entitydialogdelete-newspaper.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class NewspaperDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	constructor(entityFacade: ArticleNewspaperFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
