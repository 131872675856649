import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { IProcess } from '../../models/process';
import { ProcessApiService } from '../../services/api/process.service';
import { ProcessDialogService } from '../../services/dialog/process.service';
import { ProcessNotificationService } from '../../services/notification/process.service';
import { AppState, IProcessState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { fromSessionActions } from '../session/session.actions';
import { SessionSelector } from '../session/session.selectors';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { fromProcessActions } from './process.actions';
import { ProcessSelector } from './process.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProcessEffects extends EntityEffects<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: ProcessApiService, notificationService: ProcessNotificationService, dialogService: ProcessDialogService, selector: ProcessSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromProcessActions, 'PROCESS');
	}

	public onLoggedIn$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.loggedIn),
			map(() => fromProcessActions.filter())
		)
	);
}
