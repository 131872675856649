import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ArticleKind, IArticle } from '../../models/article.model';
import { AppState, IArticleState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { fromArticleActions } from './article.actions';
import { initialEditFormValue } from './article.reducer';
import { ArticleSelector } from './article.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleFacade extends EntityFacade<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleActions;

	constructor(store: Store<AppState>, entitySelector: ArticleSelector) {
		super(store, entitySelector);
	}

	public updateStoragePlace(articleKind: ArticleKind, entity: IArticle, storagePlaceId: string): void {
		this.store.dispatch(this.actions.updateStoragePlace({ articleKind, entity, storagePlaceId: storagePlaceId }));
	}

	public updateStoragePlaces(articleKind: ArticleKind, storagePlaceId: string): void {
		this.store.dispatch(this.actions.updateStoragePlaces({ articleKind, storagePlaceId }));
	}

	public deleteByFilter(articleKind: ArticleKind): void {
		this.store.dispatch(this.actions.deleteByFilter({ articleKind }));
	}
}
