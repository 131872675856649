import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDocument } from 'src/models/document.model';
import { IDocumentEditForm, IDocumentFilterForm } from '../../models/document.model';
import { AppState, IDocumentState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDocumentActions } from './document.actions';
import { initialEditFormValue } from './document.reducer';
import { DocumentSelector } from './document.selectors';

@Injectable({
	providedIn: 'root',
})
export class DocumentFacade extends EntityFacade<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDocumentActions;

	constructor(store: Store<AppState>, documentSelector: DocumentSelector) {
		super(store, documentSelector);
	}

	public createdDocument(entity: IDocument, file: File): void {
		this.store.dispatch(this.actions.createdDocumentForm({ entity, file }));
	}

	public updatedDocument(entity: IDocument, file: File): void {
		this.store.dispatch(this.actions.updatedDocumentForm({ entity, file }));
	}
}
