import { AppState, IShippingState } from '../app.state';
import { IShipping, IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EntityFacade } from '../entity.facade';
import { fromShippingActions } from './shipping.actions';
import { initialEditFormValue } from './shipping.reducer';
import { ShippingSelector } from './shipping.selectors';

@Injectable({
	providedIn: 'root',
})
export class ShippingFacade extends EntityFacade<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromShippingActions;

	constructor(store: Store<AppState>, shippingSelector: ShippingSelector) {
		super(store, shippingSelector);
	}

	public download(shipping: IShipping): void {
		this.store.dispatch(this.actions.download({ shipping }));
	}

	public print(shipping: IShipping): void {
		this.store.dispatch(this.actions.print({ shipping }));
	}

	public changeShippingKind(shipping: IShipping, shippingKind: string, enableSaturdayDelivery: boolean): void {
		this.store.dispatch(this.actions.changeShippingKind({ shipping, shippingKind, enableSaturdayDelivery }));
	}
}
