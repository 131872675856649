import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICollectionPurchaseTransferPosition } from '../../models/collection-purchase-transfer.model';
import { ICollectionPurchaseTransferState } from '../../state/app.state';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-collection-purchase-transferdialogchange',
	templateUrl: './entitydialogchange-collection-purchase-transfer.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CollectionPurchaseTransferDialogChangeComponent extends EntityDialogChangeComponent<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	public positionColumns = ['receiptDate', 'orderNumber', 'receiptNumber', 'partnerName', 'sAccount', 'hAccount', 'amount', 'tax7', 'tax19', 'actions'];

	constructor(private collectionPurchaseTransferFacade: CollectionPurchaseTransferFacade, matDialogRef: MatDialogRef<any>) {
		super(collectionPurchaseTransferFacade, matDialogRef);
	}

	public removePosition(position: ICollectionPurchaseTransferPosition): void {
		this.collectionPurchaseTransferFacade.removePosition(position);
	}
}
