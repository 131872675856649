import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { AppState, ICollectionPurchaseTransferState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseTransferSelector extends EntitySelector<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	protected stateSelector = (state: AppState): ICollectionPurchaseTransferState => state.collectionPurchaseTransfer;

	public filterByReceiptNumbers = (receiptNumbers: number[]) => createSelector(this.list, list => list.filter(entity => entity.positions.some(position => receiptNumbers.includes(+position.receiptNumber))));
}
