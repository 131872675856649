import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPayment } from '../../models/payment.model';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentApiService extends EntityApiService<IPayment> {
	protected entityName = 'payments';

	constructor(http: HttpClient) {
		super(http);
	}

	public changePaymentKind(payment: IPayment, paymentKind: string, authToken: string): Observable<IEntitySingleResponse<IPayment>> {
		return this.post<IEntitySingleResponse<IPayment>, { paymentKind: string }>([this.entityName, payment._id, 'change-payment-kind'], { paymentKind }, this.getHeaders(authToken));
	}
}
