import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IShippingDhl, IShippingDhlEditForm, IShippingDhlFilterForm } from '../../models/shipping.model';
import { ShippingDhlApiService } from '../../services/api/shipping.dhl.service';
import { ShippingDhlDialogService } from '../../services/dialog/shipping.dhl.service';
import { DhlNotificationService } from '../../services/notification/shipping.dhl.service';
import { AppState, IShippingDhlState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDhlActions } from './shipping.dhl.actions';
import { DhlSelector } from './shipping.dhl.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlEffects extends EntityEffects<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: ShippingDhlApiService, notificationService: DhlNotificationService, dialogService: ShippingDhlDialogService, selector: DhlSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDhlActions, 'DHL');
	}
}
