export enum MediaArticleKind {
	Book = 'Antiquarisches Buch',
	Cd = 'CD',
	Dvd = 'DVD',
	BirthdayBook = 'Persönliches Geburtstagsbuch',
	BirthdayChronicle = 'Persönliche Geburtstags-Chronik',
	Chronicle = 'Jahrgangs-Chronik',
	YearBook = 'Geschenkbuch',
	Magazine = 'Magazin',
	Coin = 'Historisches Zahlungsmittel',
	Newspaper = 'Zeitung',
}
