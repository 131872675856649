import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { AppState, IPageState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPageActions } from './page.actions';
import { initialEditFormValue } from './page.reducer';
import { PageSelector } from './page.selectors';

@Injectable({
	providedIn: 'root',
})
export class PageFacade extends EntityFacade<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	protected initialEditFormValue = initialEditFormValue;

	constructor(store: Store<AppState>, private pageSelector: PageSelector) {
		super(store, pageSelector);
	}

	protected actions = fromPageActions;
}
