import { on } from '@ngrx/store';
import { onNgrxForms } from 'ngrx-forms';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { ICartState } from '../app.state';
import { fromCartActions } from './cart.actions';

const initialState: ICartState = {
	positions: [],
	selected: null,
	isLoading: false,
	isDisabled: false,
	error: null,
	isReceiverDiffering: false,
	consentEmailTransfer: false,
	isPackstation: false,
	customer: null,
	payer: null,
	receiver: null,
	totalPrice: null,
	shippingWithoutInvoice: null,
	taxes: [],
	allowedShippingKinds: [],
	allowShippingAddress: true,
	allowPackstation: true,
	paymentKind: PaymentKind.Paypal,
	shippingKind: ShippingKind.Dhl,
};

export const cartReducer = createRehydrateReducer(
	'cart',
	initialState,
	onNgrxForms(),
	on(fromCartActions.validatePositions, state => ({ ...state, isLoading: true })),
	on(fromCartActions.validatedPositions, state => ({ ...state, isLoading: false })),
	on(fromCartActions.failed, (state, { error }) => ({ ...state, isFiltering: false, isFetching: false, isSuggesting: false, isLoading: false, error }))
);
