import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IProduct } from '../../models/product.model';
import { ProductApiService } from '../../services/api/product.service';
import { ProductDialogService } from '../../services/dialog/product.service';
import { ProductNotificationService } from '../../services/notification/product.service';
import { AppState, IProductState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { fromProductActions } from './product.actions';
import { ProductSelector } from './product.selectors';

@Injectable({
	providedIn: 'root',
})
export class ProductEffects extends EntityEffects<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: ProductApiService, notificationService: ProductNotificationService, dialogService: ProductDialogService, selector: ProductSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromProductActions, 'PRODUCT');
	}
}
