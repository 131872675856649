import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthorizationService } from 'src/services/api/authorization.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { DatabaseApiService } from '../../services/api/database.service';
import { WindowRefService } from '../../services/utility/window.service';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { fromDeviceActions } from '../device/device.actions';
import { PermissionFacade } from '../permission/permission.facade';
import { fromSessionActions } from './session.actions';
import { SessionSelector } from './session.selectors';

@Injectable({
	providedIn: 'root',
})
export class SessionEffects extends BaseEffects {
	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		private permissionFacade: PermissionFacade,
		private sessionSelector: SessionSelector,
		private authorizationService: AuthorizationService,
		private databaseService: DatabaseApiService,
		private windowService: WindowRefService,
		private router: Router
	) {
		super();
	}

	public onAuthenticated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeviceActions.authenticated),
			map(({ authToken, user }) => fromSessionActions.loggedIn({ authToken, user }))
		)
	);

	public onLogin$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.login),
			concatLatestFrom(() => this.store.select(this.sessionSelector.userForm)),
			switchMap(([, userForm]) =>
				this.authorizationService.login(userForm.value.email, userForm.value.password).pipe(
					map(response => fromSessionActions.loggedIn({ authToken: response.token, user: response.user })),
					catchError((response: IServiceErrorResponse) => of(fromSessionActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLogout$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.logout),
			switchMap(() =>
				this.authorizationService.logout().pipe(
					map(response => fromSessionActions.loggedOut()),
					catchError((response: IServiceErrorResponse) => of(fromSessionActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onLoggedOut$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromSessionActions.loggedOut),
				tap(() => this.router.navigate(['/login']))
			),
		{ dispatch: false }
	);

	public onLoggedIn$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromSessionActions.loggedIn),
				tap(() => {
					this.router.navigate(['/start']);
					this.permissionFacade.filter();
				})
			),
		{ dispatch: false }
	);

	public onResetDatabase$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.resetDatabase),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ commit }, authToken]) =>
				this.databaseService.reset(authToken, commit).pipe(
					map(() => fromSessionActions.resettedDatabase()),
					catchError((response: IServiceErrorResponse) => of(fromSessionActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onResettedDatabase$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromSessionActions.resettedDatabase),
				tap(() => this.windowService.nativeWindow.location.reload())
			),
		{ dispatch: false }
	);
}
