import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DvdNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-dvd.component';
import { IArticleDvd } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleDvdNotificationService extends EntityNotificationService<IArticleDvd> {
	protected component: ComponentType<any> = DvdNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
