import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm } from '../../models/payment.model';
import { AppState, IPaymentPurchaseOnAccountState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPurchaseOnAccountActions } from './payment.purchase-on-account.actions';
import { initialEditFormValue } from './payment.purchase-on-account.reducer';
import { PurchaseOnAccountSelector } from './payment.purchase-on-account.selectors';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOnAccountFacade extends EntityFacade<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountState, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPurchaseOnAccountActions;

	constructor(store: Store<AppState>, purchaseOnAccountSelector: PurchaseOnAccountSelector) {
		super(store, purchaseOnAccountSelector);
	}
}
