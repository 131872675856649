import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-image.component';
import { ImageDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-image.component';
import { ImageDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-image.component';
import { IImage } from 'src/models/image.model';
import { IImageState } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { IImageEditForm, IImageFilterForm } from './../../models/image.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ImageDialogService extends EntityDialogService<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	protected createComponent: ComponentType<any> = ImageDialogAddComponent;
	protected updateComponent: ComponentType<any> = ImageDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ImageDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: ImageFacade) {
		super(matDialog, entityFacade);
	}
}
