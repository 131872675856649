import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PageNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-page.component';
import { IPage } from '../../models/page.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PageNotificationService extends EntityNotificationService<IPage> {
	protected component: ComponentType<any> = PageNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
