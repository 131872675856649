import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleMagazine } from '../../models/article.model';
import { IArticleMagazineState } from '../../state/app.state';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { IArticleMagazineEditForm, IArticleMagazineFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-magazinedialogdelete',
	templateUrl: './entitydialogdelete-magazine.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class MagazineDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	constructor(entityFacade: ArticleMagazineFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
