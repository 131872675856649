import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { RegionDialogService } from '../../services/dialog/region.service';
import { IRegionState } from '../../state/app.state';
import { RegionFacade } from '../../state/region/region.facade';
import { IRegion, IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-regionpage',
	templateUrl: './entitypage-region.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class RegionPageComponent extends EntityPageComponent<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	public pageTitle$ = new BehaviorSubject('Regionen');
	public columns = ['name', 'shortName', 'regionKind', 'parentRegion', 'filter'];

	constructor(titleService: Title, entityDialogService: RegionDialogService, route: ActivatedRoute, entityFacade: RegionFacade) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
