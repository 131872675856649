import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountingSummaryDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-accounting-summary.component';
import { AccountingSummaryDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-accounting-summary.component';
import { AccountingSummaryDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-accounting-summary.component';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AccountingSummaryFacade } from '../../state/accounting-summary/accounting-summary.facade';
import { IAccountingSummaryState } from '../../state/app.state';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryDialogService extends EntityDialogService<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	protected createComponent: ComponentType<any> = AccountingSummaryDialogAddComponent;
	protected updateComponent: ComponentType<any> = AccountingSummaryDialogChangeComponent;
	protected removeComponent: ComponentType<any> = AccountingSummaryDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: AccountingSummaryFacade) {
		super(matDialog, entityFacade);
	}
}
