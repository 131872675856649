<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="folderFacade.isSearching$ | async"></mat-progress-bar>
<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Mappe austauschen</h1>
	<mat-dialog-content>
		<form>
			<div class="form-grid">
				<ng-container *ngIf="folderFacade.itemsSearched$ | async as itemsSearched">
					<table mat-table *ngIf="itemsSearched.length" [dataSource]="itemsSearched" class="mat-form-field--span">
						<ng-container matColumnDef="storagePlace">
							<th mat-header-cell *matHeaderCellDef>Preis</th>
							<td mat-cell *matCellDef="let entity">
								{{ entity.product?.price | currency }}
							</td>
						</ng-container>

						<ng-container matColumnDef="product">
							<th mat-header-cell *matHeaderCellDef>Produkt</th>
							<td mat-cell *matCellDef="let entity">
								{{ entity.product?.name }}
							</td>
						</ng-container>

						<ng-container matColumnDef="replace">
							<th mat-header-cell *matHeaderCellDef></th>
							<td mat-cell *matCellDef="let entity">
								<button type="button" mat-icon-button matTooltip="Austauschen" (click)="replaceFolder(entity)">
									<mat-icon svgIcon="check"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columns"></tr>
					</table>
				</ng-container>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<button mat-flat-button color="accent" (click)="folderFacade.search()" class="search-button">Suchen</button>
	</mat-dialog-actions>
</ng-container>
