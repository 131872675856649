import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMediaBrand } from '../../models/media-brand.model';
import { IMediaBrandState } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-media-branddialogadd',
	templateUrl: './entitydialogadd-media-brand.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class MediaBrandDialogAddComponent extends EntityDialogAddComponent<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	constructor(entityFacade: MediaBrandFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
