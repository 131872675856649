import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ICollectionPurchaseTransfer, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm } from '../../models/collection-purchase-transfer.model';
import { ICollectionPurchaseTransferState } from '../../state/app.state';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-collection-purchase-transferdialogadd',
	templateUrl: './entitydialogadd-collection-purchase-transfer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class CollectionPurchaseTransferDialogAddComponent extends EntityDialogAddComponent<ICollectionPurchaseTransfer, ICollectionPurchaseTransferState, ICollectionPurchaseTransferEditForm, ICollectionPurchaseTransferFilterForm> {
	constructor(entityFacade: CollectionPurchaseTransferFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
