import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegionDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-region.component';
import { RegionDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-region.component';
import { RegionDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-region.component';
import { IRegionState } from '../../state/app.state';
import { RegionFacade } from '../../state/region/region.facade';
import { IRegion, IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class RegionDialogService extends EntityDialogService<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	protected createComponent: ComponentType<any> = RegionDialogAddComponent;
	protected updateComponent: ComponentType<any> = RegionDialogChangeComponent;
	protected removeComponent: ComponentType<any> = RegionDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: RegionFacade) {
		super(matDialog, entityFacade);
	}
}
