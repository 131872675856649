import { Injectable } from '@angular/core';
import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { IEntity } from 'src/models/entity.model';
import { IProcessRoute } from '../models/process-route.model';
import { IUser } from '../models/user.model';
import { IServiceError } from '../services/api/api.service';
import { FilterQuery } from '../types/filter';
import { IEntityEditForm } from './../models/entity.model';
import { FilterConnection, FilterOperator, IFilterDescriptor, IProcessNodeCount, SortDescriptors } from './app.state';

export interface IGlobalFormValueChange {
	controlId: string;
	value: any;
}

export interface IFormValueChange {
	attributeName: string;
	value: any;
}

export interface IFilterValueChange {
	attributeName: string;
	value: any;
}

export interface IFilterOperatorChange {
	attributeName: string;
	operator: FilterOperator;
}

export interface IEntityActions<TEntity extends IEntity, TEntityEditForm extends IEntityEditForm> {
	fetch: ActionCreator<
		`${string} Fetch`,
		(props: { ids: string[] }) => {
			ids: string[];
		} & TypedAction<`${string} Fetch`>
	>;
	fetched: ActionCreator<
		`${string} Fetched`,
		(props: { entities: TEntity[] }) => {
			entities: TEntity[];
		} & TypedAction<`${string} Fetched`>
	>;
	missed: ActionCreator<
		`${string} Missed`,
		(props: { ids: string[] }) => {
			ids: string[];
		} & TypedAction<`${string} Missed`>
	>;

	invalidate: ActionCreator<
		`${string} Invalidate`,
		(props: { ids: string[] }) => {
			ids: string[];
		} & TypedAction<`${string} Invalidate`>
	>;
	invalidated: ActionCreator<
		`${string} Invalidated`,
		(props: { ids: string[] }) => {
			ids: string[];
		} & TypedAction<`${string} Invalidated`>
	>;

	filter: ActionCreator<`${string} Filter`, () => TypedAction<`${string} Filter`>>;
	filtered: ActionCreator<
		`${string} Filtered`,
		(props: { entities: TEntity[]; totalCount: number }) => {
			entities: TEntity[];
			totalCount: number;
		} & TypedAction<`${string} Filtered`>
	>;
	increase: ActionCreator<`${string} Increase`, () => TypedAction<`${string} Increase`>>;
	increased: ActionCreator<
		`${string} Increased`,
		(props: { entities: TEntity[]; pageIndex: number }) => {
			entities: TEntity[];
			pageIndex: number;
		} & TypedAction<`${string} Increased`>
	>;
	suggest: ActionCreator<
		`${string} Suggest`,
		(props: { filterConnection: FilterConnection; filterDescriptors: IFilterDescriptor[]; sortDescriptors: SortDescriptors<TEntity> }) => {
			filterConnection: FilterConnection;
			filterDescriptors: IFilterDescriptor[];
			sortDescriptors: SortDescriptors<TEntity>;
		} & TypedAction<`${string} Suggest`>
	>;
	suggested: ActionCreator<
		`${string} Suggested`,
		(props: { entities: TEntity[] }) => {
			entities: TEntity[];
		} & TypedAction<`${string} Suggested`>
	>;
	failed: ActionCreator<
		`${string} Failed`,
		(props: { error: IServiceError }) => {
			error: IServiceError;
		} & TypedAction<`${string} Failed`>
	>;
	selected: ActionCreator<
		`${string} Selected`,
		(props: { selected: TEntity }) => {
			selected: TEntity;
		} & TypedAction<`${string} Selected`>
	>;
	formValueChanged: ActionCreator<
		`${string} Form Value Changed`,
		(props: { changes: IFormValueChange[] }) => {
			changes: IFormValueChange[];
		} & TypedAction<`${string} Form Value Changed`>
	>;
	globalFormValueChanged: ActionCreator<
		`${string} Global Form Value Changed`,
		(props: { changes: IGlobalFormValueChange[] }) => {
			changes: IGlobalFormValueChange[];
		} & TypedAction<`${string} Global Form Value Changed`>
	>;
	filterValueChanged: ActionCreator<
		`${string} Filter Value Changed`,
		(props: { changes: IFilterValueChange[] }) => {
			changes: IFilterValueChange[];
		} & TypedAction<`${string} Filter Value Changed`>
	>;
	filterDescriptorChanged: ActionCreator<
		`${string} Filter Descriptor Changed`,
		(props: { filterDescriptors: IFilterDescriptor[]; ignoreProgrammatic?: boolean }) => {
			filterDescriptors: IFilterDescriptor[];
			ignoreProgrammatic?: boolean;
		} & TypedAction<`${string} Filter Descriptor Changed`>
	>;
	filterConnectionChanged: ActionCreator<
		`${string} Filter Connection Changed`,
		(props: { filterConnection: FilterConnection }) => {
			filterConnection: FilterConnection;
		} & TypedAction<`${string} Filter Connection Changed`>
	>;
	filterOperatorChanged: ActionCreator<
		`${string} Filter Operator Changed`,
		(props: { changes: IFilterOperatorChange[] }) => {
			changes: IFilterOperatorChange[];
		} & TypedAction<`${string} Filter Operator Changed`>
	>;
	filterQueryChanged: ActionCreator<
		`${string} Filter Query Changed`,
		(props: { filterQuery: FilterQuery<TEntity> }) => {
			filterQuery: FilterQuery<TEntity>;
		} & TypedAction<`${string} Filter Query Changed`>
	>;
	sortDescriptorChanged: ActionCreator<
		`${string} Sort Descriptor Changed`,
		(props: { sortDescriptors: SortDescriptors<TEntity> }) => {
			sortDescriptors: SortDescriptors<TEntity>;
		} & TypedAction<`${string} Sort Descriptor Changed`>
	>;
	pageChanged: ActionCreator<
		`${string} Page Changed`,
		(props: { pageIndex: number; pageSize: number }) => {
			pageIndex: number;
			pageSize: number;
		} & TypedAction<`${string} Page Changed`>
	>;
	createForm: ActionCreator<`${string} Create Form`, () => TypedAction<`${string} Create Form`>>;
	createdForm: ActionCreator<`${string} Created Form`, () => TypedAction<`${string} Created Form`>>;
	created: ActionCreator<
		`${string} Created`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Created`>
	>;
	updateForm: ActionCreator<
		`${string} Update Form`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Update Form`>
	>;

	updatedForm: ActionCreator<
		`${string} Updated Form`,
		(props: { closeDialog?: boolean }) => {
			closeDialog?: boolean;
		} & TypedAction<`${string} Updated Form`>
	>;
	updated: ActionCreator<
		`${string} Updated`,
		(props: { entity: TEntity; closeDialog?: boolean }) => {
			entity: TEntity;
			closeDialog?: boolean;
		} & TypedAction<`${string} Updated`>
	>;
	updateAssignee: ActionCreator<
		`${string} Update Assignee`,
		(props: { entity: IEntity; user: IUser }) => {
			entity: IEntity;
			user: IUser;
		} & TypedAction<`${string} Update Assignee`>
	>;
	updateProcess: ActionCreator<
		`${string} Update Process`,
		(props: { entity: IEntity; processRoute: IProcessRoute; comment: string; callback: (entity: TEntity) => void }) => {
			entity: IEntity;
			processRoute: IProcessRoute;
			comment: string;
			callback: (entity: TEntity) => void;
		} & TypedAction<`${string} Update Process`>
	>;
	revertProcess: ActionCreator<
		`${string} Revert Process`,
		(props: { entity: IEntity; comment: string }) => {
			entity: IEntity;
			comment: string;
		} & TypedAction<`${string} Revert Process`>
	>;

	removeForm: ActionCreator<
		`${string} Remove Form`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Remove Form`>
	>;

	removedForm: ActionCreator<`${string} Removed Form`, () => TypedAction<`${string} Removed Form`>>;
	removed: ActionCreator<
		`${string} Removed`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Removed`>
	>;
	abortedForm: ActionCreator<`${string} Aborted Form`, () => TypedAction<`${string} Aborted Form`>>;
	previewForm: ActionCreator<
		`${string} Preview Form`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Preview Form`>
	>;

	previewedForm: ActionCreator<
		`${string} Previewed Form`,
		(props: { entity: TEntity }) => {
			entity: TEntity;
		} & TypedAction<`${string} Previewed Form`>
	>;

	count: ActionCreator<`${string} Count`, () => TypedAction<`${string} Count`>>;
	counted: ActionCreator<
		`${string} Counted`,
		(props: { processNodeCounts: IProcessNodeCount[] }) => {
			processNodeCounts: IProcessNodeCount[];
		} & TypedAction<`${string} Counted`>
	>;
}

@Injectable({
	providedIn: 'root',
})
export class EntityActions {
	public static create<TEntity extends IEntity, TEntityEditForm extends IEntityEditForm>(stateName: string): IEntityActions<TEntity, TEntityEditForm> {
		return {
			fetch: createAction(`${stateName} Fetch`, props<{ ids: string[] }>()),
			fetched: createAction(`${stateName} Fetched`, props<{ entities: TEntity[] }>()),
			missed: createAction(`${stateName} Missed`, props<{ ids: string[] }>()),

			invalidate: createAction(`${stateName} Invalidate`, props<{ ids: string[] }>()),
			invalidated: createAction(`${stateName} Invalidated`, props<{ ids: string[] }>()),

			filter: createAction(`${stateName} Filter`),
			filtered: createAction(`${stateName} Filtered`, props<{ entities: TEntity[]; totalCount: number }>()),

			increase: createAction(`${stateName} Increase`),
			increased: createAction(`${stateName} Increased`, props<{ entities: TEntity[]; pageIndex: number }>()),

			suggest: createAction(`${stateName} Suggest`, props<{ filterConnection: FilterConnection; filterDescriptors: IFilterDescriptor[]; sortDescriptors: SortDescriptors<TEntity> }>()),
			suggested: createAction(`${stateName} Suggested`, props<{ entities: TEntity[] }>()),

			failed: createAction(`${stateName} Failed`, props<{ error: IServiceError }>()),
			selected: createAction(`${stateName} Selected`, props<{ selected: TEntity }>()),

			formValueChanged: createAction(`${stateName} Form Value Changed`, props<{ changes: IFormValueChange[] }>()),
			globalFormValueChanged: createAction(`${stateName} Global Form Value Changed`, props<{ changes: IGlobalFormValueChange[] }>()),
			filterConnectionChanged: createAction(`${stateName} Filter Connection Changed`, props<{ filterConnection: FilterConnection }>()),
			filterValueChanged: createAction(`${stateName} Filter Value Changed`, props<{ changes: IFilterValueChange[] }>()),
			filterOperatorChanged: createAction(`${stateName} Filter Operator Changed`, props<{ changes: IFilterOperatorChange[] }>()),
			filterDescriptorChanged: createAction(`${stateName} Filter Descriptor Changed`, props<{ filterDescriptors: IFilterDescriptor[] }>()),
			filterQueryChanged: createAction(`${stateName} Filter Query Changed`, props<{ filterQuery: FilterQuery<TEntity> }>()),
			sortDescriptorChanged: createAction(`${stateName} Sort Descriptor Changed`, props<{ sortDescriptors: SortDescriptors<TEntity> }>()),
			pageChanged: createAction(`${stateName} Page Changed`, props<{ pageIndex: number; pageSize: number }>()),

			createForm: createAction(`${stateName} Create Form`),
			createdForm: createAction(`${stateName} Created Form`),
			created: createAction(`${stateName} Created`, props<{ entity: TEntity }>()),

			updateForm: createAction(`${stateName} Update Form`, props<{ entity: TEntity }>()),
			updatedForm: createAction(`${stateName} Updated Form`, props<{ closeDialog?: boolean }>()),
			updated: createAction(`${stateName} Updated`, props<{ entity: TEntity; closeDialog?: boolean }>()),
			updateAssignee: createAction(`${stateName} Update Assignee`, props<{ entity: IEntity; user: IUser }>()),
			updateProcess: createAction(`${stateName} Update Process`, props<{ entity: IEntity; processRoute: IProcessRoute; comment: string; callback: (entity: TEntity) => void }>()),
			revertProcess: createAction(`${stateName} Revert Process`, props<{ entity: IEntity; comment: string }>()),

			removeForm: createAction(`${stateName} Remove Form`, props<{ entity: TEntity }>()),
			removedForm: createAction(`${stateName} Removed Form`),
			removed: createAction(`${stateName} Removed`, props<{ entity: TEntity }>()),

			abortedForm: createAction(`${stateName} Aborted Form`),

			previewForm: createAction(`${stateName} Preview Form`, props<{ entity: TEntity }>()),
			previewedForm: createAction(`${stateName} Previewed Form`, props<{ entity: TEntity }>()),

			count: createAction(`${stateName} Count`),
			counted: createAction(`${stateName} Counted`, props<{ processNodeCounts: IProcessNodeCount[] }>()),
		};
	}
}
