import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IMessage } from '../../models/message.model';
import { EntityApiService, IEntitySingleResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MessageApiService extends EntityApiService<IMessage> {
	protected entityName = 'messages';

	constructor(http: HttpClient) {
		super(http);
	}

	public updatePriority(entity: IMessage, priority: number, authToken: string): Observable<IEntitySingleResponse<IMessage>> {
		return this.post<IEntitySingleResponse<IMessage>, { priority: number }>([this.entityName, entity._id, 'priority'], { priority }, this.getHeaders(authToken));
	}
}
