import { createAction, props } from '@ngrx/store';
import { IShipping, IShippingEditForm } from './../../models/shipping.model';

import { EntityActions } from '../entity.actions';

export const fromShippingActions = {
	...EntityActions.create<IShipping, IShippingEditForm>('[Shipping]'),
	download: createAction(`[Shipping] Download`, props<{ shipping: IShipping }>()),
	downloaded: createAction(`[Shipping] Downloaded`, props<{ shipping: IShipping; data: any }>()),
	print: createAction(`[Shipping] Print`, props<{ shipping: IShipping }>()),
	printed: createAction(`[Shipping] Printed`),
	changeShippingKind: createAction(`[Shipping] Change Shipping Kind`, props<{ shipping: IShipping; shippingKind: string; enableSaturdayDelivery: boolean }>()),
	changedShippingKind: createAction(`[Shipping] Changed Shipping Kind`, props<{ shipping: IShipping }>()),
};
