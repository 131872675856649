import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IRegion } from '../../models/region.model';
import { FilterConnection, IFilterDescriptor, IRegionState, SortDescriptors } from '../../state/app.state';
import { RegionFacade } from '../../state/region/region.facade';
import { IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-region-autocompletesingle',
	templateUrl: './entityautocompletesingle-region.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class RegionAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	public placeholder = 'Region';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'STARTSWITH', value }];
	protected override sortDescriptors: () => SortDescriptors<IRegion> = () => ({ name: 1 });

	constructor(entityFacade: RegionFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IRegion): string {
		return entity?.name;
	}
}
