import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IAttachment, IAttachmentEditForm } from 'src/models/attachment.model';
import { IAttachmentState } from 'src/state/app.state';
import { AttachmentFacade } from 'src/state/attachment/attachment.facade';
import { IAttachmentFilterForm } from '../../models/attachment.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-attachmentdialogdelete',
	templateUrl: './entitydialogdelete-attachment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class AttachmentDialogDeleteComponent extends EntityDialogDeleteComponent<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	constructor(entityFacade: AttachmentFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
