import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { ShippingDhlExpressApiService } from '../../services/api/shipping.dhl-express.service';
import { ShippingDhlExpressDialogService } from '../../services/dialog/shipping.dhl-express.service';
import { DhlExpressNotificationService } from '../../services/notification/shipping.dhl-express.service';
import { AppState, IShippingDhlExpressState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDhlExpressActions } from './shipping.dhl-express.actions';
import { DhlExpressSelector } from './shipping.dhl-express.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlExpressEffects extends EntityEffects<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ShippingDhlExpressApiService,
		notificationService: DhlExpressNotificationService,
		dialogService: ShippingDhlExpressDialogService,
		selector: DhlExpressSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDhlExpressActions, 'DHLEXPRESS');
	}
}
