import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDocument, IDocumentEditForm } from 'src/models/document.model';
import { IDocumentState } from 'src/state/app.state';
import { DocumentFacade } from 'src/state/document/document.facade';
import { IDocumentFilterForm } from '../../models/document.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-picturedialogchange',
	templateUrl: './entitydialogchange-document.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DocumentDialogChangeComponent extends EntityDialogChangeComponent<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	private documentFile: File = null;

	constructor(private documentFacade: DocumentFacade, matDialogRef: MatDialogRef<any>) {
		super(documentFacade, matDialogRef);
	}

	public onDocumentChanged($event: any): void {
		this.documentFile = $event.target.files[0];
	}

	public updateDocument(document: IDocument) {
		if (this.documentFile != null) {
			this.documentFacade.updatedDocument(document, this.documentFile);
		} else {
			this.documentFacade.updated();
		}
	}
}
