import { on } from '@ngrx/store';
import { createFormGroupState, FormGroupState, onNgrxForms, reset, setValue, updateGroup, validate, wrapReducerWithFormStateUpdate } from 'ngrx-forms';
import { email, required } from 'ngrx-forms/validation';
import { IUser } from 'src/models/user.model';
import { createRehydrateReducer } from 'src/types/rehydration.reducer';
import { ISessionState } from '../app.state';
import { fromSessionActions } from './session.actions';

const initialState: ISessionState = {
	authToken: null,
	isLoading: false,
	user: null,
	administrateProcessRoutes: false,
	userForm: createFormGroupState<IUser>('USER', {
		email: null,
		password: null,
		processInstance: null,
	}),
	error: null,
};

const resetUserForm = (userForm: FormGroupState<IUser>) => setValue(reset(userForm), { email: null, password: null, createdAt: null, createdBy: null, updatedAt: null, updatedBy: null, processInstance: null });

export const sessionReducer = wrapReducerWithFormStateUpdate(
	createRehydrateReducer(
		'session',
		initialState,
		onNgrxForms(),
		on(fromSessionActions.login, state => ({
			...state,
			isLoading: true,
			authToken: null,
		})),
		on(fromSessionActions.loggedIn, (state, { authToken, user }) => ({
			...state,
			isLoading: false,
			authToken,
			user,
			userForm: resetUserForm(state.userForm),
		})),
		on(fromSessionActions.logout, state => ({
			...state,
			isLoading: true,
			authToken: null,
		})),
		on(fromSessionActions.loggedOut, state => ({
			...state,
			isLoading: false,
			authToken: null,
		})),
		on(fromSessionActions.reset, state => ({
			...state,
			isLoading: false,
			userForm: resetUserForm(state.userForm),
		})),
		on(fromSessionActions.failed, (state, { error }) => ({
			...state,
			isLoading: false,
			error,
		})),
		on(fromSessionActions.changedAdministrateProcessRoutes, (state, { administrateProcessRoutes }) => ({
			...state,
			administrateProcessRoutes,
		})),
		on(fromSessionActions.resetDatabase, state => ({
			...state,
			isLoading: true,
		})),
		on(fromSessionActions.resettedDatabase, state => ({
			...state,
			isLoading: false,
		}))
	),
	state => state.userForm,
	updateGroup<IUser>({
		email: validate(required, email),
	})
);
