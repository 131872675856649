import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IConfirmationDialogData } from '../../services/dialog/dialog.service';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-confirmationdialog',
	templateUrl: './dialog-confirmation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ConfirmationDialogComponent extends DialogComponent<ConfirmationDialogComponent, IConfirmationDialogData> {
	constructor(matDialogRef: MatDialogRef<ConfirmationDialogComponent, IConfirmationDialogData>, @Inject(MAT_DIALOG_DATA) data: IConfirmationDialogData) {
		super(matDialogRef, data);
	}
}
