import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-document.component';
import { DocumentDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-document.component';
import { DocumentDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-document.component';
import { IDocument } from 'src/models/document.model';
import { IDocumentState } from 'src/state/app.state';
import { DocumentFacade } from 'src/state/document/document.facade';
import { IDocumentEditForm, IDocumentFilterForm } from '../../models/document.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class DocumentDialogService extends EntityDialogService<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	protected createComponent: ComponentType<any> = DocumentDialogAddComponent;
	protected updateComponent: ComponentType<any> = DocumentDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DocumentDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DocumentFacade) {
		super(matDialog, entityFacade);
	}
}
