export const CustomNgrxValueConverters = {
	percentage: {
		convertViewToStateValue: (view: number) => (view === null ? null : +(view / 100).toFixed(4)),
		convertStateToViewValue: (state: number) => (state === null ? null : Math.floor(state * 100)),
	},
	price: {
		convertViewToStateValue: (view: string) => (view === null ? null : +view.replace(/\./g, '').replace(',', '.')),
		convertStateToViewValue: (state: number) =>
			state === null
				? null
				: new Intl.NumberFormat('de-DE', {
						style: 'currency',
						currency: 'EUR',
						currencyDisplay: 'code',
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
				  } as any)
						.format(state)
						.replace('EUR', '')
						.trim(),
	},
};
