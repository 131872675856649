import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleStick, IArticleStickEditForm } from '../../models/article.model';
import { IArticleStickState } from '../../state/app.state';
import { ArticleStickFacade } from '../../state/article.stick/article.stick.facade';
import { IArticleStickFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-stickdialogadd',
	templateUrl: './entitydialogadd-stick.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class StickDialogAddComponent extends EntityDialogAddComponent<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	constructor(entityFacade: ArticleStickFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
