import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IMediaBrand } from './../../models/media-brand.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandApiService extends EntityApiService<IMediaBrand> {
	protected entityName = 'media-brands';

	constructor(http: HttpClient) {
		super(http);
	}
}
