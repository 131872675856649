import { box, validate } from 'ngrx-forms';
import { IStockMedia, IStockMediaEditForm, IStockMediaFilterForm, StockKind } from '../../models/stock.model';
import { EntityState, IStockMediaState } from '../app.state';

import { required } from 'ngrx-forms/validation';
import { EntityReducer } from '../entity.reducer';
import { fromMediaStockActions } from './stock.media.actions';

export const initialValue: IStockMedia = {
	_id: null,
	stockType: null,
	comment: null,
	quality: null,
	quantity: null,
	from: null,
	until: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: StockKind.StockMedia,
};

export const initialEditFormValue: IStockMediaEditForm = {
	_id: null,
	stockType: null,
	comment: null,
	quality: null,
	quantity: null,
	from: null,
	until: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: StockKind.StockMedia,
};

export const initialFilterFormValue: IStockMediaFilterForm = {
	_id: null,
	createdAt: null,
	stockType: null,
	comment: null,
	quality: box([]),
	quantity: null,
	from: null,
	until: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: null,
};

export const initialState = EntityState.create<IStockMedia, IStockMediaEditForm, IStockMediaFilterForm>(
	'MEDIASTOCK',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			processInstance: {
				state: 'IN',
			},
		},
	],
	{
		createdAt: -1,
	}
);
export const mediaStockReducer = EntityReducer.create<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
	},
	fromMediaStockActions
);
