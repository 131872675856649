import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseOnAccountDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-purchase-on-account.component';
import { PurchaseOnAccountDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-purchase-on-account.component';
import { PurchaseOnAccountDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-purchase-on-account.component';
import { IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm } from '../../models/payment.model';
import { IPaymentPurchaseOnAccountState } from '../../state/app.state';
import { PurchaseOnAccountFacade } from '../../state/payment.purchase-on-account/payment.purchase-on-account.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentPurchaseOnAccountDialogService extends EntityDialogService<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountState, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {
	protected createComponent: ComponentType<any> = PurchaseOnAccountDialogAddComponent;
	protected updateComponent: ComponentType<any> = PurchaseOnAccountDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PurchaseOnAccountDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PurchaseOnAccountFacade) {
		super(matDialog, entityFacade);
	}
}
