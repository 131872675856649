import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProduct } from '../../models/product.model';
import { IProductState } from '../../state/app.state';
import { ProductFacade } from '../../state/product/product.facade';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-productdialogchange',
	templateUrl: './entitydialogchange-product.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ProductDialogChangeComponent extends EntityDialogChangeComponent<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	constructor(entityFacade: ProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
