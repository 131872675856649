import { IEntity, IEntityEditForm, IEntityFilterForm } from './entity.model';

export interface IPage extends IEntity {
	name: string;
	url: string;
	icon: string;
	redirect: string;
	parent: string;
	isActive: boolean;
	allowSitemap: boolean;
	blocks: IPageBlock[];
	visibility: IPageVisibility;
	seo: IPageSeo;
}

export interface IPageBlock {
	blockKind: BlockKind;
	parameters: IPageBlockParameter[];
	queryParameters: IPageBlockQueryParameter[];
}

export enum BlockKind {
	IntroTeaser = 'Intro Teaser',
	SearchHeader = 'Suche Header',
	Headline = 'Überschrift',
	Text = 'Text',
	Image = 'Bild',
	ImageText = 'Bild mit Text',
	Video = 'Video',
	CustomerReviews = 'Kundenrezensionen',
	CallToAction = 'Call To Action',
	Newsletter = 'Newsletter',
	Accordion = 'Accordion',
	Spacer = 'Spacer',
	Divider = 'Divider',
	VirtualTour = 'VirtualTour',
	Grid = 'Grid',
	Usps = 'Usps',
	HowItWorks = 'HowItWorks',
}

export interface IPageBlockQueryParameter {
	name: string;
	parseAsYear?: boolean;
	parseAsDate?: boolean;
	default?: any;
}

export interface IPageBlockParameter {
	name: string;
	value: any;
}

export interface IPageVisibility {
	header: boolean;
	footer: boolean;
	mobile: boolean;
	checkout: boolean;
}

export interface IPageSeo {
	title: string;
	description: string;
}

export interface IPageEditForm extends IEntityEditForm {
	name: string;
	url: string;
	icon: string;
	redirect: string;
	parent: string;
	isActive: boolean;
	allowSitemap: boolean;
	blocks: IPageBlock[];
	visibility: IPageVisibility;
	seo: IPageSeo;
}

export interface IPageFilterForm extends IEntityFilterForm {
	name: string;
	url: string;
	icon: string;
	redirect: string;
	parent: string;
	isActive: boolean;
	allowSitemap: boolean;
	blocks: IPageBlock[];
	visibility: IPageVisibility;
	seo: IPageSeo;
}
