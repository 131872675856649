import { Injectable } from '@angular/core';
import { AppState, IDeliveryNoteState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from './../../models/delivery-note';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteSelector extends EntitySelector<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	protected stateSelector = (state: AppState): IDeliveryNoteState => state.deliveryNote;
}
