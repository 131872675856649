import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CoinAddDialogComponent } from '../../components/entitydialog/entitydialog-coin-add.component';
import { CoinReplaceDialogComponent } from '../../components/entitydialog/entitydialog-coin-replace.component';
import { CoinDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-coin.component';
import { CoinDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-coin.component';
import { CoinDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-coin.component';
import { IArticleCoin, IArticleCoinEditForm, IArticleCoinFilterForm } from '../../models/article.model';
import { IArticleCoinState } from '../../state/app.state';
import { ArticleCoinFacade } from '../../state/article.coin/article.coin.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleCoinDialogService extends EntityDialogService<IArticleCoin, IArticleCoinState, IArticleCoinEditForm, IArticleCoinFilterForm> {
	protected createComponent: ComponentType<any> = CoinDialogAddComponent;
	protected updateComponent: ComponentType<any> = CoinDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CoinDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = CoinReplaceDialogComponent;
	protected addComponent: ComponentType<any> = CoinAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleCoinFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleCoin>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleCoin>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
