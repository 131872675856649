import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { CollectionPurchaseTransferNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-collection-purchase-transfer.component';
import { ICollectionPurchaseTransfer } from '../../models/collection-purchase-transfer.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseTransferNotificationService extends EntityNotificationService<ICollectionPurchaseTransfer> {
	protected component: ComponentType<any> = CollectionPurchaseTransferNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
