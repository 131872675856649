import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAccessoryProduct } from '../../models/accessory-product.model';
import { AccessoryProductApiService } from '../../services/api/accessory-product.service';
import { AccessoryProductDialogService } from '../../services/dialog/accessory-product.service';
import { AccessoryProductNotificationService } from '../../services/notification/accessory-product.service';
import { AppState, IAccessoryProductState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IAccessoryProductEditForm, IAccessoryProductFilterForm } from './../../models/accessory-product.model';
import { fromAccessoryProductActions } from './accessory-product.actions';
import { AccessoryProductSelector } from './accessory-product.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccessoryProductEffects extends EntityEffects<IAccessoryProduct, IAccessoryProductState, IAccessoryProductEditForm, IAccessoryProductFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: AccessoryProductApiService,
		notificationService: AccessoryProductNotificationService,
		dialogService: AccessoryProductDialogService,
		selector: AccessoryProductSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromAccessoryProductActions, 'ACCESSORYPRODUCT');
	}
}
