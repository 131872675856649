import { createAction, props } from '@ngrx/store';

import { IReceipt } from '../../models/receipt';
import { EntityActions } from '../entity.actions';
import { IReceiptEditForm } from './../../models/receipt';

export const fromReceiptActions = {
	...EntityActions.create<IReceipt, IReceiptEditForm>('[Receipt]'),
	updateReceiver: createAction(`[Receipt] Update Receiver`),
	updatedReceiver: createAction(`[Receipt] Updated Receiver`, props<{ receipt: IReceipt }>()),
	download: createAction(`[Receipt] Download`, props<{ receipt: IReceipt }>()),
	downloaded: createAction(`[Receipt] Downloaded`, props<{ receipt: IReceipt; data: any }>()),
	downloadFirstReminder: createAction(`[Receipt] Download First Reminder`, props<{ receipt: IReceipt }>()),
	downloadedFirstReminder: createAction(`[Receipt] Downloaded First Reminder`, props<{ receipt: IReceipt; data: any }>()),
	downloadSecondReminder: createAction(`[Receipt] Download Second Reminder`, props<{ receipt: IReceipt }>()),
	downloadedSecondReminder: createAction(`[Receipt] Downloaded Second Reminder`, props<{ receipt: IReceipt; data: any }>()),
	print: createAction(`[Receipt] Print`, props<{ receipt: IReceipt }>()),
	printed: createAction(`[Receipt] Printed`),
	followUp: createAction(`[Receipt] Follow Up`),
	followedUp: createAction(`[Receipt] Followed Up`),
};
