import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { MailTemplateDialogService } from 'src/services/dialog/mailtemplate.service';
import { MailTemplateNotificationService } from 'src/services/notification/mailtemplate.service';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { MailTemplateApiService } from '../../services/api/mailtemplate.service';
import { AppState, IMailTemplateState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IMailTemplateEditForm, IMailTemplateFilterForm } from './../../models/mailtemplate.model';
import { fromMailTemplateActions } from './mailtemplate.actions';
import { MailTemplateSelector } from './mailtemplate.selectors';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateEffects extends EntityEffects<IMailTemplate, IMailTemplateState, IMailTemplateEditForm, IMailTemplateFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: MailTemplateApiService,
		notificationService: MailTemplateNotificationService,
		dialogService: MailTemplateDialogService,
		private mailTemplateSelector: MailTemplateSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, mailTemplateSelector, sessionSelector, fromMailTemplateActions, 'MAILTEMPLATE');
	}

	public onTest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromMailTemplateActions.tested),
			concatLatestFrom(() => this.store.select(this.mailTemplateSelector.editForm)),
			concatLatestFrom(() => this.store.select(this.mailTemplateSelector.selected)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[[, editForm], entity], authToken]) =>
				this.apiService.test(entity, editForm.value.receivers, authToken).pipe(
					map(() => fromMailTemplateActions.testSent()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onTestSent$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromMailTemplateActions.testSent),
				tap(() => this.dialogService.closeDialog())
			),
		{
			dispatch: false,
		}
	);
}
