import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from '../../models/shipping.model';
import { AppState, IShippingDhlPremiumState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromDhlPremiumActions } from './shipping.dhl-premium.actions';
import { initialEditFormValue } from './shipping.dhl-premium.reducer';
import { DhlPremiumSelector } from './shipping.dhl-premium.selectors';

@Injectable({
	providedIn: 'root',
})
export class DhlPremiumFacade extends EntityFacade<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromDhlPremiumActions;

	constructor(store: Store<AppState>, dhlPremiumSelector: DhlPremiumSelector) {
		super(store, dhlPremiumSelector);
	}
}
