import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IPermission } from '../../models/permission.model';
import { EntityState, IPermissionState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { fromPermissionActions } from './permission.actions';

export const initialValue: IPermission = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialEditFormValue: IPermissionEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialFilterFormValue: IPermissionFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	displayName: null,
};

export const initialState = { ...EntityState.create<IPermission, IPermissionEditForm, IPermissionFilterForm>('PERMISSION', initialValue, initialEditFormValue, initialFilterFormValue), pageSize: 1000 };
export const permissionReducer = EntityReducer.create<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm>(
	initialState,
	{
		name: validate(required),
	},
	fromPermissionActions
);
