import { IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IAddress } from './address.model';
import { ICompanyInfo } from './contact-details.model';
import { IEntity } from './entity.model';

export interface IConfiguration extends IEntity {
	articleInformation: IArticleInformation;
	customerInformation: ICustomerInformation;
	orderInformation: IOrderInformation;
	receiptInformation: IReceiptInformation;
	shippingInformation: IShippingInformation;
	paymentInformation: IPaymentInformation;
}

export interface IConfigurationEditForm extends IEntityEditForm {
	articleInformation: IArticleInformation;
	customerInformation: ICustomerInformation;
	orderInformation: IOrderInformation;
	receiptInformation: IReceiptInformation;
	shippingInformation: IShippingInformation;
	paymentInformation: IPaymentInformation;
}

export interface IConfigurationFilterForm extends IEntityFilterForm {
	articleInformation: IArticleInformation;
	customerInformation: ICustomerInformation;
	orderInformation: IOrderInformation;
	receiptInformation: IReceiptInformation;
	shippingInformation: IShippingInformation;
	paymentInformation: IPaymentInformation;
}

export interface IPaymentInformation {
	paymentTypes: IPaymentTypeInformation[];
}

export interface IShippingInformation {
	details: ICompanyInfo;
	shippingTypes: IShippingTypeInformation[];
	pickupStations: IPickupStation[];
	length: number;
	width: number;
	height: number;
	weight: number;
}

export interface IReceiptInformation {
	details: ICompanyInfo;
	number: number;
	numberRange: string;
	dunningFee: number;
	defaultTaxRate: number;
	receiptTypes: IReceiptTypeInformation[];
}

export interface IOrderInformation {
	brandName: string;
	number: number;
}

export interface ICustomerInformation {
	number: number;
}

export interface IArticleInformation {
	identifier: number;
}

export interface IShippingTypeInformation {
	price: number;
	priceSaturdayDelivery: number;
	shippingKind: string;
	shippingDaysMin: number;
	shippingDaysMax: number;
	shippedBeforeHour: string;
	password: string;
}

export interface IPaymentTypeInformation {
	price: number;
	paymentKind: string;
	emailText: string;
	pdfText: string;
}

export interface IReceiptTypeInformation {
	receiptKind: string;
	dueDays: number;
	reminderDays: number;
	pdfText: string;
}

export interface IPickupStation {
	type: PickupStationType;
	address: IAddress;
	default: boolean;
}

export enum PickupStationType {
	Grundstraße = 'Grundstraße',
	Clausewitzstraße = 'Clausewitzstraße',
}
