import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { IBundleState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-bundledialogchange',
	templateUrl: './entitydialogchange-bundle.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class BundleDialogChangeComponent extends EntityDialogChangeComponent<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	constructor(entityFacade: BundleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
