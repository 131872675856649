import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IReceiptCancellation } from '../../models/receipt';
import { IReceiptCancellationState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { CancellationFacade } from '../../state/receipt.cancellation/receipt.cancellation.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from './../../models/receipt';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-cancellationdialogchange',
	templateUrl: './entitydialogchange-cancellation.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CancellationDialogChangeComponent extends EntityDialogChangeComponent<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	public addressKinds = Object.values(AddressKind);
	public salutationKinds = Object.values(SalutationKind);

	public paymentColumns = ['paidDate', 'dueAmount', 'paymentKind', 'state', 'actions'];
	public positionColumns = ['image', 'title', 'price', 'positionKind'];

	constructor(
		entityFacade: CancellationFacade,
		matDialogRef: MatDialogRef<any>,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public paymentFacade: PaymentFacade,
		public customerFacade: CustomerFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(entityFacade, matDialogRef);
	}
}
