import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { OrderNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-order.component';
import { IOrder } from '../../models/order.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class OrderNotificationService extends EntityNotificationService<IOrder> {
	protected component: ComponentType<any> = OrderNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
