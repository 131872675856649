import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ITextTemplate, ITextTemplateEditForm, ITextTemplateFilterForm } from '../../models/text-template.model';
import { ITextTemplateState } from '../../state/app.state';
import { TextTemplateFacade } from '../../state/text-template/text-template.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-text-templatedialogchange',
	templateUrl: './entitydialogchange-text-template.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class TextTemplateDialogChangeComponent extends EntityDialogChangeComponent<ITextTemplate, ITextTemplateState, ITextTemplateEditForm, ITextTemplateFilterForm> {
	constructor(entityFacade: TextTemplateFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
