import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CashOnDeliveryDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-cash-on-delivery.component';
import { CashOnDeliveryDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-cash-on-delivery.component';
import { CashOnDeliveryDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-cash-on-delivery.component';
import { IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from '../../models/payment.model';
import { IPaymentCashOnDeliveryState } from '../../state/app.state';
import { CashOnDeliveryFacade } from '../../state/payment.cash-on-delivery/payment.cash-on-delivery.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentCashOnDeliveryDialogService extends EntityDialogService<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm> {
	protected createComponent: ComponentType<any> = CashOnDeliveryDialogAddComponent;
	protected updateComponent: ComponentType<any> = CashOnDeliveryDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CashOnDeliveryDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CashOnDeliveryFacade) {
		super(matDialog, entityFacade);
	}
}
