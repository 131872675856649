import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';

@Directive()
export abstract class EntityDialogDeleteComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> {
	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>, public matDialogRef: MatDialogRef<any>) {}
}
