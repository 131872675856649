import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from '../../models/payment.model';
import { AppState, IPaymentPrepaymentState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPrepaymentActions } from './payment.prepayment.actions';
import { initialEditFormValue } from './payment.prepayment.reducer';
import { PrepaymentSelector } from './payment.prepayment.selectors';

@Injectable({
	providedIn: 'root',
})
export class PrepaymentFacade extends EntityFacade<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPrepaymentActions;

	constructor(store: Store<AppState>, prepaymentSelector: PrepaymentSelector) {
		super(store, prepaymentSelector);
	}
}
