import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleBirthdayBook } from '../../models/article.model';
import { IArticleBirthdayBookState } from '../../state/app.state';
import { ArticleBirthdayBookFacade } from '../../state/article.birthday-book/article.birthday-book.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-birthday-bookdialogchange',
	templateUrl: './entitydialogchange-birthday-book.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class BirthdayBookDialogChangeComponent extends EntityDialogChangeComponent<IArticleBirthdayBook, IArticleBirthdayBookState, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm> {
	constructor(entityFacade: ArticleBirthdayBookFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
