import { Component, Input } from '@angular/core';
import { BaseComponent } from '../base.component';
import { PriorityType } from 'src/models/process-instance-model';
import { IMessage } from 'src/models/message.model';
import { MessageFacade } from 'src/state/message/message.facade';

@Component({
	selector: 'babylon-process-priority-message',
	templateUrl: './process-priority-message.component.html',
})
export class ProcessPriorityMessageComponent extends BaseComponent {
	public PriorityTypes = PriorityType;
	@Input() public message: IMessage;

	constructor(public messageFacade: MessageFacade) {
		super();
	}

	public changeMessagePriority(message: IMessage, priority: number): void {
		this.messageFacade.updatePriority(message, priority);
	}
}
