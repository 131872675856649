import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { IAccountingSummary, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AccountingSummaryNotificationErrorComponent } from './../../components/entitynotificationerror/entitynotificationerror-accounting-summary.component';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryNotificationService extends EntityNotificationService<IAccountingSummary<IAccountingSummaryPosition>> {
	protected component: ComponentType<any> = AccountingSummaryNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
