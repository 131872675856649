<ng-container *ngIf="entity && facade">
	<ng-container *ngIf="(facade.isInvalidated(entity._id) | async) && (facade.isFiltering$ | async); else processState">
		<span class="status"> Wird aktualisiert </span>
	</ng-container>
	<ng-template #processState>
		<span *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode" class="status status--{{ processNode.color }}">
			{{ processNode.displayName }}
		</span>
	</ng-template>
</ng-container>
