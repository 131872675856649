import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { DialogService } from '../dialog/dialog.service';
import { WindowRefService } from './window.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
	constructor(private dialogService: DialogService, private zone: NgZone, private windowService: WindowRefService) {}

	public handleError(error: any) {
		this.zone.run(() =>
			this.dialogService.openErrorDialog({
				error,
				title: 'Es ist ein Fehler aufgetreten',
				text: 'Folgende Informationen helfen dem technischen Support bei der Identifizierung des Problems:',
				onReject: () => this.windowService.nativeWindow.location.reload(),
			})
		);
		console.error('Es ist ein Fehler aufgetreten', error);
	}
}
