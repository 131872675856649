import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { AppState, IUploadedFileState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';
import { fromUploadedFileActions } from './uploadedfile.actions';
import { initialEditFormValue } from './uploadedfile.reducer';
import { UploadedFileSelector } from './uploadedfile.selectors';

@Injectable({
	providedIn: 'root',
})
export class UploadedFileFacade extends EntityFacade<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromUploadedFileActions;

	constructor(store: Store<AppState>, uploadedfileSelector: UploadedFileSelector) {
		super(store, uploadedfileSelector);
	}
}
