import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IDocument } from 'src/models/document.model';
import { DocumentApiService } from 'src/services/api/document.service';
import { DocumentDialogService } from 'src/services/dialog/document.service';
import { DocumentNotificationService } from 'src/services/notification/document.service';
import { IDocumentEditForm, IDocumentFilterForm } from '../../models/document.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { AppState, IDocumentState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromDocumentActions } from './document.actions';
import { DocumentSelector } from './document.selectors';

@Injectable({
	providedIn: 'root',
})
export class DocumentEffects extends EntityEffects<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: DocumentApiService,
		notificationService: DocumentNotificationService,
		dialogService: DocumentDialogService,
		private selector: DocumentSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromDocumentActions, 'DOCUMENT');
	}

	public onCreatedDocumentForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDocumentActions.createdDocumentForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.createDocument(entity, file, authToken).pipe(
					map(result => fromDocumentActions.created({ entity })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdatedDocumentForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDocumentActions.updatedDocumentForm),
			concatLatestFrom(() => this.store.select(this.selector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ file }, entity], authToken]) =>
				this.apiService.updateDocument(entity, file, authToken).pipe(
					map(result => fromDocumentActions.updated({ entity })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);
}
