import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-image.component';
import { ImageDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-image.component';
import { ImageDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-image.component';
import { IUploadedFileState } from 'src/state/app.state';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class UploadedFileDialogService extends EntityDialogService<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm> {
	protected createComponent: ComponentType<any> = ImageDialogAddComponent;
	protected updateComponent: ComponentType<any> = ImageDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ImageDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: UploadedFileFacade) {
		super(matDialog, entityFacade);
	}
}
