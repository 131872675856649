import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleBirthdayBook, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm } from '../../models/article.model';
import { AppState, IArticleBirthdayBookState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleBirthdayBookActions } from './article.birthday-book.actions';
import { initialEditFormValue } from './article.birthday-book.reducer';
import { ArticleBirthdayBookSelector } from './article.birthday-book.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayBookFacade extends EntityFacade<IArticleBirthdayBook, IArticleBirthdayBookState, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleBirthdayBookActions;

	constructor(store: Store<AppState>, private birthdayBookSelector: ArticleBirthdayBookSelector) {
		super(store, birthdayBookSelector);
	}

	public isSearching$ = this.store.select(this.birthdayBookSelector.isSearching);
	public isExtending$ = this.store.select(this.birthdayBookSelector.isExtending);
	public itemsSearched$ = this.store.select(this.birthdayBookSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.birthdayBookSelector.paging), null);

	public search(date: Date): void {
		this.store.dispatch(this.actions.search({ date }));
	}

	public extend(date: Date): void {
		this.store.dispatch(fromArticleBirthdayBookActions.pagingChanged({ date, paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
