import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IOrderState } from 'src/state/app.state';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { OrderDialogService } from '../../services/dialog/order.service';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { UserFacade } from '../../state/user/user.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-orderdetaildialog',
	templateUrl: './entitydialog-order-detail.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class OrderDetailDialogComponent extends EntityDialogComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	public customerColumns = ['salutationKind', 'lastName', 'firstName', 'email', 'actions'];
	public receiptColumns = ['receiptKind', 'number', 'date', 'dueDate', 'totalPrice', 'state', 'actions'];
	public paymentColumns = ['paymentKind', 'dueAmount', 'state', 'actions'];
	public shippingColumns = ['shippingKind', 'state', 'actions'];
	public deliveryNoteColumns = ['deliveryNoteKind', 'receiver', 'actions'];

	constructor(
		public orderDialogService: OrderDialogService,
		public orderFacade: OrderFacade,
		public customerFacade: CustomerFacade,
		public receiptFacade: ReceiptFacade,
		public processNodeFacade: ProcessNodeFacade,
		public userFacade: UserFacade,
		public paymentFacade: PaymentFacade,
		public shippingFacade: ShippingFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		matDialogRef: MatDialogRef<OrderDetailDialogComponent, IEntityDialogData<IOrder>>
	) {
		super(orderFacade, matDialogRef);
	}

	public allReceipts(primaryReceipt: string, secondaryReceipts: string[]): string[] {
		return [primaryReceipt, ...secondaryReceipts];
	}
}
