import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PublisherNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-publisher.component';
import { IPublisher } from 'src/models/publisher.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PublisherNotificationService extends EntityNotificationService<IPublisher> {
	protected component: ComponentType<any> = PublisherNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
