<ng-container *ngIf="userFacade.fetchOne(userId) | async as user">
	<ng-container *ngIf="user.image && imageFacade.fetchOne(user.image) | async as image">
		<ng-container *ngIf="image.file && uploadedFileFacade.fetchOne(image.file) | async as file">
			<babylon-picture class="profile-picture" [image]="image" [file]="file" [width]="30"></babylon-picture>
		</ng-container>
	</ng-container>
	<div *ngIf="!user.image" class="profile-picture">
		<img src="https://ui-avatars.com/api/?background=random&size=24&name={{ user.name }}" />
	</div>
	<ng-container *ngIf="showName">{{ user.name }}</ng-container>
</ng-container>
