import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IImage, IImageEditForm } from 'src/models/image.model';
import { IImageState } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { IImageFilterForm } from './../../models/image.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-imagedialogdelete',
	templateUrl: './entitydialogdelete-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class ImageDialogDeleteComponent extends EntityDialogDeleteComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	constructor(entityFacade: ImageFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
