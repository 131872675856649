import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { CollectionPurchaseNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-collection-purchase.component';
import { IPaymentCollectionPurchase } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseNotificationService extends EntityNotificationService<IPaymentCollectionPurchase> {
	protected component: ComponentType<any> = CollectionPurchaseNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
