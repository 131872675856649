import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPublisher } from 'src/models/publisher.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PublisherApiService extends EntityApiService<IPublisher> {
	protected entityName = 'publishers';

	constructor(http: HttpClient) {
		super(http);
	}
}
