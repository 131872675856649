import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { UploadedFileApiService } from '../../services/api/uploaded-file.service';
import { UploadedFileDialogService } from '../../services/dialog/uploaded-file.service';
import { UploadedFileNotificationService } from '../../services/notification/uploaded-file.service';
import { AppState, IUploadedFileState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';
import { fromUploadedFileActions } from './uploadedfile.actions';
import { UploadedFileSelector } from './uploadedfile.selectors';

@Injectable({
	providedIn: 'root',
})
export class UploadedFileEffects extends EntityEffects<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: UploadedFileApiService,
		notificationService: UploadedFileNotificationService,
		dialogService: UploadedFileDialogService,
		selector: UploadedFileSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromUploadedFileActions, 'UPLOADEDFILE');
	}
}
