import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IBundle } from '../../models/bundle.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class BundleApiService extends EntityApiService<IBundle> {
	public entityName = 'bundles';

	constructor(http: HttpClient) {
		super(http);
	}

	public getAll(): Observable<IBundle[]> {
		return this.get<IEntityListResponse<IBundle>>([this.entityName, 'public'], null).pipe(map(result => result.data));
	}
}
