import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { progressFade } from '../../animations/progressfade.animation';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { FilterConnection, IFilterDescriptor, IPartnerState, SortDescriptors } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-partner-autocompletesingle',
	templateUrl: './entityautocompletesingle-partner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PartnerAutocompleteSingleComponent,
		},
	],
})
export class PartnerAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	public placeholder = 'Partner';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'STARTSWITH', value }];
	protected override sortDescriptors: () => SortDescriptors<IPartner> = () => ({ name: 1 });
	protected minInputLength = 1;

	constructor(entityFacade: PartnerFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IPartner): string {
		return entity?.name;
	}
}
