import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleBook, IArticleBookEditForm, IBookFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleBookApiService } from '../../services/api/article.book.service';
import { ArticleBookDialogService } from '../../services/dialog/article.book.service';
import { ArticleBookNotificationService } from '../../services/notification/article.book.service';
import { AppState, IArticleBookState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleBookActions } from './article.book.actions';
import { ArticleBookSelector } from './article.book.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleBookEffects extends EntityEffects<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleBookApiService,
		notificationService: ArticleBookNotificationService,
		dialogService: ArticleBookDialogService,
		private selector: ArticleBookSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleBookActions, 'BOOK');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBookActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Book,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBookActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBookActions.dayRangeChanged, fromArticleBookActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleBookActions.extended());
				}

				return this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.Book,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBookActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBookActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleBookActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBookActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
