import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShippingCommissionDialogComponent } from '../../components/entitydialog/entitydialog-shipping-commission.component';
import { ShippingPrepareDialogComponent } from '../../components/entitydialog/entitydialog-shipping-prepare.component';
import { ShippingDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-shipping.component';
import { ShippingKind } from '../../models/enums/shipping-kind.enum';
import { IShipping, IShippingDhl, IShippingDhlExpress, IShippingDhlPremium, IShippingPickup, IShippingReturn } from '../../models/shipping.model';
import { IShippingState } from '../../state/app.state';
import { ChangeShippingKindDialogComponent } from './../../components/dialog/dialog-change-shipping-kind.component';
import { IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';
import { ShippingFacade } from './../../state/shipping/shipping.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';
import { ShippingDhlExpressDialogService } from './shipping.dhl-express.service';
import { ShippingDhlPremiumDialogService } from './shipping.dhl-premium.service';
import { ShippingDhlDialogService } from './shipping.dhl.service';
import { ShippingPickupDialogService } from './shipping.pickup.service';
import { ShippingReturnDialogService } from './shipping.return.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingDialogService extends EntityDialogService<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	protected updateComponent: ComponentType<any> = ShippingDialogChangeComponent;
	protected prepareComponent: ComponentType<any> = ShippingPrepareDialogComponent;
	protected commissionComponent: ComponentType<any> = ShippingCommissionDialogComponent;
	protected changeShippingKindComponent: ComponentType<any> = ChangeShippingKindDialogComponent;

	constructor(
		matDialog: MatDialog,
		private shippingFacade: ShippingFacade,
		public dhlDialogService: ShippingDhlDialogService,
		public dhlPremiumDialogService: ShippingDhlPremiumDialogService,
		public dhlExpressDialogService: ShippingDhlExpressDialogService,
		public pickupDialogService: ShippingPickupDialogService,
		public returnDialogService: ShippingReturnDialogService
	) {
		super(matDialog, shippingFacade);
	}

	public openPrepareDialog(data: IEntityDialogData<IShipping>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.prepareComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openChangeShippingKindDialog(data: IEntityDialogData<IShipping>): void {
		const dialogRef = this.openDialog(this.changeShippingKindComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});

		dialogRef.afterClosed().subscribe((result: { entity: IShipping; shippingKind: ShippingKind; enableSaturdayDelivery: boolean }) => {
			if (result) {
				this.shippingFacade.changeShippingKind(result.entity, result.shippingKind, result.enableSaturdayDelivery);
			}
		});
	}

	public openCommissionDialog(data: IEntityDialogData<IShipping>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.commissionComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
		});
	}

	public openUpdateDialog(data: IEntityDialogData<IShipping>): MatDialogRef<any> {
		switch (data.entity.shippingKind) {
			case ShippingKind.Dhl:
				return this.dhlDialogService.openUpdateDialog(data as IEntityDialogData<IShippingDhl>);
			case ShippingKind.DhlExpress:
				return this.dhlExpressDialogService.openUpdateDialog(data as IEntityDialogData<IShippingDhlExpress>);
			case ShippingKind.DhlPremium:
				return this.dhlPremiumDialogService.openUpdateDialog(data as IEntityDialogData<IShippingDhlPremium>);
			case ShippingKind.Pickup:
				return this.pickupDialogService.openUpdateDialog(data as IEntityDialogData<IShippingPickup>);
			case ShippingKind.Return:
				return this.returnDialogService.openUpdateDialog(data as IEntityDialogData<IShippingReturn>);
		}
	}
}
