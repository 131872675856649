import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShipping } from '../../models/shipping.model';
import { IShippingState } from '../../state/app.state';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { IShippingEditForm, IShippingFilterForm } from './../../models/shipping.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-shippingdialogchange',
	templateUrl: './entitydialogchange-shipping.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ShippingDialogChangeComponent extends EntityDialogChangeComponent<IShipping, IShippingState, IShippingEditForm, IShippingFilterForm> {
	constructor(entityFacade: ShippingFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
