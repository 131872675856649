<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="select-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>
									<mat-checkbox (change)="masterSelection$.next($event.checked)" [checked]="selection.hasValue() && isAllSelected$ | async" [indeterminate]="selection.hasValue() && (isAllSelected$ | async) === false">
									</mat-checkbox>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="select-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="select-content">
							<td mat-cell *matCellDef="let entity">
								<mat-checkbox (change)="$event ? selection.toggle(entity) : null" [checked]="selection.isSelected(entity)"> </mat-checkbox>
							</td>
						</ng-container>

						<ng-container matColumnDef="customer-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Kunde</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="customer-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<babylon-customer-autocompletemultiple
										[optional]="true"
										[values]="filterForm.controls[0].controls.customer.value"
										[controlId]="filterForm.controls[0].controls.customer.id"></babylon-customer-autocompletemultiple>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['IN']"
										[value]="filterOperatorForm.controls[0].controls.customer.value"
										[controlId]="filterOperatorForm.controls[0].controls.customer.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="customer-content">
							<td mat-cell *matCellDef="let entity" (click)="update(entity)" class="cursor-pointer">
								<div class="partner-wrapper">
									<div class="partner" *ngIf="entity.partner">
										{{ (partnerFacade.fetchOne(entity.partner) | async)?.name }}
										<babylon-fetch [facade]="partnerFacade" [value]="entity.partner"></babylon-fetch>
									</div>
									<ng-container *ngIf="entity.partner && entity.shippings[0]">
										<ng-container *ngIf="shippingFacade.fetchOne(entity.shippings[0]) | async as shipping">
											<ng-container *ngIf="deliveryNoteFacade.fetchOne(shipping.deliveryNote) | async as deliveryNote"> {{ deliveryNote.receiver.firstName }} {{ deliveryNote.receiver.lastName }} </ng-container>
											<babylon-fetch [facade]="shippingFacade" [value]="entity.shippings[0]"></babylon-fetch>
											<babylon-fetch [facade]="deliveryNoteFacade" [value]="shipping.deliveryNote"></babylon-fetch>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!entity.partner || !entity.shippings[0]">
										<ng-container *ngIf="customerFacade.fetchOne(entity.customer) | async as customer"> {{ customer.firstName }} {{ customer.lastName }} </ng-container>
										<babylon-fetch [facade]="customerFacade" [value]="entity.customer"></babylon-fetch>
									</ng-container>
								</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="number-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="number">Auftragsnummer</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="number-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Auftragsnummer</mat-label>
										<input type="number" matInput [ngrxFormControlState]="filterForm.controls[0].controls.number" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['EQ', 'NE']"
										[value]="filterOperatorForm.controls[0].controls.number.value"
										[controlId]="filterOperatorForm.controls[0].controls.number.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="number-content">
							<td mat-cell *matCellDef="let entity" (click)="update(entity)" class="cursor-pointer">{{ entity.number }}</td>
						</ng-container>

						<ng-container matColumnDef="createdAt-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="createdAt">Bestelldatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="createdAt-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Bestelldatum</mat-label>
										<input matInput [matDatepicker]="createdAt" [ngrxFormControlState]="filterForm.controls[0].controls.createdAt" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="createdAt"></mat-datepicker-toggle>
										<mat-datepicker #createdAt></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.createdAt.value"
										[controlId]="filterOperatorForm.controls[0].controls.createdAt.id"
										[availableOptions]="['DATEEQ', 'GTE', 'LTE', 'GT', 'LT']"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="createdAt-content">
							<td mat-cell *matCellDef="let entity">{{ entity.createdAt | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm (dd)' }}</td>
						</ng-container>

						<ng-container matColumnDef="totalPrice-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Auftragswert</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalPrice-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Auftragswert</mat-label>
										<input type="number" matInput disabled />
									</mat-form-field>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalPrice-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
									{{ receipt.totalPrice | currency }}
								</ng-container>
								<babylon-fetch [facade]="receiptFacade" [value]="entity.primaryReceipt"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="paymentKind-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Zahlungsart</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="paymentKind-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="paymentKind-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
									<ng-container *ngIf="paymentFacade.fetchOne(receipt.payments[0]) | async as payment">
										{{ payment.paymentKind }}
									</ng-container>
									<babylon-fetch [facade]="paymentFacade" [value]="receipt.payments[0]"></babylon-fetch>
								</ng-container>
								<babylon-fetch [facade]="receiptFacade" [value]="entity.primaryReceipt"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="orderState-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="orderState">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="orderState-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="orderState-content">
							<td mat-cell *matCellDef="let entity" class="multi-state">
								<babylon-process-state [entity]="entity" [facade]="entityFacade"></babylon-process-state>
								<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
									<ng-container *ngIf="paymentFacade.fetchOne(receipt.payments[0]) | async as payment">
										&nbsp;
										<babylon-process-state [entity]="payment" [facade]="paymentFacade"></babylon-process-state>
										<babylon-fetch [facade]="processNodeFacade" [value]="payment.processInstance.state"> </babylon-fetch>
									</ng-container>
									<babylon-fetch [facade]="paymentFacade" [value]="receipt.payments[0]"></babylon-fetch>
								</ng-container>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"> </babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="prio-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="prio">Priorität</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="prio-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Priorität</mat-label>
										<mat-select matInput [ngrxFormControlState]="filterForm.controls[0].controls.processInstance.controls.priority">
											<mat-option [value]="null"></mat-option>
											<mat-option [value]="priorityType.value" *ngFor="let priorityType of PriorityTypes | keyvalue: returnZero">
												<babylon-process-priority-order [priority]="priorityType.value"></babylon-process-priority-order
											></mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="prio-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-priority-order [priority]="entity.processInstance.priority"></babylon-process-priority-order>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
									<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
										<mat-icon svgIcon="pencil"></mat-icon>
									</button>
								</ng-container>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent" [class.highlight]="(highlightedId$ | async) == row._id"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons" *ngIf="selection.hasValue()">
							<babylon-user-autocompletesingle (selected)="changeAssignee($event)" placeholder="Bearbeiter ändern" [optional]="true"> </babylon-user-autocompletesingle>
							<mat-form-field appearance="outline" class="margin-top">
								<mat-label>Priorität ändern</mat-label>
								<mat-select matInput (selectionChange)="changePriority($event.value)">
									<mat-option [value]="priorityType.value" *ngFor="let priorityType of PriorityTypes | keyvalue: returnZero">
										<babylon-process-priority-order [priority]="priorityType.value"></babylon-process-priority-order>
									</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
