import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { IMessageState } from '../../state/app.state';
import { MessageFacade } from '../../state/message/message.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-messagedialogdelete',
	templateUrl: './entitydialogdelete-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class MessageDialogDeleteComponent extends EntityDialogDeleteComponent<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	constructor(entityFacade: MessageFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
