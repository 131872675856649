import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IAccountingSummaryPosition } from './../../models/accounting-summary.model';
import { fromAccountingSummaryActions } from './accounting-summary.actions';
import { initialEditFormValue } from './accounting-summary.reducer';
import { AccountingSummarySelector } from './accounting-summary.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryFacade extends EntityFacade<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAccountingSummaryActions;

	constructor(store: Store<AppState>, entitySelector: AccountingSummarySelector) {
		super(store, entitySelector);
	}

	public downloadCsv(entity: IAccountingSummary<IAccountingSummaryPosition>) {
		this.store.dispatch(this.actions.downloadCsv({ entity }));
	}
}
