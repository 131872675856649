import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { IPartnerState } from '../../state/app.state';
import { PartnerFacade } from '../../state/partner/partner.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-partnerdialogdelete',
	templateUrl: './entitydialogdelete-partner.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class PartnerDialogDeleteComponent extends EntityDialogDeleteComponent<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	constructor(entityFacade: PartnerFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
