import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewspaperAddDialogComponent } from '../../components/entitydialog/entitydialog-newspaper-add.component';
import { NewspaperReplaceDialogComponent } from '../../components/entitydialog/entitydialog-newspaper-replace.component';
import { NewspaperDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-newspaper.component';
import { NewspaperDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-newspaper.component';
import { NewspaperDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-newspaper.component';
import { IArticleNewspaper, IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from '../../models/article.model';
import { IArticleNewspaperState } from '../../state/app.state';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperDialogService extends EntityDialogService<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	protected createComponent: ComponentType<any> = NewspaperDialogAddComponent;
	protected updateComponent: ComponentType<any> = NewspaperDialogChangeComponent;
	protected removeComponent: ComponentType<any> = NewspaperDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = NewspaperReplaceDialogComponent;
	protected addComponent: ComponentType<any> = NewspaperAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleNewspaperFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleNewspaper>): MatDialogRef<any> {
		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '800px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleNewspaper>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '800px',
			data,
		});
	}
}
