import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticle } from '../../models/article.model';
import { PriorityType } from '../../models/process-instance-model';
import { IArticleState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-articledialogchange',
	templateUrl: './entitydialogchange-article.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ArticleDialogChangeComponent extends EntityDialogChangeComponent<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	constructor(entityFacade: ArticleFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public PriorityTypes = PriorityType;
}
