import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPermission } from '../../models/permission.model';
import { IPermissionState } from '../../state/app.state';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-permissiondialogdelete',
	templateUrl: './entitydialogdelete-permission.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class PermissionDialogDeleteComponent extends EntityDialogDeleteComponent<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	constructor(entityFacade: PermissionFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
