<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="orderFacade.isSearching$ | async"></mat-progress-bar>
<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Auftragssuche</h1>
<mat-dialog-content>
	<form>
		<div class="form-grid order-search">
			<mat-form-field appearance="outline">
				<input
					type="text"
					matInput
					placeholder="Kundendaten, Auftragsnummer, Belegnummer, Kundennummer, Sendungsnummer"
					#searchValue
					[value]="orderFacade.searchValue$ | async"
					(keyup)="orderFacade.changeSearchValue(searchValue.value)" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Ergebnisanzahl</mat-label>
				<mat-select [value]="orderFacade.searchLimit$ | async" (selectionChange)="orderFacade.changeSearchLimit($event.value)">
					<mat-option [value]="20">Standard</mat-option>
					<mat-option [value]="999">Vollständig</mat-option>
				</mat-select>
			</mat-form-field>
			<ng-container *ngIf="orderFacade.totalCountSearched$ | async as totalCountSearched">
				<ng-container *ngIf="orderFacade.listSearched$ | async as listSearched">
					<table mat-table *ngIf="listSearched.length > 0" [dataSource]="listSearched" class="mat-form-field--span">
						<ng-container matColumnDef="customer-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Kunde</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="customer-content">
							<td mat-cell *matCellDef="let entity" class="cursor-pointer">
								<div class="partner-wrapper">
									<div class="partner" *ngIf="entity.partner">
										{{ (partnerFacade.fetchOne(entity.partner) | async)?.name }}
										<babylon-fetch [facade]="partnerFacade" [value]="entity.partner"></babylon-fetch>
									</div>
									<ng-container *ngIf="entity.partner && entity.shippings[0]">
										<ng-container *ngIf="shippingFacade.fetchOne(entity.shippings[0]) | async as shipping">
											<ng-container *ngIf="deliveryNoteFacade.fetchOne(shipping.deliveryNote) | async as deliveryNote"> {{ deliveryNote.receiver.firstName }} {{ deliveryNote.receiver.lastName }} </ng-container>
											<babylon-fetch [facade]="shippingFacade" [value]="entity.shippings[0]"></babylon-fetch>
											<babylon-fetch [facade]="deliveryNoteFacade" [value]="shipping.deliveryNote"></babylon-fetch>
										</ng-container>
									</ng-container>
									<ng-container *ngIf="!entity.partner || !entity.shippings[0]">
										<ng-container *ngIf="customerFacade.fetchOne(entity.customer) | async as customer">
											{{ customer.firstName }} {{ customer.lastName }}
											<span class="debt-collection-indicator" *ngIf="orderFacade.getCustomerWarning(customer) | async as warning"><mat-icon svgIcon="alert-circle" [matTooltip]="warning.message"></mat-icon></span>
										</ng-container>
										<babylon-fetch [facade]="customerFacade" [value]="entity.customer"></babylon-fetch>
									</ng-container>
								</div>
							</td>
						</ng-container>

						<ng-container matColumnDef="number-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Auftragsnummer</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="number-content">
							<td mat-cell *matCellDef="let entity" class="cursor-pointer">
								<a [routerLink]="['/entity', 'order', 'change', entity._id]" target="_blank">{{ entity.number }}</a>
							</td>
						</ng-container>

						<ng-container matColumnDef="createdAt-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Bestelldatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="createdAt-content">
							<td mat-cell *matCellDef="let entity">{{ entity.createdAt | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm (dd)' }}</td>
						</ng-container>

						<ng-container matColumnDef="totalPrice-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Auftragswert</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalPrice-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="receiptFacade.fetchOne(entity.primaryReceipt) | async as receipt">
									{{ receipt.totalPrice | currency }}
								</ng-container>
								<babylon-fetch [facade]="receiptFacade" [value]="entity.primaryReceipt"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="orderState-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="orderState-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-state [entity]="entity" [facade]="orderFacade"></babylon-process-state>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"> </babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="prio-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Priorität</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="prio-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-priority-order [priority]="entity.processInstance.priority"></babylon-process-priority-order>
							</td>
						</ng-container>

						<ng-container matColumnDef="edit-header">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="edit-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<button mat-icon-button (click)="orderDialogService.openDetailDialog(entity)" matTooltip="Auftragsdetails">
									<mat-icon svgIcon="open-in-new"></mat-icon>
								</button>
								<button mat-icon-button (click)="orderDialogService.openPreviewDialog({ entity })" matTooltip="Auftragshistorie">
									<mat-icon svgIcon="history"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
					<p class="mat-form-field--span">
						<small *ngIf="totalCountSearched > listSearched.length">Es werden maximal {{ listSearched.length }} von insgesamt {{ totalCountSearched }} Ergebnissen zu deiner Suchanfrage angezeigt.</small>
						<small *ngIf="totalCountSearched <= listSearched.length">Es wurden {{ totalCountSearched }} Ergebnisse zu deiner Suchanfrage gefunden.</small>
					</p>
				</ng-container>
			</ng-container>
		</div>
	</form>
</mat-dialog-content>
