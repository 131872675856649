<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Auftragsdetails</h1>
<mat-dialog-content>
	<ng-container *ngIf="orderFacade.selected$ | async as entity">
		<div class="content-wrapper">
			<h2 class="mat-h4">Kunde <mat-spinner [@progressFade] diameter="15" *ngIf="customerFacade.isFetching$ | async"></mat-spinner></h2>
			<ng-container *ngIf="customerFacade.fetchOne(entity.customer) | async as customer">
				<table class="mat-table">
					<tr class="mat-header-row">
						<th class="mat-header-cell">Kundennummer</th>
						<th class="mat-header-cell">Name</th>
						<th class="mat-header-cell">Telefonnummer</th>
						<th class="mat-header-cell">E-Mail</th>
						<th class="mat-header-cell"></th>
					</tr>
					<tr class="mat-row">
						<td class="mat-cell">{{ customer.number }}</td>
						<td class="mat-cell">{{ customer.salutationKind }} {{ customer.firstName }} {{ customer.lastName }}</td>
						<td class="mat-cell">{{ customer.telephone }}</td>
						<td class="mat-cell">{{ customer.email }}</td>
					</tr>
				</table>
			</ng-container>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4 headline-flex">
				<div>
					Belege &amp; Zahlungen
					<mat-spinner diameter="15" *ngIf="(receiptFacade.isFetching$ | async) || (receiptFacade.isFiltering$ | async) || (paymentFacade.isFetching$ | async) || (paymentFacade.isFiltering$ | async)"> </mat-spinner>
				</div>
			</h2>

			<table mat-table [dataSource]="receiptFacade.fetchMany(allReceipts(entity.primaryReceipt, entity.secondaryReceipts)) | async" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="receiptKind">
					<th mat-header-cell *matHeaderCellDef>Belegtyp/Zahlart</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">{{ receipt.receiptKind }}</td>
				</ng-container>

				<ng-container matColumnDef="number">
					<th mat-header-cell *matHeaderCellDef>Belegnummer</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">{{ receipt.number }}</td>
				</ng-container>

				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef>Belegdatum</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">{{ receipt.date | amDateFormat: 'DD.MM.YYYY (dd)' }}</td>
				</ng-container>

				<ng-container matColumnDef="dueDate">
					<th mat-header-cell *matHeaderCellDef>Fällig am</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header">
						{{ receipt.dueDate | amDateFormat: 'DD.MM.YYYY (dd)' }}
						<ng-container *ngIf="receipt.dueDateDelayed && receipt.dueDateDelayed != receipt.dueDate"> &rarr; {{ receipt.dueDateDelayed | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
					</td>
				</ng-container>

				<ng-container matColumnDef="totalPrice">
					<th mat-header-cell *matHeaderCellDef>Betrag</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header" width="100">{{ receipt.totalPrice | currency }}</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let receipt" class="detail-header align-right" width="200">
						<babylon-process-state [entity]="receipt" [facade]="receiptFacade"></babylon-process-state>
						<babylon-fetch [facade]="processNodeFacade" [value]="receipt.processInstance.state"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="payments">
					<td [attr.colspan]="receiptColumns.length" mat-cell *matCellDef="let receipt" class="detail-cell">
						<table mat-table [dataSource]="paymentFacade.fetchMany(receipt.payments) | async">
							<ng-container matColumnDef="paymentKind">
								<td mat-cell *matCellDef="let payment">
									{{ payment.paymentKind }}
								</td>
							</ng-container>

							<ng-container matColumnDef="dueAmount">
								<td mat-cell *matCellDef="let payment" width="85">{{ payment.dueAmount | currency }}</td>
							</ng-container>

							<ng-container matColumnDef="state">
								<td mat-cell *matCellDef="let payment" class="align-right" width="200">
									<babylon-process-state [entity]="payment" [facade]="paymentFacade"></babylon-process-state>
									<babylon-fetch [facade]="processNodeFacade" [value]="payment.processInstance.state"> </babylon-fetch>
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let payment" class="align-right" width="250">
									<ng-container *ngIf="processNodeFacade.fetchOne(payment.processInstance.state) | async as processNode">
										<babylon-process-buttons [facade]="paymentFacade" [entity]="payment" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
									</ng-container>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: paymentColumns"></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let receipt" class="detail-header align-right" width="250">
						<babylon-process-buttons [facade]="receiptFacade" [entity]="receipt" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
						<button mat-icon-button matTooltip="Drucken" (click)="receiptFacade.print(receipt)" [disabled]="receiptFacade.isFetching$ | async">
							<mat-icon svgIcon="printer-outline"></mat-icon>
						</button>
						<button mat-icon-button matTooltip="Herunterladen" [matMenuTriggerFor]="receiptMenu">
							<mat-icon svgIcon="file-download-outline"></mat-icon>
						</button>
						<mat-menu #receiptMenu="matMenu">
							<button mat-menu-item (click)="receiptFacade.download(receipt)">Beleg</button>
							<button mat-menu-item (click)="receiptFacade.downloadFirstReminder(receipt)">Zahlungserinnerung</button>
							<button mat-menu-item (click)="receiptFacade.downloadSecondReminder(receipt)">Mahnung</button>
						</mat-menu>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="receiptColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: receiptColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: ['payments']" class="detail-row"></tr>
			</table>
		</div>

		<div class="content-wrapper">
			<h2 class="mat-h4">
				Lieferungen & Lieferscheine
				<mat-spinner [@progressFade] diameter="15" *ngIf="(shippingFacade.isFetching$ | async) || (deliveryNoteFacade.isFetching$ | async) || (shippingFacade.isFiltering$ | async) || (deliveryNoteFacade.isFiltering$ | async)">
				</mat-spinner>
			</h2>
			<table mat-table [dataSource]="shippingFacade.fetchMany(entity.shippings) | async" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="shippingKind">
					<th mat-header-cell *matHeaderCellDef>Lieferart/Lieferschein</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header">{{ shipping.shippingKind }}</td>
				</ng-container>

				<ng-container matColumnDef="state">
					<th mat-header-cell *matHeaderCellDef class="align-right">Status</th>
					<td mat-cell *matCellDef="let shipping" class="detail-header align-right" width="200">
						<babylon-process-state [entity]="shipping" [facade]="shippingFacade"></babylon-process-state>
						<babylon-fetch [facade]="processNodeFacade" [value]="shipping.processInstance.state"> </babylon-fetch>
					</td>
				</ng-container>

				<ng-container matColumnDef="deliveryNotes">
					<td [attr.colspan]="shippingColumns.length" mat-cell *matCellDef="let shipping" class="detail-cell">
						<table mat-table [dataSource]="deliveryNoteFacade.fetchMany([shipping.deliveryNote]) | async">
							<ng-container matColumnDef="deliveryNoteKind">
								<td mat-cell *matCellDef="let deliveryNote">Lieferschein</td>
							</ng-container>

							<ng-container matColumnDef="receiver">
								<td mat-cell *matCellDef="let deliveryNote">
									{{ deliveryNote.receiver.salutationKind }} {{ deliveryNote.receiver.firstName }} {{ deliveryNote.receiver.lastName }},
									<ng-container *ngIf="deliveryNote.receiver.company">{{ deliveryNote.receiver.company }}, </ng-container> {{ deliveryNote.receiver.address.street }} {{ deliveryNote.receiver.address.streetNumber }},
									{{ deliveryNote.receiver.address.postCode }} {{ deliveryNote.receiver.address.city }}, {{ deliveryNote.receiver.address.country }}
								</td>
							</ng-container>

							<ng-container matColumnDef="actions">
								<td mat-cell *matCellDef="let deliveryNote" class="align-right" width="250">
									<button mat-icon-button matTooltip="Drucken" (click)="deliveryNoteFacade.print(deliveryNote)" [disabled]="receiptFacade.isFetching$ | async">
										<mat-icon svgIcon="printer-outline"></mat-icon>
									</button>
									<button mat-icon-button matTooltip="Herunterladen" (click)="deliveryNoteFacade.download(deliveryNote)">
										<mat-icon svgIcon="file-download-outline"></mat-icon>
									</button>
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: deliveryNoteColumns"></tr>
						</table>
					</td>
				</ng-container>

				<ng-container matColumnDef="actions">
					<th mat-header-cell *matHeaderCellDef></th>
					<td mat-cell *matCellDef="let shipping" class="detail-header align-right" width="250">
						<babylon-process-buttons [facade]="shippingFacade" [entity]="shipping" mode="icon" (executed)="entityFacade.invalidate(entity)"></babylon-process-buttons>
						<ng-container *ngIf="processNodeFacade.fetchOne(shipping.processInstance.state) | async as processNode">
							<button
								mat-icon-button
								matTooltip="Drucken"
								(click)="shippingFacade.print(shipping)"
								[disabled]="(processNode.name != 'ShippingCommissioned' && processNode.name != 'ShippingShipped') || (shippingFacade.isFetching$ | async)">
								<mat-icon svgIcon="printer-outline"></mat-icon>
							</button>
							<button mat-icon-button matTooltip="Herunterladen" (click)="shippingFacade.download(shipping)" [disabled]="processNode.name != 'ShippingCommissioned' && processNode.name != 'ShippingShipped'">
								<mat-icon svgIcon="file-download-outline"></mat-icon>
							</button>
						</ng-container>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="shippingColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: shippingColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: ['deliveryNotes']" class="detail-row"></tr>
			</table>
		</div>
	</ng-container>
</mat-dialog-content>
