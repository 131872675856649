import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseResetDialogComponent } from '../../components/dialog/dialog-database-reset.component';
import { DialogService } from './dialog.service';

@Injectable({
	providedIn: 'root',
})
export class DatabaseDialogService extends DialogService {
	protected resetComponent: ComponentType<any> = DatabaseResetDialogComponent;

	constructor(matDialog: MatDialog) {
		super(matDialog);
	}

	public openResetDialog(): void {
		this.openDialog(this.resetComponent, {
			width: '650px',
		});
	}
}
