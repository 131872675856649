<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Zustand ändern</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-form-field>
				<mat-label>Name</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.displayName" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Name (intern)</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Version</mat-label>
				<input type="number" matInput [ngrxFormControlState]="editForm.controls.version" />
			</mat-form-field>
		</form>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="primary" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
		<button mat-button color="primary" (click)="matDialogRef.close()">Abbrechen</button>
	</div>
</ng-container>
