import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleChronicle, IArticleChronicleEditForm, IExpandedArticleChronicle } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleChronicleActions = {
	...EntityActions.create<IArticleChronicle, IArticleChronicleEditForm>('[Chronicle]'),
	search: createAction(`[Chronicle] Search`, props<{ date: Date }>()),
	searched: createAction(`[Chronicle] Searched`, props<{ date: Date; entities: IExpandedArticleChronicle[] }>()),
	extended: createAction(`[Chronicle] Extended`),
	pagingChanged: createAction(`[Chronicle] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[Chronicle] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
