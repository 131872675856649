import { EntityState, IPublicationCycleState } from '../app.state';
import { IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';

import { EntityReducer } from '../entity.reducer';
import { IPublicationCycle } from '../../models/publication-cycle.model';
import { fromPublicationCycleActions } from './publication-cycle.actions';
import { required } from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';

export const initialValue: IPublicationCycle = {
	_id: null,
	processInstance: null,
	name: null,
	cronExpression: null,
};

export const initialEditFormValue: IPublicationCycleEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	cronExpression: null,
};

export const initialFilterFormValue: IPublicationCycleFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	cronExpression: null,
};

export const initialState = EntityState.create<IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm>('PUBLICATIONCYCLE', initialValue, initialEditFormValue, initialFilterFormValue, [{}], {
	name: 1,
});

export const publicationCycleReducer = EntityReducer.create<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm>(
	initialState,
	{
		name: validate(required),
		cronExpression: validate(required),
	},
	fromPublicationCycleActions
);
