import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IPage, IPageFilterForm } from 'src/models/page.model';
import { PageDialogService } from 'src/services/dialog/page.service';
import { IPageState } from 'src/state/app.state';
import { PageFacade } from 'src/state/page/page.facade';
import { IPageEditForm } from '../../models/page.model';
import { ImageFacade } from '../../state/image/image.facade';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-pagepage',
	templateUrl: './entitypage-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class PagePageComponent extends EntityPageComponent<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	public pageTitle$ = new BehaviorSubject('Landing-Pages');
	public columns = ['name', 'parent', 'url', 'visibility', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: PageDialogService,
		route: ActivatedRoute,
		entityFacade: PageFacade,
		public permissionFacade: PermissionFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
