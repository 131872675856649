import { Injectable } from '@angular/core';
import { IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryCustomerState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerSelector extends EntitySelector<IAccountingSummaryCustomer, IAccountingSummaryCustomerState, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm> {
	protected stateSelector = (state: AppState): IAccountingSummaryCustomerState => state.accountingSummaryCustomer;
}
