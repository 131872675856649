import { Injectable } from '@angular/core';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { AppState, IUploadedFileState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';

@Injectable({
	providedIn: 'root',
})
export class UploadedFileSelector extends EntitySelector<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm> {
	protected stateSelector = (state: AppState): IUploadedFileState => state.uploadedFile;
}
