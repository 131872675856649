import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { IRegion } from '../../models/region.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { RegionApiService } from '../../services/api/region.service';
import { RegionDialogService } from '../../services/dialog/region.service';
import { RegionNotificationService } from '../../services/notification/region.service';
import { AppState, IRegionState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { fromRegionActions } from './region.actions';
import { RegionSelector } from './region.selectors';

@Injectable({
	providedIn: 'root',
})
export class RegionEffects extends EntityEffects<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: RegionApiService,
		notificationService: RegionNotificationService,
		dialogService: RegionDialogService,
		private selector: RegionSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromRegionActions, 'REGION');
	}

	public onNameChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromRegionActions.nameChanged),
			debounceTime(500),
			map(({ name }) => {
				if (name != null && name.length >= 3) {
					return fromRegionActions.search();
				}

				return fromRegionActions.suggested({ entities: [] });
			})
		)
	);

	public onSearch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromRegionActions.search),
			concatLatestFrom(() => this.store.select(this.selector.name)),
			switchMap(([, name]) =>
				this.apiService.suggest({ name }).pipe(
					map(list => fromRegionActions.searched({ entities: list })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUnselected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromRegionActions.selected),
			filter(({ selected }) => selected == null),
			map(() => fromRegionActions.suggested({ entities: [] }))
		)
	);
}
