import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IStoragePlace } from '../../models/storage-place.model';
import { FilterConnection, IFilterDescriptor, IStoragePlaceState, SortDescriptors } from '../../state/app.state';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IStoragePlaceEditForm, IStoragePlaceFilterForm } from './../../models/storage-place.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-storage-place-autocompletesingle',
	templateUrl: './entityautocompletesingle-storage-place.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class StoragePlaceAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IStoragePlace, IStoragePlaceState, IStoragePlaceEditForm, IStoragePlaceFilterForm> {
	public placeholder = 'Lagerplatz';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'identifier', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IStoragePlace> = () => ({ identifier: 1 });

	constructor(entityFacade: StoragePlaceFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IStoragePlace): string {
		return entity?.identifier;
	}
}
