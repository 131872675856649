import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPosition } from '../../models/position.model';
import { ApiService, IDataResponse } from './api.service';

@Injectable({
	providedIn: 'root',
})
export class CartApiService extends ApiService {
	public urlPathName = 'carts';

	constructor(http: HttpClient) {
		super(http);
	}

	public validate(positions: IPosition[], authToken: string): Observable<IDataResponse<IPosition[]>> {
		const headers = this.getHeaders(authToken);

		return this.post<IDataResponse<IPosition[]>, IPosition[]>([this.urlPathName, 'validate'], positions, headers);
	}
}
