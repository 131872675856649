import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStockMedia } from '../../models/stock.model';
import { IStockMediaState } from '../../state/app.state';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-mediastockdialogdelete',
	templateUrl: './entitydialogdelete-media-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class MediaStockDialogDeleteComponent extends EntityDialogDeleteComponent<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	constructor(entityFacade: MediaStockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
