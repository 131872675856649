import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IBundle, IBundleComponent, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { IBundleState } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { BundleComponentDialogComponent } from '../dialog/dialog-bundlecomponent.component';
import { EntityDialogTableComponent } from './entitydialogtable.component';

@Component({
	selector: 'babylon-bundlecomponentstable',
	templateUrl: './entitydialogtable-bundlecomponents.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class BundleComponentsTableComponent extends EntityDialogTableComponent<IBundle, IBundleState, IBundleComponent, IBundleComponent, IBundleEditForm, IBundleFilterForm> {
	public columns = ['articleKind', 'discount', 'edit'];

	constructor(entityFacade: BundleFacade, private matDialog: MatDialog) {
		super(entityFacade);
	}

	protected dataSourceSelector(entity: IBundle): IBundleComponent[] {
		return entity.components;
	}

	protected createElement(): IBundleComponent {
		return {
			articleKind: null,
			discount: 0,
			isPrincipal: false,
		};
	}

	public openEditDialog(data: IBundleComponent, index: number): void {
		const dialogRef = this.matDialog.open(BundleComponentDialogComponent, {
			width: '1000px',
			data: data,
		});

		dialogRef.afterClosed().subscribe((result: IBundleComponent) => {
			if (result) {
				this.valueChanges$.next([...this.valueChanges$.value, ...this.onUpdate({ index, value: result })]);
			}
		});
	}
}
