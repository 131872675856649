import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { PaymentPaypalApiService } from 'src/services/api/payment.paypal.service';
import { PaypalFacade } from 'src/state/payment.paypal/payment.paypal.facade';
import { ShippingFacade } from 'src/state/shipping/shipping.facade';
import { IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { PaymentDialogService } from '../../services/dialog/payment.service';
import { IPaymentPaypalState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-paypalpage',
	templateUrl: './entitypage-paypal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class PaypalPageComponent extends EntityPageComponent<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	public pageTitle$ = new BehaviorSubject('PayPal-Zahlungen');
	public columns = ['updatedAt', 'order', 'dueAmount', 'paypalorder', 'paypaltransaction', 'assignee', 'state', 'filter'];

	protected shouldDoInitialFilter = false;

	constructor(
		titleService: Title,
		route: ActivatedRoute,
		private paymentDialogService: PaymentDialogService,
		public apiService: PaymentPaypalApiService,
		public entityFacade: PaypalFacade,
		public customerFacade: CustomerFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public articleFacade: ArticleFacade,
		public receiptFacade: ReceiptFacade,
		public productFacade: ProductFacade,
		public shippingFacade: ShippingFacade,
		public processFacade: ProcessFacade,
		public routerFacade: RouterFacade,
		public orderFacade: OrderFacade,
		public router: Router
	) {
		super(titleService, paymentDialogService, route, entityFacade);

		combineLatest([this.routerFacade.paymentStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([states, processNodes]) => processNodes.filter(processNode => states.some(state => state.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					this.entityFacade.changeSorting({ updatedAt: -1 });

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}

	public showPayDialog(entity: IPaymentPaypal, width: string = null): void {
		this.paymentDialogService.openPayDialog({ entity, width });
	}
}
