import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { PageApiService } from '../../services/api/page.service';
import { PageDialogService } from '../../services/dialog/page.service';
import { PageNotificationService } from '../../services/notification/page.service';
import { AppState, IPageState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPageActions } from './page.actions';
import { PageSelector } from './page.selectors';

@Injectable({
	providedIn: 'root',
})
export class PageEffects extends EntityEffects<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	constructor(actions$: Actions, store: Store<AppState>, apiService: PageApiService, notificationService: PageNotificationService, dialogService: PageDialogService, selector: PageSelector, sessionSelector: SessionSelector) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPageActions, 'PAGE');
	}
}
