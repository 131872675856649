import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { AppState, IPartnerState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromPartnerActions } from './partner.actions';
import { initialEditFormValue } from './partner.reducer';
import { PartnerSelector } from './partner.selectors';

@Injectable({
	providedIn: 'root',
})
export class PartnerFacade extends EntityFacade<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	protected initialEditFormValue = initialEditFormValue;

	constructor(store: Store<AppState>, private partnerSelector: PartnerSelector) {
		super(store, partnerSelector);
	}

	protected actions = fromPartnerActions;
}
