import { AccountingSummaryCustomerNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-accounting-summary-customer.component';
import { ComponentType } from '@angular/cdk/portal';
import { EntityNotificationService } from './entity.service';
import { IAccountingSummaryCustomer } from '../../models/accounting-summary.model';
import { Injectable } from '@angular/core';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerNotificationService extends EntityNotificationService<IAccountingSummaryCustomer> {
	protected component: ComponentType<any> = AccountingSummaryCustomerNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
