import { box, validate } from 'ngrx-forms';
import { IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm, IExpandedArticleBirthdayChronicle } from '../../models/article.model';
import { EntityState, IArticleBirthdayChronicleState } from '../app.state';

import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';
import { EntityReducer } from '../entity.reducer';
import { fromArticleBirthdayChronicleActions } from './article.birthday-chronicle.actions';

export const initialValue: IArticleBirthdayChronicle = {
	_id: null,
	publicationDate: null,
	order: null,
	quality: null,
	storagePlace: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialEditFormValue: IArticleBirthdayChronicleEditForm = {
	_id: null,
	order: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialFilterFormValue: IArticleBirthdayChronicleFilterForm = {
	_id: null,
	createdAt: null,
	publicationDate: null,
	order: null,
	quality: box([]),
	storagePlace: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
};

export const initialState = {
	...EntityState.create<IArticleBirthdayChronicle, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm>(
		'BIRTHDAYCHRONICLE',
		initialValue,
		initialEditFormValue,
		initialFilterFormValue,
		[
			{
				processInstance: {
					state: 'IN',
				},
				publicationDate: 'GTE',
			},
		],
		{
			product: 1,
			publicationDate: 1,
		}
	),
	isSearching: false,
	isExtending: false,
	itemsSearched: [] as IExpandedArticleBirthdayChronicle[],
	paging: {
		skip: 0,
		top: 1,
	},
	dayRange: {
		current: 365,
		min: 365,
		max: 365,
	},
};

export const articleBirthdayChronicleReducer = EntityReducer.create<IArticleBirthdayChronicle, IArticleBirthdayChronicleState, IArticleBirthdayChronicleEditForm, IArticleBirthdayChronicleFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
		publicationDate: validate(required),
	},
	fromArticleBirthdayChronicleActions,
	on(fromArticleBirthdayChronicleActions.search, state => ({
		...state,
		isSearching: true,
		paging: { ...state.paging, top: initialState.paging.top },
		dayRange: { ...state.dayRange, current: state.dayRange.min },
	})),
	on(fromArticleBirthdayChronicleActions.searched, (state, { entities }) => ({
		...state,
		itemsSearched: entities,
		isExtending: false,
		isSearching: false,
	})),
	on(fromArticleBirthdayChronicleActions.failed, state => ({
		...state,
		isSearching: false,
	})),
	on(fromArticleBirthdayChronicleActions.extended, state => ({ ...state, isExtending: false, isSearching: false })),
	on(fromArticleBirthdayChronicleActions.pagingChanged, (state, { paging }) => ({ ...state, paging, isExtending: true })),
	on(fromArticleBirthdayChronicleActions.dayRangeChanged, (state, { dayRange }) => ({ ...state, dayRange, isExtending: true }))
);
