import { Directive } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MenuFacade } from 'src/state/menu/menu.facade';
import { PageComponent } from '../page.component';

@Directive()
export abstract class EntityPageAddComponent extends PageComponent {
	constructor(titleService: Title, public menuFacade: MenuFacade) {
		super(titleService);
	}
}
