import { Injectable } from '@angular/core';
import { IPartner, IPartnerEditForm, IPartnerFilterForm } from '../../models/partner.model';
import { AppState, IPartnerState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class PartnerSelector extends EntitySelector<IPartner, IPartnerState, IPartnerEditForm, IPartnerFilterForm> {
	protected stateSelector = (state: AppState): IPartnerState => state.partner;
}
