import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDocument, IDocumentEditForm } from 'src/models/document.model';
import { IDocumentState } from 'src/state/app.state';
import { DocumentFacade } from 'src/state/document/document.facade';
import { IDocumentFilterForm } from '../../models/document.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-documentdialogadd',
	templateUrl: './entitydialogadd-document.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class DocumentDialogAddComponent extends EntityDialogAddComponent<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	private documentFile: File = null;

	constructor(public documentFacade: DocumentFacade, matDialogRef: MatDialogRef<any>) {
		super(documentFacade, matDialogRef);
	}

	public onDocumentChanged($event: any): void {
		this.documentFile = $event.target.files[0];
	}

	public createDocument(document: IDocument) {
		this.documentFacade.createdDocument(document, this.documentFile);
	}
}
