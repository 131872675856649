import { box, validate } from 'ngrx-forms';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { EntityState, IAccountingSummaryState } from '../app.state';

import { required } from 'ngrx-forms/validation';
import { EntityReducer } from '../entity.reducer';
import { fromAccountingSummaryActions } from './accounting-summary.actions';

export const initialValue: IAccountingSummary<IAccountingSummaryPosition> = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialEditFormValue: IAccountingSummaryEditForm<IAccountingSummaryPosition> = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	positions: [
		{
			orderNumber: null,
		},
	],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialFilterFormValue: IAccountingSummaryFilterForm<IAccountingSummaryPosition> = {
	_id: null,
	createdAt: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	positions: [],
	from: null,
	until: null,
	accountingSummaryKind: null,
};

export const initialState = EntityState.create<IAccountingSummary<IAccountingSummaryPosition>, IAccountingSummaryEditForm<IAccountingSummaryPosition>, IAccountingSummaryFilterForm<IAccountingSummaryPosition>>(
	'ACCOUNTINGSUMMARY',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			createdAt: 'GTE',
			from: 'GTE',
			until: 'GTE',
			processInstance: {
				state: 'IN',
			},
		},
	],
	{
		createdAt: 1,
	}
);
export const accountingSummaryReducer = EntityReducer.create<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
>(
	initialState,
	{
		accountingSummaryKind: validate(required),
		from: validate(required),
		until: validate(required),
	},
	fromAccountingSummaryActions
);
