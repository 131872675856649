import { box } from 'ngrx-forms';
import { IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentCashOnDeliveryState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCashOnDeliveryActions } from './payment.cash-on-delivery.actions';

export const initialValue: IPaymentCashOnDelivery = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentCashOnDeliveryEditForm = {
	_id: null,
	paidDate: null,
	dueAmount: null,
	order: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentCashOnDeliveryFilterForm = {
	_id: null,
	createdAt: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPaymentCashOnDelivery, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm>('CASHONDELIVERY', initialValue, initialEditFormValue, initialFilterFormValue);
export const cashOnDeliveryReducer = EntityReducer.create<IPaymentCashOnDelivery, IPaymentCashOnDeliveryState, IPaymentCashOnDeliveryEditForm, IPaymentCashOnDeliveryFilterForm>(initialState, {}, fromCashOnDeliveryActions);
