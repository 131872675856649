import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IMediaProduct } from '../../models/media-product.model';
import { MediaProductApiService } from '../../services/api/media-product.service';
import { MediaProductDialogService } from '../../services/dialog/media-product.service';
import { MediaProductNotificationService } from '../../services/notification/media-product.service';
import { AppState, IMediaProductState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { fromMediaProductActions } from './media-product.actions';
import { MediaProductSelector } from './media-product.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaProductEffects extends EntityEffects<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: MediaProductApiService,
		notificationService: MediaProductNotificationService,
		dialogService: MediaProductDialogService,
		selector: MediaProductSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromMediaProductActions, 'MEDIAPRODUCT');
	}
}
