import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IImage, IImageEditForm } from 'src/models/image.model';
import { IImageState } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { IImageFilterForm } from './../../models/image.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-imagedialogadd',
	templateUrl: './entitydialogadd-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ImageDialogAddComponent extends EntityDialogAddComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	private imageFile: File = null;

	constructor(public imageFacade: ImageFacade, matDialogRef: MatDialogRef<any>) {
		super(imageFacade, matDialogRef);
	}

	public onImageChanged($event: any): void {
		this.imageFile = $event.target.files[0];
	}

	public createImage(image: IImage) {
		this.imageFacade.createdImage(image, this.imageFile);
	}
}
