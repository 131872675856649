import { box } from 'ngrx-forms';
import { IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from '../../models/receipt';
import { EntityState, IReceiptCancellationState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromCancellationActions } from './receipt.cancellation.actions';

export const initialValue: IReceiptCancellation = {
	_id: null,
	payments: [],
	order: null,
	number: null,
	biller: {
		companyName: null,
		accountOwner: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		name: null,
		nameInAddress: null,
		bankAccountNumber: null,
		bankCode: null,
	},
	receiver: {
		salutationKind: null,
		addressKind: null,
		firstName: null,
		lastName: null,
		company: null,
		email: null,
		telephone: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
	},
	date: null,
	dueDate: null,
	dueDateDelayed: null,
	receiptKind: null,
	positions: [],
	totalPrice: null,
	referenceReceipt: null,
	customer: null,
	consentEmailTransfer: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IReceiptCancellationEditForm = {
	_id: null,
	payments: box([]),
	order: null,
	number: null,
	biller: {
		companyName: null,
		accountOwner: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		name: null,
		nameInAddress: null,
		bankAccountNumber: null,
		bankCode: null,
	},
	receiver: {
		salutationKind: null,
		addressKind: null,
		firstName: null,
		lastName: null,
		company: null,
		email: null,
		telephone: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
	},
	date: null,
	dueDate: null,
	dueDateDelayed: null,
	receiptKind: null,
	referenceReceipt: null,
	positions: [
		{
			_id: null,
			positionKind: null,
			title: null,
			price: null,
			taxRate: null,
			units: null,
			paymentKind: null,
			shippingKind: null,
			article: {
				_id: null,
				publicationDate: null,
				articleKind: null,
				processInstance: {
					actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
					state: {
						name: null,
						displayName: null,
						version: null,
					},
					priority: null,
				},
				product: {
					name: null,
					productKind: null,
					articleKind: null,
					price: null,
					taxRate: null,
				},
			},
			bundle: {
				_id: null,
				name: null,
				price: null,
				components: [
					{
						articleKind: null,
						discount: null,
						isPrincipal: null,
					},
				],
			},
			articles: [
				{
					_id: null,
					publicationDate: null,
					articleKind: null,
					processInstance: {
						actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
						state: {
							name: null,
							displayName: null,
							version: null,
						},
						priority: null,
					},
					product: {
						name: null,
						productKind: null,
						articleKind: null,
						price: null,
						taxRate: null,
					},
				},
			],
		},
	],
	totalPrice: null,
	customer: null,
	consentEmailTransfer: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IReceiptCancellationFilterForm = {
	_id: null,
	createdAt: null,
	payments: box([]),
	order: null,
	number: null,
	biller: {
		companyName: null,
		accountOwner: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
		hrb: null,
		bank: null,
		iban: null,
		bic: null,
		email: null,
		bankEmail: null,
		taxNumber: null,
		taxOffice: null,
		telephoneNumber: null,
		creditorIdentificationNumber: null,
		salesTaxIdentificationNumber: null,
		name: null,
		nameInAddress: null,
		bankAccountNumber: null,
		bankCode: null,
	},
	receiver: {
		salutationKind: null,
		addressKind: null,
		firstName: null,
		lastName: null,
		company: null,
		email: null,
		telephone: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
	},
	date: null,
	dueDate: null,
	dueDateDelayed: null,
	receiptKind: null,
	referenceReceipt: null,
	positions: [],
	totalPrice: null,
	customer: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm>('CANCELLATION', initialValue, initialEditFormValue, initialFilterFormValue);
export const cancellationReducer = EntityReducer.create<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm>(initialState, {}, fromCancellationActions);
