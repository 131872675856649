<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Erscheinungszeitraum löschen</h1>
	<mat-dialog-content>
		Möchten Sie den folgenden Erscheinungszeitraum wirklich löschen?
		<pre>{{ editForm.value.name }}</pre>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="warn" *ngIf="entityFacade.editFormValue$ | async as editFormValue" (click)="entityFacade.removed()" [disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Löschen</button>
	</mat-dialog-actions>
</ng-container>
