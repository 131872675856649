<ng-container>
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Liefermethode ändern</h1>
	<div mat-dialog-content>
		<form [formGroup]="formGroup">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-select placeholder="Liefermethode" formControlName="shippingKind">
						<mat-option *ngFor="let shippingKind of ShippingKinds | keyvalue" [value]="shippingKind.value">{{ shippingKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-checkbox formControlName="enableSaturdayDelivery">Samstagszustellung</mat-checkbox>
				<p class="mat-form-field--span" *ngIf="formGroup.value?.enableSaturdayDelivery">
					<i>Die Samstagszustellung kann nur aktiviert werden, wenn in der Konfiguration bereits ein Preis für die Samstagszustellung hinterlegt ist.</i>
				</p>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="matDialogRef.close(value)" [disabled]="!formGroup.valid">Speichern</button>
	</mat-dialog-actions>
</ng-container>
