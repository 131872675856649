import { ChangeDetectionStrategy, Component } from '@angular/core';
import moment from 'moment';
import { progressFade } from '../../animations/progressfade.animation';
import { IStock, IStockAccessory } from '../../models/stock.model';
import { FilterConnection, IFilterDescriptor, IStockAccessoryState, SortDescriptors } from '../../state/app.state';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-accessory-stock-autocompletesingle',
	templateUrl: './entityautocompletesingle-accessory-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class AccessoryStockAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	public placeholder = 'Bestand';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IStockAccessory> = () => ({ createdAt: -1 });

	constructor(entityFacade: AccessoryStockFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IStock): string {
		return `${moment(entity?.createdAt).format('DD.MM.YYYY')}`;
	}
}
