import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DhlExpressDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-dhl-express.component';
import { DhlExpressDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-dhl-express.component';
import { DhlExpressDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-dhl-express.component';
import { IShippingDhlExpress, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from '../../models/shipping.model';
import { IShippingDhlExpressState } from '../../state/app.state';
import { DhlExpressFacade } from '../../state/shipping.dhl-express/shipping.dhl-express.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingDhlExpressDialogService extends EntityDialogService<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	protected createComponent: ComponentType<any> = DhlExpressDialogAddComponent;
	protected updateComponent: ComponentType<any> = DhlExpressDialogChangeComponent;
	protected removeComponent: ComponentType<any> = DhlExpressDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: DhlExpressFacade) {
		super(matDialog, entityFacade);
	}
}
