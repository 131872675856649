import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm } from '../../models/stock.model';
import { StockAccessoryApiService } from '../../services/api/stock.accessory.service';
import { StockAccessoryDialogService } from '../../services/dialog/stock.accessory.service';
import { AccessoryStockNotificationService } from '../../services/notification/stock.accessory.service';
import { AppState, IStockAccessoryState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAccessoryStockActions } from './stock.accessory.actions';
import { AccessoryStockSelector } from './stock.accessory.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccessoryStockEffects extends EntityEffects<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: StockAccessoryApiService,
		notificationService: AccessoryStockNotificationService,
		dialogService: StockAccessoryDialogService,
		selector: AccessoryStockSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromAccessoryStockActions, 'ACCESSORYSTOCK');
	}
}
