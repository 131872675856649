import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-receipt-receiverdialog',
	templateUrl: './dialog-receipt-receiver.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ReceiptReceiverDialogComponent extends DialogComponent<ReceiptReceiverDialogComponent> {
	public SalutationKinds = SalutationKind;
	public AddressKinds = AddressKind;

	constructor(matDialogRef: MatDialogRef<ReceiptReceiverDialogComponent>, private matDialog: MatDialog, public receiptFacade: ReceiptFacade) {
		super(matDialogRef);
	}
}
