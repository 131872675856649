import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import _ from 'lodash';
import moment from 'moment';
import { MarkAsTouchedAction, ResetAction, SetValueAction } from 'ngrx-forms';
import { debounceTime, of } from 'rxjs';
import { bufferTime, catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { EntityApiService } from 'src/services/api/entity.service';
import { EntityDialogService } from 'src/services/dialog/entity.service';
import { EntityNotificationService } from 'src/services/notification/entity.service';
import { FilterOperator, FilterOperators } from 'src/state/app.state';
import { IServiceErrorResponse } from '../services/api/api.service';
import { FilterExpression, FilterQuery, QuerySelector, RootQuerySelector } from '../types/filter';
import { constructFormGroupValue } from '../types/form';
import { AppState, FilterConnection, IEntityState, IFilterDescriptor } from './app.state';
import { BaseEffects } from './base.effects';
import { IEntityActions } from './entity.actions';
import { EntitySelector } from './entity.selector';
import { IInvalidationResult, fromSessionActions } from './session/session.actions';
import { SessionSelector } from './session/session.selectors';
import { fromStatisticsActions } from './statistics/statistics.actions';

@Injectable({
	providedIn: 'root',
})
export abstract class EntityEffects<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseEffects {
	constructor(
		protected actions$: Actions,
		protected store: Store<AppState>,
		protected entityService: EntityApiService<TEntity>,
		protected notificationService: EntityNotificationService<TEntity>,
		protected dialogService: EntityDialogService<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>,
		protected entitySelector: EntitySelector<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>,
		protected sessionSelector: SessionSelector,
		@Inject('') protected entityActions: IEntityActions<TEntity, TEntityEditForm>,
		@Inject('') protected entityIdentifier: string
	) {
		super();
	}

	public onFetch$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.fetch),
			bufferTime(250),
			map(requests => _.uniq(_.flatten(requests.map(request => request.ids)))),
			map(ids => ids.filter(id => id != null)),
			filter(ids => ids.length > 0),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([ids, authToken]) =>
				this.entityService
					.filter(
						{
							query: {
								_id: {
									$in: ids,
								},
							},
						},
						authToken
					)
					.pipe(
						map(result => result.data),
						switchMap(entities => {
							const result: Action[] = [];

							if (entities.length > 0) {
								result.push(this.entityActions.fetched({ entities }));
							}

							const diff = _.difference(
								ids,
								entities.map(entity => entity._id)
							);

							if (diff.length > 0) {
								result.push(this.entityActions.missed({ ids: diff }));
							}

							return result;
						}),
						catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onInvalidate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.invalidate),
			concatLatestFrom(({ ids }) => this.store.select(this.entitySelector.getMany(ids))),
			map(([{ ids }, entities]) => ({ ids, entities: entities.filter(entity => !!entity) })),
			switchMap(({ ids, entities }) => [fromSessionActions.invalidated({ invalidationResults: this.onInvalidateMany(entities) }), this.entityActions.invalidated({ ids }), this.entityActions.fetch({ ids })])
		)
	);

	public onInvalidated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromSessionActions.invalidated),
			bufferTime(500),
			map(requests => _.uniq(_.flatten(requests.map(request => request.invalidationResults)))),
			map(invalidationResults => invalidationResults.filter(invalidationResult => invalidationResult.entityIdentifier == this.entityIdentifier)),
			filter(invalidationResults => invalidationResults.length > 0),
			map(invalidationResults => invalidationResults.map(invalidationResult => invalidationResult.entityId)),
			switchMap(ids => [this.entityActions.invalidate({ ids }), this.entityActions.fetch({ ids })])
		)
	);

	public onFilterValueChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filterValueChanged),
			switchMap(({ changes }) => changes.map(change => new SetValueAction(`${this.FILTER_FORM}.${change.attributeName}`, change.value)))
		)
	);

	public onFilterOperatorChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filterOperatorChanged),
			switchMap(({ changes }) => changes.map(change => new SetValueAction(`${this.FILTER_OPERATOR_FORM}.${change.attributeName}`, change.operator)))
		)
	);

	public onFormValueChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.formValueChanged),
			switchMap(({ changes }) => changes.map(change => new SetValueAction(`${this.EDIT_FORM}.${change.attributeName}`, change.value)))
		)
	);

	public onGlobalFormValueChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.globalFormValueChanged),
			switchMap(({ changes }) => changes.map(change => new SetValueAction(change.controlId, change.value)))
		)
	);

	public onFilterFormChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SetValueAction.TYPE),
			filter((formControlUpdate: SetValueAction<string>) => formControlUpdate.controlId?.indexOf(this.FILTER_FORM) > -1),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterFormValue)),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterOperatorFormValue)),
			debounceTime(500),
			map(([[, filters], filterOperators]) => this.buildFilterDescriptors(filters, filterOperators)),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterDescriptors)),
			filter(([filterDescriptors, formerFilterDescriptors]) => this.areFilterDescriptorsChanged(filterDescriptors, formerFilterDescriptors)),
			switchMap(([filterDescriptors]) => [
				this.entityActions.filterDescriptorChanged({
					filterDescriptors,
				}),
			])
		)
	);

	public onFilterDescriptorChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filterConnectionChanged, this.entityActions.filterDescriptorChanged),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterConnection)),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterDescriptors)),
			map(([[, filterConnection], filterDescriptors]) => this.entityActions.filterQueryChanged({ filterQuery: this.buildFilterQuery(filterDescriptors, filterConnection) }))
		)
	);

	public onFilterDescriptorChangedForForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filterDescriptorChanged),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialFilterFormValue)),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterDescriptors)),
			map(([[, initialFilterFormValue], filterDescriptors]) => this.convertFilterDescriptorsForForm(filterDescriptors, initialFilterFormValue).map(entity => constructFormGroupValue<TEntityFilterForm>(entity, initialFilterFormValue))),
			map(filterFormValue => new SetValueAction<TEntityFilterForm[]>(this.FILTER_FORM, filterFormValue))
		)
	);

	public onFilterDescriptorChangedForOperatorForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filterDescriptorChanged),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterOperatorFormValue)),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterDescriptors)),
			map(([[, filterOperatorFormValue], filterDescriptors]) => this.convertFilterDescriptorsForOperatorForm(filterDescriptors, filterOperatorFormValue)),
			map(filterFormValue => new SetValueAction<FilterOperators<TEntity>[]>(this.FILTER_OPERATOR_FORM, filterFormValue))
		)
	);

	public onFilter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filter, this.entityActions.pageChanged, this.entityActions.sortDescriptorChanged, this.entityActions.filterQueryChanged),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterQuery)),
			concatLatestFrom(() => this.store.select(this.entitySelector.sortDescriptors)),
			concatLatestFrom(() => this.store.select(this.entitySelector.pageSize)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[[[, filterQuery], sortDescriptors], pageSize], authToken]) =>
				this.entityService
					.filter(
						{
							query: filterQuery,
							queryOptions: {
								sort: sortDescriptors,
								skip: 0,
								limit: pageSize,
								count: true,
							},
						},
						authToken
					)
					.pipe(
						map(result => this.entityActions.filtered({ entities: result.data, totalCount: result.totalCount })),
						catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onIncrease$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.increase),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.entitySelector.filterQuery)),
			concatLatestFrom(() => this.store.select(this.entitySelector.sortDescriptors)),
			concatLatestFrom(() => this.store.select(this.entitySelector.pageIndex)),
			concatLatestFrom(() => this.store.select(this.entitySelector.pageSize)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[[[[, filterQuery], sortDescriptors], pageIndex], pageSize], authToken]) =>
				this.entityService
					.filter(
						{
							query: filterQuery,
							queryOptions: {
								sort: sortDescriptors,
								skip: (pageIndex + 1) * pageSize,
								limit: pageSize,
								count: false,
							},
						},
						authToken
					)
					.pipe(
						map(result => this.entityActions.increased({ entities: result.data, pageIndex: result.data.length >= pageSize ? pageIndex + 1 : pageIndex })),
						catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onSuggest$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.suggest),
			debounceTime(10),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ filterConnection, filterDescriptors, sortDescriptors }, authToken]) => {
				if (filterDescriptors.length > 0) {
					return this.entityService
						.filter(
							{
								query: this.buildFilterQuery(filterDescriptors, filterConnection),
								queryOptions: {
									sort: sortDescriptors,
								},
							},
							authToken
						)
						.pipe(
							map(result => this.entityActions.suggested({ entities: result.data })),
							catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
						);
				} else {
					return of(this.entityActions.suggested({ entities: [] }));
				}
			})
		)
	);

	public onFilteredForCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.filtered),
			map(() => this.entityActions.count())
		)
	);

	public onCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.count),
			debounceTime(500),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([, authToken]) =>
				this.entityService.count(authToken).pipe(
					map(result => this.entityActions.counted({ processNodeCounts: result.data })),
					catchError(() => of(this.entityActions.counted({ processNodeCounts: [] })))
				)
			)
		)
	);

	public onCounted$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromStatisticsActions.counted),
			map(({ processNodeCounts }) => processNodeCounts[this.entityIdentifier]),
			filter(processNodeCounts => !!processNodeCounts),
			map(processNodeCounts => this.entityActions.counted({ processNodeCounts }))
		)
	);

	public onError$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(this.entityActions.failed),
				filter(({ error }) => error != null),
				tap(({ error }) => this.notificationService.showError(error))
			),
		{
			dispatch: false,
		}
	);

	public onCreateForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.createForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialValue)),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([[, entity], initialEditFormValue]) => [entity, constructFormGroupValue(entity, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new ResetAction(this.EDIT_FORM), new MarkAsTouchedAction(this.EDIT_FORM), new SetValueAction(this.EDIT_FORM, formValue)])
		)
	);

	public onCreatedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.createdForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[, entity], authToken]) =>
				this.entityService.create(entity, authToken).pipe(
					map(result => this.entityActions.created({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onCreated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.created),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue(entity, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new SetValueAction(this.EDIT_FORM, formValue)])
		)
	);

	public onUpdateForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updateForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue(entity, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new ResetAction(this.EDIT_FORM), new MarkAsTouchedAction(this.EDIT_FORM), new SetValueAction(this.EDIT_FORM, formValue)])
		)
	);

	public onUpdatedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updatedForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ closeDialog }, entity], authToken]) =>
				this.entityService.update(entity, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data, closeDialog })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updated),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue<TEntity>(entity, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new SetValueAction(this.EDIT_FORM, formValue), this.entityActions.selected({ selected: entity })])
		)
	);

	public onUpdatedForInvalidation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updated),
			map(({ entity }) => this.entityActions.invalidate({ ids: [entity._id] }))
		)
	);

	public onRemoveForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.removeForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.initialEditFormValue)),
			map(([{ entity }, initialEditFormValue]) => [entity, constructFormGroupValue<TEntity>(entity, initialEditFormValue)]),
			switchMap(([entity, formValue]) => [new ResetAction(this.EDIT_FORM), new MarkAsTouchedAction(this.EDIT_FORM), new SetValueAction(this.EDIT_FORM, formValue)])
		)
	);

	public onRemovedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.removedForm),
			concatLatestFrom(() => this.store.select(this.entitySelector.editFormValue)),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[, entity], authToken]) =>
				this.entityService.remove(entity, authToken).pipe(
					map(() => this.entityActions.removed({ entity })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onRemoved$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.removed),
			switchMap(({ entity }) => [new ResetAction(this.EDIT_FORM)])
		)
	);

	public onAbortedForm$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.abortedForm),
			switchMap(() => [new ResetAction(this.EDIT_FORM)])
		)
	);

	public onFormSucces$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.created, this.entityActions.removed),
			tap(() => this.dialogService.closeDialog()),
			map(() => this.entityActions.filter())
		)
	);

	public onUpdateFormSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updated),
			tap(({ closeDialog }) => {
				if (closeDialog !== false) {
					this.dialogService.closeDialog();
				}
			}),
			map(() => this.entityActions.filter())
		)
	);

	public onProcessUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updateProcess),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, processRoute, comment, callback }, authToken]) =>
				this.entityService.changeProcess(entity, processRoute, comment, authToken).pipe(
					tap(result => (callback != null ? callback(result.data) : null)),
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onProcessReverted$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.revertProcess),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, comment }, authToken]) =>
				this.entityService.revertProcess(entity, comment, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onAssigneeUpdated$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.updateAssignee),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, user }, authToken]) =>
				this.entityService.updateAssignee(entity, user, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onCreatedUpdatedRemoved$ = createEffect(() =>
		this.actions$.pipe(
			ofType(this.entityActions.created, this.entityActions.updated, this.entityActions.removed),
			map(() => this.entityActions.filter())
		)
	);

	protected onInvalidateMany(entities: TEntity[]): IInvalidationResult[] {
		return _.flatten(entities.map(entity => this.onInvalidate(entity)));
	}

	protected onInvalidate(entity: TEntity): IInvalidationResult[] {
		return [];
	}

	protected get EDIT_FORM(): string {
		return `${this.entityIdentifier}_EDIT`;
	}

	protected get FILTER_FORM(): string {
		return `${this.entityIdentifier}_FILTER`;
	}

	protected get FILTER_OPERATOR_FORM(): string {
		return `${this.entityIdentifier}_FILTER_OPERATOR`;
	}

	private buildFilterDescriptors<T, K>(filter: T, filterOperator: K, attributePath: string[] = []): IFilterDescriptor[] {
		let result: IFilterDescriptor[] = [];

		for (let attributeName in filter) {
			const newAttributePath = [...attributePath, attributeName];
			const filterOperatorValue = filterOperator != null ? (filterOperator[attributeName as any as keyof K] as FilterOperator) : null;
			const filterValue = filter[attributeName];

			if (_.isObject(filterValue) && !_.isArray(filterValue) && (filterOperatorValue == null || _.isObject(filterOperatorValue))) {
				result = [...result, ...this.buildFilterDescriptors(filterValue, filterOperatorValue, newAttributePath)];
			} else {
				let hasValue = false;

				if ((_.isArray(filterValue) && filterValue.length > 0) || (!_.isObject(filterValue) && filterValue != null)) {
					hasValue = true;
				} else {
					for (let innerAttribute in filterValue) {
						if (filterValue[innerAttribute] != null) {
							hasValue = true;
							break;
						}
					}
				}

				if (hasValue) {
					result.push({
						isProgrammatic: false,
						attributeName: newAttributePath.join('.'),
						value: filterValue,
						operator: filterOperatorValue,
					});
				}
			}
		}

		return result;
	}

	private buildFilterQuery<TEntity extends IEntity>(filterDescriptors: IFilterDescriptor[], filterConnection: FilterConnection): FilterQuery<TEntity> {
		let filter: FilterQuery<TEntity> = {};
		let filterExpressionTypes: FilterExpression<TEntity>[] = [];

		if (filterDescriptors == null || filterDescriptors.length === 0) {
			return filter;
		}

		let filterConnector: keyof RootQuerySelector<TEntity> = '$and';

		if (filterConnection != null && filterConnection === 'OR') {
			filterConnector = '$or';
		}

		for (const filterDescriptor of filterDescriptors) {
			if (filterDescriptor.value == null || ((filterDescriptor.value as string[]) != null && (filterDescriptor.value as string[]).length === 0)) {
				continue;
			}
			let filterExpression = {};

			const attributeName = filterDescriptor.attributeName.substring(1, 2) == '.' ? filterDescriptor.attributeName.slice(2) : filterDescriptor.attributeName;
			switch (filterDescriptor.operator) {
				case 'CONTAINS':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$regex');
					break;
				case 'GT':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$gt');
					break;
				case 'GTE':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$gte');
					break;
				case 'LT':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$lt');
					break;
				case 'LTE':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$lte');
					break;
				case 'STARTSWITH':
					filterExpression = this.generateFilterQuery(attributeName, `^${filterDescriptor.value}`, '$regex');
					break;
				case 'IN':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$in');
					break;
				case 'NOTCONTAINS':
					filterExpression = this.generateFilterQuery(attributeName, `^((?!${filterDescriptor.value}).)*$`, '$regex');
					break;
				case 'NE':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$ne');
					break;
				case 'DATEEQ':
					filterExpression = {
						$and: [
							this.generateFilterQuery(attributeName, moment.utc(filterDescriptor.value).startOf('day').toISOString(), '$gte'),
							this.generateFilterQuery(attributeName, moment.utc(filterDescriptor.value).endOf('day').toISOString(), '$lte'),
						],
					};
					break;
				case 'CUSTOM':
					filterExpression = filterDescriptor.value;
					break;
				default:
				case 'EQ':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$eq');
					break;
				case 'ELEMMATCH':
					filterExpression = this.generateFilterQuery(attributeName, filterDescriptor.value, '$elemMatch');
					break;
			}

			if (filterExpression !== null) {
				filterExpressionTypes.push(filterExpression);
			}
		}

		if (filterExpressionTypes != null && filterExpressionTypes.length > 0) {
			filter = { [filterConnector]: filterExpressionTypes } as FilterQuery<TEntity>;
		}

		return filter;
	}

	private generateFilterQuery<TEntity extends IEntity>(attributeName: string, value: string | number | boolean | string[], filterType: keyof QuerySelector<TEntity>): FilterQuery<TEntity> {
		if (attributeName == null || value == null || filterType == null) {
			return null;
		}

		return { [attributeName]: { [filterType]: value } } as FilterQuery<TEntity>;
	}

	private areFilterDescriptorsChanged(filterDescriptors: IFilterDescriptor[], formerFilterDescriptors: IFilterDescriptor[]): boolean {
		return !_.isEqual(
			filterDescriptors.map(x => _.omit(x, 'isProgrammatic')),
			formerFilterDescriptors.map(x => _.omit(x, 'isProgrammatic'))
		);
	}

	private convertFilterDescriptorsForForm(filterDescriptors: IFilterDescriptor[], initialFilterFormValue: TEntityFilterForm): TEntityFilterForm[] {
		const result: any[] = [_.cloneDeep(initialFilterFormValue), _.cloneDeep(initialFilterFormValue)];

		for (const filterDescriptor of filterDescriptors) {
			this.setValue(result, filterDescriptor.attributeName.split('.'), filterDescriptor.value);
		}

		return result;
	}

	private convertFilterDescriptorsForOperatorForm(filterDescriptors: IFilterDescriptor[], filterOperatorFormValue: FilterOperators<TEntity>[]): FilterOperators<TEntity>[] {
		const result: any[] = [_.cloneDeep(filterOperatorFormValue[0]), _.cloneDeep(filterOperatorFormValue[1])];

		for (const filterDescriptor of filterDescriptors) {
			this.setValue(result, filterDescriptor.attributeName.split('.'), filterDescriptor.operator);
		}

		return result;
	}

	private setValue(item: any, attributePath: string[], value: any): void {
		const lastPathPart = attributePath.pop();

		for (let i = 0; i < attributePath.length; i++) {
			item = item[attributePath[i]];
		}

		item[lastPathPart] = value;
	}
}
