import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleMagazine } from '../../models/article.model';
import { IArticleMagazineState } from '../../state/app.state';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { IArticleMagazineEditForm, IArticleMagazineFilterForm } from './../../models/article.model';
import { ProcessRouteFacade } from './../../state/process-route/process-route.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-magazinedialogchange',
	templateUrl: './entitydialogchange-magazine.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class MagazineDialogChangeComponent extends EntityDialogChangeComponent<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	constructor(entityFacade: ArticleMagazineFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
