import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IProcessState } from 'src/state/app.state';
import { ProcessDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-process.component';
import { ProcessDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-process.component';
import { ProcessDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-process.component';
import { IProcess } from '../../models/process';
import { ProcessFacade } from '../../state/process/process.facade';
import { IProcessEditForm, IProcessFilterForm } from './../../models/process';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ProcessDialogService extends EntityDialogService<IProcess, IProcessState, IProcessEditForm, IProcessFilterForm> {
	protected createComponent: ComponentType<any> = ProcessDialogAddComponent;
	protected updateComponent: ComponentType<any> = ProcessDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ProcessDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: ProcessFacade) {
		super(matDialog, entityFacade);
	}
}
