import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'join',
})
export class JoinPipe implements PipeTransform {
	public transform(input: Array<any>, property: string = null): string {
		if (property != null) {
			input = input.map(x => x[property]);
		}

		return input.join(', ');
	}
}
