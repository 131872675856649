import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IDeliveryNoteOrderState } from '../../state/app.state';
import { DeliveryNoteOrderFacade } from '../../state/delivery-note.order/delivery-note.order.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-delivery-note-orderdialogchange',
	templateUrl: './entitydialogchange-delivery-note-order.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DeliveryNoteOrderDialogChangeComponent extends EntityDialogChangeComponent<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	public SalutationKinds = SalutationKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: DeliveryNoteOrderFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
