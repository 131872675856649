import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleStick, IArticleStickEditForm, IArticleStickFilterForm } from '../../models/article.model';
import { AppState, IArticleStickState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromArticleStickActions } from './article.stick.actions';
import { initialEditFormValue } from './article.stick.reducer';
import { ArticleStickSelector } from './article.stick.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickFacade extends EntityFacade<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleStickActions;

	constructor(store: Store<AppState>, private stickSelector: ArticleStickSelector) {
		super(store, stickSelector);
	}

	public isSearching$ = this.store.select(this.stickSelector.isSearching);
	public isExtending$ = this.store.select(this.stickSelector.isExtending);
	public itemsSearched$ = this.store.select(this.stickSelector.itemsSearched);
	public paging$ = this.createBehaviorSubject(this.store.select(this.stickSelector.paging), null);

	public search(): void {
		this.store.dispatch(this.actions.search());
	}

	public extend(): void {
		this.store.dispatch(fromArticleStickActions.pagingChanged({ paging: { ...this.paging$.value, top: this.paging$.value.top * 2 } }));
	}
}
