<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Kunde ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="readonly">
					<mat-label>Kundennummer</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.number" readonly />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Anrede</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.salutationKind">
						<mat-option *ngFor="let salutationKind of salutationKinds" [value]="salutationKind">{{ salutationKind }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Vorname</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.firstName" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Nachname</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.lastName" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>E-Mail</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.email" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Telefon</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.telephone" />
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
