<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Landing-Page ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
						</mat-form-field>
						<babylon-page-autocompletesingle [value]="editForm.controls.parent.value" [controlId]="editForm.controls.parent.id" placeholder="Übergeordnete Seite"></babylon-page-autocompletesingle>
						<mat-form-field appearance="outline">
							<mat-label>URL</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.url" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Weiterleitung</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.redirect" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Icon</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.icon" />
						</mat-form-field>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.allowSitemap">Dynamische Sitemap aktivieren</mat-checkbox>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.isActive">Öffentlich zugänglich</mat-checkbox>
					</div>
				</mat-tab>
				<mat-tab label="Navigation">
					<div class="form-grid">
						<mat-checkbox [ngrxFormControlState]="editForm.controls.visibility.controls.header">Kopfzeile</mat-checkbox>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.visibility.controls.footer">Fußzeile</mat-checkbox>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.visibility.controls.mobile">Mobil</mat-checkbox>
						<mat-checkbox [ngrxFormControlState]="editForm.controls.visibility.controls.checkout">Kasse</mat-checkbox>
					</div>
				</mat-tab>
				<mat-tab label="SEO">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Titel</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.seo.controls.title" />
						</mat-form-field>
						<div class="editor">
							<span class="mat-caption">Beschreibung</span>
							<ckeditor [editor]="CKEditor" [ngrxFormControlState]="editForm.controls.seo.controls.description"></ckeditor>
						</div>
					</div>
				</mat-tab>
				<mat-tab label="Seiteninhalte">
					<mat-card class="box">
						<mat-card-content>
							<babylon-pageblockstable controlId="blocks"></babylon-pageblockstable>
						</mat-card-content>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
