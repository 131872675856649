import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IBundle, IBundleFilterForm } from 'src/models/bundle.model';
import { BundleDialogService } from 'src/services/dialog/bundle.service';
import { IBundleState } from 'src/state/app.state';
import { BundleFacade } from 'src/state/bundle/bundle.facade';
import { IBundleEditForm } from '../../models/bundle.model';
import { ImageFacade } from '../../state/image/image.facade';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-bundlepage',
	templateUrl: './entitypage-bundle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class BundlePageComponent extends EntityPageComponent<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	public pageTitle$ = new BehaviorSubject('Produktpakete');
	public columns = ['name', 'headline', 'image', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: BundleDialogService,
		route: ActivatedRoute,
		entityFacade: BundleFacade,
		public permissionFacade: PermissionFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
