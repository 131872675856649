import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from '../../models/payment.model';
import { PaymentPrepaymentApiService } from '../../services/api/payment.prepayment.service';
import { PaymentPrepaymentDialogService } from '../../services/dialog/payment.prepayment.service';
import { PrepaymentNotificationService } from '../../services/notification/payment.prepayment.service';
import { AppState, IPaymentPrepaymentState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPrepaymentActions } from './payment.prepayment.actions';
import { PrepaymentSelector } from './payment.prepayment.selectors';

@Injectable({
	providedIn: 'root',
})
export class PrepaymentEffects extends EntityEffects<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PaymentPrepaymentApiService,
		notificationService: PrepaymentNotificationService,
		dialogService: PaymentPrepaymentDialogService,
		selector: PrepaymentSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPrepaymentActions, 'PREPAYMENT');
	}
}
