import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleDvd } from '../../models/article.model';
import { IArticleDvdState } from '../../state/app.state';
import { ArticleDvdFacade } from '../../state/article.dvd/article.dvd.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleDvdEditForm, IArticleDvdFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-dvddialogchange',
	templateUrl: './entitydialogchange-dvd.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DvdDialogChangeComponent extends EntityDialogChangeComponent<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	constructor(entityFacade: ArticleDvdFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
