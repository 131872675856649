import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IPosition } from '../../models/position.model';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromCartActions } from './cart.actions';
import { CartSelector } from './cart.selectors';

@Injectable({
	providedIn: 'root',
})
export class CartFacade extends BaseFacade {
	constructor(store: Store<AppState>, private cartSelector: CartSelector) {
		super(store);
	}

	public isLoading$ = this.store.select(this.cartSelector.isLoading);

	public validatePositions(...positions: IPosition[]) {
		this.store.dispatch(fromCartActions.validatePositions({ positions }));
	}
}
