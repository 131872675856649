import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { IPayment } from '../../models/payment.model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-change-payment-kinddialog',
	templateUrl: './dialog-change-payment-kind.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class ChangePaymentKindDialogComponent extends DialogComponent<ChangePaymentKindDialogComponent, IEntityDialogData<IPayment>> {
	public PaymentKinds = PaymentKind;

	constructor(matDialogRef: MatDialogRef<ChangePaymentKindDialogComponent, IEntityDialogData<IPayment>>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) public data: IEntityDialogData<IPayment>) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				paymentKind: [data.entity?.paymentKind, [Validators.required]],
			})
		);
	}
}
