import { createAction, props } from '@ngrx/store';

import { EntityActions } from '../entity.actions';
import { IDeliveryNote } from '../../models/delivery-note';
import { IDeliveryNoteEditForm } from './../../models/delivery-note';

export const fromDeliveryNoteActions = {
	...EntityActions.create<IDeliveryNote, IDeliveryNoteEditForm>('[DeliveryNote]'),
	download: createAction(`[DeliveryNote] Download`, props<{ deliveryNote: IDeliveryNote }>()),
	downloaded: createAction(`[DeliveryNote] Downloaded`, props<{ deliveryNote: IDeliveryNote; data: any }>()),
	print: createAction(`[DeliveryNote] Print`, props<{ deliveryNote: IDeliveryNote }>()),
	printed: createAction(`[DeliveryNote] Printed`),
};
