import { EntityState, IImageState } from '../app.state';
import { IImage, IImageEditForm } from 'src/models/image.model';

import { EntityReducer } from '../entity.reducer';
import { IImageFilterForm } from './../../models/image.model';
import { fromImageActions } from './image.actions';
import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';

export const initialValue: IImage = {
	_id: null,
	title: null,
	file: null,
	version: null,
	altText: null,
};

export const initialEditFormValue: IImageEditForm = {
	_id: null,
	title: null,
	file: null,
	version: null,
	altText: null,
};

export const initialFilterFormValue: IImageFilterForm = {
	_id: null,
	createdAt: null,
	title: null,
	file: null,
	version: null,
	altText: null,
};

export const initialState = EntityState.create<IImage, IImageEditForm, IImageFilterForm>(
	'IMAGE',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			title: 'CONTAINS',
			altText: 'CONTAINS',
		},
	],
	{
		title: 1,
	}
);

export const imageReducer = EntityReducer.create<IImage, IImageState, IImageEditForm, IImageFilterForm>(
	initialState,
	{
		title: validate(required),
		altText: validate(required),
	},
	fromImageActions,
	on(fromImageActions.createdImageForm, state => ({ ...state, isFiltering: true })),
	on(fromImageActions.updatedImageForm, state => ({ ...state, isFiltering: true }))
);
