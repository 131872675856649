import { IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentDirectDebit } from '../../models/payment.model';
import { IPaymentDirectDebitState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { DirectDebitFacade } from '../../state/payment.direct-debit/payment.direct-debit.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-direct-debitdialogchange',
	templateUrl: './entitydialogchange-direct-debit.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DirectDebitDialogChangeComponent extends EntityDialogChangeComponent<IPaymentDirectDebit, IPaymentDirectDebitState, IPaymentDirectDebitEditForm, IPaymentDirectDebitFilterForm> {
	constructor(entityFacade: DirectDebitFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
