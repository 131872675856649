import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteState } from '../../state/app.state';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-delivery-notedialogadd',
	templateUrl: './entitydialogadd-delivery-note.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class DeliveryNoteDialogAddComponent extends EntityDialogAddComponent<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	constructor(entityFacade: DeliveryNoteFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
