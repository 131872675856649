import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaypalDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-paypal.component';
import { PaypalDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-paypal.component';
import { PaypalDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-paypal.component';
import { IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { IPaymentPaypalState } from '../../state/app.state';
import { PaypalFacade } from '../../state/payment.paypal/payment.paypal.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentPaypalDialogService extends EntityDialogService<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm> {
	protected createComponent: ComponentType<any> = PaypalDialogAddComponent;
	protected updateComponent: ComponentType<any> = PaypalDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PaypalDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PaypalFacade) {
		super(matDialog, entityFacade);
	}
}
