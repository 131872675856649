import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProduct } from '../../models/product.model';
import { IProductState } from '../../state/app.state';
import { ProductFacade } from '../../state/product/product.facade';
import { IProductEditForm, IProductFilterForm } from './../../models/product.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-productdialogadd',
	templateUrl: './entitydialogadd-product.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ProductDialogAddComponent extends EntityDialogAddComponent<IProduct, IProductState, IProductEditForm, IProductFilterForm> {
	constructor(entityFacade: ProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
