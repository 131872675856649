import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { StockTypeEnum } from '../../models/enums/stock-type.enum';
import { IStockMedia } from '../../models/stock.model';
import { IStockMediaState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-mediastockdialogchange',
	templateUrl: './entitydialogchange-media-stock.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class MediaStockDialogChangeComponent extends EntityDialogChangeComponent<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	public StockTypes = StockTypeEnum;

	constructor(entityFacade: MediaStockFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialogRef);
	}
}
