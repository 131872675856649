import { AddressFormType, IArticle, IArticleCertificateEditForm, IArticleCertificateFilterForm } from '../../models/article.model';
import { AppState, IArticleCertificateState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IArticleCertificate } from '../../models/article.model';
import { EntityFacade } from '../entity.facade';
import { fromArticleCertificateActions } from './article.certificate.actions';
import { initialEditFormValue } from './article.certificate.reducer';
import { ArticleCertificateSelector } from './article.certificate.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleCertificateFacade extends EntityFacade<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromArticleCertificateActions;

	constructor(store: Store<AppState>, private certificateSelector: ArticleCertificateSelector) {
		super(store, certificateSelector);
	}

	public isSearching$ = this.store.select(this.certificateSelector.isSearching);
	public itemsSearched$ = this.store.select(this.certificateSelector.itemsSearched);
	public expandedEditForm$ = this.store.select(this.certificateSelector.expandedEditForm);
	public expandedEditFormValue$ = this.store.select(this.certificateSelector.expandedEditFormValue);

	public download(certificate: IArticle): void {
		this.store.dispatch(this.actions.download({ certificate }));
	}

	public print(certificate: IArticle): void {
		this.store.dispatch(this.actions.print({ certificate }));
	}

	public search(): void {
		this.store.dispatch(this.actions.search());
	}

	public changedAddressForm(addressForm: AddressFormType): void {
		this.store.dispatch(this.actions.addressFormChanged({ addressForm }));
	}
}
