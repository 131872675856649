import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { ShippingReturnApiService } from '../../services/api/shipping.return.service';
import { ShippingReturnDialogService } from '../../services/dialog/shipping.return.service';
import { ReturnNotificationService } from '../../services/notification/shipping.return.service';
import { AppState, IShippingReturnState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromReturnActions } from './shipping.return.actions';
import { ReturnSelector } from './shipping.return.selectors';

@Injectable({
	providedIn: 'root',
})
export class ReturnEffects extends EntityEffects<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ShippingReturnApiService,
		notificationService: ReturnNotificationService,
		dialogService: ShippingReturnDialogService,
		selector: ReturnSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromReturnActions, 'RETURN');
	}
}
