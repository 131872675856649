import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IProcessRoute } from '../../models/process-route.model';
import { FilterConnection, IFilterDescriptor, IProcessRouteState, SortDescriptors } from '../../state/app.state';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-process-route-autocompletesingle',
	templateUrl: './entityautocompletesingle-process-route.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class ProcessRouteAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	public placeholder = 'Aktion';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'displayName', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IProcessRoute> = () => ({ displayName: 1 });

	constructor(entityFacade: ProcessRouteFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IProcessRoute): string {
		return entity?.displayName;
	}
}
