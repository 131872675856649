import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import 'moment/locale/de';
import { MainComponent } from 'src/components/main/main.component';
import { SidenavComponent } from 'src/components/sidenav/sidenav.component';
import { AuthorizationService } from 'src/services/api/authorization.service';
import { environment } from '../environments/environment';
import { MenuEffects } from '../state/menu/menu.effects';
import { actionReducerMap } from '../state/reducers';
import { SharedModule } from './shared.module';

@NgModule({
	declarations: [MainComponent, SidenavComponent],
	imports: [
		BrowserModule,
		SharedModule,
		HttpClientModule,
		HttpClientJsonpModule,
		BrowserAnimationsModule,
		EffectsModule.forRoot([MenuEffects]),
		RouterModule.forRoot(
			[
				{
					path: 'start',
					loadChildren: () => import('./startpage.module').then(m => m.StartpageModule),
					canActivate: [AuthorizationService],
				},
				{
					path: 'entity',
					loadChildren: () => import('./entitypage.module').then(m => m.EntitypageModule),
					canActivate: [AuthorizationService],
				},
				{
					path: 'login',
					loadChildren: () => import('./loginpage.module').then(m => m.LoginpageModule),
				},
				{
					path: '**',
					redirectTo: '/start',
				},
			],
			{
				scrollPositionRestoration: 'enabled',
			}
		),
		StoreModule.forRoot(actionReducerMap),
		StoreDevtoolsModule.instrument(),
		StoreRouterConnectingModule.forRoot(),
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: environment.serviceWorker,
			registrationStrategy: 'registerWhenStable:30000',
		}),
	],
	bootstrap: [MainComponent],
})
export class BabylonModule {}
