import { EntityState, IDocumentState } from '../app.state';
import { IDocument, IDocumentEditForm } from 'src/models/document.model';

import { EntityReducer } from '../entity.reducer';
import { IDocumentFilterForm } from '../../models/document.model';
import { fromDocumentActions } from './document.actions';
import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';

export const initialValue: IDocument = {
	_id: null,
	title: null,
	file: null,
	version: null,
};

export const initialEditFormValue: IDocumentEditForm = {
	_id: null,
	title: null,
	file: null,
	version: null,
};

export const initialFilterFormValue: IDocumentFilterForm = {
	_id: null,
	createdAt: null,
	title: null,
	file: null,
	version: null,
};

export const initialState = EntityState.create<IDocument, IDocumentEditForm, IDocumentFilterForm>(
	'DOCUMENT',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			title: 'CONTAINS',
		},
	],
	{
		title: 1,
	}
);

export const documentReducer = EntityReducer.create<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm>(
	initialState,
	{
		title: validate(required),
	},
	fromDocumentActions,
	on(fromDocumentActions.createdDocumentForm, state => ({ ...state, isFiltering: true })),
	on(fromDocumentActions.updatedDocumentForm, state => ({ ...state, isFiltering: true }))
);
