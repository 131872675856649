import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm } from '../../models/accounting-summary.model';
import { AccountingSummaryCustomerApiService } from '../../services/api/accounting-summary.customer.service';
import { AccountingSummaryCustomerDialogService } from '../../services/dialog/accounting-summary.customer.service';
import { AccountingSummaryCustomerNotificationService } from '../../services/notification/accounting-summary.customer.service';
import { AppState, IAccountingSummaryCustomerState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromAccountingSummaryCustomerActions } from './accounting-summary.customer.actions';
import { AccountingSummaryCustomerSelector } from './accounting-summary.customer.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerEffects extends EntityEffects<IAccountingSummaryCustomer, IAccountingSummaryCustomerState, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: AccountingSummaryCustomerApiService,
		notificationService: AccountingSummaryCustomerNotificationService,
		dialogService: AccountingSummaryCustomerDialogService,
		selector: AccountingSummaryCustomerSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromAccountingSummaryCustomerActions, 'ACCOUNTINGSUMMARYCUSTOMER');
	}
}
