import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IArticlePosition, IBundlePosition, IPosition } from '../../models/position.model';
import { AppState, IOrderState } from '../app.state';

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { ICustomer } from '../../models/customer.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IProduct } from '../../models/product.model';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class OrderSelector extends EntitySelector<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	protected stateSelector = (state: AppState): IOrderState => state.order;

	//public returnPositions = createSelector(this.selected, order => order.positions.filter(position => position.positionKind == PositionKind.Article || position.positionKind == PositionKind.Bundle));
	public editFormPositions = createSelector(this.editFormValue, order => order.positions);
	public positions = createSelector(this.selected, order => order.positions);
	public articlePositions = createSelector(this.positions, positions => positions.filter(position => position.positionKind == PositionKind.Article) as IArticlePosition[]);
	public bundlePositions = createSelector(this.positions, positions => positions.filter(position => position.positionKind == PositionKind.Bundle) as IBundlePosition[]);
	public returnPositions = createSelector(this.articlePositions, this.bundlePositions, (articlePositions, bundlePositions) => [...articlePositions, ...bundlePositions]);
	public articles = createSelector(this.articlePositions, this.bundlePositions, (articlePositions, bundlePositions) => [
		...articlePositions.map(position => position.article),
		..._.flatten(bundlePositions.map(position => position.articles)),
	]);
	public products = createSelector(this.articles, articles => articles.map(article => article.product as any as IProduct));

	public searchValue = createSelector(this.selectState, state => state.searchValue);
	public searchLimit = createSelector(this.selectState, state => state.searchLimit);
	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public totalCountSearched = createSelector(this.selectState, state => state.totalCountSearched);
	public listSearched = createSelector(this.items, this.itemsSearched, (items, itemsSearched) => itemsSearched.map(id => items[id]).filter(item => item != null));
	public customerWarning = (customer: ICustomer) => createSelector(this.selectState, state => state.warnings.find(warning => warning.customerId == customer._id));

	public getArticlesFromPosition = (position: IPosition) =>
		createSelector(this.positions, positions => {
			const selectedPosition = positions.find(x => x._id == position._id);

			if (selectedPosition?.positionKind == PositionKind.Article) {
				return (selectedPosition as IArticlePosition).article ? [(selectedPosition as IArticlePosition).article] : [];
			}

			if (selectedPosition?.positionKind == PositionKind.Bundle) {
				return (selectedPosition as IBundlePosition)?.articles ?? [];
			}

			return null;
		});

	public getArticlesFromEditPosition = (position: IPosition) =>
		createSelector(this.editFormPositions, positions => {
			const selectedPosition = positions.find(x => x._id == position._id);

			if (selectedPosition?.positionKind == PositionKind.Article) {
				return (selectedPosition as IArticlePosition).article ? [(selectedPosition as IArticlePosition).article] : [];
			}

			if (selectedPosition?.positionKind == PositionKind.Bundle) {
				return (selectedPosition as IBundlePosition).articles ?? [];
			}

			return null;
		});
}
