import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MagazineNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-magazine.component';
import { IArticleMagazine } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleMagazineNotificationService extends EntityNotificationService<IArticleMagazine> {
	protected component: ComponentType<any> = MagazineNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
