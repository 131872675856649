import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IProcessRoute } from 'src/models/process-route.model';
import { IProcessRouteState } from 'src/state/app.state';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-processroutedialogdelete',
	templateUrl: './entitydialogdelete-process-route.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class ProcessRouteDialogDeleteComponent extends EntityDialogDeleteComponent<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm> {
	constructor(entityFacade: ProcessRouteFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
