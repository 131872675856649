import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { NewspaperNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-newspaper.component';
import { IArticleNewspaper } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperNotificationService extends EntityNotificationService<IArticleNewspaper> {
	protected component: ComponentType<any> = NewspaperNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
