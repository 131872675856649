import { EntityState, IRegionState } from '../app.state';
import { IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { box, validate } from 'ngrx-forms';

import { EntityReducer } from '../entity.reducer';
import { IRegion } from '../../models/region.model';
import { fromRegionActions } from './region.actions';
import { on } from '@ngrx/store';
import { required } from 'ngrx-forms/validation';

export const initialValue: IRegion = {
	_id: null,
	processInstance: null,
	name: null,
	parentRegion: null,
	shortName: null,
	regionKind: null,
	location: {
		latitude: null,
		longitude: null,
	},
	bounds: {
		northeast: {
			latitude: null,
			longitude: null,
		},
		southwest: {
			latitude: null,
			longitude: null,
		},
	},
	address: null,
};

export const initialEditFormValue: IRegionEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	parentRegion: null,
	shortName: null,
	regionKind: null,
	location: {
		latitude: null,
		longitude: null,
	},
	bounds: {
		northeast: {
			latitude: null,
			longitude: null,
		},
		southwest: {
			latitude: null,
			longitude: null,
		},
	},
	address: null,
};

export const initialFilterFormValue: IRegionFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	parentRegion: box([]),
	shortName: null,
	regionKind: null,
	location: {
		latitude: null,
		longitude: null,
	},
	bounds: {
		northeast: {
			latitude: null,
			longitude: null,
		},
		southwest: {
			latitude: null,
			longitude: null,
		},
	},
	address: null,
};

export const initialState = {
	...EntityState.create<IRegion, IRegionEditForm, IRegionFilterForm>(
		'REGION',
		initialValue,
		initialEditFormValue,
		initialFilterFormValue,
		[
			{
				name: 'STARTSWITH',
				shortName: 'EQ',
				parentRegion: 'IN',
			},
		],
		{
			name: 1,
		}
	),
	name: null as string,
	itemsSearched: [] as string[],
	isSearching: false,
};

export const regionReducer = EntityReducer.create<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm>(
	initialState,
	{
		name: validate(required),
		regionKind: validate(required),
	},
	fromRegionActions,
	on(fromRegionActions.nameChanged, (state, { name }) => ({ ...state, name })),
	on(fromRegionActions.search, state => ({
		...state,
		error: initialState.error,
		isSearching: true,
	})),
	on(fromRegionActions.searched, (state, { entities }) => ({
		...state,
		error: initialState.error,
		isSearching: false,
		items: { ...state.items, ...entities.reduce((x, entity) => ({ ...x, [entity._id]: entity }), {}) },
		itemsSearched: entities.map(entity => entity._id),
	}))
);
