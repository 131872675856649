import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AddressFormType, IArticleCertificate, OccasionType } from '../../models/article.model';
import { IArticleCertificateState } from '../../state/app.state';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleCertificateEditForm, IArticleCertificateFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-certificatedialogchange',
	templateUrl: './entitydialogchange-certificate.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
	styles: [
		'@font-face { font-family: "Algerian"; src: url("/assets/fonts/alger.woff2") format("woff2"); font-weight: normal; font-style: normal; font-display: swap; } @font-face { font-family: "Lucida Calligraphy"; src: url("/assets/fonts/lucida_cbd.woff2") format("woff2"); font-weight: normal; font-style: normal; font-display: swap; } @font-face { font-family: "Times New Roman"; src: url("/assets/fonts/times.woff2") format("woff2"); font-weight: normal; font-style: normal; font-display: swap; }',
	],
})
export class CertificateDialogChangeComponent extends EntityDialogChangeComponent<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm> {
	public OccasionTypes = OccasionType;
	public AdressFormTypes = AddressFormType;

	constructor(private certificateFacade: ArticleCertificateFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(certificateFacade, matDialogRef);
	}

	public changedAddressForm(event: MatSelectChange) {
		this.certificateFacade.changedAddressForm(event.value);
	}
}
