import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IReceiptState } from '../../state/app.state';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { IReceipt, IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-receiptdialogadd',
	templateUrl: './entitydialogadd-receipt.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class ReceiptDialogAddComponent extends EntityDialogAddComponent<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	constructor(entityFacade: ReceiptFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
