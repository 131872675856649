import { EntityApiService } from './entity.service';
import { HttpClient } from '@angular/common/http';
import { IAccountingSummaryCustomer } from '../../models/accounting-summary.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerApiService extends EntityApiService<IAccountingSummaryCustomer> {
	protected entityName = 'accounting-customers';

	constructor(http: HttpClient) {
		super(http);
	}

	public downloadCsv(authToken: string, entity: IAccountingSummaryCustomer): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'text/csv');

		return this.post<any, any>([this.entityName, entity._id, 'generate-csv'], null, headers, 'blob');
	}
}
