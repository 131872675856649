import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProcessNodeCount } from '../../state/app.state';
import { ApiService, IDataResponse } from './api.service';

export interface IProcessNodeCounts {
	[key: string]: IProcessNodeCount[];
}

@Injectable({
	providedIn: 'root',
})
export class StatisticsApiService extends ApiService {
	constructor(http: HttpClient) {
		super(http);
	}

	public count(authToken: string): Observable<IDataResponse<IProcessNodeCounts>> {
		return this.get<IDataResponse<IProcessNodeCounts>>(['statistics', 'count'], this.getHeaders(authToken));
	}
}
