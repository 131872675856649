import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { AttachmentDialogService } from '../../services/dialog/attachment.service';
import { IMessageState } from '../../state/app.state';
import { MessageFacade } from '../../state/message/message.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-messagedialogadd',
	templateUrl: './entitydialogadd-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class MessageDialogAddComponent extends EntityDialogAddComponent<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	constructor(entityFacade: MessageFacade, matDialogRef: MatDialogRef<any>, private attachmentDialogService: AttachmentDialogService) {
		super(entityFacade, matDialogRef);
	}

	public createAttachment(width: string = null): void {
		this.attachmentDialogService.openCreateDialog({ entity: null, width });
	}
}
