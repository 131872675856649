<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">E-Mail Template hinzufügen</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="row">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
				</mat-form-field>
			</div>
			<mat-form-field>
				<mat-label>Betreff</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.subject" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>HTML-Template</mat-label>
				<textarea matInput cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.template"></textarea>
			</mat-form-field>
			<div class="row">
				<mat-checkbox [ngModelOptions]="{ standalone: true }" [(ngModel)]="showTestData">Testdaten zur Vorschau-Generierung anzeigen</mat-checkbox>
			</div>
		</form>
	</div>
	<div mat-dialog-actions>
		<button
			mat-raised-button
			color="primary"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>

		<button mat-button color="primary" (click)="matDialogRef.close()">Abbrechen</button>
	</div>
	<button class="close" mat-icon-button (click)="matDialogRef.close()">
		<mat-icon>close</mat-icon>
	</button>
</ng-container>
