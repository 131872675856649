import { EntityState, IUserState } from '../app.state';
import { IUser, IUserEditForm, IUserFilterForm } from 'src/models/user.model';
import { box, validate } from 'ngrx-forms';

import { EntityReducer } from '../entity.reducer';
import { fromUserActions } from './user.actions';
import { required } from 'ngrx-forms/validation';

export const initialValue: IUser = {
	_id: null,
	processInstance: null,
	email: null,
	password: null,
	name: null,
	permissions: [],
	image: null,
};

export const initialEditFormValue: IUserEditForm = {
	_id: null,
	processInstance: null,
	email: null,
	password: null,
	name: null,
	permissions: box([]),
	image: null,
};

export const initialFilterFormValue: IUserFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	email: null,
	password: null,
	name: null,
	permissions: box([]),
	image: null,
};

export const initialState = {
	...EntityState.create<IUser, IUserEditForm, IUserFilterForm>('USER', initialValue, initialEditFormValue, initialFilterFormValue, [{}], {
		name: 1,
	}),
	pageSize: 1000,
};

export const userReducer = EntityReducer.create<IUser, IUserState, IUserEditForm, IUserFilterForm>(
	initialState,
	{
		name: validate(required),
		email: validate(required),
	},
	fromUserActions
);
