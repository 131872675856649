import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { AppState, IPredictionState } from '../app.state';
import { BaseSelector } from '../base.selector';

@Injectable({
	providedIn: 'root',
})
export class PredictionSelector extends BaseSelector<IPredictionState> {
	protected stateSelector = (state: AppState): IPredictionState => state.prediction;

	public receiptTotalPrice = createSelector(this.selectState, state => state.receiptTotalPrice);
	public isLoading = createSelector(this.selectState, state => state.isLoading);
	public receiptTotalPriceOfToday = createSelector(this.receiptTotalPrice, receiptTotalPrice => (receiptTotalPrice != null ? receiptTotalPrice[moment().format('YYYY-MM-DD')] : null));
}
