import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm } from '../../models/stock.model';
import { AppState, IStockAccessoryState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromAccessoryStockActions } from './stock.accessory.actions';
import { initialEditFormValue } from './stock.accessory.reducer';
import { AccessoryStockSelector } from './stock.accessory.selectors';

@Injectable({
	providedIn: 'root',
})
export class AccessoryStockFacade extends EntityFacade<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromAccessoryStockActions;

	constructor(store: Store<AppState>, accessoryStockSelector: AccessoryStockSelector) {
		super(store, accessoryStockSelector);
	}
}
