<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Kommentar</h1>
<mat-dialog-content>
	<form [formGroup]="formGroup">
		<div class="form-grid">
			<mat-form-field appearance="outline" class="mat-form-field--span">
				<mat-label>Kommentar</mat-label>
				<textarea matInput cdkTextareaAutosize rows="5" formControlName="comment"></textarea>
			</mat-form-field>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
	<button mat-flat-button color="accent" (click)="matDialogRef.close(formGroup.value)" [disabled]="!formGroup.valid">Speichern</button>
</mat-dialog-actions>
