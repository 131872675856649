<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="updatedAt-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="updatedAt">Änderungsdatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="updatedAt-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="updatedAt-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.updatedAt | amLocal | amDateFormat: 'DD.MM.YYYY HH:mm:ss (dd)' }}
							</td>
						</ng-container>

						<ng-container matColumnDef="order-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="order">Bestellung</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="order-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
									<a [routerLink]="['/entity', 'order', 'change', order._id]">{{ order.number }}</a>
								</ng-container>
								<babylon-fetch [facade]="orderFacade" [value]="entity.order"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="publicationDate-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="publicationDate">Publizierungsdatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="publicationDate-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Publizierungsdatum</mat-label>
										<input matInput [matDatepicker]="publicationDate" [ngrxFormControlState]="filterForm.controls[0].controls.publicationDate" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="publicationDate"></mat-datepicker-toggle>
										<mat-datepicker #publicationDate></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.publicationDate.value"
										[controlId]="filterOperatorForm.controls[0].controls.publicationDate.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="publicationDate-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.publicationDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)' }}
							</td>
						</ng-container>

						<ng-container matColumnDef="quality-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="quality">Qualität</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="quality-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="quality-content">
							<td mat-cell *matCellDef="let entity">{{ entity.quality }}</td>
						</ng-container>

						<ng-container matColumnDef="storagePlace-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="storagePlace">Lagerplatz</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="storagePlace-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-storage-place-autocompletesingle [value]="filterForm.controls[0].controls.storagePlace.value" [controlId]="filterForm.controls[0].controls.storagePlace.id"></babylon-storage-place-autocompletesingle>
							</th>
						</ng-container>
						<ng-container matColumnDef="storagePlace-content">
							<td mat-cell *matCellDef="let entity">
								{{ (storagePlaceFacade.fetchOne(entity.storagePlace) | async)?.identifier }}
								<babylon-fetch [facade]="storagePlaceFacade" [value]="entity.storagePlace"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="product-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="product">Produkt</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="product-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-media-product-autocompletesingle [value]="filterForm.controls[0].controls.product.value" [controlId]="filterForm.controls[0].controls.product.id"></babylon-media-product-autocompletesingle>
							</th>
						</ng-container>
						<ng-container matColumnDef="product-content">
							<td mat-cell *matCellDef="let entity">
								{{ (mediaProductFacade.fetchOne(entity.product) | async)?.name }}
								<babylon-fetch [facade]="mediaProductFacade" [value]="entity.product"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="assignee-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Bearbeiter</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="assignee-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="assignee-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-profile-picture [userId]="entity.processInstance.currentUser"></babylon-profile-picture>
							</td>
						</ng-container>

						<ng-container matColumnDef="state-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="state">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-state [entity]="entity" [facade]="entityFacade"></babylon-process-state>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<babylon-process-buttons [facade]="entityFacade" [entity]="entity" mode="icon"></babylon-process-buttons>
								<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
									<mat-icon svgIcon="pencil"></mat-icon>
								</button>
								<button mat-icon-button (click)="remove(entity)" matTooltip="Löschen">
									<mat-icon svgIcon="trash-can-outline"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<button mat-flat-button color="accent" (click)="create()">
								<mat-icon svgIcon="plus"></mat-icon>
								Hinzufügen
							</button>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
