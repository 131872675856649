import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { ArticleKind, IArticle } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleApiService } from '../../services/api/article.service';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { ArticleNotificationService } from '../../services/notification/article.service';
import { AppState, IArticleState } from '../app.state';
import { fromArticleMagazineActions } from '../article.magazine/article.magazine.actions';
import { ArticleMagazineSelector } from '../article.magazine/article.magazine.selectors';
import { fromArticleNewspaperActions } from '../article.newspaper/article.newspaper.actions';
import { ArticleNewspaperSelector } from '../article.newspaper/article.newspaper.selectors';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { fromArticleActions } from './article.actions';
import { ArticleSelector } from './article.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleEffects extends EntityEffects<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleApiService,
		private articleDialogService: ArticleDialogService,
		notificationService: ArticleNotificationService,
		selector: ArticleSelector,
		sessionSelector: SessionSelector,
		private newspaperSelector: ArticleNewspaperSelector,
		private magazineSelector: ArticleMagazineSelector
	) {
		super(actions$, store, apiService, notificationService, articleDialogService, selector, sessionSelector, fromArticleActions, 'ARTICLE');
	}

	public onStoragePlaceUpdate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleActions.updateStoragePlace),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ articleKind, entity, storagePlaceId }, authToken]) =>
				this.apiService.updateStoragePlace(entity, storagePlaceId, authToken).pipe(
					map(result => {
						return fromArticleActions.updatedStoragePlaces({ articleKind, storagePlaceId });
					}),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onStoragesPlaceUpdate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleActions.updateStoragePlaces),
			concatLatestFrom(({ articleKind }) => {
				switch (articleKind) {
					case ArticleKind.Newspaper:
						return this.store.select(this.newspaperSelector.filterQuery);
					case ArticleKind.Magazine:
						return this.store.select(this.magazineSelector.filterQuery);
					default:
						return this.store.select(this.entitySelector.filterQuery);
				}
			}),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ articleKind, storagePlaceId }, query], authToken]) =>
				this.apiService.updateStoragePlaces({ query }, storagePlaceId, authToken).pipe(
					map(result => {
						return fromArticleActions.updatedStoragePlaces({ articleKind, storagePlaceId });
					}),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDeleteByFilter$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleActions.deleteByFilter),
			concatLatestFrom(({ articleKind }) => {
				switch (articleKind) {
					case ArticleKind.Newspaper:
						return this.store.select(this.newspaperSelector.filterQuery);
					case ArticleKind.Magazine:
						return this.store.select(this.magazineSelector.filterQuery);
					default:
						return this.store.select(this.entitySelector.filterQuery);
				}
			}),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([[{ articleKind }, query], authToken]) =>
				this.apiService.deleteFilter({ query }, authToken).pipe(
					map(() => {
						switch (articleKind) {
							case ArticleKind.Newspaper:
								return fromArticleNewspaperActions.filter();
							case ArticleKind.Magazine:
								return fromArticleMagazineActions.filter();
							default:
								return fromArticleActions.filter();
						}
					}),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onUpdatedStorageplaces$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromArticleActions.updatedStoragePlaces),
				filter(({ articleKind, storagePlaceId }) => storagePlaceId != null && articleKind != null),
				tap(({ articleKind, storagePlaceId }) => this.articleDialogService.openStoragePlaceUpdated(articleKind, storagePlaceId))
			),
		{
			dispatch: false,
		}
	);
}
