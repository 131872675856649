import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../components/dialog/dialog-confirmation.component';
import { ErrorDialogComponent } from '../../components/dialog/dialog-error.component';
import { IEntity } from '../../models/entity.model';

export interface IDialogData {
	width?: string;
	height?: string;
}

export interface IConfirmationDialogData extends IDialogData {
	title: string;
	text: string;
	onConfirm: () => void;
	onReject: () => void;
	confirmButtonText?: string;
	rejectButtonText?: string;
}

export interface IErrorDialogData extends IDialogData {
	title: string;
	text: string;
	error: any;
	onReject: () => void;
}

export interface IEntityDialogData<TEntity extends IEntity> extends IDialogData {
	entity?: TEntity;
}

@Injectable({
	providedIn: 'root',
})
export abstract class DialogService {
	protected errorDialogRef: MatDialogRef<any>;
	protected dialogRef: MatDialogRef<any>;
	protected confirmationComponent: ComponentType<any> = ConfirmationDialogComponent;
	protected errorComponent: ComponentType<any> = ErrorDialogComponent;

	constructor(protected matDialog: MatDialog) {}

	public openConfirmationDialog(data: IConfirmationDialogData): void {
		this.openDialog(this.confirmationComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data: {
				...data,
				onConfirm: () => {
					data.onConfirm();
					this.dialogRef.close();
				},
				onReject: () => {
					data.onReject();
					this.dialogRef.close();
				},
			},
		});
	}

	public openErrorDialog(data: IErrorDialogData): void {
		if (this.errorDialogRef == null) {
			this.errorDialogRef = this.openDialog(this.errorComponent, {
				width: data.width,
				height: data.height,
				disableClose: false,
				minWidth: '400px',
				data: {
					...data,
					onReject: () => {
						data.onReject();
						this.errorDialogRef.close();
						this.errorDialogRef = null;
					},
				},
			});
		}
	}

	protected openDialog<T, D>(component: ComponentType<T>, config?: MatDialogConfig<D>): MatDialogRef<any> {
		this.dialogRef = this.matDialog.open(component, config);
		return this.dialogRef;
	}

	public closeDialog(): void {
		this.dialogRef?.close();
	}
}
