import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IReceiptInvoice, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm } from '../../models/receipt';
import { ReceiptInvoiceApiService } from '../../services/api/receipt.invoice.service';
import { ReceiptInvoiceDialogService } from '../../services/dialog/receipt.invoice.service';
import { InvoiceNotificationService } from '../../services/notification/receipt.invoice.service';
import { AppState, IReceiptInvoiceState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromInvoiceActions } from './receipt.invoice.actions';
import { InvoiceSelector } from './receipt.invoice.selectors';

@Injectable({
	providedIn: 'root',
})
export class InvoiceEffects extends EntityEffects<IReceiptInvoice, IReceiptInvoiceState, IReceiptInvoiceEditForm, IReceiptInvoiceFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ReceiptInvoiceApiService,
		notificationService: InvoiceNotificationService,
		dialogService: ReceiptInvoiceDialogService,
		selector: InvoiceSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromInvoiceActions, 'INVOICE');
	}
}
