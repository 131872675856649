<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Statistik ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-form-field appearance="outline">
				<mat-label>Typ</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.type" />
			</mat-form-field>
			<mat-form-field appearance="outline">
				<mat-label>Wert</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.value" />
			</mat-form-field>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
