<ng-container *ngIf="entityFacade.selected$ | async as entity">
	<mat-dialog-content class="picking-dialog">
		<form>
			<div class="form-grid">
				<div class="form-grid--left">
					<h3 class="mat-h3" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">
						{{ entity.paymentKind }}
					</h3>
				</div>
			</div>
			<mat-divider></mat-divider>
			<div class="form-grid">
				<div class="form-grid--left">
					<mat-form-field appearance="outline">
						<mat-label>Kommentar</mat-label>
						<textarea matInput rows="24" [(ngModel)]="comment" [ngModelOptions]="{ standalone: true }"></textarea>
					</mat-form-field>
				</div>
				<div class="form-grid--right">
					<mat-form-field appearance="outline" class="readonly">
						<mat-label>Zahlbetrag</mat-label>
						<input type="text" matInput readonly />
						<span>{{ entity.dueAmount | currency }}</span>
					</mat-form-field>
					<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
						<mat-form-field *ngIf="customerFacade.fetchOne(order.customer) | async as customer" appearance="outline" class="readonly">
							<mat-label>Kunde</mat-label>
							<input type="text" matInput readonly />
							<span>{{ customer.firstName }} {{ customer.lastName }}</span>
						</mat-form-field>
						<mat-form-field appearance="outline" class="readonly">
							<mat-label>Auftragsnummer</mat-label>
							<input type="text" matInput readonly />
							<span>{{ order.number }}</span>
						</mat-form-field>
						<mat-form-field *ngIf="receiptFacade.fetchManyCombined(order) | async as receipts" appearance="outline" class="readonly">
							<mat-label>Belegnummer</mat-label>
							<input type="text" matInput readonly />
							<span *ngIf="receiptFacade.getOrderReceiptByPayment(receipts, entity) as receipt">{{ receipt.number ? receipt.number : 'Proforma' }}</span>
						</mat-form-field>
					</ng-container>
					<br />
					<button mat-button (click)="paymentDialogService.openUpdateDialog({ entity })" color="accent"><mat-icon svgIcon="pencil"></mat-icon> Zahlbetrag ändern</button>
				</div>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Schließen</button>
		<babylon-process-buttons [facade]="paymentFacade" [comment]="comment"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
