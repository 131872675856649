import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BookNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-book.component';
import { IArticleBook } from '../../models/article.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleBookNotificationService extends EntityNotificationService<IArticleBook> {
	protected component: ComponentType<any> = BookNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
