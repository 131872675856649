import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind } from '../../models/article.model';
import { IBundle } from '../../models/bundle.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IPosition } from '../../models/position.model';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { DialogComponent } from './dialog.component';

export interface IPositionAddDialogData {
	position: IPosition;
	articleKind?: ArticleKind;
	bundle?: IBundle;
}
@Component({
	selector: 'babylon-positionadddialog',
	templateUrl: './dialog-position-add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class PositionAddDialogComponent extends DialogComponent<PositionAddDialogComponent, IPositionAddDialogData> {
	public PositionKinds = PositionKind;
	public ArticleKinds = ArticleKind;

	constructor(matDialogRef: MatDialogRef<PositionAddDialogComponent, IPositionAddDialogData>, formBuilder: FormBuilder, @Inject(MAT_DIALOG_DATA) data: IPositionAddDialogData, public bundleFacade: BundleFacade) {
		super(
			matDialogRef,
			data,
			formBuilder.group({
				position: formBuilder.group({
					title: null,
					positionKind: [{ value: PositionKind.Article }, [Validators.required]],
					price: null,
				}),
				articleKind: [{ value: ArticleKind.Newspaper }],
				bundle: null,
			})
		);
		bundleFacade.filter();
	}
}

export const positionKind: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
	const positionKind = control.get('positionKind');
	const articleKind = control.get('articleKind');

	return positionKind.value == PositionKind.Article && articleKind ? { positionKind: true } : null;
};
