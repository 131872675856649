import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IImage, IImageEditForm } from 'src/models/image.model';
import { IImageState } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { IImageFilterForm } from './../../models/image.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-picturedialogchange',
	templateUrl: './entitydialogchange-image.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class ImageDialogChangeComponent extends EntityDialogChangeComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	private imageFile: File = null;

	constructor(private imageFacade: ImageFacade, matDialogRef: MatDialogRef<any>) {
		super(imageFacade, matDialogRef);
	}

	public onImageChanged($event: any): void {
		this.imageFile = $event.target.files[0];
	}

	public updateImage(image: IImage) {
		if (this.imageFile != null) {
			this.imageFacade.updatedImage(image, this.imageFile);
		} else {
			this.imageFacade.updated();
		}
	}
}
