import { Component, Input } from '@angular/core';
import { IEntity, IEntityEditForm, IEntityFilterForm } from '../../models/entity.model';
import { IEntityState } from '../../state/app.state';
import { EntityFacade } from '../../state/entity.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { BaseComponent } from '../base.component';

@Component({
	selector: 'babylon-process-state',
	styleUrls: ['./process-state.component.scss'],
	templateUrl: './process-state.component.html',
})
export class ProcessStateComponent<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseComponent {
	@Input() public facade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>;
	@Input() public entity: TEntity;

	constructor(public processNodeFacade: ProcessNodeFacade) {
		super();
	}
}
