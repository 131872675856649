import { Injectable } from '@angular/core';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { AppState, IShippingReturnState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class ReturnSelector extends EntitySelector<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	protected stateSelector = (state: AppState): IShippingReturnState => state.shippingDhl;
}
