import { createAction, props } from '@ngrx/store';
import { AddressFormType, IArticle, IArticleCertificate, IExpandedArticleCertificate } from '../../models/article.model';
import { EntityActions, IFormValueChange } from '../entity.actions';

import { IArticleCertificateEditForm } from '../../models/article.model';

export const fromArticleCertificateActions = {
	...EntityActions.create<IArticleCertificate, IArticleCertificateEditForm>('[Certificate]'),
	download: createAction(`[Certificate] Download`, props<{ certificate: IArticle }>()),
	downloaded: createAction(`[Certificate] Downloaded`, props<{ certificate: IArticle; data: any }>()),
	print: createAction(`[Certificate] Print`, props<{ certificate: IArticle }>()),
	printed: createAction(`[Certificate] Printed`),
	search: createAction(`[Certificate] Search`),
	searched: createAction(`[Certificate] Searched`, props<{ entities: IExpandedArticleCertificate[] }>()),
	updateExpandedForm: createAction(`[Certificate] Update Expanded Form`, props<{ entity: IExpandedArticleCertificate }>()),
	expandedFormValueChanged: createAction(`[Certificate] Expanded Form Value Changed`, props<{ changes: IFormValueChange[] }>()),
	addressFormChanged: createAction(`[Certificate] Change Address Form`, props<{ addressForm: AddressFormType }>()),
};
