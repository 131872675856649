import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IStockMedia, IStockMediaEditForm, IStockMediaFilterForm } from '../../models/stock.model';
import { StockMediaApiService } from '../../services/api/stock.media.service';
import { StockMediaDialogService } from '../../services/dialog/stock.media.service';
import { MediaStockNotificationService } from '../../services/notification/stock.media.service';
import { AppState, IStockMediaState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromMediaStockActions } from './stock.media.actions';
import { MediaStockSelector } from './stock.media.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaStockEffects extends EntityEffects<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: StockMediaApiService,
		notificationService: MediaStockNotificationService,
		dialogService: StockMediaDialogService,
		selector: MediaStockSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromMediaStockActions, 'MEDIASTOCK');
	}
}
