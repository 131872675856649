import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { filter, first } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { AddressFormType, ArticleKind, IExpandedArticle, OccasionType } from '../../models/article.model';
import { IBundlePosition } from '../../models/position.model';
import { IRegion } from '../../models/region.model';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { ArticleFolderFacade } from '../../state/article.folder/article.folder.facade';
import { ArticleMagazineFacade } from '../../state/article.magazine/article.magazine.facade';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { DialogComponent } from './dialog.component';

@Component({
	selector: 'babylon-bundle-positionadddialog',
	templateUrl: './dialog-bundle-position-add.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./dialog.component.scss'],
	animations: [progressFade],
})
export class BundlePositionAddDialogComponent extends DialogComponent<BundlePositionAddDialogComponent, IBundlePosition> {
	public formGroup: FormGroup;
	public newspaperColumns = ['publicationDate', 'product', 'region', 'price', 'add'];
	public magazineColumns = ['publicationDate', 'product', 'region', 'price', 'add'];
	public folderColumns = ['product', 'storagePlace', 'add'];
	public articleKinds = ArticleKind;
	public OccasionTypes = OccasionType;
	public AdressFormTypes = AddressFormType;

	public position: IBundlePosition;

	public displayRegionWith = (region: IRegion) => region.name;

	constructor(
		matDialogRef: MatDialogRef<BundlePositionAddDialogComponent, IBundlePosition>,
		formBuilder: FormBuilder,
		@Inject(MAT_DIALOG_DATA) data: IBundlePosition,
		public bundleFacade: BundleFacade,
		public newspaperFacade: ArticleNewspaperFacade,
		public magazineFacade: ArticleMagazineFacade,
		public folderFacade: ArticleFolderFacade,
		public certificateFacade: ArticleCertificateFacade,
		public cartFacade: CartFacade,
		public regionFacade: RegionFacade,
		public orderFacade: OrderFacade
	) {
		super(matDialogRef, data);

		bundleFacade.filter();
		this.folderFacade.search();
		this.certificateFacade.search();

		this.formGroup = formBuilder.group({
			newspaper: formBuilder.group({
				publicationDate: [[Validators.required]],
			}),
			magazine: formBuilder.group({
				publicationDate: [[Validators.required]],
			}),
		});
		this.position = { ...data };
	}

	public searchNewspaper(): void {
		this.newspaperFacade.search(this.formGroup.value.newspaper.publicationDate);
	}

	public searchMagazine(): void {
		this.magazineFacade.search(this.formGroup.value.magazine.publicationDate);
	}

	public addArticle(article: IExpandedArticle, articleKind: ArticleKind): void {
		let articles = [...this.position.articles];
		let index = articles.findIndex(a => a.articleKind == articleKind);

		if (index > -1) {
			articles[index] = article;
		} else {
			articles.push(article);
		}

		this.position.articles = articles;
	}

	public onBlurRegion(value: string): void {
		this.regionFacade.selected$
			.pipe(
				first(),
				filter(region => region == null && value != null && value.length > 0)
			)
			.subscribe(() => {
				this.regionFacade.changeName('');
			});
	}

	public addPosition(): void {
		this.certificateFacade.expandedEditFormValue$.pipe(first()).subscribe(value => {
			this.position.articles = [...this.position.articles, value];
			this.cartFacade.validatePositions(this.position);
			this.matDialogRef.close();
		});
	}

	public cancel(): void {
		this.orderFacade.removePosition(this.position);
		this.matDialogRef.close();
	}

	public positionShouldContain(articleKind: ArticleKind) {
		return this.position.bundle.components.some(a => a.articleKind === articleKind);
	}

	public positionContains(articleKind: ArticleKind) {
		return this.position.articles.some(a => a.articleKind === articleKind);
	}

	public positionContainsId(id: string) {
		return this.position.articles.some(a => a._id == id);
	}

	public positionInvalid() {
		return this.position.bundle.components.some(c => (c.articleKind != ArticleKind.Certificate ? !this.positionContains(c.articleKind) : false));
	}

	public changedAddressForm(event: MatSelectChange) {
		this.certificateFacade.changedAddressForm(event.value);
	}
}
