import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BarzahlenDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-barzahlen.component';
import { BarzahlenDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-barzahlen.component';
import { BarzahlenDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-barzahlen.component';
import { IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from '../../models/payment.model';
import { IPaymentBarzahlenState } from '../../state/app.state';
import { BarzahlenFacade } from '../../state/payment.barzahlen/payment.barzahlen.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PaymentBarzahlenDialogService extends EntityDialogService<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {
	protected createComponent: ComponentType<any> = BarzahlenDialogAddComponent;
	protected updateComponent: ComponentType<any> = BarzahlenDialogChangeComponent;
	protected removeComponent: ComponentType<any> = BarzahlenDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: BarzahlenFacade) {
		super(matDialog, entityFacade);
	}
}
