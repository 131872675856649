import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MediaArticleKind } from '../../models/enums/mediaarticle-kind.enum';
import { IMediaProduct } from '../../models/media-product.model';
import { IMediaProductState } from '../../state/app.state';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-media-productdialogchange',
	templateUrl: './entitydialogchange-media-product.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class MediaProductDialogChangeComponent extends EntityDialogChangeComponent<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	public ArticleKinds = MediaArticleKind;

	constructor(entityFacade: MediaProductFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
