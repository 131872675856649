import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaProductDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-media-product.component';
import { MediaProductDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-media-product.component';
import { MediaProductDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-media-product.component';
import { IMediaProduct } from '../../models/media-product.model';
import { IMediaProductState } from '../../state/app.state';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { IMediaProductEditForm, IMediaProductFilterForm } from './../../models/media-product.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class MediaProductDialogService extends EntityDialogService<IMediaProduct, IMediaProductState, IMediaProductEditForm, IMediaProductFilterForm> {
	protected createComponent: ComponentType<any> = MediaProductDialogAddComponent;
	protected updateComponent: ComponentType<any> = MediaProductDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MediaProductDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: MediaProductFacade) {
		super(matDialog, entityFacade);
	}
}
