import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MediaStockDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-media-stock.component';
import { MediaStockDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-media-stock.component';
import { MediaStockDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-media-stock.component';
import { IStockMedia, IStockMediaEditForm, IStockMediaFilterForm } from '../../models/stock.model';
import { IStockMediaState } from '../../state/app.state';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class StockMediaDialogService extends EntityDialogService<IStockMedia, IStockMediaState, IStockMediaEditForm, IStockMediaFilterForm> {
	protected createComponent: ComponentType<any> = MediaStockDialogAddComponent;
	protected updateComponent: ComponentType<any> = MediaStockDialogChangeComponent;
	protected removeComponent: ComponentType<any> = MediaStockDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: MediaStockFacade) {
		super(matDialog, entityFacade);
	}
}
