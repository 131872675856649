import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PublisherDialogAddComponent } from 'src/components/entitydialogadd/entitydialogadd-publisher.component';
import { PublisherDialogChangeComponent } from 'src/components/entitydialogchange/entitydialogchange-publisher.component';
import { PublisherDialogDeleteComponent } from 'src/components/entitydialogdelete/entitydialogdelete-publisher.component';
import { IPublisher } from 'src/models/publisher.model';
import { IPublisherState } from 'src/state/app.state';
import { PublisherFacade } from 'src/state/publisher/publisher.facade';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PublisherDialogService extends EntityDialogService<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	protected createComponent: ComponentType<any> = PublisherDialogAddComponent;
	protected updateComponent: ComponentType<any> = PublisherDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PublisherDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PublisherFacade) {
		super(matDialog, entityFacade);
	}
}
