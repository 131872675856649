import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { ICustomer } from '../../models/customer.model';
import { FilterConnection, ICustomerState, IFilterDescriptor, SortDescriptors } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { ICustomerEditForm, ICustomerFilterForm } from './../../models/customer.model';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-customer-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-customer.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class CustomerAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<ICustomer, ICustomerState, ICustomerEditForm, ICustomerFilterForm> {
	public placeholder = 'Kunden';
	public columns: string[] = ['number', 'firstName', 'lastName', 'delete'];

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [
		{ attributeName: 'lastName', operator: 'CONTAINS', value },
		{ attributeName: 'firstName', operator: 'CONTAINS', value },
	];
	protected override sortDescriptors: () => SortDescriptors<ICustomer> = () => ({ lastName: 1 });

	constructor(entityFacade: CustomerFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: ICustomer): string {
		return entity != null ? `${entity.firstName} ${entity.lastName}` : null;
	}
}
