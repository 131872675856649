<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Zahlbetrag ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm" *ngIf="entityFacade.selected$ | async as barzahlen">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Betrag</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.dueAmount" [ngrxValueConverter]="priceConverter" />
							<span matSuffix>€</span>
						</mat-form-field>
						<ng-container *ngIf="orderFacade.fetchOne(barzahlen.order) | async as order">
							<mat-form-field *ngIf="customerFacade.fetchOne(order.customer) | async as customer" appearance="outline" class="readonly">
								<mat-label>Kunde</mat-label>
								<input type="text" matInput readonly />
								<span>{{ customer.firstName }} {{ customer.lastName }}</span>
							</mat-form-field>
							<mat-form-field appearance="outline" class="readonly">
								<mat-label>Auftragsnummer</mat-label>
								<input type="text" matInput readonly />
								<span>{{ order.number }}</span>
							</mat-form-field>
							<mat-form-field *ngIf="receiptFacade.fetchManyCombined(order) | async as receipts" appearance="outline" class="readonly">
								<mat-label>Rechnungsnummer</mat-label>
								<input type="text" matInput readonly />
								<span *ngIf="receiptFacade.getOrderReceiptByPayment(receipts, barzahlen) as receipt">{{ receipt.number ? receipt.number : 'Proforma' }}</span>
							</mat-form-field>
						</ng-container>
					</div>
				</mat-tab>
				<mat-tab label="Historie">
					<babylon-paymenthistorytable controlId="processInstance.actions"></babylon-paymenthistorytable>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
