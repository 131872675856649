<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="from-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="from">Von</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="from-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Von</mat-label>
										<input matInput [matDatepicker]="from" [ngrxFormControlState]="filterForm.controls[0].controls.from" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="from"></mat-datepicker-toggle>
										<mat-datepicker #from></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.from.value"
										[controlId]="filterOperatorForm.controls[0].controls.from.id"
										[availableOptions]="['DATEEQ', 'GTE', 'LTE', 'GT', 'LT']"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="from-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.from | amLocal | amDateFormat: 'DD.MM.YYYY' }}
							</td>
						</ng-container>

						<ng-container matColumnDef="until-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="until">Bis</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="until-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Bis</mat-label>
										<input matInput [matDatepicker]="until" [ngrxFormControlState]="filterForm.controls[0].controls.until" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="until"></mat-datepicker-toggle>
										<mat-datepicker #until></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.until.value"
										[controlId]="filterOperatorForm.controls[0].controls.until.id"
										[availableOptions]="['DATEEQ', 'GTE', 'LTE', 'GT', 'LT']"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="until-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.until | amLocal | amDateFormat: 'DD.MM.YYYY' }}
							</td>
						</ng-container>

						<ng-container matColumnDef="totalAmount-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="totalAmount">Betrag</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalAmount-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Preis</mat-label>
										<input type="number" matInput [ngrxFormControlState]="filterForm.controls[0].controls.totalAmount" />
										<span matSuffix>€</span>
									</mat-form-field>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalAmount-content">
							<td mat-cell *matCellDef="let entity">{{ entity.totalAmount | currency: 'EUR':'symbol' }}</td>
						</ng-container>

						<ng-container matColumnDef="positionCount-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Anzahl Positionen</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="positionCount-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="positionCount-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.positions.length }}
							</td>
						</ng-container>

						<ng-container matColumnDef="partner-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Partner</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="partner-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="partner-content">
							<td mat-cell *matCellDef="let entity">
								{{ (partnerFacade.fetchOne(entity.partner) | async)?.name }}
								<babylon-fetch [facade]="partnerFacade" [value]="entity.partner"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="state-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="state">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<ng-container *ngIf="processFacade.findByName('CollectionPurchaseTransfer') | async as process">
										<babylon-process-node-autocompletemultiple
											[optional]="true"
											[process]="process"
											[values]="filterForm.controls[0].controls.processInstance.controls.state.value"
											[controlId]="filterForm.controls[0].controls.processInstance.controls.state.id"></babylon-process-node-autocompletemultiple>
										<babylon-filteroperator
											[entityFacade]="entityFacade"
											[availableOptions]="['IN']"
											[value]="filterOperatorForm.controls[0].controls.processInstance.controls.state.value"
											[controlId]="filterOperatorForm.controls[0].controls.processInstance.controls.state.id"></babylon-filteroperator>
									</ng-container>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-process-state [entity]="entity" [facade]="entityFacade"></babylon-process-state>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<babylon-process-buttons [facade]="entityFacade" [entity]="entity" mode="icon"></babylon-process-buttons>
								<ng-container *ngIf="processNodeFacade.fetchOne(entity.processInstance.state) | async as processNode">
									<button mat-icon-button (click)="entityFacade.downloadCsv(entity)" matTooltip="Download CSV" [disabled]="entity.positions.length == 0">
										<mat-icon svgIcon="file-delimited-outline"></mat-icon>
									</button>
									<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten" *ngIf="processNode.name != 'CollectionPurchaseTransferFinished'">
										<mat-icon svgIcon="pencil"></mat-icon>
									</button>
									<button mat-icon-button (click)="remove(entity)" matTooltip="Löschen" *ngIf="processNode.name != 'CollectionPurchaseTransferFinished'">
										<mat-icon svgIcon="trash-can-outline"></mat-icon>
									</button>
								</ng-container>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<button mat-flat-button color="accent" (click)="create()">
								<mat-icon svgIcon="plus"></mat-icon>
								Hinzufügen
							</button>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
