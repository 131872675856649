import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IReceiptCorrection, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm } from '../../models/receipt';
import { ReceiptCorrectionApiService } from '../../services/api/receipt.correction.service';
import { ReceiptCorrectionDialogService } from '../../services/dialog/receipt.correction.service';
import { CorrectionNotificationService } from '../../services/notification/receipt.correction.service';
import { AppState, IReceiptCorrectionState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCorrectionActions } from './receipt.correction.actions';
import { CorrectionSelector } from './receipt.correction.selectors';

@Injectable({
	providedIn: 'root',
})
export class CorrectionEffects extends EntityEffects<IReceiptCorrection, IReceiptCorrectionState, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: ReceiptCorrectionApiService,
		notificationService: CorrectionNotificationService,
		dialogService: ReceiptCorrectionDialogService,
		selector: CorrectionSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCorrectionActions, 'CORRECTION');
	}
}
