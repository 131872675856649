import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStockAccessory } from '../../models/stock.model';
import { IStockAccessoryState } from '../../state/app.state';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-accessorystockdialogadd',
	templateUrl: './entitydialogadd-accessory-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class AccessoryStockDialogAddComponent extends EntityDialogAddComponent<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	constructor(entityFacade: AccessoryStockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
