import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IPublisher } from 'src/models/publisher.model';
import { EntityState, IPublisherState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { fromPublisherActions } from './publisher.actions';

export const initialValue: IPublisher = {
	_id: null,
	processInstance: null,
	name: null,
	description: null,
};

export const initialEditFormValue: IPublisherEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	description: null,
};

export const initialFilterFormValue: IPublisherFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	description: null,
};

export const initialState = EntityState.create<IPublisher, IPublisherEditForm, IPublisherFilterForm>('PUBLISHER', initialValue, initialEditFormValue, initialFilterFormValue);
export const publisherReducer = EntityReducer.create<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm>(
	initialState,
	{
		name: validate(required),
		description: validate(required),
	},
	fromPublisherActions
);
