import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IStoragePlace } from '../../models/storage-place.model';
import { IServiceError } from '../../services/api/api.service';
import { EntityNotificationErrorComponent } from './entitynotificationerror.component';

@Component({
	selector: 'babylon-storage-placenotificationerror',
	templateUrl: './entitynotificationerror-storage-place.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitynotificationerror.component.scss'],
})
export class StoragePlaceNotificationErrorComponent extends EntityNotificationErrorComponent<IStoragePlace> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
