import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IPage, IPageEditForm, IPageFilterForm } from '../../models/page.model';
import { IPageState } from '../../state/app.state';
import { PageFacade } from '../../state/page/page.facade';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-pagedialogadd',
	templateUrl: './entitydialogadd-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PageDialogAddComponent extends EntityDialogAddComponent<IPage, IPageState, IPageEditForm, IPageFilterForm> {
	public SalutationKinds = SalutationKind;
	public PaymentKinds = PaymentKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: PageFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
