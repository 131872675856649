import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNoteOrder, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm } from '../../models/delivery-note';
import { IDeliveryNoteOrderState } from '../../state/app.state';
import { DeliveryNoteOrderFacade } from '../../state/delivery-note.order/delivery-note.order.facade';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-delivery-note-orderdialogdelete',
	templateUrl: './entitydialogdelete-delivery-note-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class DeliveryNoteOrderDialogDeleteComponent extends EntityDialogDeleteComponent<IDeliveryNoteOrder, IDeliveryNoteOrderState, IDeliveryNoteOrderEditForm, IDeliveryNoteOrderFilterForm> {
	constructor(entityFacade: DeliveryNoteOrderFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
