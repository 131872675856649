import { Injectable } from '@angular/core';
import { IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AppState, IAccountingSummaryState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummarySelector extends EntitySelector<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	protected stateSelector = (state: AppState): IAccountingSummaryState => state.accountingSummary;
}
