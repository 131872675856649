import { EntityState, IMediaBrandState } from '../app.state';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';

import { EntityReducer } from '../entity.reducer';
import { IMediaBrand } from '../../models/media-brand.model';
import { fromMediaBrandActions } from './media-brand.actions';
import { required } from 'ngrx-forms/validation';
import { validate } from 'ngrx-forms';

export const initialValue: IMediaBrand = {
	_id: null,
	processInstance: null,
	caption: null,
	description: null,
	shortDescription: null,
	link: null,
	image: null,
	logo: null,
};

export const initialEditFormValue: IMediaBrandEditForm = {
	_id: null,
	processInstance: null,
	caption: null,
	description: null,
	shortDescription: null,
	link: null,
	image: null,
	logo: null,
};

export const initialFilterFormValue: IMediaBrandFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	caption: null,
	description: null,
	shortDescription: null,
	link: null,
	image: null,
	logo: null,
};

export const initialState = EntityState.create<IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm>(
	'MEDIABRAND',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			caption: 'CONTAINS',
			description: 'CONTAINS',
		},
	],
	{
		caption: 1,
	}
);

export const mediaBrandReducer = EntityReducer.create<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm>(
	initialState,
	{
		caption: validate(required),
	},
	fromMediaBrandActions
);
