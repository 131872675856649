import { box, validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IStock } from '../../models/stock.model';
import { EntityState, IStockState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { fromStockActions } from './stock.actions';

export const initialValue: IStock = {
	_id: null,
	comment: null,
	quality: null,
	quantity: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: null,
};

export const initialEditFormValue: IStockEditForm = {
	_id: null,
	comment: null,
	quality: null,
	quantity: null,
	product: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: null,
};

export const initialFilterFormValue: IStockFilterForm = {
	_id: null,
	createdAt: null,
	comment: null,
	quality: box([]),
	quantity: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	storagePlace: null,
	stockKind: null,
};

export const initialState = EntityState.create<IStock, IStockEditForm, IStockFilterForm>('STOCK', initialValue, initialEditFormValue, initialFilterFormValue);
export const stockReducer = EntityReducer.create<IStock, IStockState, IStockEditForm, IStockFilterForm>(
	initialState,
	{
		product: validate(required),
		storagePlace: validate(required),
	},
	fromStockActions
);
