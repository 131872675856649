import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleYearBook, IArticleYearBookEditForm, IArticleYearBookFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleYearBookApiService } from '../../services/api/article.yearbook.service';
import { ArticleYearArticleBookDialogService } from '../../services/dialog/article.yearbook.service';
import { ArticleYearArticleBookNotificationService } from '../../services/notification/article.yearbook.service';
import { AppState, IArticleYearBookState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleYearBookActions } from './article.yearbook.actions';
import { ArticleYearBookSelector } from './article.yearbook.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleYearBookEffects extends EntityEffects<IArticleYearBook, IArticleYearBookState, IArticleYearBookEditForm, IArticleYearBookFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleYearBookApiService,
		notificationService: ArticleYearArticleBookNotificationService,
		dialogService: ArticleYearArticleBookDialogService,
		private selector: ArticleYearBookSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleYearBookActions, 'YEARBOOK');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleYearBookActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.YearBook,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleYearBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleYearBookActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleYearBookActions.dayRangeChanged, fromArticleYearBookActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleYearBookActions.extended());
				}

				return this.apiService
					.search(
						{
							date: moment.utc(date).startOf('year').toDate(),
							dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(),
							dateUntil: moment.utc(date).add(dayRange.max, 'days').toDate(),
							articleKind: ArticleKind.YearBook,
							region,
						},
						paging
					)
					.pipe(
						map(data => fromArticleYearBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleYearBookActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleYearBookActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleYearBookActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleYearBookActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
