import { box } from 'ngrx-forms';
import { IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm } from '../../models/shipping.model';
import { EntityState, IShippingPickupState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromPickupActions } from './shipping.pickup.actions';

export const initialValue: IShippingPickup = {
	_id: null,
	order: null,
	deliveryNote: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	pickupStation: {
		type: null,
		default: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
	},
	shippingKind: null,
};

export const initialEditFormValue: IShippingPickupEditForm = {
	_id: null,
	deliveryNote: null,
	order: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	pickupStation: {
		type: null,
		default: null,
		address: {
			street: null,
			streetNumber: null,
			postCode: null,
			city: null,
			country: null,
		},
	},
	shippingKind: null,
};

export const initialFilterFormValue: IShippingPickupFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	pickupStation: {
		type: null,
		default: null,
		address: null,
	},
	order: null,
	deliveryNote: box([]),
	shippingKind: null,
};

export const initialState = EntityState.create<IShippingPickup, IShippingPickupEditForm, IShippingPickupFilterForm>('PICKUP', initialValue, initialEditFormValue, initialFilterFormValue);
export const pickupReducer = EntityReducer.create<IShippingPickup, IShippingPickupState, IShippingPickupEditForm, IShippingPickupFilterForm>(initialState, {}, fromPickupActions);
