import { IPaymentPaypalEditForm, IPaymentPaypalFilterForm } from '../../models/payment.model';
import { EntityState, IPaymentPaypalState } from '../app.state';

import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { IPaymentPaypal } from '../../models/payment.model';
import { EntityReducer } from '../entity.reducer';
import { fromPaypalActions } from './payment.paypal.actions';

export const initialValue: IPaymentPaypal = {
	_id: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paypalOrder: {
		payerId: null,
		id: null,
		link: null,
	},
	paypalTransaction: {
		id: null,
		state: null,
		payer: {
			id: null,
			email: null,
			name: null,
		},
	},
	isManuallySent: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IPaymentPaypalEditForm = {
	_id: null,
	order: null,
	paidDate: null,
	dueAmount: null,
	paypalOrder: {
		payerId: null,
		id: null,
		link: null,
	},
	paypalTransaction: {
		id: null,
		state: null,
		payer: {
			id: null,
			email: null,
			name: null,
		},
	},
	isManuallySent: null,
	paymentKind: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IPaymentPaypalFilterForm = {
	_id: null,
	createdAt: null,
	paidDate: null,
	order: null,
	dueAmount: null,
	paypalOrder: {
		payerId: null,
		id: null,
		link: null,
	},
	paypalTransaction: {
		id: null,
		state: null,
		payer: {
			id: null,
			email: null,
			name: null,
		},
	},
	isManuallySent: null,
	paymentKind: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IPaymentPaypal, IPaymentPaypalEditForm, IPaymentPaypalFilterForm>('PAYPAL', initialValue, initialEditFormValue, initialFilterFormValue, [
	{
		createdAt: 'GTE',
		dueAmount: 'EQ',
		paypalOrder: {
			id: 'CONTAINS',
		},
		paypalTransaction: {
			id: 'CONTAINS',
		},
		processInstance: {
			state: 'IN',
		},
	},
]);
export const paypalReducer = EntityReducer.create<IPaymentPaypal, IPaymentPaypalState, IPaymentPaypalEditForm, IPaymentPaypalFilterForm>(
	initialState,
	{},
	fromPaypalActions,
	on(fromPaypalActions.sendPaymentLink, state => ({
		...state,
		isFetching: true,
	})),
	on(fromPaypalActions.sentPaymentLink, state => ({
		...state,
		isFetching: false,
	}))
);
