import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAttachmentEditForm } from 'src/models/attachment.model';
import { progressFade } from '../../animations/progressfade.animation';
import { IAttachment, IAttachmentFilterForm } from '../../models/attachment.model';
import { FilterConnection, IAttachmentState, IFilterDescriptor, SortDescriptors } from '../../state/app.state';
import { AttachmentFacade } from '../../state/attachment/attachment.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-attachment-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-attachment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class AttachmentAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	public placeholder = 'Anhänge';
	public columns: string[] = ['title', 'delete'];
	@Input() public allowSelect: boolean = true;

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'title', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IAttachment> = () => ({ title: 1 });

	constructor(entityFacade: AttachmentFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IAttachment): string {
		return entity?.title;
	}
}
