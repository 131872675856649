import { Injectable } from '@angular/core';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { AppState, IPaymentCollectionPurchaseState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseSelector extends EntitySelector<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	protected stateSelector = (state: AppState): IPaymentCollectionPurchaseState => state.paymentCollectionPurchase;
}
