import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from '../../models/delivery-note';
import { AddressKind } from '../../models/enums/address-kind.enum';
import { SalutationKind } from '../../models/enums/salutation-kind.enum';
import { IDeliveryNoteState } from '../../state/app.state';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-delivery-notedialogchange',
	templateUrl: './entitydialogchange-delivery-note.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DeliveryNoteDialogChangeComponent extends EntityDialogChangeComponent<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	public SalutationKinds = SalutationKind;
	public AddressKinds = AddressKind;

	constructor(entityFacade: DeliveryNoteFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
