import { createReducer, on } from '@ngrx/store';
import { IAggregationState } from '../app.state';
import { fromAggregationActions } from './aggregation.actions';
import { thisWeek } from './aggregation.facade';

const initialState: IAggregationState = {
	orderCount: {},
	orderState: {},
	receiptTotalPrice: {},
	receiptState: {},
	from: thisWeek.from,
	until: thisWeek.until,
	granularity: 'Täglich',
	error: null,
	isLoading: false,
};

export const aggregationReducer = createReducer(
	initialState,
	on(fromAggregationActions.loadOrderCount, state => ({
		...state,
		isLoading: true,
		orderCount: {},
		error: null,
	})),
	on(fromAggregationActions.loadedOrderCount, (state, { data }) => ({
		...state,
		isLoading: false,
		orderCount: data,
		error: null,
	})),
	on(fromAggregationActions.loadOrderState, (state, { displayName }) => ({
		...state,
		isLoading: true,
		orderState: { ...state.orderState, [displayName]: {} },
		error: null,
	})),
	on(fromAggregationActions.loadedOrderState, (state, { data, displayName }) => ({
		...state,
		isLoading: false,
		orderState: { ...state.orderState, [displayName]: data },
		error: null,
	})),
	on(fromAggregationActions.loadReceiptTotalPrice, state => ({
		...state,
		isLoading: true,
		reciptTotalPrice: {},
		error: null,
	})),
	on(fromAggregationActions.loadedReceiptTotalPrice, (state, { data }) => ({
		...state,
		isLoading: false,
		receiptTotalPrice: data,
		error: null,
	})),
	on(fromAggregationActions.loadReceiptState, (state, { displayName }) => ({
		...state,
		isLoading: true,
		receiptState: { ...state.receiptState, [displayName]: {} },
		error: null,
	})),
	on(fromAggregationActions.loadedReceiptState, (state, { data, displayName }) => ({
		...state,
		isLoading: false,
		receiptState: { ...state.receiptState, [displayName]: data },
		error: null,
	})),
	on(fromAggregationActions.changeTimespan, (state, { from, until }) => ({
		...state,
		from,
		until,
		orderState: {},
		receiptState: {},
	})),
	on(fromAggregationActions.changeFrom, (state, { from }) => ({
		...state,
		from,
		orderState: {},
		receiptState: {},
	})),
	on(fromAggregationActions.changeUntil, (state, { until }) => ({
		...state,
		until,
		orderState: {},
		receiptState: {},
	})),
	on(fromAggregationActions.changeGranularity, (state, { granularity }) => ({
		...state,
		granularity,
		orderState: {},
		receiptState: {},
	})),
	on(fromAggregationActions.reset, state => ({
		...state,
		orderCount: {},
		receiptTotalPrice: {},
		orderState: {},
		receiptState: {},
		isLoading: false,
		error: null,
	})),
	on(fromAggregationActions.failed, (state, { error }) => ({
		...state,
		isLoading: false,
		error,
	}))
);
