import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MailTemplateNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-mailtemplate.component';
import { IMailTemplate } from 'src/models/mailtemplate.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class MailTemplateNotificationService extends EntityNotificationService<IMailTemplate> {
	protected component: ComponentType<any> = MailTemplateNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
