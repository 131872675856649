import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromPredictionActions } from './prediction.actions';
import { PredictionSelector } from './prediction.selectors';

@Injectable({
	providedIn: 'root',
})
export class PredictionFacade extends BaseFacade {
	constructor(store: Store<AppState>, private predictionSelector: PredictionSelector) {
		super(store);
	}

	public receiptTotalPrice$ = this.store.select(this.predictionSelector.receiptTotalPrice);
	public receiptTotalPriceOfToday$ = this.store.select(this.predictionSelector.receiptTotalPriceOfToday);

	public loadReceiptTotalPrice(): void {
		this.store.dispatch(fromPredictionActions.loadReceiptTotalPrice());
	}
}
