import { EntityState, IUploadedFileState } from '../app.state';
import { IUploadedFileEditForm, IUploadedFileFilterForm } from './../../models/uploaded-file.model';

import { EntityReducer } from '../entity.reducer';
import { IUploadedFile } from '../../models/uploaded-file.model';
import { fromImageActions } from '../image/image.actions';
import { fromUploadedFileActions } from './uploadedfile.actions';
import { on } from '@ngrx/store';

export const initialValue: IUploadedFile = {
	_id: null,
	processInstance: null,
	name: null,
	mimeType: null,
	url: null,
};

export const initialEditFormValue: IUploadedFileEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	mimeType: null,
	url: null,
};

export const initialFilterFormValue: IUploadedFileFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	mimeType: null,
	url: null,
};

export const initialState = EntityState.create<IUploadedFile, IUploadedFileEditForm, IUploadedFileFilterForm>('UPLOADEDFILE', initialValue, initialEditFormValue, initialFilterFormValue, [{}], {
	name: 1,
});

export const uploadedfileReducer = EntityReducer.create<IUploadedFile, IUploadedFileState, IUploadedFileEditForm, IUploadedFileFilterForm>(
	initialState,
	{},
	fromUploadedFileActions,
	on(fromImageActions.updatedImageForm, (state, { entity }) => {
		const items = { ...state.items };
		delete items[entity._id];

		return {
			...state,
			itemsFiltered: state.itemsFiltered.filter(x => x != entity._id),
			items,
		};
	})
);
