import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DirectDebitTransferNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-direct-debit-transfer.component';
import { IDirectDebitTransfer } from '../../models/direct-debit-transfer.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DirectDebitTransferNotificationService extends EntityNotificationService<IDirectDebitTransfer> {
	protected component: ComponentType<any> = DirectDebitTransferNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
