import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleStick } from '../../models/article.model';
import { IArticleStickState } from '../../state/app.state';
import { ArticleStickFacade } from '../../state/article.stick/article.stick.facade';
import { IArticleStickEditForm, IArticleStickFilterForm } from './../../models/article.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-stickdialogdelete',
	templateUrl: './entitydialogdelete-stick.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class StickDialogDeleteComponent extends EntityDialogDeleteComponent<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	constructor(entityFacade: ArticleStickFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
