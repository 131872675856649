import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgrxValueConverters } from 'ngrx-forms';
import { RegionKind } from '../../models/enums/region-kind.model';
import { IRegionState } from '../../state/app.state';
import { RegionFacade } from '../../state/region/region.facade';
import { IRegion, IRegionEditForm, IRegionFilterForm } from './../../models/region.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-regiondialogadd',
	templateUrl: './entitydialogadd-region.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class RegionDialogAddComponent extends EntityDialogAddComponent<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	public RegionKinds = RegionKind;

	constructor(entityFacade: RegionFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}

	public dateToISOString = NgrxValueConverters.dateToISOString;
}
