import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingDhlPremium } from '../../models/shipping.model';
import { IShippingDhlPremiumState } from '../../state/app.state';
import { DhlPremiumFacade } from '../../state/shipping.dhl-premium/shipping.dhl-premium.facade';
import { IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from './../../models/shipping.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-dhl-premiumdialogchange',
	templateUrl: './entitydialogchange-dhl-premium.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class DhlPremiumDialogChangeComponent extends EntityDialogChangeComponent<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	constructor(entityFacade: DhlPremiumFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
