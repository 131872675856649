import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, first } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleBookState } from 'src/state/app.state';
import { IArticleBook, IArticleBookEditForm, IBookFilterForm, IExpandedArticle, IExpandedArticleBook } from '../../models/article.model';
import { IRegion } from '../../models/region.model';
import { ArticleBookDialogService } from '../../services/dialog/article.book.service';
import { IReplaceArticleDialogData } from '../../services/dialog/article.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleBookFacade } from '../../state/article.book/article.book.facade';
import { CartFacade } from '../../state/cart/cart.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { RegionFacade } from '../../state/region/region.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-bookreplacedialog',
	templateUrl: './entitydialog-book-replace.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class BookReplaceDialogComponent extends EntityDialogComponent<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	public formGroup: FormGroup;
	public columns = ['publicationDate', 'product', 'region', 'storagePlace', 'replace'];

	public displayRegionWith = (region: IRegion) => region.name;

	constructor(
		public bookFacade: ArticleBookFacade,
		formBuilder: FormBuilder,
		matDialogRef: MatDialogRef<BookReplaceDialogComponent, IEntityDialogData<IArticleBook>>,
		@Inject(MAT_DIALOG_DATA) data: IReplaceArticleDialogData<IExpandedArticleBook>,
		public storagePlaceFacade: StoragePlaceFacade,
		public mediaProductFacade: MediaProductFacade,
		public orderFacade: OrderFacade,
		public regionFacade: RegionFacade,
		public ArticleBookDialogService: ArticleBookDialogService,
		public cartFacade: CartFacade
	) {
		super(bookFacade, matDialogRef);

		this.bookFacade.search(new Date(data.entity.publicationDate));
		this.formGroup = formBuilder.group({
			publicationDate: [data.entity.publicationDate, [Validators.required]],
		});

		this.position = data.position;
		this.originalArticle = data.entity;
	}

	public search(): void {
		this.bookFacade.search(this.formGroup.value.publicationDate);
	}

	public replaceBook(article: IExpandedArticle): void {
		const updatedPosition = this.replaceArticlePosition(article);
		this.cartFacade.validatePositions(updatedPosition);
		this.matDialogRef.close();
	}

	public onBlurRegion(value: string): void {
		this.regionFacade.selected$
			.pipe(
				first(),
				filter(region => region == null && value != null && value.length > 0)
			)
			.subscribe(() => {
				this.regionFacade.changeName('');
			});
	}
}
