import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleBirthdayBook, IArticleBirthdayBookEditForm, IExpandedArticleBirthdayBook } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleBirthdayBookActions = {
	...EntityActions.create<IArticleBirthdayBook, IArticleBirthdayBookEditForm>('[BirthdayBook]'),
	search: createAction(`[BirthdayBook] Search`, props<{ date: Date }>()),
	searched: createAction(`[BirthdayBook] Searched`, props<{ date: Date; entities: IExpandedArticleBirthdayBook[] }>()),
	extended: createAction(`[BirthdayBook] Extended`),
	pagingChanged: createAction(`[BirthdayBook] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[BirthdayBook] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
