import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { PredictionService } from '../../services/api/prediction.service';
import { fromAggregationActions } from '../aggregation/aggregation.actions';
import { AggregationSelector } from '../aggregation/aggregation.selectors';
import { AppState } from '../app.state';
import { BaseEffects } from '../base.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPredictionActions } from './prediction.actions';

@Injectable({
	providedIn: 'root',
})
export class PredictionEffects extends BaseEffects {
	constructor(private actions$: Actions, private store: Store<AppState>, private sessionSelector: SessionSelector, private aggregationSelector: AggregationSelector, private predictionService: PredictionService) {
		super();
	}

	public onLoadReceiptTotalPriceAggregation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadReceiptTotalPrice),
			map(() => fromPredictionActions.reset())
		)
	);

	public onLoadedReceiptTotalPriceAggregation$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromAggregationActions.loadedReceiptTotalPrice),
			map(() => fromPredictionActions.loadReceiptTotalPrice())
		)
	);

	public onLoadReceiptTotalPrice$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromPredictionActions.loadReceiptTotalPrice),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.receiptTotalPrice)),
			concatLatestFrom(() => this.store.select(this.aggregationSelector.granularity)),
			switchMap(([[[, authToken], aggregation], granularity]) =>
				this.predictionService.predictReceiptTotalPrice(authToken, aggregation, granularity).pipe(
					map(result => fromPredictionActions.loadedReceiptTotalPrice({ data: result.data })),
					catchError((response: IServiceErrorResponse) => of(fromPredictionActions.failed({ error: response.error })))
				)
			)
		)
	);
}
