import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BundleNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-bundle.component';
import { IBundle } from '../../models/bundle.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class BundleNotificationService extends EntityNotificationService<IBundle> {
	protected component: ComponentType<any> = BundleNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
