import { AccountingSummaryAdvancementNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-accounting-summary-advancement.component';
import { ComponentType } from '@angular/cdk/portal';
import { EntityNotificationService } from './entity.service';
import { IAccountingSummaryAdvancement } from '../../models/accounting-summary.model';
import { Injectable } from '@angular/core';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryAdvancementNotificationService extends EntityNotificationService<IAccountingSummaryAdvancement> {
	protected component: ComponentType<any> = AccountingSummaryAdvancementNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
