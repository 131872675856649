import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IDeliveryNoteState } from 'src/state/app.state';
import { IDeliveryNote, IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from '../../models/delivery-note';
import { DeliveryNoteKind } from '../../models/enums/delivery-note-kind.enum';
import { DeliveryNoteDialogService } from '../../services/dialog/delivery-note.service';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-deliveryNotepage',
	templateUrl: './entitypage-delivery-note.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class DeliveryNotePageComponent extends EntityPageComponent<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	public pageTitle$ = new BehaviorSubject('Lieferscheine');
	public columns = ['number', 'receiver', 'date', 'filter'];
	public deliveryNoteKinds = DeliveryNoteKind;

	constructor(
		titleService: Title,
		entityDialogService: DeliveryNoteDialogService,
		route: ActivatedRoute,
		public entityFacade: DeliveryNoteFacade,
		public processFacade: ProcessFacade,
		public processNodeFacade: ProcessNodeFacade,
		public orderFacade: OrderFacade,
		public deliveryNoteDialogService: DeliveryNoteDialogService,
		public routerFacade: RouterFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
