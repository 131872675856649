import { Injectable } from '@angular/core';
import { IPaymentPrepayment, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from '../../models/payment.model';
import { AppState, IPaymentPrepaymentState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class PrepaymentSelector extends EntitySelector<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	protected stateSelector = (state: AppState): IPaymentPrepaymentState => state.paymentPrepayment;
}
