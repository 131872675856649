import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { RegionNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-region.component';
import { IRegion } from './../../models/region.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class RegionNotificationService extends EntityNotificationService<IRegion> {
	protected component: ComponentType<any> = RegionNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
