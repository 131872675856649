import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CorrectionDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-correction.component';
import { CorrectionDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-correction.component';
import { CorrectionDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-correction.component';
import { IReceiptCorrection, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm } from '../../models/receipt';
import { IReceiptCorrectionState } from '../../state/app.state';
import { CorrectionFacade } from '../../state/receipt.correction/receipt.correction.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ReceiptCorrectionDialogService extends EntityDialogService<IReceiptCorrection, IReceiptCorrectionState, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {
	protected createComponent: ComponentType<any> = CorrectionDialogAddComponent;
	protected updateComponent: ComponentType<any> = CorrectionDialogChangeComponent;
	protected removeComponent: ComponentType<any> = CorrectionDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: CorrectionFacade) {
		super(matDialog, entityFacade);
	}
}
