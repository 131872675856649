import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleNewspaper } from '../../models/article.model';
import { IArticleNewspaperState } from '../../state/app.state';
import { ArticleNewspaperFacade } from '../../state/article.newspaper/article.newspaper.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-newspaperdialogchange',
	templateUrl: './entitydialogchange-newspaper.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class NewspaperDialogChangeComponent extends EntityDialogChangeComponent<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	constructor(entityFacade: ArticleNewspaperFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
