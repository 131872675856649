import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleState } from 'src/state/app.state';
import { ArticleKind, IArticle, IArticleEditForm, IArticleFilterForm } from '../../models/article.model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { IEntityDialogData } from '../../services/dialog/dialog.service';
import { ArticleCertificateFacade } from '../../state/article.certificate/article.certificate.facade';
import { ArticleFacade } from '../../state/article/article.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { EntityDialogComponent } from './entitydialog.component';

@Component({
	selector: 'babylon-articlereproducedialog',
	templateUrl: './entitydialog-article-reproduce.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialog.component.scss'],
	animations: [progressFade],
})
export class ArticleReproduceDialogComponent extends EntityDialogComponent<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	public comment: string = '';
	public ArticleKinds = ArticleKind;

	constructor(
		public articleFacade: ArticleFacade,
		public productFacade: ProductFacade,
		public certificateFacade: ArticleCertificateFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public articleDialogService: ArticleDialogService,
		matDialogRef: MatDialogRef<ArticleReproduceDialogComponent, IEntityDialogData<IArticle>>
	) {
		super(articleFacade, matDialogRef);
	}

	public openUpdateDialog(entity: IArticle): void {
		this.articleDialogService.openUpdateDialog({ entity });
	}
}
