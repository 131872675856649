import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IProcessRoute } from '../../models/process-route.model';
import { IServiceError } from '../../services/api/api.service';
import { EntityNotificationErrorComponent } from './entitynotificationerror.component';

@Component({
	selector: 'babylon-processroutenotificationerror',
	templateUrl: './entitynotificationerror-process-route.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitynotificationerror.component.scss'],
})
export class ProcessRouteNotificationErrorComponent extends EntityNotificationErrorComponent<IProcessRoute> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
