<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lagerplatz hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline">
					<mat-label>Lagerplatz</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.identifier" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Raum</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.room" />
				</mat-form-field>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Kommentar</mat-label>
					<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.comment"></textarea>
				</mat-form-field>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
