import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IImage } from 'src/models/image.model';
import { ImageDialogService } from 'src/services/dialog/image.service';
import { IImageState } from 'src/state/app.state';
import { ImageFacade } from 'src/state/image/image.facade';
import { IImageEditForm, IImageFilterForm } from './../../models/image.model';
import { UploadedFileFacade } from './../../state/uploadedfile/uploadedfile.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-imagepage',
	templateUrl: './entitypage-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class ImagePageComponent extends EntityPageComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	public pageTitle$ = new BehaviorSubject('Bilder');
	public columns = ['image', 'title', 'altText', 'file', 'filter'];

	constructor(titleService: Title, imageDialogService: ImageDialogService, route: ActivatedRoute, entityFacade: ImageFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(titleService, imageDialogService, route, entityFacade);
	}
}
