import { map, Observable } from 'rxjs';
import { IAccessoryArticleRequest, IPaging } from '../../models/article-request';
import { IArticle, IArticleCertificate, IExpandedArticleCertificate } from '../../models/article.model';
import { EntityApiService, IEntityListResponse } from './entity.service';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICertificateRating } from '../../models/article-rating';

@Injectable({
	providedIn: 'root',
})
export class ArticleCertificateApiService extends EntityApiService<IArticleCertificate> {
	protected entityName = 'certificates';

	constructor(http: HttpClient) {
		super(http);
	}

	public print(authToken: string, certificate: IArticle): Observable<string> {
		let headers = this.getHeaders(authToken);

		return this.post<any, unknown>([this.entityName, certificate._id, 'print-pdf'], null, headers);
	}

	public download(authToken: string, certificate: IArticle): Observable<string> {
		let headers = this.getHeaders(authToken);
		headers = headers.set('Accept', 'application/pdf');

		return this.post<any, any>([this.entityName, certificate._id, 'generate-pdf'], null, headers, 'blob');
	}

	public search(request: IAccessoryArticleRequest, paging?: IPaging): Observable<IExpandedArticleCertificate[]> {
		const route = [this.entityName, 'search'];

		if (paging != null) {
			route.push(`?skip=${paging.skip}&top=${paging.top}`);
		}

		return this.post<IEntityListResponse<ICertificateRating>, IAccessoryArticleRequest>(route, request).pipe(map(ratings => ratings.data.map(rating => rating.article)));
	}
}
