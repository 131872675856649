<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="orderFacade.isUpdating$ | async"></mat-progress-bar>
<ng-container *ngIf="orderFacade.selected$ | async as entity">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Positionen auswählen</h1>
	<div mat-dialog-content>
		<div class="content-wrapper">
			<table mat-table [dataSource]="orderFacade.positions$ | async" multiTemplateDataRows class="detail-table">
				<ng-container matColumnDef="select">
					<th mat-header-cell *matHeaderCellDef>
						<div>
							<mat-checkbox (change)="masterSelection$.next($event.checked)" [checked]="selection.hasValue() && isAllSelected$ | async" [indeterminate]="selection.hasValue() && (isAllSelected$ | async) === false">
							</mat-checkbox>
						</div>
					</th>
					<td mat-cell *matCellDef="let position" class="detail-header">
						<mat-checkbox (change)="$event ? selection.toggle(position) : null" [checked]="selection.isSelected(position)"> </mat-checkbox>
					</td>
				</ng-container>

				<ng-container matColumnDef="title">
					<th mat-header-cell *matHeaderCellDef>Position/Artikel</th>
					<td mat-cell *matCellDef="let position" class="detail-header">
						{{ position.title }}
					</td>
				</ng-container>

				<ng-container matColumnDef="price">
					<th mat-header-cell *matHeaderCellDef>Preis</th>
					<td mat-cell *matCellDef="let position" class="detail-header" width="100">
						{{ position.price | currency }}
					</td>
				</ng-container>

				<ng-container matColumnDef="articles">
					<td [attr.colspan]="positionColumns.length" mat-cell *matCellDef="let position" class="detail-cell">
						<table mat-table [dataSource]="orderFacade.getArticlesFromEditPosition(position) | async">
							<ng-container matColumnDef="select">
								<th mat-header-cell *matHeaderCellDef></th>
								<td mat-cell *matCellDef="let position"></td>
							</ng-container>
							<ng-container matColumnDef="title">
								<td mat-cell *matCellDef="let article">
									{{ article.product.name }} <ng-container *ngIf="article.publicationDate">vom {{ article.publicationDate | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
								</td>
							</ng-container>

							<ng-container matColumnDef="price">
								<td mat-cell *matCellDef="let article" width="100">
									{{ article?.product?.price | currency }}
								</td>
							</ng-container>

							<tr mat-row *matRowDef="let row; columns: positionColumns"></tr>
						</table>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="positionColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: positionColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: ['articles']" class="detail-row" [hidden]="row.positionKind != PositionKinds.Article && row.positionKind != PositionKinds.Bundle"></tr>
			</table>
		</div>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="showCreateRetoureDialog()" [disabled]="orderFacade.isUpdating$ | async">Retoure erstellen</button>
	</mat-dialog-actions>
</ng-container>
