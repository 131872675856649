<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Rechnungskorrektur ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm" *ngIf="entityFacade.selected$ | async as receipt">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline" class="readonly">
							<mat-label>Belegnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.number" readonly />
						</mat-form-field>
						<mat-form-field appearance="outline" class="readonly">
							<mat-label>Belegdatum</mat-label>
							<input matInput [matDatepicker]="date" [ngrxFormControlState]="editForm.controls.date" [ngrxValueConverter]="dateToISOStringConverter" />
							<mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
							<mat-datepicker #date></mat-datepicker>
						</mat-form-field>
						<mat-form-field appearance="outline" class="readonly">
							<mat-label>Zahlungsziel (Original)</mat-label>
							<input matInput [matDatepicker]="dueDate" [ngrxFormControlState]="editForm.controls.dueDate" [ngrxValueConverter]="dateToISOStringConverter" />
							<mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
							<mat-datepicker #dueDate></mat-datepicker>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Zahlungsziel (Manuell)</mat-label>
							<input matInput [matDatepicker]="dueDateDelayed" [ngrxFormControlState]="editForm.controls.dueDateDelayed" [ngrxValueConverter]="dateToISOStringConverter" />
							<mat-datepicker-toggle matSuffix [for]="dueDateDelayed"></mat-datepicker-toggle>
							<mat-datepicker #dueDateDelayed></mat-datepicker>
						</mat-form-field>
					</div>
				</mat-tab>

				<mat-tab label="Historie">
					<babylon-correctionhistorytable controlId="processInstance.actions"></babylon-correctionhistorytable>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<babylon-process-buttons [facade]="entityFacade"></babylon-process-buttons>
	</mat-dialog-actions>
</ng-container>
