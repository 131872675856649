import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { StockTypeEnum } from '../../models/enums/stock-type.enum';
import { IStockAccessory } from '../../models/stock.model';
import { IStockAccessoryState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { IStockAccessoryEditForm, IStockAccessoryFilterForm } from './../../models/stock.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-accessorystockdialogchange',
	templateUrl: './entitydialogchange-accessory-stock.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class AccessoryStockDialogChangeComponent extends EntityDialogChangeComponent<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	public StockTypes = StockTypeEnum;

	constructor(entityFacade: AccessoryStockFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialogRef);
	}
}
