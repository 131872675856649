import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { IPaymentPrepayment } from '../../models/payment.model';
import { IServiceError } from '../../services/api/api.service';
import { EntityNotificationErrorComponent } from './entitynotificationerror.component';

@Component({
	selector: 'babylon-prepaymentnotificationerror',
	templateUrl: './entitynotificationerror-prepayment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitynotificationerror.component.scss'],
})
export class PrepaymentNotificationErrorComponent extends EntityNotificationErrorComponent<IPaymentPrepayment> {
	constructor(@Inject(MAT_SNACK_BAR_DATA) matSnackBarData: IServiceError, matSnackBarRef: MatSnackBarRef<any>) {
		super(matSnackBarData, matSnackBarRef);
	}
}
