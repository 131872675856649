import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingDhl } from '../../models/shipping.model';
import { IShippingDhlState } from '../../state/app.state';
import { DhlFacade } from '../../state/shipping.dhl/shipping.dhl.facade';
import { IShippingDhlEditForm, IShippingDhlFilterForm } from './../../models/shipping.model';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-dhldialogdelete',
	templateUrl: './entitydialogdelete-dhl.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class DhlDialogDeleteComponent extends EntityDialogDeleteComponent<IShippingDhl, IShippingDhlState, IShippingDhlEditForm, IShippingDhlFilterForm> {
	constructor(entityFacade: DhlFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
