import { box } from 'ngrx-forms';
import { IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from '../../models/shipping.model';
import { EntityState, IShippingDhlPremiumState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromDhlPremiumActions } from './shipping.dhl-premium.actions';

export const initialValue: IShippingDhlPremium = {
	_id: null,
	order: null,
	shipmentNumber: null,
	labelUrl: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	dhlOrder: {
		shipmentNumber: null,
		labelUrl: null,
	},
};

export const initialEditFormValue: IShippingDhlPremiumEditForm = {
	_id: null,
	order: null,
	shipmentNumber: null,
	labelUrl: null,
	deliveryNote: null,
	shippingKind: null,
	shippingByPartner: null,
	shippingWithoutInvoice: null,
	consentEmailTransfer: null,
	enableSaturdayDelivery: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	dhlOrder: {
		shipmentNumber: null,
		labelUrl: null,
	},
};

export const initialFilterFormValue: IShippingDhlPremiumFilterForm = {
	_id: null,
	createdAt: null,
	order: null,
	shipmentNumber: null,
	labelUrl: null,
	deliveryNote: box([]),
	shippingKind: null,
	processInstance: null,
};

export const initialState = EntityState.create<IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm>('DHLPREMIUM', initialValue, initialEditFormValue, initialFilterFormValue);
export const dhlPremiumReducer = EntityReducer.create<IShippingDhlPremium, IShippingDhlPremiumState, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm>(initialState, {}, fromDhlPremiumActions);
