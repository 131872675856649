import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPermissionState } from '../../state/app.state';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { IPermission, IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-permissiondialogadd',
	templateUrl: './entitydialogadd-permission.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PermissionDialogAddComponent extends EntityDialogAddComponent<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	constructor(entityFacade: PermissionFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
