import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAction } from '../../models/process-instance-model';
import { IShippingDhlPremium, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm } from '../../models/shipping.model';
import { IShippingDhlPremiumState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { DhlPremiumFacade } from '../../state/shipping.dhl-premium/shipping.dhl-premium.facade';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-dhl-premiumhistorytable',
	templateUrl: './entitydialogtable-dhl-premiumhistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class DhlPremiumHistoryTableComponent extends HistoryDialogTableComponent<IShippingDhlPremium, IShippingDhlPremiumState, IAction, IAction, IShippingDhlPremiumEditForm, IShippingDhlPremiumFilterForm> {
	public columns = ['date', 'userName', 'route', 'state', 'comment'];

	constructor(entityFacade: DhlPremiumFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IShippingDhlPremium): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
