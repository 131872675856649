import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleCd } from '../../models/article.model';
import { IArticleCdState } from '../../state/app.state';
import { ArticleCdFacade } from '../../state/article.cd/article.cd.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { IArticleCdEditForm, IArticleCdFilterForm } from './../../models/article.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-cddialogchange',
	templateUrl: './entitydialogchange-cd.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CdDialogChangeComponent extends EntityDialogChangeComponent<IArticleCd, IArticleCdState, IArticleCdEditForm, IArticleCdFilterForm> {
	constructor(entityFacade: ArticleCdFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
