import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IStock } from '../../models/stock.model';
import { AppState, IStockState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { fromStockActions } from './stock.actions';
import { initialEditFormValue } from './stock.reducer';
import { StockSelector } from './stock.selectors';

@Injectable({
	providedIn: 'root',
})
export class StockFacade extends EntityFacade<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromStockActions;

	constructor(store: Store<AppState>, stockSelector: StockSelector) {
		super(store, stockSelector);
	}
}
