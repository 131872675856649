import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleBirthdayBook, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleBirthdayArticleBookApiService } from '../../services/api/article.birthday-book.service';
import { ArticleBirthdayBookDialogService } from '../../services/dialog/article.birthday-book.service';
import { ArticleBirthdayArticleBookNotificationService } from '../../services/notification/article.birthday-book.service';
import { AppState, IArticleBirthdayBookState } from '../app.state';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleBirthdayBookActions } from './article.birthday-book.actions';
import { ArticleBirthdayBookSelector } from './article.birthday-book.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleBirthdayBookEffects extends EntityEffects<IArticleBirthdayBook, IArticleBirthdayBookState, IArticleBirthdayBookEditForm, IArticleBirthdayBookFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private apiService: ArticleBirthdayArticleBookApiService,
		notificationService: ArticleBirthdayArticleBookNotificationService,
		dialogService: ArticleBirthdayBookDialogService,
		private selector: ArticleBirthdayBookSelector,
		sessionSelector: SessionSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleBirthdayBookActions, 'BIRTHDAYBOOK');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayBookActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.BirthdayBook, region }, paging)
					.pipe(
						map(data => fromArticleBirthdayBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBirthdayBookActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayBookActions.dayRangeChanged, fromArticleBirthdayBookActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleBirthdayBookActions.extended());
				}

				return this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.min, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.max, 'days').startOf('day').toDate(), articleKind: ArticleKind.BirthdayBook, region }, paging)
					.pipe(
						map(data => fromArticleBirthdayBookActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleBirthdayBookActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayBookActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleBirthdayBookActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleBirthdayBookActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
