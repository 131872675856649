import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IMediaBrandState } from 'src/state/app.state';
import { MediaBrandDialogService } from '../../services/dialog/media-brand.service';
import { ImageFacade } from '../../state/image/image.facade';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IMediaBrand, IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-media-brandpage',
	templateUrl: './entitypage-media-brand.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class MediaBrandPageComponent extends EntityPageComponent<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	public pageTitle$ = new BehaviorSubject('Marken');
	public columns = ['caption', 'description', 'image', 'logo', 'filter'];

	constructor(titleService: Title, entityDialogService: MediaBrandDialogService, route: ActivatedRoute, entityFacade: MediaBrandFacade, public imageFacade: ImageFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(titleService, entityDialogService, route, entityFacade);
	}
}
