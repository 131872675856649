import { createAction, props } from '@ngrx/store';
import { ArticleKind, IArticle } from '../../models/article.model';
import { EntityActions } from '../entity.actions';
import { IArticleEditForm } from './../../models/article.model';

export const fromArticleActions = {
	...EntityActions.create<IArticle, IArticleEditForm>('[Article]'),
	updateStoragePlace: createAction(`[Article] Update Storageplace`, props<{ articleKind: ArticleKind; entity: IArticle; storagePlaceId: string }>()),
	updateStoragePlaces: createAction(`[Article] Update Storageplaces`, props<{ articleKind: ArticleKind; storagePlaceId: string }>()),
	deleteByFilter: createAction(`[Article] Delete By Filter`, props<{ articleKind: ArticleKind }>()),
	updatedStoragePlaces: createAction(`[Article] Updated Storageplaces`, props<{ articleKind: ArticleKind; storagePlaceId: string }>()),
};
