import { Injectable } from '@angular/core';
import { IDocument } from 'src/models/document.model';
import { IDocumentEditForm, IDocumentFilterForm } from '../../models/document.model';
import { AppState, IDocumentState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class DocumentSelector extends EntitySelector<IDocument, IDocumentState, IDocumentEditForm, IDocumentFilterForm> {
	protected stateSelector = (state: AppState): IDocumentState => state.document;
}
