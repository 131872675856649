<mat-progress-bar class="dialog-progress" [@progressFade] mode="indeterminate" *ngIf="receiptFacade.isUpdating$ | async"></mat-progress-bar>
<ng-container *ngIf="receiptFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Belegempfänger bearbeiten</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm" *ngIf="receiptFacade.selected$ | async as receipt">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Anrede</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.receiver.controls.salutationKind">
						<mat-option *ngFor="let salutationKind of SalutationKinds | keyvalue" [value]="salutationKind.value">{{ salutationKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Vorname</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.firstName" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Nachname</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.lastName" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Anredeform</mat-label>
					<mat-select matInput [ngrxFormControlState]="editForm.controls.receiver.controls.addressKind">
						<mat-option *ngFor="let addressKind of AddressKinds | keyvalue" [value]="addressKind.value">{{ addressKind.value }}</mat-option>
					</mat-select>
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Firma</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.company" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Straße</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.street" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Hausnummer</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.streetNumber" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>PLZ</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.postCode" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Stadt</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.city" />
				</mat-form-field>
				<mat-form-field appearance="outline">
					<mat-label>Land</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.country" />
				</mat-form-field>
			</div>
		</form>
	</div>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="receiptFacade.updateReceiver()" [disabled]="receiptFacade.isUpdating$ | async">Speichern</button>
	</mat-dialog-actions>
</ng-container>
