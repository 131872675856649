import { Directive } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgrxValueConverters } from 'ngrx-forms';
import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { IEntityState } from 'src/state/app.state';
import { EntityFacade } from 'src/state/entity.facade';
import { CustomNgrxValueConverters } from '../../converter/ngrx-custom-converter';
import { IExpandedArticle } from '../../models/article.model';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IArticlePosition, IBundlePosition, IPosition } from '../../models/position.model';
import { IEntityDialogData } from '../../services/dialog/dialog.service';

@Directive()
export abstract class EntityDialogComponent<TEntity extends IEntity, TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>, TEntityEditForm extends IEntityEditForm, TEntityFilterForm extends IEntityFilterForm> {
	public objectToJSONConverter = NgrxValueConverters.objectToJSON;
	public dateToISOStringConverter = NgrxValueConverters.dateToISOString;
	public percentageConverter = CustomNgrxValueConverters.percentage;
	public priceConverter = CustomNgrxValueConverters.price;
	public CKEditor = ClassicEditor;

	protected position: IPosition;
	protected originalArticle: IExpandedArticle;

	constructor(public entityFacade: EntityFacade<TEntity, TEntityState, TEntityEditForm, TEntityFilterForm>, public matDialogRef: MatDialogRef<any, IEntityDialogData<TEntity>>) {}

	public addArticlePosition(article: IExpandedArticle): IPosition {
		const articlePosition: IArticlePosition = { ...this.position, article: article };
		return articlePosition;
	}

	protected replaceArticlePosition(article: IExpandedArticle): IPosition {
		if (this.position.positionKind == PositionKind.Article) {
			const articlePosition: IArticlePosition = { ...this.position, article: article };
			return articlePosition;
		} else if (this.position.positionKind == PositionKind.Bundle) {
			const articles = [...(this.position as IBundlePosition).articles];
			const articleIndex = articles.findIndex(a => a._id == this.originalArticle._id);

			articles.splice(articleIndex, 1, article);

			const bundlePosition: IBundlePosition = { ...this.position, articles };
			return bundlePosition;
		}

		return null;
	}
}
