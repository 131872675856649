import { on } from '@ngrx/store';
import { v4 } from 'uuid';
import { createRehydrateReducer } from '../../types/rehydration.reducer';
import { IDeviceState } from '../app.state';
import { fromDeviceActions } from './device.actions';

const initialState: IDeviceState = {
	forMachine: [],
	forUser: null,
	userRegistered: false,
	isLoading: false,
	machineId: null,
	loaded: false,
	error: null,
};

export const deviceReducer = createRehydrateReducer(
	'device',
	initialState,
	on(fromDeviceActions.loadDevicesForMachine, state => ({
		...state,
		isLoading: true,
	})),
	on(fromDeviceActions.loadedDevicesForMachine, (state, { userDevices }) => ({
		...state,
		forMachine: userDevices,
		isLoading: false,
	})),
	on(fromDeviceActions.loadDeviceForUser, state => ({
		...state,
		isLoading: true,
	})),
	on(fromDeviceActions.loadedDeviceForUser, (state, { userDevice }) => ({
		...state,
		forUser: userDevice,
		isLoading: false,
	})),
	on(fromDeviceActions.register, state => ({
		...state,
		isLoading: true,
		result: null,
		userRegistered: false,
	})),
	on(fromDeviceActions.registered, state => ({
		...state,
		isLoading: false,
		userRegistered: true,
	})),
	on(fromDeviceActions.authenticate, state => ({
		...state,
		isLoading: true,
		result: null,
	})),
	on(fromDeviceActions.authenticated, state => ({
		...state,
		isLoading: false,
	})),
	on(fromDeviceActions.reset, state => ({
		...state,
		result: null,
		isLoading: false,
		forUser: null,
	})),
	on(fromDeviceActions.machineIdCalculated, (state, { machineId }) => ({
		...state,
		machineId,
	})),
	on(fromDeviceActions.failed, (state, { error }) => ({
		...state,
		isLoading: false,
		error,
	}))
);
