import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { progressFade } from '../../animations/progressfade.animation';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { FilterConnection, IBundleState, IFilterDescriptor, SortDescriptors } from '../../state/app.state';
import { BundleFacade } from '../../state/bundle/bundle.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-bundle-autocompletesingle',
	templateUrl: './entityautocompletesingle-bundle.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: BundleAutocompleteSingleComponent,
		},
	],
})
export class BundleAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm> {
	public placeholder = 'Paket';

	protected override filterConnection: () => FilterConnection = () => 'OR';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'name', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IBundle> = () => ({ name: 1 });

	constructor(entityFacade: BundleFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IBundle): string {
		return entity != null ? entity.name : null;
	}
}
