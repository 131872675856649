import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { AppState, IMessageState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { fromMessageActions } from './message.actions';
import { initialEditFormValue } from './message.reducer';
import { MessageSelector } from './message.selectors';

@Injectable({
	providedIn: 'root',
})
export class MessageFacade extends EntityFacade<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromMessageActions;

	constructor(store: Store<AppState>, messageSelector: MessageSelector) {
		super(store, messageSelector);
	}

	public updatePriority(entity: IMessage, priority: number): void {
		this.store.dispatch(this.actions.updatePriority({ entity, priority }));
	}
}
