import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import { IRegion } from '../../models/region.model';
import { AppState, IRegionState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IRegionEditForm, IRegionFilterForm } from './../../models/region.model';

@Injectable({
	providedIn: 'root',
})
export class RegionSelector extends EntitySelector<IRegion, IRegionState, IRegionEditForm, IRegionFilterForm> {
	protected stateSelector = (state: AppState): IRegionState => state.region;

	public name = createSelector(this.selectState, state => state.name);
	public isSearching = createSelector(this.selectState, state => state.isSearching);
	public itemsSearched = createSelector(this.selectState, state => state.itemsSearched);
	public listSearched = createSelector(this.items, this.itemsSearched, (items, itemsSearched) => itemsSearched.map(id => items[id]));

	public selectedAddress = createSelector(this.selected, seleted => seleted?.address || '');
}
