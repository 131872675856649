import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IProcess } from '../../models/process';
import { IProcessNode } from '../../models/process-node.model';
import { FilterConnection, IFilterDescriptor, IProcessNodeState, SortDescriptors } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-process-node-autocompletesingle',
	templateUrl: './entityautocompletesingle-process-node.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class ProcessNodeAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm> {
	public placeholder = 'Status';
	@Input() public process: IProcess;

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [{ attributeName: 'process', operator: 'EQ', value: this.process._id }];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'displayName', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IProcessNode> = () => ({ displayName: 1 });

	constructor(entityFacade: ProcessNodeFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IProcessNode): string {
		return entity?.displayName;
	}
}
