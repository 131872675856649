import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IProcessNode } from '../../models/process-node.model';
import { EntityState, IProcessNodeState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IProcessNodeEditForm, IProcessNodeFilterForm } from './../../models/process-node.model';
import { fromProcessNodeActions } from './process-node.actions';

export const initialValue: IProcessNode = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
	version: null,
	color: null,
	process: null,
};

export const initialEditFormValue: IProcessNodeEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
	version: null,
};

export const initialFilterFormValue: IProcessNodeFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	displayName: null,
	version: null,
	color: null,
	process: null,
};

export const initialState = { ...EntityState.create<IProcessNode, IProcessNodeEditForm, IProcessNodeFilterForm>('PROCESSNODE', initialValue, initialEditFormValue, initialFilterFormValue), pageSize: 1000 };
export const processNodeReducer = EntityReducer.create<IProcessNode, IProcessNodeState, IProcessNodeEditForm, IProcessNodeFilterForm>(
	initialState,
	{
		name: validate(required),
	},
	fromProcessNodeActions
);
