import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IMediaBrand } from '../../models/media-brand.model';
import { AppState, IMediaBrandState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';
import { fromMediaBrandActions } from './media-brand.actions';
import { initialEditFormValue } from './media-brand.reducer';
import { MediaBrandSelector } from './media-brand.selectors';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandFacade extends EntityFacade<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromMediaBrandActions;

	constructor(store: Store<AppState>, mediaBrandSelector: MediaBrandSelector) {
		super(store, mediaBrandSelector);
	}
}
