import { Directive } from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';

@Directive()
export abstract class NotificationComponent<TData> {
	constructor(protected matSnackBarData: TData, private matSnackBarRef: MatSnackBarRef<any>) {}

	public ignore(): void {
		this.matSnackBarRef.dismiss();
	}
}
