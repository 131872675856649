<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Lieferschein ändern</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Empfänger">
					<div class="form-grid">
						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Anrede</mat-label>
							<mat-select matInput [ngrxFormControlState]="editForm.controls.receiver.controls.salutationKind">
								<mat-option *ngFor="let salutationKind of SalutationKinds | keyvalue" [value]="salutationKind.value">{{ salutationKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Vorname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.firstName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Nachname</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.lastName" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Anredeform</mat-label>
							<mat-select matInput [ngrxFormControlState]="editForm.controls.receiver.controls.addressKind">
								<mat-option *ngFor="let addressKind of AddressKinds | keyvalue" [value]="addressKind.value">{{ addressKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Firma</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.company" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Straße</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.street" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Hausnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.streetNumber" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>PLZ</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.postCode" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Stadt</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.city" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Land</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.address.controls.country" />
						</mat-form-field>
					</div>
				</mat-tab>
				<mat-tab label="Kontaktdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Email-Adresse</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.email" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Telefonnummer</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.receiver.controls.telephone" />
						</mat-form-field>
					</div>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button mat-flat-button color="accent" (click)="entityFacade.updated()" [class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">Speichern</button>
	</mat-dialog-actions>
</ng-container>
