import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReturnDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-return.component';
import { ReturnDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-return.component';
import { ReturnDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-return.component';
import { IShippingReturn, IShippingReturnEditForm, IShippingReturnFilterForm } from '../../models/shipping.model';
import { IShippingReturnState } from '../../state/app.state';
import { ReturnFacade } from '../../state/shipping.return/shipping.return.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ShippingReturnDialogService extends EntityDialogService<IShippingReturn, IShippingReturnState, IShippingReturnEditForm, IShippingReturnFilterForm> {
	protected createComponent: ComponentType<any> = ReturnDialogAddComponent;
	protected updateComponent: ComponentType<any> = ReturnDialogChangeComponent;
	protected removeComponent: ComponentType<any> = ReturnDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: ReturnFacade) {
		super(matDialog, entityFacade);
	}
}
