import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IImageEditForm } from 'src/models/image.model';
import { progressFade } from '../../animations/progressfade.animation';
import { IImage } from '../../models/image.model';
import { FilterConnection, IFilterDescriptor, IImageState, SortDescriptors } from '../../state/app.state';
import { ImageFacade } from '../../state/image/image.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { IImageFilterForm } from './../../models/image.model';
import { EntityAutocompleteMultipleComponent } from './entityautocompletemultiple.component';

@Component({
	selector: 'babylon-image-autocompletemultiple',
	templateUrl: './entityautocompletemultiple-image.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletemultiple.component.scss'],
	animations: [progressFade],
})
export class ImageAutocompleteMultipleComponent extends EntityAutocompleteMultipleComponent<IImage, IImageState, IImageEditForm, IImageFilterForm> {
	public placeholder = 'Bilder';
	public columns: string[] = ['image', 'title', 'delete'];

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'title', operator: 'CONTAINS', value }];
	protected override sortDescriptors: () => SortDescriptors<IImage> = () => ({ title: 1 });

	constructor(entityFacade: ImageFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IImage): string {
		return entity?.title;
	}
}
