import { Injectable } from '@angular/core';
import { IReceiptCancellation, IReceiptCancellationEditForm, IReceiptCancellationFilterForm } from '../../models/receipt';
import { AppState, IReceiptCancellationState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class CancellationSelector extends EntitySelector<IReceiptCancellation, IReceiptCancellationState, IReceiptCancellationEditForm, IReceiptCancellationFilterForm> {
	protected stateSelector = (state: AppState): IReceiptCancellationState => state.receiptCancellation;
}
