import { ChangeDetectionStrategy, Component } from '@angular/core';
import { progressFade } from '../../animations/progressfade.animation';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { FilterConnection, IFilterDescriptor, IOrderState, SortDescriptors } from '../../state/app.state';
import { MediaBrandFacade } from '../../state/media-brand/media-brand.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { EntityAutocompleteSingleComponent } from './entityautocompletesingle.component';

@Component({
	selector: 'babylon-order-autocompletesingle',
	templateUrl: './entityautocompletesingle-order.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entityautocompletesingle.component.scss'],
	animations: [progressFade],
})
export class OrderAutocompleteSingleComponent extends EntityAutocompleteSingleComponent<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	public placeholder = 'Bestellung';

	protected override filterConnection: () => FilterConnection = () => 'AND';
	protected override filterDescriptorsStatic: () => IFilterDescriptor[] = () => [];
	protected override filterDescriptors: (value: string) => IFilterDescriptor[] = (value: string) => [{ attributeName: 'number', operator: 'EQ', value }];
	protected override sortDescriptors: () => SortDescriptors<IOrder> = () => ({ number: 1 });

	constructor(entityFacade: OrderFacade, public mediaBrandFacade: MediaBrandFacade) {
		super(entityFacade);
	}

	public displayWith(entity: IOrder): string {
		return entity?.number.toString();
	}
}
