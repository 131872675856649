import { IPaymentCashEditForm, IPaymentCashFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentCash } from '../../models/payment.model';
import { IPaymentCashState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { CashFacade } from '../../state/payment.cash/payment.cash.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-cashdialogchange',
	templateUrl: './entitydialogchange-cash.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class CashDialogChangeComponent extends EntityDialogChangeComponent<IPaymentCash, IPaymentCashState, IPaymentCashEditForm, IPaymentCashFilterForm> {
	constructor(entityFacade: CashFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
