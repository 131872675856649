import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IAccountingSummaryCustomer, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm } from '../../models/accounting-summary.model';
import { AccountingSummaryCustomerFacade } from '../../state/accounting-summary.customer/accounting-summary.customer.facade';
import { IAccountingSummaryCustomerState } from '../../state/app.state';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class AccountingSummaryCustomerDialogService extends EntityDialogService<IAccountingSummaryCustomer, IAccountingSummaryCustomerState, IAccountingSummaryCustomerEditForm, IAccountingSummaryCustomerFilterForm> {
	protected createComponent: ComponentType<any> = null;
	protected updateComponent: ComponentType<any> = null;
	protected removeComponent: ComponentType<any> = null;

	constructor(matDialog: MatDialog, entityFacade: AccountingSummaryCustomerFacade) {
		super(matDialog, entityFacade);
	}
}
