import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IUser, IUserEditForm, IUserFilterForm } from 'src/models/user.model';
import { IUserState } from 'src/state/app.state';
import { UserFacade } from 'src/state/user/user.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-userdialogchange',
	templateUrl: './entitydialogchange-user.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class UserDialogChangeComponent extends EntityDialogChangeComponent<IUser, IUserState, IUserEditForm, IUserFilterForm> {
	constructor(entityFacade: UserFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
