import { Injectable } from '@angular/core';
import { IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm } from '../../models/stock.model';
import { AppState, IStockAccessoryState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class AccessoryStockSelector extends EntitySelector<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	protected stateSelector = (state: AppState): IStockAccessoryState => state.stockAccessory;
}
