import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleMagazineEditForm, IArticleMagazineFilterForm } from '../../models/article.model';
import { AppState, IArticleMagazineState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { IArticleMagazine } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleMagazineApiService } from '../../services/api/article.magazine.service';
import { ArticleMagazineDialogService } from '../../services/dialog/article.magazine.service';
import { ArticleMagazineNotificationService } from '../../services/notification/article.magazine.service';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleMagazineActions } from './article.magazine.actions';
import { ArticleMagazineSelector } from './article.magazine.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleMagazineEffects extends EntityEffects<IArticleMagazine, IArticleMagazineState, IArticleMagazineEditForm, IArticleMagazineFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		notificationService: ArticleMagazineNotificationService,
		dialogService: ArticleMagazineDialogService,
		private selector: ArticleMagazineSelector,
		sessionSelector: SessionSelector,
		private apiService: ArticleMagazineApiService,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleMagazineActions, 'MAGAZINE');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleMagazineActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.current, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.min, 'days').toDate(), articleKind: ArticleKind.Magazine, region }, paging)
					.pipe(
						map(data => fromArticleMagazineActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleMagazineActions.failed({ error: response.error })))
					)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleMagazineActions.dayRangeChanged, fromArticleMagazineActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleMagazineActions.extended());
				}

				return this.apiService
					.search({ date, dateFrom: moment.utc(date).subtract(dayRange.current, 'days').toDate(), dateUntil: moment.utc(date).add(dayRange.current, 'days').toDate(), articleKind: ArticleKind.Magazine, region }, paging)
					.pipe(
						map(data => fromArticleMagazineActions.searched({ date, entities: data })),
						catchError((response: IServiceErrorResponse) => of(fromArticleMagazineActions.failed({ error: response.error })))
					);
			})
		)
	);

	public onLoadedForDayRange$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleMagazineActions.searched),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			map(([{ date }, dayRange]) => fromArticleMagazineActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
		)
	);

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleMagazineActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);

	public onStoragePlaceUpdate$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleMagazineActions.updateStoragePlace),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ entity, storagePlaceId: storagePlaceId }, authToken]) =>
				this.apiService.updateStoragePlace(entity, storagePlaceId, authToken).pipe(
					map(result => this.entityActions.updated({ entity: result.data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);
}
