import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ProcessRouteFacade } from 'src/state/process-route/process-route.facade';
import { AccountingSummaryKind, IAccountingSummary, IAccountingSummaryEditForm, IAccountingSummaryFilterForm, IAccountingSummaryPosition } from '../../models/accounting-summary.model';
import { AccountingSummaryFacade } from '../../state/accounting-summary/accounting-summary.facade';
import { IAccountingSummaryState } from '../../state/app.state';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-accounting-summarydialogchange',
	templateUrl: './entitydialogchange-accounting-summary.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class AccountingSummaryDialogChangeComponent extends EntityDialogChangeComponent<
	IAccountingSummary<IAccountingSummaryPosition>,
	IAccountingSummaryState,
	IAccountingSummaryEditForm<IAccountingSummaryPosition>,
	IAccountingSummaryFilterForm<IAccountingSummaryPosition>
> {
	public AccountingSummaryKinds = AccountingSummaryKind;

	constructor(entityFacade: AccountingSummaryFacade, matDialogRef: MatDialogRef<any>, public processRouteFacade: ProcessRouteFacade) {
		super(entityFacade, matDialogRef);
	}
}
