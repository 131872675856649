import { Injectable } from '@angular/core';
import { IPublicationCycle } from '../../models/publication-cycle.model';
import { AppState, IPublicationCycleState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';

@Injectable({
	providedIn: 'root',
})
export class PublicationCycleSelector extends EntitySelector<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	protected stateSelector = (state: AppState): IPublicationCycleState => state.publicationCycle;
}
