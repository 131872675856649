<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Marke hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<div class="form-grid">
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Bezeichnung</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.caption" />
				</mat-form-field>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Kurzbeschreibung</mat-label>
					<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.shortDescription"></textarea>
				</mat-form-field>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Beschreibung</mat-label>
					<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.description"></textarea>
				</mat-form-field>
				<mat-form-field appearance="outline" class="mat-form-field--span">
					<mat-label>Link</mat-label>
					<input type="text" matInput [ngrxFormControlState]="editForm.controls.link" />
				</mat-form-field>
				<babylon-image-autocompletesingle [value]="editForm.controls.logo.value" [controlId]="editForm.controls.logo.id" [optional]="true" placeholder="Logo"></babylon-image-autocompletesingle>
				<babylon-image-autocompletesingle [value]="editForm.controls.image.value" [controlId]="editForm.controls.image.id" [optional]="true" placeholder="Artikelbild"></babylon-image-autocompletesingle>
			</div>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
