import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccessoryStockDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-accessory-stock.component';
import { AccessoryStockDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-accessory-stock.component';
import { AccessoryStockDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-accessory-stock.component';
import { IStockAccessory, IStockAccessoryEditForm, IStockAccessoryFilterForm } from '../../models/stock.model';
import { IStockAccessoryState } from '../../state/app.state';
import { AccessoryStockFacade } from '../../state/stock.accessory/stock.accessory.facade';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class StockAccessoryDialogService extends EntityDialogService<IStockAccessory, IStockAccessoryState, IStockAccessoryEditForm, IStockAccessoryFilterForm> {
	protected createComponent: ComponentType<any> = AccessoryStockDialogAddComponent;
	protected updateComponent: ComponentType<any> = AccessoryStockDialogChangeComponent;
	protected removeComponent: ComponentType<any> = AccessoryStockDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: AccessoryStockFacade) {
		super(matDialog, entityFacade);
	}
}
