import { validate } from 'ngrx-forms';
import { required } from 'ngrx-forms/validation';
import { IProcessRoute } from '../../models/process-route.model';
import { EntityState, IProcessRouteState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { IProcessRouteEditForm, IProcessRouteFilterForm } from './../../models/process-route.model';
import { fromProcessRouteActions } from './process-route.actions';

export const initialValue: IProcessRoute = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
	matIconName: null,
	version: null,
	sourceNode: null,
	destinationNode: null,
	permissions: null,
	color: null,
	isHappyPath: false,
	isVisible: false,
};

export const initialEditFormValue: IProcessRouteEditForm = {
	_id: null,
	processInstance: null,
	name: null,
	displayName: null,
	matIconName: null,
	version: null,
	sourceNode: null,
	destinationNode: null,
	permissions: null,
	color: null,
	isHappyPath: false,
	isVisible: false,
};

export const initialFilterFormValue: IProcessRouteFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: null,
	name: null,
	displayName: null,
	matIconName: null,
	version: null,
	sourceNode: null,
	destinationNode: null,
	permissions: null,
	color: null,
	isHappyPath: false,
	isVisible: false,
};

export const initialState = { ...EntityState.create<IProcessRoute, IProcessRouteEditForm, IProcessRouteFilterForm>('PROCESSROUTE', initialValue, initialEditFormValue, initialFilterFormValue), pageSize: 1000 };
export const processRouteReducer = EntityReducer.create<IProcessRoute, IProcessRouteState, IProcessRouteEditForm, IProcessRouteFilterForm>(
	initialState,
	{
		name: validate(required),
		displayName: validate(required),
		matIconName: validate(required),
	},
	fromProcessRouteActions
);
