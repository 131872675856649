import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PublicationCycleDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-publication-cycle.component';
import { PublicationCycleDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-publication-cycle.component';
import { PublicationCycleDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-publication-cycle.component';
import { IPublicationCycleState } from '../../state/app.state';
import { PublicationCycleFacade } from '../../state/publication-cycle/publication-cycle.facade';
import { IPublicationCycle, IPublicationCycleEditForm, IPublicationCycleFilterForm } from './../../models/publication-cycle.model';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PublicationCycleDialogService extends EntityDialogService<IPublicationCycle, IPublicationCycleState, IPublicationCycleEditForm, IPublicationCycleFilterForm> {
	protected createComponent: ComponentType<any> = PublicationCycleDialogAddComponent;
	protected updateComponent: ComponentType<any> = PublicationCycleDialogChangeComponent;
	protected removeComponent: ComponentType<any> = PublicationCycleDialogDeleteComponent;

	constructor(matDialog: MatDialog, entityFacade: PublicationCycleFacade) {
		super(matDialog, entityFacade);
	}
}
