import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPublisher } from 'src/models/publisher.model';
import { IPublisherState } from 'src/state/app.state';
import { PublisherFacade } from 'src/state/publisher/publisher.facade';
import { IPublisherEditForm, IPublisherFilterForm } from './../../models/publisher.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-publisherdialogadd',
	templateUrl: './entitydialogadd-publisher.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class PublisherDialogAddComponent extends EntityDialogAddComponent<IPublisher, IPublisherState, IPublisherEditForm, IPublisherFilterForm> {
	constructor(entityFacade: PublisherFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
