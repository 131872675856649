import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AppState, IDeliveryNoteState } from '../app.state';
import { IDeliveryNoteEditForm, IDeliveryNoteFilterForm } from './../../models/delivery-note';
import { catchError, debounceTime, map, of, switchMap, tap } from 'rxjs';

import { DeliveryNoteApiService } from '../../services/api/delivery-note.service';
import { DeliveryNoteDialogService } from '../../services/dialog/delivery-note.service';
import { DeliveryNoteNotificationService } from '../../services/notification/delivery-note.service';
import { DeliveryNoteSelector } from './delivery-note.selectors';
import { EntityEffects } from '../entity.effects';
import { IDeliveryNote } from '../../models/delivery-note';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { Injectable } from '@angular/core';
import { SessionSelector } from '../session/session.selectors';
import { Store } from '@ngrx/store';
import { fromDeliveryNoteActions } from './delivery-note.actions';
import { saveAs } from 'file-saver';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteEffects extends EntityEffects<IDeliveryNote, IDeliveryNoteState, IDeliveryNoteEditForm, IDeliveryNoteFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		private deliveryNoteService: DeliveryNoteApiService,
		notificationService: DeliveryNoteNotificationService,
		dialogService: DeliveryNoteDialogService,
		selector: DeliveryNoteSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, deliveryNoteService, notificationService, dialogService, selector, sessionSelector, fromDeliveryNoteActions, 'DELIVERYNOTE');
	}

	public onPrint$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteActions.print),
			debounceTime(500),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ deliveryNote }, authToken]) =>
				this.deliveryNoteService.print(authToken, deliveryNote).pipe(
					map(() => fromDeliveryNoteActions.printed()),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownload$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromDeliveryNoteActions.download),
			concatLatestFrom(() => this.store.select(this.sessionSelector.authToken)),
			switchMap(([{ deliveryNote }, authToken]) =>
				this.deliveryNoteService.download(authToken, deliveryNote).pipe(
					map(data => fromDeliveryNoteActions.downloaded({ deliveryNote, data })),
					catchError((response: IServiceErrorResponse) => of(this.entityActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDownloaded$ = createEffect(
		() =>
			this.actions$.pipe(
				ofType(fromDeliveryNoteActions.downloaded),
				tap(({ deliveryNote, data }) => saveAs(data, `Lieferschein-${deliveryNote._id}.pdf`))
			),
		{ dispatch: false }
	);
}
