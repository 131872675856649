import { IEntity, IEntityEditForm, IEntityFilterForm } from 'src/models/entity.model';
import { AppState, FilterOperators, IEntityState } from './app.state';

import { Injectable } from '@angular/core';
import { createSelector } from '@ngrx/store';
import _ from 'lodash';
import { destructFormGroupValue } from '../types/form';
import { BaseSelector } from './base.selector';

@Injectable({
	providedIn: 'root',
})
export abstract class EntitySelector<
	TEntity extends IEntity,
	TEntityState extends IEntityState<TEntity, TEntityEditForm, TEntityFilterForm>,
	TEntityEditForm extends IEntityEditForm,
	TEntityFilterForm extends IEntityFilterForm
> extends BaseSelector<TEntityState> {
	protected abstract stateSelector(state: AppState): TEntityState;

	public items = createSelector(this.selectState, state => state.items);
	public itemsFiltered = createSelector(this.selectState, state => state.itemsFiltered);
	public itemsSuggested = createSelector(this.selectState, state => state.itemsSuggested);
	public itemsInvalidated = createSelector(this.selectState, state => state.itemsInvalidated);
	public isFiltering = createSelector(this.selectState, state => state.isFiltering);
	public isUpdating = createSelector(this.selectState, state => state.isUpdating);
	public isSuggesting = createSelector(this.selectState, state => state.isSuggesting);
	public isFetching = createSelector(this.selectState, state => state.isFetching);
	public error = createSelector(this.selectState, state => state.error);
	public selected = createSelector(this.selectState, state => state.selected);
	public pageIndex = createSelector(this.selectState, state => state.pageIndex);
	public pageSize = createSelector(this.selectState, state => state.pageSize);
	public filterCount = createSelector(this.itemsFiltered, itemsFiltered => itemsFiltered.length);
	public totalCount = createSelector(this.selectState, state => state.totalCount);
	public initialValue = createSelector(this.selectState, state => state.initialValue);
	public initialEditFormValue = createSelector(this.selectState, state => state.initialEditFormValue);
	public initialFilterFormValue = createSelector(this.selectState, state => state.initialFilterFormValue);
	public editForm = createSelector(this.selectState, state => state.editForm);
	public filterForm = createSelector(this.selectState, state => state.filterForm);
	public filterOperatorForm = createSelector(this.selectState, state => state.filterOperatorForm);
	public sortDescriptors = createSelector(this.selectState, state => state.sortDescriptors);
	public filterConnection = createSelector(this.selectState, state => state.filterConnection);
	public filterDescriptors = createSelector(this.selectState, state => state.filterDescriptors);
	public filterQuery = createSelector(this.selectState, state => state.filterQuery);
	public processNodeCounts = createSelector(this.selectState, state => state.processNodeCounts);

	public list = createSelector(this.items, items => _.values(items));
	public listFiltered = createSelector(this.items, this.itemsFiltered, (items, itemsFiltered) => itemsFiltered.map(id => items[id]).filter(x => !!x));
	public listSuggested = createSelector(this.items, this.itemsSuggested, (items, itemsSuggested) => itemsSuggested.map(id => items[id]).filter(x => !!x));
	public editFormValue = createSelector(this.editForm, form => destructFormGroupValue<TEntity>(form.value));
	public filterFormValue = createSelector(this.filterForm, form => destructFormGroupValue<TEntityFilterForm>(form.value));
	public filterOperatorFormValue = createSelector(this.filterOperatorForm, form => destructFormGroupValue<FilterOperators<TEntity>[]>(form.value));

	public isInvalidated = (id: string) => createSelector(this.itemsInvalidated, items => (id == null ? false : items.indexOf(id) >= 0));
	public getOne = (id: string) => createSelector(this.items, items => (id == null ? null : items[id]));
	public getMany = (ids: string[]) => createSelector(this.items, items => (ids == null ? [] : ids.map(id => items[id])));
	public find = (...predicates: ((entity: TEntity) => boolean)[]) => createSelector(this.list, list => list.find(entity => predicates.every(predicate => predicate(entity))));
	public filter = (...predicates: ((entity: TEntity) => boolean)[]) => createSelector(this.list, list => list.filter(entity => predicates.every(predicate => predicate(entity))));
	public filteredProcessNodeCounts = (...processNodeNames: string[]) =>
		createSelector(this.processNodeCounts, processNodeCounts => processNodeCounts.filter(processNodeCount => processNodeNames.some(x => x.toLowerCase() == processNodeCount.processNode.name.toLowerCase())));
	public countByProcessNodes = (...processNodeNames: string[]) => createSelector(this.filteredProcessNodeCounts(...processNodeNames), processNodeCounts => _.sumBy(processNodeCounts, x => x.count));

	public buildDataSource<T>(selector: (entity: TEntity) => T[]) {
		return createSelector(this.editFormValue, value => _.cloneDeep(selector(value)));
	}
}
