import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrderFacade } from 'src/state/order/order.facade';
import { OrdersPositionDialogComponent } from '../../components/dialog/dialog-order-positions.component';
import { OrdersReturnPositionDialogComponent } from '../../components/dialog/dialog-order-return-positions.component';
import { OrderDetailDialogComponent } from '../../components/entitydialog/entitydialog-order-detail.component';
import { OrderSearchDialogComponent } from '../../components/entitydialog/entitydialog-order-search.component';
import { OrderDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-order.component';
import { OrderDialogPreviewComponent } from '../../components/entitydialogpreview/entitydialogpreview-order.component';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IOrderState } from '../../state/app.state';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class OrderDialogService extends EntityDialogService<IOrder, IOrderState, IOrderEditForm, IOrderFilterForm> {
	protected removeComponent: ComponentType<any> = OrderDialogDeleteComponent;
	protected previewComponent: ComponentType<any> = OrderDialogPreviewComponent;
	protected editPositionsComponent: ComponentType<any> = OrdersPositionDialogComponent;
	protected returnPositionsComponent: ComponentType<any> = OrdersReturnPositionDialogComponent;
	protected searchComponent: ComponentType<any> = OrderSearchDialogComponent;
	protected detailComponent: ComponentType<any> = OrderDetailDialogComponent;

	constructor(matDialog: MatDialog, private orderFacade: OrderFacade) {
		super(matDialog, orderFacade);
	}

	public openEditPositionsDialog(): void {
		this.openDialog(this.editPositionsComponent, {
			disableClose: false,
		});
	}

	public openRetourePositionsDialog(): void {
		this.openDialog(this.returnPositionsComponent, {
			disableClose: false,
		});
	}

	public openSearchDialog(): void {
		this.orderFacade.changeSearchValue(null);
		this.openDialog(this.searchComponent, {
			width: '1000px',
		});
	}

	public openDetailDialog(oder: IOrder): void {
		this.orderFacade.select(oder);
		this.openDialog(this.detailComponent, {
			width: '1000px',
		});
	}
}
