import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAction } from '../../models/process-instance-model';
import { IStockMedia } from '../../models/stock.model';
import { IStockMediaState } from '../../state/app.state';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { IStockMediaEditForm, IStockMediaFilterForm } from './../../models/stock.model';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-media-stockhistorytable',
	templateUrl: './entitydialogtable-media-stockhistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class MediaStockHistoryTableComponent extends HistoryDialogTableComponent<IStockMedia, IStockMediaState, IAction, IAction, IStockMediaEditForm, IStockMediaFilterForm> {
	public columns = ['date', 'userName', 'route', 'state', 'comment'];

	constructor(entityFacade: MediaStockFacade, matDialog: MatDialog, public processRouteFacade: ProcessRouteFacade, public processNodeFacade: ProcessNodeFacade) {
		super(entityFacade, matDialog);
	}

	protected dataSourceSelector(entity: IStockMedia): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}
}
