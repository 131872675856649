import { Injectable } from '@angular/core';
import { IMediaBrand } from '../../models/media-brand.model';
import { AppState, IMediaBrandState } from '../app.state';
import { EntitySelector } from '../entity.selector';
import { IMediaBrandEditForm, IMediaBrandFilterForm } from './../../models/media-brand.model';

@Injectable({
	providedIn: 'root',
})
export class MediaBrandSelector extends EntitySelector<IMediaBrand, IMediaBrandState, IMediaBrandEditForm, IMediaBrandFilterForm> {
	protected stateSelector = (state: AppState): IMediaBrandState => state.mediaBrand;
}
