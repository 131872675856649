<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Produkt hinzufügen</h1>
	<mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-tab-group>
				<mat-tab label="Stammdaten">
					<div class="form-grid">
						<mat-form-field appearance="outline">
							<mat-label>Name</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Artikeltyp</mat-label>
							<mat-select matInput [ngrxFormControlState]="editForm.controls.articleKind">
								<mat-option *ngFor="let articleKind of ArticleKinds | keyvalue" [value]="articleKind.value">{{ articleKind.value }}</mat-option>
							</mat-select>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Preis</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.price" />
							<span matSuffix>€</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Steuersatz</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.taxRate" [ngrxValueConverter]="percentageConverter" />
							<span matSuffix>%</span>
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Priorität</mat-label>
							<input type="number" max="10" min="0" matInput [ngrxFormControlState]="editForm.controls.priority" />
						</mat-form-field>
						<mat-form-field appearance="outline">
							<mat-label>Minimale Anzahl</mat-label>
							<input type="number" matInput [ngrxFormControlState]="editForm.controls.minimalQuantity" />
						</mat-form-field>

						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Kurzbeschreibung</mat-label>
							<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.shortDescription"></textarea>
						</mat-form-field>
						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Beschreibung</mat-label>
							<textarea matInput rows="4" cdkTextareaAutosize [ngrxFormControlState]="editForm.controls.description"></textarea>
						</mat-form-field>
						<mat-form-field appearance="outline" class="mat-form-field--span">
							<mat-label>Link</mat-label>
							<input type="text" matInput [ngrxFormControlState]="editForm.controls.link" />
						</mat-form-field>
						<babylon-image-autocompletesingle [value]="editForm.controls.logo.value" [controlId]="editForm.controls.logo.id" [optional]="true" placeholder="Logo"></babylon-image-autocompletesingle>
						<babylon-image-autocompletesingle [value]="editForm.controls.image.value" [controlId]="editForm.controls.image.id" [optional]="true" placeholder="Artikelbild"></babylon-image-autocompletesingle>
						<babylon-image-autocompletemultiple
							[values]="editForm.controls.additionalImages.value"
							[controlId]="editForm.controls.additionalImages.id"
							placeholder="Zusatzbilder"
							class="mat-form-field--span"></babylon-image-autocompletemultiple>
					</div>
				</mat-tab>
				<mat-tab label="Partnerfreigabe">
					<mat-card class="box">
						<mat-card-content>
							<babylon-accessory-product-partner-pricestable controlId="partnerPrices"></babylon-accessory-product-partner-pricestable>
						</mat-card-content>
					</mat-card>
				</mat-tab>
			</mat-tab-group>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-button color="default" (click)="matDialogRef.close()">Abbrechen</button>
		<button
			mat-flat-button
			color="accent"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
	</mat-dialog-actions>
</ng-container>
