import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IArticleBook } from '../../models/article.model';
import { IArticleBookState } from '../../state/app.state';
import { ArticleBookFacade } from '../../state/article.book/article.book.facade';
import { IArticleBookEditForm, IBookFilterForm } from './../../models/article.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-bookdialogadd',
	templateUrl: './entitydialogadd-book.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class BookDialogAddComponent extends EntityDialogAddComponent<IArticleBook, IArticleBookState, IArticleBookEditForm, IBookFilterForm> {
	constructor(entityFacade: ArticleBookFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
