import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, first, map, Observable, tap, withLatestFrom } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleState } from 'src/state/app.state';
import { IArticle } from '../../models/article.model';
import { PriorityType } from '../../models/process-instance-model';
import { ArticleDialogService } from '../../services/dialog/article.service';
import { ArticleFacade } from '../../state/article/article.facade';
import { ImageFacade } from '../../state/image/image.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ProductFacade } from '../../state/product/product.facade';
import { RouterFacade } from '../../state/router/router.facade';
import { StockFacade } from '../../state/stock/stock.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { UserFacade } from '../../state/user/user.facade';
import { IArticleEditForm, IArticleFilterForm } from './../../models/article.model';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-articlepage',
	templateUrl: './entitypage-article.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class ArticlePageComponent extends EntityPageComponent<IArticle, IArticleState, IArticleEditForm, IArticleFilterForm> {
	public pageTitle$ = new BehaviorSubject('Artikel');
	public columns = ['select', 'name', 'order', 'storageRoom', 'assignee', 'status', 'prio', 'filter'];
	public selection = new SelectionModel<IArticle>(true, []);
	public masterSelection$ = new BehaviorSubject<boolean>(false);
	public PriorityTypes = PriorityType;

	protected shouldDoInitialFilter = false;
	protected shouldDoRegularFilter = 60;

	constructor(
		titleService: Title,
		route: ActivatedRoute,
		private articleDialogService: ArticleDialogService,
		public entityFacade: ArticleFacade,
		public orderFacade: OrderFacade,
		public productFacade: ProductFacade,
		public stockFacade: StockFacade,
		public userFacade: UserFacade,
		public routerFacade: RouterFacade,
		public imageFacade: ImageFacade,
		public uploadedFileFacade: UploadedFileFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, articleDialogService, route, entityFacade);

		this.masterSelection$
			.pipe(
				withLatestFrom(this.entityFacade.listFiltered$),
				map(([selectAll, list]) => {
					if (selectAll) {
						this.selection.select(...list);
					} else {
						this.selection.clear();
					}
				})
			)
			.subscribe();

		combineLatest([this.routerFacade.articleStates$, this.processNodeFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, processNodes]) => processNodes.length > 0),
				map(([states, processNodes]) => processNodes.filter(processNode => states.some(state => state.toLowerCase() == processNode?.name.toLowerCase()))),
				debounceTime(200),
				tap(processNodes => {
					const processNodeNames = processNodes.map(processNode => processNode.name);

					if (processNodes.length == 0 || processNodeNames.indexOf('ArticleSold') > -1) {
						this.entityFacade.changeSorting({ updatedAt: -1 });
					} else {
						this.entityFacade.changeSorting({ 'processInstance.priority': -1, 'updatedAt': 1 });
					}

					if (processNodes.length > 0) {
						this.entityFacade.changeFilterDescriptor({ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) });
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}

	public get isAllSelected$(): Observable<boolean> {
		return this.entityFacade.listFiltered$.pipe(map(list => list.length == this.selection.selected.length));
	}

	public changeAssignee(userId: string) {
		this.userFacade
			.fetchOne(userId)
			.pipe(first())
			.subscribe(user => {
				for (let article of this.selection.selected) {
					this.entityFacade.updateAssignee(article, user);
				}

				this.selection.clear();
			});
	}

	public showPickDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openPickDialog({ entity, width });
	}

	public showReproduceDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openReproduceDialog({ entity, width });
	}

	public showHandoverDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openHandoverDialog({ entity, width });
	}

	public showReturnDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openReturnDialog({ entity, width });
	}

	public showOrderDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openOrderDialog({ entity, width });
	}

	public showPostcheckDialog(entity: IArticle, width: string = null): void {
		this.articleDialogService.openPostcheckDialog({ entity, width });
	}
}
