import { box } from 'ngrx-forms';
import { IBundle, IBundleEditForm, IBundleFilterForm } from '../../models/bundle.model';
import { EntityState, IBundleState } from '../app.state';
import { EntityReducer } from '../entity.reducer';
import { fromBundleActions } from './bundle.actions';

export const initialValue: IBundle = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	name: null,
	printName: null,
	headline: null,
	isPreferred: null,
	isActive: null,
	image: null,
	description: null,
	descriptionModal: null,
	upsellingHeadline: null,
	upsellingText: null,
	components: [{ articleKind: null, discount: null, isPrincipal: null }],
	upgrades: [],
	price: null,
};

export const initialEditFormValue: IBundleEditForm = {
	_id: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	name: null,
	printName: null,
	headline: null,
	isPreferred: null,
	isActive: null,
	image: null,
	description: null,
	descriptionModal: null,
	upsellingHeadline: null,
	upsellingText: null,
	components: [{ articleKind: null, discount: null, isPrincipal: null }],
	upgrades: [],
	price: null,
};

export const initialFilterFormValue: IBundleFilterForm = {
	_id: null,
	createdAt: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	name: null,
	printName: null,
	headline: null,
	isPreferred: null,
	isActive: null,
	image: null,
	description: null,
	components: [],
	upgrades: [],
	price: null,
};

export const initialState = EntityState.create<IBundle, IBundleEditForm, IBundleFilterForm>('BUNDLE', initialValue, initialEditFormValue, initialFilterFormValue);
export const bundleReducer = EntityReducer.create<IBundle, IBundleState, IBundleEditForm, IBundleFilterForm>(initialState, {}, fromBundleActions);
