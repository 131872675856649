<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle cdkDragBoundary=".cdk-overlay-container">Produkt hinzufügen</h1>
	<div mat-dialog-content>
		<form [ngrxFormState]="editForm">
			<mat-form-field>
				<mat-label>Produktname</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.name" />
			</mat-form-field>
			<mat-form-field>
				<mat-label>Preis</mat-label>
				<input type="text" matInput [ngrxFormControlState]="editForm.controls.price" />
			</mat-form-field>
		</form>
	</div>
	<div mat-dialog-actions>
		<button
			mat-raised-button
			color="primary"
			*ngIf="entityFacade.editFormValue$ | async as editFormValue"
			(click)="entityFacade.created(editFormValue)"
			[class.mat-button-disabled]="editForm.isInvalid || (entityFacade.isFiltering$ | async)">
			Hinzufügen
		</button>
		<button mat-button color="primary" (click)="matDialogRef.close()">Abbrechen</button>
	</div>
</ng-container>
