import { box, createFormGroupState } from 'ngrx-forms';
import { IArticleCertificateEditForm, IArticleCertificateFilterForm, IExpandedArticleCertificate, IExpandedArticleCertificateEditForm } from '../../models/article.model';
import { EntityState, IArticleCertificateState } from '../app.state';

import { on } from '@ngrx/store';
import { IArticleCertificate } from '../../models/article.model';
import { EntityReducer } from '../entity.reducer';
import { fromArticleCertificateActions } from './article.certificate.actions';

export const initialValue: IArticleCertificate = {
	_id: null,
	anniversary: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	order: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
	occasion: null,
	customOccasion: null,
	addressForm: null,
	date: null,
	partner: null,
	partnerBirthName: null,
	partnerAdditionalName: null,
	giftee: null,
	gifteeBirthName: null,
	gifteeAdditionalName: null,
	isCustomized: null,
	text: null,
	message: null,
	fontSizeOccasion: null,
	fontSizeGiftee: null,
	fontSizeGifteeBirthName: null,
	fontSizePartner: null,
	fontSizePartnerBirthName: null,
	fontSizeText: null,
	fontSizeMessage: null,
};

export const initialEditFormValue: IArticleCertificateEditForm = {
	_id: null,
	anniversary: null,
	order: null,
	publicationDate: null,
	quality: null,
	storagePlace: null,
	product: null,
	processInstance: {
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
	occasion: null,
	customOccasion: null,
	addressForm: null,
	date: null,
	partner: null,
	partnerBirthName: null,
	partnerAdditionalName: null,
	giftee: null,
	gifteeBirthName: null,
	gifteeAdditionalName: null,
	isCustomized: null,
	text: null,
	message: null,
	fontSizeOccasion: null,
	fontSizeGiftee: null,
	fontSizeGifteeBirthName: null,
	fontSizePartner: null,
	fontSizePartnerBirthName: null,
	fontSizeText: null,
	fontSizeMessage: null,
};

export const initialExpandedEditFormValue: IExpandedArticleCertificateEditForm = {
	_id: null,
	anniversary: null,
	publicationDate: null,
	processInstance: {
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: {
			name: null,
			displayName: null,
			version: null,
		},
		priority: null,
	},
	product: {
		_id: null,
		createdAt: null,
		createdBy: null,
		updatedAt: null,
		name: null,
		price: null,
		taxRate: null,
		productKind: null,
		articleKind: null,
	},
	articleKind: null,
	occasion: null,
	customOccasion: null,
	addressForm: null,
	date: null,
	partner: null,
	partnerBirthName: null,
	partnerAdditionalName: null,
	giftee: null,
	gifteeBirthName: null,
	gifteeAdditionalName: null,
	isCustomized: null,
	text: null,
	message: null,
	fontSizeOccasion: null,
	fontSizeGiftee: null,
	fontSizeGifteeBirthName: null,
	fontSizePartner: null,
	fontSizePartnerBirthName: null,
	fontSizeText: null,
	fontSizeMessage: null,
};

export const initialFilterFormValue: IArticleCertificateFilterForm = {
	_id: null,
	createdAt: null,
	anniversary: null,
	order: null,
	publicationDate: null,
	quality: box([]),
	storagePlace: null,
	product: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
	articleKind: null,
	stock: null,
	occasion: null,
	customOccasion: null,
	addressForm: null,
	date: null,
	partner: null,
	partnerBirthName: null,
	partnerAdditionalName: null,
	giftee: null,
	gifteeBirthName: null,
	gifteeAdditionalName: null,
	isCustomized: null,
	text: null,
	message: null,
	fontSizeOccasion: null,
	fontSizeGiftee: null,
	fontSizeGifteeBirthName: null,
	fontSizePartner: null,
	fontSizePartnerBirthName: null,
	fontSizeText: null,
	fontSizeMessage: null,
};

export const initialState = {
	...EntityState.create<IArticleCertificate, IArticleCertificateEditForm, IArticleCertificateFilterForm>('CERTIFICATE', initialValue, initialEditFormValue, initialFilterFormValue, [
		{
			processInstance: {
				state: 'IN',
			},
			date: 'EQ',
		},
	]),
	expandedEditForm: createFormGroupState<IExpandedArticleCertificateEditForm>(`CERTIFICATE_EXPANDED_EDIT`, initialExpandedEditFormValue),
	isSearching: false,
	isExtending: false,
	itemsSearched: [] as IExpandedArticleCertificate[],
	paging: {
		skip: 0,
		top: 100,
	},
};
export const articleCertificateReducer = EntityReducer.create<IArticleCertificate, IArticleCertificateState, IArticleCertificateEditForm, IArticleCertificateFilterForm>(
	initialState,
	{},
	fromArticleCertificateActions,
	on(fromArticleCertificateActions.print, state => ({ ...state, isFetching: true })),
	on(fromArticleCertificateActions.printed, state => ({ ...state, isFetching: false })),
	on(fromArticleCertificateActions.download, state => ({ ...state, isFetching: true })),
	on(fromArticleCertificateActions.downloaded, state => ({ ...state, isFetching: false })),
	on(fromArticleCertificateActions.search, state => ({
		...state,
		isSearching: true,
	})),
	on(fromArticleCertificateActions.searched, (state, { entities }) => ({
		...state,
		itemsSearched: entities,
		isSearching: false,
	})),
	on(fromArticleCertificateActions.failed, state => ({
		...state,
		isSearching: false,
	}))
);
