<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="name-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="name">Name</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="name-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline">
										<mat-label>Name</mat-label>
										<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.name" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['STARTSWITH', 'CONTAINS']"
										[value]="filterOperatorForm.controls[0].controls.name.value"
										[controlId]="filterOperatorForm.controls[0].controls.name.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="name-content">
							<td mat-cell *matCellDef="let entity">{{ entity.name }}</td>
						</ng-container>

						<ng-container matColumnDef="shortName-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="shortName">Abkürzung</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="shortName-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline">
										<mat-label>Abkürzung</mat-label>
										<input type="text" matInput [ngrxFormControlState]="filterForm.controls[0].controls.shortName" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['EQ', 'STARTSWITH', 'CONTAINS']"
										[value]="filterOperatorForm.controls[0].controls.shortName.value"
										[controlId]="filterOperatorForm.controls[0].controls.shortName.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="shortName-content">
							<td mat-cell *matCellDef="let entity">{{ entity.shortName }}</td>
						</ng-container>

						<ng-container matColumnDef="regionKind-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="regionKind">Regionstyp</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="regionKind-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="regionKind-content">
							<td mat-cell *matCellDef="let entity">{{ entity.regionKind }}</td>
						</ng-container>

						<ng-container matColumnDef="parentRegion-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="parentRegion">Übergeordnete Region</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="parentRegion-filter">
							<th mat-header-cell *matHeaderCellDef>
								<babylon-region-autocompletemultiple
									[optional]="true"
									[values]="filterForm.controls[0].controls.parentRegion.value"
									[controlId]="filterForm.controls[0].controls.parentRegion.id"></babylon-region-autocompletemultiple>
								<babylon-filteroperator
									[entityFacade]="entityFacade"
									[availableOptions]="['IN']"
									[value]="filterOperatorForm.controls[0].controls.parentRegion.value"
									[controlId]="filterOperatorForm.controls[0].controls.parentRegion.id"></babylon-filteroperator>
							</th>
						</ng-container>
						<ng-container matColumnDef="parentRegion-content">
							<td mat-cell *matCellDef="let entity">
								{{ (entityFacade.fetchOne(entity.parentRegion) | async)?.name }}
								<babylon-fetch [facade]="entityFacade" [value]="entity.parentRegion"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
									<mat-icon svgIcon="pencil"></mat-icon>
								</button>
								<button mat-icon-button (click)="remove(entity)" matTooltip="Löschen">
									<mat-icon svgIcon="trash-can-outline"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<button mat-flat-button color="accent" (click)="create()">
								<mat-icon svgIcon="plus"></mat-icon>
								Hinzufügen
							</button>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
