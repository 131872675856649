import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IUserDevice } from '../../models/user.model';
import { AppState } from '../app.state';
import { BaseFacade } from '../base.facade';
import { fromDeviceActions } from './device.actions';
import { DeviceSelector } from './device.selectors';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

@Injectable({
	providedIn: 'root',
})
export class DeviceFacade extends BaseFacade {
	constructor(store: Store<AppState>, private deviceSelector: DeviceSelector) {
		super(store);
	}

	public isFiltering$ = this.store.select(this.deviceSelector.isLoading);
	public forMachine$ = this.store.select(this.deviceSelector.forMachine);
	public forUser$ = this.store.select(this.deviceSelector.forUser);
	public userRegistered$ = this.store.select(this.deviceSelector.userRegistered);

	public calculateMachineId(): void {
		FingerprintJS.load()
			.then(fp => fp.get())
			.then(result => this.store.dispatch(fromDeviceActions.machineIdCalculated({ machineId: result.visitorId })));
	}

	public loadDevicesForMachine(): void {
		this.store.dispatch(fromDeviceActions.loadDevicesForMachine());
	}

	public loadDeviceForUser(email: string): void {
		this.store.dispatch(fromDeviceActions.loadDeviceForUser({ email }));
	}

	public register(userDevice: IUserDevice): void {
		this.store.dispatch(fromDeviceActions.register({ userDevice }));
	}

	public authenticate(userDevice: IUserDevice): void {
		this.store.dispatch(fromDeviceActions.authenticate({ userDevice }));
	}

	public reset(): void {
		this.store.dispatch(fromDeviceActions.reset());
	}
}
