import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { CashNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-cash.component';
import { IPaymentCash } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class CashNotificationService extends EntityNotificationService<IPaymentCash> {
	protected component: ComponentType<any> = CashNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
