import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentCollectionPurchase, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm } from '../../models/payment.model';
import { PaymentCollectionPurchaseApiService } from '../../services/api/payment.collection-purchase.service';
import { PaymentCollectionPurchaseDialogService } from '../../services/dialog/payment.collection-purchase.service';
import { CollectionPurchaseNotificationService } from '../../services/notification/payment.collection-purchase.service';
import { AppState, IPaymentCollectionPurchaseState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromCollectionPurchaseActions } from './payment.collection-purchase.actions';
import { CollectionPurchaseSelector } from './payment.collection-purchase.selectors';

@Injectable({
	providedIn: 'root',
})
export class CollectionPurchaseEffects extends EntityEffects<IPaymentCollectionPurchase, IPaymentCollectionPurchaseState, IPaymentCollectionPurchaseEditForm, IPaymentCollectionPurchaseFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PaymentCollectionPurchaseApiService,
		notificationService: CollectionPurchaseNotificationService,
		dialogService: PaymentCollectionPurchaseDialogService,
		selector: CollectionPurchaseSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromCollectionPurchaseActions, 'COLLECTIONPURCHASE');
	}
}
