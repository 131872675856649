import { Injectable } from '@angular/core';
import { IPaymentBarzahlen, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm } from '../../models/payment.model';
import { AppState, IPaymentBarzahlenState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class BarzahlenSelector extends EntitySelector<IPaymentBarzahlen, IPaymentBarzahlenState, IPaymentBarzahlenEditForm, IPaymentBarzahlenFilterForm> {
	protected stateSelector = (state: AppState): IPaymentBarzahlenState => state.paymentBarzahlen;
}
