import { IEnvironment } from 'src/models/environment.model';

export const environment: IEnvironment = {
	production: false,
	serviceWorker: true,
	googleMapsApiKey: 'XXX',
	serverUrl: 'https://api.babylon.test.geschenkzeitung.de',
	serverPort: 443,
	apiUrl: 'api/v1',
	projectId: 'pr45',
	cloudImageToken: 'aookdwenar',
};
