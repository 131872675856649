import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { DeliveryNoteOrderNotificationErrorComponent } from 'src/components/entitynotificationerror/entitynotificationerror-delivery-note-order.component';
import { IDeliveryNoteOrder } from '../../models/delivery-note';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class DeliveryNoteOrderNotificationService extends EntityNotificationService<IDeliveryNoteOrder> {
	protected component: ComponentType<any> = DeliveryNoteOrderNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
