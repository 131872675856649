export enum RegionKind {
	Sublocality2 = 'Stadtteil',
	Sublocality1 = 'Bezirk',
	Locality = 'Stadt',
	AdministrativeArea3 = 'Landkreis',
	AdministrativeArea1 = 'Bundesland',
	Compass = 'Himmelsrichtung',
	Country = 'Land',
	International = 'International',
}
