import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IArticleDvdState } from 'src/state/app.state';
import { IArticleDvd } from '../../models/article.model';
import { ArticleDvdDialogService } from '../../services/dialog/article.dvd.service';
import { ArticleDvdFacade } from '../../state/article.dvd/article.dvd.facade';
import { MediaProductFacade } from '../../state/media-product/media-product.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { MediaStockFacade } from '../../state/stock.media/stock.media.facade';
import { StoragePlaceFacade } from '../../state/storage-place/storage-place.facade';
import { IArticleDvdEditForm, IArticleDvdFilterForm } from './../../models/article.model';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-dvdpage',
	templateUrl: './entitypage-dvd.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class DvdPageComponent extends EntityPageComponent<IArticleDvd, IArticleDvdState, IArticleDvdEditForm, IArticleDvdFilterForm> {
	public pageTitle$ = new BehaviorSubject('DVDs');
	public columns = ['updatedAt', 'order', 'product', 'storagePlace', 'quality', 'publicationDate', 'assignee', 'state', 'filter'];

	constructor(
		titleService: Title,
		entityDialogService: ArticleDvdDialogService,
		route: ActivatedRoute,
		public entityFacade: ArticleDvdFacade,
		public orderFacade: OrderFacade,
		public mediaProductFacade: MediaProductFacade,
		public mediaStockFacade: MediaStockFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public storagePlaceFacade: StoragePlaceFacade,
		public processFacade: ProcessFacade
	) {
		super(titleService, entityDialogService, route, entityFacade);
		this.entityFacade.changeSorting({ updatedAt: -1 });
	}
}
