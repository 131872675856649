<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<table mat-table [dataSource]="dataSource$ | async">
		<ng-container matColumnDef="articleKind">
			<th mat-header-cell *matHeaderCellDef>
				<div>Artikeltyp</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let item">
				{{ item.articleKind }}
			</td>
		</ng-container>
		<ng-container matColumnDef="price">
			<th mat-header-cell *matHeaderCellDef>
				<div>Preis (Brutto)</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let item">
				{{ item.price | currency }}
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td width="100px" mat-cell *matCellDef="let item; let i = index">
				<button mat-icon-button (click)="remove(i)" matTooltip="Entfernen">
					<mat-icon svgIcon="trash-can-outline"></mat-icon>
				</button>
				<button mat-icon-button (click)="openEditDialog(item, i)" matTooltip="Bearbeiten">
					<mat-icon svgIcon="pencil"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let row; columns: columns"></tr>
	</table>
</ng-container>
<br /><button mat-button color="primary" (click)="add({ articleKind: null, price: null })">Artikelpreis hinzufügen</button>
