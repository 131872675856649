<ng-container *ngIf="entityFacade.editForm$ | async as editForm">
	<table mat-table [dataSource]="dataSource$ | async">
		<ng-container matColumnDef="paymentKind">
			<th mat-header-cell *matHeaderCellDef>
				<div>Zahlungsart</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let paymentType">
				{{ paymentType.paymentKind }}
			</td>
		</ng-container>
		<ng-container matColumnDef="price">
			<th mat-header-cell *matHeaderCellDef>
				<div>Preis</div>
			</th>
			<td width="250px" mat-cell *matCellDef="let paymentType">
				{{ paymentType.price | currency }}
			</td>
		</ng-container>

		<ng-container matColumnDef="edit">
			<th mat-header-cell *matHeaderCellDef></th>
			<td width="50px" mat-cell *matCellDef="let paymentType; let i = index">
				<button mat-icon-button (click)="openEditDialog(paymentType, i)" matTooltip="Bearbeiten">
					<mat-icon svgIcon="pencil"></mat-icon>
				</button>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="columns"></tr>
		<tr mat-row *matRowDef="let row; columns: columns"></tr>
	</table>
</ng-container>
