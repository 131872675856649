import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PaymentKind } from '../../models/enums/payment-kind.enum';
import { IProcessRoute } from '../../models/process-route.model';
import { AppState, IPaymentState } from '../app.state';
import { EntityFacade } from '../entity.facade';
import { CashOnDeliveryFacade } from '../payment.cash-on-delivery/payment.cash-on-delivery.facade';
import { CashFacade } from '../payment.cash/payment.cash.facade';
import { CollectionPurchaseFacade } from '../payment.collection-purchase/payment.collection-purchase.facade';
import { DirectDebitFacade } from '../payment.direct-debit/payment.direct-debit.facade';
import { PaypalFacade } from '../payment.paypal/payment.paypal.facade';
import { PrepaymentFacade } from '../payment.prepayment/payment.prepayment.facade';
import { PurchaseOnAccountFacade } from '../payment.purchase-on-account/payment.purchase-on-account.facade';
import { IPayment, IPaymentEditForm, IPaymentFilterForm } from './../../models/payment.model';
import { fromPaymentActions } from './payment.actions';
import { initialEditFormValue } from './payment.reducer';
import { PaymentSelector } from './payment.selectors';

@Injectable({
	providedIn: 'root',
})
export class PaymentFacade extends EntityFacade<IPayment, IPaymentState, IPaymentEditForm, IPaymentFilterForm> {
	protected initialEditFormValue = initialEditFormValue;
	protected actions = fromPaymentActions;

	constructor(
		store: Store<AppState>,
		paymentSelector: PaymentSelector,
		private cashFacade: CashFacade,
		private cashOnDeliveryFacade: CashOnDeliveryFacade,
		private directDebitFacade: DirectDebitFacade,
		private paypalFacade: PaypalFacade,
		private prepaymentFacade: PrepaymentFacade,
		private purchaseOnAccountFacade: PurchaseOnAccountFacade,
		private collectionPurchaseFacade: CollectionPurchaseFacade
	) {
		super(store, paymentSelector);
	}

	public updateProcess(entity: IPayment, route: IProcessRoute, comment: string = null, callback: (entity: IPayment) => void = null): void {
		switch (entity.paymentKind) {
			case PaymentKind.Cash:
				this.cashFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.CashOnDelivery:
				this.cashOnDeliveryFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.DirectDebit:
				this.directDebitFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.Paypal:
				this.paypalFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.Prepayment:
				this.prepaymentFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.PurchaseOnAccount:
				this.purchaseOnAccountFacade.updateProcess(entity, route, comment, callback);
				break;
			case PaymentKind.CollectionPurchase:
				this.collectionPurchaseFacade.updateProcess(entity, route, comment, callback);
				break;
		}
	}

	public sendPaymentLink(payment: IPayment): void {
		switch (payment.paymentKind) {
			case PaymentKind.Paypal:
				this.paypalFacade.sendPaymentLink(payment);
				break;
		}
	}

	public changePaymentKind(payment: IPayment, paymentKind: string): void {
		this.store.dispatch(this.actions.changePaymentKind({ payment, paymentKind }));
	}
}
