import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm } from '../../models/payment.model';
import { PaymentPurchaseOnAccountApiService } from '../../services/api/payment.purchase-on-account.service';
import { PaymentPurchaseOnAccountDialogService } from '../../services/dialog/payment.purchase-on-account.service';
import { PurchaseOnAccountNotificationService } from '../../services/notification/payment.purchase-on-account.service';
import { AppState, IPaymentPurchaseOnAccountState } from '../app.state';
import { EntityEffects } from '../entity.effects';
import { SessionSelector } from '../session/session.selectors';
import { fromPurchaseOnAccountActions } from './payment.purchase-on-account.actions';
import { PurchaseOnAccountSelector } from './payment.purchase-on-account.selectors';

@Injectable({
	providedIn: 'root',
})
export class PurchaseOnAccountEffects extends EntityEffects<IPaymentPurchaseOnAccount, IPaymentPurchaseOnAccountState, IPaymentPurchaseOnAccountEditForm, IPaymentPurchaseOnAccountFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		apiService: PaymentPurchaseOnAccountApiService,
		notificationService: PurchaseOnAccountNotificationService,
		dialogService: PaymentPurchaseOnAccountDialogService,
		selector: PurchaseOnAccountSelector,
		sessionSelector: SessionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromPurchaseOnAccountActions, 'PURCHASEONACCOUNT');
	}
}
