import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { BarzahlenNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-barzahlen.component';
import { IPaymentBarzahlen } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class BarzahlenNotificationService extends EntityNotificationService<IPaymentBarzahlen> {
	protected component: ComponentType<any> = BarzahlenNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
