import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IReceiptCorrection } from '../../models/receipt';
import { IReceiptCorrectionState } from '../../state/app.state';
import { CorrectionFacade } from '../../state/receipt.correction/receipt.correction.facade';
import { IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm } from './../../models/receipt';
import { EntityDialogDeleteComponent } from './entitydialogdelete.component';

@Component({
	selector: 'babylon-correctiondialogdelete',
	templateUrl: './entitydialogdelete-correction.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogdelete.component.scss'],
})
export class CorrectionDialogDeleteComponent extends EntityDialogDeleteComponent<IReceiptCorrection, IReceiptCorrectionState, IReceiptCorrectionEditForm, IReceiptCorrectionFilterForm> {
	constructor(entityFacade: CorrectionFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
