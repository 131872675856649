import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IShippingDhlExpress } from '../../models/shipping.model';
import { IShippingDhlExpressState } from '../../state/app.state';
import { DhlExpressFacade } from '../../state/shipping.dhl-express/shipping.dhl-express.facade';
import { IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm } from './../../models/shipping.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-dhl-expressdialogadd',
	templateUrl: './entitydialogadd-dhl-express.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class DhlExpressDialogAddComponent extends EntityDialogAddComponent<IShippingDhlExpress, IShippingDhlExpressState, IShippingDhlExpressEditForm, IShippingDhlExpressFilterForm> {
	constructor(entityFacade: DhlExpressFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
