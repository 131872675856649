import { Injectable } from '@angular/core';

export interface IShopvoteWindow extends Window {
	createRBadge(shopId: number, badgeType: number, protocol: string): void;
}

function getWindow(): any {
	return window;
}

@Injectable({
	providedIn: 'root',
})
export class WindowRefService {
	get nativeWindow(): IShopvoteWindow {
		return getWindow();
	}

	get location(): string {
		return this.nativeWindow.location.href.split('?')[0];
	}
}
