import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StickAddDialogComponent } from '../../components/entitydialog/entitydialog-stick-add.component';
import { StickReplaceDialogComponent } from '../../components/entitydialog/entitydialog-stick-replace.component';
import { StickDialogAddComponent } from '../../components/entitydialogadd/entitydialogadd-stick.component';
import { StickDialogChangeComponent } from '../../components/entitydialogchange/entitydialogchange-stick.component';
import { StickDialogDeleteComponent } from '../../components/entitydialogdelete/entitydialogdelete-stick.component';
import { IArticleStick, IArticleStickEditForm, IArticleStickFilterForm } from '../../models/article.model';
import { IArticleStickState } from '../../state/app.state';
import { ArticleStickFacade } from '../../state/article.stick/article.stick.facade';
import { IEntityDialogData } from './dialog.service';
import { EntityDialogService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class ArticleStickDialogService extends EntityDialogService<IArticleStick, IArticleStickState, IArticleStickEditForm, IArticleStickFilterForm> {
	protected createComponent: ComponentType<any> = StickDialogAddComponent;
	protected updateComponent: ComponentType<any> = StickDialogChangeComponent;
	protected removeComponent: ComponentType<any> = StickDialogDeleteComponent;
	protected replaceComponent: ComponentType<any> = StickReplaceDialogComponent;
	protected addComponent: ComponentType<any> = StickAddDialogComponent;

	constructor(matDialog: MatDialog, entityFacade: ArticleStickFacade) {
		super(matDialog, entityFacade);
	}

	public openReplaceDialog(data: IEntityDialogData<IArticleStick>): MatDialogRef<any> {
		this.entityFacade.update(data.entity);

		return this.openDialog(this.replaceComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}

	public openAddDialog(data: IEntityDialogData<IArticleStick>): MatDialogRef<any> {
		return this.openDialog(this.addComponent, {
			width: data.width,
			height: data.height,
			disableClose: false,
			minWidth: '400px',
			data,
		});
	}
}
