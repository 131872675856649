import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ThrottleQueue } from 'rx-queue';
import { tap } from 'rxjs';
import { IServiceError } from '../api/api.service';

@Injectable({
	providedIn: 'root',
})
export abstract class NotificationService {
	constructor(protected notificationHandler: NotificationHandler) {}

	public showInfo<TData>(data: TData, component: ComponentType<unknown>, config?: Partial<MatSnackBarConfig<TData>>): void {
		this.notificationHandler.showInfo(data, component, config);
	}
}

interface INotification<TData> {
	config: MatSnackBarConfig<TData>;
	component: ComponentType<unknown>;
}

@Injectable({
	providedIn: 'root',
})
export class NotificationHandler {
	public errors = new ThrottleQueue<INotification<IServiceError>>(1200);
	public infos = new ThrottleQueue<INotification<any>>(1200);

	constructor(protected matSnackBar: MatSnackBar) {
		this.infos
			.pipe(
				tap(notification =>
					this.matSnackBar.openFromComponent(notification.component, {
						duration: 1000,
						horizontalPosition: 'right',
						verticalPosition: 'bottom',
						...notification.config,
					})
				)
			)
			.subscribe();

		this.errors
			.pipe(
				tap(notification =>
					this.matSnackBar.openFromComponent(notification.component, {
						duration: 5000,
						horizontalPosition: 'right',
						verticalPosition: 'bottom',
						...notification.config,
					})
				)
			)
			.subscribe();
	}

	public showError(error: IServiceError, component: ComponentType<unknown>, config?: Partial<MatSnackBarConfig<IServiceError>>): void {
		if (config != null) {
			config.data = error;
		} else {
			config = { data: error };
		}

		this.errors.next({ component, config });
	}

	public showInfo<TData>(data: TData, component: ComponentType<unknown>, config?: Partial<MatSnackBarConfig<TData>>): void {
		if (config != null) {
			config.data = data;
		} else {
			config = { data: data };
		}

		this.infos.next({ component, config });
	}
}
