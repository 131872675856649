import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { PrepaymentNotificationErrorComponent } from '../../components/entitynotificationerror/entitynotificationerror-prepayment.component';
import { IPaymentPrepayment } from '../../models/payment.model';
import { EntityNotificationService } from './entity.service';
import { NotificationHandler } from './notification.service';

@Injectable({
	providedIn: 'root',
})
export class PrepaymentNotificationService extends EntityNotificationService<IPaymentPrepayment> {
	protected component: ComponentType<any> = PrepaymentNotificationErrorComponent;

	constructor(notificationHandler: NotificationHandler) {
		super(notificationHandler);
	}
}
