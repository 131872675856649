import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, tap } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IFilterDescriptor, IReceiptState } from 'src/state/app.state';
import { IReceipt, IReceiptCancellation, IReceiptCorrection, IReceiptInvoice } from '../../models/receipt';
import { ReceiptCancellationDialogService } from '../../services/dialog/receipt.cancellation.service';
import { ReceiptCorrectionDialogService } from '../../services/dialog/receipt.correction.service';
import { ReceiptInvoiceDialogService } from '../../services/dialog/receipt.invoice.service';
import { ReceiptDialogService } from '../../services/dialog/receipt.service';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ProcessFacade } from '../../state/process/process.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { initialState } from '../../state/receipt/receipt.reducer';
import { RouterFacade } from '../../state/router/router.facade';
import { ReceiptKind } from './../../models/enums/receipt-kind.enum';
import { IReceiptEditForm, IReceiptFilterForm } from './../../models/receipt';
import { EntityPageComponent } from './entitypage.component';

@UntilDestroy()
@Component({
	selector: 'babylon-receiptpage',
	templateUrl: './entitypage-receipt.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class ReceiptPageComponent extends EntityPageComponent<IReceipt, IReceiptState, IReceiptEditForm, IReceiptFilterForm> {
	public pageTitle$ = new BehaviorSubject('Belege');
	public columns = ['number', 'date', 'duedate', 'order', 'customer', 'totalPrice', 'referenceReceipt', 'paymentKind', 'state', 'filter'];
	public ReceiptKinds = ReceiptKind;

	protected shouldDoInitialFilter = false;

	constructor(
		titleService: Title,
		private receiptDialogService: ReceiptDialogService,
		route: ActivatedRoute,
		public entityFacade: ReceiptFacade,
		public processFacade: ProcessFacade,
		public processNodeFacade: ProcessNodeFacade,
		public processRouteFacade: ProcessRouteFacade,
		public paymentFacade: PaymentFacade,
		public orderFacade: OrderFacade,
		public customerFacade: CustomerFacade,
		public invoiceDialogService: ReceiptInvoiceDialogService,
		public correctionDialogService: ReceiptCorrectionDialogService,
		public cancellationDialogService: ReceiptCancellationDialogService,
		public routerFacade: RouterFacade
	) {
		super(titleService, receiptDialogService, route, entityFacade);

		combineLatest([this.routerFacade.receiptStates$, this.routerFacade.previousRoute$, this.processNodeFacade.list$, this.processRouteFacade.list$])
			.pipe(
				untilDestroyed(this),
				filter(([, , processNodes]) => processNodes.length > 0),
				map(([states, previousRoute, processNodes, processRoutes]) => ({
					processNodes: processNodes.filter(processNode => states.some(state => state.toLowerCase() == processNode?.name.toLowerCase())),
					previousRoute: processRoutes.find(route => route.name.toLowerCase() == previousRoute?.toLowerCase()),
				})),
				debounceTime(200),
				tap(({ processNodes, previousRoute }) => {
					const processNodeNames = processNodes.map(processNode => processNode.name);

					if (processNodes.length == 0 || processNodeNames.indexOf('ReceiptUncompleted') > -1 || processNodeNames.indexOf('ReceiptCompleted') > -1) {
						this.entityFacade.changeSorting({ createdAt: -1 });
					} else {
						this.entityFacade.changeSorting(initialState.sortDescriptors);
					}

					if (processNodes.length > 0) {
						var filterDescriptors: IFilterDescriptor[] = [{ attributeName: '0.processInstance.state', operator: 'IN', value: processNodes.map(processNode => processNode._id) }];

						if (previousRoute != null) {
							filterDescriptors.push({ attributeName: '0.processInstance.actions', operator: 'ELEMMATCH', value: { route: previousRoute._id } });
						}

						this.entityFacade.changeFilterDescriptors(filterDescriptors);
					} else {
						this.entityFacade.resetFilter(true);
					}
				})
			)
			.subscribe();
	}

	public edit(entity: IReceipt, width: string = null): void {
		switch (entity.receiptKind) {
			case ReceiptKind.Invoice:
				this.invoiceDialogService.openUpdateDialog({ entity: entity as IReceiptInvoice, width });
				break;
			case ReceiptKind.Correction:
				this.correctionDialogService.openUpdateDialog({ entity: entity as IReceiptCorrection, width });
				break;
			case ReceiptKind.Cancellation:
				this.cancellationDialogService.openUpdateDialog({ entity: entity as IReceiptCancellation, width });
				break;
			default:
				break;
		}
	}
}
