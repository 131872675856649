import { createAction, props } from '@ngrx/store';
import { IPaging } from '../../models/article-request';
import { IArticleBook, IArticleBookEditForm, IExpandedArticleBook } from '../../models/article.model';
import { IDayRange } from '../app.state';
import { EntityActions } from '../entity.actions';

export const fromArticleBookActions = {
	...EntityActions.create<IArticleBook, IArticleBookEditForm>('[Book]'),
	search: createAction(`[Book] Search`, props<{ date: Date }>()),
	searched: createAction(`[Book] Searched`, props<{ date: Date; entities: IExpandedArticleBook[] }>()),
	extended: createAction(`[Book] Extended`),
	pagingChanged: createAction(`[Book] Paging Changed`, props<{ date: Date; paging: IPaging }>()),
	dayRangeChanged: createAction(`[Book] Day Range Changed`, props<{ date: Date; dayRange: IDayRange }>()),
};
