import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IStock } from '../../models/stock.model';
import { IStockState } from '../../state/app.state';
import { StockFacade } from '../../state/stock/stock.facade';
import { IStockEditForm, IStockFilterForm } from './../../models/stock.model';
import { EntityDialogAddComponent } from './entitydialogadd.component';

@Component({
	selector: 'babylon-stockdialogadd',
	templateUrl: './entitydialogadd-stock.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogadd.component.scss'],
})
export class StockDialogAddComponent extends EntityDialogAddComponent<IStock, IStockState, IStockEditForm, IStockFilterForm> {
	constructor(entityFacade: StockFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
