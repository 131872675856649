import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { IAttachment } from 'src/models/attachment.model';
import { AttachmentDialogService } from 'src/services/dialog/attachment.service';
import { IAttachmentState } from 'src/state/app.state';
import { AttachmentFacade } from 'src/state/attachment/attachment.facade';
import { IAttachmentEditForm, IAttachmentFilterForm } from '../../models/attachment.model';
import { UploadedFileFacade } from '../../state/uploadedfile/uploadedfile.facade';
import { EntityPageComponent } from './entitypage.component';

@Component({
	selector: 'babylon-attachmentpage',
	templateUrl: './entitypage-attachment.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitypage.component.scss'],
	animations: [progressFade],
})
export class AttachmentPageComponent extends EntityPageComponent<IAttachment, IAttachmentState, IAttachmentEditForm, IAttachmentFilterForm> {
	public pageTitle$ = new BehaviorSubject('Anhänge');
	public columns = ['title', 'file', 'filter'];

	constructor(titleService: Title, attachmentDialogService: AttachmentDialogService, route: ActivatedRoute, entityFacade: AttachmentFacade, public uploadedFileFacade: UploadedFileFacade) {
		super(titleService, attachmentDialogService, route, entityFacade);
	}
}
