<mat-progress-bar [@progressFade] mode="indeterminate" *ngIf="entityFacade.isFiltering$ | async"></mat-progress-bar>
<div class="container">
	<h1>{{ pageTitle$ | async }}</h1>
	<ng-container *ngIf="entityFacade.filterOperatorForm$ | async as filterOperatorForm">
		<ng-container *ngIf="entityFacade.filterForm$ | async as filterForm">
			<mat-card class="table-wrapper">
				<div (scroll)="onScroll($event)">
					<table mat-table matSort (matSortChange)="onSortChange($event)" [dataSource]="entityFacade.listFiltered$ | async" class="{{ filterExpanded ? 'filter-toggled' : '' }}">
						<ng-container matColumnDef="number-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="number">Belegnummer</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="number-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Belegnummer</mat-label>
										<input type="number" matInput [ngrxFormControlState]="filterForm.controls[0].controls.number" />
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[availableOptions]="['EQ', 'NE']"
										[value]="filterOperatorForm.controls[0].controls.number.value"
										[controlId]="filterOperatorForm.controls[0].controls.number.id"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="number-content">
							<td mat-cell *matCellDef="let entity">{{ entity.number || 'Proforma' }} ({{ entity.receiptKind | slice: 0:1 }})</td>
						</ng-container>

						<ng-container matColumnDef="date-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="date">Belegdatum</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="date-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Belegdatum</mat-label>
										<input matInput [matDatepicker]="date" [ngrxFormControlState]="filterForm.controls[0].controls.date" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
										<mat-datepicker #date></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.date.value"
										[controlId]="filterOperatorForm.controls[0].controls.date.id"
										[availableOptions]="['DATEEQ', 'GTE', 'LTE', 'GT', 'LT']"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="date-content">
							<td mat-cell *matCellDef="let entity">{{ entity.date ? (entity.date | amLocal | amDateFormat: 'DD.MM.YYYY (dd)') : '' }}</td>
						</ng-container>

						<ng-container matColumnDef="duedate-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="duedate">Zahlungsziel</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="duedate-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Zahlungsziel</mat-label>
										<input matInput [matDatepicker]="duedate" [ngrxFormControlState]="filterForm.controls[0].controls.dueDate" [ngrxValueConverter]="dateToISOStringConverter" />
										<mat-datepicker-toggle matSuffix [for]="duedate"></mat-datepicker-toggle>
										<mat-datepicker #duedate></mat-datepicker>
									</mat-form-field>
									<babylon-filteroperator
										[entityFacade]="entityFacade"
										[value]="filterOperatorForm.controls[0].controls.dueDate.value"
										[controlId]="filterOperatorForm.controls[0].controls.dueDate.id"
										[availableOptions]="['DATEEQ', 'GTE', 'LTE', 'GT', 'LT']"></babylon-filteroperator>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="duedate-content">
							<td mat-cell *matCellDef="let entity">
								{{ entity.dueDate ? (entity.dueDate | amLocal | amDateFormat: 'DD.MM.YYYY (dd)') : '' }}
								<ng-container *ngIf="entity.dueDateDelayed && entity.dueDateDelayed != entity.dueDate"> &rarr; {{ entity.dueDateDelayed | amLocal | amDateFormat: 'DD.MM.YYYY (dd)' }}</ng-container>
							</td>
						</ng-container>

						<ng-container matColumnDef="order-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="order">Bestellung</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<babylon-order-autocompletesingle [value]="filterForm.controls[0].controls.order.value" [controlId]="filterForm.controls[0].controls.order.id"></babylon-order-autocompletesingle>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="order-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
									<a [routerLink]="['/entity', 'order', 'change', order._id]">{{ order.number }}</a>
								</ng-container>
								<babylon-fetch [facade]="orderFacade" [value]="entity.order"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="customer-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="customer">Kunde</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="customer-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="customer-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="orderFacade.fetchOne(entity.order) | async as order">
									<ng-container *ngIf="customerFacade.fetchOne(order.customer) | async as customer"> {{ customer.lastName }}, {{ customer.firstName }} </ng-container>
									<babylon-fetch [facade]="customerFacade" [value]="order.customer"></babylon-fetch>
								</ng-container>
								<babylon-fetch [facade]="orderFacade" [value]="entity.order"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="totalPrice-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="totalPrice">Betrag</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalPrice-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field">
									<mat-form-field appearance="outline" class="filter">
										<mat-label>Preis</mat-label>
										<input type="number" matInput [ngrxFormControlState]="filterForm.controls[0].controls.totalPrice" />
										<span matSuffix>€</span>
									</mat-form-field>
								</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="totalPrice-content">
							<td mat-cell *matCellDef="let entity">{{ entity.totalPrice | currency: 'EUR':'symbol' }}</td>
						</ng-container>

						<ng-container matColumnDef="referenceReceipt-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="referenceReceipt">Referenzbeleg</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="referenceReceipt-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="referenceReceipt-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="entityFacade.fetchOne(entity.referenceReceipt) | async as referenceReceipt">
									{{ referenceReceipt.number }}
								</ng-container>
								<babylon-fetch [facade]="entityFacade" [value]="entity.referenceReceipt"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="paymentKind-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Zahlungsart</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="paymentKind-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="paymentKind-content">
							<td mat-cell *matCellDef="let entity">
								<ng-container *ngIf="paymentFacade.fetchOne(entity.payments[0]) | async as payment">
									{{ payment.paymentKind }}
								</ng-container>
								<babylon-fetch [facade]="paymentFacade" [value]="entity.payments[0]"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="assignee-header">
							<th mat-header-cell *matHeaderCellDef>
								<div>Bearbeiter</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="assignee-filter">
							<th mat-header-cell *matHeaderCellDef></th>
						</ng-container>
						<ng-container matColumnDef="assignee-content">
							<td mat-cell *matCellDef="let entity">
								<babylon-profile-picture [userId]="entity.processInstance.currentUser"></babylon-profile-picture>
							</td>
						</ng-container>

						<ng-container matColumnDef="state-header">
							<th mat-header-cell *matHeaderCellDef>
								<div mat-sort-header="state">Status</div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-filter">
							<th mat-header-cell *matHeaderCellDef>
								<div class="filter-field"></div>
							</th>
						</ng-container>
						<ng-container matColumnDef="state-content">
							<td mat-cell *matCellDef="let entity" class="multi-state">
								<babylon-process-state [entity]="entity" [facade]="entityFacade"></babylon-process-state>
								<ng-container *ngIf="paymentFacade.fetchOne(entity.payments[0]) | async as payment">
									&nbsp;
									<babylon-process-state [entity]="payment" [facade]="paymentFacade"></babylon-process-state>
									<babylon-fetch [facade]="processNodeFacade" [value]="payment.processInstance.state"> </babylon-fetch>
								</ng-container>
								<babylon-fetch [facade]="paymentFacade" [value]="entity.payments[0]"></babylon-fetch>
								<babylon-fetch [facade]="processNodeFacade" [value]="entity.processInstance.state"></babylon-fetch>
							</td>
						</ng-container>

						<ng-container matColumnDef="filter-header">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button (click)="filterExpanded = !filterExpanded" class="{{ filterExpanded ? 'filter-on' : '' }}">
									<mat-icon svgIcon="filter-variant"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-filter">
							<th mat-header-cell *matHeaderCellDef class="filter-button-column">
								<button mat-icon-button matTooltip="Alle Filter zurücksetzen" (click)="entityFacade.resetFilter()" [disabled]="(entityFacade.filterDescriptors$ | async).length == 0">
									<mat-icon svgIcon="close"></mat-icon>
								</button>
							</th>
						</ng-container>
						<ng-container matColumnDef="filter-content">
							<td mat-cell *matCellDef="let entity" class="actions-column">
								<button mat-icon-button matTooltip="Download" [matMenuTriggerFor]="receiptMenu">
									<mat-icon svgIcon="file-pdf-box"></mat-icon>
								</button>
								<mat-menu #receiptMenu="matMenu">
									<button mat-menu-item (click)="entityFacade.download(entity)">Beleg</button>
									<button mat-menu-item (click)="entityFacade.downloadFirstReminder(entity)">Zahlungserinnerung</button>
									<button mat-menu-item (click)="entityFacade.downloadSecondReminder(entity)">Mahnung</button>
								</mat-menu>
								<button mat-icon-button (click)="update(entity)" matTooltip="Bearbeiten">
									<mat-icon svgIcon="pencil"></mat-icon>
								</button>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="columnsHeader; sticky: true"></tr>
						<tr mat-header-row class="filter-row" *matHeaderRowDef="columnsFilter; sticky: true"></tr>
						<tr mat-row *matRowDef="let row; columns: columnsContent"></tr>
					</table>
				</div>
				<mat-toolbar>
					<mat-toolbar-row>
						<div class="buttons">
							<button mat-flat-button color="accent" (click)="entityFacade.followUp()"><mat-icon svgIcon="note-search-outline"></mat-icon> Wiedervorlagen prüfen</button>
						</div>
						<div fxFlex></div>
						<span class="total-count">{{ entityFacade.filterCount$ | async }} von {{ entityFacade.totalCount$ | async }} Einträgen</span>
						<button mat-icon-button (click)="entityFacade.filter()" matTooltip="Aktualisieren">
							<mat-icon svgIcon="refresh"></mat-icon>
						</button>
						<button mat-icon-button (click)="entityFacade.increase()" matTooltip="Weitere anzeigen">
							<mat-icon svgIcon="playlist-plus"></mat-icon>
						</button>
					</mat-toolbar-row>
				</mat-toolbar>
			</mat-card>
		</ng-container>
	</ng-container>
</div>
