import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import _ from 'lodash';
import moment from 'moment';
import { combineLatest, distinctUntilChanged, filter, map, mergeMap, Observable, of } from 'rxjs';
import { progressFade } from 'src/animations/progressfade.animation';
import { ArticleKind, IArticle, IArticleCertificate, IExpandedArticle } from '../../models/article.model';
import { IDeliveryNote } from '../../models/delivery-note';
import { PositionKind } from '../../models/enums/position-kind.enum';
import { IOrder, IOrderEditForm, IOrderFilterForm } from '../../models/order.model';
import { IPayment } from '../../models/payment.model';
import { IArticlePosition, IBundlePosition } from '../../models/position.model';
import { IAction } from '../../models/process-instance-model';
import { ProductKind } from '../../models/product.model';
import { IReceipt } from '../../models/receipt';
import { IShipping } from '../../models/shipping.model';
import { AccountingSummaryAdvancementFacade } from '../../state/accounting-summary.advancement/accounting-summary.advancement.facade';
import { IOrderState } from '../../state/app.state';
import { ArticleFacade } from '../../state/article/article.facade';
import { CollectionPurchaseTransferFacade } from '../../state/collection-purchase-transfer/collection-purchase-transfer.facade';
import { DeliveryNoteFacade } from '../../state/delivery-note/delivery-note.facade';
import { DirectDebitTransferFacade } from '../../state/direct-debit-transfer/direct-debit-transfer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PaymentFacade } from '../../state/payment/payment.facade';
import { ProcessNodeFacade } from '../../state/process-node/process-node.facade';
import { ProcessRouteFacade } from '../../state/process-route/process-route.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { ShippingFacade } from '../../state/shipping/shipping.facade';
import { CommentDialogComponent } from '../dialog/dialog-comment.component';
import { HistoryDialogTableComponent } from './historydialogtable.component';

@Component({
	selector: 'babylon-orderhistorytable',
	templateUrl: './entitydialogtable-orderhistory.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entitydialogtable.component.scss'],
	animations: [progressFade],
})
export class OrderHistoryTableComponent extends HistoryDialogTableComponent<IOrder, IOrderState, IAction, IAction, IOrderEditForm, IOrderFilterForm> {
	public columns = ['date', 'userName', 'entityType', 'entityName', 'route', 'state', 'comment'];

	constructor(
		public orderMatDialog: MatDialog,
		public orderFacade: OrderFacade,
		public processRouteFacade: ProcessRouteFacade,
		public processNodeFacade: ProcessNodeFacade,
		public articleFacade: ArticleFacade,
		public receiptFacade: ReceiptFacade,
		public shippingFacade: ShippingFacade,
		public paymentFacade: PaymentFacade,
		public deliveryNoteFacade: DeliveryNoteFacade,
		public accountingSummaryAdvancementFacade: AccountingSummaryAdvancementFacade,
		public collectionPurchaseTransferFacade: CollectionPurchaseTransferFacade,
		public directDebitTransferFacade: DirectDebitTransferFacade
	) {
		super(orderFacade, orderMatDialog);
		this.controlId = 'processInstance.actions';
	}

	public openOrderCommentDialog(action: IAction, entity: IOrder): void {
		const dialogRef = this.orderMatDialog.open<CommentDialogComponent, string, { comment: string }>(CommentDialogComponent, {
			width: '500px',
			data: action.comment,
		});

		dialogRef
			.afterClosed()
			.pipe(filter(result => !!result))
			.subscribe(result => {
				switch (action.entityName) {
					case 'Auftrag':
						this.entityFacade.changeFormValue({ attributeName: 'processInstance.actions.' + action.index + '.comment', value: result.comment });
						this.entityFacade.updated(false);
						break;
					case 'Artikel':
						this.articleFacade.update(action.entity as IArticle);
						this.articleFacade.changeFormValue({ attributeName: 'processInstance.actions.' + action.index + '.comment', value: result.comment });
						this.articleFacade.updated(false);
						break;
					case 'Beleg':
						this.receiptFacade.update(action.entity as IReceipt);
						this.receiptFacade.changeFormValue({ attributeName: 'processInstance.actions.' + action.index + '.comment', value: result.comment });
						this.receiptFacade.updated(false);
						break;
					case 'Zahlung':
						this.paymentFacade.update(action.entity as IPayment);
						this.paymentFacade.changeFormValue({ attributeName: 'processInstance.actions.' + action.index + '.comment', value: result.comment });
						this.paymentFacade.updated(false);
						break;
					case 'Lieferung':
						this.shippingFacade.update(action.entity as IShipping);
						this.shippingFacade.changeFormValue({ attributeName: 'processInstance.actions.' + action.index + '.comment', value: result.comment });
						this.shippingFacade.updated(false);
						break;
					default:
						break;
				}
				this.entityFacade.invalidate(entity);
			});
	}

	public deepHistory$ = this.buildDeepHistory$().pipe(distinctUntilChanged(_.isEqual));

	protected dataSourceSelector(entity: IOrder): IAction[] {
		return entity.processInstance.actions;
	}

	protected createElement(): IAction {
		return { userName: '', route: '', comment: '', timespan: null, isManual: true, isHappyPath: false, isVisible: true, date: null };
	}

	private getArticlesFromDeliveryNote(deliveryNote: IDeliveryNote): IExpandedArticle[] {
		const articlesFromArticlePositions = _.flatten(deliveryNote.positions.filter(position => position.positionKind == PositionKind.Article).map(position => (position as IArticlePosition).article));
		const articlesFromBundlePositions = _.flatten(deliveryNote.positions.filter(position => position.positionKind == PositionKind.Bundle).map(position => (position as IBundlePosition).articles));
		return [...articlesFromArticlePositions, ...articlesFromBundlePositions];
	}

	private buildDeepHistory$(): Observable<IAction[]> {
		return this.entityFacade.selected$.pipe(
			mergeMap(entity =>
				combineLatest([this.orderFacade.articles$, this.orderFacade.products$, this.receiptFacade.fetchMany([entity.primaryReceipt, ...entity.secondaryReceipts]), this.shippingFacade.fetchMany(entity.shippings)]).pipe(
					mergeMap(([articles, products, receipts, shippings]) =>
						combineLatest([
							this.articleFacade.fetchMany(articles.map(a => a._id)),
							this.paymentFacade.fetchMany(_.flatten(receipts.map(r => r.payments))),
							this.deliveryNoteFacade.fetchMany(shippings.map(s => s.deliveryNote)),
							this.accountingSummaryAdvancementFacade.filterByReceiptNumbers(receipts.map(r => r.number)),
							this.collectionPurchaseTransferFacade.filterByReceiptNumbers(receipts.map(r => r.number)),
						]).pipe(
							mergeMap(([articles, payments, deliveryNotes, accountingSummaries, collectionPurchaseTransfers]) =>
								combineLatest([
									of(_.uniqBy(_.flatten(deliveryNotes.map(deliveryNote => this.getArticlesFromDeliveryNote(deliveryNote))), article => article._id).filter(article => !articles.map(a => a._id).includes(article._id))),
								]).pipe(
									mergeMap(([articlesFromDeliveryNotes]) =>
										combineLatest([this.directDebitTransferFacade.filterByPaymentIds(payments.map(p => p._id)), this.articleFacade.fetchMany(articlesFromDeliveryNotes.map(a => a._id))]).pipe(
											map(([directDebitTransfers, reloadedArticlesFromDeliveryNotes]) => ({
												articles,
												articlesFromDeliveryNotes,
												reloadedArticlesFromDeliveryNotes,
												products,
												receipts,
												shippings,
												deliveryNotes,
												payments,
												accountingSummaries,
												directDebitTransfers,
												collectionPurchaseTransfers,
											}))
										)
									)
								)
							)
						)
					),
					map(entities => {
						let actions: IAction[] = entity.processInstance.actions.map((action, actionIndex) => ({ ...action, entity, entityName: 'Auftrag', displayName: `Auftrag (${entity.number})`, index: actionIndex }));

						actions = [
							...actions,
							..._.flatten(
								entities.articles.map((article, index) =>
									article.processInstance.actions.map((action, actionIndex) => {
										const product = entities.products[index];
										let displayName: string;

										if (product.productKind == ProductKind.MediaProduct) {
											displayName = `${product.name} vom ${moment(article.publicationDate).format('DD.MM.YYYY')}`;
										} else if (article.articleKind == ArticleKind.Certificate) {
											const certificate = article as IArticleCertificate;

											displayName = `${product.name} für ${certificate.giftee}${certificate.partner != null ? ` und ${certificate.partner}` : ''} am ${moment(certificate.date).format('DD.MM.YYYY')}`;
										} else {
											displayName = `${product.name}`;
										}

										return { ...action, displayName, entity: article, entityName: 'Artikel', index: actionIndex };
									})
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.reloadedArticlesFromDeliveryNotes.map((article, index) =>
									article.processInstance.actions.map((action, actionIndex) => {
										const articleFromDeliveryNote = entities.articlesFromDeliveryNotes.find(a => a._id == article._id);
										const product = articleFromDeliveryNote.product;
										let displayName: string;

										if (product.productKind == ProductKind.MediaProduct) {
											displayName = `${product.name} vom ${moment(article.publicationDate).format('DD.MM.YYYY')}`;
										} else if (article.articleKind == ArticleKind.Certificate) {
											const certificate = article as IArticleCertificate;

											displayName = `${product.name} für ${certificate.giftee}${certificate.partner != null ? ` und ${certificate.partner}` : ''} am ${moment(certificate.date).format('DD.MM.YYYY')}`;
										} else {
											displayName = `${product.name}`;
										}

										return { ...action, displayName, entity: article, entityName: 'Retourartikel', index: actionIndex };
									})
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.receipts.map(receipt =>
									receipt.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `${receipt.receiptKind} (${receipt.number ?? 'Proforma'})`,
										entity: receipt,
										entityName: 'Beleg',
										index: actionIndex,
									}))
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.payments.map(payment =>
									payment.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `${payment.paymentKind} (${payment.dueAmount.toLocaleString('de-DE', {
											style: 'currency',
											currency: 'EUR',
										})})`,
										entity: payment,
										entityName: 'Zahlung',
										index: actionIndex,
									}))
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.shippings.map(shipping => {
									const deliveryNote = entities.deliveryNotes.find(d => d._id == shipping.deliveryNote);
									return shipping.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `${shipping.shippingKind} (${deliveryNote.receiver.company || deliveryNote.receiver.lastName}, ${deliveryNote.receiver.address.city})`,
										entity: shipping,
										entityName: 'Lieferung',
										index: actionIndex,
									}));
								})
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.accountingSummaries.map(accountingSummary =>
									accountingSummary.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `${accountingSummary.accountingSummaryKind} (${moment(accountingSummary.from).format('DD.MM.yyyy')} - ${moment(accountingSummary.until).format('DD.MM.yyyy')})`,
										entity: accountingSummary,
										entityName: `Buchungssatz`,
										index: actionIndex,
									}))
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.directDebitTransfers.map(directDebitTransfer =>
									directDebitTransfer.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `Lastschrifteinzug ${moment(directDebitTransfer.from).format('DD.MM.yyyy')} - ${moment(directDebitTransfer.until).format('DD.MM.yyyy')}`,
										entity: directDebitTransfer,
										entityName: `Lastschrifteinzug`,
										index: actionIndex,
									}))
								)
							),
						];

						actions = [
							...actions,
							..._.flatten(
								entities.collectionPurchaseTransfers.map(collectionPurchaseTransfer =>
									collectionPurchaseTransfer.processInstance.actions.map((action, actionIndex) => ({
										...action,
										displayName: `Sammelrechnung ${moment(collectionPurchaseTransfer.from).format('DD.MM.yyyy')} - ${moment(collectionPurchaseTransfer.until).format('DD.MM.yyyy')}`,
										entity: collectionPurchaseTransfer,
										entityName: `Sammelrechnung`,
										index: actionIndex,
									}))
								)
							),
						];

						const sortedActions = _.orderBy(actions, ['date'], ['desc']);
						const result: IAction[] = [sortedActions[0]];

						if (sortedActions.length > 1) {
							for (let i = 1; i < sortedActions.length; i++) {
								const currentAction = sortedActions[i];
								const lastAction = sortedActions[i - 1];
								const secondsDiff = moment(lastAction.date).diff(moment(currentAction.date), 'seconds');

								if (secondsDiff >= 10) {
									result.push({ timespan: secondsDiff, comment: null, date: null, isManual: false, isHappyPath: false, isVisible: false, userName: null, route: null });
								}

								result.push(currentAction);
							}
						}

						return result;
					})
				)
			)
		);
	}
}
