import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, filter, map, of, switchMap } from 'rxjs';
import { ArticleKind, IArticleNewspaperEditForm, IArticleNewspaperFilterForm } from '../../models/article.model';
import { AppState, IArticleNewspaperState } from '../app.state';

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import moment from 'moment';
import { IArticleNewspaper } from '../../models/article.model';
import { IServiceErrorResponse } from '../../services/api/api.service';
import { ArticleNewspaperApiService } from '../../services/api/article.newspaper.service';
import { ArticleApiService } from '../../services/api/article.service';
import { ArticleNewspaperDialogService } from '../../services/dialog/article.newspaper.service';
import { ArticleNewspaperNotificationService } from '../../services/notification/article.newspaper.service';
import { fromArticleActions } from '../article/article.actions';
import { EntityEffects } from '../entity.effects';
import { RegionSelector } from '../region/region.selectors';
import { SessionSelector } from '../session/session.selectors';
import { fromArticleNewspaperActions } from './article.newspaper.actions';
import { ArticleNewspaperSelector } from './article.newspaper.selectors';

@Injectable({
	providedIn: 'root',
})
export class ArticleNewspaperEffects extends EntityEffects<IArticleNewspaper, IArticleNewspaperState, IArticleNewspaperEditForm, IArticleNewspaperFilterForm> {
	constructor(
		actions$: Actions,
		store: Store<AppState>,
		notificationService: ArticleNewspaperNotificationService,
		dialogService: ArticleNewspaperDialogService,
		sessionSelector: SessionSelector,
		private apiService: ArticleNewspaperApiService,
		private articleApiService: ArticleApiService,
		private selector: ArticleNewspaperSelector,
		private regionSelector: RegionSelector
	) {
		super(actions$, store, apiService, notificationService, dialogService, selector, sessionSelector, fromArticleNewspaperActions, 'NEWSPAPER');
	}

	public onSearch$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleNewspaperActions.search),
			debounceTime(10),
			filter(({ date }) => date != null),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			switchMap(([[[{ date }, region], paging], dayRange]) =>
				this.apiService.search({ date, dateFrom: moment.utc(date).toDate(), dateUntil: moment.utc(date).toDate(), articleKind: ArticleKind.Newspaper, region }, paging).pipe(
					map(data => fromArticleNewspaperActions.searched({ date, entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleNewspaperActions.failed({ error: response.error })))
				)
			)
		)
	);

	public onDayRangeChanged$: any = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleNewspaperActions.dayRangeChanged, fromArticleNewspaperActions.pagingChanged),
			concatLatestFrom(() => this.store.select(this.selector.itemsSearched)),
			concatLatestFrom(() => this.store.select(this.selector.isSearching)),
			concatLatestFrom(() => this.store.select(this.selector.paging)),
			concatLatestFrom(() => this.store.select(this.selector.dayRange)),
			concatLatestFrom(() => this.store.select(this.regionSelector.selected)),
			filter(([[[[[], date]]]]) => date != null),
			switchMap(([[[[[{ date }, list], isSearching], paging], dayRange], region]) => {
				if (isSearching || list.length >= paging.top || dayRange.current > dayRange.max) {
					return of(fromArticleNewspaperActions.extended());
				}

				return this.apiService.search({ date, dateFrom: moment.utc(date).toDate(), dateUntil: moment.utc(date).toDate(), articleKind: ArticleKind.Newspaper, region }, paging).pipe(
					map(data => fromArticleNewspaperActions.searched({ date, entities: data })),
					catchError((response: IServiceErrorResponse) => of(fromArticleNewspaperActions.failed({ error: response.error })))
				);
			})
		)
	);

	// public onLoadedForDayRange$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(fromArticleNewspaperActions.searched),
	// 		concatLatestFrom(() => this.store.select(this.selector.dayRange)),
	// 		map(([{ date }, dayRange]) => fromArticleNewspaperActions.dayRangeChanged({ date, dayRange: { ...dayRange, current: dayRange.current * 2 } }))
	// 	)
	// );

	public onSelected$ = createEffect(() =>
		this.actions$.pipe(
			ofType(fromArticleNewspaperActions.selected),
			map(({ selected }) => fromArticleActions.selected({ selected }))
		)
	);
}
