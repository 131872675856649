import { Injectable } from '@angular/core';
import { IMessage, IMessageEditForm, IMessageFilterForm } from '../../models/message.model';
import { AppState, IMessageState } from '../app.state';
import { EntitySelector } from '../entity.selector';

@Injectable({
	providedIn: 'root',
})
export class MessageSelector extends EntitySelector<IMessage, IMessageState, IMessageEditForm, IMessageFilterForm> {
	protected stateSelector = (state: AppState): IMessageState => state.message;
}
