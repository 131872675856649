import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPermissionState } from '../../state/app.state';
import { PermissionFacade } from '../../state/permission/permission.facade';
import { IPermission, IPermissionEditForm, IPermissionFilterForm } from './../../models/permission.model';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-permissiondialogchange',
	templateUrl: './entitydialogchange-permission.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PermissionDialogChangeComponent extends EntityDialogChangeComponent<IPermission, IPermissionState, IPermissionEditForm, IPermissionFilterForm> {
	constructor(entityFacade: PermissionFacade, matDialogRef: MatDialogRef<any>) {
		super(entityFacade, matDialogRef);
	}
}
