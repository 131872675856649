import { IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm } from './../../models/payment.model';

import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IPaymentPrepayment } from '../../models/payment.model';
import { IPaymentPrepaymentState } from '../../state/app.state';
import { CustomerFacade } from '../../state/customer/customer.facade';
import { OrderFacade } from '../../state/order/order.facade';
import { PrepaymentFacade } from '../../state/payment.prepayment/payment.prepayment.facade';
import { ReceiptFacade } from '../../state/receipt/receipt.facade';
import { EntityDialogChangeComponent } from './entitydialogchange.component';

@Component({
	selector: 'babylon-prepaymentdialogchange',
	templateUrl: './entitydialogchange-prepayment.component.html',
	styleUrls: ['./entitydialogchange.component.scss'],
})
export class PrepaymentDialogChangeComponent extends EntityDialogChangeComponent<IPaymentPrepayment, IPaymentPrepaymentState, IPaymentPrepaymentEditForm, IPaymentPrepaymentFilterForm> {
	constructor(entityFacade: PrepaymentFacade, matDialogRef: MatDialogRef<any>, public orderFacade: OrderFacade, public receiptFacade: ReceiptFacade, public customerFacade: CustomerFacade) {
		super(entityFacade, matDialogRef);
	}
}
