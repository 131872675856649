import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPartner } from '../../models/partner.model';
import { EntityApiService } from './entity.service';

@Injectable({
	providedIn: 'root',
})
export class PartnerApiService extends EntityApiService<IPartner> {
	public entityName = 'partners';

	constructor(http: HttpClient) {
		super(http);
	}
}
