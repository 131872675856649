import { IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm } from '../../models/direct-debit-transfer.model';
import { EntityState, IDirectDebitTransferState } from '../app.state';

import { on } from '@ngrx/store';
import { box } from 'ngrx-forms';
import { EntityReducer } from '../entity.reducer';
import { fromDirectDebitTransferActions } from './direct-debit-transfer.actions';

export const initialValue: IDirectDebitTransfer = {
	_id: null,
	positions: [],
	from: null,
	until: null,
	totalAmount: null,
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialEditFormValue: IDirectDebitTransferEditForm = {
	_id: null,
	from: null,
	until: null,
	totalAmount: null,
	positions: [
		{
			_id: null,
			payment: null,
			orderNumber: null,
			date: null,
			customerName: null,
			account: null,
			saleProceed: null,
			amount: null,
		},
	],
	processInstance: {
		currentUser: null,
		actions: [{ userName: null, route: null, comment: null, date: null, timespan: null, isManual: null, isHappyPath: null, isVisible: null }],
		state: null,
		process: null,
		priority: null,
	},
};

export const initialFilterFormValue: IDirectDebitTransferFilterForm = {
	_id: null,
	createdAt: null,
	from: null,
	until: null,
	totalAmount: null,
	positions: null,
	processInstance: {
		currentUser: box([]),
		actions: { route: null },
		state: box([]),
		process: null,
		priority: null,
	},
};

export const initialState = EntityState.create<IDirectDebitTransfer, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm>(
	'DIRECTDEBITTRANSFER',
	initialValue,
	initialEditFormValue,
	initialFilterFormValue,
	[
		{
			from: 'DATEEQ',
			until: 'DATEEQ',
			totalAmount: 'EQ',
			processInstance: {
				state: 'IN',
			},
		},
	],
	{ createdAt: -1 }
);
export const directDebitTransferReducer = EntityReducer.create<IDirectDebitTransfer, IDirectDebitTransferState, IDirectDebitTransferEditForm, IDirectDebitTransferFilterForm>(
	initialState,
	{},
	fromDirectDebitTransferActions,
	on(fromDirectDebitTransferActions.filterByPaymentIds, state => ({
		...state,
		isFetching: true,
	})),
	on(fromDirectDebitTransferActions.filterByPaymentIds, state => ({
		...state,
		isFetching: false,
	}))
);
